
<div :style="cssVars">
  <PageHeader title="Pages">
    <template #actions>
      <Button
        v-track="['Pages Modal Opened']"
        type="button"
        variant="neutral"
        size="md"
        @click="addPages"
      >
        <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600" />
        Add From Library
      </Button>
    </template>
  </PageHeader>
  <InfoBox
    title="Attach pages from your library to this project."
    icon="icon_v2-so_docs-files"
  >
    Add Pages from your Library to this Project.
    The selected Pages will appear in your client’s sidebar menu.
  </InfoBox>
  <Form
    v-if="state"
    v-slot="{ isBusy, submit, hasChanges }"
    :state="state"
    success-message="Project changes saved"
    :on-submit="() => save()"
  >
    <div class="flex flex-col max-w-page">
      <!-- Packaged Services -->
      <b-form-group>
        <SlickList
          :value="pages"
          use-drag-handle
          lock-to-container-edges
          axis="xy"
          helper-class="so-table-row-dragging"
          @input="reorder"
        >
          <SlickItem
            v-for="(page, index) in pages"
            :key="page._id"
            :index="index"
            class="flex flex-col p-0 overflow-visible"
          >
            <ProjectSettingsPageItem
              :document="page"
              @remove="remove(page._id)"
              @edit="edit(page._id)"
            />
          </SlickItem>
        </SlickList>
      </b-form-group>
    </div>
    <PageFooter>
      <Button
        :disabled="isBusy"
        size="lg"
        variant="primary"
        type="submit"
        @click="submit"
      >
        Save Changes
      </Button>
      <template v-if="hasChanges" #right>
        <PageFooterMessage />
      </template>
    </PageFooter>
  </Form>
  <SelectDocumentsModal
    v-if="isModalOpen"
    v-model="isModalOpen"
    :selection.sync="selection"
    title="Add pages from your library"
    button-label="Page"
    :documents="documents"
    hide-cost
    @done="updatePages"
  />
</div>
