<template>
  <div class="grid" :style="projectTheme">
    <ProjectTasksPageHeader
      :view-context="viewContext"
      :keyword.sync="keyword"
      :hidden-tabs="hiddenTabs"
      :hide-new-section-button="$route.name === PROJECT_TASKS_DOCUMENTS"
      @update:view-context="updateViewContext"
      @create-section="(...args) => view.createSection(...args)"
    />
    <router-view
      v-if="viewContext"
      ref="view"
      :organization="organization"
      :project="project"
      :view-context="viewContext"
      :keyword="keyword"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { useLocalStorageReactive } from '@/v2/lib/composition/useLocalStorage'
import useProjectTheme from '@/v2/lib/composition/useProjectTheme'
import { useProjectModule } from '@/v2/services/projects/compositions'
import { PROJECT_TASKS_VIEW_DEFAULTS } from '@/v2/services/projects/projectsTypes'
import { PROJECT_TASKS_DOCUMENTS } from '@/router/project/type'
import ProjectTasksPageHeader from '@/components/ProjectTasksPageHeader.vue'

export default defineComponent({
  name: 'ViewProjectTasks',
  components: {
    ProjectTasksPageHeader,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const keyword = ref('')
    const view = ref(null)

    const viewContext = useLocalStorageReactive(
      'projectTasksViewContext',
      PROJECT_TASKS_VIEW_DEFAULTS
    )

    const updateViewContext = data => Object.assign(viewContext, data)

    const projectTheme = useProjectTheme({
      project: computed(() => props.project),
    })

    const documentsModule = useProjectModule(computed(() => props.project), 'documents')

    const hiddenTabs = computed(() => {
      const hidden = []

      if (!documentsModule.value.enabled) {
        hidden.push('documents')
      }

      return hidden
    })

    return {
      // view context
      viewContext,
      updateViewContext,
      keyword,
      view,
      PROJECT_TASKS_DOCUMENTS,
      projectTheme,
      hiddenTabs,
    }
  },
})
</script>
<style lang="postcss" scoped>
  .tasks {
    min-height: 100%;
    border: 1px solid blue;
  }
</style>
