
<div
  class="container relative flex flex-col items-center justify-center pt-36"
>
  <IconGrid :size="3">
    <span
      v-if="icon"
      :class="`icon_v2-so_${icon}`"
      class="w-48 text-gray-500 dark:text-darkGray-700 text-48"
    ></span>
    <DocumentIcon
      v-else-if="documentCategory"
      :category="documentCategory"
      size="48"
      class="text-gray-500 dark:text-darkGray-600"
    />
  </IconGrid>

  <div
    class="flex flex-col items-center justify-center mb-24 text-center max-w-prose"
  >
    <h2 class="font-semibold text-gray-700 dark:text-darkGray-400 text-18">
      <slot name="title" />
    </h2>
    <p class="text-gray-600 dark:text-darkGray-400 text-16">
      <slot name="description" />
    </p>
  </div>
  <div
    class="
      absolute top-0 right-0 transform scale-75 -translate-y-40
      lg:translate-y-0 lg:scale-100 lg:right-32 xl:right-64
    "
  >
    <GraphicBase
      v-if="!isClientPortal"
      :vw="33"
      :vh="175"
      :scale="2"
      rotate="56"
      class="text-gray-400 dark:text-darkGray-700 origin-top-left"
    >
      <GraphicLongArrow />
    </GraphicBase>
  </div>
</div>
