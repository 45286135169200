<template>
  <div>
    <SidebarGroup label="Projects">
      <SidebarMenu>
        <SidebarMenuLink
          v-for="projectCollection in collections.projects"
          :key="projectCollection.id"
          :to="{
            name: ORGANIZATION_TEMPLATES_PROJECTS,
            params: { slug: projectCollection.slug },
          }"
          activate-on-child-route
        >
          <SidebarItemBasic
            :label="projectCollection.label"
            :count="projectCollection.count"
            count-variant="muted"
          />
        </SidebarMenuLink>
      </SidebarMenu>
    </SidebarGroup>
    <SidebarGroup label="Documents">
      <SidebarMenu>
        <SidebarMenuLink
          v-for="documentCollection in collections.documents"
          :key="documentCollection.id"
          :to="{
            name: ORGANIZATION_TEMPLATES_DOCUMENTS,
            params: { slug: documentCollection.slug },
          }"
          activate-on-child-route
        >
          <SidebarItemBasic
            :label="documentCollection.label"
            :count="documentCollection.count"
            count-variant="muted"
          />
        </SidebarMenuLink>
      </SidebarMenu>
    </SidebarGroup>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  ORGANIZATION_TEMPLATES_PROJECTS,
  ORGANIZATION_TEMPLATES_DOCUMENTS,
} from '@/router/organization/type'
import SidebarGroup from '@/components/Sidebar/SidebarGroup.vue'
import SidebarMenu from '@/components/Sidebar/SidebarMenu.vue'
import SidebarMenuLink from '@/components/Sidebar/SidebarMenuLink.vue'
import SidebarItemBasic from '@/components/Sidebar/SidebarItemBasic.vue'

export default defineComponent({
  name: 'OrganizationTemplatesNav',
  components: {
    SidebarGroup,
    SidebarMenu,
    SidebarMenuLink,
    SidebarItemBasic,
  },
  props: {
    collections: {
      type: Object,
      default: () => ({
        projects: [],
        documents: [],
      }),
    },
  },
  setup() {
    return {
      ORGANIZATION_TEMPLATES_PROJECTS,
      ORGANIZATION_TEMPLATES_DOCUMENTS,
    }
  },
})
</script>
