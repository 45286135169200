
<div v-fragment>
  <ImageSearchUnsplashModal
    v-if="isUnsplashOpen"
    v-model="isUnsplashOpen"
    @select="onUnsplashImageSelect"
  />
  <FileUploader
    v-slot="{ selectFiles }"
    block
    :multiple="false"
    accept="image/*"
    @upload-start="onUploadStart"
    @file-progress="onFileProgress"
    @file-done="onFileDone"
    @file-error="onFileError"
  >
    <DocumentNodeEditorToolbarPopover
      ref="popover"
      triggers="click"
      :close-on-click-outside="!noClose"
    >
      <template #button-content>
        <div
          v-if="!hasUrl"
          v-b-tooltip.hover.bottom.v-info
          :class="$style['icon-preview']"
          title="Select background image"
        >
          <icon-base
            width="60"
            height="30"
            vw="72"
            vh="36"
            icon-name="image-background"
          >
            <icon_v2-so_image-background />
          </icon-base>
        </div>
        <div
          v-else
          :class="$style['image-preview']"
          :style="{ backgroundImage: `url(${fileUrl})` }"
        />
      </template>
      <template #title>
        Background Image
      </template>
      <template #default>
        <div class="d-block w-100 mt-8">
          <template v-if="hasUrl">
            <DocumentNodeEditorToolbarSlider
              :value="opacity"
              :min="0"
              :max="100"
              :step="5"
              label="Opacity"
              @input="v => $emit('update:opacity', v)"
            />
            <div
              :class="$style.image"
              :style="{ backgroundImage: `url(${fileUrl})` }"
            >
              <!-- <PieProgressBar
                v-show="fileUploadState.isUploading"
                :ratio="fileUploadState.progress"
              /> -->
              <div
                :class="[
                  'flex items-center justify-center w-full h-112',
                  'rounded bg-center bg-cover relative mb-8',
                  'group',
                ]"
                :style="{ backgroundImage: `url(${fileUrl})` }"
              >
                <PieProgressBar
                  v-show="fileUploadState.isUploading"
                  :ratio="fileUploadState.progress"
                />
                <b-link
                  :class="[
                    'absolute bottom-8 right-8 opacity-0 invisible items-center',
                    'bg-gray-1000 bg-opacity-60 px-10 py-6 rounded',
                    'text-14 text-gray-400 hover:text-white',
                    'group-hover:opacity-100 group-hover:visible',
                  ]"
                  @click="removeFile"
                >
                  <span class="icon_v2-so_trash mr-8" />Remove
                </b-link>
              </div>
            </div>
          </template>

          <!-- Upload Buttons  -->
          <div class="space-y-8">
            <Button
              variant="primary"
              block
              size="m"
              @click="onSelectFiles(selectFiles)"
            >
              <span class="icon_v2-so_image text-16 w-16 mr-8" />Upload file
            </Button>
            <Button
              variant="neutral"
              :disabled="fileUploadState.isUploading"
              block
              size="m"
              @click="openUnsplash"
            >
              <span class="w-16 mr-8 icon-logo-unsplash text-16" />
              from Unsplash
            </Button>
          </div>
        </div>
      </template>
    </DocumentNodeEditorToolbarPopover>
  </FileUploader>
</div>
