<template>
  <WithSidebar>
    <template #sidebar-header>
      <h2 class="font-semibold text-16">Settings</h2>
    </template>
    <template #sidebar>
      <UserSettingsNav />
    </template>
    <template #default>
      <router-view :user="user" :organization="organization" />
    </template>
  </WithSidebar>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import WithSidebar from '@/components/WithSidebar.vue'
import UserSettingsNav from '@/components/UserSettingsNav.vue'
import { useUser } from '@/v2/services/users/usersCompositions'

export default defineComponent({
  name: 'ViewUserSettings',
  components: {
    WithSidebar,
    UserSettingsNav,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { user } = useUser()

    return {
      user,
    }
  },
})
</script>
