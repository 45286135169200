<template>
  <LayoutCenter v-if="error" transparent>
    <Error
      :error="error"
      variant="danger"
    />
  </LayoutCenter>
  <section
    v-else-if="!isTokenPending"
    :class="[
      'flex flex-col lg:pb-48',
    ]"
  >
    <div class="flex items-center justify-center md:py-16 h-116 lg:py-34 lg:mb-0">
      <span class="w-48 text-white text-48 icon-superokay-symbol"></span>
    </div>
    <!-- Card -->
    <div
      :class="[
        'flex flex-col justify-center items-center',
        'w-full md:py-16 px-16 md:px-32 max-w-[554px]',
      ]"
    >
      <!-- Logo -->

      <!-- Welcome message -->
      <div class="mb-40 text-center">
        <h1 class="font-semibold text-gray-900 text-18 md:text-24">
          Welcome to SuperOkay, Sumo-ling
        </h1>
        <p class="leading-normal text-gray-600 text-14 lg:text-15">
          Complete your registration. <span class="text-gray-900">Just a few more steps.</span>
        </p>
      </div>


      <!-- Form -->
      <section class="flex flex-col items-center justify-center space-y-8">
        <SignUpFormProfile
          :user="user"
          :profile="profile"
          :is-loading="isRegistrationPending"
          :is-email-locked="true"
          @submit="onSubmit"
        />
      </section>
    </div>
  </section>
</template>

<script>
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { useNamespacedMutations } from 'vuex-composition-helpers'
import { path, pick } from 'ramda'
import { GUEST_HOME } from '@/router/guest/type'
import { useAccountsRegisterSumoling } from '@/v2/services/accounts/accountsCompositions'
import { useUser } from '@/v2/services/users/usersCompositions'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { isCodeValid } from '@/v2/lib/helpers/validators'
import SignUpFormProfile from '@/components/SignUpFormProfile.vue'
import LayoutCenter from '@/views/LayoutCenter.vue'
import Error from '@/components/Error.vue'
import { TYPE } from '@/v2/services/tokens/tokensTypes'
import { tokens } from '@/v2/services'

const credentials = pick(['email', 'password'])

export default defineComponent({
  name: 'ViewSignUp',
  metaInfo: {
    titleTemplate: 'Welcome, Sumoling - %s',
  },
  components: {
    SignUpFormProfile,
    Error,
    LayoutCenter,
  },
  staticData: {
    GUEST_HOME,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const user = reactive({ email: '', password: '' })
    const profile = reactive({ firstName: '', lastName: '' })
    const isRegistrationPending = ref(false);
    const isTokenPending = ref(true);
    const error = ref(null)
    const { setIsAppSumoRegistration } = useNamespacedMutations('globalFlags', ['setIsAppSumoRegistration'])
    const { routerPush } = useRouter()

    const registerSumoling = useAccountsRegisterSumoling()
    const { authenticateLocal, logout, isAuthenticated } = useUser()

    async function onSubmit(formData) {
      try {
        isRegistrationPending.value = true

        await registerSumoling({
          user: formData.user,
          profile: formData.profile,
          organization: {
            name: `${path(['profile', 'firstName'], formData)}'s Organization`,
          },
        });
        // await wait(2000);

        await routerPush({ query: { rc: false } });
        await authenticateLocal(credentials(formData.user));
        // Raise flag so that user can be redirected to billing after completing registration
        // process
        setIsAppSumoRegistration(true)
        isRegistrationPending.value = false
      } catch (err) {
        console.error('Error on form submission:', err);
        error.value = err
        isRegistrationPending.value = false
      }
    }

    const goToSignIn = () => {
      context.root.$router.push({
        name: GUEST_HOME,
      })
    }
    const logoutExistingUser = () => {
      if (isAuthenticated.value) {
        logout()
      }
    }


    const fetchData = async () => {
      try {
        if (!isCodeValid(props.token)) {
          error.value = 'Invalid registration code. Please re-check URL received via email.'
        }
        // Get token data
        const token = await tokens.get(props.token)

        if (!token) {
          error.value = 'Invalid registration code. Registration might have been already fulfilled'
        } else if (token.type !== TYPE.APPSUMO_LICENSE) {
          error.value = 'Invalid registration code. Please re-check URL received via email.'
        } else {
          // Autofill email field
          user.email = token?.license?.activationEmail
          logoutExistingUser()
        }
      } catch (err) {
        console.error('Error on fetching registration token:', err);
        error.value = err
        // TODO: Is the toaster notification enough? Should we redirect to a dedicated page?
        // redirectHome(error)
      } finally {
        isTokenPending.value = false
      }
    }
    // Check token
    fetchData()

    return {
      user,
      profile,
      isRegistrationPending,
      isTokenPending,
      error,

      onSubmit,
      goToSignIn,
    }
  },
})
</script>

<style lang="scss" module>
.login {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 32px 0 100px;
}

.text {
  padding-right: 48px;
  color: $gray-900;
}

.form {
  background-color: white;
  border-radius: $border-radius-x2;
  padding: 55px 55px;
  @include box-shadow-item;
  border: 1px solid $gray-300;
}
.signin {
  color: $gray-900;
  border: 1px solid $gray-500;
  border-radius: $border-radius;
  display: inline-block;
  text-decoration: none;
  font-weight: $medium;
  font-size: $fs18;
  line-height: 50px;
  padding: 0 34px;
  margin-left: 24px;
  transition: all 0.3 ease;
  &:hover {
    color: $primary;
    text-decoration: none;
    border: 1px solid $primary;
    background-color: rgba(white, 1);
  }
}
.links {
  font-size: $fs18;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-900;
  position: absolute;
  bottom: -68px;
  right: 0;
  left: 0;
  height: 40px;
}
</style>
