<template>
  <div
    class="card relative flex flex-col min-w-0 pb-0"
    v-bind="$attrs"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CardMaterial',
}
</script>

<style lang="postcss" module>
.card-material :global(.form-buttons) {
  @apply -mb-16 md:-mb-24 lg:-mb-32 -ml-16 md:-ml-32 -mr-16 md:-mr-32 mt-24 px-16 md:px-32;
}
</style>
