<template>
  <div>
    <PageHeader title="Email White Label" />
    <div class="mb-24 text-gray-600 text-14">
      <p>
        Send email notifications from your own email address instead of no-reply@superokay.com
      </p>
      <p>Connect your business email to personalize your notification emails "from" field.</p>
    </div>

    <SettingsEmailActive
      v-if="[
        EMAIL_STATUS.active,
        EMAIL_STATUS.locked,
        EMAIL_STATUS.removeRequested
      ].includes(organization.emailStatus)"
      :organization="organization"
    />
    <SettingsEmailSetup v-else :organization="organization" />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { EMAIL_STATUS } from '@/v2/services/organizations/organizationsTypes'
import PageHeader from '@/components/PageHeader.vue'
import Badge from '@/components/Badge.vue'
import SettingsEmailActive from '@/components/SettingsEmailActive.vue'
import SettingsEmailSetup from '@/components/SettingsEmailSetup.vue'

export default defineComponent({
  name: 'ViewOrganizationSettingsEmailWhiteLabel',
  components: {
    PageHeader,
    Badge,
    SettingsEmailActive,
    SettingsEmailSetup,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      EMAIL_STATUS,
    }
  },
})
</script>
