
<ModalBasic
  :title="modalTitle"
  :should-close-on-backdrop="false"
  :hide-footer="true"
  content-class="modal-content-tertiary"
  :body-class="$style['modal-body-apps']"
  :header-class="$style['modal-header-apps']"
  footer-class="modal-footer-tertiary"
  :title-class="$style['modal-title-apps']"
  size="lg"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #body="{ close }">
    <EmbeddedAppForm
      :app="linkedAppDefaults"
      :variant="appPreset ? 'url':'embedCode'"
      :instructions="appPreset ? appPreset.instructions : null"
      @submit="data => !(void $tracking.track('New Embedded App Created')) && saveFormData(data)"
    >
      <template #buttons="{ isFormBusy, isFormInvalid }">
        <div class="mb-32 mt-auto">
          <Button
            :disabled="isFormInvalid || isFormBusy"
            type="submit"
            size="md"
            variant="primary"
          >
            Save App
          </Button>
        </div>
      </template>
    </EmbeddedAppForm>
  </template>
</ModalBasic>
