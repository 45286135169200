
<div
  v-if="show"
  class="text-left text-14"
>
  <div
    v-if="group.style === 'checkmark'"
    class="flex items-center gap-4"
  >
    <span
      class="
        inline-flex w-16 h-16
        items-center justify-center
        rounded-full text-white
      "
      :class="{
        'bg-green-500': isEnabled,
        'bg-gray-500': isEnabledLimited,
        'bg-red-500': isDisabled,
      }"
    >
      <span
        class="text-14"
        :class="{
          'icon_v2-so_tick': isEnabled || isEnabledLimited,
          'icon_v2-so_close': isDisabled,
        }"
      />
    </span>
    <span :class="{ 'text-gray-400': isDisabled }">
      {{ featureFlag.label }}
    </span>
  </div>
  <div v-if="group.style === 'bold'" class="font-bold">
    {{ featureFlag.label }}
  </div>
</div>
