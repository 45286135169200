<template>
  <div v-if="client">
    <ClientPageHeader
      :client="client"
      @create-client-contact="createContact"
      @remove-client="removeClient"
    />
    <ClientContacts
      :client="client"
      :contacts="contacts"
      @edit-client-contact="editContact"
      @create-client-contact="createContact"
    />
    <TeamMemberFormModal
      v-if="isContactModalOpen"
      v-model="isContactModalOpen"
      :member-id="contactMemberId"
      :organization-id="organization._id"
      create-as-client-contact
      :client-id="client._id"
      @hide="$tracking.track('New Client Organization Modal Closed')"
      @save="closeContactModal"
      @close="closeContactModal"
      @blur="closeContactModal"
    />
  </div>
</template>
<script>
  import Vue from 'vue'
import { computed, defineComponent } from '@vue/composition-api'
import { ROLES } from '@/v2/services/members/membersTypes'
import {
  ORGANIZATION_SETTINGS_DOMAIN,
  ORGANIZATION_CLIENTS,
} from '@/router/organization/type'
import { useFindClientContacts } from '@/v2/services/clients/compositions'
import { useMsgBoxConfirm, useMsgBoxOK } from '@/v2/lib/composition/useMsgBox'
import useEntityEditor from '@/v2/lib/composition/useEntityEditor'
import { useMemberLimit } from '@/v2/services/members/compositions'
import { useRouter } from '@/v2/lib/composition/useRouter'
import TeamMemberFormModal from '@/components/TeamMemberFormModal.vue'
import ClientContacts from '@/components/ClientContacts.vue'
import ClientPageHeader from '@/components/ClientPageHeader.vue'

export default defineComponent({
  name: 'ViewOrganizationClientContacts',
  components: {
    ClientContacts,
    ClientPageHeader,
    TeamMemberFormModal,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const msgBoxConfirm = useMsgBoxConfirm()
    const msgBoxOK = useMsgBoxOK()
    const { routerPush } = useRouter()

    const clientId = computed(() => props.client?._id ?? null)
    const { items: contacts, isPending } = useFindClientContacts(clientId)

    // -- create / edit contact
    const {
      entityId: contactMemberId,
      isEditorOpen: isContactModalOpen,
      closeEditor: closeContactModal,
      create: _createContact,
      edit: editContact,
    } = useEntityEditor()

    const { checkMemberLimit } = useMemberLimit()

    const createContact = async () => {
      if (checkMemberLimit(ROLES.client)) {
        return
      }

      if (props.organization.subdomain) {
        _createContact()
        return
      }

      // else prompt the message box to ask user how to continue
      const ok = await msgBoxConfirm({
        title: 'No subdomain',
        message: 'To invite a client contact you need to have a subdomain.'
          + ' Would you like to set one now ?',
        okLabel: 'Set one up now',
        okVariant: 'tertiary',
        cancelLabel: 'No',
      })

      if (ok) {
        routerPush({ name: ORGANIZATION_SETTINGS_DOMAIN })
      }
    }

    // -- delete client
    const { Project } = Vue.$FeathersVuex.api

    const validateRemoveClient = async () => {
      const projectCount = await Project.count({
        query: { client: props.client._id },
      })

      return Number(projectCount ?? 0) === 0
    }

    const removeClient = async () => {
      const canRemove = await validateRemoveClient()
      if (!canRemove) {
        const ok = await msgBoxOK({
          title: `Delete ${props.client.name}?`,
          message:
          '⚠️ Cannot delete client because it is linked to projects. Please unlink them by changing the client on the projects or delete the projects',
          okLabel: 'OK',
        })
        if (ok) {
          return null
        }
      }
      const ok = await msgBoxConfirm({
        title: `Delete ${props.client.name}?`,
        message: 'This will remove the client and contacts',
        okLabel: 'Delete',
        okVariant: 'danger',
        cancelLabel: 'Cancel',
      })

      if (ok) {
        await props.client.remove()
        routerPush({ name: ORGANIZATION_CLIENTS })
      }
      return null
    }

    return {
      isPending,
      // client
      removeClient,
      // contacts
      contacts,
      createContact,
      editContact,
      contactMemberId,
      isContactModalOpen,
      closeContactModal,
    }
  },
})
</script>
