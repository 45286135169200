
<FeathersVuexGet
  :id="clientId === 'new' ? null : clientId"
  v-slot="{ item: client, isGetPending }"
  service="clients"
>
  <router-view
    v-if="!isGetPending"
    :organization="organization"
    :client="client"
  />
</FeathersVuexGet>
