<template>
  <div v-fragment>
    <!-- Button Add Before -->
    <div v-if="!isSelected && showCreateBefore" class="block-add add-top">
      <DocumentBlockButtonCreateMenu
        :parent-node-id="parent"
        :index="index"
        :accepts="accepts"
        location="before"
        @visibility-change="v => $emit('menu-visibility-change', v)"
      />
    </div>
    <!-- end of Button Add Before -->
    <!-- Button Add After -->
    <div v-if="!isSelected && showCreateBefore" class="block-add add-bottom">
      <DocumentBlockButtonCreateMenu
        :parent-node-id="parent"
        :index="index"
        :accepts="accepts"
        location="after"
        @visibility-change="v => $emit('menu-visibility-change', v)"
      />
    </div>
    <!-- end of Button Add After -->

    <!-- Other Node Editor Actions -->
    <div
      v-if="!isGroupColumn"
      class="block-actions"
      :class="isFocus && 'is-focus'"
    >
      <!-- Left Hand Actions -->
      <!-- Multiple Selection Handle -->
      <div
        v-if="!isSpecificEmbed && sameSelectedNodeParent"
        class="block-actions-select"
        @click.stop
      >
        <b-form-checkbox
          v-b-tooltip.hover.top.v-info.dh0.ds200
          :checked="isSelected"
          :title="isSelected ? 'Remove from selection' : 'Add to selection'"
          size="lg"
          class="custom-checkbox-dark"
          @change="isSelected => onCheckboxToggle(isSelected)"
        />
      </div>

      <!-- Drag -->
      <div
        class="block-actions-move"
      >
        <div
          v-if="!isSelected"
          class="block-actions-move-button"
        >
          <button
            v-handle
            v-b-tooltip.hover.top.v-info.dh0.ds200
            class="
              w-32 h-32 flex items-center justify-center
              hover:bg-gray-500 hover:bg-opacity-10 outline-none focus:outline-none
            "
            title="Drag to move"
            @click.stop
          >
            <span class="icon_v2-so_drag so-drag-icon" />
          </button>
        </div>
      </div>

      <!-- Move Up -->
      <button
        v-if="showMoveUp"
        v-b-tooltip.hover.top.v-info.dh0.ds200
        :title="`Move Block Up (${$mac ? 'CMD' : 'CTRL'} + ⬆️)`"
        class="
          flex items-center justify-center w-32 h-32 outline-none
          hover:bg-gray-500 hover:bg-opacity-10 focus:outline-none
        "
        @click.stop="$emit('move-up')"
      >
        <span class="w-16 text-gray-200 text-16 icon_v2-so_arrow-up"></span>
      </button>

      <!-- Move Down -->
      <button
        v-if="showMoveDown"
        v-b-tooltip.hover.top.v-info.dh0.ds200
        :title="`Move Block Down (${$mac ? 'CMD' : 'CTRL'} + ⬇️)`"
        class="
          flex items-center justify-center w-32 h-32 outline-none
          hover:bg-gray-500 hover:bg-opacity-10 focus:outline-none
        "
        @click.stop="$emit('move-down')"
      >
        <span class="w-16 text-gray-200 text-16 icon_v2-so_arrow-down"></span>
      </button>

      <!-- Comments -->
      <portal-target
        :name="`comments-${node._id}`"
        slim
      />

      <!-- Duplicate -->
      <button
        v-if="!isSpecificEmbed && !isSelected"
        v-b-tooltip.hover.top.v-info.dh0.ds200
        title="Duplicate Block"
        class="
          flex items-center justify-center w-32 h-32 outline-none
          hover:bg-gray-500 hover:bg-opacity-10 focus:outline-none
        "
        :disabled="node.hasEmbeddedDocument"
        @click.stop="$emit('duplicate')"
      >
        <span class="w-16 text-gray-200 text-16 icon_v2-so_copy-done"></span>
      </button>

      <!-- Delete -->
      <button
        v-if="!isSelected"
        v-b-tooltip.hover.top.v-info.dh0.ds200
        :title="`Delete Block (${$mac ? 'CMD' : 'CTRL'} + ⬅️)`"
        class="
          flex items-center justify-center w-32 h-32 outline-none
          hover:bg-gray-500 hover:bg-opacity-10 focus:outline-none
        "
        @click.stop="$emit('remove')"
      >
        <span class="w-16 text-gray-200 text-16 icon_v2-so_trash"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, toRef } from '@vue/composition-api'
import { useNamespacedActions, useNamespacedMutations, useNamespacedState } from 'vuex-composition-helpers'
import { HandleDirective } from 'vue-slicksort'
import useKeydown from '@/v2/lib/composition/useKeydown'
import DocumentBlockButtonCreateMenu from '@/components/Document/DocumentBlockButtonCreateMenu.vue'


export default defineComponent({
  name: 'DocumentNodeEditorActions',
  directives: { handle: HandleDirective },
  components: {
    DocumentBlockButtonCreateMenu,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    parent: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    accepts: {
      type: Array,
      default: null,
    },
    showMoveUp: {
      type: Boolean,
      default: true,
    },
    showMoveDown: {
      type: Boolean,
      default: true,
    },
    showCreateBefore: {
      type: Boolean,
      default: true,
    },
    grid: Boolean,
    isFocus: Boolean,
  },
  setup(props, context) {
    useKeydown({
      enabled: toRef(props, 'isFocus'),
      keyMap: {
        ArrowUp: {
          metaKey: true,
          callback: () => context.emit('move-up'),
          condition: () => props.showMoveUp,
        },
        ArrowDown: {
          metaKey: true,
          callback: () => context.emit('move-down'),
          condition: () => props.showMoveDown,
        },
        Backspace: {
          metaKey: true,
          callback: () => context.emit('remove'),
        },
      },
    })

    const { selectedNodes, selectedNodeParent } = useNamespacedState('documentEditor', ['selectedNodes', 'selectedNodeParent'])
    const isSelected = computed(() => selectedNodes.value.includes(props.node._id))

    /** Is this node rendering a Case Study or Team Bio type of Content Block? */
    const isSpecificEmbed = computed(() => Boolean(props.node.isSpecificEmbed))
    const isGroupColumn = computed(() => Boolean(props.node.isGroupColumn))

    const {
      removeNodeFromSelection,
      addNodesToSelection,
    } = useNamespacedActions('documentEditor', ['removeNodeFromSelection', 'addNodesToSelection'])
    const { setSelectedNodeParent } = useNamespacedMutations('documentEditor', ['setSelectedNodeParent'])
    const onCheckboxToggle = isNodeSelected => {
      if (isNodeSelected) {
        addNodesToSelection([props.node._id])
        setSelectedNodeParent(props.parent)
      } else {
        removeNodeFromSelection(props.node._id)
        setSelectedNodeParent('')
      }
    }

    const sameSelectedNodeParent = computed(() => selectedNodeParent.value === ''
    || selectedNodeParent.value === props.parent)

    return {
      isSelected,
      isSpecificEmbed,
      onCheckboxToggle,
      selectedNodeParent,
      sameSelectedNodeParent,
      selectedNodes,
      isGroupColumn,
    }
  },
})
</script>

<style lang="postcss" scoped>
.block-actions {
  @apply absolute z-1000;
  @apply bg-gray-800 rounded-md shadow;
  @apply flex flex-col items-center justify-start;
  @apply pointer-events-auto;
  @apply print:hidden;
  @apply pt-8 pb-4 px-2 mr-6;
  @apply -left-16/*  top-6 */;

  /** large screen */
  @apply 2xl:pt-0 2xl:pb-0;
  @apply 2xl:h-32 2xl:left-6 2xl:mr-0;
  @apply 2xl:flex-row;
  @apply 2xl:-top-16;

  &.is-focus {
    @apply right-full left-auto;
    /** large screen */
    @apply 2xl:-top-8 2xl:tablet:-top-40 2xl:left-0 2xl:right-auto;
  }
}

.block-add {
  @apply absolute h-0;
  @apply left-0 right-0 z-30;
  @apply flex flex-row justify-center;
}

.add-top {
  @apply top-0;
}

.block-add.add-top {
  @apply z-40
}

.add-bottom {
  @apply bottom-0;
}

.block-add:before {
  /* content: "";
  @apply absolute h-2 bg-gray-800 left-0 right-0;
  @apply top-0;
  z-index: -1; */
}
/* ----------------------------------------------- */
/* ------- LEFT ---------------------------------- */
/* ----------------------------------------------- */

.block-actions-left {
  @apply flex flex-row items-center justify-center z-1;
}

.block-actions-select {
  @apply flex flex-col items-center justify-center w-32 m-0 p-0;
}


.so-drag-icon {
  /* color: var(--so-block-foreground); */
  @apply text-gray-100;
}

/* ----------------------------------------------- */
/* ------- RIGHT --------------------------------- */
/* ----------------------------------------------- */
.block-actions-right {
  @apply flex flex-row items-center;
  @apply text-gray-100;
}

/* ----------------------------------------------- */
/* ------- GRID FOR CASE STUDIES AND TEAM BIOS --- */
/* ----------------------------------------------- */

/* Left */
.grid .block-actions-left {
    @apply top-20 left-0 p-0 border-0;
}


/* Right */
.grid .block-actions-right {
  @apply w-48 right-20 top-20 space-y-8;
}

</style>
