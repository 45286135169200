<template>
  <div>
    <ListLightDropdown
      :variant="variant"
      :size="size"
      :lazy="true"
      position="right"
      toggle-class="px-10"
      no-caret
    >
      <template #button-content>
        <span :class="iconClass" />
        <span>{{ label }}</span>
      </template>

      <template #default>
        <ListLightDropdownItem
          label="Blank"
          :document-category="category"
          @select="createDocument()"
        />
        <ListLightDropdownItem
          v-if="hasTemplates"
          label="Use Template"
          icon="templates"
          :badge="templateCount"
          @select="openModal"
        />
      </template>
    </ListLightDropdown>
    <DocumentFromTemplateModal
      v-if="hasTemplates && isModalOpen"
      v-model="isModalOpen"
      :templates-category="templatesCategory"
      @select="onTemplateSelect"
    />
  </div>
</template>

<script>
import { applySpec, both, map, path } from 'ramda'
import { rejectNil, isNotNil } from 'ramda-extension'
import { computed, ref, watch } from '@vue/composition-api'
import strapi from '@/v2/services/strapiService'
import { useDocumentLimit } from '@/v2/services/documents/compositions'
import { useDocuments } from '@/v2/services/documents/documentsCompositions'
import ListLightDropdown from '@/components/List/ListLightDropdown.vue'
import ListLightDropdownItem from '@/components/List/ListLightDropdownItem.vue'
import DocumentFromTemplateModal from '@/components/DocumentFromTemplateModal.vue'
import { DOCUMENT_TEMPLATE_FIELDS } from '@/v2/services/documents/documentsTypes'

// -- helpers
const checkHasTemplates = both(isNotNil, v => v > 0)

const makeMapTemplateData = documentCategory => {
  const fields = DOCUMENT_TEMPLATE_FIELDS[documentCategory]
  if (!fields) {
    return () => ({}) // nothing to map
  }

  const spec = map(field => path(field.split('.')))
  return applySpec(spec(fields))
}

// -- component
export default {
  name: 'ButtonMenuCreateDocument',
  components: {
    ListLightDropdown,
    ListLightDropdownItem,
    DocumentFromTemplateModal,
  },
  props: {
    category: {
      type: String,
      default: '',
    },
    templatesCategory: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'dark',
    },
    size: {
      type: String,
      default: 'sm',
    },
    iconClass: {
      type: String,
      default: 'icon_v2-so_plus',
    },
  },
  setup(props) {
    const { create } = useDocuments()
    const isCreatingDocument = ref(false)
    const templateCount = ref(null)
    const isModalOpen = ref(false)

    const { checkDocumentLimit } = useDocumentLimit()

    const openModal = () => {
      if (!checkDocumentLimit(props.category)) {
        isModalOpen.value = true
      }
    }

    const hasTemplates = computed(() => checkHasTemplates(templateCount.value))

    const templateCountQuery = computed(() => ({
      'template_category.name': props.templatesCategory ?? props.category,
    }))

    const fetchTemplateCount = async () => {
      const { data: count } = await strapi.templates.count(templateCountQuery.value)
      templateCount.value = count
    }

    const createDocument = async (cloneOf, data = {}) => {
      if (isCreatingDocument.value || checkDocumentLimit(props.category)) {
        return
      }

      try {
        isCreatingDocument.value = true
        await create(rejectNil({
          category: props.category,
          cloneOf,
          ...data,
        }))
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      } finally {
        isCreatingDocument.value = false
      }
    }

    const onTemplateSelect = ({ template, document: documentId }) => {
      const mapTemplateData = makeMapTemplateData(props.category)
      return createDocument(documentId, mapTemplateData(template))
    }

    watch(
      templateCountQuery,
      fetchTemplateCount,
      { deep: true, immediate: true }
    )

    return {
      isModalOpen,
      openModal,
      create,
      createDocument,
      fetchTemplateCount,
      templateCount,
      hasTemplates,
      onTemplateSelect,
    }
  },
}
</script>
