<template>
  <SpinnerOverlay v-if="isBusy" />
  <Error
    v-else-if="error"
    variant="danger"
    :error="error"
  />
  <div v-else>
    <PageHeader>
      <div class="text-24 flex items-center justify-center gap-x-12">
        <div>Checkout</div>
        <span class="text-green-500">{{ planItem.name }}</span>
        <Badge variant="tertiary">{{ periodUnitLabel }}</Badge>
      </div>
    </PageHeader>
    <iframe
      v-if="checkoutUrl"
      frameborder="0"
      :src="checkoutUrl"
      class="h-full w-full"
    />
  </div>
</template>
<script>
import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useChargebee } from '@/v2/services/chargebee/compositions'
import { ORGANIZATION_SUBSCRIPTION_SUCCESS } from '@/router/organization/type'
import paymentPlansService from '@/v2/services/paymentPlans/paymentPlansService'
import Badge from '@/components/Badge.vue'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import useAsyncTask from '@/v2/lib/composition/useAsyncTask'
import Error from '@/components/Error.vue'
import PageHeader from '@/components/PageHeader.vue'

const findPriceItem = (plan, periodUnit) => plan?.prices?.find(
  price => price.periodUnit === periodUnit
) ?? null

export default defineComponent({
  name: 'ViewOrganizationSubscriptionCheckout',
  components: {
    Badge,
    SpinnerOverlay,
    Error,
    PageHeader,
  },
  props: {
    provider: {
      type: String,
      required: true,
    },
    plan: {
      type: String,
      required: true,
    },
    periodUnit: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { router } = useRouter()
    const chargebee = useChargebee()

    const planItem = ref(null)
    const priceItem = ref(null)
    const checkoutUrl = ref(null)

    const {
      exec: load,
      isBusy,
      error,
    } = useAsyncTask(async () => {
      try {
        const params = { query: { provider: props.provider } }
        planItem.value = await paymentPlansService.get(props.plan, params)
        priceItem.value = findPriceItem(planItem.value, props.periodUnit)

        if (!priceItem.value) {
          throw new Error('Invalid Selection')
        }

        const { href: redirectUrl } = router.resolve({
          name: ORGANIZATION_SUBSCRIPTION_SUCCESS,
        })

        const { result } = await chargebee.checkout({
          itemPriceId: priceItem.value.id,
          redirectUrl: import.meta.env.VITE_URL + redirectUrl,
        })
        checkoutUrl.value = result.url
      } catch (err) {
        planItem.value = null
        priceItem.value = null
        checkoutUrl.value = null

        throw err
      }
    }, false)

    const periodUnitLabel = computed(() => {
      const dict = { year: 'yearly', month: 'monthly' }
      return dict[props.periodUnit]
    })

    watch(() => props.plan, load, { immediate: true })

    return {
      planItem,
      priceItem,
      isBusy,
      error,
      periodUnitLabel,
      checkoutUrl,
    }
  },
})
</script>
