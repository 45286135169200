<template>
  <div v-fragment>
    <CardSettings :step="step">
      <CardSettingsHeader
        title="Your Team"
        info="Who from your team can access this project?"
      >
        <template #button>
          <Button
            type="button"
            variant="primary"
            size="sm"
            @click="create"
          >
            <span class="icon_v2-so_users mr-4"></span>
            Add team member
          </Button>
        </template>
      </CardSettingsHeader>
      <TeamManager
        v-model="state"
        :organization-id="organization._id"
        :query="query"
        :query-selectable="querySelectable"
        empty-message="No team members"
        button-title="Manage team access"
        select-people-title="Add people from your organization"
        select-people-description="Who from your team can access this project?"
      />
    </CardSettings>
    <TeamMemberFormModal
      v-if="isMemberModalOpen"
      v-model="isMemberModalOpen"
      :organization-id="organization._id"
      @saved="onCreated"
    />
  </div>
</template>
<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import { ROLES, STATUS } from '@/v2/services/members/membersTypes'
import CardSettings from '@/components/CardSettings.vue'
import CardSettingsHeader from '@/components/CardSettingsHeader.vue'
import Button from '@/components/Button.vue'
import TeamManager from '@/components/TeamManager.vue'
import TeamMemberFormModal from '@/components/TeamMemberFormModal.vue'

export default defineComponent({
  name: 'ProjectSettingsAccessTeam',
  components: {
    CardSettings,
    CardSettingsHeader,
    Button,
    TeamManager,
    TeamMemberFormModal,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      default: null,
    },
  },
  setup(props, context) {
    const state = computed({
      get() {
        return props.value
      },
      set(value) {
        context.emit('input', value)
      },
    })

    const isMemberModalOpen = ref(false)

    const queryCommon = computed(() => ({
      organization: props.organization._id,
      status: { $in: [STATUS.ACTIVE, STATUS.INVITED] },
      $sort: { _id: 1 },
    }))

    const query = computed(() => ({
      ...queryCommon.value,
      $or: [
        { role: { $in: [ROLES.admin, ROLES.projectManager] } },
        { role: ROLES.member, _id: { $in: state.value } },
      ],
    }));

    const querySelectable = computed(() => ({
      ...queryCommon.value,
      role: ROLES.member,
    }));

    const create = () => {
      isMemberModalOpen.value = true
    }

    const onCreated = newMember => {
      isMemberModalOpen.value = false

      if ([ROLES.member].includes(newMember.role)) {
        state.value = [...state.value, newMember._id]
      }
    }

    return {
      state,
      isMemberModalOpen,
      query,
      querySelectable,
      create,
      onCreated,
    }
  },
})
</script>
