<template>
  <div
    class="group p-8 flex gap-8 rounded-md px-10 py-6 max-w-form"
    :class="{
      'hover:bg-gray-100 dark:hover:bg-darkGray-500': !$isMobile,
    }"
    @dblclick="$emit('edit')"
  >
    <button
      v-handle
      class="
          w-16 flex items-center justify-center
          text-gray-600 hover:text-gray-700 opacity-25 group-hover:opacity-100
          dark:text-darkGray-400 dark:hover:text-darkGray-400
        "
      title="Drag to move"
      @click.stop
    >
      <span class="icon_v2-so_drag" />
    </button>
    <div
      class="flex items-center justify-between flex-1 w-128"
    >
      <div
        class="text-gray-800 dark:text-white flex gap-8 flex-wrap"
      >
        <div class="text-14">{{ document.title }}</div>
        <div
          class="items-center gap-8"
          :class="{
            'hidden group-hover:flex opacity-25 group-hover:opacity-100': !$isMobile,
            'flex': $isMobile
          }"
        >
          <ButtonIcon
            v-b-tooltip.hover.top.v-info.dh0.ds200="'Edit in library'"
            icon="icon_v2-so_pencil"
            class="dark:text-darkGray-400"
            size="18"
            @click="$emit('edit')"
          />
          <ButtonIcon
            v-b-tooltip.hover.top.v-info.dh0.ds200="'Remove from project'"
            icon="icon_v2-so_trash"
            class="dark:text-darkGray-400"
            size="18"
            @click="$emit('remove')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { HandleDirective } from 'vue-slicksort'
import ButtonIcon from '@/components/ButtonIcon.vue'

export default defineComponent({
  name: 'ProjectSettingsPageItem',
  directives: { handle: HandleDirective },
  components: {
    ButtonIcon,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
})
</script>
