<template>
  <ProjectGet
    :id="projectId"
    v-slot="{ project }"
  >
    <div v-if="project" v-fragment>
      <PortalRender to="app-sidebar-header" :provide="{ project }">
        <ProjectNavHeader :project="project" />
      </PortalRender>
      <Provide :provide="{ project }">
        <Watch
          :value="project"
          immediate
          @change="setProjectLocale"
        >
          <LoaderWebFont
            :families="[
              project?.brandingFontHeading ?? 'Inter',
              project?.brandingFontParagraph ?? 'Inter',
            ]"
          >
            <router-view
              :organization="organization"
              :project="project"
            />
          </LoaderWebFont>
        </Watch>
      </Provide>
    </div>
  </ProjectGet>
</template>
<script>
import { ref, defineComponent, onUnmounted, getCurrentInstance } from '@vue/composition-api'
import { PROJECT_DASHBOARD } from '@/router/project/type'
import Provide from '@/components/Provide.vue'
import PortalRender from '@/components/PortalRender.vue'
import Watch from '@/components/Watch.vue'
import LoaderWebFont from '@/components/LoaderWebFonts.vue';
import ProjectGet from '@/components/ProjectGet.vue'
import ProjectNavHeader from '@/components/ProjectNavHeader.vue'

export default defineComponent({
  name: 'ViewProject',
  components: {
    Provide,
    Watch,
    LoaderWebFont,
    ProjectGet,
    PortalRender,
    ProjectNavHeader,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLocalePending = ref(false)
    const vm = getCurrentInstance().proxy
    const i18n = vm.$i18n


    const setLocale = async project => {
      const locale = project.locale ?? props.organization?.locale ?? 'en'
      const { default: messages } = await import(`../locales/${locale}.json`)
      i18n.setLocaleMessage(locale, messages)
      i18n.locale = locale
    }

    const setProjectLocale = async project => {
      if (project) {
        isLocalePending.value = true
        await setLocale(project)
        isLocalePending.value = false
      }
    }

    onUnmounted(() => {
      i18n.locale = import.meta.env.VITE_I18N_LOCALE
    })

    return {
      PROJECT_DASHBOARD,
      isLocalePending,
      setProjectLocale,
    }
  },
})
</script>
