<template>
  <ProjectTheme :project="project" no-dark-mode>
    <ProjectPermissions
      v-slot="{ permissions }"
      :project="project"
    >
      <DocActions
        v-slot="{
          update,
          remove,
          move,
          share,
          download,
          duplicate,
        }"
        :document="document"
      >
        <DocRenderer :document="document">
          <template #breadcrumbs>
            <DocBreadcrumbs :document="document" :project="project" />
          </template>
          <template #actions>
            <DocFolderViewOptions
              :value="viewContext"
              @input="value => $emit('update-view-context', value)"
            />
            <DocCreateButton
              v-if="permissions.create"
              :disabled="document.status === DOCUMENT_STATUS.APPROVED"
              disabled-reason="Approved folders are read-only"
            />
            <DocMenu
              :document="document"
              :active-items="{
                share: true,
                duplicate: permissions.create,
                remove: permissions.remove,
                download: true,
                move: permissions.create && permissions.remove,
              }"
              @remove="remove"
              @move="move"
              @share="share"
              @download="download"
              @duplicate="duplicate"
            />
          </template>
          <template #header>
            <DocTitle
              :document="document"
              :disabled="!permissions.update"
              @update="title => update({ title })"
            />
            <DocSharedBadge :document="document" @click="share" />
          </template>

          <template #footer-left>
            <DocUpdatedBy :document="document" />
          </template>
          <template #footer-right>
            <DocStatusToggle
              :value="document.status"
              data-intercom-target="Document Status Select"
              @input="status => update({ status })"
            />
          </template>

          <template #content>
            <FolderContentList
              v-if="document"
              :document="document"
              :view-context="viewContext"
              :permissions="permissions"
            />
            <DocApprovalBadge :document="document" />
          </template>
          <template #sidebar-right>
            <DocSidebar :document="document" />
          </template>
        </DocRenderer>
      </DocActions>
    </ProjectPermissions>
  </ProjectTheme>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { STATUS as DOCUMENT_STATUS } from '@/types/document'
import FolderContentList from '@/components/FolderContentList.vue'
import ProjectTheme from '@/components/ProjectTheme.vue'
import DocApprovalBadge from '@/components/Doc/DocApprovalBadge.vue'
import DocBreadcrumbs from '@/components/Doc/DocBreadcrumbs.vue'
import DocCreateButton from '@/components/Doc/DocCreateButton.vue'
import DocFolderViewOptions from '@/components/Doc/DocFolderViewOptions.vue'
import DocMenu from '@/components/Doc/DocMenu.vue'
import DocRenderer from '@/components/Doc/DocRenderer.vue'
import DocTitle from '@/components/Doc/DocTitle.vue'
import DocUpdatedBy from '@/components/Doc/DocUpdatedBy.vue'
import DocStatusToggle from '@/components/Doc/DocStatusToggle.vue'
import DocActions from '@/components/Doc/DocActions.vue'
import DocSidebar from '@/components/Doc/DocSidebar.vue'
import DocSharedBadge from '@/components/Doc/DocSharedBadge.vue'
import ProjectPermissions from '@/components/ProjectPermissions.vue'

export default defineComponent({
  name: 'FSFolder',
  components: {
    FolderContentList,
    ProjectTheme,
    DocApprovalBadge,
    DocBreadcrumbs,
    DocCreateButton,
    DocFolderViewOptions,
    DocMenu,
    DocRenderer,
    DocTitle,
    DocUpdatedBy,
    DocStatusToggle,
    DocActions,
    DocSidebar,
    DocSharedBadge,
    ProjectPermissions,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    viewContext: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      DOCUMENT_STATUS,
    }
  },
})
</script>
