<template>
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="isEmpty"

    :config-override="{
      stylingToolbar: {
        features: {
          sizes: false,
        },
      },
    }"
  >
    <template #default>
      <SlickList
        v-if="!isEmpty"
        v-model="nodes"
        lock-to-container-edges
        axis="xy"
        use-drag-handle
        helper-class="so-group-node-dragging"
        class="grid grid-cols-1 gap-24 mb-24 auto-rows-fr lg:grid-cols-2 print:grid-cols-2"
        @sort-start="handleSortStart"
        @sort-end="handleSortEnd"
      >
        <!-- <DocumentMasonry> -->
        <SlickItem
          v-for="(nodeId, index) in nodes"
          :key="nodeId"
          :index="index"
        >
          <DocumentNodeEditable
            :index="index"
            :node-id="nodeId"
            :parent="node._id"
            :is-first="index === 0"
            :is-last="index === children.length - 1"
            rounded-selection
            grid
            class="so-slick-inner"
          />
        </SlickItem>
        <!-- </DocumentMasonry> -->
      </SlickList>
      <!-- <DocumentMasonry v-if="!isEmpty">
        <DocumentNodeEditable
          v-for="(nodeId, index) in children"
          :key="nodeId"
          :node-id="nodeId"
          :parent="node._id"
          :index="index"
          :is-first="index === 0"
          :is-last="index === children.length - 1"
          rounded-selection
          grid
        />
      </DocumentMasonry> -->
      <DocumentNodeGroupTeamBiosPlaceholder v-else />
      <DocumentBlockButtonCreate
        :parent-node-id="node._id"
        :index="children.length"
        content-library
        :accepts="accepts"
        class="w-full mb-16"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import { computed } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { SlickList, SlickItem } from 'vue-slicksort'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/types/document'
import useNestedComponents from '@/v2/lib/composition/useNestedComponents'
import { useStructureBranch, useStructureReorderChildren } from '@/v2/services/documentStructures/documentStructuresCompositions'
import DocumentBlockButtonCreate from '@/components/Document/DocumentBlockButtonCreate.vue'
import DocumentMasonry from '@/components/Document/DocumentMasonry.vue'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentNodeEditable from '@/v2/features/document/documentNode/DocumentNodeEditable.vue'
import { changeCursorStyle } from '@/helpers/dom'
import DocumentNodeGroupTeamBiosPlaceholder from './DocumentNodeGroupTeamBiosPlaceholder.vue'

const { useMutations, useState } = createNamespacedHelpers('documentEditor')

export default {
  name: 'DocumentNodeGroupTeamBiosEditable',
  components: {
    DocumentBlockButtonCreate,
    DocumentNodeRenderer,
    DocumentMasonry,
    DocumentNodeGroupTeamBiosPlaceholder,
    SlickList,
    SlickItem,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    useNestedComponents({ DocumentNodeEditable })
    const structureBranch = useStructureBranch()
    const { children, isEmpty } = structureBranch(props.node._id)
    const structureReorderChildren = useStructureReorderChildren()

    const { focusDisabled } = useState(['focusDisabled'])
    const { setFocusDisabled } = useMutations(['setFocusDisabled'])

    const nodes = computed({
      set: newOrder => structureReorderChildren({ parent: props.node._id, children: newOrder }),
      get: () => children.value,
    })

    const handleSortStart = () => {
      setFocusDisabled(true)
      changeCursorStyle('grabbing')
    }
    const handleSortEnd = () => {
      setFocusDisabled(false)
      changeCursorStyle('')
    }

    return {
      children,
      isEmpty,
      nodes,
      focusDisabled,
      setFocusDisabled,
      handleSortStart,
      handleSortEnd,
      accepts: [DOCUMENT_CATEGORY.DocumentTeamBio],
    }
  },
}
</script>
<style lang="postcss">
.so-group-node-dragging .node-highlight,
.so-group-node-dragging .block-actions-right {
  display: none;
}

.so-slick-inner {
  @apply shadow-none h-full;
}

.so-group-node-dragging .so-slick-inner{
  @apply z-0 transform -rotate-1 scale-105;
  @apply m-0 ring-2 bg-white bg-opacity-50 ring-blue-500 rounded-lg overflow-hidden;
  box-shadow: 0 10px 40px rgba(0,0,0, 0.10), 0 0px 60px rgba(0,0,0, 0.04);
}

.so-group-node-dragging .so-slick-inner .block-section-wrapper {
  @apply opacity-75;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
</style>
