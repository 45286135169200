
<div v-fragment>
  <ContextMenu
    v-if="contextMenu.nodeId === node._id && config.menu.enabled"
    :label="label"
    :left="contextMenu.left"
    :top="contextMenu.top"
    @close="onContextMenuClose"
  >
    <b-dropdown-item
      v-if="!isSpecificEmbed"
      :disabled="!(config.block.enableMove && showMoveUp)"
      @click="onMoveUp"
    >
      <span
        :class="['cm-icon icon_v2-so_arrow-up inline-block mr-8']"
      />
      <span>Move Up</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isSpecificEmbed"
      :disabled="!(config.block.enableMove && showMoveUp)"
      @click="onMoveUp"
    >
      <span
        :class="['cm-icon icon_v2-so_arrow-right inline-block mr-8',
                 'transform rotate-180']"
      />
      <span>Move Left</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isSpecificEmbed"
      :disabled="!(config.block.enableMove && showMoveDown)"
      @click="onMoveDown"
    >
      <span
        :class="['cm-icon icon_v2-so_arrow-right inline-block mr-8']"
      />
      <span>Move Right</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="!isSpecificEmbed"
      :disabled="!(config.block.enableMove && showMoveDown)"
      @click="onMoveDown"
    >
      <span
        :class="['cm-icon icon_v2-so_arrow-down inline-block mr-8']"
      />
      <span>Move Down</span>
    </b-dropdown-item>
    <b-dropdown-divider />
    <!-- NOTE: Case Studies and Team Bios within a group should not be copied.
    The entire group can be copied if necessary -->
    <b-dropdown-item :disabled="isSpecificEmbed" @click="cutNodes">
      <span class="inline-block mr-8 cm-icon icon_v2-so_cut"></span>
      <span>Cut</span>
    </b-dropdown-item>
    <b-dropdown-item :disabled="isCopyProhibited" @click="copyNodes">
      <span class="inline-block mr-8 cm-icon icon_v2-so_docs-files"></span>
      <span>Copy</span>
    </b-dropdown-item>
    <b-dropdown-item
      :disabled="isClipboardEmpty || isPasteProhibited"
      @click="pasteNodes('before')"
    >
      <span class="inline-block mr-8 cm-icon icon_v2-so_clipboard-before"></span>
      <span>Paste before</span>
    </b-dropdown-item>
    <b-dropdown-item
      :disabled="isClipboardEmpty || isPasteProhibited"
      @click="pasteNodes('after')"
    >
      <span class="inline-block mr-8 cm-icon icon_v2-so_clipboard-after"></span>
      <span>Paste after</span>
    </b-dropdown-item>
    <b-dropdown-divider />
    <!-- NOTE: Case Studies and Team Bios within a group should not be duplicated, only deleted.
    The entire group can be duplicated if necessary -->
    <b-dropdown-item
      v-if="!isSpecificEmbed"
      :disabled="!config.menu.duplicate"
      @click="onDuplicate"
    >
      <span class="inline-block mr-8 cm-icon icon_v2-so_copy-done"></span>
      <span>Duplicate</span>
    </b-dropdown-item>
    <b-dropdown-item
      :disabled="!config.menu.remove"
      @click="onRemove"
    >
      <span class="inline-block mr-8 cm-icon icon_v2-so_trash"></span>
      <span>Delete</span>
    </b-dropdown-item>

    <portal-target :name="`node-context-menu-${node._id}`" slim />
  </ContextMenu>

  <div
    v-show="isHighlightEnabled"
    :class="[
      'node-highlight',
      {
        hover: isHover,
        focus: isFocus,
        'hover-rounded': roundedSelection,
      },
    ]"
  />
  <!-- DEBUG -->
  <div
    v-if="debug"
    class="debug"
  >
    <span>▶</span>
    <span>category: <strong>{{ node.category }}</strong></span>
    <span>_id: <strong>{{ node._id }}</strong></span>
    <span>hov: <strong>{{ isHover }}</strong></span>
    <span>fcs: <strong>{{ isFocus }}</strong></span>
    <span>emb: <strong>{{ node.hasEmbeddedDocument }}</strong></span>
  </div>
  <!-- DEBUG -->
  <DocumentNodeEditorToolbarImpl
    v-if="config.block.enableStylingToolbar && (isFocus || filesUploadingNode[node._id] > 0) "
    v-show="isFocus && !focusDisabled && !isSelected && !isTextEditorToolbarVisible"
    :column="column"
    :node-id="node._id"
    :value="styling"
    :config="config"
    :text-variant="textVariant"
    @input="onStylingChange"
    @close="setFocus(null)"
    @duplicate="onDuplicate"
    @remove="onRemove"
    @bg-image-upload-start="onBgImageUploadStart"
    @bg-image-upload-progress="onBgImageUploadProgress"
    @bg-image-upload-done="onBgImageUploadDone"
  />
  <DocumentNodeEditorActions
    v-if="(isHover || isFocus || isSelected || isCreateMenuOpen) && !isTextEditorToolbarVisible"
    :document-id="document._id"
    :node="node"
    :parent="parent"
    :index="index"
    :accepts="config.block.accepts"
    :is-focus="isFocus"
    :show-move-up="config.block.enableMove && showMoveUp"
    :show-move-down="config.block.enableMove && showMoveDown"
    :show-create-before="config.block.enableCreateBefore && !isFocus"
    :grid="grid"
    @move-up="$emit('move', -1)"
    @move-down="$emit('move', 1)"
    @remove="onRemove"
    @menu-visibility-change="onCreateMenuVisbilityChange"
    @duplicate="onDuplicate"
  />
  <!-- BLOCK TYPE LABEL -->
  <span
    v-if="config.block.enableBlockLabel"
    class="absolute leading-none pointer-events-none right-22 top-4 text-13 z-1 print:hidden"
    :class="[`variant-${textVariant}-color opacity-40`,
             isHover && 'opacity-100'
    ]"
  >
    {{ label }}
  </span>
  <div
    v-if="bgImageUploadState.isUploading"
    class="bg-image-upload-progress"
  >
    <ImageThumbnail
      :url="bgImageUploadState.localUrl"
      :width="100"
      :height="100"
    />
    <PieProgressBar
      :ratio="bgImageUploadState.progress"
      class="bg-image-pie"
    />
    <span>Uploading background image...</span>
  </div>
</div>
