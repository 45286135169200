<template>
  <div>
    <PortalRender to="app-sidebar" :provide="{ project }">
      <ProjectNavSettings
        :organization="organization"
        :project="project"
      />
    </PortalRender>
    <ProjectTheme :project="project" no-dark-mode>
      <router-view
        :organization="organization"
        :project="project"
      />
    </ProjectTheme>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ProjectTheme from '@/components/ProjectTheme.vue'
import PortalRender from '@/components/PortalRender.vue'
import ProjectNavSettings from '@/components/ProjectNavSettings.vue'

export default defineComponent({
  name: 'ViewProjectSettings',
  components: {
    PortalRender,
    ProjectTheme,
    ProjectNavSettings,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
})
</script>
