<template>
  <div v-fragment>
    <CardSettings :step="step">
      <CardSettingsHeader
        title="Your client"
        info="Select the client organization you are working with on this project."
      >
        <template #button>
          <FeatureLimit
            v-slot="{ checkFeatureLimit }"
            flag="limitClients"
            label="clients"
            @allow="createClient"
          >
            <Button
              type="button"
              variant="primary"
              size="sm"
              @click="checkFeatureLimit"
            >
              <span class="icon_v2-so_users mr-4"></span>
              Add client
            </Button>
          </FeatureLimit>
        </template>
      </CardSettingsHeader>
      <b-form-group>
        <FormField>
          <div class="flex">
            <FormInputClients
              :value="stateClient"
              :organization-id="organization._id"
              size="md"
              class="flex-1"
              @change="onClientChange"
            />
          </div>
        </FormField>
      </b-form-group>
      <CardSettingsHeader
        title="Client contacts"
        :info="stateClient
          ? 'Who from the client\'s team can access this project?'
          : 'To add client contacts and manage their access, you must first select a client.'
        "
        :warn="!stateClient"
      >
        <template #button>
          <FeatureLimit
            v-slot="{ checkFeatureLimit }"
            flag="limitClientContacts"
            label="contact"
            @allow="createContact"
          >
            <Button
              type="button"
              variant="primary"
              size="sm"
              :disabled="!stateClient"
              @click="checkFeatureLimit"
            >
              <span class="icon_v2-so_users mr-4"></span>
              Add client contact
            </Button>
          </FeatureLimit>
        </template>
      </CardSettingsHeader>
      <TeamManager
        v-model="state"
        :organization-id="organization._id"
        :query="query"
        :query-selectable="querySelectable"
        :disabled="!stateClient"
        disabled-message="No client selected"
        empty-message="No contacts"
        button-title="Manage client access"
        select-people-title="Add people from client's team"
        select-people-description="Who from the client's team can access this project?"
      />
    </CardSettings>

    <!-- MODALS -->
    <TeamMemberFormModal
      v-if="isContactModalOpen"
      v-model="isContactModalOpen"
      :organization-id="organization._id"
      :client-id="stateClient"
      create-as-client-contact
      @saved="onContactCreated"
    />

    <ClientModal
      v-if="isClientModalOpen"
      v-model="isClientModalOpen"
      :organization="organization"
      @save="onClientCreated"
    />
  </div>
</template>
<script>
import { uniq } from 'ramda'
import Vue from 'vue'
import { defineComponent, ref, computed } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import { ROLES, STATUS } from '@/v2/services/members/membersTypes'
import CardSettings from '@/components/CardSettings.vue'
import CardSettingsHeader from '@/components/CardSettingsHeader.vue'
import Button from '@/components/Button.vue'
import TeamManager from '@/components/TeamManager.vue'
import TeamMemberFormModal from '@/components/TeamMemberFormModal.vue'
import FormField from '@/components/FormField.vue'
import FormInputClients from '@/components/FormInputClients.vue'
import ClientModal from '@/components/ClientModal.vue'
import FeatureLimit from '@/components/FeatureLimit.vue'

export default defineComponent({
  name: 'ProjectSettingsAccessClients',
  components: {
    CardSettings,
    Button,
    TeamManager,
    TeamMemberFormModal,
    CardSettingsHeader,
    FormField,
    FormInputClients,
    ClientModal,
    FeatureLimit,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    client: {
      type: String,
      default: null,
    },
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      default: null,
    },
  },
  setup(props, context) {
    // -- contact
    const isContactModalOpen = ref(false)

    const state = computed({
      get() {
        return props.value ?? []
      },
      set(value) {
        context.emit('input', value)
      },
    })

    const createContact = () => {
      isContactModalOpen.value = true
    }

    const onContactCreated = newContact => {
      isContactModalOpen.value = false
      state.value = [...state.value, newContact._id]
    }

    // -- client
    const isClientModalOpen = ref(false)

    const stateClient = computed({
      get() {
        return props.client
      },
      set(value) {
        context.emit('update:client', value)
      },
    })

    const createClient = () => {
      isClientModalOpen.value = true
    }

    const onClientCreated = newClientId => {
      stateClient.value = newClientId
    }

    // -- Logic for removing or adding client contacts when clientId changes
    // If a new client is selected, remove all client contacts from the Client's Team.
    // If the current client is re-selected, restore all its client contacts to the Client's Team.

    const { Member } = Vue.$FeathersVuex.api

    const membersParams = computed(() => ({
      query: {
        _id: {
          $in: uniq([
            ...state.value,
            ...props.project.members,
          ]),
        },
      },
    }))

    const { items: allProjectMembers } = useFind({
      model: Member,
      params: membersParams,
    })

    const clientContacts = computed(() => new Set(
      allProjectMembers.value
        .filter(m => m.role === ROLES.client)
        .map(m => m._id)
    ));

    const removeClientContacts = () => {
      const updatedMembers = state.value.filter(mId => !clientContacts.value.has(mId))
      state.value = updatedMembers
    }

    const addInitialClientContacts = clientId => {
      if (clientId !== props.project.client) {
        return
      }

      const updatedMembers = uniq([
        ...state.value,
        ...props.project.members.filter(mId => clientContacts.value.has(mId)),
      ])

      state.value = updatedMembers
    }

    const onClientChange = clientId => {
      removeClientContacts()
      addInitialClientContacts(clientId)
      stateClient.value = clientId
    }

    // -- queries
    const queryCommon = computed(() => ({
      organization: props.organization._id,
      status: { $in: [STATUS.ACTIVE, STATUS.INVITED] },
      $sort: { _id: 1 },
    }))

    const query = computed(() => ({
      ...queryCommon.value,
      client: stateClient.value,
      role: ROLES.client,
      _id: { $in: state.value },
    }));

    const querySelectable = computed(() => ({
      ...queryCommon.value,
      client: stateClient.value,
      role: ROLES.client,
    }));


    return {
      state,
      stateClient,
      query,
      querySelectable,

      // contact
      isContactModalOpen,
      createContact,
      onContactCreated,

      // client
      isClientModalOpen,
      createClient,
      onClientCreated,
      onClientChange,
    }
  },
})
</script>
