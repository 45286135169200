
<div
  id="app"
  class="w-full"
  :class="sidebarIsExpanded ? 'sidebar-expanded' : 'sidebar-collapsed'"
>
  <ConnectionStatus>
    <AppController v-if="routeReady" />
  </ConnectionStatus>
</div>
