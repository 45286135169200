
<div
  class="flex justify-stretch items-stretch
  "
  :style="{
    width: `${width}`,
    height: `${height}`,
  }"
>
  <div
    class="border border-green-500 dark:border-green-300 bg-green-100 dark:bg-darkGray-800 rounded-sm
    flex grow relative
  "
  >
    <div
      class="absolute top-0 w-full h-24 text-12 leading-none font-semibold
       flex items-center justify-center text-green-700 dark:text-green-400
      "
    >
      <span class="bg-green-100 dark:bg-darkGray-800 px-4 relative z-2">{{ displayWidth || width }}px</span>
      <span class="absolute top-1/2 w-full h-2 bg-green-700 dark:bg-green-400  z-1 block opacity-75"></span>
    </div>

    <div
      class="absolute right-0 h-full w-24 text-12 leading-none font-semibold
       flex items-center justify-center text-green-700 dark:text-green-400
      "
    >
      <span class="bg-green-100 dark:bg-darkGray-800 px-4 relative z-2 transform -rotate-90 -translate-x-6">{{ displayHeight || height }}px</span>
      <span class="absolute right-1/2 mr-4 h-full w-2 bg-green-700 dark:bg-green-400 z-1 block opacity-75"></span>
    </div>
  </div>
</div>
