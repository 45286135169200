
<svg
  xmlns="http://www.w3.org/2000/svg"
  :width="width"
  :height="height"
  :vw="vw"
  :vh="vh"
  :viewBox="viewbox"
  :aria-labelledby="iconName"
  role="presentation"
  class="inline-block align-baseline"
>
  <g :fill="iconColor">
    <slot />
  </g>
</svg>
