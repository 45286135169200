
<ProjectTheme :project="project" no-dark-mode>
  <ProjectPermissions
    v-slot="{ permissions }"
    :project="project"
  >
    <DocRenderer :document="document">
      <template #breadcrumbs>
        <DocBreadcrumbs :document="document" :project="project" />
      </template>
      <template #actions>
        <DocFolderViewOptions
          :value="viewContext"
          @input="value => $emit('update-view-context', value)"
        />
        <DocCreateButton v-if="permissions.create" />
      </template>
      <template #header>
        <DocTitleReadonly :project="project" />
      </template>

      <template #content>
        <div v-if="document" v-fragment>
          <FolderContentList
            :document="document"
            :view-context="viewContext"
            :permissions="permissions"
          />
        </div>
      </template>
      <template #info-panel>
        <InfoPanel>
          <template #icon>
            <span class="icon_v2-so_shared text-40"></span>
          </template>
          <template #title>This is your Shared folder</template>
          <template #description>
            <div class="flex flex-col gap-4">
              <p>
                Share anything from Docs &amp; Files with your client contacts by
                <strong>moving them here</strong>.
              </p>
              <p>
                When your client contacts navigate to their Docs &amp; Files tab,
                they will see the contents of this folder.
              </p>
              <p>
                Client Contacts can create new folders, upload files, and
                <strong
                  class="
                  inline-flex items-center px-6 py-3
                  font-medium leading-none bg-yellow-200/80
                  rounded-md text-gray-700
                "
                >rename or delete anything in this folder</strong>.
              </p>
              <a
                href="https://help.superokay.com/en/articles/6385387-how-does-the-shared-folder-work"
                title="learn more about the Shared folder"
                class="underline mt-8"
              >Learn more about how your Shared folder works</a>
            </div>
          </template>
        </InfoPanel>
      </template>
    </DocRenderer>
  </ProjectPermissions>
</ProjectTheme>
