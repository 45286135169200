
<FeathersCount
  v-slot="{ count }"
  :query="query"
  :model="model"
  :events="['created', 'patched', ...events]"
>
  <MainHeaderNavItem
    :label="label"
    :to="to"
    :count="count"
  />
</FeathersCount>
