<template>
  <div>
    <div :class="$style['list']">
      <div v-if="fields" :class="$style['row-header']">
        <ListTableCellHeader
          v-for="(field, index) in fields"
          :key="index"
          :field="field"
        />
      </div>
      <div
        v-for="(item, index) in items"
        :key="item[keyField] || index"
        :class="$style['row']"
      >
        <slot name="default" v-bind="{ item, index }" />
      </div>
    </div>
    <div v-if="hasCreateListener" :class="$style['row-create']">
      <Button
        variant="primary"
        @click="$emit('create')"
      >
        <span class="w-24 icon-add-rounded-s text-24 mr-8 leading-none"></span>
        Add
      </Button>
    </div>
  </div>
</template>

<script>
import { alwaysEmptyArray } from 'ramda-extension'
import { hasListener } from '@/helpers/vue-component'
import ListTableCellHeader from '@/components/ListTableCellHeader.vue'
import Button from '@/components/Button.vue'

const hasCreateListener = hasListener('create')

export default {
  name: 'ListTable',
  components: {
    ListTableCellHeader,
    Button,
  },
  props: {
    items: {
      type: Array,
      default: alwaysEmptyArray,
    },
    fields: {
      type: Array,
      required: true,
    },
    keyField: {
      type: String,
      default: '_id',
    },
  },
  computed: {
    hasCreateListener() {
      return hasCreateListener(this)
    },
  },
}
</script>

<style lang="scss" module>
.list {
  display: table;
  width: 100%;
  table-layout: fixed;

  .row,
  .row-header {
    display: table-row;
  }

  .row:hover {
    :global(.cell) {
      position: relative;
      &::before,
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
    :global(.btn-delete) {
      visibility: visible;
      opacity: 1;
    }
  }

  .row-header {
    font-weight: bold;
  }
}

.row-create {
  border-bottom: 1px solid $gray-300;
  padding: $gutterx2 0;
  margin-bottom: 24px;
}
</style>
