
<div>
  <LibraryDocumentPageHeader
    title="Case Studies"
    label="Case Study"
    :document-category="category"
  />
  <div class="project-page">
    <div class="project-page-wrapper">
      <DocumentList
        :category="category"
        :organization-id="organization._id"
        :auto-margin="false"
      >
        <template #list="{ documents, open, remove }">
          <div class="max-w-page mt-24">
            <DocumentMasonry>
              <DocumentContentBlockCaseStudy
                v-for="document in documents"
                :key="document._id"
                class="masonry-item"
                :content-block="{ embeddedDocument: document._id }"
                standalone
                show-analytics
                @open="open(document)"
                @remove="remove(document)"
              />
            </DocumentMasonry>
          </div>
        </template>
        <template #placeholder>
          <PlaceholderCreate
            :document-category="category"
            button-label="Create your first Case Study"
            class="container"
          >
            <template #title>
              Showcase your best work
            </template>
            <template
              #description
            >
              Create case studies of your past work, add client testimonials and
              reuse them in any Proposal or Brief.
            </template>
          </PlaceholderCreate>
        </template>
      </DocumentList>
    </div>
  </div>
</div>
