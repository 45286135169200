import { identity } from 'ramda'
import { computed } from '@vue/composition-api'
import debounce from '@/v2/lib/helpers/debounce'

export default function useStateDebounce(fields, state, serializers = {}, delay = 500) {
  return fields.reduce((acc, field) => ({
    ...acc,
    [field]: computed({
      get: () => state[field],
      set: debounce(value => {
        const serializer = serializers[field] ?? identity
        // eslint-disable-next-line no-param-reassign
        state[field] = serializer(value)
      }, delay),
    }),
  }), {});
}
