
<SpinnerOverlay v-if="isBusy" />
<Error
  v-else-if="error"
  variant="danger"
  :error="error"
/>
<div v-else>
  <PageHeader>
    <div class="text-24 flex items-center justify-center gap-x-12">
      <div>Checkout</div>
      <span class="text-green-500">{{ planItem.name }}</span>
      <Badge variant="tertiary">{{ periodUnitLabel }}</Badge>
    </div>
  </PageHeader>
  <iframe
    v-if="checkoutUrl"
    frameborder="0"
    :src="checkoutUrl"
    class="h-full w-full"
  />
</div>
