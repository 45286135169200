<template>
  <WithSidebar>
    <template #sidebar-header>
      <h2 class="font-semibold text-16">Templates</h2>
    </template>
    <template #sidebar>
      <OrganizationTemplatesNav :collections="collections" />
    </template>
    <template #default>
      <router-view :collections="collections" />
    </template>
  </WithSidebar>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { useTemplates } from '@/v2/services/templates/compositions'
import strapi from '@/v2/services/strapiService'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { ORGANIZATION_TEMPLATES, ORGANIZATION_TEMPLATES_PROJECTS } from '@/router/organization/type'
import WithSidebar from '@/components/WithSidebar.vue'
import OrganizationTemplatesNav from '@/components/OrganizationTemplatesNav.vue'

export default defineComponent({
  name: 'ViewTemplates',
  components: {
    WithSidebar,
    OrganizationTemplatesNav,
  },
  metaInfo: {
    title: 'Templates',
  },
  setup() {
    const { routerPush, router } = useRouter()

    // -- project collections
    const {
      load: loadProjectCollections,
      collections: projectCollections,
    } = useTemplates()

    const goToFirstProjectCollection = () => {
      const slug = projectCollections.value?.[0]?.slug
      if (slug) {
        routerPush({
          name: ORGANIZATION_TEMPLATES_PROJECTS,
          params: { slug },
        })
      }
    }

    // -- document collections
    const documentCollections = ref([])

    const setDocumentCollections = items => {
      const sorted = [...items].sort((a, b) => a.name.localeCompare(b.name))
      documentCollections.value = sorted.map(item => ({
        id: item.id,
        label: item.name,
        slug: item.slug,
        count: item.templates.length,
      }))
    }

    const loadDocumentCollections = async () => {
      const response = await strapi.templateCollections.find()
      setDocumentCollections(response.data)
    }

    const collections = computed(() => ({
      projects: projectCollections.value,
      documents: documentCollections.value,
    }))

    const init = async () => {
      // load document collections
      loadDocumentCollections()

      // load project collections then go to the first project collection
      await loadProjectCollections()
      goToFirstProjectCollection()
    }

    const unsubscribeAfterEach = router.afterEach(to => {
      if (to.name === ORGANIZATION_TEMPLATES) {
        goToFirstProjectCollection()
      }
    })

    onUnmounted(() => {
      unsubscribeAfterEach()
    })

    init()

    return {
      collections,
    }
  },
})
</script>
