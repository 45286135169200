
<router-link
  v-slot="{ navigate, href, /*isExactActive, */ isActive }"
  :to="to"
  custom
>
  <a
    :href="href"
    @click="navigate"
  >
    <MainNavItem
      :active="isActive || isActiveOtherRoutes"
      :icon="icon"
      :icon-active="iconActive"
      :label="label"
    />
  </a>
</router-link>
