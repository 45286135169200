
<nav>
  <SidebarGroup>
    <SidebarMenu>
      <SidebarMenuLink
        :to="{ name: ORGANIZATION_SETTINGS_MY_ORGANIZATION }"
      >
        <SidebarItemBasic label="My Organization" icon="icon_v2-so_filter-dots" />
      </SidebarMenuLink>
      <SidebarMenuLink
        :to="{ name: ORGANIZATION_SETTINGS_TEAM }"
      >
        <SidebarItemBasic label="Team Members" icon="icon_v2-so_users" />
      </SidebarMenuLink>
      <SidebarMenuLink
        :to="{ name: ORGANIZATION_SETTINGS_PORTAL_SETTINGS }"
        activate-on-child-route
      >
        <SidebarItemBasic label="Client Portal" icon="icon_v2-so_menu-home" />
      </SidebarMenuLink>
      <SidebarMenuLink
        :to="{ name: ORGANIZATION_SETTINGS_DOMAIN }"
      >
        <SidebarItemBasic label="Custom Domain" icon="icon_v2-so_globe" />
      </SidebarMenuLink>
      <SidebarMenuLink
        :to="{ name: ORGANIZATION_SETTINGS_EMAIL }"
      >
        <SidebarItemBasic label="Email White Label" icon="icon_v2-so_email-sent" />
      </SidebarMenuLink>
      <SidebarMenuLink
        :to="{ name: ORGANIZATION_SETTINGS_SUBSCRIPTION }"
        :activate-on-routes="[ORGANIZATION_SETTINGS_SUBSCRIPTION_MANAGE]"
      >
        <SidebarItemBasic label="Subscription &amp; Billing" icon="icon_v2-so_card" />
      </SidebarMenuLink>
    </SidebarMenu>
  </SidebarGroup>
</nav>
