<template>
  <b-select
    :options="clientOptions"
    class="custom-select-client"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #first>
      <b-form-select-option :value="null">None</b-form-select-option>
    </template>
  </b-select>
</template>
<script>
import { computed } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'


export default {
  name: 'FormInputClients',
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const { Client } = context.root.$FeathersVuex.api

    const findParams = computed(() => ({
      query: { organization: props.organizationId },
    }))

    const { items: clients } = useFind({
      model: Client,
      params: findParams,
    })

    const clientOptions = computed(() => clients.value
      .map(({ _id, name }) => ({ text: name, value: _id })))


    return {
      clientOptions,
      clients,
    }
  },
}
</script>

<style lang="postcss" scoped>
  .custom-select-client[value=""] {
    @apply text-gray-500;
  }
</style>
