<template>
  <MemberRole v-slot="{ admin, projectManager, member }">
    <div v-if="organization" class="project-page project-page-wrapper">
      <ProjectViewContext v-slot="{ viewContext, updateViewContext }">
        <div>
          <OrganizationProjectsPageHeader
            :view-context="viewContext"
            :keyword.sync="keyword"
            :tags="tags"
            :selected-tag-count="selectedTagCount"
            :selected-tags.sync="selectedTags"
            @update-view-context="updateViewContext"
          />
          <!-- Content -->
          <section v-if="!isTagsPending">
            <ProjectList
              :keyword="keyword"
              :selected-tags="selectedTags"
              :organization-id="organization._id"
              class="mb-32"
              :view-context="viewContext"
              :query="activeProjectsQuery"
              :header-collapsed="viewContext.activeCollapsed"
              :menu-items="{
                settings: admin || projectManager,
                portalPreview: true,
                duplicate: admin || projectManager,
                archive: admin || projectManager,
                delete: admin || projectManager,
              }"
              @update:header-collapsed="val => updateViewContext({ activeCollapsed: val })"
              @open-project="open"
            >
              <template #header>
                <h2 class="text-18 mb-0 font-semibold text-gray-900">Active Projects</h2>
              </template>
            </ProjectList>
            <ProjectList
              :keyword="keyword"
              :selected-tags="selectedTags"
              :organization-id="organization._id"
              class="mb-32"
              :view-context="viewContext"
              :config="{ createButton: false, menu: true }"
              :query="archivedProjectsQuery"
              :menu-items="{
                settings: false,
                portalPreview: false,
                duplicate: false,
                archive: admin || projectManager,
                delete: admin || projectManager,
              }"
              :header-collapsed="viewContext.archivedCollapsed"
              @update:header-collapsed="val => updateViewContext({ archivedCollapsed: val })"
            >
              <template #header="{ projects }">
                <h2
                  v-if="projects?.length"
                  class="text-18 mb-0 font-semibold text-gray-900"
                >
                  Archived Projects
                </h2>
              </template>
            </ProjectList>
          </section>
        </div>
      </ProjectViewContext>
    </div>
  </MemberRole>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { ORGANIZATION_SETTINGS_MY_ORGANIZATION } from '@/router/organization/type'
import { PROJECT, PROJECT_SETTINGS_DETAILS } from '@/router/project/type'
import { useCurrentProfile } from '@/v2/services/profiles/profilesCompositions'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useSearchProjects } from '@/v2/services/projects/compositions'
import ProjectList from '@/components/ProjectList.vue'
import Button from '@/components/Button.vue'
import ProjectViewContext from '@/components/ProjectViewContext.vue'
import OrganizationProjectsPageHeader from '@/components/OrganizationProjectsPageHeader.vue'
import MemberRole from '@/components/MemberRole.vue'

export default defineComponent({
  name: 'ViewOrganizationProjects',
  metaInfo: {
    title: 'Projects',
  },
  components: {
    ProjectList,
    Button,
    ProjectViewContext,
    OrganizationProjectsPageHeader,
    MemberRole,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const keyword = ref('')
    const selectedTags = ref({})

    const selectedTagCount = computed(
      () => Object.values(selectedTags.value).filter(Boolean).length
    )

    const { isPending: isTagsPending, tags } = useSearchProjects()

    const { profile } = useCurrentProfile()
    const { routerPush } = useRouter()

    const open = projectId => {
      routerPush({
        name: PROJECT,
        params: { projectId },
      })
    }

    const activeProjectsQuery = { archived: { $ne: true } }
    const archivedProjectsQuery = { archived: true }

    return {
      PROJECT_SETTINGS_DETAILS,
      ORGANIZATION_SETTINGS_MY_ORGANIZATION,
      profile,
      open,
      keyword,
      selectedTags,
      tags,
      selectedTagCount,
      isTagsPending,
      activeProjectsQuery,
      archivedProjectsQuery,
    }
  },
})
</script>
