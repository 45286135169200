
<LayoutCenter v-if="error" transparent>
  <Error
    :error="error"
    variant="danger"
  />
</LayoutCenter>
<section
  v-else-if="!isTokenPending"
  :class="[
    'flex flex-col lg:pb-48',
  ]"
>
  <div class="flex items-center justify-center md:py-16 h-116 lg:py-34 lg:mb-0">
    <span class="w-48 text-white text-48 icon-superokay-symbol"></span>
  </div>
  <!-- Card -->
  <div
    :class="[
      'flex flex-col justify-center items-center',
      'w-full md:py-16 px-16 md:px-32 max-w-[554px]',
    ]"
  >
    <!-- Logo -->

    <!-- Welcome message -->
    <div class="mb-40 text-center">
      <h1 class="font-semibold text-gray-900 text-18 md:text-24">
        Welcome to SuperOkay, Sumo-ling
      </h1>
      <p class="leading-normal text-gray-600 text-14 lg:text-15">
        Complete your registration. <span class="text-gray-900">Just a few more steps.</span>
      </p>
    </div>


    <!-- Form -->
    <section class="flex flex-col items-center justify-center space-y-8">
      <SignUpFormProfile
        :user="user"
        :profile="profile"
        :is-loading="isRegistrationPending"
        :is-email-locked="true"
        @submit="onSubmit"
      />
    </section>
  </div>
</section>
