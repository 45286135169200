<template>
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="isEmpty"
    :root-class="['node-group-columns-container']"
    :config-override="{
      stylingToolbar: {
        features: {
          sizes: true,
          settings: true,
        },
      },
    }"
  >
    <template #default="{ isHover }">
      <SlickList
        v-if="!isEmpty"
        v-model="nodes"
        lock-to-container-edges
        axis="xy"
        use-drag-handle
        helper-class="so-group-node-dragging"
        class="flex flex-row items-stretch w-full px-12 py-20 space-x-12 print:py-0"
        @sort-start="handleSortStart"
        @sort-end="handleSortEnd"
      >
        <SlickItem
          v-for="(nodeId, index) in groupColumnNodes"
          :key="nodeId"
          :index="index"
          :class="`flex flex-col relative column-${columnsRatio[index] == 100 ? 'oo' : 'mto'}`"
          :style="{ width: `${columnsRatio[index]}%`}"
        >
          <DocumentNodeEditable
            :index="index"
            :node-id="nodeId"
            :parent="node._id"
            class="so-slick-inner"
          />
        </SlickItem>
      </SlickList>
      <DocumentNodeGroupColumnsContainerPlaceholder
        v-else
        @add-columns="addColumns"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
    <template #styling-settings="{ value, update }">
      <DocumentNodeGroupColumnsContainerSettings
        :node-id="node._id"
        :value="value"
        @input="(k, v) => update({ [k]: v })"
      />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import { computed } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { SlickList, SlickItem } from 'vue-slicksort'
import { CATEGORY as NODE_CATEGORY } from '@/v2/services/documentNodes/documentNodesTypes'
import useNestedComponents from '@/v2/lib/composition/useNestedComponents'
import { useStructureBranch, useStructureReorderChildren } from '@/v2/services/documentStructures/documentStructuresCompositions'
import { useCreateNode } from '@/v2/services/documentNodes/documentNodesCompositions'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentNodeEditable from '@/v2/features/document/documentNode/DocumentNodeEditable.vue'
import { changeCursorStyle } from '@/helpers/dom'
import DocumentNodeGroupColumnsContainerPlaceholder from './DocumentNodeGroupColumnsContainerPlaceholder.vue'
import DocumentNodeGroupColumnsContainerSettings from './DocumentNodeGroupColumnsContainerSettings.vue'

export default {
  name: 'DocumentNodeGroupColumnsContainerEditable',
  components: {
    DocumentNodeRenderer,
    DocumentNodeGroupColumnsContainerPlaceholder,
    DocumentNodeGroupColumnsContainerSettings,
    SlickList,
    SlickItem,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    useNestedComponents({ DocumentNodeEditable })
    const structureBranch = useStructureBranch()
    const { children, isEmpty } = structureBranch(props.node._id)
    const structureReorderChildren = useStructureReorderChildren()

    const { useMutations, useState } = createNamespacedHelpers('documentEditor')
    const createNodeGroup = useCreateNode()

    const { focusDisabled } = useState(['focusDisabled'])
    const { setFocusDisabled } = useMutations(['setFocusDisabled'])
    const nodes = computed({
      set: newOrder => structureReorderChildren({ parent: props.node._id, children: newOrder }),
      get: () => children.value,
    })

    const handleSortStart = () => {
      setFocusDisabled(true)
      changeCursorStyle('grabbing')
    }
    const handleSortEnd = () => {
      setFocusDisabled(false)
      changeCursorStyle('')
    }


    const addColumns = columns => {
      if (nodes.value.length !== 0) {
        return null
      }
      columns.reduce(acc => acc.then(() => createNodeGroup({
        parentNodeId: props.node._id,
        nodeData: { category: NODE_CATEGORY.NodeGroupColumn },
        flashNode: false,
      })), Promise.resolve());

      context.emit('update-node', { styling: { ...props.node.styling, ratio: columns, stack: true } })
    }

    const numberOfColumns = computed(() => nodes.value.length)
    const ratios = computed(() => props.node?.styling?.ratio || [])
    const columnsRatio = computed(() => ratios.value.filter(element => element !== 0))
    const groupColumnNodes = computed(() => nodes.value.filter((node, index) => ratios.value[index] !== 0))

    return {
      children,
      isEmpty,
      nodes,
      focusDisabled,
      setFocusDisabled,
      handleSortStart,
      handleSortEnd,
      addColumns,
      numberOfColumns,
      columnsRatio,
      groupColumnNodes,
    }
  },
}
</script>

<style lang="postcss">
.so-group-node-dragging .node-highlight,
.so-group-node-dragging .block-actions-right {
  display: none;
}

.so-slick-inner {
  @apply shadow-none;
}

.so-group-node-dragging .so-slick-inner{
  @apply z-0 transform -rotate-1 scale-105;
  @apply m-0 ring-2 bg-white bg-opacity-50 ring-blue-500 rounded-lg overflow-hidden;
  box-shadow: 0 10px 40px rgba(0,0,0, 0.10), 0 0px 60px rgba(0,0,0, 0.04);
}

.so-group-node-dragging .so-slick-inner .block-section-wrapper {
  @apply opacity-75;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
:global(.is-share-view) .node-group-columns-container {
  &:global(.is-size-L) {
    @apply px-64;
  }
}
</style>
