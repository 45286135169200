<template>
  <ModalBasic
    :title="modalTitle"
    :should-close-on-backdrop="false"
    :hide-footer="true"
    content-class="modal-content-tertiary"
    :body-class="$style['modal-body-apps']"
    :header-class="$style['modal-header-apps']"
    footer-class="modal-footer-tertiary"
    :title-class="$style['modal-title-apps']"
    size="lg"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #body="{ close }">
      <Form
        v-slot="{ state, isBusy, isInvalid, v }"
        :state="integrationData"
        :validation="validation"
        clone
        @invalid="val => $emit('invalid', val)"
        @submit="data => !(void $tracking.track('New Integration Created')) && submit(data)"
      >
        <div
          class="w-full gap-16"
          :class="[instructions
            ? 'grid grid-cols-2 auto-cols-max auto-rows-max'
            : 'flex flex-col' ]"
        >
          <!-- ------------ -->
          <!-- FORM         -->
          <!-- ------------ -->
          <div class="flex flex-col flex-1 gap-8 grow">
            <div class="flex flex-row items-center gap-8 justify-items-stretch">
              <!-- title -->
              <FormField
                :v="v.name"
                label="Name"
                required
                wrapper-class="grow"
              >
                <b-input
                  v-model="state.name"
                  placeholder="Name your integration here..."
                  class="form-control-lg"
                />
              </FormField>
            </div>
            <FormField
              label="Custom Code"
              required
              :v="v.customCode"
              :messages="{
                supported: scriptInvalidMessage,
              }"
            >
              <CodeEditor
                v-model="state.customCode"
                placeholder="Paste your custom code here..."
              />
              <!-- <b-form-textarea
                v-model.trim="state.customCode"

                class="form-control-lg"
                rows="9"
                max-rows="18"
              /> -->
            </FormField>
            <div class="mb-32 mt-8">
              <p
                class="bg-yellow-100 p-8 rounded-md text-13 leading-none text-black/70 inline"
              >
                <strong>🚨 SuperOkay doesn't validate your code.</strong>
                &nbsp;Please make sure your code is correct and test the integration.
              </p>
            </div>
            <div class="mb-32 mt-auto">
              <Button
                :disabled="isInvalid || isBusy"
                type="submit"
                size="md"
                variant="primary"
              >
                Save Integration
              </Button>
            </div>
          </div>
          <!-- ------------ -->
          <!-- INSTRUCTIONS -->
          <!-- ------------ -->
          <div
            v-if="instructions"
            class="relative ml-24 -mr-32 overflow-hidden rounded-tl-2xl rounded-br-2xl bg-blue"
          >
            <div class="px-24 pt-24 overflow-y-scroll h-395 pb-112">
              <h4
                class="inline-block px-4 mb-16 font-semibold text-blue-800 bg-blue-200 rounded
                        text-16"
              >
                About this integration
              </h4>
              <ol class="pl-16 space-y-24 list-decimal">
                <li v-for="step in instructions" :key="step.title">
                  <p class="font-semibold text-gray-900 text-16">{{ step.title }}</p>
                  <img
                    v-if="step.screenshot"
                    class="h-auto pl-16 my-8"
                    :src="step.screenshot"
                    :alt="step.title"
                  />
                  <p class="pl-16 text-gray-700 text-14">{{ step.description }}</p>

                  <a
                    v-if="step.readMore"
                    :href="step.readMore"
                    target="_blank"
                    class="font-semibold underline pl-16"
                  >{{ step.readMoreLabel || 'Read more' }}</a>
                </li>
              </ol>
            </div>
            <div class="instructions-gradient instructions-gradient-light"></div>
          </div>
        </div>
      </Form>
    </template>
  </ModalBasic>
</template>
<script>
import { nanoid } from 'nanoid'
import { defineComponent, computed } from '@vue/composition-api'
import { required, url, helpers as vuelidateHelpers } from '@vuelidate/validators'
import { clone } from 'ramda'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import Button from '@/components/Button.vue'
import CodeEditor from '@/components/CodeEditor.vue'
import Form from './Form.vue'
import FormField from './FormField.vue'

const scriptInvalidMessage = 'This doesn\'t seem to be valid HTML code'


export default defineComponent({
  name: 'ProjectSettingsIntegrationModal',
  components: {
    ModalBasic,
    Button,
    Form,
    FormField,
    CodeEditor,
  },
  props: {
    formError: {
      type: Object,
      default: null,
    },
    integrationItem: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    /** `true` if this is an edit operation for an already embedded app, `false` if this is a
     * create operation */
    const isEditOperation = computed(() => Boolean(props.integrationItem?._id))
    const modalTitle = computed(() => (isEditOperation.value ? 'Edit integration' : 'Add new integration'))
    const integrationData = computed(() => (isEditOperation.value
      ? props.integrationItem // Edit mode
      // Create mode. Assign presets
      : ({
        _id: nanoid(),
        // icon: props.integrationItem?.icon ?? 'icon_v2-so_apps',
        name: props.integrationItem?.name ?? '',
        customCode: '',
      })))
    const instructions = props.integrationItem?.instructions ?? null

    const validation = computed(() => ({
      name: {
        required,
      },
      customCode: {
        required,
        $lazy: true,
      },
    }))

    const submit = formData => {
      // let appData = { ...formData }
      // const assocField = field => assoc(field, '', appData)
      // if (embedType.value === 'embedCode') appData = assocField('url')
      // if (embedType.value === 'url') appData = assocField('embedCode')
      const data = clone(formData)

      return isEditOperation.value
        ? context.emit('update', data)
        : context.emit('create', data)
    }


    return {
      // Static
      scriptInvalidMessage,
      // State
      integrationData,
      modalTitle,
      validation,
      instructions,
      // Methods
      submit,
    }
  },
})
</script>
<style lang="postcss" module>
.modal-header-apps {
  @apply min-h-0 pt-32 pb-0 mb-0;
}
.modal-body-apps {
  @apply pt-32;
}
.modal-title-apps {
  @apply text-left;
}
</style>
