
<div
  class="flex flex-col items-center text-center relative hover:text-green-600"
  :class="{'text-green-600': active }"
>
  <i
    :class="active ? iconActive : icon"
    class="text-28"
  />
  <span class="text-10 leading-3 font-semibold">{{ label }}</span>
</div>
