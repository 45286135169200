<template>
  <ContentLibraryList
    v-slot="{ document }"
    :category="category"
    :parent-folder-id="parentFolderId"
    :title="CATEGORY_TITLES[category] | pluralize(2)"
    create-button="Create new"
    v-bind="$attrs"
    :grid="false"
    v-on="$listeners"
  >
    <FolderItemRow
      :document="document"
      size="l"
      read-only
    />
  </ContentLibraryList>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { CATEGORY as DOCUMENT_CATEGORY, CATEGORY_TO_LIST_TITLE as CATEGORY_TITLES } from '@/v2/services/documents/documentsTypes'
import ContentLibraryList from './ContentLibraryList.vue'
import FolderItemRow from '../FolderItemRow.vue'

export default defineComponent({
  name: 'ContentLibrary',
  components: {
    ContentLibraryList,
    FolderItemRow,
  },
  props: {
    category: {
      type: String,
      default: DOCUMENT_CATEGORY.Document,
    },
    parentFolderId: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      DOCUMENT_CATEGORY,
      CATEGORY_TITLES,
    }
  },
})
</script>
