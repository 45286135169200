<template>
  <Configuration v-if="isAppLoaded" v-slot="{ isPending: isConfigurationPending }">
    <div>
      <Announcement />
      <router-view v-if="!isConfigurationPending" />
      <SpinnerOverlay v-else />
    </div>
  </Configuration>
</template>
<script>
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'
import { ref, watch, computed, onMounted, defineComponent, provide } from '@vue/composition-api'
import { useNamespacedGetters } from 'vuex-composition-helpers'
import EventBus from '@/event-bus/event-bus'
import config from '@/config'
import { useMsgBoxOK, useMsgBoxError } from '@/v2/lib/composition/useMsgBox'
import { useRouter, useRoute } from '@/v2/lib/composition/useRouter'
import { useLocalStorageRef } from '@/v2/lib/composition/useLocalStorage'
import { useLoadMembers } from '@/v2/services/myMembers/compositions'
import { useUser } from '@/v2/services/users/usersCompositions'
import { debounceWithArgs } from '@/v2/lib/helpers/debounce'
import { ORGANIZATION_HOME } from '@/router/organization/type'
import { ONBOARDING_ORGANIZATION_DETAILS, ONBOARDING_BRANDING, ONBOARDING_PROJECT_TEMPLATES } from '@/router/welcome/type'
import { ONBOARDING_STATUS } from '@/v2/services/organizations/organizationsTypes'
import { GUEST_HOME } from '@/router/guest/type'
import { ROLES } from '@/v2/services/members/membersTypes'
import useNotification from '@/v2/lib/composition/useNotification'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import Announcement from '@/components/Announcement.vue'
import Configuration from '@/components/Configuration.vue'

export default defineComponent({
  name: 'AppController',
  components: {
    SpinnerOverlay,
    Announcement,
    Configuration,
  },
  setup(props, context) {
    const { $store } = context.root
    const { MyMember } = context.root.$FeathersVuex.api

    const { router, routerReplace } = useRouter()
    const { route, routeOwnMetaEq, routeMetaEq, routeMeta } = useRoute();
    const { authenticate, authenticateMember, isAuthenticated, logout } = useUser()
    const lastMember = useLocalStorageRef('lastMember')
    const { firstMember } = useNamespacedGetters('myMembers', ['firstMember'])
    const loadMembers = useLoadMembers()
    const msgBoxErr = useMsgBoxError()
    /** Should the browser's page be reloaded when user is logged out?
     * Normally this is required in order to clear the Vuex Store, however this is problematic
     * when a user has no active members and attempts to login as they would submit their
     * credentials and the page would reload, without any visible feedback. This scenario is the
     * only one which should prevent browser refresh */
    let reloadPageOnLogout = true;

    const routeRequiresAuth = _route => routeMetaEq('requiresAuth', true, _route)
    const routeRequiresGuest = _route => routeMetaEq('requiresGuest', true, _route)

    const routeCheckMemberRole = (_route, role) => {
      const requiredRoles = routeMeta('requiresRoles', _route)
      return !requiredRoles?.length || requiredRoles.includes(role);
    }

    const isAppLoaded = ref(false)

    const currentMember = computed(() => {
      if (lastMember.value) {
        return MyMember.getFromStore(lastMember.value._id) ?? firstMember.value
      }

      return firstMember.value
    })

    const redirectGuest = (reloadPage = false) => {
      if (reloadPage) {
        setTimeout(() => router.go({ name: GUEST_HOME }), 100)
        return
      }

      routerReplace({ name: GUEST_HOME })
    }

    const redirectUser = () => {
      const { organization$ } = currentMember.value
      const routeParams = { organizationId: organization$._id };

      if (organization$.onboardingStatus === ONBOARDING_STATUS.details) {
        return routerReplace({
          name: ONBOARDING_ORGANIZATION_DETAILS,
          params: routeParams,
        })
      }

      if (organization$.onboardingStatus === ONBOARDING_STATUS.branding) {
        return routerReplace({
          name: ONBOARDING_BRANDING,
          params: routeParams,
        })
      }

      if (organization$.onboardingStatus === ONBOARDING_STATUS.projectTemplate) {
        return routerReplace({
          name: ONBOARDING_PROJECT_TEMPLATES,
          params: routeParams,
        })
      }

      // redirect login, register etc pages to dashboard
      if (!routeRequiresAuth() && !routeOwnMetaEq('noRedirect', true)) {
        return routerReplace({
          name: ORGANIZATION_HOME,
          params: routeParams,
        })
      }

      if (!routeCheckMemberRole(null, currentMember.value?.role)) {
        msgBoxErr({
          title: 'Forbidden',
          message: 'Insufficient permissions',
        })

        return routerReplace({
          name: ORGANIZATION_HOME,
          params: routeParams,
        })
      }

      return null;
    }

    const loadSession = () => authenticate()
      .catch(error => {
        if (['NotAuthenticated'].includes(error.name)) {
          console.log(error.name)
        } else {
          console.error(error)
        }

        return null
      })
      .then(propOr(null, 'user'))

    const loadMembersAndAuthMember = async () => {
      const { total } = await loadMembers({ role: { $ne: ROLES.client } })

      if (total) {
        await authenticateMember(currentMember.value._id)
      }

      return Boolean(total)
    }

    const protectRoutes = () => {
      router.beforeEach((to, _from, next) => {
        if (!isAuthenticated.value && routeRequiresAuth(to)) {
          redirectGuest()
          return next(false)
        }

        if (isAuthenticated.value && routeRequiresGuest(to)) {
          redirectUser()
          return next(false)
        }

        if (!routeCheckMemberRole(to, currentMember.value?.role)) {
          redirectUser()
          return next(false)
        }

        return next()
      })
    }

    const watchUser = () => {
      watch(
        () => $store.state.auth.user,
        async user => {
          if (user) {
            if (routeOwnMetaEq('skipUserWatch', true)) {
              return
            }
            // Authenticate with (new) member. Adds member data to JWT
            const hasMembers = await loadMembersAndAuthMember()

            if (!hasMembers) {
              // No active members found. User is "locked out", most likely due to a payment plan
              // issue.
              // Inform the user that they cannot enter SuperOkay. No need to await modal result.
              msgBoxErr({
                title: 'Cannot log you in',
                message: 'Please contact the owner of your organization',
              })
              // Prevent page reload
              reloadPageOnLogout = false
              await logout() // Required in order to clear **user** (not member) jwt
              return // Do nothing else. Stay on this page
            }

            const { redirect } = route.value.query
            if (isNotNilOrEmpty(redirect)) {
              routerReplace({ path: redirect })
            } else {
              redirectUser()
            }
          } else {
            redirectGuest(reloadPageOnLogout)
            // Reset local state. User with no active members logged out
            reloadPageOnLogout = true
          }
        }
      )
    }

    const initLocale = async () => {
      const i18n = context.root.$i18n
      const currentLocale = i18n.locale
      const { default: currentMessages } = await import(`../locales/${currentLocale}.json`)
      i18n.setLocaleMessage(currentLocale, currentMessages)
    }

    const boot = async () => {
      const user = await loadSession()

      if (user && (await loadMembersAndAuthMember())) {
        await redirectUser()
      } else if (routeRequiresAuth()) {
        await redirectGuest()
      }

      protectRoutes()
      watchUser()

      await initLocale()

      isAppLoaded.value = true
    }

    const msgBoxInfo = useMsgBoxOK()
    EventBus.$on('messageInfo', debounceWithArgs(({ title, message }) => {
      msgBoxInfo({ title, message })
    }))

    const notification = useNotification()
    EventBus.$on('messageError', debounceWithArgs(
      ({ title, error }) => {
        const notificationData = config.isDevelopment || config.debug
          ? { title, message: error.message }
          : { title: 'Whoops!', message: 'Something went wrong. Please try again or contact support if the issue continues' }

        notification({
          ...notificationData,
          variant: 'danger',
        })
      }
    ))

    protectRoutes()

    onMounted(() => {
      boot()
    })

    return {
      isAppLoaded,
      currentMember,
    }
  },
})
</script>
