
<b-form @submit.prevent="formSubmit">
  <b-form-group :class="$style.group">
    <FormField
      :v="$v.email"
      label="Email Address"
      required
      :messages="{
        email: 'A valid email address is required',
        required: 'A valid email address is required',
        available: 'The email address you have entered is already registered'
      }"
    >
      <b-input
        v-model.trim="formStateEmail"
        placeholder="you@super.agency"
        type="email"
        :disabled="isEmailLocked"
        autocomplete="username"
        class="form-control-lg"
        autofocus
      />
    </FormField>
  </b-form-group>
  <b-form-group :class="$style.group">
    <div :class="$style['group-col']">
      <section>
        <FormField
          :v="$v.firstName"
          label="First Name"
          required
          :messages="{
            required: 'Please fill in your first name',
          }"
        >
          <b-input
            v-model="formState.firstName"
            placeholder="Alex"
            autocomplete="given-name"
            class="form-control-lg"
          />
        </FormField>
      </section>
      <section>
        <FormField
          :v="$v.lastName"
          label="Last Name"
          required
          :messages="{
            required: 'Please fill in your last name',
          }"
        >
          <b-input
            v-model="formState.lastName"
            placeholder="Appleseed"
            autocomplete="family-name"
            class="form-control-lg"
          />
        </FormField>
      </section>
    </div>
  </b-form-group>
  <b-form-group :class="$style.group">
    <FormField
      :v="$v.password"
      label="Password (must be at least 8 characters)"
      required
      :messages="{
        minLength: 'The password should be at least 8 characters long.”',
        required: 'Please fill in the Password field',
      }"
    >
      <FormInputPassword
        v-model="formState.password"
        placeholder="Choose your password"
      />
    </FormField>
  </b-form-group>
  <Button
    type="submit"
    size="md"
    variant="primary"
    :is-loading="isLoading"
    :block="true"
  >
    <span v-if="isLoading">Your account is being created...</span>
    <span v-else>{{ buttonLabel }}</span>
  </Button>
  <div :class="$style.note">
    By continuing, you agree to our
    <b-link href="https://www.superokay.com/terms-of-use">
      Terms Of Use
    </b-link>
  </div>
</b-form>
