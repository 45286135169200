<template>
  <div v-fragment>
    <ProjectTemplatePreviewModal
      v-if="isPreviewModalOpen"
      v-model="isPreviewModalOpen"
      :template="template"
      @use="onTemplateSelect"
      @hidden="onTemplateModalClosed"
    />
    <div class="container md:py-16">
      <FormMessage v-if="error">{{ error }}</FormMessage>
      <ProjectTemplates
        no-scroll
        @preview="onTemplatePreview"
        @create="onTemplateSelect"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useTracking } from '@/v2/services/tracking/compositions'
import { PROJECT_DASHBOARD } from '@/router/project/type'
import ProjectTemplates from '@/components/ProjectTemplates.vue'
import ProjectTemplatePreviewModal from '@/components/ProjectTemplatePreviewModal.vue';
import FormMessage from '@/components/FormMessage.vue'

const { useMutations } = createNamespacedHelpers('globalFlags')

export default defineComponent({
  name: 'ViewSignUpProjectTemplates',
  components: {
    ProjectTemplates,
    ProjectTemplatePreviewModal,
    FormMessage,
  },
  setup(props, context) {
    const organization = useCurrentOrganization()
    const { routerPush } = useRouter()
    const { track } = useTracking()
    const { setShowQuickStart } = useMutations(['setShowQuickStart'])

    const isLoading = ref(false)
    const error = ref(null)
    const template = ref(null);
    const isPreviewModalOpen = ref(false);

    const onTemplatePreview = selectedTemplate => {
      template.value = selectedTemplate
      isPreviewModalOpen.value = true
    }

    const closeTemplatePreview = () => {
      template.value = null
      isPreviewModalOpen.value = false
    }

    const onTemplateModalClosed = () => {
      template.value = null
    }

    const onTemplateSelect = async selectedTemplate => {
      if (isLoading.value) {
        return
      }

      isLoading.value = true

      try {
        await organization.value.patch({
          data: {
            onboardingProjectTemplate: selectedTemplate.project,
          },
        })

        track('Onboarding Project Template Selected', {
          template: selectedTemplate.project$.name,
        })
      } catch (err) {
        error.value = err
      } finally {
        isLoading.value = false
        closeTemplatePreview()
      }
    }

    // listen for project template creation and redirect to project's dashboard
    const { Project } = context.root.$FeathersVuex.api

    const onProjectCreated = project => {
      routerPush({
        name: PROJECT_DASHBOARD,
        params: { projectId: project._id },
      })

      setShowQuickStart(true)
    };

    Project.on('created', onProjectCreated)
    onUnmounted(() => {
      Project.off('created', onProjectCreated)
    })

    return {
      error,
      template,
      isPreviewModalOpen,
      onTemplatePreview,
      onTemplateModalClosed,
      onTemplateSelect,
    }
  },
})
</script>
