
<div v-if="isVisible" :class="$style['content-library-modal']">
  <!-- v-if="isBlock" -->
  <ContentLibraryModalBlock
    :value="isVisible"
    :title="title"
    :dialog-class="$style['modal-library-dialog']"
    :category.sync="category"
    :hide-sidebar="isSideBarHidden"
    @select="create"
    @input="onModalStateChange"
  />
</div>
