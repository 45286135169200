<template>
  <div class="flex flex-col items-center lg:justify-center height-pass">
    <CardWhiteRounded class-name="max-w-form w-full mt-48">
      <RecoverPasswordFormReset
        v-show="state === STATE.TOKEN_VALID"
        :token="token"
        @state-change="onStateChange"
      />
      <div
        v-if="state === STATE.TOKEN_ERROR || state === STATE.PASSWORD_ERROR"
        class="flex flex-col items-center justify-center text-center"
      >
        <span class="w-48 my-32 text-red-600 icon-alert text-48"></span>
        <h2 class="text-center title-h2">Whoops! Something went wrong.</h2>
        <p v-if="state === STATE.TOKEN_ERROR" class="text-gray-700">
          Your verification code is invalid.<br />Please try again.
        </p>
        <p v-else-if="state === STATE.PASSWORD_ERROR" class="text-gray-700">
          The password change was unsuccessful.<br />Please contact us.
        </p>
      </div>
      <div
        v-else-if="state === STATE.PASSWORD_CHANGED"
        class="flex flex-col items-center justify-center text-center"
      >
        <span class="w-48 my-32 text-green-600 icon_v2-so_tick text-48"></span>
        <h2 class="title-h2">Great!</h2>
        <p class="text-gray-700">
          Your password has been successfully changed.
        </p>
        <b-link
          :to="{ name: GUEST_HOME }"
          class="btn btn-default btn-md"
        >
          Login
        </b-link>
      </div>
    </cardwhiterounded>
  </div>
  </CardWhiteRounded>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { GUEST_HOME } from '@/router/guest/type'
import CardWhiteRounded from '@/components/CardWhiteRounded.vue'
import RecoverPasswordFormReset, { STATE } from '@/components/RecoverPasswordFormReset.vue'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @typedef {typeof STATE[keyof typeof STATE] | null} State
 */

export default defineComponent({
  name: 'ViewGuestRecoverPasswordReset',
  metaInfo: {
    title: 'Reset Password - SuperOkay',
  },
  components: {
    CardWhiteRounded,
    RecoverPasswordFormReset,
  },
  props: {
    token: {
      type: String,
      default: null,
      required: true,
    },
  },
  setup() {
    /** @type {Ref<State>} */
    const state = ref(null)

    /** @type {(newState: State) => void} */
    const onStateChange = newState => {
      state.value = newState
    }

    return {
      state,
      onStateChange,
      STATE,
      GUEST_HOME,
    }
  },
})
</script>
