
<b-form @submit.prevent="formSubmit">
  <!-- COMPANY NAME -->
  <b-form-group :class="$style['btn-radio-group']">
    <FormField
      :v="$v.name"
      label="Company name"
      required
      :messages="{
        required: 'Your company name is required',
      }"
      wrapper-class="mb-16"
    >
      <b-input
        v-model="formState.name"
        autofocus
        placeholder="Acme Design"
        class="form-control-lg"
      />
    </FormField>
    <FormField
      :v="$v.subdomain"
      label="Subdomain"
      :messages="{
        available: 'This subdomain is not available',
      }"
    >
      <div class="input-group">
        <b-input
          v-model="formState.subdomain"
          :disabled="Boolean(organization.subdomain)"
          placeholder="mycompany"
          autocomplete="off"
          size="lg"
        />
        <div class="input-group-append">
          <span class="font-semibold input-group-text text-14">.superokay.com</span>
        </div>
        <p class="text-12 text-gray-600 mt-8 max-w-510">
          You can later configure
          <strong>your own custom domain</strong>
          instead of .superokay.com
        </p>
      </div>
    </FormField>
  </b-form-group>
  <!-- WHAT DO YOU DO -->
  <div :class="$style['btn-radio-group']">
    <FormField
      :v="$v.kind"
      label="What do you do?"
      aside="Pick one or more"
      required
      :messages="{
        required: 'Please select one or more of the options',
      }"
    >
      <div :class="'flex flex-wrap gap-8 mt-8'">
        <b-form-checkbox-group
          v-model="formState.kind"
          :options="options.kind"
          name="radio-company-kind"
          buttons
          button-variant="form"
        />
      </div>
    </FormField>
    <FormField
      v-if="formState.kind.includes('Other')"
      class="mt-8"
      :v="$v.kindOther"
      :messages="{
        required: 'Field is required',
      }"
      wrapper-class="mb-16"
    >
      <b-input
        v-model="formState.kindOther"
        autofocus
        placeholder="Please specify your industry"
        class="form-control-lg"
      />
    </FormField>
  </div>

  <!-- TEAM SIZE -->
  <div :class="$style['btn-radio-group-team']">
    <FormField
      :v="$v.size"
      label="Team size"
      required
      :messages="{
        required: 'Please select one of the options above',
      }"
      aside="Pick one"
    >
      <div :class="'flex flex-wrap gap-8 mt-8'">
        <b-form-radio-group
          v-model="formState.size"
          :options="options.size"
          name="radio-company-kind"
          buttons
          button-variant="form"
        />
      </div>
    </FormField>
    <Button
      :is-loading="isLoading"
      type="submit"
      size="lg"
      variant="primary"
      :block="true"
    >
      <span v-if="isLoading">Your organization is being updated...</span>
      <span v-else>Continue</span>
    </Button>
  </div>
</b-form>
