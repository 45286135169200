
<DocumentNodeEditorToolbar
  :column="column"
  @close="$emit('close')"
>
  <!-- Heading Sizes -->
  <template v-if="config.stylingToolbar.features.headingSizes">
    <DocumentNodeEditorToolbarGroup label="Size">
      <DocumentNodeEditorToolbarFontSize
        :value="value.headingSize"
        :sizes="config.stylingToolbar.headingSizes"
        @input="v => update({ headingSize: v })"
      />
    </DocumentNodeEditorToolbarGroup>
    <DocumentNodeEditorToolbarSeparator v-if="separators.headingSizes" />
  </template>
  <!-- Background Color -->
  <template v-if="config.stylingToolbar.features.backgroundColor">
    <DocumentNodeEditorToolbarGroup label="Background">
      <DocumentNodeEditorToolbarColorPalette
        :value="value.backgroundColor || 'transparent'"
        :presets="colorPresets"
        :class="'mr-8'"
        placeholder="transparent"
        heading="Background Color"
        @input="v => update({ backgroundColor: v })"
      />
      <DocumentNodeEditorToolbarImage
        :value="backgroundImage"
        :opacity="value.backgroundImageOpacity"
        @input="v => update({ backgroundImage: v })"
        @update:opacity="v => update({ backgroundImageOpacity: v })"
        @upload-start="localUrl => $emit('bg-image-upload-start', localUrl)"
        @file-progress="ratio => $emit('bg-image-upload-progress', ratio)"
        @file-done="$emit('bg-image-upload-done')"
      />
    </DocumentNodeEditorToolbarGroup>
    <DocumentNodeEditorToolbarSeparator v-if="separators.backgroundColor" />
  </template>
  <!-- Text Color -->
  <template v-if="config.stylingToolbar.features.textColor">
    <DocumentNodeEditorToolbarGroup label="Color">
      <DocumentNodeEditorToolbarColorPalette
        :value="value.textColor"
        :presets="colorPresets"
        :color-variant="value.textColor ? null : textVariant"
        placeholder="auto"
        heading="Text Color"
        :class="'mr-8'"
        @input="v => update({ textColor: v })"
      />
    </DocumentNodeEditorToolbarGroup>
    <DocumentNodeEditorToolbarSeparator v-if="separators.textColor" />
  </template>
  <!-- Font Family-->
  <template v-if="config.stylingToolbar.features.fontFamily">
    <DocumentNodeEditorToolbarGroup label="Font">
      <DocumentNodeEditorToolbarFont
        :value="value.font"
        heading="Text Font"
        @input="v => update({ font: v })"
      />
    </DocumentNodeEditorToolbarGroup>
    <DocumentNodeEditorToolbarSeparator v-if="separators.fontFamily" />
  </template>
  <!-- Sizes -->
  <template v-if="config.stylingToolbar.features.sizes">
    <DocumentNodeEditorToolbarGroup label="Width">
      <DocumentNodeEditorToolbarButtonGroup
        :value="value.size"
        :options="config.stylingToolbar.sizes"
        @input="v => update({ size: v })"
      />
    </DocumentNodeEditorToolbarGroup>
    <DocumentNodeEditorToolbarSeparator v-if="separators.sizes" />
  </template>
  <!-- Text Align -->
  <template v-if="config.stylingToolbar.features.textAlign">
    <DocumentNodeEditorToolbarGroupButtons label="Align">
      <DocumentNodeEditorToolbarButton
        :is-selected="value.textAlign === 'left'"
        :class="'rounded-l'"
        @click="update({ textAlign: 'left' })"
      >
        <span class="w-24 text-24 icon-toolbar-align-left" />
      </DocumentNodeEditorToolbarButton>
      <DocumentNodeEditorToolbarButton
        :is-selected="value.textAlign === 'center'"
        @click="update({ textAlign: 'center' })"
      >
        <span class="w-24 text-24 icon-toolbar-align-center" />
      </DocumentNodeEditorToolbarButton>
      <DocumentNodeEditorToolbarButton
        :is-selected="value.textAlign === 'right'"
        :class="'rounded-r'"
        @click="update({ textAlign: 'right' })"
      >
        <span class="w-24 text-24 icon-toolbar-align-right" />
      </DocumentNodeEditorToolbarButton>
    </DocumentNodeEditorToolbarGroupButtons>
    <DocumentNodeEditorToolbarSeparator v-if="separators.textAlign" />
  </template>

  <!-- Object -->
  <template v-if="config.stylingToolbar.features.objectAlign">
    <DocumentNodeEditorToolbarGroupButtons label="Align">
      <DocumentNodeEditorToolbarButton
        :is-selected="value.objectAlign === 'left'"
        :class="'rounded-l'"
        @click="update({ objectAlign: 'left' })"
      >
        <span class="icon-toolbar-object-align-left w-24 text-24" />
      </DocumentNodeEditorToolbarButton>
      <DocumentNodeEditorToolbarButton
        :is-selected="value.objectAlign === 'center'"
        @click="update({ objectAlign: 'center' })"
      >
        <span class="icon-toolbar-object-align-center w-24 text-24" />
      </DocumentNodeEditorToolbarButton>
      <DocumentNodeEditorToolbarButton
        :is-selected="value.objectAlign === 'right'"
        :class="'rounded-r'"
        @click="update({ objectAlign: 'right' })"
      >
        <span class="icon-toolbar-object-align-right w-24 text-24" />
      </DocumentNodeEditorToolbarButton>
      <DocumentNodeEditorToolbarButton
        :is-selected="value.objectAlign === 'stretch'"
        :class="'rounded-r'"
        @click="update({ objectAlign: 'stretch', size: 'L' })"
      >
        <span class="icon-toolbar-object-align-justify w-24 text-24" />
      </DocumentNodeEditorToolbarButton>
    </DocumentNodeEditorToolbarGroupButtons>
    <DocumentNodeEditorToolbarSeparator v-if="separators.objectAlign" />
  </template>

  <!-- Vertical Alignment -->
  <template v-if="config.stylingToolbar.features.verticalAlign">
    <DocumentNodeEditorToolbarGroupButtons label="Vertical Alignment">
      <DocumentNodeEditorToolbarButton
        :is-selected="value.verticalAlign === 'start'"
        :class="'rounded-l'"
        @click="update({ verticalAlign: 'start' })"
      >
        <span class="icon-toolbar-object-valign-start w-24 text-24" />
      </DocumentNodeEditorToolbarButton>
      <DocumentNodeEditorToolbarButton
        :is-selected="value.verticalAlign === 'center'"
        @click="update({ verticalAlign: 'center' })"
      >
        <span class="icon-toolbar-object-valign-center w-24 text-24" />
      </DocumentNodeEditorToolbarButton>
      <DocumentNodeEditorToolbarButton
        :is-selected="value.verticalAlign === 'end'"
        :class="'rounded-r'"
        @click="update({ verticalAlign: 'end' })"
      >
        <span class="icon-toolbar-object-valign-end w-24 text-24" />
      </DocumentNodeEditorToolbarButton>
    </DocumentNodeEditorToolbarGroupButtons>
    <DocumentNodeEditorToolbarSeparator v-if="separators.verticalAlign" />
  </template>

  <!-- Spacing -->
  <template v-if="config.stylingToolbar.features.spacing">
    <DocumentNodeEditorToolbarGroup label="Spacing">
      <DocumentNodeEditorToolbarSpacing
        :padding-top="value.paddingTop"
        :padding-bottom="value.paddingBottom"
        :margin-top="value.marginTop"
        :margin-bottom="value.marginBottom"
        :class="nodeId"
        @change="(k, v) => update({ [k]: v })"
      />
    </DocumentNodeEditorToolbarGroup>
    <DocumentNodeEditorToolbarSeparator v-if="separators.spacing" />
  </template>

  <!-- Node Settings -->
  <template v-if="config.stylingToolbar.features.settings">
    <DocumentNodeEditorToolbarGroup label="Settings">
      <DocumentNodeEditorToolbarSettings
        v-if="config.stylingToolbar.features.settings"
        :popover-target-id="`settings-popover-${nodeId}`"
      >
        <portal-target
          :name="`node-settings-${nodeId}`"
          slim
          :slot-props="{ value, update }"
        />
      </DocumentNodeEditorToolbarSettings>
    </DocumentNodeEditorToolbarGroup>
    <DocumentNodeEditorToolbarSeparator v-if="separators.settings" />
  </template>

  <!-- Settings for content blocks (QA, upload, cost breakdown)-->
  <portal-target :name="`content-block-settings-${nodeId}`" slim />

  <!-- <DocumentNodeEditorToolbarSeparator />
    <DocumentNodeEditorToolbarMenu
      :disable-menu="!config.menu.enabled"
      toggle-class="w-32"
      dropdown-class="editor-toolbar-button"
    >
      <template #button>
        <span class="w-24 text-24 icon_v2-so_menu-circle-vertical"></span>
      </template>
      <template #default>
        <DocumentNodeEditorToolbarMenuItem
          v-if="config.menu.duplicate"
          icon="org-duplicate"
          label="Duplicate block"
          @select="$emit('duplicate')"
        />
      </template>
    </DocumentNodeEditorToolbarMenu> -->
</DocumentNodeEditorToolbar>
