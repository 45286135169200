
<li
  v-if="value"
  class="module"
  :class="{
    'border-green-500 hover:border-green-600': value.enabled,
    'hover:border-gray-300': !value.enabled
  }"
>
  <div class="border-b dark:border-b-darkGray-900 p-12 flex items-center justify-between">
    <h2 class="font-semibold text-14 flex items-center gap-4">
      <span class="icon_v2-so_modules text-18" />
      {{ moduleName }}
    </h2>
    <span
      v-if="value.enabledPortal"
      v-b-tooltip.hover.top.v-info.dh0.ds200="'This module is currently visible on the portal'"
      class="icon_v2-so_eye text-green-500 text-20"
    />
  </div>
  <div class="p-12 text-14">
    <div class="grid grid-cols-2 gap-8">
      <!-- toggles -->
      <div class="flex flex-col justify-center">
        <div v-if="features.enabled">
          <b-form-checkbox
            :checked="value.enabled"
            name="check-button"
            switch
            size="sm"
            class="flex items-center"
            @change="val => update('enabled', val)"
          >
            Your team
          </b-form-checkbox>
        </div>
        <div v-if="features.enabledPortal">
          <b-form-checkbox
            :checked="value.enabledPortal"
            :disabled="!value.enabled"
            name="check-button"
            switch
            size="sm"
            class="flex items-center"
            :class="{ 'opacity-40': !value.enabled }"
            @change="val => update('enabledPortal', val)"
          >
            Client
          </b-form-checkbox>
        </div>
      </div>

      <!-- form -->
      <div class="form-grid">
        <template v-if="features.icon">
          <label>Icon</label>
          <div class="flex items-center gap-4">
            <DropdownIcons
              size="sm"
              :current-icon="value.icon || defaultIcon"
              @pick-icon="val => update('icon', val)"
            />
            <ButtonClear @click="update('icon', defaultIcon)" />
          </div>
        </template>

        <template v-if="features.title">
          <label>Custom Title</label>
          <b-input
            :value="value.title"
            :placeholder="moduleName"
            @input="val => update('title', val)"
          />
        </template>
      </div>
    </div>
  </div>
  <div v-if="$slots.description" class="p-12 text-12 bg-gray-200 border-t">
    <slot name="description" />
  </div>
</li>
