<template>
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="isEmpty"
    :root-class="[
      'screen:border screen:border-dashed screen:border-gray-200',
    ]"
    :content-class="`flex flex-col justify-${node.styling.verticalAlign}`"
    :inner-class="'px-16 lg:px-24 print:px-16 print:h-full print:flex'"
    :config-override="{
      stylingToolbar: {
        features: {
          sizes: false,
          textColor: false,
          verticalAlign: true
        },
      },
      styling: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      block: {
        enableBlockLabel: false,
        enableCreateBefore: false,
      },
      menu: {
        enabled: false
      }
    }"
  >
    <template #default="{isHover}">
      <div
        :class="[
          'h-64 flex flex-row items-center px-12',
          'absolute top-0 left-0 right-0 lg:-left-24 lg:-right-24',
          'space-x-16',
          isHover ? 'opacity-100' : 'opacity-25',
          'print:hidden'
        ]"
      >
        <div
          class="block-actions-move"
        >
          <div
            class="block-actions-move-button"
          >
            <button
              v-handle
              v-b-tooltip.hover.top.v-info.dh0.ds200
              :class="['w-32 h-32 flex items-center justify-center',
                       'outline-none focus:outline-none rounded-lg',
                       'hover:bg-gray-800 hover:text-white',
              ]"
              title="Drag to move"
              @click.stop
            >
              <span class="icon_v2-so_drag so-drag-icon" />
            </button>
          </div>
        </div>

        <DocumentBlockButtonCreate
          :parent-node-id="node._id"
          :index="children.length"
          :accepts="accepts"
          no-content-library
          mode="child"
          :class="['grow', $style['create']]"
        />

        <!-- Settings  -->
        <Button
          :class="['w-32 h-32 flex items-center justify-center',
                   'outline-none focus:outline-none rounded-lg',
                   'hover:bg-gray-800 hover:text-white',
          ]"
          @click.stop="setFocusOnColumn"
        >
          <span class="w-24 text-24 icon-toolbar-settings" />
        </Button>
      </div>
      <div
        v-if="!isEmpty"
        :class="['mt-64 print:mt-0', ]"
      >
        <SlickList
          v-model="nodes"
          lock-axis="y"
          use-drag-handle
          helper-class="so-group-node-dragging"
          class="p-0"
          lock-to-container-edges
          @sort-start="handleSortStart"
          @sort-end="handleSortEnd"
        >
          <SlickItem
            v-for="(nodeId, index) in nodes"
            :key="nodeId"
            :index="index"
          >
            <DocumentNodeEditable
              :index="index"
              :node-id="nodeId"
              :parent="node._id"
              :is-first="index === 0"
              :is-last="index === children.length - 1"
              class="so-slick-inner"
            />
          </SlickItem>
        </SlickList>
      </div>
      <div v-else class="mx-2 mt-64 mb-16">
        <DocumentNodeGroupColumnPlaceholder
          :node-id="node._id"
        />
      </div>
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import { computed } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'
import { CATEGORY as CONTENT_BLOCK_CATEGORY } from '@/v2/services/contentBlocks/contentBlocksTypes'
import useNestedComponents from '@/v2/lib/composition/useNestedComponents'
import { useStructureBranch, useStructureReorderChildren } from '@/v2/services/documentStructures/documentStructuresCompositions'
import DocumentBlockButtonCreate from '@/components/Document/DocumentBlockButtonCreate.vue'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentNodeEditable from '@/v2/features/document/documentNode/DocumentNodeEditable.vue'
import { changeCursorStyle } from '@/helpers/dom'
import DocumentNodeGroupColumnPlaceholder from './DocumentNodeGroupColumnPlaceholder.vue'


const { useMutations, useState } = createNamespacedHelpers('documentEditor')


const ACCEPTS = [
  CONTENT_BLOCK_CATEGORY.ElementHeading,
  CONTENT_BLOCK_CATEGORY.ElementText,
  CONTENT_BLOCK_CATEGORY.ElementImage,
  CONTENT_BLOCK_CATEGORY.ElementBrief,
  CONTENT_BLOCK_CATEGORY.ElementEstimate,
  CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  CONTENT_BLOCK_CATEGORY.ElementApproval,
  CONTENT_BLOCK_CATEGORY.ElementQA,
  CONTENT_BLOCK_CATEGORY.ElementEmbed,
  CONTENT_BLOCK_CATEGORY.ElementButton,
  // CONTENT_BLOCK_CATEGORY.ElementAttachments,
]


export default {
  name: 'DocumentNodeGroupColumnEditable',
  directives: { handle: HandleDirective },
  components: {
    DocumentNodeRenderer,
    DocumentNodeGroupColumnPlaceholder,
    SlickList,
    SlickItem,
    DocumentBlockButtonCreate,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    useNestedComponents({ DocumentNodeEditable })
    const structureBranch = useStructureBranch()
    const structureReorderChildren = useStructureReorderChildren()

    const { focusDisabled } = useState(['focusDisabled'])
    const { setFocusDisabled, setFocus } = useMutations(['setFocusDisabled', 'setFocus'])
    const { children, isEmpty } = structureBranch(props.node._id)


    const nodes = computed({
      set: newOrder => structureReorderChildren({ parent: props.node._id, children: newOrder }),
      get: () => children.value,
    })


    const handleSortStart = () => {
      setFocus(null)
      setFocusDisabled(true)
      changeCursorStyle('grabbing')
    }

    const handleSortEnd = () => {
      setFocusDisabled(false)
      changeCursorStyle('')
    }

    const setFocusOnColumn = () => setFocus(props.node._id)


    return {
      children,
      isEmpty,
      nodes,
      focusDisabled,
      setFocusDisabled,
      handleSortStart,
      handleSortEnd,
      accepts: ACCEPTS,
      setFocusOnColumn,
    }
  },
}
</script>

<style lang="postcss">
.so-group-node-dragging .node-highlight,
.so-group-node-dragging .block-actions-right {
  display: none;
}

.so-slick-inner {
  @apply h-auto shadow-none;
}

.so-group-node-dragging .so-slick-inner{
  @apply z-0 transform -rotate-1 scale-105;
  @apply m-0 ring-2 bg-white bg-opacity-50 ring-blue-500 rounded-lg overflow-hidden;
  box-shadow: 0 10px 40px rgba(0,0,0, 0.10), 0 0px 60px rgba(0,0,0, 0.04);
}

.so-group-node-dragging .so-slick-inner .block-section-wrapper {
  @apply opacity-75;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
</style>
<style lang="postcss" module>
.create :global(.btn) {
  @apply justify-start;
}
</style>
