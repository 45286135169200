<template>
  <div>
    <template v-if="!isPending">
      <!-- Header -->
      <slot
        name="header"
        v-bind="{
          hasNotifications,
          hasUnreadNotifications: Boolean(unreadNotifications.length),
          isPendingMarkAllAsRead, markAllAsRead
        }"
      />

      <!-- Content -->
      <section
        v-if="hasNotifications"
        class="space-y-16"
      >
        <!-- UNREAD -->
        <section v-if="unreadNotifications.length" class="space-y-8">
          <NotificationList
            item-class="
              hover:bg-yellow-10 group rounded-lg transition-all
              dark:bg-opacity-10
            "
            :notifications="unreadNotifications"
            :show-project="showProject"
            :show-document="showDocument"
            show-close
            show-bullet
            show-mark-as-read
            @mark-as-read="markAsRead"
          />
          <div>
            <Button
              v-if="unreadHasMore"
              size="md"
              :is-loading="unreadPending"
              variant="primary"
              @click="unreadLoadMore"
            >
              Load more notifications
            </Button>
          </div>
        </section>

        <!-- READ -->
        <section
          v-show="readNotifications.length"
          class="space-y-8"
        >
          <span class="uppercase text-12 font-semibold text-gray-600 inline-block">previous</span>
          <NotificationList
            item-class="
              rounded-lg transition-all
              hover:bg-gray-400 hover:bg-opacity-20
              dark:hover:bg-darkGray-700
              -ml-8 -mr-8 p-8
            "
            :notifications="readNotifications"
            :slim="slim"
            :show-project="showProject"
            :show-document="showDocument"
            :show-close="false"
            :show-bullet="false"
          />
          <div>
            <Button
              v-if="readHasMore"
              size="md"
              :is-loading="readPending"
              variant="neutral"
              @click="readLoadMore"
            >
              Load older notifications
            </Button>
          </div>
        </section>
      </section>

      <!-- No Notifications -->
      <MessageBox v-else icon="icon_v2-so_info">
        No activity to display yet.
      </MessageBox>
    </template>
    <div v-else class="flex items-center justify-center w-full h-96">
      <Spinner
        :size="48"
        variant="dark"
        class="ml-8"
      />
    </div>
  </div>
</template>

<script>
import { rejectNil } from 'ramda-extension'
import { computed, defineComponent } from '@vue/composition-api'
import useNotification from '@/v2/lib/composition/useNotification'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import { useInbox, useInboxActions } from '@/v2/services/notifications/compositions'
import Spinner from '@/components/Spinner.vue'
import Button from '@/components/Button.vue'
import MessageBox from '@/components/MessageBox.vue'
import NotificationList from './NotificationList.vue'

export default defineComponent({
  name: 'NotificationsInbox',
  components: {
    Spinner,
    NotificationList,
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    MessageBox,
  },
  props: {
    project: {
      type: String,
      default: null,
    },
    document: {
      type: String,
      default: null,
    },
    showDocument: {
      type: Boolean,
      default: true,
    },
    slim: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 15,
    },
  },
  setup(props) {
    const notification = useNotification()
    const organization = useCurrentOrganization()

    const query = computed(() => rejectNil({
      organization: organization.value._id,
      project: props.project,
      document: props.document,
    }))

    // -- unread notifications
    const unreadQuery = computed(() => ({
      ...query.value,
      read: false,
    }))

    const {
      items: unreadNotifications,
      hasMore: unreadHasMore,
      loadMore: unreadLoadMore,
      isPending: unreadPending,
      haveLoaded: unreadHaveLoaded,
    } = useInbox({
      query: unreadQuery,
      pageSize: props.pageSize,
      queryId: 'inboxUnread',
    })

    // -- read notifications
    const readQuery = computed(() => ({
      ...query.value,
      read: true,
    }))

    const {
      items: readNotifications,
      hasMore: readHasMore,
      loadMore: readLoadMore,
      isPending: readPending,
      haveLoaded: readHaveLoaded,
    } = useInbox({
      query: readQuery,
      pageSize: props.pageSize,
      queryId: 'inboxRead',
    })

    const hasNotifications = computed(
      () => unreadNotifications.value.length || readNotifications.value.length
    )
    const showProject = computed(() => (!props.document && !props.project))
    const isPending = computed(() => !unreadHaveLoaded.value || !readHaveLoaded.value);

    const {
      markAsRead,
      markAllAsRead,
      isPendingMarkAllAsRead,
    } = useInboxActions()

    const _markAsRead = async ({ _id: notificationId }) => {
      try {
        markAsRead(notificationId)
      } catch (err) {
        notification({
          message: 'Unable to mark notification as read.',
          variant: 'danger',
        })
      }
    }

    const _markAllAsRead = async () => {
      try {
        await markAllAsRead(query.value);
        notification({
          message: 'Marked all notifications as read',
        })
      } catch (err) {
        notification({
          message: 'Unable to mark notification as read.',
          variant: 'danger',
        })
      }
    }

    return {
      organization,
      showProject,
      isPending,

      isPendingMarkAllAsRead,
      markAsRead: _markAsRead,
      markAllAsRead: _markAllAsRead,

      // unread
      unreadNotifications,
      unreadHasMore,
      unreadLoadMore,
      unreadPending,

      // read
      readNotifications,
      readHasMore,
      readLoadMore,
      readPending,

      hasNotifications,
    }
  },
})
</script>
