
<div class="mb-16">
  <h3 class="settings-section-title">
    {{ title }}
    <slot name="button" />
  </h3>
  <p class="settings-section-description" :class="{ 'text-orange-500': warn }">
    {{ info }}
  </p>
</div>
