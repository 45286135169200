
<div>
  <PageHeader
    :title="
      !client ? 'New Client Organization' : 'Edit Client Organization'
    "
    :show-back="Boolean(client)"
    @back="redirect(client?._id)"
  />
  <ClientEditor
    :organization="organization"
    :client-id="client?._id ?? 'new'"
    @saved="redirect"
    @removed="redirect"
  />
</div>
