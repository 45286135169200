<template>
  <div>
    <PageHeader title="Custom Subdomain &amp; Domain" />
    <OrganizationSettingsSubdomain
      :organization="organization"
      :licensing="licensing"
    />

    <OrganizationSettingsDomain
      :organization="organization"
      :licensing="licensing"
    />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import PageHeader from '@/components/PageHeader.vue'
import OrganizationSettingsSubdomain from '@/components/OrganizationSettingsSubdomain.vue'
import OrganizationSettingsDomain from '@/components/OrganizationSettingsDomain.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'ViewOrganizationSettingsCustomDomain',
  metaInfo: {
    title: 'Custom Domain',
  },
  components: {
    PageHeader,
    OrganizationSettingsSubdomain,
    OrganizationSettingsDomain,
    Button,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    licensing: {
      type: Object,
      required: true,
    },
  },
})
</script>
