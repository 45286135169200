<template>
  <div class="py-32 border-t border-gray-200 dark:border-darkGray-800 mt-80">
    <div class="flex flex-col items-start justify-start">
      <div class="text-gray-700/70  dark:text-darkGray-600"><slot name="icon" /></div>
      <div class="max-w-page">
        <h2
          class="mt-8 font-medium leading-normal
         text-gray-700 dark:text-darkGray-400 text-16 mb-8"
        >
          <slot name="title" />
        </h2>
        <div class="inline-flex items-center text-gray-700/70 dark:text-darkGray-400 text-14">
          <slot name="description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InfoPanel',

  setup() { },
}
</script>
