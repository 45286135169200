<template>
  <div class="dark min-h-full flex flex-col">
    <img :src="`${publicPath}assets/img/whatsnew/new-portal.jpg`" class="flex-none" />
    <div :class="['prose prose-sm prose-violet p-32 dark:text-darkGray-300 mt-auto']">
      <h2 class="dark:text-yellow-400">
        🎉 Finally, a client portal you’ll simply know how to use.
      </h2>
      <p>Project manage your clients via a simple intuitive portal.
Look professional, the lean way.</p>
      <b-button
        variant="dark"
        size="md"
        href="https://superokay.com/progress-updates/"
        target="_blank"
        class="no-underline"
      >
        🎁 See what's new in SuperOkay
      </b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */

export default {
  name: 'WhatsNewPreview',
  setup() {
    return {
      publicPath: import.meta.env.BASE_URL,
    }
  },
}
</script>
