
<transition name="slide-fade">
  <div
    v-show="selectedNodes.length"
    id="multiple-selection-toolbar"
    :class="'fixed bottom-0 pb-16 left-40 tablet:left-256 2xl:left-288 text-white '
      + 'flex-row right-0 flex justify-center pointer-events-none z-1000'"
  >
    <div
      :class="'pointer-events-auto bg-gray-800 rounded-lg shadow-lg '
        +'p-8 flex w-320 justify-between relative z-1 '"
    >
      <transition name="bounce" mode="out-in">
        <div
          :key="selectedNodes.length"
          class="flex items-center px-12 font-medium text-white bg-gray-800 rounded-md text-14"
        >
          {{ 'block' | translate | pluralize(selectedNodes.length, true) }} selected
        </div>
      </transition>
      <b-dropdown
        dropup
        variant="primary"
        toggle-class="text-decoration-none"
        no-caret
        right
      >
        <template #button-content>
          Actions <span class="ml-4 -mr-4 icon-util-arrows-select"></span>
        </template>
        <b-dropdown-item @click="saveAsReusableBlock">
          <span class="inline-block w-16 mr-8 cm-icon icon-util-copy text-16"></span>
          <span>Save to reusable block</span>
        </b-dropdown-item>
        <b-dropdown-item :disabled="!isRoot" @click="group">
          <span class="inline-block w-16 mr-8 cm-icon icon-object-group text-16"></span>
          Group selection
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item @click="selectAllNodes">
          <span class="inline-block w-16 mr-8 cm-icon icon_v2-so_action-items text-16"></span>
          <span>Select all</span>
        </b-dropdown-item>
        <b-dropdown-item @click="clearNodeSelection">
          <span class="inline-block w-16 mr-8 cm-icon icon-util-deselect-all text-16"></span>
          <span>Clear selection</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item @click="onCutSelectedNodes">
          <span class="inline-block w-16 mr-8 cm-icon icon-util-cut text-16"></span>
          <span>
            Cut {{ 'block' | translate | pluralize(selectedNodes.length, true) }}
          </span>
        </b-dropdown-item>
        <b-dropdown-item @click="onCopySelectedNodes">
          <span class="inline-block w-16 mr-8 cm-icon icon-util-copy text-16"></span>
          <span>Copy {{ 'block' | translate | pluralize(selectedNodes.length, true) }}</span>
        </b-dropdown-item>
        <b-dropdown-item @click="onRemoveSelectedNodes">
          <span class="inline-block w-16 mr-8 cm-icon icon_v2-so_trash text-16"></span>
          <span>Delete {{ 'block' | translate | pluralize(selectedNodes.length, true) }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <b-tooltip
      target="multiple-selection-toolbar"
      placement="top"
      variant="success"
      triggers="manual"
      :show.sync="showTooltip"
    >
      {{ tooltipMessage }}
    </b-tooltip>
    <div class="selection-toolbar"></div>
  </div>
</transition>
