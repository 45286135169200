import { parse_host as parseHost } from 'tld-extract'
import tinycolor from 'tinycolor2'

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length == 1 ? `0${hex}` : hex;
}

export function rgbToHex(r, g, b) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

// Extract full domain from an email address
// Return an object with domain, tld, sub
export function domainFromEmail(email) {
  if (!email || typeof (email) !== 'string') {
    return {
      domain: 'superokay.com',
      tld: '.com',
    }
  }

  try {
    const domain = (email ?? '').split('@', 2).pop();
    if (!domain.includes('.')) {
      return null;
    }
    return parseHost(domain);
  } catch (err) {
    return null;
  }
}

// Extract root domain from
export function rootDomainFromEmail(email) {
  const domain = domainFromEmail(email)
  const rootDomain = domain.domain.replace(`.${domain.tld}`, '')
  return rootDomain
}

// Construct URL from email address
export function websiteFromEmail(email) {
  return `https://${domainFromEmail(email).domain}`
}

// Get most readable foreground color for any background color
export function mostReadableForeground(color) {
  const accentSpace = tinycolor(color).monochromatic(3)
  return tinycolor.mostReadable(
    color,
    [
      ...accentSpace,
      '#FFFFFF',
    ],
    {
      level: 'AA',
      size: 'large',
    }
  ).toHexString()
}
