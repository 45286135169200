<template>
  <div :style="cssVars">
    <PageHeader title="Pages">
      <template #actions>
        <Button
          v-track="['Pages Modal Opened']"
          type="button"
          variant="neutral"
          size="md"
          @click="addPages"
        >
          <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600" />
          Add From Library
        </Button>
      </template>
    </PageHeader>
    <InfoBox
      title="Attach pages from your library to this project."
      icon="icon_v2-so_docs-files"
    >
      Add Pages from your Library to this Project.
      The selected Pages will appear in your client’s sidebar menu.
    </InfoBox>
    <Form
      v-if="state"
      v-slot="{ isBusy, submit, hasChanges }"
      :state="state"
      success-message="Project changes saved"
      :on-submit="() => save()"
    >
      <div class="flex flex-col max-w-page">
        <!-- Packaged Services -->
        <b-form-group>
          <SlickList
            :value="pages"
            use-drag-handle
            lock-to-container-edges
            axis="xy"
            helper-class="so-table-row-dragging"
            @input="reorder"
          >
            <SlickItem
              v-for="(page, index) in pages"
              :key="page._id"
              :index="index"
              class="flex flex-col p-0 overflow-visible"
            >
              <ProjectSettingsPageItem
                :document="page"
                @remove="remove(page._id)"
                @edit="edit(page._id)"
              />
            </SlickItem>
          </SlickList>
        </b-form-group>
      </div>
      <PageFooter>
        <Button
          :disabled="isBusy"
          size="lg"
          variant="primary"
          type="submit"
          @click="submit"
        >
          Save Changes
        </Button>
        <template v-if="hasChanges" #right>
          <PageFooterMessage />
        </template>
      </PageFooter>
    </Form>
    <SelectDocumentsModal
      v-if="isModalOpen"
      v-model="isModalOpen"
      :selection.sync="selection"
      title="Add pages from your library"
      button-label="Page"
      :documents="documents"
      hide-cost
      @done="updatePages"
    />
  </div>
</template>
<script>
import { indexBy, pluck } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import Vue from 'vue'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { SlickList, SlickItem } from 'vue-slicksort'
import { useFind } from 'feathers-vuex'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import {
  ORGANIZATION_LIBRARY_PAGE,
  ORGANIZATION_LIBRARY_PAGES,
} from '@/router/organization/type'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useMsgBoxConfirm } from '@/v2/lib/composition/useMsgBox'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'
import PageFooter from '@/components/PageFooter.vue'
import Form from '@/components/Form.vue'
import Button from '@/components/Button.vue'
import PageHeader from '@/components/PageHeader.vue'
import SelectDocumentsModal from '@/components/SelectDocumentsModal.vue'
import InfoBox from '@/components/InfoBox.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'
import ProjectSettingsPageItem from '@/components/ProjectSettingsPageItem.vue'


export default defineComponent({
  name: 'ViewProjectSettingsPages',
  components: {
    PageHeader,
    PageFooter,
    Form,
    Button,
    SlickList,
    SlickItem,
    SelectDocumentsModal,
    InfoBox,
    PageFooterMessage,
    ProjectSettingsPageItem,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { routerPush } = useRouter()
    const msgBoxConfirm = useMsgBoxConfirm()

    const {
      state,
      save,
    } = useFormEntityEditor({
      model: 'Project',
      id: computed(() => props.project._id),
      fields: computed(() => ['pages']),
    })

    // CSS VARS NEEDED FOR STYLING THE PREVIEW
    const cssVars = computed(() => ({
      '--cp-accent': state.value.portalAccentColor,
    }))

    const { Document } = Vue.$FeathersVuex.api

    const findParams = computed(() => ({
      query: {
        organization: props.organization._id,
        category: DOCUMENT_CATEGORY.DocumentPage,
        $sort: { title: 1 },
      },
    }))

    const {
      items: documents,
    } = useFind({
      model: Document,
      params: findParams,
    })

    const documentsIndexed = computed(
      () => indexBy(doc => doc._id, documents.value)
    )

    const pages = computed(
      () => (state.value.pages ?? [])
        .map(docId => documentsIndexed.value[docId])
        .filter(isNotNil)
    )

    const showNoPagesDialog = async () => {
      const ok = await msgBoxConfirm({
        title: 'No Pages',
        size: 'md',
        message: 'No Pages found in your library. Would you like to create one?',
        okLabel: 'Go to Pages',
        okVariant: 'tertiary',
        cancelLabel: 'Close',
      })

      if (ok) {
        routerPush({
          name: ORGANIZATION_LIBRARY_PAGES,
        })
      }
    }

    const isModalOpen = ref(false)
    const selection = ref([])

    const openModal = () => {
      selection.value = [...state.value.pages ?? []]
      isModalOpen.value = true
    }

    const addPages = () => {
      if (!documents.value.length) {
        showNoPagesDialog()
        return
      }

      openModal()
    }

    const updatePages = () => {
      state.value.pages = selection.value
      isModalOpen.value = false
      selection.value = []
    }

    const edit = id => {
      routerPush({
        name: ORGANIZATION_LIBRARY_PAGE,
        params: {
          documentId: id,
        },
      })
    }

    const remove = id => {
      const index = state.value.pages.findIndex(pageId => pageId === id)
      state.value.pages.splice(index, 1)
    }

    const reorder = items => {
      state.value.pages = pluck('_id', items)
    }

    return {
      state,
      save,
      remove,
      reorder,
      isModalOpen,
      cssVars,
      edit,
      documents,
      pages,
      addPages,
      updatePages,
      selection,
    }
  },
})
</script>
