<template>
  <DocumentGet
    :id="documentId"
    v-slot="{ document, isGetPending: isDocumentPending }"
    :params="{ embed: true }"
    force
  >
    <template v-if="!isDocumentPending">
      <OrganizationGet
        v-if="document"
        :id="document.organization"
        v-slot="{ organization, isGetPending: isOrganizationPending }"
      >
        <ProjectGet
          :id="document.project"
          v-slot="{
            project: projectFromDocument,
            isGetPending: isProjectGetPending,
          }"
        >
          <DocumentTheme
            :organization="organization"
            :project="projectFromDocument"
            :document="document"
          >
            <DocumentViewerClientPortal
              v-if="!isOrganizationPending && !isProjectGetPending"
              :document="document"
              :organization="organization"
              :is-client-portal="false"
              :project="projectFromDocument"
              print
            />
          </DocumentTheme>
        </ProjectGet>
      </OrganizationGet>
      <LayoutGuest v-else :banner="false">
        <b-alert show variant="warning">
          <h4 class="alert-heading">Document not found</h4>
          <p>Invalid sharing link or link no longer available.</p>
        </b-alert>
      </LayoutGuest>
    </template>
  </DocumentGet>
</template>

<script>
import { noop } from 'ramda-adjunct'
import { defineComponent, provide } from '@vue/composition-api'
import OrganizationGet from '@/components/OrganizationGet.vue'
import DocumentGet from '@/components/DocumentGet.vue'
import LayoutGuest from '@/views/LayoutGuest.vue'
import DocumentViewerClientPortal from '@/components/Document/Viewer/DocumentViewerClientPortal.vue'
import ProjectGet from '@/components/ProjectGet.vue'
import DocumentTheme from '@/components/DocumentTheme.vue'

export default defineComponent({
  name: 'ViewGuestPrintDocument',
  components: {
    OrganizationGet,
    DocumentGet,
    LayoutGuest,
    DocumentViewerClientPortal,
    ProjectGet,
    DocumentTheme,
  },
  props: {
    documentId: {
      type: String,
      required: true,
    },
  },
  setup() {
    provide('scrollTo', noop)
  },
})
</script>
