<template>
  <LoaderWebFont
    :families="[
      branding.brandingFontHeading || 'Inter',
      branding.brandingFontParagraph || 'Inter'
    ]"
  >
    <div :style="theme">
      <div
        class="
          overflow-hidden border border-gray-200 rounded-md
          shadow-xl dark:border-darkGray-700 page-background
        "
        :class="className"
      >
        <!-- Header -->
        <div :class="branding.portalHeaderTheme">
          <div class="bg-[color:var(--cp-header-bg)] pb-56 px-32 pt-24 relative">
            <div class="header-background-image"></div>

            <!-- Greeting -->
            <p
              class="
                relative z-20 mb-16 font-bold text-center
                text-20 portal-heading-font portal-link-foreground
              "
            >
              Good morning
            </p>

            <!-- Quick Links -->
            <div class="flex items-center justify-center">
              <ul class="relative z-20 flex flex-row items-center justify-center space-x-32 p-16">
                <li
                  v-for="index in [0, 1, 2, 3]"
                  :key="index"
                  class="flex flex-col items-center justify-center space-y-4"
                >
                  <div
                    class="
                      flex items-center justify-center w-24 h-24
                      rounded-md portal-accent-background
                    "
                  >
                    <span
                      class="
                      icon-util-star-full text-12
                      text-[color:var(--cp-accent-most-readable)]"
                    />
                  </div>
                  <span class="portal-link-foreground text-10">{{ `Link ${index + 1}` }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Apps -->
        <div :class="branding.portalTheme">
          <div class="p-32 page-background">
            <div class="relative grid grid-cols-2 gap-8 -mb-56 transform -translate-y-56">
              <!-- ---- -->
              <!-- DOCS -->
              <!-- ---- -->
              <div class="flex flex-col items-center p-8 pt-16 pb-0 rounded shadow card">
                <div class="flex flex-row items-center mb-12 space-x-4 leading-none">
                  <span class="icon_v2-so_docs-files text-12 portal-accent-foreground"></span>
                  <span
                    :class="['font-semibold text-gray-700 text-11',
                             'dark:text-darkGray-300', 'portal-paragraph-font']"
                  >Docs</span>
                </div>
                <!-- card shadow Content -->
                <div class="grid w-full grid-cols-2 gap-8 px-8 mt-8 overflow-hidden h-96">
                  <span
                    v-for="index in [0, 1, 2, 3]"
                    :key="index"
                    class="flex h-56 bg-gray-100 rounded dark:bg-darkGray-800"
                  ></span>
                </div>
              </div>

              <!-- ---- -->
              <!-- ACTION ITEMS -->
              <!-- ---- -->
              <div class="flex flex-col items-center p-8 pt-16 pb-0 rounded shadow card">
                <div class="flex flex-row items-center mb-12 space-x-4 leading-none">
                  <span class="icon_v2-so_action-items text-12 portal-accent-foreground"></span>
                  <span
                    class="
                      font-semibold text-gray-700 text-11
                      dark:text-darkGray-300 portal-paragraph-font
                    "
                  >Tasks</span>
                </div>

                <!-- card shadow Content -->
                <ul class="flex flex-col w-full px-8 mt-8 space-y-6 overflow-hidden h-96">
                  <li
                    v-for="index in [0, 1, 2, 3, 4, 5, 6, 7]"
                    :key="index"
                    class="flex flex-row items-center w-full space-x-2"
                  >
                    <i class="block w-6 h-6 bg-gray-100 rounded dark:bg-darkGray-800"></i>
                    <p class="grow">
                      <span class="block w-56 h-6 bg-gray-100 rounded-full dark:bg-darkGray-800" />
                    </p>
                    <i class="w-16 h-6 mr-auto bg-gray-100 rounded-full dark:bg-darkGray-800"></i>
                  </li>
                </ul>
              </div>
              <!-- ---- -->
              <!-- Knowledge Base -->
              <!-- ---- -->
              <div class="flex flex-col items-center p-8 pt-16 pb-0 rounded shadow card">
                <div class="flex flex-row items-center mb-12 space-x-4 leading-none">
                  <span class="icon-document-knowledge-base text-12 portal-accent-foreground" />
                  <span
                    class="
                      font-semibold text-gray-700 text-11
                      dark:text-darkGray-300 portal-paragraph-font
                    "
                  >Knowledge Base</span>
                </div>
                <!-- card shadow Content -->
                <div class="grid w-full grid-cols-1 gap-8 px-8 mt-8 overflow-hidden h-96">
                  <span
                    v-for="index in [0, 1, 2]"
                    :key="index"
                    class="flex h-32 bg-gray-100 rounded dark:bg-darkGray-800"
                  />
                </div>
              </div>
              <div class="grid grid-cols-2 gap-8">
                <div
                  v-for="app in ['Figma', 'Airtable', 'Drive']"
                  :key="app"
                  class="p-8 rounded shadow card"
                >
                  <div
                    class="
                      flex flex-row items-center justify-center
                      w-full mb-12 space-x-4 leading-none
                    "
                  >
                    <span class="brand-figma text-12 portal-accent-foreground"></span>
                    <span
                      class="
                        font-semibold text-gray-700 text-11
                        dark:text-darkGray-300', 'portal-paragraph-font
                      "
                    >{{ app }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Updates -->
        <!-- {{ branding.portalTheme }}
        {{ branding.portalAccentColor }}
        {{ branding.portalHeadingFont }}
        {{ branding.portalHeaderBackgroundColor }}
        {{ branding.portalHeaderImage }} -->
      </div>
    </div>
  </LoaderWebFont>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import tinycolor from 'tinycolor2'
import LoaderWebFont from '@/components/LoaderWebFonts.vue';
import Logo from '@/components/Logo.vue'

export default defineComponent({
  name: 'ProjectBrandingPreview',
  components: {
    LoaderWebFont,
    Logo,
  },
  props: {
    branding: {
      type: Object,
      required: true,
    },
    className: {
      type: String,
      default: null,
    },
    organization: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const accentColorSpace = computed(
      () => tinycolor(props.branding.portalAccentColor).monochromatic(3)
    )

    const mostReadable = computed(
      () => tinycolor.mostReadable(
        props.branding.portalAccentColor,
        [
          ...accentColorSpace.value,
          '#FFFFFF',
        ],
        {
          level: 'AA',
          size: 'large',
        }
      ).toHexString()
    )


    const theme = computed(() => ({
      '--cp-header-bg': props.branding.portalHeaderBackgroundColor,
      '--cp-accent': props.branding.portalAccentColor,
      '--cp-link-color': props.branding.portalLinkColor,
      '--cp-accent-most-readable': mostReadable.value,
      '--cp-header-image-url': `url(${props.branding.portalHeaderImage})`,
      '--cp-heading-font': props.branding.brandingFontHeading,
      '--cp-paragraph-font': props.branding.brandingFontParagraph,
      '--cp-header-image-opacity': props.branding.portalHeaderImageOpacity / 100,
    }))

    return {
      theme,
    }
  },
})
</script>

<style lang="postcss" scoped>
.header-background {
  /* background-color: var(--cp-header-bg); */
}

.header-background-image {
  background-color: var(--cp-header-bg);
  @apply absolute inset-0 z-0;
}

.header-background-image::after {
  content: '';
  background-image: var(--cp-header-image-url);
  background-size: cover;
  background-position: center center;
  opacity: var(--cp-header-image-opacity);
  @apply absolute inset-0 z-0;
}
</style>
