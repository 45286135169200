
<main :class="$style['layout-signup-wrap']">
  <!-- Header -->
  <header
    class="container flex flex-row justify-center mt-24 lg:mt-40 lg:justify-start"
  >
  </header>
  <section :class="$style['layout-signup']" class="container">
    <!-- Content -->

    <CardWhiteRounded class="w-full max-w-prose md:px-64 md:py-64">
      <slot v-if="hasDefaultSlot" />
      <router-view v-else />
    </CardWhiteRounded>
  </section>
</main>
