
<LayoutSignup>
  <img
    v-if="organization.logoUrl"
    :src="organization.logoUrl"
    :class="$style['agency-logo']"
    class="object-contain object-center"
  />
  <SignUpFormHeader>
    {{ organization.name }} invited your to join their team on
    SuperOkay
  </SignUpFormHeader>
  <FormMessage v-if="error">
    {{ error }}
  </FormMessage>
  <SignUpFormProfile
    button-label="Sign Up"
    :user="user"
    :profile="profile"
    :is-loading="isLoading"
    :is-email-locked="true"
    @submit="onSubmit"
  />
</LayoutSignup>
