<template>
  <ModalBasic
    title="Create New Client"
    :should-close-on-backdrop="false"
    :hide-footer="true"
    content-class="modal-content-tertiary"
    body-class="modal-body-tertiary"
    header-class="modal-header-tertiary"
    footer-class="modal-footer-tertiary"
    size="lg"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #body="{ close }">
      <div>
        <ClientEditor
          ref="clientEditorRef"
          :organization="organization"
          client-id="new"
          :standalone="false"
          @saved="newClient => onSaved(newClient, close)"
        />
        <Buttons>
          <Button
            type="button"
            variant="neutral"
            size="md"
            @click="close"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            size="md"
            @click="clientEditorRef.create()"
          >
            Create Client
          </Button>
        </Buttons>
      </div>
    </template>
  </ModalBasic>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import Button from '@/components/Button.vue'
import Buttons from '@/components/Buttons.vue'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import ClientEditor from './ClientEditor.vue'

export default defineComponent({
  name: 'ClientModal',
  components: {
    ModalBasic,
    Buttons,
    Button,
    ClientEditor,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const clientEditorRef = ref(null)

    const onSaved = (client, closeFn) => {
      context.emit('save', client)
      closeFn()
    }

    return {
      onSaved,
      clientEditorRef,
    }
  },
})
</script>
