<template>
  <div v-fragment>
    <h2 class="text-gray-700 mb-8">Column sizes</h2>
    <div class="flex justify-between gap-6 mb-12">
      <template v-for="(ratio, index) in ratios">
        <div :key="`filled-ratio-${index}`" class="flex flex-col flex-1">
          <button
            v-b-tooltip.hover.top.v-info.dh0.ds200="`Remove column`"
            :class="['rounded bg-gray-100 h-72',
                     'border-2 border-gray-100 text-gray-500',
                     'justify-end items-end flex hover:text-gray-700 mb-8']"
            @click="removeColumn(index)"
          >
            <span class="inline-block mr-10 mb-10">
              <span class="w-20 icon_v2-so_trash text-20"></span>
            </span>
          </button>
          <b-form-input
            :value="ratio || 0"
            type="number"
            :number="true"
            size="md"
            min="0"
            max="100"
            :class="['w-full px-8', $style['input']]"
            @input="value => updateRatio(Number(value), index)"
          />
        </div>
      </template>
      <template v-for="index in remainingRatio">
        <div :key="`empty-ratio-${index}`" class="flex flex-col flex-1">
          <button
            v-b-tooltip.hover.top.v-info.dh0.ds200="`Add column`"
            :class="['rounded bg-white h-72 border-2 border-dashed',
                     'border-gray-100 flex justify-center items-center mb-8',
                     'text-gray-500 hover:border-gray-200 hover:text-gray-700']"
            @click="addColumn"
          >
            <span class="w-24 icon_v2-so_plus text-24" />
          </button>
        </div>
      </template>
    </div>
    <SettingsSwitch
      :value="value.stack"
      label="Stack on mobile"
      tooltip=""
      @input="value =>$emit('input', 'stack', value)"
    />
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import debounce from '@/v2/lib/helpers/debounce'
import { useCreateNode } from '@/v2/services/documentNodes/documentNodesCompositions'
import { CATEGORY as NODE_CATEGORY } from '@/v2/services/documentNodes/documentNodesTypes'
import { useStructureBranch, useStructureRemoveNode } from '@/v2/services/documentStructures/documentStructuresCompositions'
import { useNodeWidth, widthSize } from '@/v2/lib/composition/useNodeWidth'
import SettingsSwitch from '@/components/SettingsSwitch.vue'

const { useActions } = createNamespacedHelpers('documentEditor')


// If an input is changing, the remaining up until 100 needs to be distributed
// between the others(max 3). In case it is an odd number then, the remainder (%)
// will be added to the next one after the one it changes.

const transformRatios = (ratio, val, index) => {
  if (index > ratio.length - 1) {
    ratio.push(val)
  }
  return ratio.reduce((acc, rat, i) => {
    let split = false
    const rest = val % 2;
    const { length } = ratio
    if (ratio.length > 2) {
      split = true
    }
    if (index !== i) {
      split ? acc = [...acc, (100 - rest - val) / 2] : acc = [...acc, 100 - val]
    }
    if (index === i) {
      return [...acc, val]
    }
    if (rest && i === index + 1 && length !== 2) {
      acc[i] = (100 - rest - val) / 2 + rest
    }
    if (index === length - 1 && length > 2) {
      acc[0] = (100 - rest - val) / 2 + rest
    }
    return acc
  }, [])
}

const ratioDefaults = {
  1: [100],
  2: [50, 50],
  3: [33, 33, 34],
}

export default {
  name: 'DocumentNodeGroupColumnsContainerSettings',
  components: {
    SettingsSwitch,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    nodeId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const createNodeGroup = useCreateNode()
    const structureBranch = useStructureBranch()
    const structureRemoveNode = useStructureRemoveNode()
    const { children } = structureBranch(props.nodeId)
    const { load } = useNodeWidth()

    const ratios = ref(props.value.ratio ?? [])

    const remainingRatio = computed(() => 3 - ratios.value.length)
    const nodes = computed({
      get: () => children.value,
    })

    const { addNodeWidth } = useActions(['addNodeWidth'])

    const updateChildrensWidth = debounce(() => children.value.forEach(async nodeId => {
      const width = await load(nodeId)
      addNodeWidth(widthSize({ [nodeId]: width }))
    }), 200)

    const saveRatio = debounce(data => {
      context.emit('input', 'ratio', data)
      updateChildrensWidth()
    }, 500)

    const updateRatio = (val, index) => {
      if (val > 100) {
        return
      }
      const newVal = transformRatios(ratios.value, val, index)
      ratios.value = newVal
      saveRatio(newVal)
    }


    const addColumn = async () => {
      // Add column (child)
      createNodeGroup({
        parentNodeId: props.nodeId,
        nodeData: { category: NODE_CATEGORY.NodeGroupColumn },
        flashNode: false,
      })
      // Update the ratio array on the column container (parent)
      await context.emit('input', 'ratio', ratioDefaults[String(ratios.value.length + 1)])
      ratios.value = ratioDefaults[String(ratios.value.length + 1)]
    }

    const removeColumn = async index => {
      // Remove column (child)
      await structureRemoveNode({ id: nodes.value[index] })

      // Update the ratio array on the column container (parent)
      context.emit('input', 'ratio', ratioDefaults[String(nodes.value.length)])
      ratios.value = ratioDefaults[String(nodes.value.length)]
    }

    return {
      ratios,
      remainingRatio,
      updateRatio,
      addColumn,
      nodes,
      removeColumn,
    }
  },

}
</script>

<style lang="scss" scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style lang="scss" module>
.input {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.91094 9.09091C5.91094 10.2045 6.60697 11.1392 7.89958 11.1392C9.17231 11.1392 9.86833 10.2045 9.86833 9.09091V8.55398C9.86833 7.44034 9.19219 6.50568 7.89958 6.50568C6.62685 6.50568 5.91094 7.44034 5.91094 8.55398V9.09091ZM0.839921 3.2642C0.839921 4.37784 1.53594 5.3125 2.82856 5.3125C4.10129 5.3125 4.79731 4.37784 4.79731 3.2642V2.72727C4.79731 1.61364 4.12117 0.678977 2.82856 0.678977C1.55583 0.678977 0.839921 1.61364 0.839921 2.72727V3.2642ZM1.21776 11H2.35129L9.35129 0.818182H8.21776L1.21776 11ZM6.92515 8.55398C6.92515 7.9375 7.20356 7.3608 7.89958 7.3608C8.61549 7.3608 8.85413 7.9375 8.85413 8.55398V9.09091C8.85413 9.70739 8.5956 10.2841 7.89958 10.2841C7.18367 10.2841 6.92515 9.70739 6.92515 9.09091V8.55398ZM1.85413 2.72727C1.85413 2.1108 2.13254 1.53409 2.82856 1.53409C3.54447 1.53409 3.7831 2.1108 3.7831 2.72727V3.2642C3.7831 3.88068 3.52458 4.45739 2.82856 4.45739C2.11265 4.45739 1.85413 3.88068 1.85413 3.2642V2.72727Z' fill='%23BABBBF'/%3E%3C/svg%3E");
  padding-right: 18px !important;
  background-size: 12px 18px;
  background-repeat: no-repeat;
  background-position: right 8px center;
}
</style>
