<template>
  <Form
    v-if="state"
    v-slot="{ isBusy, v, submit, hasChanges }"
    :state="state"
    :validation="validation"
    success-message="Organization settings saved"
    :on-submit="() => save()"
  >
    <h3 class="project-page-h3 mb-18">
      Default Client Portal Language
    </h3>
    <!-- Language -->
    <FormField
      :v="v.locale"
      label="Language"
      wrapper-class="flex"
      label-class="mr-24"
    >
      <b-form-select v-model="state.locale" class="max-w-240">
        <b-form-select-option
          v-for="(lang, i) in locales"
          :key="`lang-${i}`"
          :value="lang"
        >
          {{ localesNames[lang] }}
        </b-form-select-option>
      </b-form-select>
    </FormField>


    <!-- Portal Login -->
    <div class="grid grid-cols-1 gap-32 mt-32 pb-32">
      <div>
        <h3 class="mt-24 project-page-h3">
          Login Options
        </h3>
        <p class="mb-16 text-gray-500 text-12">
          Switching on both login options will give your
          Client the option to choose how they would like to login
        </p>
        <div class="option-container">
          <FormField
            label="Email / Password"
            wrapper-class="flex items-center flex-wrap"
            label-class="w-192 mb-0"
            aside
            error-class="w-full"
            :description-class="$style['option-description']"
            description="Standard email/password authentication for your portal."
            :v="v.portalLoginPasswordEnabled"
            :messages="{
              required: 'At least one login method must be selected.'
            }"
          >
            <b-form-checkbox
              v-model="state.portalLoginPasswordEnabled"
              switch
              size="lg"
            />
          </FormField>
        </div>
        <div class="option-container">
          <FormField
            label="Magic Link"
            wrapper-class="flex items-center flex-wrap"
            label-class="w-192 mb-0"
            aside
            :v="v.portalLoginMagicLinkEnabled"
            error-class="w-full"
            :description-class="$style['option-description']"
            :description="[
              'One-click magic link authentication for your portal.',
              !state.portalLoginMagicLinkNoExpire
                && 'Each magic link has a short lifespan and will expire if not used.',
              state.portalLoginMagicLinkNoExpire
                && 'Each magic link is permanent and can be used indefinitely.',
            ].filter(Boolean)"
            :messages="{
              required: 'At least one login method must be selected.'
            }"
          >
            <b-form-checkbox
              v-model="state.portalLoginMagicLinkEnabled"
              switch
              size="lg"
              @change="onMagicLinkEnabledChanged"
            />
          </FormField>
        </div>
        <div v-if="state.portalLoginMagicLinkEnabled" class="option-container">
          <FormField
            label="Magic Link Doesn't Expire"
            wrapper-class="flex items-center flex-wrap"
            label-class="w-192 mb-0"
            aside
            error-class="w-full"
            :description-class="$style['option-description']"
            :description="[
              'Permanent magic links for portal authentication',
              'Please be aware that this option may decrease the security level of your portal'
            ]"
          >
            <b-form-checkbox
              v-model="state.portalLoginMagicLinkNoExpire"
              switch
              size="lg"
              @change="onMagicLinkNoExpireChanged"
            />
          </FormField>
        </div>
      </div>
    </div>
    <PageFooter>
      <Button
        :disabled="isBusy"
        size="lg"
        variant="primary"
        type="submit"
        @click="submit"
      >
        Save Changes
      </Button>
      <template v-if="hasChanges" #right>
        <PageFooterMessage />
      </template>
    </PageFooter>
  </Form>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import { sameAs, or } from '@vuelidate/validators'
import { useMsgBoxConfirm } from '@/v2/lib/composition/useMsgBox'
import useConfig from '@/v2/lib/composition/useConfig'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'
import FormField from '@/components/FormField.vue'
import Button from '@/components/Button.vue'
import Form from '@/components/Form.vue'
import PageFooter from '@/components/PageFooter.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'

const join = lines => lines.join(' ');

export default defineComponent({
  name: 'ViewOrganizationSettingsPortalSettings',
  components: {
    FormField,
    Button,
    Form,
    PageFooter,
    PageFooterMessage,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    licensing: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { locales, localesNames } = useConfig(['locales', 'localesNames'])

    const {
      state,
      save,
    } = useFormEntityEditor({
      model: 'Organization',
      id: computed(() => props.organization._id),
      fields: [
        'locale',
        'portalLoginMagicLinkEnabled',
        'portalLoginMagicLinkNoExpire',
        'portalLoginPasswordEnabled',
      ],
    })

    const validation = computed(() => ({
      portalLoginMagicLinkEnabled: {
        required: or(
          sameAs(true),
          () => state.value.portalLoginPasswordEnabled
        ),
      },
      portalLoginPasswordEnabled: {
        required: or(
          sameAs(true),
          () => state.value.portalLoginMagicLinkEnabled
        ),
      },
    }))

    const msgBoxConfirm = useMsgBoxConfirm()

    const showWarningDialog = message => msgBoxConfirm({
      size: 'md',
      title: 'Warning',
      message,
      okLabel: 'Yes',
      cancelLabel: 'No',
    })

    const onMagicLinkNoExpireChanged = async () => {
      const ok = await showWarningDialog(join([
        'Changing this option will delete all existing magic links,',
        'requiring them to be regenerated. Are you certain you want to proceed with this change?',
      ]))
      if (!ok) {
        state.value.portalLoginMagicLinkNoExpire = !state.value.portalLoginMagicLinkNoExpire
      }
    }

    const onMagicLinkEnabledChanged = async () => {
      const isToggledOff = !state.value.portalLoginMagicLinkEnabled;
      if (isToggledOff) {
        const ok = await showWarningDialog(join([
          'Disabling the magic links will delete all existing magic links,',
          'requiring them to be regenerated if they are re-enabled at a later time.',
          'Are you certain you want to proceed with this change?',
        ]))
        if (!ok) {
          state.value.portalLoginMagicLinkEnabled = true
        }
      }
    }

    return {
      locales,
      localesNames,
      onMagicLinkNoExpireChanged,
      onMagicLinkEnabledChanged,
      validation,
      state,
      save,
    }
  },
})
</script>
<style lang="postcss" module>
.preview-box{
  transform: scale(var(--cp-login-preview-scale));
}
.preview-box-container {
  height: var(--cp-login-preview-height);
  @apply mb-24 2xl:mb-0;
}
.btn-group-appearance  {
  :global(.btn-radio.active:nth-child(2)) {
    @apply bg-darkGray-800 ring-darkGray-800;
  }
}
.theme {
  @apply relative aspect-[16/9] rounded-lg h-[90px]
  mr-12 flex items-center select-none shadow-sm border border-gray-100
  cursor-pointer hover:shadow-lg mb-24 transition-all overflow-hidden;
}

.option-description {
  @apply w-full mt-6 text-12 !text-gray-400;
}
</style>

<style lang="postcss" scoped>
.horizontal >>> .v-hl-btn-prev svg {
  @apply rounded-full bg-white text-gray-400 hover:text-gray-600
}

.horizontal >>> .v-hl-btn-next {
  @apply top-0 bottom-24  translate-x-0 !important;
}


.more-btn {
  background: linear-gradient(to right, #ffffff00, white);
  @apply flex items-center pl-48 h-full
}

.more-btn > div {
  @apply bg-white text-gray-500 shadow-md text-15
  px-16 rounded-md h-30 inline-flex items-center
  justify-center font-medium leading-none hover:text-gray-900
  transition-all;
}

.option-container  {
  /* @apply border border-gray-200 rounded-md p-12 max-w-2/3; */
  @apply mt-32;
}

</style>
