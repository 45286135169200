
<DocumentGet
  :id="documentId ?? folderId"
  v-slot="{ document, isGetPending: isPending }"
>
  <template v-if="!isPending">
    <div v-if="document" class="flex-1 flex">
      <DocumentViewContext v-slot="{ viewContext, updateViewContext }">
        <DocumentTheme
          :organization="organization"
          :project="project"
          :document="document"
        >
          <Provide :provide="{ organization, project, document }">
            <div class="flex-1 flex">
              <router-view
                :organization="organization"
                :project="project"
                :document="document"
                :view-context="viewContext"
                @update-view-context="updateViewContext"
              />
            </div>
          </Provide>
        </DocumentTheme>
      </DocumentViewContext>
    </div>
    <div v-else>Document no longer available.</div>
  </template>
</DocumentGet>
