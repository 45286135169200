
<MemberRole v-slot="{ admin, projectManager }">
  <div class="flex items-center justify-between w-full">
    <router-link
      v-slot="{ navigate }"
      :to="{ name: PROJECT_DASHBOARD }"
      custom
    >
      <ButtonTransparent class="project-button" @click="navigate">
        <ProjectSwitcherItem :project="project" @click="navigate" />
      </ButtonTransparent>
    </router-link>
    <ProjectMenu
      :project="project"
      class="-mr-8"
      :items="{
        settings: admin || projectManager,
        portalPreview: true,
        duplicate: admin || projectManager,
        archive: admin || projectManager,
        delete: admin || projectManager,
      }"
    />
  </div>
</MemberRole>
