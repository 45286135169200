
<portal to="app-sidebar-right">
  <div
    ref="editor"
    class="
      grid w-[540px] bg-white/90 backdrop-blur-sm
      shadow-xs p-24 h-full
    "
  >
    <ProjectTaskEditor
      :project="project"
      :section-id="sectionId"
      :document-id="documentId"
      :task-id="taskId"
      :disabled-fields="disabledFields"
      @close="close"
      @created="task => $emit('created', sectionId, task)"
      @updated="task => $emit('updated', task)"
      @remove="task => $emit('remove', task)"
    />
  </div>
</portal>
