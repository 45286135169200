
<b-button
  variant="neutral"
  size="md"
  :class="[
    'space-x-12 w-full',
    'shadow-xs bg-gray',
    'hover:shadow'
  ]"
  @click="auth"
>
  <SvgGraphic
    width="24px"
    height="24px"
    name="google-logo"
  />
  <span>{{ message }}</span>
</b-button>
