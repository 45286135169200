<template>
  <LayoutGuest>
    <template #header>
      <div
        class="
          container flex flex-col items-center justify-center w-full py-16 space-x-16
          tablet:flex-row h-116 lg:py-34 lg:mb-0 mb-36
        "
      >
        <span
          class="
            w-40 mt-24 mb-16 text-white text-40 tablet:w-32 tablet:text-32
            icon-superokay-symbol tablet:mt-0 tablet:mb-0
          "
        />
        <ul
          class="
            flex justify-center flex-1 space-x-6 font-medium leading-snug text-gray-800
            tablet:items-center tablet:text-white text-13 tablet:space-x-17
          "
        >
          <template v-for="(step, index) in steps">
            <li
              :key="`${step.name}-label`"
              class="
                flex flex-col items-center text-center tablet:flex-row
                opacity-80 tablet:text-left
              "
            >
              <span
                class="
                  inline-flex items-center justify-center
                  w-32 h-32 tablet:mr-12 flex-none mb-4 tablet:mb-0
                  border rounded-full
                "
                :class="{
                  'text-gray-900 font-bold bg-white border-white': step.active,
                  'opacity-60 tablet:border-white border-gray-900 border-opacity-50': !step.active,
                }"
              >
                <span v-if="index < activeStepIndex" class="w-16 icon_v2-so_tick text-16" />
                <span v-else>{{ index + 1 }}</span>
              </span> {{ step.label }}
            </li>
            <li
              v-if="index < steps.length - 1"
              :key="`${step.name}-separator`"
              class="mt-4 mb-auto"
            >
              <span
                class="
                  inline-block w-16 h-2 bg-gray-800 tablet:bg-white tablet:w-24
                  opacity-20 tablet:opacity-40
                "
              />
            </li>
          </template>
        </ul>
        <span class="w-48" />
      </div>
    </template>
    <template #default>
      <router-view />
    </template>
  </LayoutGuest>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import { useRoute } from '@/v2/lib/composition/useRouter'
import {
  ONBOARDING_ORGANIZATION_DETAILS,
  ONBOARDING_BRANDING,
  ONBOARDING_PROJECT_TEMPLATES,
} from '@/router/welcome/type'
import LayoutGuest from '@/views/LayoutGuest.vue'

const ONBOARDING_STEPS = [
  { name: 'account', label: 'Account' },
  { name: 'details', label: 'Company details', route: ONBOARDING_ORGANIZATION_DETAILS },
  { name: 'branding', label: 'Set up your brand', route: ONBOARDING_BRANDING },
  { name: 'project-templates', label: 'Choose a Project Template', route: ONBOARDING_PROJECT_TEMPLATES },
];

export default defineComponent({
  name: 'LayoutOnboarding',
  components: {
    LayoutGuest,
  },
  setup() {
    const { routeIs } = useRoute()

    const steps = computed(
      () => ONBOARDING_STEPS.map(step => ({
        ...step,
        active: routeIs(step.route),
      }))
    )

    const activeStepIndex = computed(
      () => steps.value.findIndex(step => step.active)
    )

    return {
      steps,
      activeStepIndex,
    }
  },
})
</script>
