<template>
  <ModalBasic
    :title="isNew ? 'Add new quick link' : 'Edit quick link'"
    :should-close-on-backdrop="false"
    :hide-footer="true"
    content-class="modal-content-tertiary"
    :body-class="$style['modal-body-link']"
    :header-class="$style['modal-header-link']"
    footer-class="modal-footer-tertiary"
    :title-class="$style['modal-title-link']"
    size="md"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #body="{ close }">
      <Form
        v-slot="{ state, v }"
        :state="isNew ? quickLinkDefaults : quickLink"
        :validation="validation"
        clone
        @submit="data => !(void $tracking.track('New Quick Link Created')) && submit(data)"
      >
        <b-form-group class="mb-8">
          <!-- url -->
          <FormField
            :v="v.url"
            label="Link"
            :messages="{
              supported: 'Invalid URL'
            }"
            required
          >
            <b-input
              v-model="state.url"
              placeholder="https://your-url.com"
              class="form-control-lg"
              trim
            />
          </FormField>
        </b-form-group>
        <div class="flex flex-row items-center gap-8 justify-items-stretch">
          <FormField
            :v="v.icon"
            label="Icon"
          >
            <div class="flex justify-between ">
              <!-- <span :class="`${quickLinks.icon} text-24`" /> -->
              <DropdownIcons
                :target-id="`quick-link-popover-${quickLink ? quickLink._id : '' }`"
                :current-icon="state.icon"
                @pick-icon="(icon) => state.icon = icon"
              />
            </div>
          </FormField>
          <!-- label -->
          <FormField
            :v="v.label"
            label="Label"
            required
            wrapper-class="grow"
          >
            <b-input
              v-model="state.label"
              placeholder="Your Link"
              class="form-control-lg"
            />
          </FormField>
        </div>
        <b-form-group>
          <div class="mt-16 relative">
            <!-- open link in new tab  -->
            <SettingsSwitch
              v-model="state.openInNewTab"
              label="Open in new tab"
              tooltip=""
            />
          </div>
        </b-form-group>
        <Button
          type="submit"
          size="lg"
          variant="primary"
        >
          {{ quickLink ? 'Save' : 'Add' }}
        </Button>
      </Form>
    </template>
  </ModalBasic>
</template>
<script>
import { isNil } from 'ramda'
import { nanoid } from 'nanoid'
import { defineComponent, computed } from '@vue/composition-api'
import { required, url, alphaNum } from '@vuelidate/validators'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import Button from '@/components/Button.vue'
import Form from '@/components/Form.vue'
import FormField from '@/components/FormField.vue'
import SettingsSwitch from '@/components/SettingsSwitch.vue'
import DropdownIcons from '@/components/DropdownIcons.vue'

export default defineComponent({
  name: 'ProjectSettingsQuickLinkModal',
  components: {
    ModalBasic,
    Button,
    Form,
    FormField,
    SettingsSwitch,
    DropdownIcons,
  },
  props: {
    quickLink: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const quickLinkDefaults = {
      _id: nanoid(),
      url: '',
      label: '',
      openInNewTab: true,
      icon: 'icon_v2-so_apps',
    }

    const validation = computed(() => ({
      label: {
        required,
      },
      url: {
        required,
        $lazy: true,
        supported: value => {
          // EMAIL ADDRESS + PHONE NUMBER
          if (value?.startsWith('mailto:') || value?.startsWith('tel:')) {
            return true
          }

          // ANCHOR LINK
          if (value?.startsWith('#')) {
            return alphaNum.$validator(value.replace('#', '').trim())
          }

          return url.$validator(value)
        },
      },
      icon: {
        required,
      },
    }))

    const isNew = computed(() => isNil(props.quickLink))

    const submit = data => {
      if (isNew.value) {
        context.emit('create', data)
      } else {
        context.emit('update', data)
      }
    }

    return {
      submit,
      quickLinkDefaults,
      validation,
      isNew,
    }
  },
})
</script>
<style lang="postcss" module>
.modal-header-link {
  @apply min-h-0 pt-32 pb-0 mb-0;
}
.modal-body-link {
  @apply pt-24 pb-24 z-0;
}
.modal-title-link {
  @apply text-left;
}
</style>
