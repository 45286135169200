<template>
  <b-form @submit.prevent="formSubmit">
    <!-- COMPANY NAME -->
    <b-form-group :class="$style['btn-radio-group']">
      <FormField
        :v="$v.name"
        label="Company name"
        required
        :messages="{
          required: 'Your company name is required',
        }"
        wrapper-class="mb-16"
      >
        <b-input
          v-model="formState.name"
          autofocus
          placeholder="Acme Design"
          class="form-control-lg"
        />
      </FormField>
      <FormField
        :v="$v.subdomain"
        label="Subdomain"
        :messages="{
          available: 'This subdomain is not available',
        }"
      >
        <div class="input-group">
          <b-input
            v-model="formState.subdomain"
            :disabled="Boolean(organization.subdomain)"
            placeholder="mycompany"
            autocomplete="off"
            size="lg"
          />
          <div class="input-group-append">
            <span class="font-semibold input-group-text text-14">.superokay.com</span>
          </div>
          <p class="text-12 text-gray-600 mt-8 max-w-510">
            You can later configure
            <strong>your own custom domain</strong>
            instead of .superokay.com
          </p>
        </div>
      </FormField>
    </b-form-group>
    <!-- WHAT DO YOU DO -->
    <div :class="$style['btn-radio-group']">
      <FormField
        :v="$v.kind"
        label="What do you do?"
        aside="Pick one or more"
        required
        :messages="{
          required: 'Please select one or more of the options',
        }"
      >
        <div :class="'flex flex-wrap gap-8 mt-8'">
          <b-form-checkbox-group
            v-model="formState.kind"
            :options="options.kind"
            name="radio-company-kind"
            buttons
            button-variant="form"
          />
        </div>
      </FormField>
      <FormField
        v-if="formState.kind.includes('Other')"
        class="mt-8"
        :v="$v.kindOther"
        :messages="{
          required: 'Field is required',
        }"
        wrapper-class="mb-16"
      >
        <b-input
          v-model="formState.kindOther"
          autofocus
          placeholder="Please specify your industry"
          class="form-control-lg"
        />
      </FormField>
    </div>

    <!-- TEAM SIZE -->
    <div :class="$style['btn-radio-group-team']">
      <FormField
        :v="$v.size"
        label="Team size"
        required
        :messages="{
          required: 'Please select one of the options above',
        }"
        aside="Pick one"
      >
        <div :class="'flex flex-wrap gap-8 mt-8'">
          <b-form-radio-group
            v-model="formState.size"
            :options="options.size"
            name="radio-company-kind"
            buttons
            button-variant="form"
          />
        </div>
      </FormField>
      <Button
        :is-loading="isLoading"
        type="submit"
        size="lg"
        variant="primary"
        :block="true"
      >
        <span v-if="isLoading">Your organization is being updated...</span>
        <span v-else>Continue</span>
      </Button>
    </div>
  </b-form>
</template>

<script>
import { pathOr, applySpec, pick, reject, pipe } from 'ramda'
import { isNilOrEmpty } from 'ramda-adjunct'
import { defineComponent, reactive } from '@vue/composition-api'
import { minLength, required, helpers as vuelidateHelpers, requiredIf } from '@vuelidate/validators'
import { email as emailSanitizer } from '@/v2/lib/helpers/sanitizer'
import useValidators from '@/v2/services/validators/validatorsCompositions'
import useForm from '@/v2/lib/composition/useForm'
import useStateDebounce from '@/v2/lib/composition/useStateDebounce'
import { useTracking } from '@/v2/services/tracking/compositions'
import options from '@/types/organization-options'
import FormField from '@/components/FormField.vue'
import Button from '@/components/Button.vue'

const { withAsync, req } = vuelidateHelpers

const pathOrNull = pathOr(null);
const organizationProps = pipe(
  pick(['name', 'kind', 'size', 'domain', 'subdomain']),
  reject(isNilOrEmpty)
)


// const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

// const organizationName = ({
//   /* user, organization, */ profile,
// }) => capitalizeFirstLetter(`${profile.firstName}'s Organization`);


const formStateSpec = applySpec({
  name: () => '',
  kind: pathOr([], ['organization', 'kind']),
  size: pathOrNull(['organization', 'size']),
  subdomain: pathOrNull(['organization', 'subdomain']),
  role: pathOrNull(['profile', 'role']),
  kindOther: () => '',
})

export default defineComponent({
  name: 'SignUpFormOrganization',
  components: {
    FormField,
    Button,
  },
  props: {
    organization: {
      type: Object,
      default: () => ({}),
    },
    profile: {
      type: Object,
      default: () => ({}),
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    isLoading: Boolean,
  },
  setup(props, context) {
    const { subdomainAvailable/* , domainAvailable */ } = useValidators();
    const formState = reactive(formStateSpec(props));
    const { subdomain } = useStateDebounce(['subdomain'], formState, { subdomain: emailSanitizer });
    const { track } = useTracking()

    const onSubmit = async () => {
      const { kindOther, kind, ...rest } = formState
      const data = { kind: [...kind], ...rest }

      // replace 'Other' with specific value
      if (data.kind.includes('Other')) {
        data.kind = data.kind.filter(k => (k === 'Other' ? kindOther : k))
      }

      track('Onboarding Company Details Completed', {
        email: props.user.email,
        kind,
        size: data.size,
      })

      context.emit('submit', {
        organization: organizationProps(data),
      })
    }

    const { formSubmit, $v } = useForm({
      onSubmit,
      state: formState,
      validation: {
        name: {
          required,
        },
        kind: {
          required,
        },
        kindOther: {
          required: requiredIf(() => formState.kind.includes('Other')),
        },
        size: {
          required,
        },
        subdomain: {
          minLength: minLength(3),
          subdomain: vuelidateHelpers.withMessage(
            'Invalid characters detected',
            vuelidateHelpers.regex(/^\w(?:[\w-]{0,61}\w)?$/)
          ),
          available: withAsync(
            async value => !req(value) || subdomainAvailable(value)
          ),
          $lazy: true,
        },
      },
    })

    return {
      formState,
      subdomain,
      formSubmit,
      $v,
      options,

    }
  },
})
</script>

<style lang="postcss" module>
.btn-radio-group {
  @apply mb-24;
}
.btn-radio-group :global(.btn-group) {
  @apply flex flex-wrap gap-16 mb-8;
}

.btn-radio-group-team :global(.btn-group) {
  @apply flex flex-wrap gap-8 mb-32;
}

</style>
