export const TYPE = {
  /**
   * @type {'MEMBER_INVITATION'}
   */
  MEMBER_INVITATION: 'MEMBER_INVITATION',

  /**
   * @type {'PASSWORD_RESET'}
   */
  PASSWORD_RESET: 'PASSWORD_RESET',

  /**
   * @type {'DOCUMENT_SHARE'}
   */
  DOCUMENT_SHARE: 'DOCUMENT_SHARE',

  /**
   * Used by PDF print service to access any document
   * @type {'DOCUMENT_PRINT'}
   */
  DOCUMENT_PRINT: 'DOCUMENT_PRINT',

  /**
   * Used for client contacts' authentication without a password on the Client Portal
   * @type {'AUTH_CLIENT'}
   */
  AUTH_CLIENT: 'AUTH_CLIENT',

  /**
   * Used for signing up users which obtained a license through Appsumo
   * @type {'APPSUMO_LICENSE'}
   */
  APPSUMO_LICENSE: 'APPSUMO_LICENSE',
};
