
<DocumentNodeEditorToolbarPopover
  class-name="rounded hover:bg-gray-500 hover:bg-opacity-10 editor-toolbar-button"
  triggers="click blur"
  tooltip="Change Font"
>
  <template #button-content>
    <span class="w-24 text-24 icon_v2-so_font-size mx-4" />
    <span :class="[$style['toolbar-dropdown']]" />
    <span v-if="value" class="text-14 opacity-50 ml-4 mr-8">
      <span class="max-w-96 truncate inline-block">{{ value }}</span>
    </span>
  </template>
  <template #title>
    {{ heading }}
  </template>
  <template #default>
    <BrandingFontsFormSelect
      :value="value"
      @input="(v)=> $emit('input',v)"
    />
  </template>
</DocumentNodeEditorToolbarPopover>
