import { debounceAsync } from '@/v2/lib/helpers/debounce'
import chargebeeService from '../chargebeeService'
import { COMMAND } from '../chargebeeTypes'

const DEBOUNCE = 250

export default () => ({
  checkout: debounceAsync(({ itemPriceId, redirectUrl = null }) => chargebeeService.create({
    command: COMMAND.checkout,
    payload: { itemPriceId, redirectUrl },
  }), DEBOUNCE),
  createPortalSession: debounceAsync(() => chargebeeService.create({
    command: COMMAND.createPortalSession,
  }), DEBOUNCE),
})
