
<b-form @submit.prevent="formSubmit">
  <FormMessage v-if="formError">
    {{ formError.message }}
  </FormMessage>
  <div class="mb-50">
    <h3 class="project-page-subtitle">
      Subdomain
    </h3>
    <p class="mb-16 text-gray-600 text-14 max-w-510">
      This is your default sharing subdomain.
    </p>
    <div class="flex">
      <FormField
        v-slot="{ state }"
        wrapper-class="mr-10"
        :v="$v.subdomain"
        :messages="{
          required: 'Subdomain is required',
          available: 'Subdomain is not available',
          subdomain: 'Invalid characters detected',
          different: 'Subdomain must be different'
        }"
      >
        <div class="input-group max-w-510">
          <b-input
            v-model="subdomain"
            placeholder="myagency"
            :state="!subdomain ? null : state"
            autocomplete="off"
            size="lg"
          />
          <div class="input-group-append">
            <span class="font-semibold input-group-text text-14">.superokay.com</span>
          </div>
        </div>
      </FormField>
      <Button
        :disabled="formIsBusy"
        size="md"
        variant="neutral"
        type="submit"
      >
        Save
      </Button>
    </div>
    <!-- <p v-if="organization.subdomain" class="mt-8 text-gray-500 text-13">
      This is your .superokay subdomain. To change it contact us via Intercom.
    </p> -->
  </div>
</b-form>
