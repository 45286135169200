
<div>
  <PageHeader title="Integrations">
    <template #actions>
      <Button
        v-track="['New Integration Modal Opened']"
        type="button"
        variant="neutral"
        size="md"
        @click="edit(null)"
      >
        <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600" />
        Add Custom Code
      </Button>
    </template>
  </PageHeader>
  <InfoBox
    title="Add 3rd party widgets &amp; code integrations."
    icon="icon_v2-so_diamond"
  >
    Integrations will be active on every page of your client facing portal.
  </InfoBox>
  <div class="mt-8 mb-32">
    <p
      class="inline p-8 leading-none bg-yellow-100 rounded-md text-13 text-black/70"
    >
      <strong>🚨 SuperOkay doesn't validate your code.</strong>
      &nbsp;Please make sure your code is correct and test the integration.
    </p>
  </div>
  <!-- Quick Embed Chat -->
  <div class="mb-64">
    <h3 class="settings-section-title">
      Chat Widgets
    </h3>
    <div class="flex flex-row flex-wrap gap-16 mb-24 max-w-page">
      <QuickEmbedApp
        v-for="widget in chatWidgets"
        :key="widget.name"
        :app="widget"
        @click="preselect(widget)"
      />
    </div>
  </div>

  <Form
    v-if="state"
    v-slot="{ isBusy, submit, hasChanges }"
    :state="state"
    success-message="Project changes saved"
    :on-submit="() => save()"
  >
    <div class="flex flex-col max-w-page">
      <!-- Pinned Folders -->
      <b-form-group>
        <SlickList
          v-if="state.portalIntegrations.length"
          :value="state.portalIntegrations"
          use-drag-handle
          lock-to-container-edges
          axis="xy"
          class="gap-16 mx-16 space-y-16 sm:mx-16 max-w-page"
          helper-class="so-table-row-dragging"
          @input="reorder"
        >
          <SlickItem
            v-for="(item, index) in state.portalIntegrations"
            :key="item._id"
            :index="index"
            class="p-0 mb-16 overflow-visible card-flat-interactive"
          >
            <ActionsToolbar
              show-drag
              card-drag
              show-delete
              show-edit
              class="p-16"
              @remove="remove(item)"
              @edit="edit(item)"
            >
              <template #default>
                <div class="flex items-center flex-1 px-12 space-x-16 space-y-4 text-left">
                  <span class="text-gray-400 icon-toolbar-code text-40"></span>
                  <div>
                    <h6
                      class="
                        font-semibold leading-none text-gray-800 text-18
                        portal-heading-font dark:text-darkGray-300
                      "
                    >
                      {{ item.name }}
                    </h6>
                    <p
                      class="
                        font-normal truncate text-14 opacity-40
                        dark:text-darkGray-400 max-w-256
                      "
                    >
                      {{ item.customCode }}
                    </p>
                  </div>
                </div>
              </template>
            </ActionsToolbar>
          </SlickItem>
        </SlickList>
      </b-form-group>
      <!-- End of Pinned Folders -->
    </div>
    <PageFooter>
      <Button
        :disabled="isBusy"
        size="lg"
        variant="primary"
        type="submit"
        @click="submit"
      >
        Save Changes
      </Button>
      <template v-if="hasChanges" #right>
        <PageFooterMessage />
      </template>
    </PageFooter>
  </Form>
  <ProjectSettingsIntegrationModal
    v-if="isModalOpen"
    v-model="isModalOpen"
    :integration-item="selectedItem"
    @create="create"
    @update="update"
  />
</div>
