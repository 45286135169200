<template>
  <!-- <div v-if="!isPending" class="container max-w-screen-xl"> -->
  <div v-if="!isPending" class="container max-w-screen-2xl">
    <template v-if="paymentPlans.length">
      <!-- Header -->
      <section class="flex flex-col justify-center text-center pt-40 pb-32">
        <template v-if="$route.query.action === 'subscription-required'">
          <span class="text-gray-600 text-16 ">Your subscription has ended.</span>
          <h1 class="text-red-600 text-24 font-semibold">
            Please subscribe to continue using SuperOkay
          </h1>
        </template>
        <template v-else>
          <span class="text-gray-600 text-16 ">Turn your true work into profit</span>
          <h1 class="text-gray-900 text-32 font-semibold">
            Upgrade your
            <span class="text-gray-600">{{ plan.name }}</span>
            account
          </h1>
        </template>

        <div v-if="hasPricesUnits" class="mx-auto mt-40 flex items-center space-x-24">
          <b-form-radio-group
            id="btn-radios-1"
            :checked="periodUnit"
            :options="periodSwitchOptions"
            name="radios-btn-default"
            button-variant="radio-dark"
            class="btn-group-sp flex"
            size="md"
            buttons
            plain
            @input="val => periodUnit = val"
          />
          <SubscriptionTrialExtend :organization="organization" />
        </div>
      </section>
      <div
        v-if="globalDiscountPercentage"
        class="flex items-center justify-center mb-32 flex-col"
      >
        <div
          class="
            font-semibold font-18 text-center
            bg-red-600 text-white rounded-md px-16 mb-6
          "
        >
          {{ globalDiscountPercentage }}% off Limited-Time Offer
        </div>
        <div class="text-14 text-gray-500">Applicable for the first 12 months.</div>
      </div>
      <div
        class="grid grid-cols-1 gap-32 mb-32"
        :class="`md:grid-cols-${paymentPlans.length}`"
      >
        <PaymentPlanCard
          v-for="availablePlan in paymentPlans"
          :key="availablePlan._id"
          :plan="availablePlan"
          :period-unit="hasPricesUnits ? periodUnit : null"
          @select="onPlanSelect(availablePlan)"
        />
      </div>
      <FormMessage v-if="error">{{ error }}</FormMessage>
    </template>
    <b-alert
      v-else
      show
      variant="info"
      class="m-16"
    >
      <h4 class="alert-heading">No available plans</h4>
      <router-link
        v-slot="{ navigate }"
        :to="{ name: ORGANIZATION, params: { organization: organization._id } }"
        custom
      >
        <Button variant="primary" @click="navigate">Go to dashboard</Button>
      </router-link>
    </b-alert>
  </div>
</template>
<script>
import { computed, defineComponent, ref, h, toRef } from '@vue/composition-api'
import { usePlanDowngradeMessages, usePaymentPlans } from '@/v2/services/paymentPlans/compositions'
import { useMsgBoxConfirm } from '@/v2/lib/composition/useMsgBox'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { ORGANIZATION, ORGANIZATION_SUBSCRIPTION_CHECKOUT } from '@/router/organization/type'
import { LICENSING_PROVIDER } from '@/v2/services/organizations/organizationsTypes'
import PaymentPlanCard from '@/components/PaymentPlanCard.vue'
import FormMessage from '@/components/FormMessage.vue'
import Button from '@/components/Button.vue'
import SubscriptionTrialExtend from '@/components/SubscriptionTrialExtend.vue'

export default defineComponent({
  name: 'ViewOrganizationSubscriptionPlans',
  components: {
    PaymentPlanCard,
    FormMessage,
    Button,
    SubscriptionTrialExtend,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    licensing: {
      type: Object,
      required: true,
    },
    plan: {
      type: Object,
      required: true,
    },
    provider: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      items: plans,
      globalDiscountPercentage,
      error,
      isPending,
    } = usePaymentPlans(toRef(props, 'provider'))

    const { routerPush } = useRouter()

    const isMonthly = ref(false)
    const selectedItem = ref(null)
    const periodUnit = ref('year')

    const msgBoxConfirm = useMsgBoxConfirm()
    const planDowngradeMessages = usePlanDowngradeMessages(
      computed(() => props.organization?.featureFlags)
    )

    const periodUnits = computed(() => plans.value?.[0]?.periodUnits ?? null)
    const hasPricesUnits = computed(() => Boolean(periodUnits.value))
    const periodSwitchOptions = computed(() => periodUnits.value?.map(value => ({
      value,
      text: value === 'year' ? 'Annually -30%' : 'Monthly',
    })) ?? null)

    const currentPlanIndex = computed(
      () => plans.value?.find(
        ({ _id }) => _id === props.licensing.planId
      )?.index ?? -1
    )

    const paymentPlans = computed(() => {
      const { isActive, planId } = props.licensing;

      return plans.value?.map(plan => ({
        ...plan,
        isCurrent: isActive && plan._id === planId,
        isAvailable: !isActive || plan.index > currentPlanIndex.value || plan.isDowngradable,
        isDowngrade: plan.index < currentPlanIndex.value,
        isUpgrade: plan.index > currentPlanIndex.value,
      })) ?? [];
    });

    const checkout = plan => {
      if (plan.provider === LICENSING_PROVIDER.appsumo) {
        const { invoiceItemUUID } = props.organization.licensing
        window.open(`https://appsumo.com/account/redemption/${invoiceItemUUID}#change-plan`, '_blank')
      } else if (plan.provider === LICENSING_PROVIDER.chargebee) {
        routerPush({
          name: ORGANIZATION_SUBSCRIPTION_CHECKOUT,
          params: {
            provider: plan.provider,
            plan: plan._id,
            periodUnit: periodUnit.value,
          },
        })
      }
    }

    const onPlanSelect = async plan => {
      const { messages } = planDowngradeMessages(plan.featureFlags)

      if (!messages?.length) {
        checkout(plan)
        return
      }

      const messageVNode = h(
        'ul',
        { class: 'text-left list-disc' },
        messages.map(message => h('li', { class: 'text-semibold' }, [message]))
      );

      const { isTrial } = props.licensing

      const title = isTrial
        ? 'Some features may be lost'
        : `Change plan ${props.plan.name} to ${plan.name}?`

      const ok = await msgBoxConfirm({
        title,
        message: [messageVNode],
        okLabel: 'Continue',
        okVariant: 'tertiary',
        cancelLabel: 'Cancel',
        size: 'md',
      })

      if (ok) {
        checkout(plan)
      }
    }

    const closeCheckout = () => {
      selectedItem.value = null
    }

    return {
      isMonthly,
      periodUnit,
      selectedItem,
      onPlanSelect,
      closeCheckout,
      paymentPlans,
      ORGANIZATION,
      periodSwitchOptions,
      hasPricesUnits,

      // plans
      plans,
      error,
      isPending,
      periodUnits,
      globalDiscountPercentage,
    }
  },
})
</script>
