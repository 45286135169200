
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isEmpty"
  :root-class="[

    'node-group-basic',
  ]"
  :config-override="{
    stylingToolbar: {
      features: {
        sizes: true,
        textColor: false,
      },
    },
    styling: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    block: {
      enableBlockLabel: false,
    }
  }"
>
  <template #default="{ isHover }">
    <!-- Group Label -->
    <div
      :class="[
        'h-48 flex flex-row items-center justify-end py-4 px-12',
        'right-0 left-0 sticky top-48 z-1 transition-all pointer-events-none',
        isHover ? 'opacity-100' : 'opacity-25',
        'print:hidden'
      ]"
    >
      <span
        :class="[
          'text-green-800 bg-green-200 font-bold uppercase',
          'text-11 inline-flex rounded-l-lg pl-8 pr-5 pb-4 pt-5 leading-none',
          'transform translate-x-12',
        ]"
      >
        Group
      </span>
    </div>
    <div
      v-if="!isEmpty"
      class="mt-32"
    >
      <SlickList
        v-model="nodes"
        lock-axis="y"
        use-drag-handle
        helper-class="so-group-node-dragging"
        lock-to-container-edges
        @sort-start="handleSortStart"
        @sort-end="handleSortEnd"
      >
        <SlickItem
          v-for="(nodeId, index) in nodes"
          :key="nodeId"
          :index="index"
        >
          <DocumentNodeEditable
            :index="index"
            :node-id="nodeId"
            :parent="node._id"
            :is-first="index === 0"
            :is-last="index === children.length - 1"
            class="so-slick-inner"
          />
        </SlickItem>
      </SlickList>
    </div>
    <div v-else class="mx-2 mt-32 mb-16">
      <DocumentNodeGroupBasicPlaceholder
        :node-id="node._id"
      />
    </div>
    <div
      :class="[
        'h-32',
        'absolute top-24 left-0 right-0 transition-all',
        isHover ? 'opacity-100' : 'opacity-25',
        'print:hidden'
      ]"
    >
      <div class="flex flex-row items-center justify-between mx-auto space-x-24 max-w-prose">
        <DocumentBlockButtonCreate
          :show="isHover"
          :parent-node-id="node._id"
          :index="children.length"
          :accepts="accepts"
          mode="child"
          class="grow"
          button-label="Add to group"
        />
        <b-button
          variant="neutral-primary"
          size="sm"
          :class="[
            'p-8 h-auto text-14 leading-none',
            'hover:text-green-700',
          ]"
          @click="unGroup"
        >
          Ungroup
        </b-button>
      </div>
    </div>
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
</DocumentNodeRenderer>
