<template>
  <b-select
    :options="currencyOptions"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>
<script>
import { useConfiguration } from '@/v2/services/configurations/configurationsCompositions'

export default {
  name: 'FormInputCurrencies',
  setup() {
    const { CURRENCIES } = useConfiguration()

    const currencyOptions = CURRENCIES.map(({ ISO, label }) => ({
      text: `${ISO} - ${label}`,
      value: ISO,
    }))

    return {
      currencyOptions,
    }
  },
}
</script>
