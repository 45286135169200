<template>
  <ModalBasic
    v-bind="$attrs"
    :dialog-class="$style['sidebar-modal-dialog']"
    :header-class="$style['sidebar-modal-header']"
    :content-class="$style['sidebar-modal-content']"
    :body-class="$style['sidebar-modal-body']"
    v-on="$listeners"
  >
    <template #body="bindings">
      <div :class="$style['sidebar-modal-layout']">
        <div v-if="!hideSidebar" :class="$style['sidebar-modal-aside']">
          <slot name="sidebar" v-bind="bindings"></slot>
        </div>
        <div :class="$style['sidebar-modal-inner-body']">
          <slot name="body" v-bind="bindings"></slot>
        </div>
      </div>
    </template>
  </ModalBasic>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import ModalBasic from './ModalBasic.vue'

export default defineComponent({
  name: 'ModalSidebar',
  components: {
    ModalBasic,
  },
  props: {
    hideSidebar: Boolean,
  },
})
</script>

<style lang="scss" module>
.sidebar-modal-dialog {
    &:global(.modal-dialog) {
        max-width: 998px;
    }
}
.sidebar-modal-header {
    &:global(.modal-header) {
        position: relative;
        z-index: 1;
        margin: 0;
        padding: 0;
        min-height: 0;
    }
    :global(.modal-close) {
        right: 30px;
        top: 42px;
    }
}
.sidebar-modal-content {
    padding: 0;
    overflow: hidden;
}
.sidebar-modal-layout {
    display: flex;
    height: 100%;
    min-height: 585px;
}

.sidebar-modal-aside {
    width: 270px;
    flex: 0 0 auto;
    border-right: 1px solid #dfe0e5;
    background-color: white;
    padding: 16px;
}

.sidebar-modal-body {
    padding: 0;
}

.sidebar-modal-inner-body {
    @apply flex-1 bg-page;
    @apply px-24 pt-24 pb-0;
}
</style>
