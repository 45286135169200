<template>
  <router-link
    v-slot="{ navigate, href, /*isExactActive, */ isActive }"
    :to="to"
    custom
  >
    <a
      :href="href"
      @click="navigate"
    >
      <MainNavItem
        :active="isActive || isActiveOtherRoutes"
        :icon="icon"
        :icon-active="iconActive"
        :label="label"
      />
    </a>
  </router-link>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import { useRoute } from '@/v2/lib/composition/useRouter'
import MainNavItem from '@/components/MainNavItem.vue'

export default defineComponent({
  name: 'MainNavItemLink',
  components: {
    MainNavItem,
  },
  props: {
    to: {
      type: Object,
      default: null,
    },
    icon: {
      type: String,
      required: true,
    },
    iconActive: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    activateOnRoutes: {
      type: Array,
      default: null,
    },
    activateOnParentRoutes: {
      type: Array,
      default: null,
    },
  },
  setup(props) {
    const { routeIs, routeIsChildOf } = useRoute()

    const isActiveOtherRoutes = computed(
      () => props.activateOnRoutes?.some(r => routeIs(r))
        || props.activateOnParentRoutes?.some(r => routeIsChildOf(r))
    )

    return {
      isActiveOtherRoutes,
    }
  },
})
</script>
