import ViewProjectSettings from '@/views/project-settings/ViewProjectSettings.vue'
// project settings tabs
import ViewProjectSettingsDetails from '@/views/project-settings/ViewProjectSettingsDetails.vue'
import ViewProjectSettingsBranding from '@/views/project-settings/ViewProjectSettingsBranding.vue'
import ViewProjectSettingsModules from '@/views/project-settings/ViewProjectSettingsModules.vue'
import ViewProjectSettingsManageAccess from '@/views/project-settings/ViewProjectSettingsManageAccess.vue'
// sidebar items
import ViewProjectSettingsPinnedFolders from '@/views/project-settings/ViewProjectSettingsPinnedFolders.vue'
import ViewProjectSettingsQuickLinks from '@/views/project-settings/ViewProjectSettingsQuickLinks.vue'
import ViewProjectSettingsEmbeddedApps from '@/views/project-settings/ViewProjectSettingsEmbeddedApps.vue'
import ViewProjectSettingsIntegrations from '@/views/project-settings/ViewProjectSettingsIntegrations.vue'
import ViewProjectSettingsPackagedServices from '@/views/project-settings/ViewProjectSettingsPackagedServices.vue'
import ViewProjectSettingsPages from '@/views/project-settings/ViewProjectSettingsPages.vue'

import { ROLES } from '@/v2/services/members/membersTypes'

import {
  PROJECT_SETTINGS,
  // project settings tabs
  PROJECT_SETTINGS_DETAILS,
  PROJECT_SETTINGS_BRANDING,
  PROJECT_SETTINGS_MODULES,
  PROJECT_SETTINGS_MANAGE_ACCESS,
  // sidebar items
  PROJECT_SETTINGS_PINNED_FOLDERS,
  PROJECT_SETTINGS_QUICK_LINKS,
  PROJECT_SETTINGS_APPS,
  PROJECT_SETTINGS_INTEGRATIONS,
  PROJECT_SETTINGS_PACKAGED_SERVICES,
  PROJECT_SETTINGS_PAGES,
} from './type'

export default [
  {
    name: PROJECT_SETTINGS,
    path: 'settings',
    component: ViewProjectSettings,
    props: true,
    redirect: { name: PROJECT_SETTINGS_DETAILS },
    meta: {
      requiresRoles: [ROLES.admin, ROLES.projectManager],
    },
    children: [
      {
        name: PROJECT_SETTINGS_DETAILS,
        path: 'details',
        component: ViewProjectSettingsDetails,
        props: true,
      },
      {
        name: PROJECT_SETTINGS_BRANDING,
        path: 'branding',
        component: ViewProjectSettingsBranding,
        props: true,
      },
      {
        name: PROJECT_SETTINGS_MODULES,
        path: 'modules',
        component: ViewProjectSettingsModules,
        props: true,
      },
      {
        name: PROJECT_SETTINGS_MANAGE_ACCESS,
        path: 'manage-access',
        component: ViewProjectSettingsManageAccess,
        props: true,
      },

      // sidebar items
      {
        name: PROJECT_SETTINGS_PINNED_FOLDERS,
        path: 'pinned-folders',
        component: ViewProjectSettingsPinnedFolders,
        props: true,
      },
      {
        name: PROJECT_SETTINGS_QUICK_LINKS,
        path: 'quick-links',
        component: ViewProjectSettingsQuickLinks,
        props: true,
      },
      {
        name: PROJECT_SETTINGS_APPS,
        path: 'apps',
        component: ViewProjectSettingsEmbeddedApps,
        props: true,
      },
      {
        name: PROJECT_SETTINGS_INTEGRATIONS,
        path: 'integrations',
        component: ViewProjectSettingsIntegrations,
        props: true,
      },
      {
        name: PROJECT_SETTINGS_PACKAGED_SERVICES,
        path: 'packaged-services',
        component: ViewProjectSettingsPackagedServices,
        props: true,
      },
      {
        name: PROJECT_SETTINGS_PAGES,
        path: 'pages',
        component: ViewProjectSettingsPages,
        props: true,
      },
    ],
  },
]
