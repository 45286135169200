
<div v-if="client">
  <ClientPageHeader
    :client="client"
    @create-client-contact="createContact"
    @remove-client="removeClient"
  />
  <ClientContacts
    :client="client"
    :contacts="contacts"
    @edit-client-contact="editContact"
    @create-client-contact="createContact"
  />
  <TeamMemberFormModal
    v-if="isContactModalOpen"
    v-model="isContactModalOpen"
    :member-id="contactMemberId"
    :organization-id="organization._id"
    create-as-client-contact
    :client-id="client._id"
    @hide="$tracking.track('New Client Organization Modal Closed')"
    @save="closeContactModal"
    @close="closeContactModal"
    @blur="closeContactModal"
  />
</div>
