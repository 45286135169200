import { useRoute } from '@/v2/lib/composition/useRouter'
import useAsyncTask from '@/v2/lib/composition/useAsyncTask'
import feedbackService from '../feedbackService'
import { TYPE } from '../feedbackTypes'

export default function useUserFeedback() {
  const { route } = useRoute()

  const send = async ({ feedback }) => {
    const data = {
      type: TYPE.USER_FEEDBACK,
      feedback,
      url: route.value.path,
    }

    return feedbackService.create(data)
  }

  const { exec, ...rest } = useAsyncTask(send)

  return {
    send: exec,
    ...rest,
  }
}
