import Vue from 'vue'
import { indexBy } from 'ramda'
import { computed } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import { ROLES } from '@/v2/services/members/membersTypes'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 *
 * @param {Ref<string>} clientId
 * @returns
 */
export default function useFindClientContacts(clientId) {
  const { Member, User, Project } = Vue.$FeathersVuex.api
  const organization = useCurrentOrganization()

  // -- all members
  const { items: members, isPending: membersPending } = useFind({
    model: Member,
    params: computed(() => ({
      query: {
        role: ROLES.client,
        client: clientId.value,
        organization: organization.value._id,
      },
    })),
  })

  const projectIds = computed(() => members.value.flatMap(m => m.projects));
  const userIds = computed(() => members.value.map(m => m.user));

  // -- all projects
  const { items: projects, isPending: projectsPending } = useFind({
    model: Project,
    params: computed(() => (projectIds.value.length ? ({
      query: {
        _id: { $in: projectIds.value },
        organization: organization.value._id,
      },
    }) : null)),
  })

  const indexedProjects = computed(() => indexBy(p => p._id, projects.value));

  // -- users
  const { items: users, isPending: usersPending } = useFind({
    model: User,
    params: computed(() => (userIds.value.length ? {
      query: {
        _id: { $in: userIds.value },
      },
    } : null)),
  })

  const indexedUsers = computed(() => indexBy(u => u._id, users.value))

  const items = computed(() => members.value.map(member => ({
    member,
    user: indexedUsers.value[member.user] ?? null,
    projects: (member.projects ?? [])
      .map(projectId => indexedProjects.value[projectId])
      .filter(Boolean),
  })))

  const isPending = computed(() => [
    membersPending.value,
    projectsPending.value,
    usersPending.value,
  ].some(Boolean))

  return {
    items,
    isPending,
  }
}
