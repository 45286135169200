<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :vw="vw"
    :vh="vh"
    :viewBox="viewbox"
    :aria-labelledby="iconName"
    role="presentation"
    class="inline-block align-baseline"
  >
    <g :fill="iconColor">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    vw: {
      type: [Number, String],
      default: 24,
    },
    vh: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
  },
  computed: {
    viewbox() {
      return '0 0 ' + this.vw + ' ' + this.vh
    },
  },
}
</script>
