
<div class="flex flex-col gap-12">
  <ActionItemLink
    v-for="item in actionItems"
    :key="item._id"
    v-slot="{ navigate }"
    :action-item="item.actionItem"
  >
    <article
      class="card-interactive p-12 flex flex-col gap-8 bg-gray-100/80"
      @click="navigate"
    >
      <div
        class="text-12 font-semibold"
        :class="{
          'text-green-500': item.actionItem.isResolved,
        }"
      >
        {{ item.actionItem.title }}
      </div>
      <div class="flex items-center justify-between">
        <div>
          <ProjectBadge
            v-if="item.project"
            class="!text-12 font-normal !whitespace-normal"
            :label="item.project.name"
            :background-color="item.project.portalAccentColor"
          />
        </div>
        <div>
          <ActionItemDateDisplay
            v-if="item.actionItem.dueDate"
            v-slot="{ displayDate }"
            :item="item.actionItem"
            class-name="
          inline-flex items-center text-gray-600 text-12
          dark:text-darkGray-500 !whitespace-normal
        "
          >
            {{ displayDate.message }}
          </ActionItemDateDisplay>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="inline-flex gap-8">
          <span
            v-if="item.actionItem.public"
            class="inline-block text-gray-600"
          >
            <span class="icon-util-view" />
          </span>
          <span
            v-else
            class="inline-block text-gray-600 bg-opacity-70"
          >
            <span class="icon_v2-so_eye-disabled" />
          </span>
          <span
            v-if="item.actionItem.notes"
            class="inline-block"
            @click="toggleNote(item.actionItem._id)"
          >
            <span class="icon_v2-so_file text-18" />
          </span>
        </div>
        <div>
          <Avatar
            v-if="item.user?.profile$"
            class="!flex"
            :profile="item.user.profile$"
            :show-name="false"
            :nowrap="false"
          />
        </div>
      </div>
      <div
        v-if="visibleNotes[item.actionItem._id]"
        class="text-12 p-4 bg-yellow-100 rounded-md"
      >
        {{ item.actionItem.notes }}
      </div>
    </article>
  </ActionItemLink>
</div>
