
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isEmpty"
  :root-class="[
    'screen:border screen:border-dashed screen:border-gray-200',
  ]"
  :content-class="`flex flex-col justify-${node.styling.verticalAlign}`"
  :inner-class="'px-16 lg:px-24 print:px-16 print:h-full print:flex'"
  :config-override="{
    stylingToolbar: {
      features: {
        sizes: false,
        textColor: false,
        verticalAlign: true
      },
    },
    styling: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    block: {
      enableBlockLabel: false,
      enableCreateBefore: false,
    },
    menu: {
      enabled: false
    }
  }"
>
  <template #default="{isHover}">
    <div
      :class="[
        'h-64 flex flex-row items-center px-12',
        'absolute top-0 left-0 right-0 lg:-left-24 lg:-right-24',
        'space-x-16',
        isHover ? 'opacity-100' : 'opacity-25',
        'print:hidden'
      ]"
    >
      <div
        class="block-actions-move"
      >
        <div
          class="block-actions-move-button"
        >
          <button
            v-handle
            v-b-tooltip.hover.top.v-info.dh0.ds200
            :class="['w-32 h-32 flex items-center justify-center',
                     'outline-none focus:outline-none rounded-lg',
                     'hover:bg-gray-800 hover:text-white',
            ]"
            title="Drag to move"
            @click.stop
          >
            <span class="icon_v2-so_drag so-drag-icon" />
          </button>
        </div>
      </div>

      <DocumentBlockButtonCreate
        :parent-node-id="node._id"
        :index="children.length"
        :accepts="accepts"
        no-content-library
        mode="child"
        :class="['grow', $style['create']]"
      />

      <!-- Settings  -->
      <Button
        :class="['w-32 h-32 flex items-center justify-center',
                 'outline-none focus:outline-none rounded-lg',
                 'hover:bg-gray-800 hover:text-white',
        ]"
        @click.stop="setFocusOnColumn"
      >
        <span class="w-24 text-24 icon-toolbar-settings" />
      </Button>
    </div>
    <div
      v-if="!isEmpty"
      :class="['mt-64 print:mt-0', ]"
    >
      <SlickList
        v-model="nodes"
        lock-axis="y"
        use-drag-handle
        helper-class="so-group-node-dragging"
        class="p-0"
        lock-to-container-edges
        @sort-start="handleSortStart"
        @sort-end="handleSortEnd"
      >
        <SlickItem
          v-for="(nodeId, index) in nodes"
          :key="nodeId"
          :index="index"
        >
          <DocumentNodeEditable
            :index="index"
            :node-id="nodeId"
            :parent="node._id"
            :is-first="index === 0"
            :is-last="index === children.length - 1"
            class="so-slick-inner"
          />
        </SlickItem>
      </SlickList>
    </div>
    <div v-else class="mx-2 mt-64 mb-16">
      <DocumentNodeGroupColumnPlaceholder
        :node-id="node._id"
      />
    </div>
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
</DocumentNodeRenderer>
