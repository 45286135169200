<template>
  <FeatureLimit
    v-slot="{ checkFeatureLimit }"
    flag="limitProjects"
    label="projects"
    @allow="() => $emit('use', template)"
  >
    <ModalBasic
      size="xl"
      :hide-footer="true"
      content-class="modal-content-template-preview"
      body-class="modal-body-template-preview"
      footer-class="modal-footer-template-preview"
      close-text="Close Preview"
      :no-close="pending"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #header>
        <Button
          class="whitespace-nowrap"
          size="md"
          :is-loading="pending"
          @click="checkFeatureLimit"
        >
          <span class="icon_v2-so_tick mr-4 text-24 w-24" />Use this template
        </Button>
      </template>
      <template #body>
        <div class="px-24">
          <FormMessage v-if="error" supress-generic-message>{{ error }}</FormMessage>
          <ProjectTemplatePreview
            v-if="template"
            :template="template"
            :pending="pending"
            @use="() => checkFeatureLimit()"
          />
        </div>
      </template>
    </ModalBasic>
  </FeatureLimit>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import Button from '@/components/Button.vue'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import ProjectTemplatePreview from '@/components/ProjectTemplatePreview.vue'
import FormMessage from '@/components/FormMessage.vue'
import FeatureLimit from '@/components/FeatureLimit.vue'

export default defineComponent({
  name: 'ProjectTemplatePreviewModal',
  components: {
    ModalBasic,
    Button,
    ProjectTemplatePreview,
    FormMessage,
    FeatureLimit,
  },
  props: {
    template: {
      type: Object,
      default: null,
    },
    pending: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
  },
})
</script>
