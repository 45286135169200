
<div v-fragment>
  <h2 class="text-gray-700 mb-8">Column sizes</h2>
  <div class="flex justify-between gap-6 mb-12">
    <template v-for="(ratio, index) in ratios">
      <div :key="`filled-ratio-${index}`" class="flex flex-col flex-1">
        <button
          v-b-tooltip.hover.top.v-info.dh0.ds200="`Remove column`"
          :class="['rounded bg-gray-100 h-72',
                   'border-2 border-gray-100 text-gray-500',
                   'justify-end items-end flex hover:text-gray-700 mb-8']"
          @click="removeColumn(index)"
        >
          <span class="inline-block mr-10 mb-10">
            <span class="w-20 icon_v2-so_trash text-20"></span>
          </span>
        </button>
        <b-form-input
          :value="ratio || 0"
          type="number"
          :number="true"
          size="md"
          min="0"
          max="100"
          :class="['w-full px-8', $style['input']]"
          @input="value => updateRatio(Number(value), index)"
        />
      </div>
    </template>
    <template v-for="index in remainingRatio">
      <div :key="`empty-ratio-${index}`" class="flex flex-col flex-1">
        <button
          v-b-tooltip.hover.top.v-info.dh0.ds200="`Add column`"
          :class="['rounded bg-white h-72 border-2 border-dashed',
                   'border-gray-100 flex justify-center items-center mb-8',
                   'text-gray-500 hover:border-gray-200 hover:text-gray-700']"
          @click="addColumn"
        >
          <span class="w-24 icon_v2-so_plus text-24" />
        </button>
      </div>
    </template>
  </div>
  <SettingsSwitch
    :value="value.stack"
    label="Stack on mobile"
    tooltip=""
    @input="value =>$emit('input', 'stack', value)"
  />
</div>
