
<ProjectTheme :project="project" no-dark-mode>
  <ProjectPermissions
    v-slot="{ permissions }"
    :project="project"
  >
    <DocActions
      v-slot="{
        update,
        remove,
        move,
        share,
        download,
        duplicate,
      }"
      :document="document"
    >
      <DocRenderer :document="document">
        <template #breadcrumbs>
          <DocBreadcrumbs :document="document" :project="project" />
        </template>
        <template #actions>
          <DocFolderViewOptions
            :value="viewContext"
            @input="value => $emit('update-view-context', value)"
          />
          <DocCreateButton
            v-if="permissions.create"
            :disabled="document.status === DOCUMENT_STATUS.APPROVED"
            disabled-reason="Approved folders are read-only"
          />
          <DocMenu
            :document="document"
            :active-items="{
              share: true,
              duplicate: permissions.create,
              remove: permissions.remove,
              download: true,
              move: permissions.create && permissions.remove,
            }"
            @remove="remove"
            @move="move"
            @share="share"
            @download="download"
            @duplicate="duplicate"
          />
        </template>
        <template #header>
          <DocTitle
            :document="document"
            :disabled="!permissions.update"
            @update="title => update({ title })"
          />
          <DocSharedBadge :document="document" @click="share" />
        </template>

        <template #footer-left>
          <DocUpdatedBy :document="document" />
        </template>
        <template #footer-right>
          <DocStatusToggle
            :value="document.status"
            data-intercom-target="Document Status Select"
            @input="status => update({ status })"
          />
        </template>

        <template #content>
          <FolderContentList
            v-if="document"
            :document="document"
            :view-context="viewContext"
            :permissions="permissions"
          />
          <DocApprovalBadge :document="document" />
        </template>
        <template #sidebar-right>
          <DocSidebar :document="document" />
        </template>
      </DocRenderer>
    </DocActions>
  </ProjectPermissions>
</ProjectTheme>
