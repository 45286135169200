<template>
  <div>
    <PageHeader title="Project Templates" />
    <ProjectTemplateList
      :templates="templates"
      @preview="onTemplatePreview"
      @create="onCreate"
    />
    <ProjectTemplatePreviewModal
      v-if="isPreviewModalOpen"
      v-model="isPreviewModalOpen"
      :template="selectedTemplate"
      :pending="cloneProjectPending"
      :error="cloneProjectError"
      @use="onUseTemplate"
      @hide="onTemplateModalClosed"
    />
  </div>
</template>
<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import { useTracking } from '@/v2/services/tracking/compositions'
import { useCloneProject } from '@/v2/services/projects/compositions'
import PageHeader from '@/components/PageHeader.vue';
import ProjectTemplateList from '@/components/ProjectTemplateList.vue';
import ProjectTemplatePreviewModal from '@/components/ProjectTemplatePreviewModal.vue';

export default defineComponent({
  name: 'ViewTemplatesProjects',
  components: {
    PageHeader,
    ProjectTemplateList,
    ProjectTemplatePreviewModal,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    collections: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      clone: cloneProject,
      reset: cloneProjectReset,
      error: cloneProjectError,
      isPending: cloneProjectPending,
    } = useCloneProject()

    const { track } = useTracking()

    const selectedTemplate = ref(null);
    const isPreviewModalOpen = ref(false);

    const templates = computed(
      () => props.collections.projects.find(c => c.slug === props.slug)?.templates ?? []
    )

    const onTemplatePreview = template => {
      track('Project Template Previewed', {
        template: template.project$.name,
      })
      selectedTemplate.value = template
      isPreviewModalOpen.value = true
    }

    const onUseTemplate = async () => {
      if (cloneProjectPending.value) {
        return
      }

      track('Project Template Used', {
        template: selectedTemplate.value.project$.name,
      })

      await cloneProject(selectedTemplate.value.project$)
      selectedTemplate.value = null
      isPreviewModalOpen.value = false
      cloneProjectReset()
    }

    const onTemplateModalClosed = () => {
      track('Project Template Preview Modal Closed');
      selectedTemplate.value = null
      cloneProjectReset()
    }

    const onCreate = async template => {
      if (cloneProjectPending.value) {
        return
      }

      track('Project Template Used', {
        template: template.project$.name,
      })

      await cloneProject(template.project$)
      cloneProjectReset()
    }

    return {
      templates,
      selectedTemplate,
      onTemplatePreview,
      onUseTemplate,
      onCreate,
      isPreviewModalOpen,
      onTemplateModalClosed,
      cloneProjectPending,
      cloneProjectError,
    }
  },
})
</script>
