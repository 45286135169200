<template>
  <DocumentGet
    :id="documentId ?? folderId"
    v-slot="{ document, isGetPending: isPending }"
  >
    <template v-if="!isPending">
      <div v-if="document" class="flex-1 flex">
        <DocumentViewContext v-slot="{ viewContext, updateViewContext }">
          <DocumentTheme
            :organization="organization"
            :project="project"
            :document="document"
          >
            <Provide :provide="{ organization, project, document }">
              <div class="flex-1 flex">
                <router-view
                  :organization="organization"
                  :project="project"
                  :document="document"
                  :view-context="viewContext"
                  @update-view-context="updateViewContext"
                />
              </div>
            </Provide>
          </DocumentTheme>
        </DocumentViewContext>
      </div>
      <div v-else>Document no longer available.</div>
    </template>
  </DocumentGet>
</template>
<script>
import { defineComponent, provide, toRef } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useRouteParams } from '@/v2/lib/composition/useRouter'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import Provide from '@/components/Provide.vue'
import DocumentGet from '@/components/DocumentGet.vue'
import DocumentViewContext from '@/components/DocumentViewContext.vue'
import LoaderWebFont from '@/components/LoaderWebFonts.vue';
import DocumentTheme from '@/components/DocumentTheme.vue';

const { useState: useStateDocumentEditor } = createNamespacedHelpers('documentEditor')
const { useState } = createNamespacedHelpers('globalFlags')

const UIConfig = {
  [DOCUMENT_CATEGORY.ProjectPublicFolder]: {
    hideSidebar: true,
  },
  [DOCUMENT_CATEGORY.ProjectRootFolder]: {
    hideSidebar: true,
  },
}

export default defineComponent({
  name: 'ViewFS',
  components: {
    DocumentTheme,
    DocumentViewContext,
    DocumentGet,
    Provide,
    LoaderWebFont,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    folderId: {
      type: String,
      required: true,
    },
    isPinnedFolder: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    provide('isPinnedFolder', toRef(props, 'isPinnedFolder'))

    const { documentId } = useRouteParams(['documentId'])
    const { showAnnouncementCard } = useState(['showAnnouncementCard'])
    const { documentSidebarStatus } = useStateDocumentEditor(['documentSidebarStatus'])

    return {
      UIConfig,
      documentId,
      showAnnouncementCard,
      documentSidebarStatus,
    }
  },
});
</script>
