<template>
  <DocActions
    v-slot="{
      update,
      remove,
      duplicate,
      share,
      saveAsReusableBlock,
      download,
      changeFonts,
    }"
    :document="document"
  >
    <DocRenderer :document="document">
      <template #breadcrumbs>
        <DocLibraryBreadcrumbs :document="document" :category="category" />
      </template>
      <template #actions>
        <DocMenu
          :document="document"
          :active-items="{
            remove: true,
            duplicate: true,
            changeFonts: true,
          }"
          @remove="remove"
          @duplicate="duplicate"
          @change-fonts="changeFonts"
        />
      </template>
      <template #header>
        <DocTitle
          hide-icon
          :document="document"
          @update="title => update({ title })"
        />
      </template>

      <template #content>
        <DocumentEditorContent
          v-if="document"
          :document="document"
        />
        <DocApprovalBadge :document="document" />
      </template>
    </DocRenderer>
  </DocActions>
</template>

<script>
import { defineComponent, provide } from '@vue/composition-api'
import { ORGANIZATION_LIBRARY_PAGES } from '@/router/organization/type'
import { CATEGORY } from '@/v2/services/documents/documentsTypes'
import DocumentEditorContent from '@/components/Document/Editor/DocumentEditorContent.vue'
import DocApprovalBadge from '@/components/Doc/DocApprovalBadge.vue'
import DocLibraryBreadcrumbs from '@/components/Doc/DocLibraryBreadcrumbs.vue'
import DocMenu from '@/components/Doc/DocMenu.vue'
import DocRenderer from '@/components/Doc/DocRenderer.vue'
import DocTitle from '@/components/Doc/DocTitle.vue'
import DocUpdatedBy from '@/components/Doc/DocUpdatedBy.vue'
import DocPreviewToggle from '@/components/Doc/DocPreviewToggle.vue'
import DocStatusToggle from '@/components/Doc/DocStatusToggle.vue'
import DocActions from '@/components/Doc/DocActions.vue'
import DocSharedBadge from '@/components/Doc/DocSharedBadge.vue'

export default defineComponent({
  name: 'DocumentEditorPage',
  components: {
    DocumentEditorContent,
    DocApprovalBadge,
    DocLibraryBreadcrumbs,
    DocMenu,
    DocRenderer,
    DocTitle,
    DocUpdatedBy,
    DocPreviewToggle,
    DocStatusToggle,
    DocActions,
    DocSharedBadge,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  setup() {
    provide('hideNodeComments', true)
    provide('hideNodeActionItems', true)

    return {
      ORGANIZATION_LIBRARY_PAGES,
      category: CATEGORY.DocumentPage,
    }
  },
})
</script>
