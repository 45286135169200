<template>
  <div v-fragment>
    <ContextMenu
      v-if="contextMenu.nodeId === node._id && config.menu.enabled"
      :label="label"
      :left="contextMenu.left"
      :top="contextMenu.top"
      @close="onContextMenuClose"
    >
      <b-dropdown-item
        v-if="!isSpecificEmbed"
        :disabled="!(config.block.enableMove && showMoveUp)"
        @click="onMoveUp"
      >
        <span
          :class="['cm-icon icon_v2-so_arrow-up inline-block mr-8']"
        />
        <span>Move Up</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isSpecificEmbed"
        :disabled="!(config.block.enableMove && showMoveUp)"
        @click="onMoveUp"
      >
        <span
          :class="['cm-icon icon_v2-so_arrow-right inline-block mr-8',
                   'transform rotate-180']"
        />
        <span>Move Left</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isSpecificEmbed"
        :disabled="!(config.block.enableMove && showMoveDown)"
        @click="onMoveDown"
      >
        <span
          :class="['cm-icon icon_v2-so_arrow-right inline-block mr-8']"
        />
        <span>Move Right</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="!isSpecificEmbed"
        :disabled="!(config.block.enableMove && showMoveDown)"
        @click="onMoveDown"
      >
        <span
          :class="['cm-icon icon_v2-so_arrow-down inline-block mr-8']"
        />
        <span>Move Down</span>
      </b-dropdown-item>
      <b-dropdown-divider />
      <!-- NOTE: Case Studies and Team Bios within a group should not be copied.
      The entire group can be copied if necessary -->
      <b-dropdown-item :disabled="isSpecificEmbed" @click="cutNodes">
        <span class="inline-block mr-8 cm-icon icon_v2-so_cut"></span>
        <span>Cut</span>
      </b-dropdown-item>
      <b-dropdown-item :disabled="isCopyProhibited" @click="copyNodes">
        <span class="inline-block mr-8 cm-icon icon_v2-so_docs-files"></span>
        <span>Copy</span>
      </b-dropdown-item>
      <b-dropdown-item
        :disabled="isClipboardEmpty || isPasteProhibited"
        @click="pasteNodes('before')"
      >
        <span class="inline-block mr-8 cm-icon icon_v2-so_clipboard-before"></span>
        <span>Paste before</span>
      </b-dropdown-item>
      <b-dropdown-item
        :disabled="isClipboardEmpty || isPasteProhibited"
        @click="pasteNodes('after')"
      >
        <span class="inline-block mr-8 cm-icon icon_v2-so_clipboard-after"></span>
        <span>Paste after</span>
      </b-dropdown-item>
      <b-dropdown-divider />
      <!-- NOTE: Case Studies and Team Bios within a group should not be duplicated, only deleted.
      The entire group can be duplicated if necessary -->
      <b-dropdown-item
        v-if="!isSpecificEmbed"
        :disabled="!config.menu.duplicate"
        @click="onDuplicate"
      >
        <span class="inline-block mr-8 cm-icon icon_v2-so_copy-done"></span>
        <span>Duplicate</span>
      </b-dropdown-item>
      <b-dropdown-item
        :disabled="!config.menu.remove"
        @click="onRemove"
      >
        <span class="inline-block mr-8 cm-icon icon_v2-so_trash"></span>
        <span>Delete</span>
      </b-dropdown-item>

      <portal-target :name="`node-context-menu-${node._id}`" slim />
    </ContextMenu>

    <div
      v-show="isHighlightEnabled"
      :class="[
        'node-highlight',
        {
          hover: isHover,
          focus: isFocus,
          'hover-rounded': roundedSelection,
        },
      ]"
    />
    <!-- DEBUG -->
    <div
      v-if="debug"
      class="debug"
    >
      <span>▶</span>
      <span>category: <strong>{{ node.category }}</strong></span>
      <span>_id: <strong>{{ node._id }}</strong></span>
      <span>hov: <strong>{{ isHover }}</strong></span>
      <span>fcs: <strong>{{ isFocus }}</strong></span>
      <span>emb: <strong>{{ node.hasEmbeddedDocument }}</strong></span>
    </div>
    <!-- DEBUG -->
    <DocumentNodeEditorToolbarImpl
      v-if="config.block.enableStylingToolbar && (isFocus || filesUploadingNode[node._id] > 0) "
      v-show="isFocus && !focusDisabled && !isSelected && !isTextEditorToolbarVisible"
      :column="column"
      :node-id="node._id"
      :value="styling"
      :config="config"
      :text-variant="textVariant"
      @input="onStylingChange"
      @close="setFocus(null)"
      @duplicate="onDuplicate"
      @remove="onRemove"
      @bg-image-upload-start="onBgImageUploadStart"
      @bg-image-upload-progress="onBgImageUploadProgress"
      @bg-image-upload-done="onBgImageUploadDone"
    />
    <DocumentNodeEditorActions
      v-if="(isHover || isFocus || isSelected || isCreateMenuOpen) && !isTextEditorToolbarVisible"
      :document-id="document._id"
      :node="node"
      :parent="parent"
      :index="index"
      :accepts="config.block.accepts"
      :is-focus="isFocus"
      :show-move-up="config.block.enableMove && showMoveUp"
      :show-move-down="config.block.enableMove && showMoveDown"
      :show-create-before="config.block.enableCreateBefore && !isFocus"
      :grid="grid"
      @move-up="$emit('move', -1)"
      @move-down="$emit('move', 1)"
      @remove="onRemove"
      @menu-visibility-change="onCreateMenuVisbilityChange"
      @duplicate="onDuplicate"
    />
    <!-- BLOCK TYPE LABEL -->
    <span
      v-if="config.block.enableBlockLabel"
      class="absolute leading-none pointer-events-none right-22 top-4 text-13 z-1 print:hidden"
      :class="[`variant-${textVariant}-color opacity-40`,
               isHover && 'opacity-100'
      ]"
    >
      {{ label }}
    </span>
    <div
      v-if="bgImageUploadState.isUploading"
      class="bg-image-upload-progress"
    >
      <ImageThumbnail
        :url="bgImageUploadState.localUrl"
        :width="100"
        :height="100"
      />
      <PieProgressBar
        :ratio="bgImageUploadState.progress"
        class="bg-image-pie"
      />
      <span>Uploading background image...</span>
    </div>
  </div>
</template>

<script>
import { computed, reactive, inject, defineComponent, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useMsgBoxConfirmDelete } from '@/v2/lib/composition/useMsgBox'
import { useStructureRemoveNode } from '@/v2/services/documentStructures/documentStructuresCompositions'
import { useDuplicateNode, usePasteNodesFromClipboard } from '@/v2/services/documentNodes/documentNodesCompositions'
import { CATEGORY as NODE_CATEGORY } from '@/v2/services/documentNodes/documentNodesTypes'
import PieProgressBar from '@/components/Blocks/ProgressBar/PieProgressBar.vue'
import ImageThumbnail from '@/components/Blocks/Image/ImageThumbnail.vue'
import ContextMenu from '@/components/ContextMenu.vue'
import DocumentNodeEditorToolbarImpl from './DocumentNodeEditorToolbarImpl.vue'
import DocumentNodeEditorActions from './DocumentNodeEditorActions.vue'

const { useMutations, useState, useActions, useGetters } = createNamespacedHelpers('documentEditor')

export default defineComponent({
  name: 'DocumentNodeEditor',
  components: {
    PieProgressBar,
    DocumentNodeEditorToolbarImpl,
    DocumentNodeEditorActions,
    ImageThumbnail,
    ContextMenu,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    parent: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: 'Block',
    },
    textVariant: {
      type: String,
      required: true,
    },
    isFirst: Boolean,
    isLast: Boolean,
    roundedSelection: Boolean,
    grid: Boolean,
  },
  setup(props, context) {
    const document = inject('document')

    const isCreateMenuOpen = ref(false)

    const msgBoxConfirmDelete = useMsgBoxConfirmDelete()
    const structureRemoveNode = useStructureRemoveNode()
    const duplicateNode = useDuplicateNode()
    const pasteNodesUsingClipboard = usePasteNodesFromClipboard()
    const {
      hoverNodeId, focusNodeId, lastCreatedNodeId,
      contextMenu, selectedNodes, focusDisabled, isTextEditorToolbarVisible, filesUploadingNode,
    } = useState([
      'hoverNodeId',
      'focusNodeId',
      'lastCreatedNodeId',
      'contextMenu',
      'selectedNodes',
      'focusDisabled',
      'isTextEditorToolbarVisible',
      'filesUploadingNode',
    ])

    const isHover = computed(() => props.config.block.enableHover
      && props.node._id === hoverNodeId.value
      && !focusDisabled.value)

    const isFocus = computed(
      () => props.config.block.enableFocus && props.node._id === focusNodeId.value
    )
    const isSelected = computed(() => selectedNodes.value.includes(props.node._id))

    const showMoveUp = computed(() => props.config.menu.moveUp && !props.isFirst)
    const showMoveDown = computed(() => props.config.menu.moveDown && !props.isLast)


    /** Is this node rendering a Case Study or Team Bio type of Content Block? */
    const { isSpecificEmbed } = props.node
    const { isGroupColumn } = props.node
    const { isClipboardEmpty } = useGetters(['isClipboardEmpty'])
    const isCopyProhibited = isSpecificEmbed
      || props.node.hasEmbeddedDocument
    /** `true` if nodes in clipboard cannot be copied here, `false` otherwise */
    const isPasteProhibited = isSpecificEmbed// Cannot paste in node groups

    // Only show the blue highlight on a node if certain criteria is met
    // - the node is NOT selected and NOT a group column
    // - the node IS focused or hovered over
    const isHighlightEnabled = computed(() => {
      if (isSelected.value) return false
      if (isGroupColumn) return false
      if (isHover.value || isFocus.value) return true
      return false
    })
    // methods
    //
    const { setMenuOpen, setFocus, setFilesUploadingNode } = useMutations([
      'setMenuOpen',
      'setFocus',
      'setFilesUploadingNode',
    ])
    const {
      clearContextMenuData,
      copyNodesUsingClipboard,
      cutNodesUsingClipboard,
    } = useActions([
      'clearContextMenuData',
      'copyNodesUsingClipboard',
      'cutNodesUsingClipboard',
    ])

    const column = computed(() => props.node.category === NODE_CATEGORY.NodeGroupColumn)

    const onCreateMenuVisbilityChange = isOpen => {
      setMenuOpen(isOpen ? props.node._id : null)
      isCreateMenuOpen.value = isOpen
      // remove focus from whatever node had it before when opening the menu
      if (isOpen) setFocus(null)
    }

    const onStylingChange = newStyling => {
      context.emit('update', { styling: newStyling })
    }

    const onDuplicate = () => duplicateNode(props.node)

    const onMoveUp = () => context.emit('move', -1)
    const onMoveDown = () => context.emit('move', 1)

    const onRemove = () => msgBoxConfirmDelete({
      title: `Delete ${props.label}`,
      message: `Are you sure you want to delete this ${props.label}? This action is irreversible.`,
    })
      .then(result => result && structureRemoveNode({ id: props.node._id }))
      .catch(console.error)

    // bg image
    //
    const bgImageUploadState = reactive({
      isUploading: false,
      progress: 0,
      localUrl: null,
    })

    const onBgImageUploadStart = localUrl => {
      context.emit('async-save-start')
      setFilesUploadingNode({ nodeId: props.node._id, isUploading: true })
      Object.assign(bgImageUploadState, {
        progress: 0,
        isUploading: true,
        localUrl,
      })
    }

    const onBgImageUploadProgress = progress => {
      bgImageUploadState.progress = progress
    }

    const onBgImageUploadDone = () => {
      setFilesUploadingNode({ nodeId: props.node._id, isUploading: false })
      Object.assign(bgImageUploadState, {
        progress: 0,
        isUploading: false,
        localUrl: null,
      })
    }

    const onContextMenuClose = clearContextMenuData


    const copyNodes = () => copyNodesUsingClipboard([props.node._id])
    const cutNodes = () => cutNodesUsingClipboard([props.node._id])
    const pasteNodes = location => {
      // Index of current node in document's structure.
      const { index: currentNodeIndex } = props
      // If pasting before, use current index and increment by 1 if pasting after
      const offset = location === 'before' ? 0 : 1
      const targetIndex = currentNodeIndex + offset
      pasteNodesUsingClipboard({ targetIndex, targetBranch: props.parent })
    }

    // debug
    const debug = Boolean(localStorage.getItem('DEBUG'))

    return {
      // styling
      // cssClass,
      debug,
      lastCreatedNodeId,

      // state
      document,
      bgImageUploadState,
      contextMenu,

      // methods
      setFocus,
      copyNodes,
      pasteNodes,
      cutNodes,

      // event handlers
      onStylingChange,
      onCreateMenuVisbilityChange,
      onDuplicate,
      onRemove,
      onBgImageUploadStart,
      onBgImageUploadProgress,
      onBgImageUploadDone,
      onContextMenuClose,
      onMoveUp,
      onMoveDown,

      // flags
      isFocus,
      isHover,
      showMoveUp,
      showMoveDown,
      isClipboardEmpty,
      isCopyProhibited,
      isPasteProhibited,
      isSpecificEmbed,
      isGroupColumn,
      isSelected,
      isHighlightEnabled,
      focusDisabled,
      isTextEditorToolbarVisible,
      column,
      filesUploadingNode,
      isCreateMenuOpen,
    }
  },
})
</script>

<style lang="scss" scoped>

.bg-image-pie {
  position: absolute;
  margin-left: 32px;
}

.debug {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #444444;
  background-color: #a5e2ff;
  display: flex;
  font-size: 0.8em;
  & > * {
    margin-right: 20px;
  }
  z-index: 10;
}

.node-highlight {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  outline-offset: -2px;
  z-index: auto;
  @apply print:hidden;

  &.hover {
    @apply border border-green-500 border-dashed;
    // outline: 1px dashed $primary;
    // border-top-width: 2px;
    // border-top-style: solid;
    z-index: 1;

    &.hover-rounded {
      outline: none;
      // border: 2px solid $primary;
      @apply rounded-lg;
    }

    &.hover-border-top {
      // border-top-color: $primary;
    }
  }

  &.focus {
    @apply ring-2 ring-green-500 z-1;

    @apply transition-all;
    // outline: 2px solid $primary;
    // box-shadow: 0 0 12px 0 rgba(0, 152, 235, 0.4);
  }

  &.active {
    &::after {
      content: '';
      background-color: red;
      position: absolute;
      left: 0;
      right:0;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
  }
}

.bg-image-upload-progress {
  color: white;
  background-color: #0000007a;
  padding: 8px;
  display: flex;
  align-items: center;
  position: absolute;
  left: $gutter;
  bottom: $gutter;

  & > :last-child {
    margin-left: $gutter;
  }
}

.variant-light-color {
  @apply text-white;
}

.variant-dark-color {
  @apply text-gray-700;
  mix-blend-mode: difference;
}
</style>

