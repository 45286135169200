
<WithSidebar>
  <template #sidebar-header>
    <h2 class="font-semibold text-16">Templates</h2>
  </template>
  <template #sidebar>
    <OrganizationTemplatesNav :collections="collections" />
  </template>
  <template #default>
    <router-view :collections="collections" />
  </template>
</WithSidebar>
