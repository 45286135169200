<template>
  <div>
    <PageHeader title="Integrations">
      <template #actions>
        <Button
          v-track="['New Integration Modal Opened']"
          type="button"
          variant="neutral"
          size="md"
          @click="edit(null)"
        >
          <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600" />
          Add Custom Code
        </Button>
      </template>
    </PageHeader>
    <InfoBox
      title="Add 3rd party widgets &amp; code integrations."
      icon="icon_v2-so_diamond"
    >
      Integrations will be active on every page of your client facing portal.
    </InfoBox>
    <div class="mt-8 mb-32">
      <p
        class="inline p-8 leading-none bg-yellow-100 rounded-md text-13 text-black/70"
      >
        <strong>🚨 SuperOkay doesn't validate your code.</strong>
        &nbsp;Please make sure your code is correct and test the integration.
      </p>
    </div>
    <!-- Quick Embed Chat -->
    <div class="mb-64">
      <h3 class="settings-section-title">
        Chat Widgets
      </h3>
      <div class="flex flex-row flex-wrap gap-16 mb-24 max-w-page">
        <QuickEmbedApp
          v-for="widget in chatWidgets"
          :key="widget.name"
          :app="widget"
          @click="preselect(widget)"
        />
      </div>
    </div>

    <Form
      v-if="state"
      v-slot="{ isBusy, submit, hasChanges }"
      :state="state"
      success-message="Project changes saved"
      :on-submit="() => save()"
    >
      <div class="flex flex-col max-w-page">
        <!-- Pinned Folders -->
        <b-form-group>
          <SlickList
            v-if="state.portalIntegrations.length"
            :value="state.portalIntegrations"
            use-drag-handle
            lock-to-container-edges
            axis="xy"
            class="gap-16 mx-16 space-y-16 sm:mx-16 max-w-page"
            helper-class="so-table-row-dragging"
            @input="reorder"
          >
            <SlickItem
              v-for="(item, index) in state.portalIntegrations"
              :key="item._id"
              :index="index"
              class="p-0 mb-16 overflow-visible card-flat-interactive"
            >
              <ActionsToolbar
                show-drag
                card-drag
                show-delete
                show-edit
                class="p-16"
                @remove="remove(item)"
                @edit="edit(item)"
              >
                <template #default>
                  <div class="flex items-center flex-1 px-12 space-x-16 space-y-4 text-left">
                    <span class="text-gray-400 icon-toolbar-code text-40"></span>
                    <div>
                      <h6
                        class="
                          font-semibold leading-none text-gray-800 text-18
                          portal-heading-font dark:text-darkGray-300
                        "
                      >
                        {{ item.name }}
                      </h6>
                      <p
                        class="
                          font-normal truncate text-14 opacity-40
                          dark:text-darkGray-400 max-w-256
                        "
                      >
                        {{ item.customCode }}
                      </p>
                    </div>
                  </div>
                </template>
              </ActionsToolbar>
            </SlickItem>
          </SlickList>
        </b-form-group>
        <!-- End of Pinned Folders -->
      </div>
      <PageFooter>
        <Button
          :disabled="isBusy"
          size="lg"
          variant="primary"
          type="submit"
          @click="submit"
        >
          Save Changes
        </Button>
        <template v-if="hasChanges" #right>
          <PageFooterMessage />
        </template>
      </PageFooter>
    </Form>
    <ProjectSettingsIntegrationModal
      v-if="isModalOpen"
      v-model="isModalOpen"
      :integration-item="selectedItem"
      @create="create"
      @update="update"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { SlickList, SlickItem } from 'vue-slicksort'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'
import PageFooter from '@/components/PageFooter.vue'
import Form from '@/components/Form.vue'
import Button from '@/components/Button.vue'
import PageHeader from '@/components/PageHeader.vue'
import ProjectSettingsIntegrationModal from '@/components/ProjectSettingsIntegrationModal.vue'
import ActionsToolbar from '@/components/ActionsToolbar.vue'
import QuickEmbedApp from '@/components/QuickEmbedApp.vue'
import InfoBox from '@/components/InfoBox.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'

const customCodeMessage = 'The code you add is inserted at the very beginning of the page\'s <body> tag.'

const chatWidgets = [
  {
    name: 'Intercom',
    logo: 'intercom',
    instructions: [
      {
        title: 'Get your code from Intercom',
        description: 'Copy the embed code snippet from Intercom and paste it here.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/2802fa1a1b81304ad966201019655f615201cf5a777185758ea564e38156dedb.png',
        readMore: 'https://www.intercom.com/help/en/articles/167-install-intercom-on-your-website-and-chat-with-logged-out-visitors',
        readMoreLabel: '👉 Installing the Intercom Messenger Guide',
      },
    ],
  }, {
    name: 'Tidio',
    logo: 'tidio',
    instructions: [
      {
        title: 'Get your code from Tidio',
        description: 'Copy your unique Tidio javascript code and paste it here.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/fcfe7ca343f7b551e139067184fdc0a13bca6ac69c460548b52164f01c530a9c.jpeg',
        readMore: 'https://help.tidio.com/docs/install-tidio-on-your-website',
        readMoreLabel: '👉 Installing Tidio Guide',
      },
    ],
  },
  {
    name: 'Hubspot',
    logo: 'hubspot',
    instructions: [
      {
        title: 'Add your HubSpot tracking code',
        description: 'You need to add the Hubspot tracking code before you create a live chat. The live chat will not appear if the tracking code is not installed.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/3678a29737a90e1ea1d6d14350acb25a7316188427230652d1d548c957ce3775.png',
        readMore: 'https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code',
        readMoreLabel: '👉 Installing the Hubspot tracking code',
      }, {
        title: 'Create your live chat on HubSpot',
        description: 'You need to add the Hubspot tracking code before you create a live chat. The live chat will not appear if the tracking code is not installed.',
        readMore: 'https://knowledge.hubspot.com/chatflows/create-a-live-chat',
        readMoreLabel: '👉 Creating a live chat on HubSpot',
      },
    ],
  },
  {
    name: 'Botstar',
    logo: 'botstar',
    instructions: [
      {
        title: 'Get your code from Botstar',
        description: 'Copy your unique Botstar javascript code and paste it here.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/519caa8515aaded193ad9eca76e671a8e18de985fc75cb2fc76dceafa31aae12.png',
        readMore: 'https://docs.botstar.com/docs/en/integrate-botstar-with-website/',
        readMoreLabel: '👉 Installing Botstar Guide',
      },
    ],
  },
  {
    name: 'Froged',
    logo: 'froged',
    instructions: [
      {
        title: 'Get your code from Froged',
        description: 'Copy your unique Froged javascript code and paste it here.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/99b871f0428738579faee38dc560c5755b54169abc60e01a39c9aec72431f3f0.jpeg',
        readMore: 'https://support.froged.com/docs/en/25655815-install-froged-script',
        readMoreLabel: '👉 Installing Froged Guide',
      },
    ],
  },
  {
    name: 'Continually',
    logo: 'continually',
    instructions: [
      {
        title: 'Get your code from Continually',
        description: 'Copy your unique Continually javascript code and paste it here.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/316b42ed59dd17d281fdb074950f22aadb21cbe3b5b6cec5ff2b05a3edf5cc84.png',
        readMore: 'https://help.continual.ly/article/4-how-to-install-continually-on-your-site',
        readMoreLabel: '👉 Installing Continually Guide',
      },
    ],
  }, {
    name: 'MyAlice',
    logo: 'myalice',
    instructions: [
      {
        title: 'Get your Webchat code from MyAlice',
        description: 'Paste the code snippet from MyAlice here.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/98489da7683487bf14128415ae186dbcfe99c6a397969f2dd3859b083689e1e4.png',
        readMore: 'https://docs.myalice.ai/connect-social-channels/connect-web-app/connect-live-chat#add-a-web-chat-widget-on-your-website',
        readMoreLabel: '👉 Installing MyAlice Guide',
      },
    ],
  }, {
    name: '13Chats',
    logo: '13chats',
    instructions: [
      {
        title: 'Get your code from 13Chats',
        description: 'Open the Share window and choose the level of access for Anyone with the integration.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/f69d963ad5d0562755f7d694d8b1659e63ad6e63999e7cdc5a2e5bc1050a1188.png',
        readMore: '',
        readMoreLabel: '👉 Installing 13Chats Guide',
      },
    ],
  }, {
    name: 'Sendinblue',
    logo: 'sendinblue',
    instructions: [
      {
        title: 'Get your code from Sendinblue',
        description: 'Open the Share window and choose the level of access for Anyone with the integration.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/3ea480fde4928411954b8ffbd2bd96cfd28f926cf92c258a59777f7d2a4c48d6.png',
        readMore: '',
        readMoreLabel: '👉 Installing SendInBlue Guide',
      },
    ],
  }, {
    name: 'Chative',
    logo: 'chative',
    instructions: [
      {
        title: 'Get your code from Chative',
        description: 'Open the Share window and choose the level of access for Anyone with the integration.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/3ea480fde4928411954b8ffbd2bd96cfd28f926cf92c258a59777f7d2a4c48d6.png',
        readMore: '',
        readMoreLabel: '👉 Installing Chative Guide',
      },
    ],
  },
]

export default defineComponent({
  name: 'ViewProjectSettingsIntegrations',
  components: {
    PageHeader,
    PageFooter,
    Form,
    Button,
    SlickList,
    SlickItem,
    ActionsToolbar,
    ProjectSettingsIntegrationModal,
    QuickEmbedApp,
    InfoBox,
    PageFooterMessage,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      state,
      save,
    } = useFormEntityEditor({
      model: 'Project',
      id: computed(() => props.project._id),
      fields: computed(() => ['portalIntegrations']),
    })

    const isModalOpen = ref(false)
    const selectedItem = ref(null)

    const preselect = integration => {
      selectedItem.value = {
        icon: integration.icon,
        name: integration.name,
        instructions: integration.instructions,
      }

      isModalOpen.value = true
    }

    const edit = integration => {
      selectedItem.value = integration
      isModalOpen.value = true
    }

    const create = integration => {
      state.value.portalIntegrations = [...state.value.portalIntegrations, integration]
      isModalOpen.value = false
    }

    const update = integration => {
      const index = state.value.portalIntegrations.findIndex(({ _id }) => _id === integration._id)
      Vue.set(state.value.portalIntegrations, index, integration)
      isModalOpen.value = false
    }

    const remove = integration => {
      const index = state.value.portalIntegrations.findIndex(({ _id }) => _id === integration._id)
      state.value.portalIntegrations.splice(index, 1)
    }

    const reorder = items => {
      state.value.portalIntegrations = items
    }

    return {
      state,
      save,
      create,
      update,
      remove,
      reorder,
      isModalOpen,
      edit,
      chatWidgets,
      customCodeMessage,
      preselect,
      selectedItem,
    }
  },
})
</script>
