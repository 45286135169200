
<FeathersVuexFind
  v-slot="{ items: documents }"
  service="documents"
  :params="findParams"
  watch="params"
>
  <div>
    <slot name="header" v-bind="{ isEmpty: !documents.length }" />
    <slot v-if="!documents.length" name="placeholder" />
    <template v-if="documents.length">
      <slot name="folders" />
      <slot name="list" v-bind="{ documents, open, remove }" />
      <template v-if="hasDefaultSlot">
        <div
          v-for="document in documents"
          :key="document._id"
          :class="{ 'auto-margin': autoMargin }"
        >
          <slot
            v-bind="{
              document,
              open: () => open(document),
              remove: () => remove(document),
            }"
          />
        </div>
      </template>
    </template>
  </div>
</FeathersVuexFind>
