<template>
  <DocActions
    v-slot="{
      update,
      remove,
      duplicate,
      share,
      saveAsReusableBlock,
      download,
      changeFonts,
    }"
    :document="document"
  >
    <DocRenderer :document="document">
      <template #breadcrumbs>
        <DocLibraryBreadcrumbs :document="document" :category="category" />
      </template>
      <template #actions>
        <DocMenu
          :document="document"
          :active-items="{
            share: true,
            remove: true,
            download: true,
            duplicate: true,
            saveAsReusableBlock: true,
            changeFonts: true,
          }"
          @remove="remove"
          @duplicate="duplicate"
          @share="share"
          @download="download"
          @save-as-reusable-block="saveAsReusableBlock"
          @change-fonts="changeFonts"
        />
      </template>
      <template #header>
        <DocTitle
          :document="document"
          @update="title => update({ title })"
        />
        <DocSharedBadge :document="document" @click="share" />
      </template>

      <template #footer-left>
        <DocUpdatedBy :document="document" />
      </template>
      <template #footer-right>
        <DocPreviewToggle :document="document" />
        <DocStatusToggle
          :value="document.status"
          data-intercom-target="Document Status Select"
          @input="status => update({ status })"
        />
      </template>

      <template #content>
        <!-- PREVIEW CARD EDITOR -->
        <section
          v-if="!isViewer"
          class="
          transition-all duration-300 ease-in-out
          flex flex-col mt-24 mb-24
           lg:flex-row
          lg:justify-between rounded-xl
        "
        >
          <!-- (!documentSidebarStatus.isExpanded && portalHasContentRightSidebar) && 'mr-76',
          (documentSidebarStatus.isExpanded && portalHasContentRightSidebar) && '2xl:mr-420' -->

          <div class="mb-16 lg:mb-0 lg:w-4/12 flex items-center justify-center">
            <div class="max-w-320">
              <h3 class="font-bold text-gray-700 text-24 lg:mb-16">
                Preview Card
              </h3>
              <p class="pr-32 leading-snug text-gray-600 text-16">
                The preview of this Packaged Service as it will appear when included
                in documents.
              </p>
            </div>
          </div>
          <form class="lg:w-7/12">
            <div
              :class="$style.card"
              class="
              relative flex flex-col pb-24 space-y-24 overflow-hidden bg-white border
              shadow justify-stretch border-gray200 rounded-xl
            "
            >
              <ImageThumbnailUpload
                variant="cover2xl"
                :height="256"
                :value="document.imageUrl"
                @input="imageUrl => update({ imageUrl })"
              />
              <div class="flex flex-col px-24 space-y-16">
                <!-- Title -->
                <FormField
                  class="text-gray-900 text-24"
                >
                  <b-form-input
                    :value="document.title"
                    placeholder="Packaged service name"
                    @change="title => update({ title })"
                  />
                </FormField>
                <!-- Description -->
                <FormField
                  :description="`Short Description: ${descLength} / ${maxDescLength} chars`"
                >
                  <b-form-textarea
                    max-rows="8"
                    rows="4"
                    :maxlength="maxDescLength"
                    placeholder="Enter a short description..."
                    :value="document.description"
                    @input="updateDescLength"
                    @change="description => update({ description })"
                  />
                </FormField>
              </div>
            </div>
          </form>
        </section>
        <DocumentEditorContent
          v-if="document"
          :document="document"
        />
        <DocApprovalBadge :document="document" />
      </template>
    </DocRenderer>
  </DocActions>
</template>

<script>
import { pipe, length, defaultTo } from 'ramda'
import { defineComponent, provide, inject, ref } from '@vue/composition-api'
import { ORGANIZATION_LIBRARY_REUSABLE_BLOCKS } from '@/router/organization/type'
import { CATEGORY } from '@/v2/services/documents/documentsTypes'
import FormField from '@/components/FormField.vue'
import ImageThumbnailUpload from '@/components/Blocks/Image/ImageThumbnailUpload.vue'
import DocumentEditorContent from '@/components/Document/Editor/DocumentEditorContent.vue'
import DocApprovalBadge from '@/components/Doc/DocApprovalBadge.vue'
import DocLibraryBreadcrumbs from '@/components/Doc/DocLibraryBreadcrumbs.vue'
import DocMenu from '@/components/Doc/DocMenu.vue'
import DocRenderer from '@/components/Doc/DocRenderer.vue'
import DocTitle from '@/components/Doc/DocTitle.vue'
import DocUpdatedBy from '@/components/Doc/DocUpdatedBy.vue'
import DocPreviewToggle from '@/components/Doc/DocPreviewToggle.vue'
import DocStatusToggle from '@/components/Doc/DocStatusToggle.vue'
import DocActions from '@/components/Doc/DocActions.vue'
import DocSharedBadge from '@/components/Doc/DocSharedBadge.vue'

const MAX_DESC_LENGTH = 150
const getLength = pipe(length, defaultTo(0))

export default defineComponent({
  name: 'DocumentEditorPackagedService',
  components: {
    FormField,
    ImageThumbnailUpload,
    DocumentEditorContent,
    DocApprovalBadge,
    DocLibraryBreadcrumbs,
    DocMenu,
    DocRenderer,
    DocTitle,
    DocUpdatedBy,
    DocPreviewToggle,
    DocStatusToggle,
    DocActions,
    DocSharedBadge,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  setup() {
    provide('hideNodeComments', true)
    provide('hideNodeActionItems', true)

    const isViewer = inject('isViewer')

    const descLength = ref(0)

    const updateDescLength = val => {
      descLength.value = getLength(val)
    }

    return {
      ORGANIZATION_LIBRARY_REUSABLE_BLOCKS,
      category: CATEGORY.DocumentPackagedService,
      maxDescLength: MAX_DESC_LENGTH,
      isViewer,
      descLength,
      updateDescLength,
    }
  },
})
</script>
<style module lang="scss">
.card {
  a {
    cursor: pointer;
    @media print {
      text-decoration: none;
    }
  }
  @media print {
    border: 1px solid rgba($gray-900, 0.1);
  }
}
</style>
