
<LayoutSignup>
  <img
    v-if="organization.logoUrl"
    :src="organization.logoUrl"
    :class="$style['agency-logo']"
    class="object-contain object-left"
  />
  <SignUpFormHeader>
    {{ organization.name }} invited you to join their team on
    SuperOkay
  </SignUpFormHeader>
  <div class="flex flex-row items-center justify-center space-x-8">
    <Button
      :class="$style.btn"
      size="md"
      type="button"
      variant="neutral"
      @click="goToDashboard"
    >
      I'll do it later
    </Button>
    <Button
      :class="$style.btn"
      size="md"
      type="button"
      variant="primary"
      @click="join"
    >
      Join {{ organization.name }}
    </Button>
  </div>
</LayoutSignup>
