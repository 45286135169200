<template>
  <FileUploader
    :multiple="false"
    accept="image/*"
    :block="true"
    :inline-block="false"
    @upload-start="onUploadStart"
    @file-progress="onFileProgress"
    @file-done="onFileDone"
    @file-error="onFileError"
  >
    <template #default="{ selectFiles }">
      <div
        :class="[
          'rounded-md max-w-[200px] tablet:max-w-none border',
          variant==='dark' && 'bg-gray-900 border-darkGray-500 dark',
          variant==='light' && 'bg-gray border-gray-200',
          className
        ]"
      >
        <div
          :style="cssStyle"
          :class="['flex relative items-center justify-center', hasUrl && 'px-16']"
        >
          <!-- <ImageThumbnail :url="url" v-if="hasUrl" :width="width" :height="height" /> -->
          <img
            v-if="hasUrl"
            :src="fileUrl"
            class="object-contain object-center w-full h-full"
          />
          <ImagePlaceholder
            v-else
            :width="`${width}px`"
            :display-width="displayWidth"
            :display-height="displayHeight"
            :height="`${height}px`"
          />
          <div
            v-if="fileUploadState.isUploading"
            class="absolute inset-0 flex items-center justify-center"
          >
            <PieProgressBar :ratio="fileUploadState.progress" />
          </div>
        </div>
        <div v-if="!hasSlotButtons">
          <!-- -->
          <b-button
            v-if="showButton"
            pill
            variant="primary"
            class="btn-icon"
            @click="selectFiles()"
          >
            <span class="w-20 mr-4 icon_v2-so_image text-20"></span>
            {{ buttonText }}
          </b-button>
          <b-link
            v-if="!hasSlotButtons && !fileUploadState.isUploading && hasUrl"
            @click.stop="removeFile"
          >
            Delete
          </b-link>
        </div>
      </div>
      <div v-if="hasSlotButtons" class="flex flex-row items-center gap-8 pt-16">
        <slot name="buttons" v-bind="{ selectFiles, removeFile }" />
      </div>
    </template>
  </FileUploader>
</template>

<script>
import { computed, watch } from '@vue/composition-api'
import PieProgressBar from '@/components/Blocks/ProgressBar/PieProgressBar.vue'
import { useFileUpload } from '@/v2/services/uploads/uploadsCompositions'
import FileUploader from '@/components/FileUploader/FileUploader.vue'
import ImagePlaceholder from '@/components/Blocks/Image/ImagePlaceholder.vue'

export default {
  name: 'LogoUpload',
  components: {
    PieProgressBar,
    FileUploader,
    ImagePlaceholder,
  },
  props: {
    value: String,
    width: Number,
    height: Number,
    displayWidth: {
      type: Number,
      default: null,
    },
    displayHeight: {
      type: Number,
      default: null,
    },
    variant: {
      type: String,
      default: 'light',
    },
    className: {
      type: String,
      default: 'p-16',
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, context) {
    const url = computed(() => props.value)
    const {
      state: fileUploadState,
      fileUrl,
      hasUrl,
      removeFile,
      onUploadStart,
      onFileProgress,
      onFileDone,
      onFileError,
      onUploadEnd,
    } = useFileUpload(url)

    const uploading = computed(() => fileUploadState.isUploading)
    watch(uploading, uploading => context.emit('uploading', uploading))

    const cssStyle = computed(() => ({
      width: props.width && `${props.width}px`,
      height: props.height && `${props.height}px`,
    }))
    const buttonText = computed(() => (hasUrl.value ? 'Replace' : 'Upload'))
    const hasSlotButtons = computed(() => Boolean(context.slots.buttons))

    return {
      cssStyle,
      buttonText,
      hasSlotButtons,

      // file upload
      fileUploadState,
      fileUrl,
      hasUrl,
      removeFile,
      onUploadStart,
      onFileProgress,
      onFileDone,
      onFileError,
      onUploadEnd,
    }
  },
}
</script>
