<template>
  <div :style="cssVars">
    <PageHeader title="Quick Links">
      <template #actions>
        <FeatureLimit
          v-slot="{ checkFeatureLimit }"
          label="quick links"
          flag="limitQuickLinks"
          @allow="() => edit(null)"
        >
          <b-button
            type="button"
            variant="neutral"
            size="md"
            @click="checkFeatureLimit"
          >
            <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600" />
            Add Quick Link
          </b-button>
        </FeatureLimit>
      </template>
    </PageHeader>
    <InfoBox
      title="Kickstart your project by adding some quicklinks."
      icon="icon_v2-so_interactive"
    >
      Add your most used links or bookmarks and they will appear on the portal dashboard.
    </InfoBox>
    <Form
      v-if="state"
      v-slot="{ isBusy, submit, hasChanges }"
      :state="state"
      success-message="Project changes saved"
      :on-submit="() => save()"
    >
      <div class="flex flex-col max-w-page">
        <!-- Pinned Folders -->
        <b-form-group>
          <SlickList
            :value="state.portalQuickLinks"
            use-drag-handle
            lock-to-container-edges
            axis="xy"
            class="flex flex-wrap gap-16 max-w-page"
            helper-class="so-table-row-dragging"
            @input="reorder"
          >
            <SlickItem
              v-for="(link, index) in state.portalQuickLinks"
              :key="link._id"
              :index="index"
              class="flex flex-col p-0 overflow-visible card-interactive relative"
            >
              <ActionsToolbar
                show-delete
                show-edit
                show-drag
                card-drag
                @edit="() => edit(link)"
                @remove="() => remove(link)"
              >
                <div class="px-24 py-32">
                  <ProjectQuickLink :link="link" />
                </div>
              </ActionsToolbar>
            </SlickItem>
          </SlickList>
        </b-form-group>
        <!-- End of Pinned Folders -->
      </div>
      <PageFooter>
        <Button
          :disabled="isBusy"
          size="lg"
          variant="primary"
          type="submit"
          @click="submit"
        >
          Save Changes
        </Button>
        <template v-if="hasChanges" #right>
          <PageFooterMessage />
        </template>
      </PageFooter>
    </Form>
    <ProjectSettingsQuickLinkModal
      v-if="isModalOpen"
      v-model="isModalOpen"
      :quick-link="quickLink"
      @create="create"
      @update="update"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { SlickList, SlickItem } from 'vue-slicksort'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'
import PageFooter from '@/components/PageFooter.vue'
import Form from '@/components/Form.vue'
import Button from '@/components/Button.vue'
import PageHeader from '@/components/PageHeader.vue'
import ProjectSettingsQuickLinkModal from '@/components/ProjectSettingsQuickLinkModal.vue'
import ProjectQuickLink from '@/components/ProjectQuickLink.vue'
import ActionsToolbar from '@/components/ActionsToolbar.vue'
import FeatureLimit from '@/components/FeatureLimit.vue'
import InfoBox from '@/components/InfoBox.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'

export default defineComponent({
  name: 'ViewProjectSettingsQuickLinks',
  components: {
    PageHeader,
    PageFooter,
    Form,
    Button,
    SlickList,
    SlickItem,
    ActionsToolbar,
    FeatureLimit,
    ProjectSettingsQuickLinkModal,
    ProjectQuickLink,
    InfoBox,
    PageFooterMessage,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      state,
      save,
    } = useFormEntityEditor({
      model: 'Project',
      id: computed(() => props.project._id),
      fields: computed(() => ['portalQuickLinks']),
    })

    // CSS VARS NEEDED FOR STYLING THE PREVIEW
    const cssVars = computed(() => ({
      '--cp-accent': state.value.portalAccentColor,
    }))

    const isModalOpen = ref(false)
    const quickLink = ref(null)

    const edit = link => {
      quickLink.value = link
      isModalOpen.value = true
    }

    const create = link => {
      state.value.portalQuickLinks = [...state.value.portalQuickLinks, link]
      isModalOpen.value = false
    }

    const update = link => {
      const index = state.value.portalQuickLinks.findIndex(({ _id }) => _id === link._id)
      Vue.set(state.value.portalQuickLinks, index, link)
      isModalOpen.value = false
    }

    const remove = link => {
      const index = state.value.portalQuickLinks.findIndex(({ _id }) => _id === link._id)
      state.value.portalQuickLinks.splice(index, 1)
    }

    const reorder = items => {
      state.value.portalQuickLinks = items
    }

    return {
      state,
      save,
      create,
      update,
      remove,
      reorder,
      isModalOpen,
      cssVars,
      edit,
      quickLink,
    }
  },
})
</script>
