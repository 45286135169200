import ViewUserSettings from '@/views/user-settings/ViewUserSettings.vue'
import ViewUserSettingsProfile from '@/views/user-settings/ViewUserSettingsProfile.vue'
import ViewUserSettingsSecurity from '@/views/user-settings/ViewUserSettingsSecurity.vue'
import ViewUserSettingsNotifications from '@/views/user-settings/ViewUserSettingsNotifications.vue'

import {
  USER_SETTINGS,
  USER_SETTINGS_PROFILE,
  USER_SETTINGS_SECURITY,
  USER_SETTINGS_NOTIFICATIONS,
} from './type'

export default () => [
  {
    name: USER_SETTINGS,
    path: 'user-settings',
    component: ViewUserSettings,
    redirect: {
      name: USER_SETTINGS_PROFILE,
    },
    children: [
      {
        name: USER_SETTINGS_PROFILE,
        path: 'profile',
        component: ViewUserSettingsProfile,
      },
      {
        name: USER_SETTINGS_SECURITY,
        path: 'security',
        component: ViewUserSettingsSecurity,
      },
      {
        name: USER_SETTINGS_NOTIFICATIONS,
        path: 'notifications',
        component: ViewUserSettingsNotifications,
      },
    ],
  },
]
