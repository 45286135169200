
<WithSidebar class="pb-16 min-h-full">
  <template #sidebar-header>
    <h2 class="font-semibold text-16">Settings</h2>
  </template>
  <template #sidebar>
    <OrganizationSettingsNav />
  </template>
  <template #default>
    <router-view
      :organization="organization"
      :licensing="licensing"
      :plan="plan"
    />
  </template>
</WithSidebar>
