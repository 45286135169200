<template>
  <div :class="$style['layout-guest-wrap']">
    <RadialGradients :key="$route.name" />
    <div :class="$style['layout-guest']">
      <slot name="header" />
      <main class="flex flex-col items-center flex-1 grow justify-center">
        <slot v-if="hasDefaultSlot" />
        <router-view v-else />
      </main>

      <footer
        v-if="footer"
        class="
          container flex flex-col items-center self-end
          justify-between flex-none w-full py-24 text-gray-500 md:flex-row text-14 h-76
        "
      >
        <span class="inline-flex items-center">
          Made with&nbsp;
          <span class="w-16 mx-2 text-red-600 icon-heart"></span>
          &nbsp;in London &amp; Bucharest
        </span>
        <p class="space-x-16">
          <b-link href="https://www.superokay.com/privacy-policy">
            Privacy Policy
          </b-link>
          <b-link href="https://www.superokay.com/terms-of-use">
            Terms Of Use
          </b-link>
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { GUEST_HOME } from '@/router/guest/type'
import RadialGradients from '@/components/RadialGradients.vue'

export default {
  name: 'LayoutGuest',
  components: {
    RadialGradients,
  },
  props: {
    footer: {
      type: Boolean,
      default: true,
    },
  },
  metaInfo: {
    title: 'SuperOkay',
    titleTemplate: '%s - SuperOkay',
  },

  staticData: {
    GUEST_HOME,
  },
  setup(props, context) {
    const hasDefaultSlot = computed(() => context.slots.default)

    return {
      hasDefaultSlot,
    }
  },
}
</script>

<style lang="postcss" module>
.layout-guest-wrap {
  @apply bg-white w-screen min-h-screen overflow-hidden;
  /* background-image: url('/assets/img/pattern.svg'); */
}

:global(.has-banner) .layout-guest {
  min-height: calc(100vh - 116px);
}

.layout-guest {
  @apply w-screen min-h-screen relative z-1 flex flex-col ;
}
</style>
