
<ButtonTransparent
  class="text-gray-600 cursor-pointer hover:text-gray-900 relative"
  :active="active"
  v-bind="$attrs"
  v-on="$listeners"
>
  <span class="icon-util-filter-outline text-20" />
  <div
    v-if="count"
    class="
      absolute
      -top-7
      -right-7
      w-14
      h-14
      text-white text-10
      bg-green-500
      flex
      justify-center
      items-center
      rounded-full
    "
  >
    {{ count }}
  </div>
</ButtonTransparent>
