
<svg
  xmlns="http://www.w3.org/2000/svg"
  :width="Math.round(vw * scale)"
  :height="Math.round(vh * scale)"
  :vw="vw"
  :vh="vh"
  :viewBox="viewbox"
  :aria-labelledby="iconName"
  role="presentation"
  class="inline-block align-baseline"
  :style="rotatedStyle"
>
  <g :fill="fillColor">
    <slot />
  </g>
</svg>
