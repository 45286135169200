
<LayoutGuest>
  <template #header>
    <div
      class="
        container flex flex-col items-center justify-center w-full py-16 space-x-16
        tablet:flex-row h-116 lg:py-34 lg:mb-0 mb-36
      "
    >
      <span
        class="
          w-40 mt-24 mb-16 text-white text-40 tablet:w-32 tablet:text-32
          icon-superokay-symbol tablet:mt-0 tablet:mb-0
        "
      />
      <ul
        class="
          flex justify-center flex-1 space-x-6 font-medium leading-snug text-gray-800
          tablet:items-center tablet:text-white text-13 tablet:space-x-17
        "
      >
        <template v-for="(step, index) in steps">
          <li
            :key="`${step.name}-label`"
            class="
              flex flex-col items-center text-center tablet:flex-row
              opacity-80 tablet:text-left
            "
          >
            <span
              class="
                inline-flex items-center justify-center
                w-32 h-32 tablet:mr-12 flex-none mb-4 tablet:mb-0
                border rounded-full
              "
              :class="{
                'text-gray-900 font-bold bg-white border-white': step.active,
                'opacity-60 tablet:border-white border-gray-900 border-opacity-50': !step.active,
              }"
            >
              <span v-if="index < activeStepIndex" class="w-16 icon_v2-so_tick text-16" />
              <span v-else>{{ index + 1 }}</span>
            </span> {{ step.label }}
          </li>
          <li
            v-if="index < steps.length - 1"
            :key="`${step.name}-separator`"
            class="mt-4 mb-auto"
          >
            <span
              class="
                inline-block w-16 h-2 bg-gray-800 tablet:bg-white tablet:w-24
                opacity-20 tablet:opacity-40
              "
            />
          </li>
        </template>
      </ul>
      <span class="w-48" />
    </div>
  </template>
  <template #default>
    <router-view />
  </template>
</LayoutGuest>
