<template>
  <div v-fragment>
    <div>
      <div
        v-if="contacts?.length > 0"
        :class="['relative flex flex-col', 'items-start flex-1']"
      >
        <div class="w-full">
          <ul class="flex flex-wrap items-center text-gray-700">
            <ClientContact
              v-for="contact in contacts"
              :key="contact._id"
              :contact="contact"
              class="hover:bg-gray-100 p-8"
              @send-invite="sendInvite"
              @request-magic-link-token="requestMagicLinkToken"
              @edit="$emit('edit-client-contact', contact.member._id)"
              @remove="remove"
            />
          </ul>
        </div>
      </div>
      <div v-else>
        <InfoBox
          icon="icon_v2-so_clients"
          title="Contacts List Empty"
        >
          No Contacts added to this Client Organization
        </InfoBox>
        <Button
          variant="primary"
          size="xl"
          @click="$emit('create-client-contact')"
        >
          <span class="mr-4 -ml-4 icon_v2-so_plus text-white"></span>
          Add First Contact
        </Button>
      </div>
    </div>
    <MagicLinkModal
      v-if="magicLinkUrl"
      v-model="magicLinkShowModal"
      :link="magicLinkUrl"
      @hidden="onMagicLinkModalClosed"
    />
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useMsgBoxConfirm } from '@/v2/lib/composition/useMsgBox'
import useNotification from '@/v2/lib/composition/useNotification'
import { useAccountsCreateAuthClient } from '@/v2/services/accounts/accountsCompositions'
import useDomain from '@/v2/services/domains/useDomain'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import { ORGANIZATION_CLIENT_DETAILS } from '@/router/organization/type'
import ClientContact from '@/components/ClientContact.vue'
import MagicLinkModal from '@/components/MagicLinkModal.vue'
import InfoBox from '@/components/InfoBox.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'ClientContacts',
  components: {
    ClientContact,
    MagicLinkModal,
    InfoBox,
    Button,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const organization = useCurrentOrganization()
    const msgBoxConfirm = useMsgBoxConfirm()
    const notification = useNotification()

    const isSendingInvite = ref(false)
    const sendInvite = async ({ user, member }) => {
      if (isSendingInvite.value) {
        return
      }

      const ok = await msgBoxConfirm({
        title: user.email,
        message: 'You are about to generate and email a new login link to your client contact.',
        okLabel: 'Send email',
        size: 'md',
      })

      if (ok) {
        isSendingInvite.value = true
        try {
          await member.patch({ data: { resendRequested: true } })
          notification({
            title: member.email,
            message: 'Portal access link has been emailed.',
          });

          // set(resendRequestMap, member._id, true)
        } catch (err) {
          notification({
            variant: 'danger',
            message: 'There has been an error processing your request',
          })
          console.error(err);
        } finally {
          isSendingInvite.value = false
        }
      }
    }

    // -- request magic link token
    const { withClientPortalUrl } = useDomain()
    const createAuthClient = useAccountsCreateAuthClient()
    const isRequestingMagicLinkToken = ref(false)

    const magicLinkUrl = ref(null)
    const magicLinkShowModal = ref(false)

    const openMagicLinkModal = token => {
      magicLinkUrl.value = withClientPortalUrl(
        organization,
        `auth/${token}`
      ).value

      magicLinkShowModal.value = true
    }

    const onMagicLinkModalClosed = () => {
      magicLinkUrl.value = null
      magicLinkShowModal.value = false
    }

    const requestMagicLinkToken = async ({ user, member }) => {
      if (isRequestingMagicLinkToken.value) {
        return
      }

      const ok = await msgBoxConfirm({
        title: user.email,
        message: 'Generating a new portal login link will cancel and replace any login links created before',
        okLabel: 'Generate new login link',
        size: 'md',
      })

      if (ok) {
        isRequestingMagicLinkToken.value = true
        try {
          const { result: { token } } = await createAuthClient({
            memberId: member._id,
            isInvite: true,
          });
          openMagicLinkModal(token)
        } catch (err) {
          notification({
            variant: 'danger',
            message: 'There has been an error processing your request',
          })
          console.error(err);
        } finally {
          isRequestingMagicLinkToken.value = false
        }
      }
    }

    const isContactRemovalPending = ref(false)
    const remove = async ({ member }) => {
      if (isContactRemovalPending.value) {
        return
      }

      const message = 'Are you sure you want to remove this client contact? 🚨 This action is irreversible.'
      const ok = await msgBoxConfirm({
        title: member.email,
        okVariant: 'danger',
        okLabel: 'Remove Client Contact',
        size: 'md',
        message,
      })

      if (ok) {
        isContactRemovalPending.value = true
        try {
          await member.remove()
          notification({
            title: member.email,
            message: 'Removal has been successful.',
          });
          context.emit('client-contact-removed')
        } catch (err) {
          notification({
            variant: 'danger',
            message: 'There has been an error processing your request',
          })
          console.error('Error removing client contact:', err);
        } finally {
          isContactRemovalPending.value = false
        }
      }
    }

    return {
      ORGANIZATION_CLIENT_DETAILS,

      organization,

      sendInvite,

      // -- remove
      remove,

      // -- magic link
      requestMagicLinkToken,
      onMagicLinkModalClosed,
      magicLinkUrl,
      magicLinkShowModal,
    }
  },
})
</script>
