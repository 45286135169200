<template>
  <div class="flex flex-col">
    <small class="flex-1 block text-gray-500 text-12 whitespace-nowrap">{{ label }}</small>
    <div class="flex items-center h-32"><slot /></div>
  </div>
</template>

<script>
export default {
  name: 'DocumentNodeEditorToolbarGroup',
  props: {
    label: String,
  },
}
</script>
