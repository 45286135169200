
<div>
  <PageHeader title="Document Library &amp; Billable Services" />
  <div>
    <InfoBox
      title="Reusable Blocks"
      icon="icon_v2-so_reusable-block"
      full-width
    >
      <div>
        Here is where you can save your own document templates or sections of
        existing documents you would like to use again in the future.
      </div>
      <div class="mt-8">
        <router-link
          class="font-bold underline"
          :to="{ name: ORGANIZATION_LIBRARY_REUSABLE_BLOCKS }"
        >
          Go to Reusable Blocks
        </router-link>
      </div>
    </InfoBox>
    <InfoBox
      title="Team Bios"
      icon="icon_v2-so_team-bios"
      full-width
    >
      <div>
        Here is where you can create and save pre-formatted Team Bios
        you could easily insert in your documents.
      </div>
      <div class="mt-8">
        <router-link
          class="font-bold underline"
          :to="{ name: ORGANIZATION_LIBRARY_TEAM_BIOS }"
        >
          Go to Team Bios
        </router-link>
      </div>
    </InfoBox>
    <InfoBox
      title="Case Studies"
      icon="icon_v2-so_case-study"
      full-width
    >
      <div>
        Here is where you can create and save pre-formatted Case Studies
        you could easily insert in your documents.
      </div>
      <div class="mt-8">
        <router-link
          class="font-bold underline"
          :to="{ name: ORGANIZATION_LIBRARY_CASE_STUDIES }"
        >
          Go to Case Studies
        </router-link>
      </div>
    </InfoBox>
    <InfoBox
      title="Packaged Services"
      icon="icon_v2-so_open-box"
      full-width
    >
      <div>
        Here is where you can create and save pre-formatted Packaged Services
        you could easily insert in your documents or list on your portal dashboard
        for your clients to purchase directly.
      </div>
      <div class="mt-8">
        <router-link
          class="font-bold underline"
          :to="{ name: ORGANIZATION_LIBRARY_PACKAGED_SERVICES }"
        >
          Go to Packaged Services
        </router-link>
      </div>
    </InfoBox>
    <InfoBox
      title="Pages"
      icon="icon_v2-so_docs-files"
      full-width
    >
      <div>
        Here is where you can create web pages showcasing different aspects of your services,
        such as How To and About US, that you would like to share with selected clients.
        The pages reside in your library and when altered here the changes will reflect
        on all the portals where these pages are shared.
      </div>
      <div class="mt-8">
        <router-link
          class="font-bold underline"
          :to="{ name: ORGANIZATION_LIBRARY_PAGES }"
        >
          Go to Pages
        </router-link>
      </div>
    </InfoBox>
    <InfoBox
      title="Billable Services"
      icon="icon_v2-so_bill"
      full-width
    >
      <div>
        Here is where you can create and save pre-formatted Billable Services
        you could easily insert in your Cost Tables when you prepare
        an Estimate or other type of document.
      </div>
      <div class="mt-8">
        <router-link
          class="font-bold underline"
          :to="{ name: ORGANIZATION_LIBRARY_BILLABLE_SERVICES }"
        >
          Go to Billable Services
        </router-link>
      </div>
    </InfoBox>
  </div>
</div>
