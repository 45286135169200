<template>
  <PageHeader :title="title">
    <template #actions>
      <ButtonMenuCreateDocument
        :category="documentCategory"
        :templates-category="documentCategory"
        :label="`New ${label}`"
        :tooltip-message="`Create a ${label}`"
        :show-tooltip="showTooltip"
        :data-intercom-target="`Add ${label} Button`"
        variant="neutral"
        icon-class="mr-4 -ml-4 icon_v2-so_plus text-green-600"
        size="md"
      />
    </template>
  </PageHeader>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ButtonMenuCreateDocument from '@/components/ButtonMenuCreateDocument.vue'
import PageHeader from '@/components/PageHeader.vue'

export default defineComponent({
  name: 'LibraryDocumentPageHeader',
  components: {
    PageHeader,
    ButtonMenuCreateDocument,
  },
  props: {
    documentCategory: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
})
</script>
