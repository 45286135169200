
<!-- #################### -->
<!-- #################### -->
<!--  PERMISSIONS MATRIX  -->
<!-- #################### -->
<!-- #################### -->
<div
  class="mb-24 mt-64
      rounded-lg bg-gray border border-gray-200 p-24
      max-w-list text-16 text-gray-800
    "
>
  <h3 class="project-page-subtitle p-0 mb-24">Roles and permissions</h3>
  <!-- Header -->
  <div class="flex w-full flex-start pb-16 border-b border-gray-200">
    <!-- FEATURE -->
    <div class="max-w-[60%] w-full"></div>
    <!-- admin -->
    <div class="flex-1 flex items-center justify-center">
      <BadgeMemberRole :member="{ role: ROLES.admin }" />
    </div>
    <!-- Project Manager -->
    <div class="flex-1 flex items-center justify-center text-capitalize">
      <BadgeMemberRole :member="{ role: ROLES.projectManager }" />
    </div>
    <!-- MEMBER -->
    <div class="flex-1 flex items-center justify-center text-capitalize">
      <BadgeMemberRole :member="{ role: ROLES.member }" />
    </div>
  </div>
  <!-- Rows -->
  <div
    v-for="item in featureFlagsMembers"
    :key="item.feature"
    class="flex w-full flex-start py-8 border-b border-gray-200"
  >
    <!-- FEATURE -->
    <div class="max-w-[60%] w-full">{{ item.feature }}</div>
    <!-- Member -->
    <div class="flex-1 flex items-center justify-center">
      <span
        :class="
          item.admin
            ? 'icon_v2-so_tick text-18 text-green-600'
            : 'icon_v2-so_close text-red-500 text-16'
        "
      ></span>
    </div>
    <!-- Project Manager -->
    <div class="flex-1 flex items-center justify-center">
      <span
        :class="
          item.manager
            ? 'icon_v2-so_tick text-18 text-green-600'
            : 'icon_v2-so_close text-red-500 text-16'
        "
      ></span>
    </div>
    <!-- Admin -->
    <div class="flex-1 flex items-center justify-center">
      <span
        :class="
          item.member
            ? 'icon_v2-so_tick text-18 text-green-600'
            : 'icon_v2-so_close text-red-500 text-16'
        "
      ></span>
    </div>
  </div>

  <div class="py-16">
    <div class="flex flex-col gap-4 text-14 text-gray-500">
      <p class="leading-none inline-flex items-center">
        <span class="icon_v2-so_info bg-transparent text-gray-400 text-14"></span>
        To change your organization's admin, please contact us via Intercom.
      </p>
    </div>
  </div>
</div>
<!-- #################### -->
