<template>
  <div>
    <PortalRender to="app-sidebar" :provide="{ project }">
      <ProjectNav
        :organization="organization"
        :project="project"
        :folders="folders"
        :routes="navRoutes"
      />
    </PortalRender>
    <router-view
      :organization="organization"
      :project="project"
    />
  </div>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import useTreeProjectsFolders from '@/v2/lib/composition/useTreeProjectFolders'
import {
  PROJECT_DASHBOARD,
  PROJECT_LINKED_APP,
  PROJECT_UPDATES,
  PROJECT_NOTIFICATIONS,
  PROJECT_PAGE,
  PROJECT_SERVICE,
  PROJECT_FS_FOLDER,
  PROJECT_TASKS,
} from '@/router/project/type'
import PortalRender from '@/components/PortalRender.vue'
import ProjectNav from '@/components/ProjectNav.vue'

const navRoutes = {
  projectDashboard: PROJECT_DASHBOARD,
  projectLinkedApp: PROJECT_LINKED_APP,
  projectUpdates: PROJECT_UPDATES,
  projectNotifications: PROJECT_NOTIFICATIONS,
  projectPage: PROJECT_PAGE,
  projectService: PROJECT_SERVICE,
  projectFsFolder: PROJECT_FS_FOLDER,
  projectTasks: PROJECT_TASKS,
}

export default defineComponent({
  name: 'ViewProjectContent',
  components: {
    PortalRender,
    ProjectNav,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const folders = useTreeProjectsFolders({
      projectId: computed(() => props.project._id),
    })

    return {
      folders,
      navRoutes,
    }
  },
})
</script>
