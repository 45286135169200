import ViewTemplates from '@/views/templates/ViewTemplates.vue'
import ViewTemplatesProjects from '@/views/templates/ViewTemplatesProjects.vue'
import ViewTemplatesDocuments from '@/views/templates/ViewTemplatesDocuments.vue'
import { ROLES } from '@/v2/services/members/membersTypes'

import {
  ORGANIZATION_TEMPLATES,
  ORGANIZATION_TEMPLATES_PROJECTS,
  ORGANIZATION_TEMPLATES_DOCUMENTS,
} from './type'

// TEMPLATES
export default [{
  name: ORGANIZATION_TEMPLATES,
  path: 'templates',
  component: ViewTemplates,
  children: [
    {
      name: ORGANIZATION_TEMPLATES_PROJECTS,
      path: 'projects/:slug',
      props: true,
      component: ViewTemplatesProjects,
      meta: {
        requiresRoles: [ROLES.admin, ROLES.projectManager],
      },
    },
    {
      name: ORGANIZATION_TEMPLATES_DOCUMENTS,
      path: 'documents/:slug',
      props: true,
      component: ViewTemplatesDocuments,
    },
  ],
}];
