<template>
  <nav
    class="app-nav text-gray-700 flex"
    :class="{
      'pointer-events-none opacity-50': disabled,
      'flex-col items-center gap-16 pt-12 h-full': !horizontal,
      'justify-between': horizontal,
    }"
  >
    <router-link
      v-if="!horizontal"
      :to="{ name: ORGANIZATION_HOME }"
      class="
        w-42 h-42 text-white hover:text-white bg-green-500 inline-flex
        items-center justify-center
        rounded-lg
      "
    >
      <i class="icon_v2-so_superokay"></i>
    </router-link>

    <MainNavItemProjects
      :organization="organization"
      :direct-link="horizontal"
    />

    <CheckMemberRole
      v-slot="{ hasRole: canManageClients }"
      :roles="['admin', 'projectManager']"
    >
      <MainNavItemLink
        v-if="canManageClients"
        label="Clients"
        icon="icon_v2-so_clients"
        icon-active="icon_v2-so_clients-solid"
        :to="{ name: ORGANIZATION_CLIENTS }"
      />
    </CheckMemberRole>

    <MainNavItemLink
      label="Tasks"
      icon="icon_v2-so_action-items"
      icon-active="icon_v2-so_action-items-solid"
      :to="{ name: ORGANIZATION_TASKS }"
    />

    <MainNavItemLink
      label="Templates"
      icon="icon_v2-so_templates"
      icon-active="icon_v2-so_templates-solid"
      :to="{ name: ORGANIZATION_TEMPLATES }"
    />

    <MainNavItemLink
      label="Library"
      icon="icon_v2-so_library"
      icon-active="icon_v2-so_library-solid"
      :to="{ name: ORGANIZATION_LIBRARY }"
    />

    <CheckMemberRole
      v-slot="{ hasRole: canManageOrgSettings }"
      :roles="['admin']"
    >
      <MainNavItemLink
        v-if="canManageOrgSettings"
        label="Settings"
        icon="icon_v2-so_setting"
        icon-active="icon_v2-so_setting-solid"
        :to="{ name: ORGANIZATION_SETTINGS }"
      />
    </CheckMemberRole>


    <MainNavItemFeedback v-if="!horizontal" class="mt-auto mb-16 opacity-50" />
  </nav>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  ORGANIZATION_HOME,
  ORGANIZATION_CLIENTS,
  ORGANIZATION_LIBRARY,
  ORGANIZATION_TASKS,
  ORGANIZATION_TEMPLATES,
  ORGANIZATION_SETTINGS,
} from '@/router/organization/type'
import MainNavItemLink from '@/components/MainNavItemLink.vue'
import MainNavItemProjects from '@/components/MainNavItemProjects.vue'
import MainNavItemFeedback from '@/components/MainNavItemFeedback.vue'
import CheckMemberRole from '@/components/CheckMemberRole.vue'

export default defineComponent({
  name: 'MainNav',
  components: {
    MainNavItemLink,
    MainNavItemProjects,
    MainNavItemFeedback,
    CheckMemberRole,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ORGANIZATION_HOME,
      ORGANIZATION_CLIENTS,
      ORGANIZATION_LIBRARY,
      ORGANIZATION_TASKS,
      ORGANIZATION_TEMPLATES,
      ORGANIZATION_SETTINGS,
    }
  },
})
</script>
<style lang="postcss" scoped>
  .app-nav ul > li:not(:last-child) {
    @apply mb-16;
  }
</style>
