<template>
  <div :class="$style['content-library-sidebar']">
    <template v-if="currentDocument.isProjectLevel">
      <h4 class="text-gray-400 uppercase dropdown-header text-12">
        Docs &amp; Files
      </h4>
      <div class="space-b-8">
        <ListLightItem
          label="All documents"
          icon="document"
          :document-category="CATEGORY.Document"
          :active="currentSidebarSection === SIDEBAR_SECTIONS.ALL_DOCUMENTS"
          class="dropdown-item"
          :badge="countAllDocs"
          @select="select(CATEGORY.Document, SIDEBAR_SECTIONS.ALL_DOCUMENTS)"
        />
        <ProjectFolderTree
          :value="selectedFolderId"
          :project-id="currentDocument.project"
          @input="selectFolder"
        />
      </div>
    </template>
    <h4 class="text-gray-400 uppercase dropdown-header text-12 pt-16">
      Your library
    </h4>
    <div class="mt-16 space-y-8">
      <ListLightItem
        v-for="item in categoryList"
        :key="item.category"
        :label="CATEGORY_TITLES[item.category] | pluralize(2)"
        :document-category="item.category"
        :active="category === item.category"
        :badge="item.count"
        class="dropdown-item"
        @select="select(item.category, null)"
      />
    </div>
  </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, inject, onMounted, ref } from '@vue/composition-api'
import {
  CATEGORY,
  CATEGORY_TO_LIST_TITLE as CATEGORY_TITLES,
  ORGANIZATION_LIBRARY_CATEGORIES,
} from '@/v2/services/documents/documentsTypes'
import ListLightItem from '@/components/List/ListLightItem.vue'
import ProjectFolderTree from '../ProjectFolderTree.vue'

const CATEGORY_BLACKLIST = [
  CATEGORY.DocumentPage,
]

const SIDEBAR_SECTIONS = {
  ALL_DOCUMENTS: 'ALL_DOCUMENTS',
  DOCS: 'DOCS',
  LIBRARY: 'LIBRARY',
}

export default defineComponent({
  name: 'ContentLibrarySidebar',
  components: {
    ListLightItem,
    ProjectFolderTree,
  },
  props: {
    category: {
      type: String,
      default: CATEGORY.Document,
    },
    categories: {
      type: Array,
      default: () => ([]),
    },
    selectedFolderId: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const vm = getCurrentInstance().proxy
    const { Document } = context.root.$FeathersVuex.api
    const currentDocument = inject('document')
    const currentSidebarSection = ref(SIDEBAR_SECTIONS.ALL_DOCUMENTS)
    const toggleCurrentSidebarSection = section => {
      currentSidebarSection.value = section
    }
    /** A list containing document counts grouped by their category. Categoires with 0 count are
     * excluded */
    const categoryList = ref(
      ORGANIZATION_LIBRARY_CATEGORIES
        .filter(category => ![
          ...CATEGORY_BLACKLIST,
          // currentDocument.value.category,
        ].includes(category))
        .map(category => ({ count: 0, category }))
    )
    const countAllDocs = ref(0)
    const select = (category, section) => {
      vm.$emit('update:category', category)
      vm.$emit('folder-selected', null) // Clear selection on tree
      toggleCurrentSidebarSection(section || null)
    }

    const selectFolder = folderId => {
      vm.$emit('folder-selected', folderId)
      // Only `Document` type shown here. Also clears org library selection
      vm.$emit('update:category', CATEGORY.Document)
      toggleCurrentSidebarSection(SIDEBAR_SECTIONS.DOCS)
    }

    const queryByCateg = category => ({
      _id: { $ne: currentDocument.value._id }, // Exclude current document
      organization: currentDocument.value.organization,
      category,
    })


    onMounted(async () => {
      // Not all docs in store. Query db for count
      const docCounts = await Promise.all(
        ORGANIZATION_LIBRARY_CATEGORIES.map(
          category => Document.count({ query: queryByCateg(category) })
        )
      )
      // Assign counts to categories. `orgLibraryCategories` order kept
      // eslint-disable-next-line no-param-reassign
      categoryList.value.forEach((c, i, list) => { list[i].count = docCounts[i] })
      // Filter out categories with 0 entries
      categoryList.value = categoryList.value.filter(item => Boolean(item.count));

      countAllDocs.value = await Document.count({
        query:
      {
        organization: currentDocument.value.organization,
        project: currentDocument.value.project,
        category: CATEGORY.Document,
      },
      })
    })

    return {
      // Statics
      CATEGORY_TITLES,
      CATEGORY,
      SIDEBAR_SECTIONS,
      // State
      categoryList,
      currentDocument,
      currentSidebarSection,
      // Methods
      select,
      selectFolder,
      countAllDocs,
      toggleCurrentSidebarSection,
    }
  },
})
</script>

<style lang="scss" module>
.content-library-sidebar {
  :global(.menu-item) {
    margin: 0 0 2px;
  }
}
</style>
