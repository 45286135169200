
<div class="h-full relative">
  <PageHeader
    show-back
    title="Manage Subscription"
    @back="$router.push({ name: ORGANIZATION_SETTINGS_SUBSCRIPTION })"
  />
  <SpinnerOverlay v-if="isBusy" absolute />
  <Error
    v-else-if="error"
    variant="danger"
    :error="error"
  />
  <div v-if="portalUrl" class="h-full">
    <iframe
      frameborder="0"
      :src="portalUrl"
      class="h-full w-full"
    />
  </div>
</div>
