<template>
  <DocumentNodeEditorToolbarPopover
    class-name="rounded hover:bg-gray-500 hover:bg-opacity-10 editor-toolbar-button"
    triggers="click blur"
    tooltip="Change Font"
  >
    <template #button-content>
      <span class="w-24 text-24 icon_v2-so_font-size mx-4" />
      <span :class="[$style['toolbar-dropdown']]" />
      <span v-if="value" class="text-14 opacity-50 ml-4 mr-8">
        <span class="max-w-96 truncate inline-block">{{ value }}</span>
      </span>
    </template>
    <template #title>
      {{ heading }}
    </template>
    <template #default>
      <BrandingFontsFormSelect
        :value="value"
        @input="(v)=> $emit('input',v)"
      />
    </template>
  </DocumentNodeEditorToolbarPopover>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import DocumentNodeEditorToolbarPopover from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarPopover.vue'
import BrandingFontsFormSelect from '@/components/BrandingFontsFormSelect.vue'

export default defineComponent({
  name: 'DocumentNodeEditorToolbarFont',
  components: {
    DocumentNodeEditorToolbarPopover,
    BrandingFontsFormSelect,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    heading: {
      type: String,
      default: '',
    },

  },
  setup() {
    return {}
  },
})
</script>

<style lang="postcss" module>
.toolbar-dropdown{
  border-width: 0 0 6px 6px;
  border-color: transparent transparent rgba(255,255,255,0.4) transparent;
  @apply w-0 h-0 border-solid absolute bottom-0 right-0;
}
</style>
