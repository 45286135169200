<template>
  <div
    class="border border-l-8 p-16 rounded-sm"
    :class="{
      'border-blue-700 text-blue-700': variant === VARIANT.info,
      'border-yellow-700 text-yellow-700': variant === VARIANT.warn,
      'border-red-700 text-red-700': variant === VARIANT.error,
    }"
  >
    <p class="font-semibold text-16"><slot name="title" /></p>
    <p class="text-14"><slot /></p>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

const VARIANT = {
  info: 'info',
  warn: 'warn',
  error: 'error',
}

export default defineComponent({
  name: 'InfoMessage',
  props: {
    variant: {
      type: String,
      default: VARIANT.info,
      validate: val => Object.values(VARIANT).includes(val),
    },
  },
  setup() {
    return {
      VARIANT,
    }
  },
})
</script>
<style lang="postcss" scoped>

</style>
