import ViewErrorNotFound from '@/views/ViewErrorNotFound.vue'
import LayoutGuest from '@/views/LayoutGuest.vue'
import { NOT_FOUND } from './type'

export default () => [
  {
    path: '/error',
    component: LayoutGuest,
    children: [
      {
        name: NOT_FOUND,
        path: 'not-found',
        component: ViewErrorNotFound,
      },
    ],
  },
]
