import { differenceInSeconds } from 'date-fns'

const SIGN_UP_TRIGGER = 30

class FirstPromoterService {
  get eventBus() {
    return this.container.get('eventBus')
  }

  get store() {
    return this.container.get('store')
  }

  constructor(container) {
    this.container = container

    // If FPR is installed
    if (window.fpr) {
      this.eventBus.$on('init', () => this.init())
    } else {
      console.log('FPR not installed')
    }
  }

  init() {
    // console.log('init')
    this.store.subscribe(this.storeSubscriber.bind(this))
  }

  async storeSubscriber({ type }) {
    if (type === 'auth/setUser') {
      const { user } = await this.store.state.auth
      // Need ID of 1st organization so we can send it to FPR
      // Therefore we are sending the organizations
      // TODO: cleaner way of doing this?
      const { organizations } = await this.store.state
      this.onUserSignup(user, organizations)
    }
  }

  _buildUserParams(user, organizations) {
    return {
      email: user?.email,
      // 1st item in organinzations array will be the new org
      // when people sign up
      organization_id: organizations?.ids[0],
    }
  }

  onUserSignup(user, organizations) {
    const { createdAt } = user

    // We need to send this just once, when the user signs up
    // as we don't have a way to tell if this is signup or not
    // we'll do some "dark magic" and look for the difference in
    // seconds between current date and the createdAt timestamp
    // on the user in the DB.
    //
    // Assuming it won't take more than SIGN_UP_TRIGGER seconds
    // for the account to be created, we can safely
    // assume whether this is a sign up or not
    if (createdAt) {
      const secondsSinceSignUp = differenceInSeconds(
        new Date(),
        new Date(createdAt)
      )
      if (secondsSinceSignUp < SIGN_UP_TRIGGER) {
        this.setReferralUID({
          ...this._buildUserParams(user, organizations),
        })
      }
    }
  }

  setReferralUID(user) {
    window.fpr('referral', { email: user?.email, uid: user?.organization_id })
    // console.log('Welcome from FPR', user?.email, user?.organization_id)
    // console.log({ user })
  }
}

export default FirstPromoterService
