<template>
  <ModalBasic
    :title="texts.modalTitle"
    :subtitle="subtitle"
    :should-close-on-backdrop="false"
    :hide-footer="true"
    content-class="modal-content-tertiary"
    body-class="modal-body-tertiary"
    header-class="modal-header-tertiary"
    footer-class="modal-footer-tertiary"
    size="md"
    v-bind="$attrs"
    v-on="$listeners"
    @hide="trackModalClosed"
  >
    <template #body="{ close }">
      <Form
        v-slot="{ state, v }"
        :state="formState"
        :on-submit="onSave"
        :validation="validation"
        :success-message="texts.formSuccessMessage"
      >
        <b-form-group>
          <FormField
            label="First Name"
            :v="v.firstName"
            required
            :messages="{
              required: 'First name is needed',
            }"
          >
            <b-input
              v-model="state.firstName"
              :disabled="!(isNew || isInvited || createAsClientContact)"
              autofocus
              placeholder="Alex"
              class="form-control-lg"
            />
          </FormField>
        </b-form-group>
        <b-form-group>
          <FormField
            label="Last Name"
            :v="v.lastName"
            required
            :messages="{
              required: 'Last name is needed',
            }"
          >
            <b-input
              v-model="state.lastName"
              :disabled="!(isNew || isInvited || createAsClientContact)"
              placeholder="Appleseed"
              class="form-control-lg"
            />
          </FormField>
        </b-form-group>
        <b-form-group>
          <FormField
            label="Email Address"
            :v="v.email"
            required
            :messages="{
              email: 'A valid email address is required',
              required: 'A valid email address is required',
              available: 'Email address not available',
            }"
          >
            <b-input
              v-model.trim="formStateEmail"
              :disabled="!(isNew || isInvited || createAsClientContact)"
              placeholder="alex.appleseed@email.com"
              class="form-control-lg"
            />
          </FormField>
        </b-form-group>
        <b-form-group>
          <FormField
            label="Role"
            :v="v.role"
            required
            :messages="{
              required: 'Role is required',
            }"
          >
            <b-select
              v-model="state.role"
              placeholder="Role"
              :options="roleOptions"
              class="form-control-lg error"
              :disabled="createAsClientContact || member?.isOwner"
            >
              <template #first>
                <b-form-select-option :value="null">Select...</b-form-select-option>
              </template>
            </b-select>
          </FormField>
        </b-form-group>
        <Buttons>
          <Button
            type="button"
            variant="neutral"
            size="md"
            @click="close"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            size="md"
          >
            {{ texts.saveButtonLabel }}
          </Button>
        </Buttons>
      </Form>
    </template>
  </ModalBasic>
</template>

<script>
import Vue from 'vue'
import { cond, pick, T } from 'ramda'
import { computed, defineComponent, reactive, toRef } from '@vue/composition-api'
import { useGet } from 'feathers-vuex'
import { required, email, helpers } from '@vuelidate/validators'
import { rejectNil } from 'ramda-extension'
import { ROLES, STATUS } from '@/v2/services/members/membersTypes'
import { idNew } from '@/v2/lib/symbols'
import { execTasks } from '@/lib/promise'
import useValidators from '@/v2/services/validators/validatorsCompositions'
import { useTracking } from '@/v2/services/tracking/compositions'
import debounce from '@/v2/lib/helpers/debounce'
import { email as emailSanitizer } from '@/v2/lib/helpers/sanitizer'
import FormField from '@/components/FormField.vue'
import Button from '@/components/Button.vue'
import Buttons from '@/components/Buttons.vue'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import Form from '@/components/Form.vue'

const { withAsync } = helpers

const teamMemberRoles = [
  {
    text: 'Member (can be assigned to projects)',
    value: ROLES.member,
  },
  {
    text: 'Project Manager (can manage all projects)',
    value: ROLES.projectManager,
  },
  {
    text: 'Admin (all permissions)',
    value: ROLES.admin,
  },
]

const clientContactRoles = [
  {
    text: 'Client (access to client portal)',
    value: ROLES.client,
  },
]

export default defineComponent({
  name: 'TeamMemberFormModal',
  components: {
    FormField,
    Button,
    Buttons,
    ModalBasic,
    Form,
  },
  props: {
    memberId: {
      type: [String, Symbol],
      default: () => idNew,
    },
    organizationId: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
      default: null,
    },
    createAsClientContact: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { track } = useTracking()
    const { Member, User, Profile, Client } = Vue.$FeathersVuex.api

    const { item: client } = useGet({
      model: Client,
      id: toRef(props, 'clientId'),
    })

    const member = Member.getFromStore(props.memberId)
    const userModel = User.getFromStore(member?.user)
    const profileModel = Profile.getFromStore(userModel?.profile)

    const roleOptions = computed(
      () => (props.createAsClientContact ? clientContactRoles : teamMemberRoles)
    );

    const isNew = computed(() => props.memberId === idNew)
    const isInvited = computed(() => member?.status === STATUS.INVITED)

    const subtitle = computed(() => (props.createAsClientContact ? client?.name : null))

    const makeFormStateNew = () => {
      const state = {
        firstName: '',
        lastName: '',
        email: '',
        organization: props.organizationId,
      }

      if (props.createAsClientContact) {
        state.role = ROLES.client
        state.client = props.clientId || 'c' // TODO what is `c` ??
      } else {
        state.role = null
      }

      return new Member(state);
    }

    const makeFormStateUpdate = () => reactive(
      isInvited.value
        ? {
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email,
          role: member.role,
        }
        : {
          firstName: profileModel.firstName,
          lastName: profileModel.lastName,
          email: userModel.email,
          role: member.role,
        }
    )

    const formState = isNew.value
      ? makeFormStateNew()
      : makeFormStateUpdate()

    const formStateEmail = computed({
      get: () => formState.email,
      set: debounce(value => {
        formState.email = emailSanitizer(value)
      }),
    })

    const { emailAvailable } = useValidators();
    const isChangeValid = newEmail => (newEmail !== userModel.email // Email changed
      ? emailAvailable(newEmail) // Has email been claimed by another user?
      : true) // Email not changed.

    const validation = {
      firstName: { required },
      lastName: { required },
      email: rejectNil({
        required,
        email,
        available: (isNew.value || isInvited.value)
          ? null
          : withAsync(isChangeValid),
      }),
      role: { required },
    }

    const _track = () => {
      if (props.createAsClientContact) {
        track('New Client Contact Created', { client: props.clientId })
      } else {
        track('Team Member Created')
      }
    }

    const trackModalClosed = () => {
      if (props.createAsClientContact) {
        track('New Client Contact Modal Closed', { client: props.clientId })
      } else {
        track('Team Member Modal Closed')
      }
    }

    const update = () => execTasks([
      [
        () => !isInvited.value,
        () => Promise.all([
          profileModel.save({ data: pick(['firstName', 'lastName'], formState) }),
          userModel.save({ data: pick(['email'], formState) }),
          member.save({ data: pick(['role'], formState) }),
        ]),
      ],
      [
        () => isInvited.value,
        () => member.save({ data: pick(['firstName', 'lastName', 'role'], formState) }),
      ],
    ])

    const onSave = async () => {
      if (isNew.value) {
        await formState.save()
        _track()
      } else {
        await update()
      }

      context.emit('save', isNew.value)
      context.emit('saved', formState)
    }

    // watch(clientId, newVal => {
    //   if (newVal) formState.client = newVal
    // })

    const texts = computed(() => ({
      formSuccessMessage: cond([
        [() => isNew.value && props.createAsClientContact, () => 'Client Contact added'],
        [() => isNew.value && !props.createAsClientContact, () => 'Invitation sent'],
        [T, () => 'Changes Saved'],
      ])(),
      saveButtonLabel: cond([
        [() => isNew.value && props.createAsClientContact, () => 'Add Contact'],
        [() => isNew.value && !props.createAsClientContact, () => 'Send Invite'],
        [T, () => 'Update'],
      ])(),
      modalTitle: cond([
        [() => props.createAsClientContact && isNew.value, () => 'Invite New Client Contact'],
        [() => props.createAsClientContact && !isNew.value, () => 'Send Invite'],
        [() => !props.createAsClientContact && isNew.value, () => 'Invite New Team Member'],
        [() => !props.createAsClientContact && !isNew.value, () => 'Update Member'],
        // [T, () => 'Manage Member'],
      ])(),
    }))

    return {
      formState,
      formStateEmail,
      validation,
      onSave,
      subtitle,
      member,
      roleOptions,
      isNew,
      isInvited,
      texts,
      trackModalClosed,
    }
  },
})
</script>
