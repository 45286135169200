<template>
  <NotificationsInbox :project="project._id" show-document>
    <template
      #header="{
        hasUnreadNotifications,
        isPendingMarkAllAsRead,
        markAllAsRead
      }"
    >
      <PageHeader title="Project Activity">
        <template #actions>
          <Button
            v-if="hasUnreadNotifications"
            type="button"
            variant="neutral"
            size="md"
            :is-loading="isPendingMarkAllAsRead"
            @click="markAllAsRead"
          >
            <span class="mr-4 -ml-4 icon_v2-so_ok text-green-600" />
            Mark All As Read
          </Button>
        </template>
      </PageHeader>
    </template>
  </NotificationsInbox>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import PageHeader from '@/components/PageHeader.vue'
import NotificationsInbox from '@/components/Notifications/NotificationsInbox.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'ViewProjectNotifications',
  components: {
    NotificationsInbox,
    PageHeader,
    Button,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
})
</script>
