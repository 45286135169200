
<FeatureLimit
  v-slot="{ checkFeatureLimit }"
  flag="limitProjects"
  label="projects"
  @allow="() => $emit('use', template)"
>
  <ModalBasic
    size="xl"
    :hide-footer="true"
    content-class="modal-content-template-preview"
    body-class="modal-body-template-preview"
    footer-class="modal-footer-template-preview"
    close-text="Close Preview"
    :no-close="pending"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #header>
      <Button
        class="whitespace-nowrap"
        size="md"
        :is-loading="pending"
        @click="checkFeatureLimit"
      >
        <span class="icon_v2-so_tick mr-4 text-24 w-24" />Use this template
      </Button>
    </template>
    <template #body>
      <div class="px-24">
        <FormMessage v-if="error" supress-generic-message>{{ error }}</FormMessage>
        <ProjectTemplatePreview
          v-if="template"
          :template="template"
          :pending="pending"
          @use="() => checkFeatureLimit()"
        />
      </div>
    </template>
  </ModalBasic>
</FeatureLimit>
