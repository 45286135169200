
<OrganizationGet
  :id="organizationId"
  v-slot="{ organization, isGetPending: isOrganizationPending }"
  force
>
  <Provide
    v-if="!isOrganizationPending"
    :provide="{ organization }"
  >
    <OrganizationStatusGuard
      v-slot="{ active }"
      :organization="organization"
    >
      <Licensing v-slot="{ licensing, plan }">
        <LayoutApp>
          <template #header>
            <MainHeader
              :organization="organization"
              :client="project?.client$"
            >
              <template v-if="!$isMobile" #nav>
                <MainHeaderNav :organization="organization" :disabled="!active" />
              </template>
              <template #user>
                <DropdownUser
                  :organization="organization"
                  :disabled-items="{
                    deleteAccount: active,
                    userProfile: !active,
                    inbox: !$isMobile,
                  }"
                />
              </template>
            </MainHeader>
          </template>
          <template #nav>
            <MainNav
              :organization="organization"
              :disabled="!active"
              :horizontal="$isMobile"
            />
          </template>
          <template #main>
            <router-view
              v-if="organization"
              :organization="organization"
              :licensing="licensing"
              :plan="plan"
            />
          </template>
          <template #modals>
            <QuickStartModal
              v-if="showQuickStart"
              v-model="showQuickStart"
              @customize-project="goToProjectBranding"
            />
          </template>
        </LayoutApp>
      </Licensing>
    </OrganizationStatusGuard>
  </Provide>
  <SpinnerOverlay v-else />
</OrganizationGet>
