<template>
  <transition name="slide-fade">
    <div
      v-show="selectedNodes.length"
      id="multiple-selection-toolbar"
      :class="'fixed bottom-0 pb-16 left-40 tablet:left-256 2xl:left-288 text-white '
        + 'flex-row right-0 flex justify-center pointer-events-none z-1000'"
    >
      <div
        :class="'pointer-events-auto bg-gray-800 rounded-lg shadow-lg '
          +'p-8 flex w-320 justify-between relative z-1 '"
      >
        <transition name="bounce" mode="out-in">
          <div
            :key="selectedNodes.length"
            class="flex items-center px-12 font-medium text-white bg-gray-800 rounded-md text-14"
          >
            {{ 'block' | translate | pluralize(selectedNodes.length, true) }} selected
          </div>
        </transition>
        <b-dropdown
          dropup
          variant="primary"
          toggle-class="text-decoration-none"
          no-caret
          right
        >
          <template #button-content>
            Actions <span class="ml-4 -mr-4 icon-util-arrows-select"></span>
          </template>
          <b-dropdown-item @click="saveAsReusableBlock">
            <span class="inline-block w-16 mr-8 cm-icon icon-util-copy text-16"></span>
            <span>Save to reusable block</span>
          </b-dropdown-item>
          <b-dropdown-item :disabled="!isRoot" @click="group">
            <span class="inline-block w-16 mr-8 cm-icon icon-object-group text-16"></span>
            Group selection
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item @click="selectAllNodes">
            <span class="inline-block w-16 mr-8 cm-icon icon_v2-so_action-items text-16"></span>
            <span>Select all</span>
          </b-dropdown-item>
          <b-dropdown-item @click="clearNodeSelection">
            <span class="inline-block w-16 mr-8 cm-icon icon-util-deselect-all text-16"></span>
            <span>Clear selection</span>
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item @click="onCutSelectedNodes">
            <span class="inline-block w-16 mr-8 cm-icon icon-util-cut text-16"></span>
            <span>
              Cut {{ 'block' | translate | pluralize(selectedNodes.length, true) }}
            </span>
          </b-dropdown-item>
          <b-dropdown-item @click="onCopySelectedNodes">
            <span class="inline-block w-16 mr-8 cm-icon icon-util-copy text-16"></span>
            <span>Copy {{ 'block' | translate | pluralize(selectedNodes.length, true) }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="onRemoveSelectedNodes">
            <span class="inline-block w-16 mr-8 cm-icon icon_v2-so_trash text-16"></span>
            <span>Delete {{ 'block' | translate | pluralize(selectedNodes.length, true) }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <b-tooltip
        target="multiple-selection-toolbar"
        placement="top"
        variant="success"
        triggers="manual"
        :show.sync="showTooltip"
      >
        {{ tooltipMessage }}
      </b-tooltip>
      <div class="selection-toolbar"></div>
    </div>
  </transition>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { useNamespacedActions, useNamespacedState, useNamespacedMutations } from 'vuex-composition-helpers'
import { useMsgBoxConfirmDelete } from '@/v2/lib/composition/useMsgBox'
import { useStructureBranch, useStructureRemoveNodes, useStructureGroupNodes } from '@/v2/services/documentStructures/documentStructuresCompositions'
import useNotification from '@/v2/lib/composition/useNotification'
import { useSaveAsReusableBlock } from '@/v2/services/documentNodes/documentNodesCompositions'

export default defineComponent({
  name: 'DocumentSelectionToolbar',
  components: {},
  props: {},
  setup(/* props, context */) {
    const { selectedNodes, selectedNodeParent } = useNamespacedState('documentEditor', [
      'selectedNodes',
      'selectedNodeParent',
    ])

    const {
      clearNodeSelection,
      addNodesToSelection,
      copyNodesUsingClipboard,
      cutNodesUsingClipboard,
    } = useNamespacedActions('documentEditor', [
      'clearNodeSelection',
      'addNodesToSelection',
      'copyNodesUsingClipboard',
      'cutNodesUsingClipboard',
    ])

    const { setSelectedNodes } = useNamespacedMutations('documentEditor', [
      'setSelectedNodes',
    ])

    const tooltipMessage = ref('')
    const showTooltip = ref(false)
    const removeNodes = useStructureRemoveNodes()
    const msgBoxConfirmDelete = useMsgBoxConfirmDelete()
    const showNotification = useNotification()
    const groupNodes = useStructureGroupNodes()
    const getBranchById = useStructureBranch()
    const saveAsReusableBlock = useSaveAsReusableBlock()

    const isRoot = computed(() => selectedNodeParent.value === 'root')

    const setTooltipMessage = message => {
      tooltipMessage.value = message;
      showTooltip.value = true
      setTimeout(() => {
        showTooltip.value = false
        tooltipMessage.value = '';
      }, 3000);
    }

    const onRemoveSelectedNodes = async () => {
      try {
        const ok = await msgBoxConfirmDelete({
          title: 'Delete',
          message: 'Are you sure you want to delete all the selected blocks? This action is irreversible.',
        })

        if (!ok) return // Operation was cancelled. Do nothing
        // Proceed to remove all selected nodes
        await removeNodes({ ids: selectedNodes.value, parent: selectedNodeParent.value })
        clearNodeSelection() // Clear the list of selected nodes as they no longer exist
        showNotification({ title: 'Selection deleted', message: 'The selected blocks have been successfully removed' })
      } catch (err) {
        console.error('Error on removing selected nodes:', err)
      }
    }
    /** Selects all root-level nodes in current document */
    const selectAllNodes = () => {
      const { children } = getBranchById(selectedNodeParent.value)
      setSelectedNodes(children.value)
      setTooltipMessage('All blocks added to selection')
    }

    const onCopySelectedNodes = () => {
      copyNodesUsingClipboard(selectedNodes.value)
      setTooltipMessage('Selection copied to clipboard')
    }
    const onCutSelectedNodes = () => {
      cutNodesUsingClipboard(selectedNodes.value)
      setTooltipMessage('Selection cut, paste it in this document')
    }

    const group = () => {
      groupNodes({
        nodeIds: selectedNodes.value,
        fromParent: selectedNodeParent.value,
      })
      clearNodeSelection()
    }

    return {
      selectedNodes,
      onRemoveSelectedNodes,
      clearNodeSelection,
      group,
      isRoot,
      selectAllNodes,
      onCopySelectedNodes,
      onCutSelectedNodes,
      tooltipMessage,
      showTooltip,
      saveAsReusableBlock,
      selectedNodeParent,
    }
  },
})
</script>

<style lang="postcss">

.selection-toolbar {
  @apply absolute bottom-0 left-0 right-0 h-176 z-auto pointer-events-none;
  content: "";
  background: transparent linear-gradient(
    to bottom,
    rgba(0,0,0,0) 0%,
    rgba(0,0,0,.1) 60%,
    rgba(0,0,0,0.3) 100%
  );
  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  animation: shadowFadeIn .4s ease-out;
  mask-image: linear-gradient(
    90deg,
    transparent 0%,
    transparent 20%,
    black 40%,
    black 60%,
    transparent 80%,
    transparent 100%
  );
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in .4s;
}
.bounce-leave-active {
  animation: bounce-in .4s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


@keyframes shadowFadeIn {
    from { background-position: 0 200px; }
    to   { background-position: 0 0; }
}
</style>
