<template>
  <div>
    <b-table
      hover
      :items="items"
      :fields="fields"
      :sort-compare="sortCompare"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :class="$style['action-items-table']"
      thead-class="whitespace-nowrap"
      @row-clicked="onRowClicked"
    >
      <template #cell(actionItem)="data">
        <div
          class="flex items-center text-14"
          :class="{
            'text-green-00': data.item.actionItem.isResolved,
            'text-red-500': data.item.actionItem.isDue && !data.item.actionItem.isResolved
          }"
        >
          <div class="flex items-center">
            <span
              class="
                inline-flex items-center justify-center
                flex-none w-24 h-24 mr-8 border rounded-100
              "
              :class="{
                'text-white bg-green-500 border-green-500':
                  data.item.actionItem.isResolved,
                'text-gray-400 bg-white border-gray-300':
                  !data.item.actionItem.isResolved,
              }"
            >
              <span class="icon_v2-so_tick" />
            </span>
            <span>{{ data.item.actionItem.title }}</span>
          </div>
          <div class="inline-flex">
            <span
              v-if="data.item.actionItem.public"
              v-b-tooltip="{
                placement: 'top',
                trigger: 'hover',
                variant: 'info',
                delay: { show: 200, hide: 0 },
                title: 'The client sees this',
              }"
              class="inline-block px-4 ml-4 text-gray-600 rounded-lg "
            >
              <span class="icon-util-view" />
            </span>
            <span
              v-else
              v-b-tooltip="{
                placement: 'top',
                trigger: 'hover',
                variant: 'info',
                delay: { show: 200, hide: 0 },
                title: 'The client does not sees this',
              }"
              class="inline-block px-4 ml-4 text-gray-600 bg-blue-200 rounded-lg bg-opacity-70"
            >
              <span class="icon_v2-so_eye-disabled" />
            </span>
            <span
              v-if="data.item.actionItem.notes"
              :id="data.item.actionItem._id"
              class="inline-block px-4 ml-4 text-gray-600 rounded-lg "
            >
              <span class="icon_v2-so_file text-18" />
              <b-popover
                :target="data.item.actionItem._id"
                triggers="hover"
                custom-class="p-8 shadow-lg w-270"
              >
                {{ data.item.actionItem.notes }}
              </b-popover>
            </span>
          </div>
        </div>
      </template>
      <template #cell(project)="data">
        <ProjectBadge
          v-if="data.item.project"
          class="!text-12 font-normal !whitespace-normal"
          :label="data.item.project.name"
          :background-color="data.item.project.portalAccentColor"
        />
      </template>
      <template #cell(client)="data">
        <div v-if="data.item.client" class="flex items-center text-14">
          <span
            :style="{ backgroundColor: data.item.client.mainColor }"
            class="flex-none inline-block w-16 h-16 mr-4 rounded-full"
          />{{ data.item.client.name }}
        </div>
      </template>
      <template #cell(assignedTo)="data">
        <Avatar
          v-if="data.item.user?.profile$"
          class="!flex"
          :profile="data.item.user.profile$"
          show-name
          :nowrap="false"
        />
      </template>
      <template #cell(createdAt)="data">
        <div class="text-gray-600 text-14 dark:text-darkGray-500">
          <!-- {{ $d(Date.parse(data.item.actionItem.createdAt), 'short') }} -->
          {{ data.item.actionItem.createdAt | date }}
        </div>
      </template>
      <template #cell(dueDate)="data">
        <ActionItemDateDisplay
          v-if="data.item.actionItem.dueDate"
          v-slot="{ displayDate }"
          :item="data.item.actionItem"
          class-name="
            ml-6 inline-flex items-center text-gray-600 text-14
            dark:text-darkGray-500 !whitespace-normal
          "
        >
          {{ displayDate.message }}
        </ActionItemDateDisplay>
      </template>
    </b-table>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import { useGoToActionItem } from '@/v2/services/actionItems/compositions'
import matchKeyword from '@/lib/match-keyword'
import ProjectBadge from '@/components/ProjectBadge.vue'
import ActionItemDateDisplay from '@/components/ActionItemDateDisplay.vue'
import Avatar from '@/components/Blocks/Avatar/Avatar.vue'

const toDateTime = date => (date ? new Date(`${date}T00:00:00.000`) : null)

const compareDates = (a, b) => {
  const aTimestamp = a?.getTime() ?? 0
  const bTimestamp = b?.getTime() ?? 0

  if (aTimestamp === bTimestamp) {
    return 0;
  }

  return aTimestamp < bTimestamp ? -1 : 1;
}

const compareStrings = (a, b) => (a ?? '').localeCompare(b ?? '')

const sortCompare = (aRow, bRow, key) => {
  switch (key) {
    case 'actionItem':
      return compareStrings(
        aRow.actionItem.title,
        bRow.actionItem.title
      )
    case 'project':
      return compareStrings(
        aRow.project?.name,
        bRow.project?.name
      )
    case 'client':
      return compareStrings(
        aRow.client?.name,
        bRow.client?.name
      )
    case 'assignedTo':
      return compareStrings(
        aRow.user?.profile$?.fullName,
        bRow.user?.profile$?.fullName
      )
    case 'createdAt':
      return compareDates(
        new Date(aRow.actionItem.createdAt),
        new Date(bRow.actionItem.createdAt)
      )
    case 'dueDate': {
      return compareDates(
        toDateTime(aRow.actionItem.dueDate),
        toDateTime(bRow.actionItem.dueDate)
      )
    }
    default:
      return 0
  }
}

const fields = [
  {
    key: 'actionItem',
    label: 'Name',
    sortable: true,
  },
  {
    key: 'project',
    label: 'Project',
    sortable: true,
  },
  {
    key: 'client',
    label: 'Client',
    sortable: true,
  },
  {
    key: 'assignedTo',
    label: 'Assigned To',
    sortable: true,
  },
  {
    key: 'createdAt',
    label: 'Created',
    sortable: true,
  },
  {
    key: 'dueDate',
    label: 'Due Date',
    sortable: true,
  },
]

export default defineComponent({
  name: 'ActionItemsTable',
  components: {
    ProjectBadge,
    ActionItemDateDisplay,
    Avatar,
  },
  props: {
    keyword: {
      type: String,
      default: null,
    },
    actionItems: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const goToTask = useGoToActionItem()

    const onRowClicked = item => {
      goToTask(item.actionItem)
    }

    const sortBy = ref('createdAt')
    const sortDesc = ref(true)

    // search
    const items = computed(() => {
      if (!props.keyword?.length) {
        return props.actionItems
      }

      const match = matchKeyword(props.keyword)

      return props.actionItems.filter(({ actionItem, project, client, user }) => {
        const needles = [
          actionItem.title,
          project?.name,
          client?.name,
          user?.profile$?.fullName,
        ].filter(Boolean)

        return needles.some(match)
      })
    })

    return {
      items,
      fields,
      onRowClicked,
      sortCompare,
      sortBy,
      sortDesc,
    }
  },
})
</script>
<style lang="scss" module>
.action-items-table td,
.action-items-table th {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.action-items-row:hover {
  box-shadow: 0 0 #000 !important;
  background-color: $gray-100 !important;
}
</style>
