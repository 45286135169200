<template>
  <div v-fragment>
    <template v-if="!isEmpty">
      <!-- <transition-group name="node-list" tag="div"> -->
      <SlickList
        v-model="nodes"
        lock-axis="y"
        use-drag-handle
        use-custom-container
        custom-container-id="appScrollContainer"
        helper-class="so-node-dragging"
        :custom-container-offset-y="73"
        @sort-start="handleSortStart"
        @sort-end="handleSortEnd"
        class="w-full"
      >
        <SlickItem
          v-for="(nodeId, index) in nodes"
          :key="nodeId"
          :index="index"
          tag="div"
          class="so-slick-item"
        >
          <DocumentNodeEditable
            :node-id="nodeId"
            parent="root"
            :index="index"
            :is-first="index === 0"
            :is-last="index === children.length - 1"
            class="so-slick-inner"
          />
        </SlickItem>
      </Slicklist>
      <!-- </transition-group> -->
    </template>
    <DocumentNodeGroupRootPlaceholder v-else />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { SlickList, SlickItem } from 'vue-slicksort'
import { useStructureBranch, useStructureReorderChildren } from '@/v2/services/documentStructures/documentStructuresCompositions'
import DocumentNodeEditable from '@/v2/features/document/documentNode/DocumentNodeEditable.vue'
import { changeCursorStyle } from '@/helpers/dom'
import DocumentNodeGroupRootPlaceholder from './DocumentNodeGroupRootPlaceholder.vue'

const { useMutations, useState } = createNamespacedHelpers('documentEditor')

export default {
  name: 'DocumentNodeGroupRootEditable',
  components: {
    DocumentNodeEditable,
    DocumentNodeGroupRootPlaceholder,
    SlickList,
    SlickItem,
  },
  inheritAttrs: false,
  setup() {
    const structureBranch = useStructureBranch()
    const structureReorderChildren = useStructureReorderChildren()

    const { focusDisabled } = useState(['focusDisabled'])
    const { setFocusDisabled } = useMutations(['setFocusDisabled'])

    const { children, isEmpty } = structureBranch('root')

    const nodes = computed({
      set: newOrder => structureReorderChildren({ parent: 'root', children: newOrder }),
      get: () => children.value,

    })

    const handleSortStart = () => {
      setFocusDisabled(true)
      changeCursorStyle('grabbing')
    }
    const handleSortEnd = () => {
      setFocusDisabled(false)
      changeCursorStyle('')
    }

    return {
      isEmpty,
      children,
      SlickList,
      SlickItem,
      nodes,
      focusDisabled,
      setFocusDisabled,
      handleSortStart,
      handleSortEnd,
    }
  },
}
</script>
<style lang="postcss" scoped>
.node-list-move {
  @apply transform transition-transform duration-300 bg-white shadow-3xl z-50;
}
.drag-btn {
  /* visibility: hidden; */
  opacity: 0;
  position: relative;
  transition: opacity 0.2s linear;
  right: 20px;
  cursor: pointer;
}
.drag-btn:hover {
  /* visibility: visible; */
  opacity: 1;
}

.so-node-dragging {
}

.so-node-dragging section {
  margin: 0!important;
}


</style>

<style lang="postcss">

.so-node-dragging .block-create-menu-button,
.so-node-dragging .node-highlight,
.so-node-dragging .editor-toolbar,
.so-node-dragging .block-actions-right,
.so-node-dragging .block-actions-move,
.so-node-dragging .resize-container .handler {
  display: none;
}

.so-node-dragging .resize-container::after {
  opacity: 0;
}

.so-slick-inner {
  @apply shadow-none;
}

.so-node-dragging .so-slick-inner{
  @apply z-0 transform -rotate-1 scale-105;
  @apply m-0 ring-2 bg-white bg-opacity-50 ring-blue-500 rounded-lg overflow-hidden;
  box-shadow: 0 10px 40px rgba(0,0,0, 0.10), 0 0px 60px rgba(0,0,0, 0.04);
}

.so-node-dragging .so-slick-inner .block-actions,
.so-node-dragging .so-slick-inner .block-add {
  display: none;
}

.so-node-dragging .so-slick-inner .block-section-wrapper {
  @apply opacity-90 bg-white bg-opacity-50;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
</style>
