<template>
  <div>
    <PageHeader title="Manage Access" />
    <Form
      v-if="state"
      v-slot="{ isBusy, submit, hasChanges }"
      :state="state"
      success-message="Project changes saved"
      :on-submit="() => save()"
    >
      <section class="mb-40">
        <div class="mb-16">
          <p class="text-gray-500 text-14 mb-24">
            Manage who can access this project from your team or the client's team.
          </p>
        </div>


        <div class="mt-48">
          <div class="grid grid-cols-1 gap-32 lg:grid-cols-2 md:gap-16 xl:grid-cols-3">
            <template v-for="section, index in sections">
              <ProjectSettingsAccessTeam
                v-if="section.id === 'team'"
                :key="section.id"
                v-model="state.members"
                :step="index + 1"
                :organization="organization"
                :project="project"
              />
              <ProjectSettingsAccessClients
                v-else-if="section.id === 'clients'"
                :key="section.id"
                v-model="state.members"
                :step="index + 1"
                :client.sync="state.client"
                :organization="organization"
                :project="project"
              />
              <ProjectSettingsAccessPermissions
                v-else-if="section.id === 'permissions'"
                :key="section.id"
                v-model="state.permissions"
                :step="index + 1"
                :organization="organization"
                :project="project"
              />
            </template>
          </div>
        </div>
      </section>
      <PageFooter>
        <Button
          :disabled="isBusy"
          size="lg"
          variant="primary"
          type="submit"
          @click="submit"
        >
          Save Changes
        </Button>
        <template v-if="hasChanges" #right>
          <PageFooterMessage />
        </template>
      </PageFooter>
    </Form>
  </div>
</template>
<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'
import { useCheckMemberRole } from '@/v2/services/myMembers/compositions'
import { ROLES } from '@/v2/services/members/membersTypes'
import { PERMISSIONS } from '@/v2/services/projects/projectsTypes'
import Form from '@/components/Form.vue'
import PageFooter from '@/components/PageFooter.vue'
import Button from '@/components/Button.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'
import ProjectSettingsAccessTeam from '@/components/ProjectSettingsAccessTeam.vue'
import ProjectSettingsAccessClients from '@/components/ProjectSettingsAccessClients.vue'
import ProjectSettingsAccessPermissions from '@/components/ProjectSettingsAccessPermissions.vue'
import PageHeader from '@/components/PageHeader.vue'

const makePermissionFields = roles => roles.flatMap(
  role => Object.keys(PERMISSIONS)
    .map(p => `permissions.${role}.${p}`)
)

const roles = Object.keys(ROLES)

const fields = [
  'client',
  'members',
  ...makePermissionFields(roles),
]

export default defineComponent({
  name: 'ViewProjectSettingsManageAccess',
  components: {
    Form,
    Button,
    PageFooter,
    PageFooterMessage,
    ProjectSettingsAccessTeam,
    ProjectSettingsAccessClients,
    ProjectSettingsAccessPermissions,
    PageHeader,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { role } = useCheckMemberRole()

    const sections = computed(
      () => [
        { id: 'team', active: role.value === ROLES.admin },
        { id: 'clients', active: true },
        { id: 'permissions', active: true },
      ].filter(({ active }) => active)
    )

    const {
      state,
      save,
    } = useFormEntityEditor({
      model: 'Project',
      id: computed(() => props.project._id),
      fields,
    })

    return {
      // formEntityEditor
      state,
      save,
      sections,
    }
  },
})
</script>
