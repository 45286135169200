<template>
  <div class="border border-gray-200 rounded-xl dark:border-darkGray-500 p-24 relative">
    <span
      v-if="step !== null"
      class="
        absolute w-32 h-32 bg-gray-200 left-24
        -top-16 flex items-center justify-center font-bold rounded-100
      "
    >{{ step }}</span>
    <slot />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CardSettings',
  components: {

  },
  props: {
    step: {
      type: Number,
      default: null,
    },
  },
})
</script>
