<template>
  <div>
    <span class="project-page-project-pretitle portal-paragraph-font">
      <slot name="preTitle" />
    </span>
    <div class="flex flex-row items-center justify-between">
      <h2 class="leading-snug project-page-title portal-heading-font">
        <slot name="breadcrumbs" />
        <slot name="heading" />
      </h2>
      <div class="flex flex-row">
        <slot name="buttons" />
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentListHeader',
}
</script>
