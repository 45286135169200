<template>
  <div>
    <p class="inline-flex items-center mb-4">
      <span class="font-semibold">{{ organization.email }}</span>
      <Badge v-if="statusActive" class="ml-6">Active</Badge>
      <Badge
        v-else-if="statusLocked"
        variant="danger"
        class="ml-6"
      >
        Disabled
      </Badge>
      <Badge
        v-else-if="statusRemoveRequested"
        variant="danger"
        class="ml-6"
      >
        Resetting...
      </Badge>
    </p>
    <p class="text-gray-600 text-14">
      <span v-if="statusActive">
        This is your email we're using to send email notification on your behalf.
        If you'd like to update it, please reset it first.
      </span>
      <span v-if="statusLocked">
        Your current plan doesn't include white label email. Please <b-link
          class="underline"
          :to="{
            name: ORGANIZATION_SUBSCRIPTION,
            params: { provider: LICENSING_PROVIDER.chargebee }
          }"
        >upgrade</b-link> to unlock your white label email.
      </span>
    </p>
    <div v-if="statusActive || statusLocked" class="mt-16">
      <Button variant="danger" @click="resetEmail">Reset Email</Button>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import { ORGANIZATION_SUBSCRIPTION } from '@/router/organization/type'
import { LICENSING_PROVIDER, EMAIL_STATUS } from '@/v2/services/organizations/organizationsTypes'
import { useMsgBoxConfirm } from '@/v2/lib/composition/useMsgBox'
import Badge from '@/components/Badge.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'SettingsEmailActive',
  components: {
    Badge,
    Button,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // email is up & running
    const statusActive = computed(
      () => props.organization.emailStatus === EMAIL_STATUS.active
    )

    // email was locked caused by a plan downgrade
    // the new plan doesn't have the `emailWhiteLabel` feature flag enabled
    const statusLocked = computed(
      () => props.organization.emailStatus === EMAIL_STATUS.locked
    )

    const statusRemoveRequested = computed(
      () => props.organization.emailStatus === EMAIL_STATUS.removeRequested
    )

    // -- reset email
    const confirm = useMsgBoxConfirm()
    const resetError = ref(null)

    const reset = async () => {
      resetError.value = null

      try {
        await props.organization.patch({
          data: {
            emailStatus: EMAIL_STATUS.removeRequested,
          },
        })
      } catch (err) {
        resetError.value = err.message
      }
    }

    const resetEmail = async () => {
      const ok = await confirm({
        title: 'Reset Email?',
        message: 'Please note that if you reset your custom email address, '
          + 'your clients will receive notifications from no-reply@superokay.com '
          + 'until you set up a new custom email address. '
          + 'Continue?',
        size: 'md',
      })

      if (ok) {
        reset();
      }
    }

    return {
      ORGANIZATION_SUBSCRIPTION,
      LICENSING_PROVIDER,
      statusActive,
      statusLocked,
      statusRemoveRequested,
      resetEmail,
    }
  },
})
</script>
