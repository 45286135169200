<template>
  <div class="flex flex-col space-y-8">
    <div class="relative text-gray-600 focus-within:text-gray-700">
      <b-input
        v-model="filterFont"
        autofocus
        placeholder="Search fonts"
        size="md"
        class="pr-32"
      />
      <span class="icon-magnify text-18 w-18 absolute right-12 top-12 text-gray-600" />
    </div>
    <b-form-select
      :value="value || 'ABeeZee'"
      :options="options"
      :select-size="16"
      plain
      @change="(font)=>$emit('input', font)"
    />
    <b-link
      v-if="value"
      class="underline hover:no-underline text-gray-400 hover:text-gray-100"
      @click="$emit('input',null)"
    >
      Reset
    </b-link>
  </div>
</template>

<script>
import { pluck } from 'ramda'
import { computed, ref } from '@vue/composition-api'
import axios from 'axios'

const pluckFamily = pluck('family')

export default {
  name: 'BrandingFontsFormSelect',
  props: {
    value: {
      type: String,
      default: null,
    },

  },
  setup() {
    const apiKey = import.meta.env.VITE_GOOGLE_FONTS_KEY
    const receivedFonts = ref(null)
    const selectedFont = ref('ABeeZee')
    const filterFont = ref('')

    // search
    const filteredFonts = computed(() => receivedFonts.value
      && receivedFonts.value.filter(font => font.family
        .toLowerCase()
        .includes(filterFont.value.toLowerCase())))
    const options = computed(() => filteredFonts.value && filteredFonts.value.map(font => ({
      text: font.family, value: font.family,
    })))
    // show a limit no of fonts per page

    const families = computed(() => filteredFonts.value && pluckFamily(filteredFonts.value))

    // load the fonts from Google Fonts
    const fetchFonts = () => {
      axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${apiKey}`)
        .then(({ data }) => receivedFonts.value = data.items.map(el => ({ filterByFamily: el.family.replace(/ /g, '+'), ...el })))
    }
    fetchFonts()

    return {
      receivedFonts,
      filterFont,
      families,
      options,
      selectedFont,
    }
  },

}

</script>

<style lang="scss" scoped>

</style>
