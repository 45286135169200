
<div :style="cssVars">
  <PageHeader title="Quick Links">
    <template #actions>
      <FeatureLimit
        v-slot="{ checkFeatureLimit }"
        label="quick links"
        flag="limitQuickLinks"
        @allow="() => edit(null)"
      >
        <b-button
          type="button"
          variant="neutral"
          size="md"
          @click="checkFeatureLimit"
        >
          <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600" />
          Add Quick Link
        </b-button>
      </FeatureLimit>
    </template>
  </PageHeader>
  <InfoBox
    title="Kickstart your project by adding some quicklinks."
    icon="icon_v2-so_interactive"
  >
    Add your most used links or bookmarks and they will appear on the portal dashboard.
  </InfoBox>
  <Form
    v-if="state"
    v-slot="{ isBusy, submit, hasChanges }"
    :state="state"
    success-message="Project changes saved"
    :on-submit="() => save()"
  >
    <div class="flex flex-col max-w-page">
      <!-- Pinned Folders -->
      <b-form-group>
        <SlickList
          :value="state.portalQuickLinks"
          use-drag-handle
          lock-to-container-edges
          axis="xy"
          class="flex flex-wrap gap-16 max-w-page"
          helper-class="so-table-row-dragging"
          @input="reorder"
        >
          <SlickItem
            v-for="(link, index) in state.portalQuickLinks"
            :key="link._id"
            :index="index"
            class="flex flex-col p-0 overflow-visible card-interactive relative"
          >
            <ActionsToolbar
              show-delete
              show-edit
              show-drag
              card-drag
              @edit="() => edit(link)"
              @remove="() => remove(link)"
            >
              <div class="px-24 py-32">
                <ProjectQuickLink :link="link" />
              </div>
            </ActionsToolbar>
          </SlickItem>
        </SlickList>
      </b-form-group>
      <!-- End of Pinned Folders -->
    </div>
    <PageFooter>
      <Button
        :disabled="isBusy"
        size="lg"
        variant="primary"
        type="submit"
        @click="submit"
      >
        Save Changes
      </Button>
      <template v-if="hasChanges" #right>
        <PageFooterMessage />
      </template>
    </PageFooter>
  </Form>
  <ProjectSettingsQuickLinkModal
    v-if="isModalOpen"
    v-model="isModalOpen"
    :quick-link="quickLink"
    @create="create"
    @update="update"
  />
</div>
