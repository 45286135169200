<template>
  <button
    :class="['card-interactive transition-all cursor-pointer',
             'text-14 text-center text-gray-600 font-semibold',
             'flex flex-col items-center w-88 py-8']"
    @click.stop="addColumn"
  >
    <span
      :class="['w-24 text-24 text-gray-700',
               `icon-columns-variation-${$props.ratio.join('')}`
      ]"
    />
    <span>{{ showRatio }}</span>
  </button>
</template>

<script>
import { computed } from '@vue/composition-api'
import debounce from '@/v2/lib/helpers/debounce'

export default {
  name: 'ColumnsPlaceholder',
  components: {},
  props: {
    ratio: {
      type: Array,
      default: () => [],
    },

  },
  setup(props, context) {
    const showRatio = computed(() => props.ratio.join('/'))
    const addNewColumn = () => {
      // disabled.value = true
      context.emit('add-columns', props.ratio)
    }
    const addColumn = debounce(addNewColumn, 1000)

    return {
      showRatio,
      addColumn,
    }
  },
}
</script>

<style>

</style>
