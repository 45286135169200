
<div class="py-6 text-14">
  <p class="flex items-center mb-8 gap-8">
    <b-input
      v-model="keyword"
      autofocus
      type="search"
      placeholder="Filter Tags..."
      size="md"
      class="text-12 pr-24 rounded-sm"
    />
  </p>
  <div class="text-gray-700 inline-flex flex-wrap gap-6 max-h-280 overflow-y-auto">
    <b-form-checkbox
      v-for="tag in sortedTags"
      :key="tag"
      :checked="Boolean(value[tag])"
      class="custom-checkbox-list"
      size="sm"
      @change="val => onChange(tag, val)"
    >
      <span>{{ tag }}</span>
    </b-form-checkbox>
  </div>
  <p class="text-right">
    <b-link
      class="text-green-600 underline hover:no-underline"
      @click="clearTags"
    >
      Clear tags
    </b-link>
  </p>
</div>
