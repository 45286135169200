
<div
  class="text-center p-32 relative flex flex-col border border-gray-300 rounded-xl shadow-xl"
  :class="{ 'border-green-500': plan.isCurrent }"
>
  <div
    v-if="plan.discount?.discountPercentage"
    class="pricing-plan-discount"
  >
    <span>{{ plan.discount.discountPercentage }}% off</span>
  </div>
  <div class="flex-grow">
    <h2 class="font-bold text-gray-900 text-18">{{ plan.name }}</h2>
    <div class="mb-32 text-gray-600 text-14">{{ plan.description }}</div>
    <div class="flex flex-row items-end justify-center mb-32">
      <div class="price-text">
        <span v-if="pricePerMonth.price === 0">Free</span>
        <template v-else>
          <CurrencyFormatter
            :value="pricePerMonth.price"
            :currency="pricePerMonth.currency"
            :class="pricePerMonth.discountedPrice && 'line-through text-gray-400'"
          />
          <CurrencyFormatter
            v-if="pricePerMonth.discountedPrice"
            :value="pricePerMonth.discountedPrice"
            :currency="pricePerMonth.currency"
            class="ml-4"
          />
        </template>
      </div>
      <div v-if="periodUnit" class="text-gray-600 price-time">/month</div>
    </div>
    <!-- flag groups -->
    <PaymentPlanCardFeatures
      :feature-flags-groups="plan.featureFlagsGroups"
    />
  </div>
  <Button
    v-if="!plan.isAvailable && !plan.isCurrent"
    variant="neutral"
    size="lg"
    disabled
    block
  >
    Included
  </Button>
  <Button
    v-else-if="plan.isCurrent"
    variant="neutral"
    size="lg"
    disabled
    block
  >
    Your Plan
  </Button>
  <Button
    v-else-if="plan.isAvailable"
    block
    size="lg"
    @click="$emit('select')"
  >
    <span v-if="plan.isDowngrade">Downgrade</span>
    <span v-else-if="plan.isUpgrade">Upgrade</span>
    <span v-else>Select</span>
  </Button>
</div>
