<template>
  <div
    :class="[$style['cell'], field.labelAlign && $style[field.labelAlign]]"
    :style="{ width: field.width }"
  >
    {{ field.label }}
  </div>
</template>

<script>
export default {
  name: 'ListTableCellHeader',
  props: {
    field: Object,
  },
}
</script>

<style lang="scss" module>
.cell {
  display: table-cell;
  border-bottom: 1px solid $gray-300;
  font-weight: $medium;
  text-align: left;
  padding: 6px 0;
  padding-right: 18px;
  &:last-child {
    padding-right: 0;
  }

  &.right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }
}
</style>
