export const quickEmbedApps = [
  {
    name: 'Miro',
    logo: 'miro',
    icon: 'brand-miro',
    instructions: [
      {
        title: 'Enable sharing in Miro',
        description: 'Open the Share window and choose the level of access for Anyone with the link.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/3ea480fde4928411954b8ffbd2bd96cfd28f926cf92c258a59777f7d2a4c48d6.png',
        readMore: '',
      },
      {
        title: 'Copy board link',
        description: 'Copy the board link from Miro and paste it here in the "Link" field.',
        screenshot: '',
        readMore: '',
      },
    ],
  },
  {
    name: 'Airtable',
    logo: 'airtable',
    icon: 'brand-airtable',
    instructions: [
      {
        title: 'Create a view for sharing',
        description: 'Click the share link button in the view bar. This will bring up an option to create a shareable grid, calendar, gallery, or kanban view link.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/a947ee9c5a3dd5733be39f98e9a423881b5efdec02659c370ba7c6ba85c8bc24.gif',
        readMore: '',
      }, {
        title: 'Copy the Airtable share link',
        description: 'Copy the share link from Airtable and paste it here in the "Link" field',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/9eaa462c3a6ffaf5b0b69682caa2a10b76ba05fb3be1477fc902e966ba252eeb.png',
        readMore: '',
      },
    ],
  },
  {
    name: 'Figma',
    logo: 'figma',
    icon: 'brand-figma',
    instructions: [
      {
        title: 'Open the Share Modal',
        description: 'Click the Share button in the toolbar and click "Copy link" to copy the link in your clipboard. Paste that link here in the "Link" field.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/739f9df3a64eaa2529c706f4517c0e97683161601f0edd368988877629b051ee.png',
        readMore: '',
      },
    ],
  },
  {
    name: 'Canva',
    logo: 'canva',
    icon: 'brand-canva',
    instructions: [
      {
        title: 'Share from Canva',
        description: 'Click the Share button, then click on More to show the Embed option',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/fede871720b08b8d4202469aa714e0e2d809294e79e03819c3b810fc2ca4c4f6.png',
        readMore: '',
      }, {
        title: 'Get the smart embed link',
        description: 'Copy the Smart embed link and paste it here in the "Link" field',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/10eaf4ffd0e6fc5ee5df400cf98513a6f365da224cbdd0f4794fb62ba80c3af1.png',
        readMore: '',
      },
    ],
  },
  {
    name: 'YouTube',
    logo: 'youtube',
    icon: 'brand-youtube',
    instructions: [
      {
        title: 'Copy the YouTube link',
        description: 'Just copy the link to any YouTube video and paste it here in the "Link" field',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/36000afd95719a225acd39ab7d684360f19f4f06d16d1d5cb859f27f460e4f63.png',
        readMore: '',
      },
    ],
  },
  {
    name: 'Data Studio',
    logo: 'data-studio',
    icon: 'brand-data-studio',
    instructions: [
      {
        title: 'Share the report',
        description: 'For a public report, use the link sharing options "Public on the web" or "Anyone with the link can view"',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/a560ddfabd2f64750b551db5d51ac7fff15f98968d378d97e0e169a6a369eba0.png',
        readMore: '',
      }, {
        title: 'Enable embedding',
        description: 'Select File > Embed report, the click Enable embedding',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/6a5d5e831467b8b5f2bf94ff778bfe660792ba915ba4f612cdb725096526c631.png',
        readMore: '',
      }, {
        title: 'Get the Data Studio embed link',
        description: 'Select Embed URL, then click COPY TO CLIPBOARD',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/01e97dca672dd4193af78400d4c2d4bc0a4edfe4000676e90874fa15176e92f6.png',
        readMore: '',
      },
    ],
  },
  {
    name: 'Loom',
    logo: 'loom',
    icon: 'brand-loom',
    instructions: [
      {
        title: 'Copy the Loom video\'s public link',
        description: 'Make sure your Privacy Settings allow for anyone with the link to view the video.',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/53f182cdcae50e5b38144691f53f228b19d97d808cb38c3d9ef134a95e23be06.png',
        readMore: '',
      },
    ],
  },
  {
    name: 'Trello',
    logo: 'trello',
    icon: 'brand-trello',
    instructions: [
      {
        title: 'Make sure the Trello board is public',
        description: 'Change your board\'s visibility to Public on Trello',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/45f339b033e9e27123766ab760867e05f38a8f3bd0a93e5786f90391db15ac00.png',
        readMore: '',
      },
      {
        title: 'Copy the Trello board URL',
        description: 'Copy the board URL from Trello and paste it here in the "Link" field',
        readMore: '',
      },
    ],
  },
  {
    name: 'ClickUp',
    logo: 'clickup',
    icon: 'brand-clickup',
    instructions: [
      {
        title: 'Share a view from ClickUp',
        description: 'Locate Share next to the ... menu, then toggle on Public sharing',
        screenshot: 'https://s3.eu-west-2.amazonaws.com/superokay/33fc2ba7aa1c64fb9d1089b47a114696cfa1af51d228173d7a9e8f06123839f0.gif',
        readMore: '',
      },
      {
        title: 'Copy the ClickUp link',
        description: 'Add the link to the "Link" field on this form',
        readMore: '',
      },
    ],
  },
]
