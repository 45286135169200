<template>
  <div v-fragment>
    <component
      :is="component"
      :document="document"
      @share="showShareModal = true"
    />
    <ContentLibraryModal :document="document" />
    <DocumentEditorShareModal
      v-model="showShareModal"
      :document="document"
      size="lg"
    />
    <DocumentSelectionToolbar />
  </div>
</template>

<script>
import { ref, computed, provide, toRef, onUnmounted, onMounted, defineComponent, getCurrentInstance, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import debounce from '@/v2/lib/helpers/debounce'
import useBrowserTitle from '@/v2/lib/composition/useBrowserTitle'
import useWindowSize from '@/v2/lib/composition/useWindowSize'
import { useNodeWidth, widthSize } from '@/v2/lib/composition/useNodeWidth'
import { CB_ACTIONS } from '@/types/clipboard'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'

import CommentsSidebar from '@/components/CommentsSidebar.vue'
import FeathersCount from '@/components/FeathersCount.vue'
import ContentLibraryModal from '@/components/ContentLibrary/ContentLibraryModal.vue'
// document types
import DocumentEditorDefault from '@/components/Document/Editor/DocumentEditorDefault.vue'
import DocumentEditorTeamBio from '@/components/Document/Editor/DocumentEditorTeamBio.vue'
import DocumentEditorCaseStudy from '@/components/Document/Editor/DocumentEditorCaseStudy.vue'
import DocumentEditorPackagedService from '@/components/Document/Editor/DocumentEditorPackagedService.vue'
import DocumentEditorReusableBlock from '@/components/Document/Editor/DocumentEditorReusableBlock.vue'
import DocumentEditorPage from '@/components/Document/Editor/DocumentEditorPage.vue'

import DocumentSelectionToolbar from '../DocumentSelectionToolbar.vue'
import DocumentEditorShareModal from './DocumentEditorShareModal.vue'

const componentMap = {
  [DOCUMENT_CATEGORY.DocumentTeamBio]: DocumentEditorTeamBio,
  [DOCUMENT_CATEGORY.DocumentCaseStudy]: DocumentEditorCaseStudy,
  [DOCUMENT_CATEGORY.DocumentPackagedService]: DocumentEditorPackagedService,
  [DOCUMENT_CATEGORY.DocumentReusableBlock]: DocumentEditorReusableBlock,
  [DOCUMENT_CATEGORY.DocumentPage]: DocumentEditorPage,
  default: DocumentEditorDefault,
}

const { useState: useStateDocumentEditor, useActions } = createNamespacedHelpers('documentEditor')

const { useState } = createNamespacedHelpers('globalFlags')


export default defineComponent({
  name: 'DocumentEditor',
  components: {
    ContentLibraryModal,
    DocumentEditorDefault,
    DocumentEditorShareModal,
    DocumentSelectionToolbar,
    CommentsSidebar,
    FeathersCount,
  },
  props: {
    document: {
      type: Object,
      default: null,
    },
    organizationId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showAnnouncementCard } = useState(['showAnnouncementCard'])
    const {
      previewDocumentId,
      clipboard,
      commentsSidebarStatus,
      documentSidebarStatus,
      showNotifications,
    } = useStateDocumentEditor([
      'previewDocumentId',
      'clipboard',
      'commentsSidebarStatus',
      'documentSidebarStatus',
      'showNotifications',

    ])
    const { width, resizeEventListener } = useWindowSize()
    const { load } = useNodeWidth()

    const isPreview = computed(() => previewDocumentId.value === props.document._id)
    const showShareModal = ref(false)
    const component = computed(
      () => componentMap[props.document.category] || componentMap.default
    )

    provide('isEditor', computed(() => !isPreview.value))
    provide('isViewer', computed(() => isPreview.value))
    provide('isViewerPDF', computed(() => false))
    provide('isLocked', computed(() => props.document.isApproved))
    provide('isSandbox', computed(() => false))
    provide('document', toRef(props, 'document'))
    // provide('organizationId', computed(() => props.organizationId))

    const {
      clearClipboard,
      clearNodeSelection,
      addNodeWidth,
      clearNodesWidth,
    } = useActions([
      'clearClipboard',
      'clearNodeSelection',
      'addNodeWidth',
      'clearNodesWidth',
    ])

    // Set visibility of Intercom widget
    // so the widget does not appear on top of the comments form
    // for logged in users.
    const vm = getCurrentInstance().proxy

    const { DocumentStructure } = vm.$FeathersVuex.api

    const updateNodesWidth = debounce(() => {
      const structure = DocumentStructure.getFromStore(props.document.structure)
      structure?.nodes.forEach(nodeId => {
        const nodeWidth = load(nodeId)
        addNodeWidth(widthSize({ [nodeId]: nodeWidth }))
      })
    }, 500)


    // Set page title
    useBrowserTitle(computed(() => (props.document
      ? `${props.document.title} - SuperOkay`
      : 'SuperOkay')))

    onMounted(() => {
      resizeEventListener()
    })
    onUnmounted(() => {
      // Clear clipboard if content was placed there by a "cut" action
      clipboard.value.action === CB_ACTIONS.cut && clearClipboard()
      clearNodeSelection() // Clear multiple selection
      resizeEventListener()
      clearNodesWidth()
    })
    watch(
      () => width.value,
      () => updateNodesWidth()
    )

    return {
      showAnnouncementCard,
      showShareModal,
      component,
      commentsSidebarStatus,
      showNotifications,
      documentSidebarStatus,
    }
  },
})
</script>
