<template>
  <div class="py-6 text-14">
    <p class="flex items-center mb-8 gap-8">
      <b-input
        v-model="keyword"
        autofocus
        type="search"
        placeholder="Filter Tags..."
        size="md"
        class="text-12 pr-24 rounded-sm"
      />
    </p>
    <div class="text-gray-700 inline-flex flex-wrap gap-6 max-h-280 overflow-y-auto">
      <b-form-checkbox
        v-for="tag in sortedTags"
        :key="tag"
        :checked="Boolean(value[tag])"
        class="custom-checkbox-list"
        size="sm"
        @change="val => onChange(tag, val)"
      >
        <span>{{ tag }}</span>
      </b-form-checkbox>
    </div>
    <p class="text-right">
      <b-link
        class="text-green-600 underline hover:no-underline"
        @click="clearTags"
      >
        Clear tags
      </b-link>
    </p>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import matchKeyword from '@/lib/match-keyword'

export default defineComponent({
  name: 'ProjectSwitcherTags',
  components: {

  },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const keyword = ref('')

    const onChange = (tag, value) => {
      context.emit('input', { ...props.value, [tag]: value })
    }

    const filteredTags = computed(() => props.tags.filter(matchKeyword(keyword.value)));

    const sortedTags = computed(() => {
      const items = [...filteredTags.value]
      items.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      return items
    })

    const clearTags = () => {
      context.emit('input', props.tags.reduce(
        (acc, tag) => ({ ...acc, [tag]: false }),
        {}
      ));
    }

    return {
      keyword,
      sortedTags,
      onChange,
      clearTags,
    }
  },
})
</script>
