
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isEmpty"
  :root-class="['node-group-columns-container']"
  :config-override="{
    stylingToolbar: {
      features: {
        sizes: true,
        settings: true,
      },
    },
  }"
>
  <template #default="{ isHover }">
    <SlickList
      v-if="!isEmpty"
      v-model="nodes"
      lock-to-container-edges
      axis="xy"
      use-drag-handle
      helper-class="so-group-node-dragging"
      class="flex flex-row items-stretch w-full px-12 py-20 space-x-12 print:py-0"
      @sort-start="handleSortStart"
      @sort-end="handleSortEnd"
    >
      <SlickItem
        v-for="(nodeId, index) in groupColumnNodes"
        :key="nodeId"
        :index="index"
        :class="`flex flex-col relative column-${columnsRatio[index] == 100 ? 'oo' : 'mto'}`"
        :style="{ width: `${columnsRatio[index]}%`}"
      >
        <DocumentNodeEditable
          :index="index"
          :node-id="nodeId"
          :parent="node._id"
          class="so-slick-inner"
        />
      </SlickItem>
    </SlickList>
    <DocumentNodeGroupColumnsContainerPlaceholder
      v-else
      @add-columns="addColumns"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
  <template #styling-settings="{ value, update }">
    <DocumentNodeGroupColumnsContainerSettings
      :node-id="node._id"
      :value="value"
      @input="(k, v) => update({ [k]: v })"
    />
  </template>
</DocumentNodeRenderer>
