<template>
  <nav class="text-gray-700 p-4">
    <ul v-if="!disabled">
      <li>
        <MainHeaderNavItemCount
          label="Inbox"
          :count="10"
          :to="{ name: ORGANIZATION_INBOX }"
          model="Notification"
          :events="['markAllAsRead']"
          :query="{ organization: organization._id, read: false }"
        />
      </li>
    </ul>
  </nav>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import MainHeaderNavItemCount from '@/components/MainHeaderNavItemCount.vue'
import {
  ORGANIZATION_INBOX,
} from '@/router/organization/type'

export default defineComponent({
  name: 'MainHeaderNav',
  components: {
    MainHeaderNavItemCount,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ORGANIZATION_INBOX,
    }
  },
})
</script>
<style lang="postcss" scoped>
  .app-nav ul > li:not(:last-child) {
    @apply mb-16;
  }
</style>
