
<ProjectGet
  :id="projectId"
  v-slot="{ project }"
>
  <div v-if="project" v-fragment>
    <PortalRender to="app-sidebar-header" :provide="{ project }">
      <ProjectNavHeader :project="project" />
    </PortalRender>
    <Provide :provide="{ project }">
      <Watch
        :value="project"
        immediate
        @change="setProjectLocale"
      >
        <LoaderWebFont
          :families="[
            project?.brandingFontHeading ?? 'Inter',
            project?.brandingFontParagraph ?? 'Inter',
          ]"
        >
          <router-view
            :organization="organization"
            :project="project"
          />
        </LoaderWebFont>
      </Watch>
    </Provide>
  </div>
</ProjectGet>
