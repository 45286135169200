<template>
  <div>
    <div
      v-for="featureFlagsGroup in featureFlagsGroups"
      :key="featureFlagsGroup.id"
      class="mb-16"
    >
      <PaymentPlanCardFeature
        v-for="featureFlag in featureFlagsGroup.featureFlags"
        :key="featureFlag.featureFlag"
        :group="featureFlagsGroup"
        :feature-flag="featureFlag"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import PaymentPlanCardFeature from '@/components/PaymentPlanCardFeature.vue'

export default defineComponent({
  name: 'PaymentPlanCardFeatures',
  components: {
    PaymentPlanCardFeature,
  },
  props: {
    featureFlagsGroups: {
      type: Array,
      default: () => [],
    },
  },
})
</script>
