<template>
  <WithSidebar class="pb-16 min-h-full">
    <template #sidebar-header>
      <h2 class="font-semibold text-16">Settings</h2>
    </template>
    <template #sidebar>
      <OrganizationSettingsNav />
    </template>
    <template #default>
      <router-view
        :organization="organization"
        :licensing="licensing"
        :plan="plan"
      />
    </template>
  </WithSidebar>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import WithSidebar from '@/components/WithSidebar.vue';
import OrganizationSettingsNav from '@/components/OrganizationSettingsNav.vue';

export default defineComponent({
  name: 'ViewOrganizationSettings',
  components: {
    WithSidebar,
    OrganizationSettingsNav,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    licensing: {
      type: Object,
      required: true,
    },
    plan: {
      type: Object,
      default: null,
    },
  },
})
</script>
