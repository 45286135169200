import ViewLandingInvite from '@/views/ViewLandingInvite.vue'
import ViewLandingInviteUser from '@/views/ViewLandingInviteUser.vue'
import ViewLandingInviteGuest from '@/views/ViewLandingInviteGuest.vue'

import {
  LANDING_INVITE,
  LANDING_INVITE_USER,
  LANDING_INVITE_GUEST,
} from './type'
import { mapParamsToProps, reqCtxNs } from '@/v2/lib/hooks/requestContextForServer'

export default () => [
  {
    path: `/invite/:${reqCtxNs('token')}`,
    name: LANDING_INVITE,
    component: ViewLandingInvite,
    meta: {
      title: 'Join Organization',
      noRedirect: true,
    },
    props: mapParamsToProps,
    children: [
      {
        name: LANDING_INVITE_USER,
        path: 'user',
        component: ViewLandingInviteUser,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: LANDING_INVITE_GUEST,
        path: 'sign-up',
        component: ViewLandingInviteGuest,
        props: true,
        meta: {
          requiresGuest: true,
        },
      },
    ],
  },
]
