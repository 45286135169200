
<DocumentNodeEditorToolbarPopover
  :width="320"
  class-name="w-32 rounded hover:bg-gray-500 hover:bg-opacity-10"
  tooltip="Heading size"
>
  <template #button-content>
    <span
      :class="[
        'w-24 text-24 text-white',
        `icon-toolbar-heading-${value[value.length - 1]}`,
      ]"
    ></span>
    <span :class="[$style['toolbar-dropdown']]"></span>
  </template>
  <!-- <template #title>
    Heading Size
  </template> -->
  <template #default>
    <ul class="p-0 m-0">
      <li
        v-for="size in sizes"
        :key="size.value"
        class="mb-8 list-none"
      >
        <button
          :class="[
            {
              'bg-green-500 bg-opacity-20 hover:opacity-100':
                size.value === value,
            },
            $style[`heading-${size.value}`],
            'font-semibold text-white rounded text-left hover:no-underline hover:bg-green-500 hover:bg-opacity-20 px-12 w-full',
          ]"
          @click="$emit('input', size.value)"
        >
          {{ size.label }}
        </button>
      </li>
    </ul>
  </template>
</DocumentNodeEditorToolbarPopover>
