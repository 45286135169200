
<div
  ref="target"
  class="project-switcher"
  @keydown.esc.prevent="hidePopover"
>
  <ProjectSwitcherHeader
    :tags="tags"
    :keyword.sync="keyword"
    :selected-tags.sync="selectedTags"
    :selected-tag-count="selectedTagCount"
    @all-projects="goToAllProjects"
    @new-project="newProject"
  />
  <ul class="project-switcher-list p-8">
    <li
      v-for="project in projects"
      :key="project._id"
      class="
        hover:bg-gray-200 dark:text-white
        dark:hover:bg-gray-300 dark:hover:text-gray-700 p-4
        px-8 mb-4 flex items-center justify-between rounded-sm
      "
      :class="{
        'bg-gray-200 dark:bg-gray-300 relative cursor-default pr-28 dark:text-darkGray-700':
          project._id === currentProject?._id,
        'cursor-pointer': project._id !== currentProject?._id,
      }"
      @click="select(project)"
    >
      <ProjectSwitcherItem
        :project="project"
        :selected="project._id === currentProject?._id"
        :show-client="showClient"
        :dark-theme="darkTheme"
      />
      <span
        v-if="project._id === currentProject?._id"
        class="
          icon_v2-so_tick absolute right-4
          top-6 text-gray-400 pointer-events-none
        "
      />
    </li>
  </ul>
</div>
