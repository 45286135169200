
<ModalBasic
  :title="texts.modalTitle"
  :subtitle="subtitle"
  :should-close-on-backdrop="false"
  :hide-footer="true"
  content-class="modal-content-tertiary"
  body-class="modal-body-tertiary"
  header-class="modal-header-tertiary"
  footer-class="modal-footer-tertiary"
  size="md"
  v-bind="$attrs"
  v-on="$listeners"
  @hide="trackModalClosed"
>
  <template #body="{ close }">
    <Form
      v-slot="{ state, v }"
      :state="formState"
      :on-submit="onSave"
      :validation="validation"
      :success-message="texts.formSuccessMessage"
    >
      <b-form-group>
        <FormField
          label="First Name"
          :v="v.firstName"
          required
          :messages="{
            required: 'First name is needed',
          }"
        >
          <b-input
            v-model="state.firstName"
            :disabled="!(isNew || isInvited || createAsClientContact)"
            autofocus
            placeholder="Alex"
            class="form-control-lg"
          />
        </FormField>
      </b-form-group>
      <b-form-group>
        <FormField
          label="Last Name"
          :v="v.lastName"
          required
          :messages="{
            required: 'Last name is needed',
          }"
        >
          <b-input
            v-model="state.lastName"
            :disabled="!(isNew || isInvited || createAsClientContact)"
            placeholder="Appleseed"
            class="form-control-lg"
          />
        </FormField>
      </b-form-group>
      <b-form-group>
        <FormField
          label="Email Address"
          :v="v.email"
          required
          :messages="{
            email: 'A valid email address is required',
            required: 'A valid email address is required',
            available: 'Email address not available',
          }"
        >
          <b-input
            v-model.trim="formStateEmail"
            :disabled="!(isNew || isInvited || createAsClientContact)"
            placeholder="alex.appleseed@email.com"
            class="form-control-lg"
          />
        </FormField>
      </b-form-group>
      <b-form-group>
        <FormField
          label="Role"
          :v="v.role"
          required
          :messages="{
            required: 'Role is required',
          }"
        >
          <b-select
            v-model="state.role"
            placeholder="Role"
            :options="roleOptions"
            class="form-control-lg error"
            :disabled="createAsClientContact || member?.isOwner"
          >
            <template #first>
              <b-form-select-option :value="null">Select...</b-form-select-option>
            </template>
          </b-select>
        </FormField>
      </b-form-group>
      <Buttons>
        <Button
          type="button"
          variant="neutral"
          size="md"
          @click="close"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          size="md"
        >
          {{ texts.saveButtonLabel }}
        </Button>
      </Buttons>
    </Form>
  </template>
</ModalBasic>
