<template>
  <nav>
    <template v-if="!isPending">
      <SidebarGroup v-if="clients.length">
        <SidebarMenu>
          <SidebarMenuLink
            v-for="client in clients"
            :key="client._id"
            :to="{
              name: ORGANIZATION_CLIENT,
              params: { clientId: client._id },
            }"
            activate-on-child-route
          >
            <SidebarItemBasic :label="client.name">
              <template #icon>
                <span
                  class="inline-block w-16 h-16 rounded-full mr-4"
                  :style="{
                    backgroundColor: client.mainColor,
                  }"
                ></span>
              </template>
            </SidebarItemBasic>
          </SidebarMenuLink>
        </SidebarMenu>
      </SidebarGroup>
      <div v-else class="text-gray-800 opacity-50 flex flex-col items-center">
        <span class="text-64 icon_v2-so_clients" />
        <div>You have no clients</div>
      </div>
    </template>
  </nav>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { ORGANIZATION_CLIENT } from '@/router/organization/type'
import SidebarGroup from '@/components/Sidebar/SidebarGroup.vue'
import SidebarMenu from '@/components/Sidebar/SidebarMenu.vue'
import SidebarMenuLink from '@/components/Sidebar/SidebarMenuLink.vue'
import SidebarItemBasic from '@/components/Sidebar/SidebarItemBasic.vue'

export default defineComponent({
  name: 'OrganizationClientsNav',
  components: {
    SidebarGroup,
    SidebarMenu,
    SidebarMenuLink,
    SidebarItemBasic,
  },
  props: {
    clients: {
      type: Array,
      required: true,
    },
    isPending: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      ORGANIZATION_CLIENT,
    }
  },
})
</script>
