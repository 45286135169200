<template>
  <PrismEditor
    v-bind="$attrs"
    class="so-code-editor"
    :highlight="highlighter"
    line-numbers
    v-on="$listeners"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { PrismEditor } from 'vue-prism-editor';

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
// import syntax highlighting styles
export default defineComponent({
  name: 'CodeEditor',
  components: {
    PrismEditor,
  },
  setup() {
    // languages.<insert language> to return html with markup
    const highlighter = code => highlight(code, languages.markup)


    return {
      highlighter,
    }
  },
})
</script>
