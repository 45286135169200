
<nav>
  <SidebarGroup>
    <SidebarMenu>
      <SidebarMenuLink
        :to="{ name: USER_SETTINGS_PROFILE }"
      >
        <SidebarItemBasic label="My Account" icon="icon_v2-so_user-circle" />
      </SidebarMenuLink>
      <SidebarMenuLink
        :to="{ name: USER_SETTINGS_SECURITY }"
      >
        <SidebarItemBasic label="Security" icon="icon_v2-so_lock" />
      </SidebarMenuLink>
      <SidebarMenuLink
        :to="{ name: USER_SETTINGS_NOTIFICATIONS }"
      >
        <SidebarItemBasic label="Notifications" icon="icon_v2-so_notification-ringing" />
      </SidebarMenuLink>
    </SidebarMenu>
  </SidebarGroup>
</nav>
