import ViewOrganizationSettings from '@/views/settings/ViewOrganizationSettings.vue'
import ViewOrganizationSettingsMyOrganization from '@/views/settings/ViewOrganizationSettingsMyOrganization.vue'
import ViewOrganizationSettingsCustomDomain from '@/views/settings/ViewOrganizationSettingsCustomDomain.vue'
import ViewOrganizationSettingsEmailWhiteLabel from '@/views/settings/ViewOrganizationSettingsEmailWhiteLabel.vue'
import ViewOrganizationSettingsTeamMembers from '@/views/settings/ViewOrganizationSettingsTeamMembers.vue'
import ViewOrganizationSettingsSubscription from '@/views/settings/ViewOrganizationSettingsSubscription.vue'
import ViewOrganizationSettingsSubscriptionManage from '@/views/settings/ViewOrganizationSettingsSubscriptionManage.vue'
import ViewOrganizationSettingsPortal from '@/views/settings/ViewOrganizationSettingsPortal.vue'
import ViewOrganizationSettingsPortalSettings from '@/views/settings/ViewOrganizationSettingsPortalSettings.vue'
import ViewOrganizationSettingsPortalLoginTheme from '@/views/settings/ViewOrganizationSettingsPortalLoginTheme.vue'
import ViewOrganizationSettingsPortalSeo from '@/views/settings/ViewOrganizationSettingsPortalSeo.vue'

import { ROLES } from '@/v2/services/members/membersTypes'

import {
  ORGANIZATION_SETTINGS,
  ORGANIZATION_SETTINGS_MY_ORGANIZATION,
  ORGANIZATION_SETTINGS_DOMAIN,
  ORGANIZATION_SETTINGS_EMAIL,
  ORGANIZATION_SETTINGS_TEAM,
  ORGANIZATION_SETTINGS_SUBSCRIPTION,
  ORGANIZATION_SETTINGS_SUBSCRIPTION_MANAGE,
  ORGANIZATION_SETTINGS_PORTAL,
  ORGANIZATION_SETTINGS_PORTAL_SETTINGS,
  ORGANIZATION_SETTINGS_PORTAL_LOGIN_THEME,
  ORGANIZATION_SETTINGS_PORTAL_SEO,
} from './type'

export default [
  {
    name: ORGANIZATION_SETTINGS,
    path: 'settings',
    component: ViewOrganizationSettings,
    props: true,
    redirect: {
      name: ORGANIZATION_SETTINGS_MY_ORGANIZATION,
    },
    meta: {
      requiresRoles: [ROLES.admin],
    },
    children: [
      {
        name: ORGANIZATION_SETTINGS_MY_ORGANIZATION,
        path: 'my-organization',
        component: ViewOrganizationSettingsMyOrganization,
        props: true,
      },
      {
        name: ORGANIZATION_SETTINGS_DOMAIN,
        path: 'domain',
        component: ViewOrganizationSettingsCustomDomain,
        props: true,
      },
      {
        name: ORGANIZATION_SETTINGS_EMAIL,
        path: 'email',
        component: ViewOrganizationSettingsEmailWhiteLabel,
        props: true,
      },
      {
        name: ORGANIZATION_SETTINGS_SUBSCRIPTION,
        path: 'subscription',
        component: ViewOrganizationSettingsSubscription,
        props: true,
      },
      {
        name: ORGANIZATION_SETTINGS_SUBSCRIPTION_MANAGE,
        path: 'subscription-manage',
        component: ViewOrganizationSettingsSubscriptionManage,
        props: true,
      },
      {
        name: ORGANIZATION_SETTINGS_TEAM,
        path: 'team',
        component: ViewOrganizationSettingsTeamMembers,
        props: true,
      },
      {
        name: ORGANIZATION_SETTINGS_PORTAL,
        path: 'portal',
        component: ViewOrganizationSettingsPortal,
        props: true,
        redirect: { name: ORGANIZATION_SETTINGS_PORTAL_SETTINGS },
        children: [
          {
            name: ORGANIZATION_SETTINGS_PORTAL_SETTINGS,
            path: '',
            component: ViewOrganizationSettingsPortalSettings,
            props: true,
          },
          {
            name: ORGANIZATION_SETTINGS_PORTAL_LOGIN_THEME,
            path: 'login-theme',
            component: ViewOrganizationSettingsPortalLoginTheme,
            props: true,
          },
          {
            name: ORGANIZATION_SETTINGS_PORTAL_SEO,
            path: 'seo',
            component: ViewOrganizationSettingsPortalSeo,
            props: true,
          },
        ],
      },
    ],
  },
]
