import ViewOrganizationClients from '@/views/clients/ViewOrganizationClients.vue'
import ViewOrganizationClientsPlaceholder from '@/views/clients/ViewOrganizationClientsPlaceholder.vue'
import ViewOrganizationClient from '@/views/clients/ViewOrganizationClient.vue'
import ViewOrganizationClientContacts from '@/views/clients/ViewOrganizationClientContacts.vue'
import ViewOrganizationClientDetails from '@/views/clients/ViewOrganizationClientDetails.vue'

import { ROLES } from '@/v2/services/members/membersTypes'

import {
  ORGANIZATION_CLIENTS,
  ORGANIZATION_CLIENTS_PLACEHOLDER,
  ORGANIZATION_CLIENT,
  ORGANIZATION_CLIENT_DETAILS,
  ORGANIZATION_CLIENT_CONTACTS,
} from './type'

// CLIENTS
export default [{
  name: ORGANIZATION_CLIENTS,
  path: 'clients',
  component: ViewOrganizationClients,
  props: true,
  meta: {
    requiresRoles: [ROLES.admin, ROLES.projectManager],
  },
  redirect: { name: ORGANIZATION_CLIENTS_PLACEHOLDER },
  children: [
    {
      path: '',
      name: ORGANIZATION_CLIENTS_PLACEHOLDER,
      component: ViewOrganizationClientsPlaceholder,
    },
    {
      name: ORGANIZATION_CLIENT,
      path: ':clientId',
      component: ViewOrganizationClient,
      props: true,
      redirect: { name: ORGANIZATION_CLIENT_CONTACTS },
      children: [
        {
          name: ORGANIZATION_CLIENT_CONTACTS,
          path: 'contacts',
          component: ViewOrganizationClientContacts,
          props: true,
        },
        {
          name: ORGANIZATION_CLIENT_DETAILS,
          path: 'details',
          component: ViewOrganizationClientDetails,
          props: true,
        },
      ],
    },
  ],
}]
