
<div>
  <PortalRender to="app-sidebar" :provide="{ project }">
    <ProjectNavSettings
      :organization="organization"
      :project="project"
    />
  </PortalRender>
  <ProjectTheme :project="project" no-dark-mode>
    <router-view
      :organization="organization"
      :project="project"
    />
  </ProjectTheme>
</div>
