
<div class="grid" :style="projectTheme">
  <ProjectTasksPageHeader
    :view-context="viewContext"
    :keyword.sync="keyword"
    :hidden-tabs="hiddenTabs"
    :hide-new-section-button="$route.name === PROJECT_TASKS_DOCUMENTS"
    @update:view-context="updateViewContext"
    @create-section="(...args) => view.createSection(...args)"
  />
  <router-view
    v-if="viewContext"
    ref="view"
    :organization="organization"
    :project="project"
    :view-context="viewContext"
    :keyword="keyword"
  />
</div>
