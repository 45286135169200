import { computed, ref, watch } from '@vue/composition-api';
import { isNil } from 'ramda';
import paymentPlansService from '../paymentPlansService'

export default provider => {
  const items = ref([])
  const isPending = ref(false)
  const error = ref(null)

  const load = async currentProvider => {
    isPending.value = true
    error.value = null
    items.value = []

    if (isNil(provider)) {
      return
    }

    try {
      items.value = await paymentPlansService.find(
        { query: { provider: currentProvider } }
      )
    } catch (err) {
      error.value = err
    } finally {
      isPending.value = false
    }
  }


  const globalDiscountPercentage = computed(
    () => {
      const discounts = new Set(
        items.value
          .filter(item => Boolean(item.discount?.discountPercentage))
          .map(item => item?.discount?.discountPercentage)
      );

      return discounts.size === 1 ? Array.from(discounts)[0] : null
    }
  )

  watch(provider, load, { immediate: true })

  return {
    items,
    isPending,
    globalDiscountPercentage,
    error,
  }
}
