
<ListMultiSelect
  v-slot="{ item: document }"
  :items="documents"
  :value="value"
  @input="val => $emit('input', val)"
>
  <p class="grow inline-flex items-center">
    {{ document.title }}
  </p>
  <div v-if="!hideCost" class="text-right mr-auto font-semibold">
    <DocumentCurrency :value="document.cost" :document-id="document._id" />
  </div>
</ListMultiSelect>
