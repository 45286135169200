<template>
  <div>
    <MainNavItemButton
      label="Feedback"
      icon="icon_v2-so_chat"
      icon-active="icon_v2-so_chat"
      :active="showModal"
      @click="showModal = true"
    />
    <UserFeedbackModal
      v-if="showModal"
      v-model="showModal"
      @sent="showModal = false"
    />
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import MainNavItemButton from '@/components/MainNavItemButton.vue'
import UserFeedbackModal from '@/components/UserFeedbackModal.vue'

export default defineComponent({
  name: 'MainNavItemFeedback',
  components: {
    MainNavItemButton,
    UserFeedbackModal,
  },
  setup() {
    const showModal = ref(false)

    return {
      showModal,
    }
  },
})
</script>
