import LayoutGuest from '@/views/LayoutGuest.vue'
import LayoutSignup from '@/views/LayoutSignup.vue'
import ViewGuestHome from '@/views/ViewGuestHome.vue'
import ViewGuestRecoverPasswordRequest from '@/views/ViewGuestRecoverPasswordRequest.vue'
import ViewGuestRecoverPasswordReset from '@/views/ViewGuestRecoverPasswordReset.vue'
import ViewGuestPrintDocument from '@/views/ViewGuestPrintDocument.vue'
import ViewSignUp from '@/views/ViewSignUp.vue'
import ViewRegisterSumoling from '@/views/ViewRegisterSumoling.vue'
import ViewGuestTemplateDocument from '@/views/ViewGuestTemplateDocument.vue'

import { mapParamsToProps, reqCtxNs } from '@/v2/lib/hooks/requestContextForServer'
import {
  GUEST_HOME,
  GUEST_SIGN_UP,
  GUEST_RECOVER_PASSWORD_REQUEST,
  GUEST_RECOVER_PASSWORD_RESET,
  GUEST_SHARED_DOCUMENT,
  GUEST_PRINT_DOCUMENT,
  GUEST_STYLE_GUIDE,
  GUEST_TEMPLATE_DOCUMENT,
  GUEST_REGISTER_SUMOLING,
  GUEST_EMBEDDED_DOCUMENT,
} from './type'


export default () => /** @type {(import('vue-router').RouteConfig)[]} */([
  // ROOT PAGES
  {
    path: '/',
    component: LayoutGuest,
    meta: {
      requiresGuest: true,
    },
    children: [
      {
        name: GUEST_HOME,
        path: '',
        component: ViewGuestHome,
        meta: {
          title: 'SuperOkay',
        },
        props: ({ query }) => ({ redirect: query.redirect, message: query.message }),
      },
      {
        name: GUEST_SIGN_UP,
        path: 'sign-up',
        component: ViewSignUp,
      },
      {
        name: GUEST_REGISTER_SUMOLING,
        path: `register-sumoling/:${reqCtxNs('token')}`,
        component: ViewRegisterSumoling,
        props: mapParamsToProps,
        meta: {
          requiresGuest: true,
          noRedirect: true,
        },
      },
      {
        name: GUEST_RECOVER_PASSWORD_REQUEST,
        path: 'recover-password',
        component: ViewGuestRecoverPasswordRequest,
      },
      {
        name: GUEST_RECOVER_PASSWORD_RESET,
        path: `recover-password/:${reqCtxNs('token')}`,
        component: ViewGuestRecoverPasswordReset,
        props: mapParamsToProps,
      },
    ],
  },

  // SIGNUP UP FORM
  {
    path: '/sign-up',
    component: LayoutSignup, // TODO: Is this still needed? Could we simply remove it?
  },

  // SHARED/PUBLIC DOCUMENT
  {
    path: `/share/:${reqCtxNs('token')}`,
    component: () => import('@/views/ViewPublicLink.vue'),
    props: mapParamsToProps,
    meta: {
      title: 'SuperOkay',
      intercomDisable: true,
      allowCustomSubdomain: true,
      noRedirect: true,
    },
    children: [
      {
        path: '',
        name: GUEST_SHARED_DOCUMENT,
        component: () => import('@/views/ViewPublicLinkDocument.vue'),
      },
      {
        name: GUEST_EMBEDDED_DOCUMENT,
        path: ':documentId',
        component: () => import('@/views/ViewPublicLinkDocument.vue'),
        props: true,
      },
    ],
  },
  {
    name: GUEST_PRINT_DOCUMENT,
    path: `/print/:documentId/:${reqCtxNs('token')}`,
    component: ViewGuestPrintDocument,
    props: mapParamsToProps,
    meta: {
      title: 'SuperOkay',
      intercomDisable: true,
      noRedirect: true,
    },
  },
  {
    name: GUEST_TEMPLATE_DOCUMENT,
    path: `/template/:${reqCtxNs('token')}`,
    component: ViewGuestTemplateDocument,
    props: mapParamsToProps,
    meta: {
      title: 'SuperOkay',
      intercomDisable: true,
      noRedirect: true,
    },
  },

  // MISC
  {
    name: GUEST_STYLE_GUIDE,
    path: '/internal-style-guide',
    meta: {
      intercomDisable: true,
      noRedirect: true,
    },
    component: () => import('@/views/ViewStyleGuide.vue'),
  },
])
