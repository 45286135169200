
<b-form-checkbox
  v-model="isDocumentPreview"
  v-b-tooltip.hover.bottom.v-info.dh0.ds200="`
    See how this document is displayed in the
    client portal in read-only mode.`
  "
  size="md"
  switch
  class="hidden pr-4 pl-36 md:flex"
  :class="isApproved && 'opacity-50'"
  :disabled="isApproved"
>
  <span
    class="text-14 font-semibold leading-none"
    :class="isDocumentPreview ? 'text-green-600' : 'text-gray-600'"
  >
    Preview
  </span>
</b-form-checkbox>
