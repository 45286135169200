<template>
  <b-modal
    :visible="value"
    centered
    hide-header
    hide-footer
    dialog-class="lg:max-w-modal"
    content-class="rounded-2xl"
    footer-class="px-42 pb-42"
    body-class="p-42"
    no-close-on-backdrop
    no-close-on-esc
    @change="val => $emit('input', val)"
  >
    <QuickStart
      @close="$emit('input', false)"
      @customize-project="$emit('customize-project')"
    />
  </b-modal>
</template>
<script>
import { defineAsyncComponent, defineComponent } from '@vue/composition-api';

const QuickStart = defineAsyncComponent(() => import('./QuickStart.vue'));

export default defineComponent({
  name: 'QuickStartModal',
  components: {
    QuickStart,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
