import { T } from 'ramda';
import { isFunction } from 'ramda-adjunct';
import { onUnmounted, onMounted, watch } from '@vue/composition-api';

const isMac = window.navigator.userAgent.includes('Mac')

export default function useKeydown({ keyMap = {}, enabled } = {}) {
  const listener = e => {
    const { callback, metaKey = false, condition = T } = keyMap[e.key] ?? {}
    const isMetaKeyPressed = isMac ? e.metaKey : e.ctrlKey

    if (!isFunction(callback) || isMetaKeyPressed !== metaKey || !condition()) {
      return;
    }

    e.preventDefault()
    e.stopPropagation()

    callback()
  }

  const removeListener = () => document.removeEventListener('keydown', listener)
  const addListener = () => document.addEventListener('keydown', listener)

  watch(enabled, isEnabled => {
    if (isEnabled) {
      addListener()
    } else {
      removeListener()
    }
  })

  onMounted(() => {
    if (enabled.value) {
      addListener()
    }
  })

  onUnmounted(() => {
    removeListener()
  })
}

