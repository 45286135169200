<template>
  <main :class="$style['layout-signup-wrap']">
    <!-- Header -->
    <header
      class="container flex flex-row justify-center mt-24 lg:mt-40 lg:justify-start"
    >
    </header>
    <section :class="$style['layout-signup']" class="container">
      <!-- Content -->

      <CardWhiteRounded class="w-full max-w-prose md:px-64 md:py-64">
        <slot v-if="hasDefaultSlot" />
        <router-view v-else />
      </CardWhiteRounded>
    </section>
  </main>
</template>

<script>
import { computed } from '@vue/composition-api'
import CardWhiteRounded from '@/components/CardWhiteRounded.vue'

export default {
  name: 'LayoutSignup',
  components: {
    CardWhiteRounded,
  },
  metaInfo: {
    title: 'SuperOkay',
  },
  setup(_, { slots }) {
    const hasDefaultSlot = computed(() => slots.default)

    return {
      hasDefaultSlot,
    }
  },
}
</script>

<style lang="postcss" module>
.layout-signup-wrap {
  @apply bg-green w-screen min-h-screen overflow-hidden;
  background-image: url('/assets/img/pattern.svg');
}

:global(.has-banner) .layout-signup {
  min-height: calc(100vh - 116px);
}

.layout-signup {
  @apply w-screen min-h-screen relative z-1 flex flex-col justify-center items-center;
}
</style>
