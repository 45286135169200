
<div>
  <template v-if="!isPending">
    <!-- Header -->
    <slot
      name="header"
      v-bind="{
        hasNotifications,
        hasUnreadNotifications: Boolean(unreadNotifications.length),
        isPendingMarkAllAsRead, markAllAsRead
      }"
    />

    <!-- Content -->
    <section
      v-if="hasNotifications"
      class="space-y-16"
    >
      <!-- UNREAD -->
      <section v-if="unreadNotifications.length" class="space-y-8">
        <NotificationList
          item-class="
            hover:bg-yellow-10 group rounded-lg transition-all
            dark:bg-opacity-10
          "
          :notifications="unreadNotifications"
          :show-project="showProject"
          :show-document="showDocument"
          show-close
          show-bullet
          show-mark-as-read
          @mark-as-read="markAsRead"
        />
        <div>
          <Button
            v-if="unreadHasMore"
            size="md"
            :is-loading="unreadPending"
            variant="primary"
            @click="unreadLoadMore"
          >
            Load more notifications
          </Button>
        </div>
      </section>

      <!-- READ -->
      <section
        v-show="readNotifications.length"
        class="space-y-8"
      >
        <span class="uppercase text-12 font-semibold text-gray-600 inline-block">previous</span>
        <NotificationList
          item-class="
            rounded-lg transition-all
            hover:bg-gray-400 hover:bg-opacity-20
            dark:hover:bg-darkGray-700
            -ml-8 -mr-8 p-8
          "
          :notifications="readNotifications"
          :slim="slim"
          :show-project="showProject"
          :show-document="showDocument"
          :show-close="false"
          :show-bullet="false"
        />
        <div>
          <Button
            v-if="readHasMore"
            size="md"
            :is-loading="readPending"
            variant="neutral"
            @click="readLoadMore"
          >
            Load older notifications
          </Button>
        </div>
      </section>
    </section>

    <!-- No Notifications -->
    <MessageBox v-else icon="icon_v2-so_info">
      No activity to display yet.
    </MessageBox>
  </template>
  <div v-else class="flex items-center justify-center w-full h-96">
    <Spinner
      :size="48"
      variant="dark"
      class="ml-8"
    />
  </div>
</div>
