
<DocumentGet
  :id="documentId"
  v-slot="{ document, isGetPending: isDocumentPending }"
  :params="{ embed: true }"
  force
>
  <template v-if="!isDocumentPending">
    <OrganizationGet
      v-if="document"
      :id="document.organization"
      v-slot="{ organization, isGetPending: isOrganizationPending }"
    >
      <ProjectGet
        :id="document.project"
        v-slot="{
          project: projectFromDocument,
          isGetPending: isProjectGetPending,
        }"
      >
        <DocumentTheme
          :organization="organization"
          :project="projectFromDocument"
          :document="document"
        >
          <DocumentViewerClientPortal
            v-if="!isOrganizationPending && !isProjectGetPending"
            :document="document"
            :organization="organization"
            :is-client-portal="false"
            :project="projectFromDocument"
            print
          />
        </DocumentTheme>
      </ProjectGet>
    </OrganizationGet>
    <LayoutGuest v-else :banner="false">
      <b-alert show variant="warning">
        <h4 class="alert-heading">Document not found</h4>
        <p>Invalid sharing link or link no longer available.</p>
      </b-alert>
    </LayoutGuest>
  </template>
</DocumentGet>
