<template>
  <span
    class="
      inline-flex items-center justify-center
      flex-none font-semibold leading-none
      py-4 text-11 rounded-md
      whitespace-nowrap
    "
  >
    <span
      class="inline-block w-16 h-16 rounded-100 mr-6 flex-none"
      :style="{ backgroundColor: backgroundColor }"
    ></span>
    <span>{{ label }}</span>
  </span>
</template>

<script>

export default {
  name: 'ProjectBadge',
  props: {
    backgroundColor: {
      type: String,
      default: '#fff',
    },
    label: {
      type: String,
      default: null,
    },
  },
}
</script>
