<template>
  <PageHeader
    title="Projects"
    :search="!$isMobile"
    :search-keyword="keyword"
    @update:search-keyword="val => $emit('update:keyword', val)"
  >
    <template #actions>
      <ProjectViewOptions
        v-if="!$isMobile"
        :value="viewContext"
        @input="val => $emit('update-view-context', val)"
      />
      <CheckMemberRole v-slot="{ hasRole }" :roles="['admin', 'projectManager']">
        <ButtonNewProject
          v-if="hasRole"
          view-as="button"
          variant="neutral"
          size="md"
          icon-class="mr-4 -ml-4 icon_v2-so_plus text-green-600"
        />
      </CheckMemberRole>
    </template>
    <template #filter>
      <OrganizationProjectsPageHeaderTags
        :tags="tags"
        :value="selectedTags"
        :selected-tag-count="selectedTagCount"
        @input="val => $emit('update:selected-tags', val)"
      />
    </template>
  </PageHeader>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ProjectViewOptions from '@/components/ProjectViewOptions.vue'
import ButtonNewProject from '@/components/ButtonNewProject.vue'
import CheckMemberRole from '@/components/CheckMemberRole.vue'
import PageHeader from '@/components/PageHeader.vue'
import OrganizationProjectsPageHeaderTags from '@/components/OrganizationProjectsPageHeaderTags.vue'

export default defineComponent({
  name: 'OrganizationProjectsPageHeader',
  components: {
    ProjectViewOptions,
    ButtonNewProject,
    CheckMemberRole,
    PageHeader,
    OrganizationProjectsPageHeaderTags,
  },
  props: {
    viewContext: {
      type: Object,
      required: true,
    },
    keyword: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    selectedTags: {
      type: Object,
      required: true,
    },
    selectedTagCount: {
      type: Number,
      default: null,
    },
  },
})
</script>
