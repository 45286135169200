<template>
  <div :class="$style['icon-grid']">
    <div
      v-for="(row, rowIndex) in iconMatrix"
      :key="rowIndex"
      :class="$style['row']"
    >
      <div
        v-for="({ opacity }, colIndex) in row"
        :key="colIndex"
        :class="$style['icon']"
        :style="{ opacity }"
      >
        <slot else />
      </div>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import { computed } from '@vue/composition-api'
// import IconGraphic from '@/components/Blocks/Graphics/IconGraphic.vue'

const rand = () => Math.max(0.3, Math.random())
const makeIconMatrix = (icon = null, size) => R.pipe(
  size => R.repeat(icon, size ** 2),
  R.map(R.pipe(R.objOf('icon'), R.over(R.lensProp('opacity'), rand))),
  R.splitEvery(size)
)(size)

export default {
  name: 'IconGrid',
  components: {
    // IconGraphic,
  },
  props: {
    icon: {
      type: String,
    },
    size: {
      type: Number,
      default: 3,
    },
  },

  setup(props) {
    const iconMatrix = computed(() => makeIconMatrix(props.icon, props.size))
    return {
      iconMatrix,
    }
  },
  // computed: {
  //   iconMatrix() {
  //     return makeIconMatrix(this.icon, this.size)
  //   },
  // },
}
</script>

<style lang="scss" module>
.icon-grid {
  margin-bottom: 32px;
  .icon {
    display: inline-block;
    padding: 16px;
    // margin-left: 40px;
    // margin-bottom: 40px;
    // width: 64px;
    // height: 64px;
  }
}
</style>
