
<div>
  <ButtonFilter
    ref="buttonRef"
    :active="isPopoverVisible"
    :count="selectedTagCount"
  />
  <b-popover
    v-if="buttonRef"
    :target="buttonRef"
    triggers="click blur"
    placement="bottom"
    custom-class="w-320 shadow-2xl border-gray-300 rounded-lg"
    boundary="window"
  >
    <ProjectSwitcherTags
      :tags="tags"
      :value="value"
      @input="val => $emit('input', val)"
    />
  </b-popover>
</div>
