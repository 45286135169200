
<div :style="cssVars" class="absolute top-0 left-0 w-[200vw] lg:w-screen overflow-hidden h-240">
  <span
    class="absolute bottom-0 left-0 w-full h-full"
    :style="`background: linear-gradient(0deg, #ffffff 0%, var(--gr-transparent) 90%), ` +
      `radial-gradient(at center top, var(${colorScheme[0]}) 15%, var(--gr-transparent) 60%) 0 -110px, `+
      `radial-gradient(at center top, var(${colorScheme[1]}) 10%, var(--gr-transparent) 70%) 0 0, ` +
      `radial-gradient(at left top, var(${colorScheme[2]}) 10%, var(--gr-transparent) 60%) 0 0, ` +
      `radial-gradient(at center top, var(${colorScheme[3]}) 10%, var(--gr-transparent) 60%) 420px -80px,`+
      `radial-gradient(at right top, var(${colorScheme[4]}) 40%, var(--gr-transparent) 70%) 620px 0;` +
      `background-repeat: no-repeat`
    "
  ></span>
</div>
