
<div v-fragment>
  <ProjectTemplatePreviewModal
    v-if="isPreviewModalOpen"
    v-model="isPreviewModalOpen"
    :template="template"
    @use="onTemplateSelect"
    @hidden="onTemplateModalClosed"
  />
  <div class="container md:py-16">
    <FormMessage v-if="error">{{ error }}</FormMessage>
    <ProjectTemplates
      no-scroll
      @preview="onTemplatePreview"
      @create="onTemplateSelect"
    />
  </div>
</div>
