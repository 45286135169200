
<ModalBasic
  v-bind="$attrs"
  :dialog-class="$style['sidebar-modal-dialog']"
  :header-class="$style['sidebar-modal-header']"
  :content-class="$style['sidebar-modal-content']"
  :body-class="$style['sidebar-modal-body']"
  v-on="$listeners"
>
  <template #body="bindings">
    <div :class="$style['sidebar-modal-layout']">
      <div v-if="!hideSidebar" :class="$style['sidebar-modal-aside']">
        <slot name="sidebar" v-bind="bindings"></slot>
      </div>
      <div :class="$style['sidebar-modal-inner-body']">
        <slot name="body" v-bind="bindings"></slot>
      </div>
    </div>
  </template>
</ModalBasic>
