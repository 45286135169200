<template>
  <div>
    <div
      class="flex flex-wrap"
    >
      <ColorPaletteItem
        v-for="(color, index) in value"
        :key="color.id"
        :no-fade="noFade"
        :popover-placement="popoverPlacement"
        class="mr-8 included list-item mb-8 list-none"
        :value="color.color"
        :is-new="index === newColorIndex"
        :size="size"
        @input="col => handleEditColor(col, index)"
        @remove-color="handleRemoveColor(index)"
      />

      <button
        v-b-tooltip.hover.bottom.v-info.dh0.ds200
        type="button"
        title="Add new color"
        :class="[
          sizeMap[size].buttonSize,
          'rounded-full relative inline-flex items-center',
          'justify-center ring-2 ring-gray-200 ring-inset bg-white text-gray-700',
          'hover:ring-gray-300 transition-all outline-none focus:outline-none flex-none'
        ]"
        @click="handleAddNewColor"
      >
        <span :class="['icon_v2-so_plus',sizeMap[size].iconSize]" />
      </button>
    </div>
  </div>
</template>

<script>
import { append, remove, adjust, assoc } from 'ramda';
import { nanoid } from 'nanoid'
import { ref } from '@vue/composition-api'
import ColorPaletteItem from '@/components/ColorPaletteItem.vue'

// const colorCount = new Map()

export default {
  name: 'ColorPalette',
  components: {
    ColorPaletteItem,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    presets: {
      type: Array,
      default: () => [],
    },
    noFade: {
      type: Boolean,
      default: false,
    },
    popoverPlacement: {
      type: String,
      default: 'top',
    },
    size: {
      type: Number,
      default: 36,
    },
  },
  setup(props, context) {
    const sizeMap = {
      56: {
        buttonSize: 'w-56 h-56',
        iconSize: 'text-24 w-24',
      },
      42: {
        buttonSize: 'w-42 h-42',
        iconSize: 'text-24 w-24',
      },
      36: {
        buttonSize: 'w-36 h-36',
        iconSize: 'text-24 w-24',
      },
      32: {
        buttonSize: 'w-32 h-32',
        iconSize: 'text-16 w-16',
      },
      24: {
        buttonSize: 'w-24 h-24',
        iconSize: 'text-12 w-12',
      },
    }

    const newColorIndex = ref(null)

    const handleAddNewColor = () => {
      newColorIndex.value = props.value.length;
      context.emit('input', append({ color: '#CCCCCCFF', id: nanoid() }, props.value))
    }
    const handleRemoveColor = index => {
      newColorIndex.value = null
      context.emit('input', remove(index, 1, props.value))
    }

    const handleEditColor = (newVal, index) => {
      context.emit('input', adjust(index, assoc('color', newVal), props.value))
    }


    return {
      handleAddNewColor,
      handleRemoveColor,
      handleEditColor,
      newColorIndex,
      sizeMap,

    }
  },

}
</script>
<style scoped>
.list-item {
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 0.23s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
