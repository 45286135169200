
<FeatureEnabled
  v-slot="{ checkFeatureEnabled }"
  flag="customDomains"
  @allow="formSubmit"
>
  <div>
    <!-- STEP 1 -->
    <Step
      :step="1"
      header="Enter your desired custom domain"
    >
      <b-form @submit.prevent="checkFeatureEnabled">
        <FormMessage v-if="formError">
          {{ formError.message }}
        </FormMessage>
        <div class="flex mb-6">
          <FormField
            v-slot="{ state }"
            wrapper-class="mr-10"
            :v="$v.domain"
            :messages="{
              required: 'Domain is required',
              domain: 'Invalid domain format',
              available: 'Domain is not available',
            }"
          >
            <b-input
              v-model="domain"
              placeholder="portal.yourdomain.com"
              :state="!domain ? null : state"
              :disabled="formIsBusy || status === STATUS.setupPending || !isSubdomainSet"
              size="lg"
              class="max-w-510 min-w-[330px]"
            />
          </FormField>
          <Button
            v-if="status !== STATUS.setupPending"
            :disabled="!isSubdomainSet"
            :is-loading="formIsBusy"
            size="md"
            variant="neutral"
            type="submit"
          >
            Save
          </Button>
        </div>
      </b-form>
      <p class="text-gray-500 text-13">
        Example: portal.<span class="italic">yourdomain.com</span>,
        clients.<span class="italic">yourdomain.com</span>
      </p>
      <p v-if="!isSubdomainSet" class="text-gray-500 text-13 font-semibold mt-8">
        <span class="text-16 text-yellow-500 mr-2">⚠️</span>
        Please pick your .superokay.com subdomain first
      </p>
    </Step>

    <!-- STEP 2 -->
    <Step
      :step="2"
      header="Configure your domain DNS and add these settings"
    >
      <!-- table -->
      <div class="flex justify-between leading-relaxed mb-18 text-14">
        <div class="flex flex-col">
          <span>Hostname</span>
          <span v-if="!organization.domain" class="text-orange-600">
            ⚠️ Set your domain
          </span>
          <span v-else class="text-gray-500">{{ hostname }}</span>
        </div>
        <div class="flex flex-col">
          <span>Type</span>
          <span class="text-gray-500">CNAME</span>
        </div>
        <div class="flex flex-col">
          <span>Value</span>
          <span v-if="!organization.subdomain" class="text-orange-600">
            ⚠️ Set your subdomain
          </span>
          <span v-else class="text-gray-500">
            {{ organization.subdomain }}.superokay.com
          </span>
        </div>
      </div>
      <a
        class="text-green-700 text-14 group"
        href="https://help.superokay.com/en/articles/5797674-how-to-connect-your-custom-domain"
        target="_blank"
      >
        <span class="mr-4 text-12">💡</span>
        <span class="underline group-hover:no-underline">
          How to configure your CNAME settings
        </span>
      </a>
    </Step>

    <!-- STEP 3 -->
    <Step
      :step="3"
      header="Validate the configuration"
      class-name="border-none"
    >
      <p class="text-gray-500 text-14 mb-20">
        <strong>After you've updated your DNS settings</strong>,
        please click the "Validate DNS Changes" button below.
      </p>
      <Button
        :disabled="status !== STATUS.awaitingCNAMEConfirmation"
        :is-loading="confirmCNAMELoading"
        size="md"
        variant="primary-dark"
        type="button"
        @click="confirmCNAME"
      >
        <span v-if="status === STATUS.setupError">Revalidate DNS Changes</span>
        <span v-else-if="status === STATUS.setupPending">Setup In Progress...</span>
        <span v-else>Validate DNS Changes</span>
      </Button>
      <p class="text-gray-500 text-12 max-w-[480px] mt-20">
        Sometimes DNS changes can take up to 24hrs to show up.<br /> We'll keep
        checking once you start the validation process.
      </p>
    </Step>
  </div>
</FeatureEnabled>
