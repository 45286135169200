<template>
  <router-view
    v-if="isCodeChecked"
    :user="user"
    :profile="profile"
    :member="member"
    :organization="organization"
    :token="token"
  />
</template>

<script>
import { pick, prop, applySpec, always } from 'ramda'
import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import {
  LANDING_INVITE,
  LANDING_INVITE_USER,
  LANDING_INVITE_GUEST,
} from '@/router/landing/type'
import EventBus from '@/event-bus/event-bus'
import { GUEST_HOME } from '@/router/guest/type'
import { tokens, members } from '@/v2/services'
import { useUser } from '@/v2/services/users/usersCompositions'
import useValidators from '@/v2/services/validators/validatorsCompositions'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { isCodeValid } from '@/v2/lib/helpers/validators'

const stateSpec = applySpec({
  isCodeChecked: always(true),
  member: pick(['_id', 'status', 'token']),
  organization: prop('organization$'),
  profile: pick(['firstName', 'lastName']),
  user: pick(['email']),
})

export default defineComponent({
  metaInfo: {
    titleTemplate: 'Join Organization - %s',
  },
  name: 'ViewLandingInvite',
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { user, isAuthenticated } = useUser()
    const { router } = useRouter()
    const { emailAvailable } = useValidators()

    const state = reactive({
      isCodeChecked: false,
      user: null,
      profile: null,
      organization: null,
      member: null,
    })

    const redirectHome = error => {
      EventBus.$emit('messageError', { error })
      router.push({ name: GUEST_HOME })
    }

    const redirectToLogin = message => {
      const redirect = router.resolve({
        name: LANDING_INVITE,
        params: { token: props.token },
      }).href

      router.push({
        name: GUEST_HOME,
        query: { redirect, message },
      })
    }

    const redirectGuest = async () => {
      try {
        if (await emailAvailable(state.user.email)) {
          router.push({ name: LANDING_INVITE_GUEST })
        } else {
          redirectToLogin('To accept the invitation, please login')
        }
      } catch (error) {
        console.error(error)
        redirectHome(error)
      }
    }

    const redirectUser = () => {
      if (state.user.email !== user.value.email) {
        redirectHome(new Error('This invitation is not valid for the current user.'))
      } else {
        router.push({ name: LANDING_INVITE_USER })
      }
    }

    const redirect = () => {
      // if this is a landing sub-page, do nothing
      if (router.currentRoute.name !== LANDING_INVITE) {
        return
      }

      if (isAuthenticated.value) {
        redirectUser()
      } else {
        redirectGuest()
      }
    }

    const fetchData = async () => {
      try {
        if (!isCodeValid(props.token)) {
          throw new Error('Invalid invitation code')
        }

        const token = await tokens.get(props.token)
        const member = await members.get(token.member, { token: props.token });

        Object.assign(state, stateSpec(member));
        redirect()
      } catch (error) {
        console.error('Error on fetching invite data:', error);
        redirectHome(error)
      } finally {
        state.isCodeChecked = true
      }
    }

    fetchData()

    return {
      ...toRefs(state),
    }
  },
})
</script>
