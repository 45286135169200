
<DocumentNodeEditorToolbarPopoverLight
  :width="280"
  :target-id="componentID"
  triggers="click blur"
  tooltip="Select color"
  slim
>
  <template #button-content>
    <button
      :class="[
        $style.button,
        cssClassButton,
        'h-30 w-30 rounded-full relative inline-block',
      ]"
      :style="{ backgroundColor: value }"
    >
      <span class="absolute inset-0 opacity-20 z-1" />
      <span :class="[$style['toolbar-dropdown']]" />
    </button>
  </template>

  <template #default>
    <section class="flex flex-col pb-16">
      <!-- <div class="mt-16 mx-16">
        <b-link
          class="underline hover:no-underline text-gray-700 hover:text-gray-900"
          @click.stop="$emit('input','')"
        >
          Reset
        </b-link>
      </div> -->
      <ColorPicker
        :value="value"
        @input="v => pickColor(v)"
      />
      <ColorPickerPresets
        :selected-value="value"
        :presets="presets"
        @pick-color-from-presets="v => pickColor(v)"
      />
    </section>
  </template>
</DocumentNodeEditorToolbarPopoverLight>
