<template>
  <section
    class="px-24 lg:px-0
          grid grid-cols-1 pt-24 pb-40
          md:grid-cols-6 md:pt-64
          md:gap-32 lg:gap-64"
  >
    <!-- FORM -->
    <section
      class="flex flex-col items-center
            col-span-1 md:col-span-3 md:justify-center"
    >
      <CardWhiteRounded class="w-full max-w-form p-0 md:p-32 lg:p-48">
        <b-alert
          v-if="message"
          show
          variant="warning"
        >
          {{ message }}
        </b-alert>
        <!-- @done="onLogin" -->
        <SignInForm
          @recover-password="
            $router.push({ name: GUEST_RECOVER_PASSWORD_REQUEST })
          "
        />

        <div class="mt-40 space-x-8 text-center text-14">
          <span class="text-gray-600">New to SuperOkay?</span>
          <b-link
            class="inline-flex items-center font-semibold text-green-600 hover:text-green-800"
            :to="{ name: GUEST_SIGN_UP }"
            @click="$tracking.trackGuest('Create Account Clicked')"
          >
            Create an account
            <span class="w-16 ml-6 icon-arrow-right-util text-16"></span>
          </b-link>
        </div>
      </CardWhiteRounded>
    </section>

    <!-- Whats New -->
    <section
      :class="[
        'col-span-1 md:col-span-3 mt-24 md:mt-0',
        'md:px-0',
        'flex flex-col justify-center items-center',
      ]"
    >
      <transition
        name="slide-fade"
        appear
      >
        <section
          :class="[
            'bg-darkGray-900',
            'rounded-xl space-y-16 ring-1 ring-gray-900 ring-opacity-10',
            'rounded-xl overflow-hidden',
            'max-w-form shadow-2xl min-h-full',
          ]"
        >
          <WhatsNewPreview />
        </section>
      </transition>
    </section>
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  GUEST_SIGN_UP,
  GUEST_RECOVER_PASSWORD_REQUEST,
} from '@/router/guest/type'
import SignInForm from '@/components/SignInForm.vue'
import WhatsNewPreview from '@/components/WhatsNewPreview.vue'
import CardWhiteRounded from '@/components/CardWhiteRounded.vue'

export default defineComponent({
  name: 'ViewGuestHome',
  metaInfo: {
    titleTemplate: 'Log in to SuperOkay - %s',
  },
  components: {
    SignInForm,
    WhatsNewPreview,
    CardWhiteRounded,
  },
  props: {
    redirect: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isModalOpen: false,
    }
  },
  staticData: {
    GUEST_SIGN_UP,
    GUEST_RECOVER_PASSWORD_REQUEST,
  },
})
</script>

<style scoped>
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .7s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(400px) rotate(19deg);
  opacity: 0;
}
</style>
