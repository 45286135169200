
<transition name="toolbar" appear>
  <div
    class="absolute flex justify-center w-full h-px pb-4 block-toolbar bottom-full print:hidden"
    :class="[commentsSidebarStatus ? 'z-1 xl:z-50' : 'z-50' ]"
    data-intercom-target="Block Toolbar"
    @click.stop
  >
    <div
      class="flex px-8 pt-2 pb-6 -mt-64 text-white bg-gray-800 rounded-md h-60 editor-toolbar"
    >
      <slot />
    </div>
  </div>
</transition>
