
<ModalBasic
  title="Create New Client"
  :should-close-on-backdrop="false"
  :hide-footer="true"
  content-class="modal-content-tertiary"
  body-class="modal-body-tertiary"
  header-class="modal-header-tertiary"
  footer-class="modal-footer-tertiary"
  size="lg"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #body="{ close }">
    <div>
      <ClientEditor
        ref="clientEditorRef"
        :organization="organization"
        client-id="new"
        :standalone="false"
        @saved="newClient => onSaved(newClient, close)"
      />
      <Buttons>
        <Button
          type="button"
          variant="neutral"
          size="md"
          @click="close"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          size="md"
          @click="clientEditorRef.create()"
        >
          Create Client
        </Button>
      </Buttons>
    </div>
  </template>
</ModalBasic>
