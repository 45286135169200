<template>
  <CardSettings :step="step">
    <CardSettingsHeader
      title="Docs &amp; files permissions"
      info="Role based permissions for project files"
    />
    <div
      v-for="(perms, role) in value"
      :key="role"
    >
      <h3 class="text-gray-400 mb-4 font-semibold capitalize">
        {{ role }}
      </h3>
      <p v-if="roleInfo[role]" class="text-13 text-gray-400 mt-2">{{ roleInfo[role] }}</p>
      <div class="space-y-8 mt-16">
        <div
          v-for="(isEnabled, permission) in perms"
          :key="permission"
          class="-mx-16 px-16 py-4 space-y-4 hover:bg-gray-100 rounded-md"
        >
          <b-form-checkbox
            switch
            class="flex items-center text-14 self-start checkbox capitalize"
            :checked="isEnabled"
            @input="val => update(role, permission, val)"
          >
            {{ permission }}
          </b-form-checkbox>
          <div class="text-12 text-gray-400">{{ permissionInfo[permission] }}</div>
        </div>
        <div
          class="-mx-16 px-16 py-4 space-y-4 hover:bg-gray-100 rounded-md"
        >
          <b-form-checkbox
            switch
            class="flex items-center text-14 self-start checkbox capitalize"
            :checked="value[role].create && value[role].remove"
            disabled
          >
            Move
          </b-form-checkbox>
          <div class="text-12 text-gray-400">
            Move files and folders. This cannot be toggled manually -
            it's enabled when both <strong>create</strong> and <strong>remove</strong>
            permissions are active.
          </div>
        </div>
      </div>
    </div>
  </CardSettings>
</template>
<script>
import { assocPath } from 'ramda'
import { defineComponent } from '@vue/composition-api'
import CardSettings from '@/components/CardSettings.vue'
import CardSettingsHeader from '@/components/CardSettingsHeader.vue'
import { ROLES } from '@/v2/services/members/membersTypes'
import { PERMISSIONS } from '@/v2/services/projects/projectsTypes'

const roleInfo = {
  [ROLES.client]: 'Controls client contacts\' ability to create, delete, and edit files and documents in the client portal.',
}

const permissionInfo = {
  [PERMISSIONS.create]: 'Upload files, create folders and file links',
  [PERMISSIONS.update]: 'Reorder files and edit document titles',
  [PERMISSIONS.remove]: 'Delete folders, files and documents',
}

export default defineComponent({
  name: 'ProjectSettingsAccessPermissions',
  components: {
    CardSettings,
    CardSettingsHeader,
  },
  props: {
    step: {
      type: Number,
      default: null,
    },
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const update = (role, permission, value) => {
      context.emit('input', assocPath([role, permission], value, props.value))
    }

    return {
      update,
      roleInfo,
      permissionInfo,
    }
  },
})
</script>
<style lang="postcss" scoped>
.checkbox::v-deep {
  label {
    padding-top: 1px;
  }

  &.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    @apply bg-green-600 opacity-50;
  }
}
</style>
