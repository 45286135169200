
<div class="group-placeholder" @contextmenu.stop.prevent="onContextMenuOpen">
  <span class="block-placeholder">

  </span>
  <span class="block-placeholder">
  </span>
  <span class="block-placeholder">

  </span>
  <ContextMenu
    v-if="contextMenu.nodeId === placeholderNodeId"
    label="New Column Group"
    :left="contextMenu.left"
    :top="contextMenu.top"
    @close="onContextMenuClose"
  >
    <b-dropdown-item
      :disabled="isClipboardEmpty"
      @click="pasteNodes({targetBranch:nodeId })"
    >
      <span class="cm-icon icon-util-paste-before inline-block mr-8"></span>
      <span>Paste</span>
    </b-dropdown-item>
  </ContextMenu>
</div>
