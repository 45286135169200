<template>
  <div>
    <h1 class="text-center mt-16 text-gray-600">Select a variation</h1>
    <div class="flex max-w-prose mx-auto gap-10 justify-center pt-14 pb-24">
      <ColumnsPlaceholder
        :ratio="[50,50]"
        @add-columns="data => $emit('add-columns', data)"
      />
      <ColumnsPlaceholder
        :ratio="[30,70]"
        @add-columns="data => $emit('add-columns', data)"
      />
      <ColumnsPlaceholder
        :ratio="[70,30]"
        @add-columns="data => $emit('add-columns', data)"
      />
      <ColumnsPlaceholder
        :ratio="[33,33,34]"
        @add-columns="data => $emit('add-columns', data)"
      />
    </div>
  </div>
</template>

<script>
import ColumnsPlaceholder from '@/components/ColumnsPlaceholder.vue'

export default {
  name: 'DocumentNodeGroupColumnsContainerPlaceholder',
  components: {
    ColumnsPlaceholder,
  },


}
</script>
