
<DocActions
  v-slot="{
    update,
    remove,
    duplicate,
    share,
    saveAsReusableBlock,
    download,
    changeFonts,
  }"
  :document="document"
>
  <DocRenderer :document="document">
    <template #breadcrumbs>
      <DocLibraryBreadcrumbs :document="document" :category="category" />
    </template>
    <template #actions>
      <DocMenu
        :document="document"
        :active-items="{
          remove: true,
          duplicate: true,
          changeFonts: true,
        }"
        @remove="remove"
        @duplicate="duplicate"
        @change-fonts="changeFonts"
      />
    </template>
    <template #header>
      <DocTitle
        hide-icon
        :document="document"
        @update="title => update({ title })"
      />
    </template>

    <template #content>
      <DocumentEditorContent
        v-if="document"
        :document="document"
      />
      <DocApprovalBadge :document="document" />
    </template>
  </DocRenderer>
</DocActions>
