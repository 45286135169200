<template>
  <LayoutSignup>
    <img
      v-if="organization.logoUrl"
      :src="organization.logoUrl"
      :class="$style['agency-logo']"
      class="object-contain object-center"
    />
    <SignUpFormHeader>
      {{ organization.name }} invited your to join their team on
      SuperOkay
    </SignUpFormHeader>
    <FormMessage v-if="error">
      {{ error }}
    </FormMessage>
    <SignUpFormProfile
      button-label="Sign Up"
      :user="user"
      :profile="profile"
      :is-loading="isLoading"
      :is-email-locked="true"
      @submit="onSubmit"
    />
  </LayoutSignup>
</template>

<script>
import { pick } from 'ramda'
import { ref } from '@vue/composition-api'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useUser } from '@/v2/services/users/usersCompositions'
import { useAccountsRegisterInvitedMember } from '@/v2/services/accounts/accountsCompositions'
import LayoutSignup from '@/views/LayoutSignup.vue'
import SignUpFormHeader from '@/components/SignUpFormHeader.vue'
import SignUpFormProfile from '@/components/SignUpFormProfile.vue'
import FormMessage from '@/components/FormMessage.vue'

const credentials = pick(['email', 'password'])

export default {
  name: 'ViewLandingInviteGuest',
  components: {
    SignUpFormHeader,
    SignUpFormProfile,
    LayoutSignup,
    FormMessage,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    profile: {
      type: Object,
      default: () => ({}),
    },
    organization: {
      type: Object,
      default: () => ({}),
    },
    member: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const accountRegister = useAccountsRegisterInvitedMember()
    const { authenticateLocal } = useUser()
    const { routerPush } = useRouter()

    const error = ref(null)
    const isLoading = ref(false)

    const onSubmit = async formData => {
      if (isLoading.value) return
      isLoading.value = true

      try {
        await accountRegister({
          user: pick(['password'], formData.user),
          profile: formData.profile,
        });
        await routerPush({ query: { rc: false } });
        await authenticateLocal(credentials(formData.user));
      } catch (err) {
        error.value = err.message
        isLoading.value = false
      }
    }

    return {
      onSubmit,
      error,
      isLoading,
    }
  },
}
</script>

<style style="scss" module>
.agency-logo {
  max-height: 48px;
  margin: 0 auto 60px auto;
}
</style>
