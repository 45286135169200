<template>
  <div>
    <PageHeader title="Project Portal Branding" />
    <Form
      v-if="state"
      v-slot="{ isBusy, submit, hasChanges }"
      :state="state"
      success-message="Project changes saved"
      :on-submit="() => save()"
    >
      <section class="pb-16 border-b-2 border-gray-100 dark:border-darkGray-500">
        <!-- HEADER -->
        <div class="mb-16">
          <p class="settings-section-description">
            Configure how the project portal looks to you and your client
          </p>
        </div>
        <div class="gap-32 md:grid-cols-2 md:-mr-48 md:grid md:auto-cols-fr">
          <section>
            <!-- Colors & Fonts -->
            <h3 class="settings-section-title">
              Theme
            </h3>
            <FormField label="Light / Dark Mode">
              <div class="flex gap-16 max-w-384">
                <!-- Light Mode -->
                <div
                  class="flex-1 appearance-option md:w-160"
                  :class="[state.portalTheme === 'light' && 'appearance-active']"
                  @click="state.portalTheme = 'light'"
                >
                  <div class="aspect-w-16 aspect-h-9 bg-preview-light"></div>
                  <div class="appearance-option-footer">
                    <span class="icon-day text-16"></span>
                    <span>Light</span>
                  </div>
                </div>
                <!-- Dark Mode -->
                <div
                  class="flex-1 appearance-option md:w-160"
                  :class="[state.portalTheme === 'dark' && 'appearance-active']"
                  @click="state.portalTheme = 'dark'"
                >
                  <div class="aspect-w-16 aspect-h-9 bg-preview-dark">
                  </div>
                  <div class="appearance-option-footer">
                    <span class="icon-night text-16"></span>
                    <span>Dark</span>
                  </div>
                </div>
              </div>
            </FormField>
            <section class="mt-32">
              <!-- Primary Color & Heading Font -->
              <div class="flex flex-row gap-24">
                <!-- Main color -->
                <FormField
                  label="Main Color"
                  info-label="The main project portal accent color"
                >
                  <ColorPaletteItem
                    v-model="state.portalAccentColor"
                    :presets="colorPresets"
                    variant="pick"
                    popover-placement="buttom"
                    class="list-none included list-item"
                    :size="42"
                  />
                </FormField>
                <!-- Link color -->
                <FormField
                  label="Secondary Color"
                  info-label="Used for the default unshared doc &amp; folder color"
                >
                  <ColorPaletteItem
                    v-model="state.portalSecondaryColor"
                    :presets="colorPresets"
                    variant="pick"
                    popover-placement="buttom"
                    class="list-none included list-item"
                    :size="42"
                  />
                </FormField>
                <!-- Link color -->
                <FormField
                  label="Quick Link Color"
                  info-label="Used for greeting and quick link color"
                >
                  <ColorPaletteItem
                    v-model="state.portalLinkColor"
                    :presets="colorPresets"
                    variant="pick"
                    popover-placement="buttom"
                    class="list-none included list-item"
                    :size="42"
                  />
                </FormField>
              </div>
            </section>
            <!-- Portal Dashboard -->
            <b-form-group class="mt-32">
              <h3 class="settings-section-title">
                Project Portal Dashboard Header
              </h3>
              <!-- Header background -->
              <div class="flex flex-row gap-24 mb-16">
                <!-- ----- -->
                <!-- THEME -->
                <!-- ----- -->
                <FormField label="Header Light / Dark Mode" wrapper-class="grow">
                  <div class="flex gap-16">
                    <div
                      class="w-1/2 appearance-option"
                      :class="[state.portalHeaderTheme === 'light' && 'appearance-active']"
                      @click="state.portalHeaderTheme = 'light'"
                    >
                      <div class="h-40 aspect-w-16 aspect-h-6 bg-header-light"></div>
                      <div class="appearance-option-footer">
                        <span class="icon-day text-16"></span>
                        <span>Light</span>
                      </div>
                    </div>
                    <div
                      class="w-1/2 appearance-option"
                      :class="[state.portalHeaderTheme === 'dark' && 'appearance-active']"
                      @click="state.portalHeaderTheme = 'dark'"
                    >
                      <div class="h-40 aspect-w-16 aspect-h-6 bg-header-dark"></div>
                      <div class="appearance-option-footer">
                        <span class="icon-night text-16"></span>
                        <span>Dark</span>
                      </div>
                    </div>
                  </div>
                </FormField>
              </div>
              <!-- ----- -->
              <!-- IMAGE -->
              <!-- ----- -->
              <h3 class="settings-section-title mt-32">
                Dashboard Image Customization
              </h3>

              <div class="flex flex-col gap-16 md:grid md:grid-cols-4">
                <FormField label="Tint Color">
                  <ColorPaletteItem
                    v-model="state.portalHeaderBackgroundColor"
                    :presets="colorPresets"
                    variant="pick"
                    popover-placement="buttom"
                    class="list-none included list-item"
                    :size="42"
                  />
                </FormField>
                <!-- Upload Header image-->
                <FormField label="Background Image" wrapper-class="col-span-3">
                  <FileUploader
                    v-slot="{ selectFiles }"
                    accept="image/*"
                    :multiple="false"
                    :block="true"
                    :inline-block="false"
                    custom-class="flex justify-start"
                    @upload-start="onUploadStart"
                    @file-progress="onFileProgress"
                    @file-done="onFileDone"
                    @file-error="onFileError"
                  >
                    <div>
                      <!-- IF IMAGE -->
                      <div
                        v-if="state.portalHeaderImage"
                        class="
                            overflow-hidden relative w-full h-[88px]
                            background-center rounded-md
                          "
                        :style="{
                          backgroundImage: `url(${state.portalHeaderImage})`,
                        }"
                      >
                      </div>
                      <div>
                        <div class="flex items-center gap-8">
                          <div class="flex items-center gap-8 my-8 grow">
                            <b-button
                              v-b-tooltip.hover.bottom.v-info.dh0.ds200
                              title="Upload from your device"
                              variant="primary"
                              size="sm"
                              class="px-12"
                              :disabled="fileUploadState.isUploading"
                              @click="selectFiles"
                            >
                              <span class="mr-4 icon_v2-so_image-upload" />
                              <span>Upload</span>
                            </b-button>
                            <b-button
                              v-b-tooltip.hover.bottom.v-info
                              title="Use an image from Unsplash"
                              variant="black"
                              size="sm"
                              :disabled="fileUploadState.isUploading"
                              class="px-12"
                              @click="openUnsplash"
                            >
                              <span class="mr-4 icon_v2-so_unsplash" />
                              <span>Unsplash</span>
                            </b-button>
                            <b-button
                              v-if="!fileUploadState.isUploading && hasUrl"
                              variant="danger"
                              size="sm"
                              class="ml-auto btn-icon-sm"
                              @click.stop="removeHeaderImage"
                            >
                              <span class="ml-0 mr-0 icon_v2-so_trash"></span>
                            </b-button>
                          </div>
                        </div>
                        <!-- Image Opacity -->
                        <FormField
                          v-if="state.portalHeaderImage"
                          label="Background Image Opacity"
                        >
                          <div :class="['text-12 flex flex-1 items-center text-white']">
                            <RangeSlider
                              v-model="state.portalHeaderImageOpacity"
                              :min="0"
                              :max="100"
                            />
                            <div class="text-right text-gray-600 select-none w-60">
                              {{ state.portalHeaderImageOpacity }}%
                            </div>
                          </div>
                        </FormField>
                      </div>
                    </div>
                    <!-- UNSPLASH MODAL -->
                    <ImageSearchUnsplashModal
                      v-if="isUnsplashOpen"
                      v-model="isUnsplashOpen"
                      @select="onUnsplashImageSelect"
                    />
                  </FileUploader>
                </FormField>
              </div>
            </b-form-group>
          </section>
          <aside class="flex flex-col justify-center mr-48">
            <ProjectBrandingPreview
              :branding="state"
              :organization="organization"
            />
          </aside>
        </div>
      </section>
      <!-- Project Defaults -->
      <section class="mt-16 md:mt-48">
        <!-- HEADER -->
        <div class="mb-23">
          <h4 class="font-semibold text-gray-800 text-18 md:text-24 dark:text-darkGray-300">
            Project Portal Defaults
          </h4>
          <p class="settings-section-description">
            Customize the default color palette and fonts used in this project portal.
          </p>
        </div>

        <!-- Colors -->
        <b-form-group>
          <FormField label="Color Palette" wrapper-class="mb-24 md:mb-0">
            <ColorPalette
              v-model="state.brandingColors"
              :no-fade="true"
              popover-placement="right"
            />
          </FormField>
          <!-- Fonts  -->
          <FormPickFont
            :has-title="false"
            :model="state"
            @change-font="changeFont"
          />
        </b-form-group>
      </section>
      <PageFooter>
        <Button
          :disabled="isBusy"
          size="lg"
          variant="primary"
          type="submit"
          @click="submit"
        >
          Save Changes
        </Button>
        <template v-if="hasChanges" #right>
          <PageFooterMessage />
        </template>
      </PageFooter>
    </Form>
    <ModalBasic
      v-if="openToSelectFont"
      v-model="openToSelectFont"
      :title="'Choose Font'"
      :hide-footer="true"
      dialog-class="w-full"
      content-class="modal-content-tertiary"
      body-class="modal-body-tertiary"
      header-class="modal-header-tertiary"
      footer-class="modal-footer-tertiary"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
      @back="backToForm"
    >
      <template #body>
        <BrandingFonts
          :apply-font-on="applyFontOn"
          :model="state"
          @select="selectFont"
        />
      </template>
    </ModalBasic>
  </div>
</template>
<script>
import { pathOr } from 'ramda'
import { rejectNil } from 'ramda-extension'
import { defineComponent, ref, computed } from '@vue/composition-api'
import { useFileUpload } from '@/v2/services/uploads/uploadsCompositions'
import { useCurrentProfile } from '@/v2/services/profiles/profilesCompositions'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'
import PageFooter from '@/components/PageFooter.vue'
import Form from '@/components/Form.vue'
import FormField from '@/components/FormField.vue'
import Button from '@/components/Button.vue'
import ColorPaletteItem from '@/components/ColorPaletteItem.vue'
import BrandingFonts from '@/components/BrandingFonts.vue'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import FileUploader from '@/components/FileUploader/FileUploader.vue'
import ImageSearchUnsplashModal from '@/components/ImageSearchUnsplashModal.vue'
import ProjectBrandingPreview from '@/components/ProjectBrandingPreview.vue'
import FormPickFont from '@/components/FormPickFont.vue'
import ColorPalette from '@/components/ColorPalette.vue'
import RangeSlider from '@/components/RangeSlider.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'
import PageHeader from '@/components/PageHeader.vue'

const themeOptions = [
  {
    text: 'Light',
    value: 'light',
  },
  {
    text: 'Dark',
    value: 'dark',
  },
]

export default defineComponent({
  name: 'ViewProjectSettingsBranding',
  components: {
    PageFooter,
    FormField,
    Form,
    Button,
    ColorPaletteItem,
    BrandingFonts,
    ModalBasic,
    FileUploader,
    ImageSearchUnsplashModal,
    ProjectBrandingPreview,
    FormPickFont,
    ColorPalette,
    RangeSlider,
    PageFooterMessage,
    PageHeader,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { profile } = useCurrentProfile()

    const openToSelectFont = ref(false)
    const applyFontOn = ref(null)
    const isUnsplashOpen = ref(false)

    const defaults = computed(() => ({
      portalAccentColor: pathOr(null, ['brandingColors', 0, 'color'], props.organization),
      portalLinkColor: pathOr(null, ['brandingColors', 0, 'color'], props.organization),
      portalHeaderBackgroundColor: pathOr(null, ['brandingColors', 1, 'color'], props.organization),
    }))

    const fields = computed(() => [
      'brandingColors',
      'brandingFontHeading',
      'brandingFontParagraph',
      'portalTheme',
      'portalAccentColor',
      'portalSecondaryColor',
      'portalLinkColor',
      'portalHeadingFont',
      'portalHeaderTheme',
      'portalHeaderBackgroundColor',
      'portalHeaderImage',
      'portalHeaderImageOpacity',
    ])

    const {
      state,
      save,
    } = useFormEntityEditor({
      model: 'Project',
      id: computed(() => props.project._id),
      defaults,
      fields,
    })

    // greeting
    const hourNow = computed(() => {
      const today = new Date()
      return today.getHours()
    })

    const greeting = computed(() => {
      if (hourNow.value > 18 && profile.value) {
        return `Good evening, ${profile.value.firstName}`
      } if (hourNow.value > 12 && profile.value) {
        return `Good afternoon, ${profile.value.firstName}`
      } if (hourNow.value > 0 && profile.value) {
        return `Good morning, ${profile.value.firstName}`
      }
      return 'Welcome!'
    })

    const {
      state: fileUploadState,
      fileUrl,
      hasUrl,
      removeFile,
      onUploadStart,
      onFileProgress,
      onFileError,
      onUploadEnd,
    } = useFileUpload(computed(() => props.project.portalHeaderImage))

    // Color Presets
    const colorPresets = computed(() => rejectNil([
      props.project && (props.project?.client$?.mainColor ?? null) ? {
        label: 'Client color',
        colors: [{
          color: props.project?.client$?.mainColor,
          id: `client-color-${props.project?.client}`,
        }],
      } : null,
      props.project && props.project.brandingColors.length ? {
        label: 'Project Colors',
        colors: props.project.brandingColors,
      } : null,
      props.organization.brandingColors.length ? {
        label: 'Organization Colors',
        colors: props.organization.brandingColors,
      } : null,
    ]))

    // Header image
    const selectFont = (value, applyOn) => {
      // model.value[applyOn] = value
      state.value[applyOn] = value
      openToSelectFont.value = false
    }

    const changeFont = applyOn => {
      applyFontOn.value = applyOn
      openToSelectFont.value = true
    }

    const backToForm = () => {
      openToSelectFont.value = false
    }

    // Header image
    const _onUploadStart = (...args) => {
      context.emit('async-save-start')
      onUploadStart(...args)
    }
    const _onFileDone = (file, uploadedFile) => {
      state.value.portalHeaderImage = uploadedFile.url
      // model.value.portalHeaderImage = uploadedFile.url
      onUploadEnd(file, uploadedFile)
    }
    const onUnsplashImageSelect = ({ urls }) => {
      state.value.portalHeaderImage = urls.raw
      // model.value.portalHeaderImage = urls.raw
      isUnsplashOpen.value = false
    }
    const openUnsplash = () => {
      isUnsplashOpen.value = true
    }

    const removeHeaderImage = () => {
      state.value.portalHeaderImage = ''
    }

    return {
      // Variant/Theme
      themeOptions,
      // Heading font
      selectFont,
      changeFont,
      openToSelectFont,
      applyFontOn,
      backToForm,
      hourNow,
      greeting,
      colorPresets,
      // Header image
      onUnsplashImageSelect,
      onUploadStart: _onUploadStart,
      onFileProgress,
      onFileError,
      onFileDone: _onFileDone,
      isUnsplashOpen,
      fileUploadState,
      fileUrl,
      openUnsplash,
      removeFile,
      hasUrl,
      removeHeaderImage,
      fields,
      defaults,

      // formEntityEditor
      state,
      save,
    }
  },
})
</script>
<style lang="postcss" scoped>
.appearance-option {
  @apply flex flex-col rounded-md;
  @apply border border-solid border-gray-300 overflow-hidden shadow dark:border-darkGray-500;
  @apply text-gray-500 items-stretch text-left;
  @apply cursor-pointer;
  @apply transition-all ;
  @apply  hover:shadow-lg;
}

.appearance-active {
  @apply border-green-500 ring ring-green-500 ring-opacity-50;
  @apply text-gray-1000;
  @apply hover:transform-none;
}

.appearance-option-footer {
  @apply px-8 py-6 border-t border-gray-300 dark:border-darkGray-500 grow flex items-center space-x-4 dark:text-darkGray-300;
  @apply text-13 font-medium leading-none;
}

.bg-preview-dark {
  background-image: url('/assets/img/preview-dark.svg');
}
.bg-preview-light {
  background-image: url('/assets/img/preview-light.svg');
}
.bg-header-dark {
  background-image: url('/assets/img/header-dark.svg');
}
.bg-header-light {
  background-image: url('/assets/img/header-light.svg');
}

</style>
