import { CATEGORY, LABEL } from '@/v2/services/documentNodes/documentNodesTypes'
import DocumentNodeGroupCaseStudyEditable from '@/v2/features/document/documentNodeGroupImpl/groupCaseStudy/DocumentNodeGroupCaseStudyEditable.vue'
import DocumentNodeGroupTeamBiosEditable from '@/v2/features/document/documentNodeGroupImpl/groupTeamBios/DocumentNodeGroupTeamBiosEditable.vue'
import DocumentNodeGroupPackagedServiceEditable from '@/v2/features/document/documentNodeGroupImpl/groupPackagedService/DocumentNodeGroupPackagedServiceEditable.vue'
import DocumentNodeGroupColumnsContainerEditable from '@/v2/features/document/documentNodeGroupImpl/groupColumnsContainer/DocumentNodeGroupColumnsContainerEditable.vue'
import DocumentNodeGroupColumnEditable from '@/v2/features/document/documentNodeGroupImpl/groupColumn/DocumentNodeGroupColumnEditable.vue'
import DocumentNodeGroupBasicEditable from '@/v2/features/document/documentNodeGroupImpl/groupBasic/DocumentNodeGroupBasicEditable.vue'

export default {
  [CATEGORY.NodeGroupCaseStudy]: {
    label: LABEL[CATEGORY.NodeGroupCaseStudy],
    component: DocumentNodeGroupCaseStudyEditable,
  },
  [CATEGORY.NodeGroupPackagedService]: {
    label: LABEL[CATEGORY.NodeGroupPackagedService],
    component: DocumentNodeGroupPackagedServiceEditable,
  },
  [CATEGORY.NodeGroupTeamBio]: {
    label: LABEL[CATEGORY.NodeGroupTeamBio],
    component: DocumentNodeGroupTeamBiosEditable,
  },
  [CATEGORY.NodeGroupColumnsContainer]: {
    label: 'Columns Container ',
    component: DocumentNodeGroupColumnsContainerEditable,
  },
  [CATEGORY.NodeGroupColumn]: {
    label: 'Column',
    component: DocumentNodeGroupColumnEditable,
  },
  [CATEGORY.NodeGroupBasic]: {
    label: 'Group',
    component: DocumentNodeGroupBasicEditable,
  },
}
