
<nav class="text-gray-700 p-4">
  <ul v-if="!disabled">
    <li>
      <MainHeaderNavItemCount
        label="Inbox"
        :count="10"
        :to="{ name: ORGANIZATION_INBOX }"
        model="Notification"
        :events="['markAllAsRead']"
        :query="{ organization: organization._id, read: false }"
      />
    </li>
  </ul>
</nav>
