import { ref, computed } from '@vue/composition-api'
import { idNew } from '@/v2/lib/symbols'

export default () => {
  const entityId = ref(null)

  const edit = id => {
    entityId.value = id
  }

  const create = () => edit(idNew)
  const closeEditor = () => edit(null)

  const isEditorOpen = computed({
    get: () => Boolean(entityId.value),
    set: val => {
      if (!val) {
        entityId.value = null
      }
    },
  })

  const isNew = computed(() => entityId.value === idNew)

  return {
    entityId,
    edit,
    create,
    closeEditor,
    isEditorOpen,
    isNew,
  }
}
