<template>
  <div class="flex flex-col justify-center text-center mb-40">
    <span v-if="step && total" class="text-14 text-gray-500">
      Step {{ step }} of {{ total }}
    </span>
    <h2 class="title-h2 text-center">
      <slot />
    </h2>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SignUpFormHeader',
  props: {
    total: {
      type: Number,
      default: null,
    },
    step: {
      type: Number,
      default: null,
    },
  },
})
</script>
