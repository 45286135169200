<template>
  <router-link
    v-slot="{ navigate, href, /*isExactActive, */ isActive }"
    :to="to"
    custom
  >
    <a
      class="inline-flex items-center gap-x-8"
      :class="{'text-green-600': isActive}"
      :href="href"
      @click="navigate"
    >
      <span class="text-14">{{ label }}</span>
      <span
        v-if="count"
        class="
          bg-orange-600 text-white text-11 font-bold leading-none rounded-full
          flex items-center h-18 px-6 sidebar-counter flex-shrink-0
        "
      >{{ count }}</span>
    </a>
  </router-link>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MainHeaderNavItem',
  components: {
  },
  props: {
    to: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      default: null,
    },
  },
})
</script>
