<template>
  <div :class="['cell', $style['cell'], className]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ListTableCell',
  props: {
    width: {
      type: [Number, String],
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" module>
.cell {
  display: table-cell;
  border-bottom: 1px solid $gray-300;
  font-size: $fs18;
  vertical-align: middle;
  line-height: 1.4;
  padding: 10px 0;
  padding-right: 18px;
  &:last-child {
    padding-right: 0;
  }
  &::before {
    content: '';
    height: 1px;
    background-color: $primary;
    position: absolute;
    left: 0;
    top: -1px;
    right: 0;
    //transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
  }
  &::after {
    content: '';
    height: 1px;
    background-color: $primary;
    position: absolute;
    left: 0;
    bottom: -1px;
    right: 0;
    //transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
  }
  &:global(.rates) {
    font-family: $font-family-monospace;
    font-size: $fs16;
    color: $gray-900;
  }
  &:global(.edit-cell) {
    padding-right: 0;
  }

  &:global(.delete-item) {
    border: none;
    width: 1px;
  }
}
</style>
