<template>
  <Licensing v-slot="{ licensing, plan }">
    <router-view
      :organization="organization"
      :licensing="licensing"
      :plan="plan"
    />
  </Licensing>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import Licensing from '@/components/Licensing.vue'

export default defineComponent({
  name: 'ViewOrganizationSubscription',
  components: {
    Licensing,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
})
</script>
