
<div>
  <SidebarGroup label="Projects">
    <SidebarMenu>
      <SidebarMenuLink
        v-for="projectCollection in collections.projects"
        :key="projectCollection.id"
        :to="{
          name: ORGANIZATION_TEMPLATES_PROJECTS,
          params: { slug: projectCollection.slug },
        }"
        activate-on-child-route
      >
        <SidebarItemBasic
          :label="projectCollection.label"
          :count="projectCollection.count"
          count-variant="muted"
        />
      </SidebarMenuLink>
    </SidebarMenu>
  </SidebarGroup>
  <SidebarGroup label="Documents">
    <SidebarMenu>
      <SidebarMenuLink
        v-for="documentCollection in collections.documents"
        :key="documentCollection.id"
        :to="{
          name: ORGANIZATION_TEMPLATES_DOCUMENTS,
          params: { slug: documentCollection.slug },
        }"
        activate-on-child-route
      >
        <SidebarItemBasic
          :label="documentCollection.label"
          :count="documentCollection.count"
          count-variant="muted"
        />
      </SidebarMenuLink>
    </SidebarMenu>
  </SidebarGroup>
</div>
