
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isEmpty"

  :config-override="{
    stylingToolbar: {
      features: {
        sizes: false,
      },
    },
  }"
>
  <template #default>
    <!-- <DocumentMasonry v-if="!isEmpty">
      <DocumentNodeEditable
        v-for="(nodeId, index) in children"
        :key="nodeId"
        :node-id="nodeId"
        :parent="node._id"
        :index="index"
        :is-first="index === 0"
        :is-last="index === children.length - 1"
        rounded-selection
        grid
      />
    </DocumentMasonry> -->
    <SlickList
      v-if="!isEmpty"
      v-model="nodes"
      lock-to-container-edges
      axis="xy"
      use-drag-handle
      helper-class="so-group-node-dragging"
      class="grid grid-cols-1 gap-24 mb-24 auto-rows-fr lg:grid-cols-2 print:grid-cols-2"
      @sort-start="handleSortStart"
      @sort-end="handleSortEnd"
    >
      <!-- <DocumentMasonry> -->
      <SlickItem
        v-for="(nodeId, index) in nodes"
        :key="nodeId"
        :index="index"
      >
        <DocumentNodeEditable
          :index="index"
          :node-id="nodeId"
          :parent="node._id"
          :is-first="index === 0"
          :is-last="index === children.length - 1"
          rounded-selection
          grid
          class="so-slick-inner"
        />
      </SlickItem>
      <!-- </DocumentMasonry> -->
    </SlickList>
    <DocumentNodeGroupCaseStudyPlaceholder v-else />
    <DocumentBlockButtonCreate
      :parent-node-id="node._id"
      :index="children.length"
      :accepts="accepts"
      content-library
      class="w-full mb-16"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
</DocumentNodeRenderer>
