
<Form
  v-slot="{ state, isBusy, isInvalid, v }"
  :state="app"
  :validation="validation"
  :auto-dirty="autoDirty"
  clone
  @invalid="val => $emit('invalid', val)"
  @submit="submit"
>
  <div
    class="w-full gap-16"
    :class="[
      instructions ? 'grid grid-cols-2 auto-cols-max auto-rows-max' : 'flex flex-col'
    ]"
  >
    <!-- ------------ -->
    <!-- FORM         -->
    <!-- ------------ -->
    <div class="flex flex-col flex-1 gap-8 grow">
      <div class="flex flex-row items-center gap-8 justify-items-stretch">
        <FormField label="Icon">
          <div class="flex justify-between">
            <DropdownIcons
              :target-id="`connected-app-popover-${app._id}`"
              :current-icon="state.icon"
              @pick-icon="icon => (state.icon = icon)"
            />
          </div>
        </FormField>
        <!-- title -->
        <FormField
          :v="v.title"
          label="Label"
          required
          wrapper-class="grow"
        >
          <b-input
            v-model="state.title"
            placeholder="App Name"
            class="form-control-lg"
          />
        </FormField>
      </div>

      <!-- IF QUICK LINK / URL EMBED -->
      <FormField
        v-if="embedType === 'url'"
        label="Link"
        :v="v.url"
        required
        :messages="{
          supported: 'This URL is not supported',
        }"
      >
        <b-input
          v-model.trim="state.url"
          :placeholder="instructions ? `Your ${state.title} link` : 'Your app link'"
          class="form-control-lg"
        />
      </FormField>
      <div
        v-if="embedType === 'url'"
        class="bg-blue-100 p-8 rounded-md text-13 mb-12 leading-none text-black/70"
      >
        💡 For Quick Embeds you <strong>only need the link from the 3rd party app</strong>.
      </div>

      <!-- IF IFRAME EMBEDS / EMBED CODE -->
      <FormField
        v-if="embedType === 'embedCode'"
        label="Embed Code"
        required
        :v="v.embedCode"
        :messages="{
          supported: embedCodeInvalidMessage,
        }"
      >
        <CodeEditor
          v-model="state.embedCode"
          :placeholder="instructions ?
            `Paste your ${state.title} embed Code...`
            : 'Paste the iframe embed code here...'"
        />
        <!-- <b-form-textarea
          v-model="state.embedCode"
          :placeholder="instructions ?
            `Paste your ${state.title} embed Code...`
            : 'Paste the iframe embed code here...'"
          class="form-control-lg"
          rows="3"
          max-rows="6"
        /> -->
      </FormField>
      <div
        v-if="embedType === 'embedCode'"
        class="bg-yellow-100 p-8 rounded-md text-13 mb-12 leading-none text-black/70"
      >
         <strong>🚨 SuperOkay doesn't validate your code.</strong> <a
          href="https://help.superokay.com/en/articles/6053247-how-do-you-embed-apps-in-a-superokay-client-portal"
          class="underline font-semibold text-blue-800"
          target="_blank"
        > Here's a guide on how to embed apps correctly in SuperOkay</a>
      </div>
      <!-- description -->
      <FormField
        :v="v.description"
        label="Description"
        wrapper-class="mb-16"
      >
        <b-input
          v-model="state.description"
          placeholder="Describe what the app does"
          class="form-control-lg"
        />
      </FormField>
      <slot name="buttons" v-bind="{ isFormBusy: isBusy, isFormInvalid: isInvalid }" />
    </div>

    <!-- ------------ -->
    <!-- INSTRUCTIONS -->
    <!-- ------------ -->
    <div
      v-if="instructions"
      class="relative ml-24 -mr-32 overflow-hidden rounded-tl-2xl rounded-br-2xl bg-blue"
    >
      <div class="px-24 pt-24 overflow-y-scroll h-395 pb-112">
        <h4
          class="inline-block px-4 mb-16 font-semibold text-blue-800 bg-blue-200 rounded text-16"
        >
          Instructions
        </h4>
        <ol class="pl-16 space-y-24 list-decimal">
          <li v-for="step in instructions" :key="step.title">
            <p class="font-semibold text-gray-900 text-16">{{ step.title }}</p>
            <img
              v-if="step.screenshot"
              class="h-auto pl-16 my-8"
              :src="step.screenshot"
              :alt="step.title"
            />
            <p class="pl-16 text-gray-700 text-14">{{ step.description }}</p>
          </li>
        </ol>
      </div>
      <div class="instructions-gradient instructions-gradient-light"></div>
    </div>
  </div>
</Form>
