<template>
  <div v-if="isVisible" :class="$style['content-library-modal']">
    <!-- v-if="isBlock" -->
    <ContentLibraryModalBlock
      :value="isVisible"
      :title="title"
      :dialog-class="$style['modal-library-dialog']"
      :category.sync="category"
      :hide-sidebar="isSideBarHidden"
      @select="create"
      @input="onModalStateChange"
    />
  </div>
</template>

<script>
import { ref, computed, watch, defineComponent } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { pick } from 'ramda'
import { useCreateNode } from '@/v2/services/documentNodes/documentNodesCompositions'
// import { CATEGORY as DOCUMENT_CATEGORY } from '@/types/document'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'

import { CATEGORY_TO_LIST_TITLE as TITLE_MAP } from '@/v2/services/documents/documentsTypes'
import ContentLibraryModalBlock from '@/components/ContentLibrary/ContentLibraryModalBlock.vue'

const { useMutations, useState } = createNamespacedHelpers('documentEditor')

export default defineComponent({
  name: 'ContentLibraryModal',
  components: {
    ContentLibraryModalBlock,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const createNode = useCreateNode()

    const category = ref(null)
    const { sessionContentLibrary } = useState(['sessionContentLibrary'])

    const title = computed(() => TITLE_MAP[category.value])

    const isVisible = computed(
      () => props.document._id === sessionContentLibrary.value.documentId
    )

    const isSideBarHidden = computed(() => Boolean(
      sessionContentLibrary.value.contentDocumentCategory
    ))

    const { closeSessionContentLibrary } = useMutations([
      'closeSessionContentLibrary',
    ])

    const onModalStateChange = visible => !visible && closeSessionContentLibrary()

    const create = async embeddedDocument => {
      const { parentNodeId, index } = pick(
        ['parentNodeId', 'index'],
        sessionContentLibrary.value
      )

      await createNode({
        parentNodeId,
        index,
        contentBlockData: { embeddedDocument: embeddedDocument._id },
      })

      closeSessionContentLibrary()
    }

    watch(isVisible, visible => {
      if (!visible) {
        category.value = null
        return
      }

      category.value = sessionContentLibrary.value.contentDocumentCategory
        || (props.document.isProjectLevel
          ? DOCUMENT_CATEGORY.Document
          : DOCUMENT_CATEGORY.DocumentCaseStudy)
    })

    return {
      category,
      title,
      isVisible,
      isSideBarHidden,
      // Methods
      create,
      onModalStateChange,
    }
  },
})
</script>

<style lang="scss" module>
.modal-library-dialog {
  max-width: 1000px;
}
.modal-library-block {
  max-width: 730px;
  :global(.modal-header) {
    min-height: 0;
    margin: 0;
    padding-top: 24px;
  }
  // :global(.modal-title) {
  //     text-align: left;
  //     font-size: $fs19;
  //     font-weight: $medium;
  // }
}
</style>
