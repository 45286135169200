<template>
  <div class="pb-24">
    <PageHeader title="My Organization" />
    <Form
      v-if="state"
      v-slot="{ isBusy, v, submit, hasChanges }"
      :state="state"
      :validation="validation"
      success-message="Organization settings saved"
      :on-submit="() => save()"
    >
      <div class="space-y-16">
        <FormField
          :v="v.name"
          label="Organization name"
          wrapper-class="max-w-510"
        >
          <b-input
            v-model="state.name"
            size="lg"
          />
        </FormField>

        <div class="max-w-510">
          <FormField
            :v="v.currency"
            label="Default Currency"
            description="Changing the currency will be reflected across all documents, old and new."
          >
            <FormInputCurrencies
              v-model="state.currency"
              class="form-control-lg"
            />
          </FormField>
        </div>
      </div>

      <div class="space-y-48">
        <section>
          <!-- Logo -->
          <h4 class="project-page-h3 mt-48">Logo</h4>
          <p class="text-12 text-gray-600">
            A .png with transparency or .svg will look best.
          </p>

          <div class="flex flex-col md:flex-row gap-40 mt-16">
            <div :class="$style['company-logo-area']">
              <h3 class="font-medium text-14">Primary</h3>
              <p class="mb-8 text-12 text-gray-500">Used on light backgrounds</p>
              <div :class="$style['company-logo']">
                <LogoUpload
                  v-model="state.logoUrl"
                  :height="80"
                  :width="240"
                  :display-height="100"
                  :display-width="320"
                  class-name="p-0"
                  @uploading="uploadLogo"
                >
                  <template #buttons="{ selectFiles, removeFile }">
                    <Button
                      v-if="!state.logoUrl"
                      variant="dark"
                      size="xs"
                      @click="selectFiles"
                    >
                      Upload Logo
                    </Button>
                    <template v-else>
                      <Button
                        variant="dark"
                        size="xs"
                        @click="selectFiles"
                      >
                        Replace
                      </Button>
                      <Button
                        variant="danger"
                        size="xs"
                        @click="removeFile"
                      >
                        Remove
                      </Button>
                    </template>
                  </template>
                </LogoUpload>
              </div>
            </div>
            <div :class="$style['company-logo-area']">
              <h3 class="font-medium text-14">Reversed</h3>
              <p class="mb-8 text-12 text-gray-500">Used on dark backgrounds</p>
              <div :class="$style['company-logo']">
                <LogoUpload
                  v-model="state.logoDarkUrl"
                  :height="80"
                  :width="240"
                  :display-height="100"
                  :display-width="320"
                  variant="dark"
                  class-name="p-0"
                  @uploading="uploadLogo"
                >
                  <template #buttons="{ selectFiles, removeFile }">
                    <Button
                      v-if="!state.logoDarkUrl"
                      variant="dark"
                      size="xs"
                      @click="selectFiles"
                    >
                      Upload Logo
                    </Button>
                    <template v-else>
                      <Button
                        variant="dark"
                        size="xs"
                        @click="selectFiles"
                      >
                        Replace
                      </Button>
                      <Button
                        variant="danger"
                        size="xs"
                        @click="removeFile"
                      >
                        Remove
                      </Button>
                    </template>
                  </template>
                </LogoUpload>
              </div>
            </div>
          </div>
        </section>

        <!-- Colors -->
        <section>
          <h3 class="project-page-h3">Color Palette</h3>
          <p class="text-12 text-gray-600 mb-24">
            This color palette will be available in all your documents and projects.
          </p>
          <ColorPalette v-model="state.brandingColors" />
        </section>
        <!-- Fonts -->
        <section>
          <h3 class="project-page-h3">Fonts</h3>
          <p class="text-12 text-gray-600 mb-24">
            Choose the default fonts used on headings
            or paragraphs across your projects and documents
          </p>
          <!-- Fonts  -->
          <FormPickFont
            :has-title="false"
            :model="state"
            @change-font="openModal"
          />
        </section>
      </div>
      <PageFooter>
        <Button
          :disabled="isBusy"
          size="lg"
          variant="primary"
          type="submit"
          @click="submit"
        >
          Save Changes
        </Button>
        <template v-if="hasChanges" #right>
          <PageFooterMessage />
        </template>
      </PageFooter>
    </Form>
    <BrandingFontsModal
      v-if="isModalOpen"
      v-model="isModalOpen"
      :organization="organization"
      :apply-font-on="applyFontOn"
      :model="state"
      @select="selectFont"
    />
  </div>
</template>
<script>
import { ref, defineComponent, computed } from '@vue/composition-api'
import { required } from '@vuelidate/validators'
import { ORGANIZATION_SUBSCRIPTION } from '@/router/organization/type'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'
import Form from '@/components/Form.vue'
import PageHeader from '@/components/PageHeader.vue'
import PageFooter from '@/components/PageFooter.vue'
import FormField from '@/components/FormField.vue'
import Button from '@/components/Button.vue'
import FormInputCurrencies from '@/components/FormInputCurrencies.vue'
import LogoUpload from '@/components/Blocks/Image/LogoUpload.vue'
import ColorPalette from '@/components/ColorPalette.vue'
import BrandingFontsModal from '@/components/BrandingFontsModal.vue'
import FormPickFont from '@/components/FormPickFont.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'

export default defineComponent({
  name: 'ViewOrganizationSettingsMyOrganization',
  components: {
    PageHeader,
    PageFooter,
    Form,
    FormField,
    Button,
    FormInputCurrencies,
    LogoUpload,
    ColorPalette,
    BrandingFontsModal,
    FormPickFont,
    PageFooterMessage,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // const defaultColors = ref(['#DC5555FF', '#6F81B06B', '#6A11B06B', '#C92F2FAD', '#EDEA53D6'])
    const isModalOpen = ref(false)
    const applyFontOn = ref(null)
    const defaultColors = ref(undefined)
    const presets = [
      {
        label: 'Project colors',
        colors: [
          '#5EE141FF',
          '#4277E1FF',
          '#972DC9FF',
          '#6A11B06B',
          '#F9E158FF',
          '#4CE5A2FF',
        ],
      },
      {
        label: 'Organisation colors',
        colors: [
          '#C92F2FAD',
          '#EDEA53D6',
        ],
      },
    ]

    const isUploading = ref(false)
    const uploadLogo = status => { isUploading.value = status }

    const {
      state,
      save,
    } = useFormEntityEditor({
      model: 'Organization',
      id: computed(() => props.organization._id),
      fields: [
        'name',
        'currency',
        'logoUrl',
        'logoDarkUrl',
        'brandingColors',
        'brandingFontHeading',
        'brandingFontParagraph',
      ],
    })

    const validation = computed(() => ({
      name: {
        required,
      },
      currency: {
        required,
      },
    }))

    const selectFont = (value, applyOn) => {
      state.value[applyOn] = value
      isModalOpen.value = false
    }

    const openModal = font => {
      isModalOpen.value = true
      applyFontOn.value = font
    }


    return {
      validation,
      uploadLogo,
      isUploading,
      ORGANIZATION_SUBSCRIPTION,
      defaultColors,
      presets,
      selectFont,
      isModalOpen,
      openModal,
      applyFontOn,
      save,
      state,
    }
  },
})
</script>
<style lang="scss" module>
.locale-select {
  max-width: 245px;
}
.plan-label {
  @apply mb-4 inline-block text-12 text-gray-500 dark:text-darkGray-400;
}
.plan-item {
  margin-bottom: 18px;
}
.plan-result {
  @apply text-14 text-gray-700 font-semibold;
}

.plan-support {
  color: $gray-700;
  font-weight: $regular;
  font-size: $fs14;
}
.company-logo-area {
  @apply mt-8;
}
.company-logo {
  display: flex;
  align-items: center;
  :global(.pu) {
    margin-bottom: 0;
  }
  :global(.logo-area) {
    margin: 0;
    background-color: $gray-000;
    border: 1px solid $gray-300;
    padding: 4px;
    text-align: center;
    min-width: 142px;
    margin-right: 24px;
  }
  :global(.logo-placeholder) {
    color: $gray-500;
    font-weight: $medium;
    line-height: 42px;
  }
}
.company-name {
  :global(.form-control) {
    max-width: 403px;
  }
}
.popover {
  &[x-placement^='top'] {
    :global(.arrow) {
      &::after {
        border-top-color: theme('colors.gray.DEFAULT');
      }
    }
  }

  &[x-placement^='bottom'] {
    :global(.arrow) {
      &::after {
        border-bottom-color: theme('colors.gray.DEFAULT');
      }
    }
  }

  :global(.popover-body) {
    color: theme('colors.gray.700');
    padding: 0;
  }
}
</style>
