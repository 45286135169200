<template>
  <div v-fragment>
    <ImageSearchUnsplashModal
      v-if="isUnsplashOpen"
      v-model="isUnsplashOpen"
      @select="onUnsplashImageSelect"
    />
    <FileUploader
      v-slot="{ selectFiles }"
      block
      :multiple="false"
      accept="image/*"
      @upload-start="onUploadStart"
      @file-progress="onFileProgress"
      @file-done="onFileDone"
      @file-error="onFileError"
    >
      <DocumentNodeEditorToolbarPopover
        ref="popover"
        triggers="click"
        :close-on-click-outside="!noClose"
      >
        <template #button-content>
          <div
            v-if="!hasUrl"
            v-b-tooltip.hover.bottom.v-info
            :class="$style['icon-preview']"
            title="Select background image"
          >
            <icon-base
              width="60"
              height="30"
              vw="72"
              vh="36"
              icon-name="image-background"
            >
              <icon_v2-so_image-background />
            </icon-base>
          </div>
          <div
            v-else
            :class="$style['image-preview']"
            :style="{ backgroundImage: `url(${fileUrl})` }"
          />
        </template>
        <template #title>
          Background Image
        </template>
        <template #default>
          <div class="d-block w-100 mt-8">
            <template v-if="hasUrl">
              <DocumentNodeEditorToolbarSlider
                :value="opacity"
                :min="0"
                :max="100"
                :step="5"
                label="Opacity"
                @input="v => $emit('update:opacity', v)"
              />
              <div
                :class="$style.image"
                :style="{ backgroundImage: `url(${fileUrl})` }"
              >
                <!-- <PieProgressBar
                  v-show="fileUploadState.isUploading"
                  :ratio="fileUploadState.progress"
                /> -->
                <div
                  :class="[
                    'flex items-center justify-center w-full h-112',
                    'rounded bg-center bg-cover relative mb-8',
                    'group',
                  ]"
                  :style="{ backgroundImage: `url(${fileUrl})` }"
                >
                  <PieProgressBar
                    v-show="fileUploadState.isUploading"
                    :ratio="fileUploadState.progress"
                  />
                  <b-link
                    :class="[
                      'absolute bottom-8 right-8 opacity-0 invisible items-center',
                      'bg-gray-1000 bg-opacity-60 px-10 py-6 rounded',
                      'text-14 text-gray-400 hover:text-white',
                      'group-hover:opacity-100 group-hover:visible',
                    ]"
                    @click="removeFile"
                  >
                    <span class="icon_v2-so_trash mr-8" />Remove
                  </b-link>
                </div>
              </div>
            </template>

            <!-- Upload Buttons  -->
            <div class="space-y-8">
              <Button
                variant="primary"
                block
                size="m"
                @click="onSelectFiles(selectFiles)"
              >
                <span class="icon_v2-so_image text-16 w-16 mr-8" />Upload file
              </Button>
              <Button
                variant="neutral"
                :disabled="fileUploadState.isUploading"
                block
                size="m"
                @click="openUnsplash"
              >
                <span class="w-16 mr-8 icon-logo-unsplash text-16" />
                from Unsplash
              </Button>
            </div>
          </div>
        </template>
      </DocumentNodeEditorToolbarPopover>
    </FileUploader>
  </div>
</template>
<script>
// import { useMsgBoxError } from '@/v2/lib/composition/useMsgBox'
import { ref, toRef } from '@vue/composition-api'

import { useFileUpload } from '@/v2/services/uploads/uploadsCompositions'
import IconBase from '@/components/Icons/IconBase.vue'
import IconImageBackground from '@/components/Icons/IconImageBackground.vue'
import Button from '@/components/Button.vue'
import PieProgressBar from '@/components/Blocks/ProgressBar/PieProgressBar.vue'
import FileUploader from '@/components/FileUploader/FileUploader.vue'
import ImageSearchUnsplashModal from '@/components/ImageSearchUnsplashModal.vue'
import DocumentNodeEditorToolbarPopover from './DocumentNodeEditorToolbarPopover.vue'
import DocumentNodeEditorToolbarSlider from './DocumentNodeEditorToolbarSlider.vue'

export default {
  name: 'DocumentNodeEditorToolbarImage',
  components: {
    IconBase,
    IconImageBackground,
    Button,
    PieProgressBar,
    DocumentNodeEditorToolbarPopover,
    DocumentNodeEditorToolbarSlider,
    FileUploader,
    ImageSearchUnsplashModal,
  },
  props: {
    opacity: {
      type: Number,
      default: 100,
    },
    value: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    // const msgBoxError = useMsgBoxError()
    const isUnsplashOpen = ref(false)
    const popover = ref(null)

    const noClose = ref(false)
    const onSelectFiles = selectFilesCb => {
      noClose.value = true
      context.root.$nextTick(() => {
        selectFilesCb()
        noClose.value = false
      })
    }

    const {
      state: fileUploadState,
      fileUrl,
      hasUrl,
      removeFile,
      onUploadStart,
      onFileProgress,
      onFileDone,
      onFileError,
      onUploadEnd,
    } = useFileUpload(toRef(props, 'value'))

    // const onBgImageUploadError = (file, error) => {
    //   msgBoxError({
    //     title: 'Upload failed',
    //     message: `Failed to set the new background image: ${error}`,
    //   })
    // }
    const openUnsplash = () => {
      popover.value.close()
      isUnsplashOpen.value = true
    }

    const onUnsplashImageSelect = ({ urls }) => {
      context.emit('input', urls.raw)
      isUnsplashOpen.value = false
    }

    return {
      popover,
      onSelectFiles,
      noClose,
      fileUploadState,
      fileUrl,
      hasUrl,
      removeFile,
      onUploadStart,
      onFileProgress,
      onFileDone,
      onFileError,
      onUploadEnd,
      isUnsplashOpen,
      openUnsplash,
      onUnsplashImageSelect,
    }
  },
}
</script>
<style lang="scss" module>
.icon-preview {
  color: $gray-700;
  border-radius: $border-radius / 2;
  position: relative;
  display: flex;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: $border-radius / 2;
    overflow: hidden;
    box-shadow: inset 0 0 0 1px rgba(white, 0.7),
      inset 0 0 0 2px $toolbar-dropdown-bg;
    transition: box-shadow 0.2s ease-in-out;
  }
  &:hover {
    &::after {
      border-radius: $border-radius / 2;
      overflow: hidden;
      box-shadow: inset 0 0 0 2px rgba(white, 0.2),
        inset 0 0 0 4px $toolbar-dropdown-bg;
    }
  }
}

.image-preview {
  display: inline-block;
  width: 60px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: $border-radius / 2;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px rgba(white, 0.7),
    inset 0 0 0 2px $toolbar-dropdown-bg;
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: inset 0 0 0 2px rgba(white, 0.2),
      inset 0 0 0 4px $toolbar-dropdown-bg;
  }
}
</style>
