<template>
  <div :style="cssVars">
    <PageHeader title="Packaged Services">
      <template #actions>
        <Button
          v-track="['Packaged Services Modal Opened']"
          type="button"
          variant="neutral"
          size="md"
          @click="addServices"
        >
          <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600" />
          Add From Library
        </Button>
      </template>
    </PageHeader>
    <InfoBox
      title="Sell Packaged Services related to this project"
      icon="icon_v2-so_open-box"
    >
      Add your packaged services and they will appear on the portal dashboard.
    </InfoBox>
    <Form
      v-if="state"
      v-slot="{ isBusy, submit, hasChanges }"
      :state="state"
      success-message="Project changes saved"
      :on-submit="() => save()"
    >
      <div class="flex flex-col max-w-page">
        <!-- Packaged Services -->
        <b-form-group>
          <SlickList
            :value="packagedServices"
            use-drag-handle
            lock-to-container-edges
            axis="xy"
            class="flex flex-wrap gap-16 max-w-page"
            helper-class="so-table-row-dragging"
            @input="reorder"
          >
            <SlickItem
              v-for="(packagedService, index) in packagedServices"
              :key="packagedService._id"
              :index="index"
              class="flex flex-col p-0 overflow-visible card-interactive"
            >
              <ActionsToolbar
                show-delete
                show-drag
                card-drag
                show-edit
                @remove="() => remove(packagedService._id)"
                @edit="() => edit(packagedService._id)"
              >
                <div class="p-24 flex">
                  <PackagedServiceCard :document="packagedService" />
                </div>
              </ActionsToolbar>
            </SlickItem>
          </SlickList>
        </b-form-group>
      </div>
      <PageFooter>
        <Button
          :disabled="isBusy"
          size="lg"
          variant="primary"
          type="submit"
          @click="submit"
        >
          Save Changes
        </Button>
        <template v-if="hasChanges" #right>
          <PageFooterMessage />
        </template>
      </PageFooter>
    </Form>
    <SelectDocumentsModal
      v-if="isModalOpen"
      v-model="isModalOpen"
      :selection.sync="selection"
      title="Add packaged services from your library"
      button-label="Packaged Service"
      :documents="documents"
      @done="updatePackagedServices"
    />
  </div>
</template>
<script>
import { indexBy, pluck } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import Vue from 'vue'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { SlickList, SlickItem } from 'vue-slicksort'
import { useFind } from 'feathers-vuex'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import {
  ORGANIZATION_LIBRARY_PACKAGED_SERVICES,
  ORGANIZATION_LIBRARY_PACKAGED_SERVICE,
} from '@/router/organization/type'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useMsgBoxConfirm } from '@/v2/lib/composition/useMsgBox'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'
import PageFooter from '@/components/PageFooter.vue'
import Form from '@/components/Form.vue'
import Button from '@/components/Button.vue'
import PageHeader from '@/components/PageHeader.vue'
import SelectDocumentsModal from '@/components/SelectDocumentsModal.vue'
import ActionsToolbar from '@/components/ActionsToolbar.vue'
import PackagedServiceCard from '@/components/PackagedServiceCard.vue'
import InfoBox from '@/components/InfoBox.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'


export default defineComponent({
  name: 'ViewProjectSettingsPackagedServices',
  components: {
    PageHeader,
    PageFooter,
    Form,
    Button,
    SlickList,
    SlickItem,
    ActionsToolbar,
    SelectDocumentsModal,
    PackagedServiceCard,
    InfoBox,
    PageFooterMessage,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { routerPush } = useRouter()
    const msgBoxConfirm = useMsgBoxConfirm()

    const {
      state,
      save,
    } = useFormEntityEditor({
      model: 'Project',
      id: computed(() => props.project._id),
      fields: computed(() => ['packagedServices']),
    })

    // CSS VARS NEEDED FOR STYLING THE PREVIEW
    const cssVars = computed(() => ({
      '--cp-accent': state.value.portalAccentColor,
    }))

    const { Document } = Vue.$FeathersVuex.api

    const findParams = computed(() => ({
      query: {
        organization: props.organization._id,
        category: DOCUMENT_CATEGORY.DocumentPackagedService,
        $sort: { cost: 1 },
      },
    }))

    const {
      items: documents,
    } = useFind({
      model: Document,
      params: findParams,
    })

    const documentsIndexed = computed(
      () => indexBy(doc => doc._id, documents.value)
    )

    const packagedServices = computed(
      () => (state.value.packagedServices ?? [])
        .map(docId => documentsIndexed.value[docId])
        .filter(isNotNil)
    )

    const showNoServicesDialog = async () => {
      const ok = await msgBoxConfirm({
        title: 'No Packaged Services',
        size: 'md',
        message: 'No Packaged Services found in your library. Would you like to create one?',
        okLabel: 'Go to Packaged Services',
        okVariant: 'tertiary',
        cancelLabel: 'Close',
      })

      if (ok) {
        routerPush({
          name: ORGANIZATION_LIBRARY_PACKAGED_SERVICES,
        })
      }
    }

    const isModalOpen = ref(false)
    const selection = ref([])

    const openModal = () => {
      selection.value = [...state.value.packagedServices ?? []]
      isModalOpen.value = true
    }

    const addServices = () => {
      if (!documents.value.length) {
        showNoServicesDialog()
        return
      }

      openModal()
    }

    const updatePackagedServices = () => {
      state.value.packagedServices = selection.value
      isModalOpen.value = false
      selection.value = []
    }

    const edit = id => {
      routerPush({
        name: ORGANIZATION_LIBRARY_PACKAGED_SERVICE,
        params: {
          documentId: id,
        },
      })
    }

    const remove = id => {
      const index = state.value.packagedServices.findIndex(({ _id }) => _id === id)
      state.value.packagedServices.splice(index, 1)
    }

    const reorder = items => {
      state.value.packagedServices = pluck('_id', items)
    }

    return {
      state,
      save,
      remove,
      reorder,
      isModalOpen,
      cssVars,
      edit,
      documents,
      packagedServices,
      addServices,
      updatePackagedServices,
      selection,
    }
  },
})
</script>
