
<nav>
  <template v-if="!isPending">
    <SidebarGroup v-if="clients.length">
      <SidebarMenu>
        <SidebarMenuLink
          v-for="client in clients"
          :key="client._id"
          :to="{
            name: ORGANIZATION_CLIENT,
            params: { clientId: client._id },
          }"
          activate-on-child-route
        >
          <SidebarItemBasic :label="client.name">
            <template #icon>
              <span
                class="inline-block w-16 h-16 rounded-full mr-4"
                :style="{
                  backgroundColor: client.mainColor,
                }"
              ></span>
            </template>
          </SidebarItemBasic>
        </SidebarMenuLink>
      </SidebarMenu>
    </SidebarGroup>
    <div v-else class="text-gray-800 opacity-50 flex flex-col items-center">
      <span class="text-64 icon_v2-so_clients" />
      <div>You have no clients</div>
    </div>
  </template>
</nav>
