<script>
import { defineComponent, provide } from '@vue/composition-api'
import { useLicensing } from '@/v2/services/organizations/compositions'

export default defineComponent({
  name: 'Licensing',
  setup(props, context) {
    const { licensing, plan, isPending } = useLicensing()

    provide('licensing', licensing)
    provide('plan', plan)

    return () => (isPending.value ? null : context.slots.default({
      licensing: licensing.value,
      plan: plan.value,
    }))
  },
})
</script>
