<template>
  <b-button
    variant="neutral"
    size="md"
    :class="[
      'space-x-12 w-full',
      'shadow-xs bg-gray',
      'hover:shadow'
    ]"
    @click="auth"
  >
    <SvgGraphic
      width="24px"
      height="24px"
      name="google-logo"
    />
    <span>{{ message }}</span>
  </b-button>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useTracking } from '@/v2/services/tracking/compositions'

/** Renders a button which start the authentication with Google process enabling a user to either
 * sign up or sign in using Google as an identity provider */
export default defineComponent({
  name: 'ButtonGoogleAuth',

  props: {
    message: {
      type: String,
      default: 'Authenticate with Google',
    },
    track: {
      type: Array,
      default: null,
    },
  },
  setup(props) {
    const { trackGuest } = useTracking()

    const auth = async () => {
      if (props.track) {
        await trackGuest(...props.track)
      }
      window.location.href = '/oauth/google'
    }

    return {
      auth,
    }
  },
})
</script>
