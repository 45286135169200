<template>
  <ul class="flex">
    <template v-if="items.length">
      <li
        v-for="(item, index) in visibleItems"
        :key="item.member._id"
        class="flex flex-row items-center"
        :class="{'-ml-10': index > 0}"
      >
        <template v-if="item.user">
          <Avatar
            :profile="item.user.profile$"
            :size="32"
            font-size="14"
          />
        </template>
      </li>
      <li v-if="remainingCount > 0" class="-ml-10">
        <span
          class="
                w-32 h-32 inline-flex bg-white rounded-100
                items-center justify-center font-semibold text-15
              "
        >+{{ remainingCount }}</span>
      </li>
    </template>
  </ul>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import Avatar from '@/components/Blocks/Avatar/Avatar.vue'

const MAX_ITEMS = 5

export default defineComponent({
  name: 'ProjectDashboardPageHeaderMembers',
  components: {
    Avatar,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    maxItems: {
      type: Number,
      default: MAX_ITEMS,
    },
  },
  setup(props) {
    const visibleItems = computed(() => props.items.slice(0, props.maxItems))
    const remainingCount = computed(() => props.items.length - visibleItems.value.length)

    return {
      visibleItems,
      remainingCount,
    }
  },
})
</script>
