<template>
  <FeathersCount
    v-slot="{ count }"
    :query="query"
    :model="model"
    :events="['created', 'patched', ...events]"
  >
    <MainHeaderNavItem
      :label="label"
      :to="to"
      :count="count"
    />
  </FeathersCount>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import FeathersCount from '@/components/FeathersCount.vue'
import MainHeaderNavItem from '@/components/MainHeaderNavItem.vue'

export default defineComponent({
  name: 'MainHeaderNavItemCount',
  components: {
    MainHeaderNavItem,
    FeathersCount,
  },
  props: {
    to: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    query: {
      type: Object,
      required: true,
    },
    events: {
      type: Array,
      default: () => [],
    },
  },
})
</script>
