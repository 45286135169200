
<div :class="$style['content-library-sidebar']">
  <template v-if="currentDocument.isProjectLevel">
    <h4 class="text-gray-400 uppercase dropdown-header text-12">
      Docs &amp; Files
    </h4>
    <div class="space-b-8">
      <ListLightItem
        label="All documents"
        icon="document"
        :document-category="CATEGORY.Document"
        :active="currentSidebarSection === SIDEBAR_SECTIONS.ALL_DOCUMENTS"
        class="dropdown-item"
        :badge="countAllDocs"
        @select="select(CATEGORY.Document, SIDEBAR_SECTIONS.ALL_DOCUMENTS)"
      />
      <ProjectFolderTree
        :value="selectedFolderId"
        :project-id="currentDocument.project"
        @input="selectFolder"
      />
    </div>
  </template>
  <h4 class="text-gray-400 uppercase dropdown-header text-12 pt-16">
    Your library
  </h4>
  <div class="mt-16 space-y-8">
    <ListLightItem
      v-for="item in categoryList"
      :key="item.category"
      :label="CATEGORY_TITLES[item.category] | pluralize(2)"
      :document-category="item.category"
      :active="category === item.category"
      :badge="item.count"
      class="dropdown-item"
      @select="select(item.category, null)"
    />
  </div>
</div>
</div>
