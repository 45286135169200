
<div>
  <ListTable
    v-slot="{ item, index }"
    :items="value"
    :fields="[
      { label: 'Unit', width: '150px' },
      { label: 'Currency', width: '240px' },
      { label: 'Cost', width: '110px' },
      { width: 'auto' },
    ]"
    key-field="id"
    @create="create"
  >
    <BillableFormRateListItem
      :item="item"
      @update="({ key, value }) => update({ index, key, value })"
      @remove="del(index)"
    />
  </ListTable>
</div>
