<template>
  <div>
    <TabsPageHeader :tabs="tabs" />
    <router-view
      :organization="organization"
      :licensing="licensing"
    />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import TabsPageHeader from '@/components/TabsPageHeader.vue'

import {
  ORGANIZATION_SETTINGS_PORTAL_SETTINGS,
  ORGANIZATION_SETTINGS_PORTAL_LOGIN_THEME,
  ORGANIZATION_SETTINGS_PORTAL_SEO,
} from '@/router/organization/type'

const tabs = [
  {
    id: 'portal-settings',
    label: 'General Settings',
    icon: 'icon_v2-so_filter-eq',
    to: { name: ORGANIZATION_SETTINGS_PORTAL_SETTINGS },
  },
  {
    id: 'portal-login-theme',
    label: 'Login Theme',
    icon: 'icon_v2-so_filter-home',
    to: { name: ORGANIZATION_SETTINGS_PORTAL_LOGIN_THEME },
  },
  {
    id: 'portal-seo',
    label: 'SEO Optimization',
    icon: 'icon_v2-so_training',
    to: { name: ORGANIZATION_SETTINGS_PORTAL_SEO },
  },
]

export default defineComponent({
  name: 'ViewOrganizationSettingsPortal',
  components: {
    TabsPageHeader,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    licensing: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      tabs,
    }
  },
})
</script>
