<template>
  <div
    id="app"
    class="w-full"
    :class="sidebarIsExpanded ? 'sidebar-expanded' : 'sidebar-collapsed'"
  >
    <ConnectionStatus>
      <AppController v-if="routeReady" />
    </ConnectionStatus>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useRoute } from '@/v2/lib/composition/useRouter'
import ConnectionStatus from '@/components/ConnectionStatus.vue'
import AppController from '@/components/AppController.vue'


const { useState } = createNamespacedHelpers('globalFlags')

export default {
  name: 'App',
  components: {
    ConnectionStatus,
    AppController,
  },
  setup() {
    const { sidebarIsExpanded } = useState(['sidebarIsExpanded'])
    const { routeReady } = useRoute({ watchRouteReady: true })

    return {
      sidebarIsExpanded,
      routeReady,
    }
  },
}
</script>
