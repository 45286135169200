<template>
  <ModalBasic
    :should-close-on-backdrop="false"
    :hide-footer="true"
    :dialog-class="$style['modal-dialog-manage-access']"
    content-class="modal-content-tertiary"
    :body-class="$style['modal-body-link']"
    :header-class="$style['modal-header-link']"
    footer-class="modal-footer-tertiary"
    title-class="!text-left"
    size="md"
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #body="{ close }">
      <div class="mb-16">
        <p v-if="description" class="settings-section-description">
          {{ description }}
        </p>
      </div>
      <b-form-group class="mb-8 relative">
        <!-- url -->
        <b-input
          v-model="keyword"
          type="search"
          placeholder="Type a name"
          class="rounded-md h-40 text-search pr-32"
        />
        <span class="icon-util-search text-18 text-gray-400 absolute right-8 top-12"></span>
      </b-form-group>
      <div v-if="items.length" class="inline-flex space-x-8 mt-8">
        <b-link
          class="text-green-500 underline hover:no-underline"
          @click="selectAll"
        >
          Select everyone
        </b-link>
        <b-link
          class="text-green-500 underline hover:no-underline"
          @click="selectNone"
        >
          Select no one
        </b-link>
      </div>
      <p
        v-if="isSearchActive && !filteredItems.length"
        class="settings-section-description mt-16"
      >
        No people found based on the search keyword provided.
      </p>
      <TeamMemberList
        v-model="state"
        selectable
        :items="filteredItems"
        class="mt-24"
        @submit="onSubmit(close)"
      />
    </template>
  </ModalBasic>
</template>
<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import TeamMemberList from '@/components/TeamMemberList.vue'

export default defineComponent({
  name: 'ProjectSettingsQuickLinkModal',
  components: {
    ModalBasic,
    TeamMemberList,
  },
  props: {
    model: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const state = ref([...props.model])

    const keyword = ref('')
    const isSearchActive = computed(() => Boolean(keyword.value.length));

    const onSubmit = close => {
      context.emit('update:model', [...state.value]);
      close()
    }

    const filterFn = ({ user }) => {
      const kw = keyword.value.toLowerCase()
      const fullName = (user?.profile$?.fullName ?? '').toLowerCase();

      if (!kw.length || !fullName) {
        return true
      }

      if (kw.length < 3) {
        return fullName.startsWith(kw)
      }

      return fullName.includes(kw)
    }

    const filteredItems = computed(() => props.items.filter(filterFn));

    const selectAll = () => {
      state.value = filteredItems.value.map(item => item.member._id)
    }

    const selectNone = () => {
      state.value = []
    }

    return {
      state,
      filteredItems,
      onSubmit,
      selectAll,
      selectNone,
      keyword,
      isSearchActive,
    }
  },
})
</script>
<style lang="scss" module>
.modal-header-link {
  @apply min-h-0 pt-32 pb-0 mb-0;
}
.modal-body-link {
  @apply pt-24 pb-24 z-0;
}
.modal-title-link {
  @apply text-left;
}
.modal-dialog-manage-access {
  &:global(.modal-dialog) {
      max-width: 580px;
  }
}
</style>
