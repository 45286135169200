
<div>
  <PortalRender to="app-sidebar" :provide="{ project }">
    <ProjectNav
      :organization="organization"
      :project="project"
      :folders="folders"
      :routes="navRoutes"
    />
  </PortalRender>
  <router-view
    :organization="organization"
    :project="project"
  />
</div>
