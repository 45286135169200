<template>
  <OrganizationGet
    :id="organizationId"
    v-slot="{ organization, isGetPending: isOrganizationGetPending }"
  >
    <div class="flex flex-col w-full">
      <div
        v-if="!isOrganizationGetPending"
        class="flex flex-col mx-auto lg:pb-48 max-w-[554px] md:py-16 px-16 md:px-32"
      >
        <!-- Welcome message -->
        <div class="mb-36 text-center">
          <h1 class="font-semibold text-gray-900 text-18 md:text-24">
            Let's continue with your details
          </h1>
        </div>

        <FormMessage v-if="error">
          {{ error }}
        </FormMessage>
        <SignUpFormOrganization
          :profile="profile"
          :user="user"
          :organization="organization"
          :is-loading="isLoading"
          @submit="onSubmit"
        />
      </div>
    </div>
  </OrganizationGet>
</template>

<script>
import { equals } from 'ramda'
import { defineComponent, ref } from '@vue/composition-api'
import { nanoid } from 'nanoid'
import { isNotNilOrEmpty } from 'ramda-adjunct'
import { rejectNil } from 'ramda-extension'
import { useRouteParams, useRouter } from '@/v2/lib/composition/useRouter'
import FormMessage from '@/components/FormMessage.vue'
import SignUpFormOrganization from '@/components/SignUpFormOrganization.vue'
import { useUser } from '@/v2/services/users/usersCompositions'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import { useCurrentProfile } from '@/v2/services/profiles/profilesCompositions'
import { getUrlInfo } from '@/v2/services/urlInfo/urlInfoCompositions'
import { rgbToHex, rootDomainFromEmail, websiteFromEmail } from '@/helpers/branding-helper'
import { commonDomains } from '@/types/common-domains'
import { ONBOARDING_BRANDING } from '@/router/welcome/type'
import OrganizationGet from '@/components/OrganizationGet.vue'

const isEnabled = equals('1')

export default defineComponent({
  name: 'ViewSignUpOrganizationDetails',
  metaInfo: {
    titleTemplate: 'Organization Details - %s',
  },
  components: {
    FormMessage,
    SignUpFormOrganization,
    OrganizationGet,
  },
  setup() {
    const { user } = useUser()
    const { profile } = useCurrentProfile()
    const organization = useCurrentOrganization()
    const { organizationId } = useRouteParams(['organizationId'])
    const { routerPush } = useRouter()
    const userUrlData = ref(null)
    const isColorPickEnabled = isEnabled(import.meta.env.VITE_COLOR_PICK)

    const error = ref(null)
    const isLoading = ref(false)

    const onSubmit = async formData => {
      if (isLoading.value) return
      isLoading.value = true

      try {
        userUrlData.value = isColorPickEnabled && await getUrlInfo(
          websiteFromEmail(user.value.email),
          ['favicon', 'faviconColorPalette']
        )

        // Patch `organizations` with new profile data
        // + logos from favicon
        // + colors from favicon
        await organization.value.patch({
          data:
             rejectNil({
               ...formData.organization,
               logoUrl: !commonDomains.includes(rootDomainFromEmail(user.value.email))
                && isNotNilOrEmpty(userUrlData.value.favicon)
                 ? userUrlData.value.favicon
                 : null,
               logoDarkUrl: !commonDomains.includes(rootDomainFromEmail(user.value.email))
                && isNotNilOrEmpty(userUrlData.value.favicon)
                 ? userUrlData.value.favicon
                 : null,
               brandingColors: !commonDomains.includes(rootDomainFromEmail(user.value.email))
                && isNotNilOrEmpty(userUrlData.value.faviconColorPalette)
                 ? [
                   {
                     color: rgbToHex(
                       userUrlData.value.faviconColorPalette[0][0],
                       userUrlData.value.faviconColorPalette[0][1],
                       userUrlData.value.faviconColorPalette[0][2]
                     ),
                     id: nanoid(),
                   },
                   {
                     color: rgbToHex(
                       userUrlData.value.faviconColorPalette[1][0],
                       userUrlData.value.faviconColorPalette[1][1],
                       userUrlData.value.faviconColorPalette[1][2]
                     ),
                     id: nanoid(),
                   },
                   {
                     color: rgbToHex(
                       userUrlData.value.faviconColorPalette[2][0],
                       userUrlData.value.faviconColorPalette[2][1],
                       userUrlData.value.faviconColorPalette[2][2]
                     ),
                     id: nanoid(),
                   },
                   {
                     color: rgbToHex(
                       userUrlData.value.faviconColorPalette[3][0],
                       userUrlData.value.faviconColorPalette[3][1],
                       userUrlData.value.faviconColorPalette[3][2]
                     ),
                     id: nanoid(),
                   },
                   {
                     color: rgbToHex(
                       userUrlData.value.faviconColorPalette[4][0],
                       userUrlData.value.faviconColorPalette[4][1],
                       userUrlData.value.faviconColorPalette[4][2]
                     ),
                     id: nanoid(),
                   },
                 ]
                 : null,
             }),
        })
        routerPush({
          name: ONBOARDING_BRANDING,
          params: { organizationId: organization.value._id },
        })
      } catch (err) {
        error.value = err.message
        isLoading.value = false
      } finally {
        // isLoading.value = false
      }
    }

    return {
      onSubmit,
      error,
      organizationId,
      profile,
      user,
      isLoading,
      userUrlData,
      isColorPickEnabled,
    }
  },
})
</script>
