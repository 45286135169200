
<div v-fragment>
  <!-- Button Add Before -->
  <div v-if="!isSelected && showCreateBefore" class="block-add add-top">
    <DocumentBlockButtonCreateMenu
      :parent-node-id="parent"
      :index="index"
      :accepts="accepts"
      location="before"
      @visibility-change="v => $emit('menu-visibility-change', v)"
    />
  </div>
  <!-- end of Button Add Before -->
  <!-- Button Add After -->
  <div v-if="!isSelected && showCreateBefore" class="block-add add-bottom">
    <DocumentBlockButtonCreateMenu
      :parent-node-id="parent"
      :index="index"
      :accepts="accepts"
      location="after"
      @visibility-change="v => $emit('menu-visibility-change', v)"
    />
  </div>
  <!-- end of Button Add After -->

  <!-- Other Node Editor Actions -->
  <div
    v-if="!isGroupColumn"
    class="block-actions"
    :class="isFocus && 'is-focus'"
  >
    <!-- Left Hand Actions -->
    <!-- Multiple Selection Handle -->
    <div
      v-if="!isSpecificEmbed && sameSelectedNodeParent"
      class="block-actions-select"
      @click.stop
    >
      <b-form-checkbox
        v-b-tooltip.hover.top.v-info.dh0.ds200
        :checked="isSelected"
        :title="isSelected ? 'Remove from selection' : 'Add to selection'"
        size="lg"
        class="custom-checkbox-dark"
        @change="isSelected => onCheckboxToggle(isSelected)"
      />
    </div>

    <!-- Drag -->
    <div
      class="block-actions-move"
    >
      <div
        v-if="!isSelected"
        class="block-actions-move-button"
      >
        <button
          v-handle
          v-b-tooltip.hover.top.v-info.dh0.ds200
          class="
            w-32 h-32 flex items-center justify-center
            hover:bg-gray-500 hover:bg-opacity-10 outline-none focus:outline-none
          "
          title="Drag to move"
          @click.stop
        >
          <span class="icon_v2-so_drag so-drag-icon" />
        </button>
      </div>
    </div>

    <!-- Move Up -->
    <button
      v-if="showMoveUp"
      v-b-tooltip.hover.top.v-info.dh0.ds200
      :title="`Move Block Up (${$mac ? 'CMD' : 'CTRL'} + ⬆️)`"
      class="
        flex items-center justify-center w-32 h-32 outline-none
        hover:bg-gray-500 hover:bg-opacity-10 focus:outline-none
      "
      @click.stop="$emit('move-up')"
    >
      <span class="w-16 text-gray-200 text-16 icon_v2-so_arrow-up"></span>
    </button>

    <!-- Move Down -->
    <button
      v-if="showMoveDown"
      v-b-tooltip.hover.top.v-info.dh0.ds200
      :title="`Move Block Down (${$mac ? 'CMD' : 'CTRL'} + ⬇️)`"
      class="
        flex items-center justify-center w-32 h-32 outline-none
        hover:bg-gray-500 hover:bg-opacity-10 focus:outline-none
      "
      @click.stop="$emit('move-down')"
    >
      <span class="w-16 text-gray-200 text-16 icon_v2-so_arrow-down"></span>
    </button>

    <!-- Comments -->
    <portal-target
      :name="`comments-${node._id}`"
      slim
    />

    <!-- Duplicate -->
    <button
      v-if="!isSpecificEmbed && !isSelected"
      v-b-tooltip.hover.top.v-info.dh0.ds200
      title="Duplicate Block"
      class="
        flex items-center justify-center w-32 h-32 outline-none
        hover:bg-gray-500 hover:bg-opacity-10 focus:outline-none
      "
      :disabled="node.hasEmbeddedDocument"
      @click.stop="$emit('duplicate')"
    >
      <span class="w-16 text-gray-200 text-16 icon_v2-so_copy-done"></span>
    </button>

    <!-- Delete -->
    <button
      v-if="!isSelected"
      v-b-tooltip.hover.top.v-info.dh0.ds200
      :title="`Delete Block (${$mac ? 'CMD' : 'CTRL'} + ⬅️)`"
      class="
        flex items-center justify-center w-32 h-32 outline-none
        hover:bg-gray-500 hover:bg-opacity-10 focus:outline-none
      "
      @click.stop="$emit('remove')"
    >
      <span class="w-16 text-gray-200 text-16 icon_v2-so_trash"></span>
    </button>
  </div>
</div>
