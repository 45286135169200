<template>
  <ProjectPermissions
    v-slot="{ permissions }"
    :project="project"
  >
    <DocActions
      v-slot="{
        update,
        remove,
        move,
        duplicate,
        share,
        saveAsReusableBlock,
        download,
        changeFonts,
      }"
      :document="document"
    >
      <DocRenderer :document="document">
        <template #breadcrumbs>
          <DocBreadcrumbs :document="document" :project="project" />
        </template>
        <template #actions>
          <DocMenu
            :document="document"
            :active-items="{
              share: true,
              remove: permissions.remove,
              download: true,
              move: permissions.create && permissions.remove,
              duplicate: permissions.create,
              saveAsReusableBlock: permissions.create,
              changeFonts: permissions.update,
            }"
            @remove="remove"
            @move="move"
            @duplicate="duplicate"
            @share="share"
            @download="download"
            @save-as-reusable-block="saveAsReusableBlock"
            @change-fonts="changeFonts"
          />
        </template>
        <template #header>
          <DocTitle
            :disabled="!permissions.update"
            :document="document"
            @update="title => update({ title })"
          />
          <DocSharedBadge :document="document" @click="share" />
        </template>

        <template #footer-left>
          <DocUpdatedBy :document="document" />
        </template>
        <template #footer-right>
          <DocPreviewToggle
            v-if="permissions.create"
            :document="document"
          />
          <DocStatusToggle
            :value="document.status"
            data-intercom-target="Document Status Select"
            @input="status => update({ status })"
          />
        </template>

        <template #content>
          <DocumentEditorContent
            v-if="document"
            :document="document"
            :read-only="!permissions.update"
          />
          <DocApprovalBadge :document="document" />
        </template>
        <template #sidebar-right>
          <DocSidebar :document="document" />
        </template>
      </DocRenderer>
    </DocActions>
  </ProjectPermissions>
</template>
<script>
import { defineComponent, inject } from '@vue/composition-api'
import DocumentEditorContent from '@/components/Document/Editor/DocumentEditorContent.vue'
import ProjectTheme from '@/components/ProjectTheme.vue'
import DocApprovalBadge from '@/components/Doc/DocApprovalBadge.vue'
import DocBreadcrumbs from '@/components/Doc/DocBreadcrumbs.vue'
import DocMenu from '@/components/Doc/DocMenu.vue'
import DocRenderer from '@/components/Doc/DocRenderer.vue'
import DocTitle from '@/components/Doc/DocTitle.vue'
import DocUpdatedBy from '@/components/Doc/DocUpdatedBy.vue'
import DocPreviewToggle from '@/components/Doc/DocPreviewToggle.vue'
import DocStatusToggle from '@/components/Doc/DocStatusToggle.vue'
import DocActions from '@/components/Doc/DocActions.vue'
import DocSidebar from '@/components/Doc/DocSidebar.vue'
import DocSharedBadge from '@/components/Doc/DocSharedBadge.vue'
import ProjectPermissions from '@/components/ProjectPermissions.vue'

export default defineComponent({
  name: 'DocumentEditorDefault',

  components: {
    DocumentEditorContent,
    ProjectTheme,
    DocApprovalBadge,
    DocBreadcrumbs,
    DocMenu,
    DocRenderer,
    DocTitle,
    DocUpdatedBy,
    DocPreviewToggle,
    DocStatusToggle,
    DocActions,
    DocSidebar,
    DocSharedBadge,
    ProjectPermissions,
  },
  props: {
    document: {
      type: Object,
      default: () => ({}),
    },

  },
  setup() {
    const project = inject('project')

    return {
      project,
    }
  },
})
</script>
