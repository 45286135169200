<template>
  <ProjectTheme :project="project" no-dark-mode>
    <ProjectPermissions
      v-slot="{ permissions }"
      :project="project"
    >
      <DocRenderer :document="document">
        <template #breadcrumbs>
          <DocBreadcrumbs :document="document" :project="project" />
        </template>
        <template #actions>
          <DocFolderViewOptions
            :value="viewContext"
            @input="value => $emit('update-view-context', value)"
          />
          <DocCreateButton v-if="permissions.create" />
        </template>
        <template #header>
          <DocTitleReadonly :project="project" />
        </template>

        <template #content>
          <div v-if="document" v-fragment>
            <FolderContentList
              :document="document"
              :view-context="viewContext"
              :permissions="permissions"
            />
            <RecentDocuments
              v-if="recentlyUpdatedModule.enabled"
              :project="project"
            />
          </div>
        </template>
        <template #info-panel>
          <InfoPanel>
            <template #icon>
              <span class="icon_v2-so_docs-files text-40"></span>
            </template>
            <template #title>This is your Docs &amp; Files main folder</template>
            <template #description>
              <div class="flex flex-col gap-4">
                <p class="leading-none">
                  Only the contents of the
                  <strong
                    class="
                    inline-flex items-center px-6 py-3 font-medium leading-none
                    bg-green-200 rounded-md text-green-900
                  "
                  >
                    <span class="icon_v2-so_shared mr-4" />
                    Shared
                  </strong>
                  folder is visible to clients. Anything outside the <strong>Shared</strong> folder
                  is only visible internally to the members of your organization.
                </p>
                <a
                  href="https://help.superokay.com/en/collections/3548550-docs-files"
                  title="learn more about the Shared folder"
                  class="underline mt-8"
                >Learn more</a>
              </div>
            </template>
          </InfoPanel>
        </template>
      </DocRenderer>
    </ProjectPermissions>
  </ProjectTheme>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import { useProjectModule } from '@/v2/services/projects/compositions'
import FolderContentList from '@/components/FolderContentList.vue'
import RecentDocuments from '@/components/RecentDocuments.vue'
import InfoPanel from '@/components/InfoPanel.vue'
import ProjectTheme from '@/components/ProjectTheme.vue'
import DocBreadcrumbs from '@/components/Doc/DocBreadcrumbs.vue'
import DocCreateButton from '@/components/Doc/DocCreateButton.vue'
import DocFolderViewOptions from '@/components/Doc/DocFolderViewOptions.vue'
import DocRenderer from '@/components/Doc/DocRenderer.vue'
import DocTitleReadonly from '@/components/Doc/DocTitleReadonly.vue'
import ProjectPermissions from '@/components/ProjectPermissions.vue'

export default defineComponent({
  name: 'FSFolderRoot',
  components: {
    FolderContentList,
    RecentDocuments,
    InfoPanel,
    ProjectTheme,
    DocBreadcrumbs,
    DocCreateButton,
    DocFolderViewOptions,
    DocRenderer,
    DocTitleReadonly,
    ProjectPermissions,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    viewContext: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const recentlyUpdatedModule = useProjectModule(computed(() => props.project), 'recentlyUpdated')

    return {
      recentlyUpdatedModule,
    }
  },
})
</script>
