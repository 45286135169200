<template>
  <div>
    <ButtonFilter
      ref="buttonRef"
      :active="isPopoverVisible"
      :count="selectedTagCount"
    />
    <b-popover
      v-if="buttonRef"
      :target="buttonRef"
      triggers="click blur"
      placement="bottom"
      custom-class="w-320 shadow-2xl border-gray-300 rounded-lg"
      boundary="window"
    >
      <ProjectSwitcherTags
        :tags="tags"
        :value="value"
        @input="val => $emit('input', val)"
      />
    </b-popover>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import ProjectSwitcherTags from '@/components/ProjectSwitcherTags.vue'
import ButtonFilter from './ButtonFilter.vue'

export default defineComponent({
  name: 'OrganizationProjectsPageHeaderTags',
  components: {
    ProjectSwitcherTags,
    ButtonFilter,
  },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    selectedTagCount: {
      type: Number,
      default: null,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const buttonRef = ref(null)
    const isPopoverVisible = ref(false)

    return {
      buttonRef,
      isPopoverVisible,
    }
  },
})
</script>
