<template>
  <div class="flex flex-col items-center lg:justify-center height-pass w-full">
    <CardWhiteRounded class-name="max-w-form w-full mt-48 p-32">
      <RecoverPasswordFormRequest v-if="!isSuccess" @success="isSuccess = true" />
      <div v-else class="flex flex-col items-center justify-center text-center">
        <span class="w-48 my-32 text-green-600 icon_v2-so_tick text-48"></span>
        <h2 class="title-h2">Great!</h2>
        <p class="text-gray-700">
          We have sent you an email with a link to reset your password. Please
          check your inbox (even the spam folder)
        </p>
      </div>
    </CardWhiteRounded>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import CardWhiteRounded from '@/components/CardWhiteRounded.vue'
import RecoverPasswordFormRequest from '@/components/RecoverPasswordFormRequest.vue'

export default defineComponent({
  name: 'ViewGuestRecoverPasswordRequest',
  metaInfo: {
    title: 'Recover Password - SuperOkay',
  },
  components: {
    CardWhiteRounded,
    RecoverPasswordFormRequest,
  },
  setup() {
    const isSuccess = ref(false)

    return {
      isSuccess,
    }
  },
})
</script>
<style lang="scss" module>
.rec-pass {
  height: calc(100vh - 76px);
  color: red;
}
</style>
