<template>
  <ModalSidebar
    :no-close="false"
    :hide-footer="true"
    :hide-sidebar="hideSidebar"
    body-class="bg-page"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #sidebar>
      <ContentLibrarySidebar
        :category="category"
        :selected-folder-id="selectedFolderId"
        @update:category="category => $emit('update:category', category)"
        @folder-selected="selectFolder"
      />
    </template>
    <template #body>
      <ContentLibrary
        :category="category"
        :title="title"
        :parent-folder-id="selectedFolderId"
        @select="doc => $emit('select', doc)"
      />
    </template>
  </ModalSidebar>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import ModalSidebar from '@/components/Modal/ModalSidebar.vue'
import ContentLibrarySidebar from '@/components/ContentLibrary/ContentLibrarySidebar.vue'
import ContentLibrary from '@/components/ContentLibrary/ContentLibrary.vue'

export default defineComponent({
  name: 'ContentLibraryModalBlock',
  components: {
    ModalSidebar,
    ContentLibrarySidebar,
    ContentLibrary,
  },
  props: {
    category: String,
    title: String,
    hideSidebar: Boolean,
  },
  setup() {
    const selectedFolderId = ref(null)
    const selectFolder = folderId => { selectedFolderId.value = folderId }

    return {
      selectedFolderId,
      selectFolder,
    }
  },
})
</script>
