<template>
  <!-- FOR EACH CLIENT CONTACT -->
  <li
    :key="contact.member._id"
    class="
      flex flex-col
      w-full
      py-8
      border-b border-gray-100
      lg:items-center lg:flex-row
      flex-wrap
    "
  >
    <div class="flex-1">
      <!-- AVATAR -->
      <Avatar show-name :profile="contact.user?.profile$" />
      <!-- NAME + EMAIL -->
      <div
        class="flex flex-col text-gray-500 text-13 pl-[28px] -mt-5"
      >
        <div>{{ contact.user?.email ?? '' }}</div>
        <p
          v-if="contact.member.lastAccess"
          class="text-11 text-gray-300 font-medium"
        >
          <span>Last login:&nbsp;</span>
          <LiveFilter
            filter="timeago"
            locale="en-US"
            :value="contact.member.lastAccess"
          />
        </p>
      </div>
    </div>
    <!-- EDIT BUTTON -->
    <div
      class="
        flex flex-1
        items-center
        space-x-8
        md:justify-end
        pl-[28px]
        md:pl-0
        mt-8
        md:mt-0
      "
    >
      <b-button
        v-b-tooltip.hover="{ customClass: 'text-12' }"
        variant="dark"
        size="xs"
        class="flex-shrink-0 mt-auto"
        title="Email client contact with portal access link"
        @click="$emit('send-invite', contact)"
      >
        <span
          class="w-16 mr-6 opacity-50 icon-paper-plane text-16"
        ></span>
        <span>Email Link</span>
      </b-button>
      <b-button
        v-b-tooltip.hover="{ customClass: 'text-12' }"
        variant="dark"
        size="xs"
        class="flex-shrink-0 mt-auto"
        title="Generate a link for client portal access"
        @click="$emit('request-magic-link-token', contact)"
      >
        <span class="w-16 mr-6 opacity-50 icon-link text-16"></span>
        <span>Create Link</span>
      </b-button>
      <b-button
        variant="neutral"
        size="xs"
        class="flex-shrink-0 mt-auto text-gray-600"
        @click="$emit('edit', contact)"
      >
        <span class="w-12 mr-6 opacity-50 icon_v2-so_pencil text-12"></span>
        <span class="text-gray-500">Edit</span>
      </b-button>
      <b-button
        variant="reset"
        size="xs"
        class="flex-shrink-0 text-gray-600 px-0"
        @click="$emit('remove', contact)"
      >
        <span class="opacity-50 icon_v2-so_trash text-18"></span>
      </b-button>
    </div>
    <div class="basis-full">
      <div class="ml-28 pt-8">
        <MemberAssignedProjects
          v-if="contact.projects?.length"
          :projects="contact.projects"
        />
        <div v-else class="text-gray-400 text-12">
          Not added to any projects
        </div>
      </div>
    </div>
  </li>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import Avatar from '@/components/Blocks/Avatar/Avatar.vue'
import LiveFilter from '@/components/LiveFilter.vue'
import MemberAssignedProjects from '@/components/MemberAssignedProjects.vue'

export default defineComponent({
  name: 'ClientContact',
  components: {
    Avatar,
    LiveFilter,
    MemberAssignedProjects,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
})
</script>
