
<div v-fragment>
  <template v-if="!isEmpty">
    <!-- <transition-group name="node-list" tag="div"> -->
    <SlickList
      v-model="nodes"
      lock-axis="y"
      use-drag-handle
      use-custom-container
      custom-container-id="appScrollContainer"
      helper-class="so-node-dragging"
      :custom-container-offset-y="73"
      @sort-start="handleSortStart"
      @sort-end="handleSortEnd"
      class="w-full"
    >
      <SlickItem
        v-for="(nodeId, index) in nodes"
        :key="nodeId"
        :index="index"
        tag="div"
        class="so-slick-item"
      >
        <DocumentNodeEditable
          :node-id="nodeId"
          parent="root"
          :index="index"
          :is-first="index === 0"
          :is-last="index === children.length - 1"
          class="so-slick-inner"
        />
      </SlickItem>
    </Slicklist>
    <!-- </transition-group> -->
  </template>
  <DocumentNodeGroupRootPlaceholder v-else />
</div>
