
<div>
  <PageHeader title="Billable Services">
    <template #actions>
      <b-button
        v-track="['New Billable Service Modal Opened']"
        variant="neutral"
        size="md"
        @click="create"
      >
        <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600"></span>
        <span>New Billable Service</span>
      </b-button>
    </template>
  </PageHeader>
  <FeathersVuexFind
    v-slot="{ items: billables, isFindPending }"
    service="billables"
    :params="findParams"
  >
    <div v-if="!isFindPending" class="max-w-page">
      <template v-if="billables.length">
        <p class="mb-24 text-gray-500 text-14">
          Changing a billable services will not automatically update
          documents it's already been added to.
        </p>

        <ListTable
          v-slot="{ item: billable }"
          :items="billables"
          :fields="[
            { label: 'Name' },
            { label: 'Rates', labelAlign: 'right', width: '100px' },
            { width: '100px' },
            { width: '0' },
          ]"
        >
          <ListTableCell>
            <div class="flex items-center justify-between">
              {{ billable.name }}
              <Badge
                v-if="!billable.isBillable"
                variant="neutral"
                class="ml-3"
              >
                Not billable
              </Badge>
            </div>
          </ListTableCell>
          <ListTableCell class-name="rates">
            <div class="text-right">
              {{ billable.rates ? billable.rates.length : 0 }} rates
            </div>
          </ListTableCell>
          <ListTableCell class-name="edit-cell">
            <div :class="$style.edit">
              <b-button
                variant="neutral"
                size="sm"
                @click="edit(billable._id)"
              >
                Edit
              </b-button>
            </div>
          </ListTableCell>
          <ListTableCell class-name="delete-item relative">
            <div class="absolute -right-24 top-12">
              <b-button
                variant="delete"
                class="relative invisible w-24 h-24 p-0 opacity-0 left-4"
                @click="remove(billable)"
              >
                <span class="w-16 icon_v2-so_trash text-16"></span>
              </b-button>
            </div>
          </ListTableCell>
        </ListTable>
      </template>
      <PlaceholderCreate
        v-else
        button-label="Create your first Case Study"
        icon="bill"
        class="container"
      >
        <template #title>
          Billable Services
        </template>
        <template
          #description
        >
          Here is where you can create and save pre-formatted Billable Services
          you could easily insert in your Cost Tables when you prepare
          an Estimate or other type of document.
        </template>
      </PlaceholderCreate>
    </div>
  </FeathersVuexFind>
  <BillableFormModal
    v-if="isEditorOpen"
    v-model="isEditorOpen"
    :billable-id="billableId"
    :organization-id="organization._id"
    @save="closeEditor"
  />
</div>
