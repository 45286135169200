
<div>
  <LibraryDocumentPageHeader
    title="Reusable Blocks"
    label="Reusable Block"
    :document-category="category"
  />
  <div class="project-page">
    <div class="project-page-wrapper">
      <DocumentList
        :organization-id="organization._id"
        :category="category"
        :auto-margin="false"
      >
        <template #list="{ documents, open, remove }">
          <div class="mt-24 space-y-16 max-w-page">
            <FileDocList
              v-for="document in documents"
              :key="document._id"
              :document="document"
              standalone
              :show-analytics="false"
              @open="open(document)"
              @remove="remove(document)"
            />
          </div>
        </template>
        <template #placeholder>
          <PlaceholderCreate
            :document-category="category"
            button-label="Create your first Reusable Block"
          >
            <template #title>Create once, reuse everywhere</template>
            <template #description>
              Save your common document bits &amp; pieces in one central library
              so your entire team can find what they need when they need it.
            </template>
          </PlaceholderCreate>
        </template>
      </DocumentList>
    </div>
  </div>
</div>
