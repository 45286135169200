<template>
  <div class="text-12 flex items-center mb-8 text-white">
    <div v-if="label" class="text-white w-88">{{ label }}</div>
    <input
      v-bind="$attrs"
      type="range"
      :min="min"
      :max="max"
      :step="step"
      :value="value"
      :style="{ backgroundSize: backgroundSize }"
      @input="updateSlider"
    />
    <div class="w-60 text-right text-gray-400 select-none">{{ value }}</div>
  </div>
</template>

<script>
import { getCurrentInstance, ref } from '@vue/composition-api'

export default {
  name: 'DocumentNodeEditorToolbarSlider',
  props: {
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 8,
    },
    label: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const initialStep = (props.value * 100) / (props.max + props.min)
    const vm = getCurrentInstance().proxy
    const backgroundSize = ref(`${initialStep}% 100% `)
    const updateSlider = e => {
      const clickedElement = e.target;
      const min = Number(clickedElement.min);
      const max = Number(clickedElement.max);
      const val = Number(clickedElement.value)

      backgroundSize.value = `${((val - min) * 100) / (max - min)}% 100%`
      vm.$emit('input', Number(clickedElement.value))
    }
    return {
      backgroundSize,
      updateSlider,
    }
  },
}
</script>

<style lang="postcss" scoped>
/* Slider CSS */

input[type='range'] {
  @apply m-0 w-full block appearance-none;
  background: theme('colors.gray.400');
  background-image: linear-gradient(
    to right,
    theme('colors.green.600') 0%,
    theme('colors.green.200') 100%
  );
  background-repeat: no-repeat;
  border-radius: 2px;
  height: 2px;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius: 0px;
  border: none;
}
input[type='range']::-webkit-slider-thumb {
  border: 2px solid theme('colors.gray.800');
  @apply bg-green-600 rounded-full h-20 w-20 shadow-none cursor-pointer appearance-none -mt-9;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: transparent;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius: 0px;
  border: none;
}
input[type='range']::-moz-range-thumb {
  box-shadow: none;
  border: 2px solid theme('colors.gray.800');
  height: 20px;
  width: 20px;
  border-radius: 7px;
  background: theme('colors.green.600');
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
input[type='range']::-ms-fill-upper {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
input[type='range']::-ms-thumb {
  box-shadow: none;
  border: 2px solid theme('colors.gray.800');
  height: 20px;
  width: 20px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
  height: 2px;
}
input[type='range']:focus::-ms-fill-lower {
  background: transparent;
}
input[type='range']:focus::-ms-fill-upper {
  background: transparent;
}
/* End Range Slider */
</style>
