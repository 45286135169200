<template>
  <DocumentNodeEditorToolbarPopoverLight
    :width="280"
    :target-id="componentID"
    triggers="click blur"
    tooltip="Select color"
    slim
  >
    <template #button-content>
      <button
        :class="[
          $style.button,
          cssClassButton,
          'h-30 w-30 rounded-full relative inline-block',
        ]"
        :style="{ backgroundColor: value }"
      >
        <span class="absolute inset-0 opacity-20 z-1" />
        <span :class="[$style['toolbar-dropdown']]" />
      </button>
    </template>

    <template #default>
      <section class="flex flex-col pb-16">
        <!-- <div class="mt-16 mx-16">
          <b-link
            class="underline hover:no-underline text-gray-700 hover:text-gray-900"
            @click.stop="$emit('input','')"
          >
            Reset
          </b-link>
        </div> -->
        <ColorPicker
          :value="value"
          @input="v => pickColor(v)"
        />
        <ColorPickerPresets
          :selected-value="value"
          :presets="presets"
          @pick-color-from-presets="v => pickColor(v)"
        />
      </section>
    </template>
  </DocumentNodeEditorToolbarPopoverLight>
</template>
<script>
import { computed, defineComponent, getCurrentInstance, ref } from '@vue/composition-api'
import ColorPicker from '@/components/ColorPicker.vue'
import ColorPickerPresets from '@/components/ColorPickerPresets.vue'
import DocumentNodeEditorToolbarPopoverLight from './DocumentNodeEditorToolbarPopoverLight.vue'


export default defineComponent({
  name: 'DocumentNodeEditorToolbarColorPalette',

  components: {
    DocumentNodeEditorToolbarPopoverLight,
    ColorPicker,
    ColorPickerPresets,
  },

  props: {
    value: {
      type: [String, Object],
      default: '',
    },
    presets: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    colorVariant: {
      type: String,
      default: '',
    },
    heading: {
      type: String,
      default: '',
    },
  },

  setup(props, context) {
    const vm = getCurrentInstance().proxy
    const cssClassButton = computed(() => {
      if (!props.value) {
        return props.colorVariant
          ? [`variant-${props.colorVariant}-button`]
          : ['button-transparent']
      }
      return null
    })
    const activeColor = ref(props.value)

    const pickColor = v => {
      if (v !== props.value) {
        activeColor.value = v
      }
      context.emit('input', v)
    }

    const closeAllPopovers = () => {
      vm.$root.$emit('bv::hide::popover')
    }

    return {
      cssClassButton,
      closeAllPopovers,
      pickColor,
      activeColor,
    }
  },
})
</script>

<style lang="scss" module>
.button {
  box-shadow: inset 0 0 0 1px rgba(white, 0.7),
    inset 0 0 0 4px $toolbar-dropdown-bg;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: inset 0 0 0 2px rgba(white, 0.2),
      inset 0 0 0 4px $toolbar-dropdown-bg;
  }
}

.button-transparent {
  span {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 0H10V5H5V0ZM5 10H0V5H5V10ZM10 10H5V15H0V20H5V15H10V20H15V15H20V10H15V5H20V0H15V5H10V10ZM10 10H15V15H10V10Z' fill='white'/%3E%3C/svg%3E%0A");
    clip-path: circle(12px at center);
  }
}

.variant-light-button {
  background-color: $white;
}

.variant-dark-button {
  background-color: $black;
}

.color-button {
  &.selected {
    background-image: url('data:image/svg+xml;utf8,%3Csvg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M11.8787 4L6.92892 8.94975L4.10049 6.12132L2.68628 7.53553L6.92892 11.7782L8.34313 10.364L13.2929 5.41421L11.8787 4Z" fill="black" /%3E%3C/svg%3E');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
  }
}
.toolbar-dropdown{
  border-width: 0 0 6px 6px;
  border-color: transparent transparent rgba(255,255,255,0.4) transparent;
  @apply w-0 h-0 border-solid absolute bottom-0 right-0;
}
</style>

<style lang="postcss">
  .vc-chrome{
    @apply shadow-none rounded-none m-0 w-280;
  }
  .vc-chrome-body{
    @apply p-16;
  }
  .vc-chrome-saturation-wrap{
    @apply  pb-112 rounded-t-md;
  }
  .vc-chrome-fields-wrap {
    @apply flex flex-row items-start;
  }
  .vc-chrome-fields .vc-input__input {
    @apply text-13 font-semibold h-24;
  }
  .vc-chrome-fields .vc-input__label {
    all: unset;
    @apply block text-13 font-medium text-gray-600 uppercase text-center mt-2;
  }
  .vc-chrome-toggle-btn{
    all: unset;
    @apply h-24 flex items-center justify-center cursor-pointer;
  }
  .vc-chrome-toggle-icon{
    all: unset;
  }
  .vc-checkerboard{
    @apply bg-repeat rounded-full;
  }

  .vc-chrome-color-wrap {
    all: unset;
    @apply relative mr-16;
  }

  .vc-chrome-color-wrap .vc-checkerboard,
  .vc-chrome-color-wrap .vc-chrome-active-color {
    @apply w-32 h-32 rounded-full ring-1 ring-offset-2 ring-offset-white ring-gray-200;
  }

  .vc-alpha::after,
  .vc-chrome-hue-wrap .vc-hue::after {
    content: '';
    @apply absolute inset-0 rounded-full border border-gray-800 pointer-events-none;
    mix-blend-mode: difference;

  }
  .vc-chrome-hue-wrap .vc-hue{
    @apply rounded-full;
  }

  .vc-chrome-hue-wrap,
  .vc-chrome-alpha-wrap {
    @apply h-12;
  }

  .vc-chrome-hue-wrap .vc-hue-picker,
  .vc-chrome-alpha-wrap .vc-alpha-picker{
    @apply border-none m-0 h-12 w-12 rounded-full ring-1 ring-gray-300 ring-offset-white ring-offset-2 shadow-lg bg-gray-200;
    @apply transform -translate-y-0 -translate-x-6 ;
  }

  .vc-chrome-alpha-wrap .vc-alpha-gradient {
    @apply rounded-full;
  }
</style>
