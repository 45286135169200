export const COMMAND = {
  checkout: 'checkout',
  createPortalSession: 'createPortalSession',
  // getSubscription: 'getSubscription',
};

export const PAYMENT_PLAN = {
  'v2-solo': 'v2-solo',
  'v2-solo-plus': 'v2-solo-plus',
  'v2-business': 'v2-business',
}
