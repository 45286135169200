
<div>
  <ModalBasic
    :no-close="false"
    :hide-footer="true"
    title="Google Fonts"
    :content-class="$style['modal-content-fonts']"
    :body-class="$style['modal-body-fonts']"
    header-class="modal-header-tertiary"
    footer-class="modal-footer-tertiary"
    :dialog-class="$style['modal-dialog-fonts']"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #body>
      <BrandingFonts
        :organization="organization"
        :apply-font-on="applyFontOn"
        :model="model"
        @select="(family, applyFontOn) => $emit('select', family, applyFontOn)"
      />
    </template>
  </ModalBasic>
</div>
