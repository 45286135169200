<template>
  <div v-fragment>
    <div class="flex items-center space-x-8">
      <Button
        type="button"
        variant="dark"
        size="sm"
        class="right-pos"
        :disabled="disabled || !hasSelectableMembers"
        @click="openModal"
      >
        <span class="mr-4 icon_v2-so_edit-rectangle"></span>
        {{ buttonTitle }}
      </Button>
      <div
        v-if="disabled && disabledMessage"
        class="text-gray-400 text-12 dark:text-darkGray-300"
      >
        {{ disabledMessage }}
      </div>
      <div v-else-if="!hasSelectableMembers && emptyMessage" class="text-gray-400 text-12">
        {{ emptyMessage }}
      </div>
    </div>
    <div class="mt-24">
      <TeamMemberList :items="assignedMembers" />
    </div>
    <ProjectSettingsManageAccessModal
      v-if="isModalOpen"
      v-model="isModalOpen"
      :title="selectPeopleTitle"
      :description="selectPeopleDescription"
      :items="selectableMembers"
      :model.sync="state"
    />
  </div>
</template>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { useFindMembers } from '@/v2/services/members/compositions'
import Button from '@/components/Button.vue'
import ProjectSettingsManageAccessModal from '@/components/ProjectSettingsManageAccessModal.vue'
import TeamMemberList from '@/components/TeamMemberList.vue'

export default defineComponent({
  name: 'TeamManager',
  components: {
    Button,
    ProjectSettingsManageAccessModal,
    TeamMemberList,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    querySelectable: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledMessage: {
      type: String,
      default: null,
    },
    emptyMessage: {
      type: String,
      default: null,
    },
    buttonTitle: {
      type: String,
      default: null,
    },
    selectPeopleTitle: {
      type: String,
      default: null,
    },
    selectPeopleDescription: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const { items: assignedMembers } = useFindMembers({
      params: computed(() => ({ query: props.query })),
      joinUser: true,
    })

    const {
      items: selectableMembers,
      isPending: selectableMembersPending,
    } = useFindMembers({
      params: computed(() => ({ query: props.querySelectable })),
      joinUser: true,
    })

    const hasSelectableMembers = computed(
      () => !selectableMembersPending.value && selectableMembers.value.length > 0
    );

    const isModalOpen = ref(false)

    const openModal = () => {
      isModalOpen.value = true
    }

    const state = computed({
      get() {
        return props.value
      },
      set(value) {
        context.emit('input', value)
      },
    })

    return {
      state,
      assignedMembers,
      selectableMembers,
      hasSelectableMembers,
      openModal,
      isModalOpen,
      selectableMembersPending,
    }
  },
})
</script>
