<template>
  <div v-fragment>
    <DocumentEditor
      :document="document"
      :organization-id="organization._id"
    />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import DocumentEditor from '@/components/Document/Editor/DocumentEditor.vue'

export default defineComponent({
  name: 'FSDocument',
  components: {
    DocumentEditor,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
  },
})
</script>
