
<div
  v-b-tooltip.hover.top.v-info.dh0.ds200="`Embed ${app.name}`"
  class="flex flex-col space-y-6 w-64 justify-center items-center
  cursor-pointer transform-gpu transition-all hover:-translate-y-2
"
  v-on="$listeners"
>
  <!-- ICON -->
  <div
    class="w-48 h-48 border shadow-sm border-gray-100 rounded-md
          flex items-center justify-center"
  >
    <span
      :style="style"
      class="w-32 h-32"
    ></span>
  </div>
  <span class="text-11 text-gray-700 text-center leading-none">{{ app.name }}</span>
  <!-- LABEL -->
</div>
