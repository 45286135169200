<template>
  <b-form @submit.prevent="formSubmit">
    <FormMessage v-if="formError">
      {{ formError.message }}
    </FormMessage>
    <div class="mb-50">
      <h3 class="project-page-subtitle">
        Subdomain
      </h3>
      <p class="mb-16 text-gray-600 text-14 max-w-510">
        This is your default sharing subdomain.
      </p>
      <div class="flex">
        <FormField
          v-slot="{ state }"
          wrapper-class="mr-10"
          :v="$v.subdomain"
          :messages="{
            required: 'Subdomain is required',
            available: 'Subdomain is not available',
            subdomain: 'Invalid characters detected',
            different: 'Subdomain must be different'
          }"
        >
          <div class="input-group max-w-510">
            <b-input
              v-model="subdomain"
              placeholder="myagency"
              :state="!subdomain ? null : state"
              autocomplete="off"
              size="lg"
            />
            <div class="input-group-append">
              <span class="font-semibold input-group-text text-14">.superokay.com</span>
            </div>
          </div>
        </FormField>
        <Button
          :disabled="formIsBusy"
          size="md"
          variant="neutral"
          type="submit"
        >
          Save
        </Button>
      </div>
      <!-- <p v-if="organization.subdomain" class="mt-8 text-gray-500 text-13">
        This is your .superokay subdomain. To change it contact us via Intercom.
      </p> -->
    </div>
  </b-form>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { minLength, required, helpers as vuelidateHelpers } from '@vuelidate/validators'
import { isNonEmptyString } from 'ramda-adjunct'
import { email as emailSanitizer } from '@/v2/lib/helpers/sanitizer'
import useStateDebounce from '@/v2/lib/composition/useStateDebounce'
import { useMsgBoxConfirm } from '@/v2/lib/composition/useMsgBox'
import useValidators from '@/v2/services/validators/validatorsCompositions'
import useFormFeathers from '@/v2/lib/composition/useFormFeathers'
import FormField from '@/components/FormField.vue'
import Button from '@/components/Button.vue'
import FormMessage from '@/components/FormMessage.vue'

const { withAsync, req } = vuelidateHelpers


export default defineComponent({
  name: 'OrganizationSettingsSubdomain',
  components: {
    FormField,
    Button,
    FormMessage,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    licensing: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const model = props.organization.clone()

    const confirm = useMsgBoxConfirm()

    const { subdomainAvailable } = useValidators();

    const { subdomain } = useStateDebounce(
      ['subdomain'],
      model,
      { subdomain: emailSanitizer }
    );

    const validation = {
      subdomain: {
        required,
        minLength: minLength(3),
        subdomain: vuelidateHelpers.regex(/^\w(?:[\w-]{0,61}\w)?$/),
        different: value => !req(value) || value !== props.organization.subdomain,
        available: withAsync(
          async value => !req(value) || subdomainAvailable(value)
        ),
      },
    }

    const {
      formError,
      formSubmit,
      formIsBusy,
      formValidationPending,
      $v,
    } = useFormFeathers({
      model,
      fields: ['subdomain'],
      validation,
      successMessage: 'Settings saved',
      autoCommit: true,
    })

    const _formSubmit = async () => {
      $v.value.$touch()

      if ($v.value.$invalid) {
        return;
      }

      const message = [
        'Are you sure you want to change your subdomain?',

        // add message if an active domain is present
        isNonEmptyString(props.organization.domain)
          && 'Before you proceed with updating your subdomain, please be aware that your custom domain will be removed and you will need to set it up again.',
      ].filter(Boolean).join(' ');

      const ok = await confirm({
        title: 'Update Subdomain?',
        message,
        size: 'md',
      });

      if (ok) {
        formSubmit();
      }
    }

    return {
      model,
      subdomain,
      formError,
      formSubmit: _formSubmit,
      formIsBusy,
      formValidationPending,
      $v,
    }
  },
})
</script>
