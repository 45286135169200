
<PageHeader :title="title">
  <template #actions>
    <ButtonMenuCreateDocument
      :category="documentCategory"
      :templates-category="documentCategory"
      :label="`New ${label}`"
      :tooltip-message="`Create a ${label}`"
      :show-tooltip="showTooltip"
      :data-intercom-target="`Add ${label} Button`"
      variant="neutral"
      icon-class="mr-4 -ml-4 icon_v2-so_plus text-green-600"
      size="md"
    />
  </template>
</PageHeader>
