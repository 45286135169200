<template>
  <div v-fragment>
    <button
      v-for="(option, index) in options"
      :key="option.value"
      :index="index"
      class="inline-flex items-center justify-center min-w-32 h-32 font-semibold text-white px-6"
      :class="{
        'bg-gray-500 bg-opacity-10 hover:bg-opacity-20': value != option.value,
        'bg-green-500': value === option.value,
        'rounded-l': index == 0,
        'rounded-r': index == options.length - 1,
      }"
      @click="$emit('input', option.value)"
    >
      <span v-if="option.text" class="toolbar-icon">
        {{ option.text }}
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'DocumentNodeEditorToolbarButtonGroup',
  props: {
    options: Array,
    value: String,
  },
}
</script>
