
<div>
  <LibraryDocumentPageHeader
    title="Pages"
    label="Page"
    :document-category="category"
  />
  <div class="project-page">
    <div class="project-page-wrapper">
      <DocumentList
        :organization-id="organization._id"
        :category="category"
        :auto-margin="false"
      >
        <template #list="{ documents, open, remove }">
          <div class="mt-24 space-y-16 max-w-page">
            <FileDocList
              v-for="document in documents"
              :key="document._id"
              :document="document"
              standalone
              :show-analytics="false"
              @open="open(document)"
              @remove="remove(document)"
            />
          </div>
        </template>
        <template #placeholder>
          <PlaceholderCreate
            :document-category="category"
            button-label="Create your first Page"
          >
            <template #title>One Creation, Multiple Uses</template>
            <template #description>
              Here is where you can create web pages showcasing different aspects
              of your services, such as How To and About US, that you would like to
              share with selected clients. The pages reside in your library and when
              altered here the changes will reflect on all the portals
              where these pages are shared.
            </template>
          </PlaceholderCreate>
        </template>
      </DocumentList>
    </div>
  </div>
</div>
