<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="Math.round(vw * scale)"
    :height="Math.round(vh * scale)"
    :vw="vw"
    :vh="vh"
    :viewBox="viewbox"
    :aria-labelledby="iconName"
    role="presentation"
    class="inline-block align-baseline"
    :style="rotatedStyle"
  >
    <g :fill="fillColor">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    vw: {
      type: [Number, String],
      default: 24,
    },
    vh: {
      type: [Number, String],
      default: 24,
    },
    scale: {
      type: Number,
      default: 1,
    },
    fillColor: {
      type: String,
      default: 'currentColor',
    },
    rotate: {
      type: String,
      default: '',
    },
  },
  computed: {
    viewbox() {
      return '0 0 ' + this.vw + ' ' + this.vh
    },
    rotatedStyle() {
      return `transform: rotate(${this.rotate}deg);`
    },
  },
}
</script>
