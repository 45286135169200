
<ul
  class="
    inline-flex gap-x-4 text-13 flex-wrap gap-y-4
    rounded-lg w-full
  "
>
  <template v-for="project in projects">
    <li v-if="project" :key="project._id">
      <router-link
        :to="{
          name: PROJECT_SETTINGS_MANAGE_ACCESS,
          params: { projectId: project._id }
        }"
      >
        <ProjectBadge
          :label="project.name"
          :background-color="project?.portalAccentColor"
        />
      </router-link>
    </li>
  </template>
</ul>
