<template>
  <div>
    <PageHeader title="Pinned Folders">
      <template #actions>
        <b-button
          type="button"
          variant="neutral"
          size="md"
          @click="openFolderPicker"
        >
          <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600" />
          Pin Folder
        </b-button>
      </template>
    </PageHeader>
    <InfoBox
      title="Keep the most important folders front and center"
      icon="icon_v2-so_pin"
    >
      Pin any folder from your <strong
        class="inline-flex px-4 py-2 not-italic
                font-semibold leading-none text-green-900 rounded
                bg-green-200/50"
      >
        <span class="icon_v2-so_shared text-14 mr-4"></span>
        Shared</strong> folder to the Client Portal Dashboard and on the sidebar navigation.
    </InfoBox>
    <Form
      v-if="state"
      v-slot="{ isBusy, submit, hasChanges }"
      :state="state"
      success-message="Project changes saved"
      :on-submit="() => save()"
    >
      <div class="flex flex-col max-w-page">
        <!-- Pinned Folders -->
        <b-form-group>
          <SlickList
            v-if="state?.pinnedFolders.length"
            :value="state.pinnedFolders"
            lock-to-container-edges
            axis="xy"
            use-drag-handle
            custom-container-id="pinned-folders-list"
            :custom-container-offset-y="15"
            class="flex flex-wrap gap-12"
            @sort-start="handleSortStart"
            @sort-end="handleSortEnd"
            @input="reorder"
          >
            <SlickItem
              v-for="(folder, index) in pinnedFolders"
              :key="folder?._id"
              :index="index"
              tag="div"
            >
              <ActionsToolbar
                v-if="folder"
                show-drag
                show-delete
                @remove="unpinFolder(folder._id)"
              >
                <FolderCard :document="folder" size="l" />
              </ActionsToolbar>
            </SlickItem>
          </SlickList>
        </b-form-group>
        <!-- End of Pinned Folders -->
      </div>
      <PageFooter>
        <Button
          :disabled="isBusy"
          size="lg"
          variant="primary"
          type="submit"
          @click="submit"
        >
          Save Changes
        </Button>
        <template v-if="hasChanges" #right>
          <PageFooterMessage />
        </template>
      </PageFooter>
    </Form>
    <ProjectFolderSelectModal
      v-if="isFolderSelectOpen"
      key="select-pinned-folders-modal"
      v-model="isFolderSelectOpen"
      title="Select folder to pin"
      ok-label="Pin Folder"
      :project-id="project._id"
      :show-subtitle="false"
      :hidden-folder-ids="state?.pinnedFolders"
      :folder-category="DOCUMENT_CATEGORY.ProjectPublicFolder"
      @select="pinFolder"
    />
  </div>
</template>
<script>
import { pluck } from 'ramda'
import Vue from 'vue'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { SlickList, SlickItem } from 'vue-slicksort'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import { changeCursorStyle } from '@/helpers/dom'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'
import PageFooter from '@/components/PageFooter.vue'
import Form from '@/components/Form.vue'
import Button from '@/components/Button.vue'
import PageHeader from '@/components/PageHeader.vue'
import ActionsToolbar from '@/components/ActionsToolbar.vue'
import FolderCard from '@/components/FolderCard.vue'
import ProjectFolderSelectModal from '@/components/ProjectFolderSelectModal.vue'
import InfoBox from '@/components/InfoBox.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'

export default defineComponent({
  name: 'ViewProjectSettingsPinnedFolders',
  components: {
    PageHeader,
    PageFooter,
    Form,
    Button,
    SlickList,
    SlickItem,
    ActionsToolbar,
    FolderCard,
    ProjectFolderSelectModal,
    InfoBox,
    PageFooterMessage,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { Document } = Vue.$FeathersVuex.api

    const {
      state,
      save,
    } = useFormEntityEditor({
      model: 'Project',
      id: computed(() => props.project._id),
      fields: computed(() => ['pinnedFolders']),
    })

    const handleSortStart = () => changeCursorStyle('grabbing')
    const handleSortEnd = () => changeCursorStyle('')

    const pinnedFolders = computed({
      // Sidebar Tree fetches all folders on boot. Data should be in Store
      get() {
        return state.value.pinnedFolders.map(id => Document.getFromStore(id))
      },
      set(newOrder) {
        state.value.pinnedFolders = pluck('_id', newOrder)
      },
    })

    const isFolderSelectOpen = ref(false)

    const openFolderPicker = () => {
      isFolderSelectOpen.value = true
    }

    const pinFolder = folderId => {
      state.value.pinnedFolders = [...state.value.pinnedFolders, folderId]
      isFolderSelectOpen.value = false
    }

    const unpinFolder = folderId => {
      state.value.pinnedFolders = state.value.pinnedFolders.filter(
        id => id !== folderId
      )
    }

    const reorder = items => {
      state.value.pinnedFolders = items
    }

    return {
      state,
      save,
      isFolderSelectOpen,
      openFolderPicker,
      pinFolder,
      unpinFolder,
      handleSortStart,
      handleSortEnd,
      pinnedFolders,
      reorder,
      DOCUMENT_CATEGORY,
    }
  },
})
</script>
