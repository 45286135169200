import { computed } from '@vue/composition-api'
import useFindPagination from '@/v2/lib/composition/useFindPagination'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @param {object} params
 * @param {Ref<object>} params.query
 * @param {number} [params.pageSize]
 * @param {string} [params.queryId]
 */
export default function useInbox({
  query,
  pageSize = 50,
  queryId = 'inbox',
}) {
  const queryWithSort = computed(() => ({
    ...query.value,
    $sort: { time: -1 },
  }));

  return useFindPagination({
    modelName: 'Notification',
    query: queryWithSort,
    pageSize,
    queryId,
  })
}
