<template>
  <button
    class="inline-flex items-center justify-center w-32 h-32 text-white"
    @click="$emit('click')"
    :class="classes()"
  >
    <span v-if="text">
      {{ text }}
    </span>
    <slot />
  </button>
</template>

<script>
export default {
  name: 'DocumentNodeEditorToolbarButton',
  props: {
    text: String,
    isSelected: Boolean,
  },
  methods: {
    classes() {
      return this.isSelected
        ? 'bg-green-500'
        : 'bg-gray-500 bg-opacity-10 hover:bg-opacity-20'
    },
  },
}
</script>
