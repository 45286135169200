<template>
  <div class="content-library">
    <!-- header with title -->
    <div class="content-library-header">
      <h2 v-if="title && !hideTitle" class="title">
        {{ title }}
      </h2>
    </div>
    <div class="flex flex-row items-center justify-between space-x-16">
      <!-- Search Bar -->
      <div class="content-library-search">
        <span class="absolute w-24 text-gray-600 icon-magnify text-24 left-10 top-10"></span>
        <!-- NOTE: Could not find a way to debounce keyword update. Local Vuex Store is querried
        after every key press -->
        <b-form-input
          v-model.lazy="keyword"
          :placeholder="`Search ${title}`"
          class="search"
          size="lg"
        />
      </div>
      <!-- End of Search Bar -->
      <!-- <Button
        v-if="createButton"
        type="button"
        size="md"
        variant="primary"
        @click="$emit('new-document')"
      >
        <span class="icon_v2-so_plus"></span>
        {{ createButton }}
      </Button> -->
    </div>
    <div :class="[$style['content-library-content'], isEmpty && 'is-empty']">
      <div
        v-if="isEmpty"
        class="flex flex-col items-center justify-center py-32"
      >
        <span :class="`icon-${icon}`" class="h-48 mb-24 text-gray-400 text-48"></span>
        <h3 class="font-medium text-gray-700/60 text-18">
          No {{ title }} available
        </h3>
        <!-- <p class="text-gray-600 text-16">
          Create {{ title }} in order to include them in your documents.
        </p> -->
      </div>
      <div v-else :class="$style['items-wrap']">
        <div
          :class="[grid ?
                     'grid grid-cols-2 md:grid-cols-none md:flex md:flex-wrap w-full gap-12' :
                     'flex flex-col justify-center items-stretch',
                   'mb-128']"
        >
          <div
            v-for="document in filteredItems"
            :key="document._id"
            :class="$style.item"
            @click="$emit('select', document)"
          >
            <slot v-bind="{ document }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject, defineComponent, toRef } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import { isNilOrEmpty } from 'ramda-adjunct'
import { rejectNil } from 'ramda-extension'
import { CATEGORY, CATEGORY_TO_LIST_TITLE as CATEGORY_TITLES } from '@/v2/services/documents/documentsTypes'
import { useDocumentsFindQuery } from '@/v2/services/documents/documentsCompositions'
import { keywordToRegex } from '@/v2/lib/helpers/string'


export default defineComponent({
  name: 'ContentLibraryList',

  props: {
    category: {
      type: String,
      default: CATEGORY.Document,
    },
    title: {
      type: String,
      default: 'Documents',
    },
    hideTitle: Boolean,
    icon: {
      type: String,
      default: '',
    },
    grid: {
      type: Boolean,
      default: true,
    },
    createButton: {
      type: String,
      default: 'Create',
    },
    parentFolderId: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const { Document } = context.root.$FeathersVuex.api
    const currentDocument = inject('document')
    const keyword = ref('')

    const findQuery = useDocumentsFindQuery(toRef(props, 'category'))
    const findParams = computed(() => ({
      query: rejectNil({
        ...findQuery.value,
        category: props.category,
        title: keyword.value ? keywordToRegex(keyword.value) : null,
        project: props.category === CATEGORY.Document ? currentDocument.value?.project : null,
        parentFolder: props.parentFolderId,
        $sort: { title: 1 },
      }),
      debounce: 500, // This only seems to work for API requests, not local querries
    }))
    // NOTE: Could not find a way to debounce keyword update. Local Vuex Store is querried after
    // every key press
    const { items } = useFind({
      model: Document,
      params: findParams,
    })
    /** Filters out parent document from list. Cannot embed a document within itself */
    const filteredItems = computed(() => items.value.filter(
      item => currentDocument.value._id !== item._id
    ))
    const isEmpty = computed(() => isNilOrEmpty(filteredItems.value))

    return {
      isEmpty,
      keyword,
      filteredItems,
      findParams,
    }
  },
})
</script>

<style lang="postcss" scoped>
.content-library {
  @apply h-full flex flex-col;
  @apply bg-page;
  max-height: calc(100vh - 72px);
}

.title {
  @apply text-22 font-normal;
  @apply font-semibold;
  @apply h-40 flex items-center;
}

.content-library-header {
  @apply flex-1 mb-16;
}

.content-library-search {
  @apply relative flex-1;
}

.icon {
  left: 10px;
  top: 10px;
  position: absolute;
  color: rgba($gray-600, 0.8);
}

.search {
  @apply pl-40 grow;
}

</style>

<style lang="scss" module>


.content-library-content {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  @apply py-24 ;
  &:global(.is-empty) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  > div {
    flex: 1;
  }
}

.empty {
  max-width: 600px;
  h3 {
    font-size: $fs24;
    color: rgba($gray-900, 0.5);
    margin: $gutter;
  }
  .icon-empty {
    width: 48px;
    height: 48px;
  }
  p {
    color: rgba($gray-900, 0.5);
    font-size: $fs18;
    line-height: 1.8;
    a {
      color: rgba($gray-900, 0.5);
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.items-wrap {
}
.items {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  .item {
    cursor: pointer;
    min-width: 0;
  }
  &.grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
