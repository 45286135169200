<template>
  <div>
    <PageHeader title="Manage your clients and client contacts" />
    <InfoBox icon="icon_v2-so_clients">
      Here is where you can create your Client Organizations and manage access
      to the specific Client Projects, by adding or removing individual Client Contacts.
    </InfoBox>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import PageHeader from '@/components/PageHeader.vue'
import InfoBox from '@/components/InfoBox.vue'

export default defineComponent({
  name: 'ViewOrganizationClientsPlaceholder',
  components: {
    PageHeader,
    InfoBox,
  },
})
</script>
