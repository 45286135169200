export default class LoggerService {
  constructor(container) {
    const { logger } = container.get('config')
    this._enable = logger.enable
  }

  log(label, level, ...args) {
    if (!this._enable) {
      return
    }

    console[level](label, ...args)
  }
}
