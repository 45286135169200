<template>
  <DocumentContentLoader v-slot="{ isLoading }" :document="document">
    <DocumentPage
      v-if="!isLoading"
      :document="document"
      :hide-page-border="hidePageBorder"
    >
      <DocumentNodeGroupRootEditable v-if="isEditable" />
      <DocumentNodeGroupRoot v-else :is-read-only="true" />
    </DocumentPage>
  </DocumentContentLoader>
</template>
<script>
import { computed, inject, provide } from '@vue/composition-api'
import { CONTENT_BLOCK_BLACKLIST } from '@/v2/services/documents/documentsTypes'
import DocumentContentLoader from '@/components/DocumentContentLoader.vue'
import DocumentPage from '@/components/Document/Editor/DocumentPage.vue'
import DocumentNodeGroupRoot from '@/v2/features/document/documentNodeGroupImpl/groupRoot/DocumentNodeGroupRoot.vue'
import DocumentNodeGroupRootEditable from '@/v2/features/document/documentNodeGroupImpl/groupRoot/DocumentNodeGroupRootEditable.vue'

export default {
  name: 'DocumentEditorContent',
  components: {
    DocumentContentLoader,
    DocumentPage,
    DocumentNodeGroupRoot,
    DocumentNodeGroupRootEditable,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    hidePageBorder: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isEditor = inject('isEditor')
    const isLocked = inject('isLocked')

    const isEditable = computed(() => isEditor.value && !isLocked.value)

    const contentBlockBlacklist = computed(
      () => CONTENT_BLOCK_BLACKLIST[props.document?.category] ?? {}
    );
    provide('contentBlockBlacklist', contentBlockBlacklist);

    return {
      isEditable,
    }
  },
}
</script>
