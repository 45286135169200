<template>
  <nav>
    <SidebarGroup>
      <SidebarMenu>
        <SidebarMenuLink
          :to="{ name: ORGANIZATION_SETTINGS_MY_ORGANIZATION }"
        >
          <SidebarItemBasic label="My Organization" icon="icon_v2-so_filter-dots" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: ORGANIZATION_SETTINGS_TEAM }"
        >
          <SidebarItemBasic label="Team Members" icon="icon_v2-so_users" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: ORGANIZATION_SETTINGS_PORTAL_SETTINGS }"
          activate-on-child-route
        >
          <SidebarItemBasic label="Client Portal" icon="icon_v2-so_menu-home" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: ORGANIZATION_SETTINGS_DOMAIN }"
        >
          <SidebarItemBasic label="Custom Domain" icon="icon_v2-so_globe" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: ORGANIZATION_SETTINGS_EMAIL }"
        >
          <SidebarItemBasic label="Email White Label" icon="icon_v2-so_email-sent" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: ORGANIZATION_SETTINGS_SUBSCRIPTION }"
          :activate-on-routes="[ORGANIZATION_SETTINGS_SUBSCRIPTION_MANAGE]"
        >
          <SidebarItemBasic label="Subscription &amp; Billing" icon="icon_v2-so_card" />
        </SidebarMenuLink>
      </SidebarMenu>
    </SidebarGroup>
  </nav>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
// import CheckMemberRole from '@/components/CheckMemberRole.vue'
import SidebarGroup from '@/components/Sidebar/SidebarGroup.vue'
import SidebarMenu from '@/components/Sidebar/SidebarMenu.vue'
import SidebarMenuLink from '@/components/Sidebar/SidebarMenuLink.vue'
import SidebarItemBasic from '@/components/Sidebar/SidebarItemBasic.vue'

import {
  ORGANIZATION_SETTINGS_MY_ORGANIZATION,
  ORGANIZATION_SETTINGS_DOMAIN,
  ORGANIZATION_SETTINGS_EMAIL,
  ORGANIZATION_SETTINGS_TEAM,
  ORGANIZATION_SETTINGS_SUBSCRIPTION,
  ORGANIZATION_SETTINGS_SUBSCRIPTION_MANAGE,
  ORGANIZATION_SETTINGS_PORTAL_SETTINGS,
} from '@/router/organization/type'

export default defineComponent({
  name: 'OrganizationSettingsNav',
  components: {
    // CheckMemberRole,
    SidebarGroup,
    SidebarMenu,
    SidebarMenuLink,
    SidebarItemBasic,
  },
  setup() {
    return {
      ORGANIZATION_SETTINGS_MY_ORGANIZATION,
      ORGANIZATION_SETTINGS_DOMAIN,
      ORGANIZATION_SETTINGS_EMAIL,
      ORGANIZATION_SETTINGS_TEAM,
      ORGANIZATION_SETTINGS_SUBSCRIPTION,
      ORGANIZATION_SETTINGS_SUBSCRIPTION_MANAGE,
      ORGANIZATION_SETTINGS_PORTAL_SETTINGS,
    }
  },
})
</script>
