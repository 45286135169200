
<PageHeader
  title="Projects"
  :search="!$isMobile"
  :search-keyword="keyword"
  @update:search-keyword="val => $emit('update:keyword', val)"
>
  <template #actions>
    <ProjectViewOptions
      v-if="!$isMobile"
      :value="viewContext"
      @input="val => $emit('update-view-context', val)"
    />
    <CheckMemberRole v-slot="{ hasRole }" :roles="['admin', 'projectManager']">
      <ButtonNewProject
        v-if="hasRole"
        view-as="button"
        variant="neutral"
        size="md"
        icon-class="mr-4 -ml-4 icon_v2-so_plus text-green-600"
      />
    </CheckMemberRole>
  </template>
  <template #filter>
    <OrganizationProjectsPageHeaderTags
      :tags="tags"
      :value="selectedTags"
      :selected-tag-count="selectedTagCount"
      @input="val => $emit('update:selected-tags', val)"
    />
  </template>
</PageHeader>
