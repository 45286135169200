
<ModalSidebar
  :no-close="false"
  :hide-footer="true"
  :hide-sidebar="hideSidebar"
  body-class="bg-page"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #sidebar>
    <ContentLibrarySidebar
      :category="category"
      :selected-folder-id="selectedFolderId"
      @update:category="category => $emit('update:category', category)"
      @folder-selected="selectFolder"
    />
  </template>
  <template #body>
    <ContentLibrary
      :category="category"
      :title="title"
      :parent-folder-id="selectedFolderId"
      @select="doc => $emit('select', doc)"
    />
  </template>
</ModalSidebar>
