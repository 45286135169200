<template>
  <ListMultiSelect
    v-slot="{ item: document }"
    :items="documents"
    :value="value"
    @input="val => $emit('input', val)"
  >
    <p class="grow inline-flex items-center">
      {{ document.title }}
    </p>
    <div v-if="!hideCost" class="text-right mr-auto font-semibold">
      <DocumentCurrency :value="document.cost" :document-id="document._id" />
    </div>
  </ListMultiSelect>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import DocumentCurrency from './DocumentCurrency.vue'
import ListMultiSelect from './ListMultiSelect.vue'

export default defineComponent({
  name: 'ListMultiSelectDocuments',
  components: {
    ListMultiSelect,
    DocumentCurrency,
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      required: true,
    },
    hideCost: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
