import ViewProject from '@/views/ViewProject.vue'

import content from './content'
import settings from './settings'

import {
  PROJECT,
  PROJECT_CONTENT,
} from './type'

export default () => [
  {
    name: PROJECT,
    path: 'p/:projectId',
    component: ViewProject,
    props: true,
    redirect: {
      name: PROJECT_CONTENT,
    },
    children: [
      ...content,
      ...settings,
    ],
  },
]
