<template>
  <LayoutSignup>
    <img
      v-if="organization.logoUrl"
      :src="organization.logoUrl"
      :class="$style['agency-logo']"
      class="object-contain object-left"
    />
    <SignUpFormHeader>
      {{ organization.name }} invited you to join their team on
      SuperOkay
    </SignUpFormHeader>
    <div class="flex flex-row items-center justify-center space-x-8">
      <Button
        :class="$style.btn"
        size="md"
        type="button"
        variant="neutral"
        @click="goToDashboard"
      >
        I'll do it later
      </Button>
      <Button
        :class="$style.btn"
        size="md"
        type="button"
        variant="primary"
        @click="join"
      >
        Join {{ organization.name }}
      </Button>
    </div>
  </LayoutSignup>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useNamespacedActions } from 'vuex-composition-helpers'
import { useUser } from '@/v2/services/users/usersCompositions'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useLoadMembers } from '@/v2/services/myMembers/compositions'
import { ORGANIZATION_HOME } from '@/router/organization/type'
import LayoutSignup from '@/views/LayoutSignup.vue'
import Button from '@/components/Button.vue'
import SignUpFormHeader from '@/components/SignUpFormHeader.vue'
import { ROLES } from '@/v2/services/members/membersTypes'

export default defineComponent({
  name: 'ViewLandingInviteUser',
  components: {
    LayoutSignup,
    Button,
    SignUpFormHeader,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { user, authenticateMember } = useUser()
    const { router } = useRouter()
    const loadMembers = useLoadMembers()
    const { patch: patchMember } = useNamespacedActions('members', ['patch']);

    const goToDashboard = () => router.push({
      name: ORGANIZATION_HOME,
      params: { organizationId: props.organization._id },
    })
    const join = async () => {
      await patchMember([
        props.member._id,
        { user: user.value._id, status: 'active' },
        { token: props.token },
      ])

      await authenticateMember(props.member._id)
      await loadMembers({ role: { $ne: ROLES.client } })

      goToDashboard()
    }

    return {
      join,
      user,
      goToDashboard,
    }
  },
})
</script>
<style lang="scss" module>
.agency-logo {
  max-height: 48px;
  margin: 0 auto 60px auto;
}
</style>
