
<DocumentNodeEditorToolbarPopover
  class-name="w-32 rounded hover:bg-gray-500 hover:bg-opacity-10 editor-toolbar-button"
  tooltip="Control padding & margin"
>
  <template #button-content>
    <span class="w-24 text-24 icon-toolbar-spacing"></span>
    <span :class="[$style['toolbar-dropdown']]"></span>
  </template>
  <!-- <template #title>Spacing</template> -->
  <template #default>
    <header
      :class="[
        'text-gray-200 text-opacity-90',
        'bg-gray-1000 bg-opacity-50',
        'px-5 py-3 text-11 uppercase rounded-sm',
        'inline-flex items-center font-semibold mb-6',
      ]"
    >
      Padding Inside
    </header>
    <div class="mb-16">
      <DocumentNodeEditorToolbarSlider
        :value="paddingTop"
        :min="0"
        :max="512"
        :step="8"
        label="Top"
        @input="value => onChange('paddingTop', value)"
      />
      <DocumentNodeEditorToolbarSlider
        :value="paddingBottom"
        :min="0"
        :max="512"
        :step="8"
        label="Bottom"
        @input="value => onChange('paddingBottom', value)"
      />
    </div>

    <header
      :class="[
        'text-gray-200 text-opacity-90',
        'bg-gray-1000 bg-opacity-50',
        'px-5 py-3 text-11 uppercase rounded-sm',
        'inline-flex items-center font-semibold mb-6',
      ]"
    >
      Margin Outside
    </header>
    <div>
      <DocumentNodeEditorToolbarSlider
        :value="marginTop"
        :min="0"
        :max="512"
        :step="8"
        label="Before"
        @input="value => onChange('marginTop', value)"
      />
      <DocumentNodeEditorToolbarSlider
        :value="marginBottom"
        :min="0"
        :max="512"
        :step="8"
        label="After"
        @input="value => onChange('marginBottom', value)"
      />
    </div>
  </template>
</DocumentNodeEditorToolbarPopover>
