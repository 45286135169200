
<ModalBasic
  :should-close-on-backdrop="false"
  :hide-footer="true"
  :dialog-class="$style['modal-dialog-manage-access']"
  content-class="modal-content-tertiary"
  :body-class="$style['modal-body-link']"
  :header-class="$style['modal-header-link']"
  footer-class="modal-footer-tertiary"
  title-class="!text-left"
  size="md"
  :title="title"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #body="{ close }">
    <div class="mb-16">
      <p v-if="description" class="settings-section-description">
        {{ description }}
      </p>
    </div>
    <b-form-group class="mb-8 relative">
      <!-- url -->
      <b-input
        v-model="keyword"
        type="search"
        placeholder="Type a name"
        class="rounded-md h-40 text-search pr-32"
      />
      <span class="icon-util-search text-18 text-gray-400 absolute right-8 top-12"></span>
    </b-form-group>
    <div v-if="items.length" class="inline-flex space-x-8 mt-8">
      <b-link
        class="text-green-500 underline hover:no-underline"
        @click="selectAll"
      >
        Select everyone
      </b-link>
      <b-link
        class="text-green-500 underline hover:no-underline"
        @click="selectNone"
      >
        Select no one
      </b-link>
    </div>
    <p
      v-if="isSearchActive && !filteredItems.length"
      class="settings-section-description mt-16"
    >
      No people found based on the search keyword provided.
    </p>
    <TeamMemberList
      v-model="state"
      selectable
      :items="filteredItems"
      class="mt-24"
      @submit="onSubmit(close)"
    />
  </template>
</ModalBasic>
