<template>
  <ButtonTransparent v-bind="$attrs" v-on="$listeners">
    <MainNavItem
      :active="active"
      :icon="icon"
      :icon-active="iconActive"
      :label="label"
    />
  </ButtonTransparent>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ButtonTransparent from '@/components/ButtonTransparent.vue'
import MainNavItem from '@/components/MainNavItem.vue'

export default defineComponent({
  name: 'MainNavItemBasic',
  components: {
    ButtonTransparent,
    MainNavItem,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    iconActive: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
  },
})
</script>
