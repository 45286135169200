<template>
  <div
    v-if="show"
    class="text-left text-14"
  >
    <div
      v-if="group.style === 'checkmark'"
      class="flex items-center gap-4"
    >
      <span
        class="
          inline-flex w-16 h-16
          items-center justify-center
          rounded-full text-white
        "
        :class="{
          'bg-green-500': isEnabled,
          'bg-gray-500': isEnabledLimited,
          'bg-red-500': isDisabled,
        }"
      >
        <span
          class="text-14"
          :class="{
            'icon_v2-so_tick': isEnabled || isEnabledLimited,
            'icon_v2-so_close': isDisabled,
          }"
        />
      </span>
      <span :class="{ 'text-gray-400': isDisabled }">
        {{ featureFlag.label }}
      </span>
    </div>
    <div v-if="group.style === 'bold'" class="font-bold">
      {{ featureFlag.label }}
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import { isNumber } from 'ramda-adjunct'

export default defineComponent({
  name: 'PaymentPlanCardFeature',
  props: {
    group: {
      type: Object,
      required: true,
    },
    featureFlag: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isEnabled = computed(() => {
      const { type, value } = props.featureFlag
      return (type === 'number' && value === null) || (type === 'boolean' && value)
    })

    const isEnabledLimited = computed(() => {
      const { type, value } = props.featureFlag
      return (type === 'number' && isNumber(value) && value > 0)
    })

    const isDisabled = computed(() => {
      const { type, value } = props.featureFlag
      return (type === 'number' && value === 0) || (type === 'boolean' && !value)
    })

    const show = computed(() => {
      const hideDisabled = props.group.hideDisabled
        || props.featureFlag.hideDisabled

      return (isDisabled.value && !hideDisabled) || !isDisabled.value
    })


    return {
      isEnabled,
      isEnabledLimited,
      isDisabled,
      show,
    }
  },
})
</script>
