
<div>
  <div :class="$style.org_team" class="mb-12 border-b border-gray-200">
    <b-table
      hover
      primary-key="member._id"
      details-td-class="!pt-0 !pl-36"
      :items="items"
      :fields="[
        {
          key: 'name',
          sortable: true,
          label: 'Name',
          tdClass: $style['col-menu']
        },
        {
          key: 'projects',
          sortable: false,
          name: 'Projects',
        },
        {
          key: 'accessLevel',
          label: 'Access Level',
          sortable: true,
          tdClass: $style['col-menu']
        },
        {
          key: 'remove',
          label: '',
          sortable: false,
          tdClass: $style['col-menu']
        },
      ]"
      class="text-14"
      :class="$style['m-table']"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'remove' ? '80px' : 'auto' }"
        />
      </template>
      <template #cell(name)="data">
        <p class="inline-flex items-center">
          <Avatar
            :class="!data.item.user && $style['avatar-resend']"
            :profile="data.item.user?.profile$"
            :name="
              data.item.member.status === STATUS.INVITED
                ? [data.item.member.firstName, data.item.member.lastName].join(' ')
                : null
            "
            show-name
          />
          <span
            v-if="isCurrentMember(data.item.member._id)"
            class="opacity-50 font-medium"
          >&nbsp;(You)</span>
        </p>
        <p class="ml-28 text-12 font-medium">
          {{ data.item.user?.email ?? data.item.member?.email }}
        </p>
      </template>
      <template #cell(accessLevel)="data">
        <div :class="[$style.actions, 'flex items-center']">
          <BadgeMemberRole
            v-if="data.item.member.status === STATUS.ACTIVE"
            :member="data.item.member"
          />
          <Badge
            v-if="data.item.member.status === STATUS.INVITED"
            class="table-disabled-text"
            variant="tertiary"
          >
            Invited
          </Badge>
          <Badge
            v-else-if="data.item.member.status === STATUS.LOCKED"
            variant="warning"
          >
            Disabled
          </Badge>
        </div>
      </template>
      <template #cell(remove)="data">
        <OrganizationTeamMemberMenu
          v-if="!isCurrentMember(data.item.member._id) || !isOwner"
          :member="data.item.member"
          :disable-resend-invitation="
            isLoadingResendInvitation
              || resendRequestMap[data.item.member._id]
          "
          @edit="edit(data.item.member._id)"
          @remove="removeMember(data.item.member)"
          @resend-invite="resendInvitation(data.item.member)"
          @cancel-invite="cancelInvitation(data.item.member)"
        />
      </template>
      <template #cell(projects)="data">
        <template v-if="data.item.member.role === ROLES.member">
          <MemberAssignedProjects
            v-if="data.item.projects?.length"
            :projects="data.item.projects"
          />
          <div v-else class="text-gray-400 text-12 font-semibold">No Projects</div>
        </template>
        <div
          v-else
          class="text-green-600 text-12 font-semibold"
        >
          All Projects
        </div>
      </template>
    </b-table>
    <TeamMemberFormModal
      v-if="isEditorOpen"
      v-model="isEditorOpen"
      :member-id="memberId"
      :organization-id="organizationId"
      :create-as-client-contact="typeOfMembers === 'client-contacts'"
      @save="closeAndNotify"
    />
  </div>
  <Licensing v-slot="{ plan }">
    <div
      v-if="plan?.featureFlags?.limitMembers"
      class="w-full bg-violet-50/50 p-12 rounded-md border border-violet-200
      flex items-center justify-between my-16"
    >
      <p class="text-14 grow">
        {{ items?.length }} of
        <strong>{{ plan?.featureFlags?.limitMembers ?? 'unlimited' }}</strong>
        team members invited.
      </p>
      <router-link
        v-slot="{ navigate }"
        :to="{
          name: ORGANIZATION_SUBSCRIPTION,
          params: { provider: LICENSING_PROVIDER.chargebee }
        }"
        custom
      >
        <b-button
          v-if="plan?.featureFlags?.limitMembers === items?.length"
          size="sm"
          variant="tertiary"
          @click="navigate"
        >
          🚀 Upgrade to invite more team members
        </b-button>
      </router-link>
    </div>
  </Licensing>
</div>
