
<div>
  <PageHeader title="Customize Modules" />
  <Form
    v-if="state"
    v-slot="{ isBusy, submit, hasChanges }"
    :state="state"
    success-message="Project changes saved"
    :on-submit="() => save()"
  >
    <div>
      <div>
        <p class="settings-section-description mb-0">
          Customize the titles, icons, and visiblity of portal modules.
        </p>
        <p class="settings-section-description mb-16">
          Toggle on and off any module to control whether it's visible
          to your organization and/or client organization.
        </p>
      </div>
      <ul class="flex flex-col max-w-page gap-16">
        <!-- Docs & files -->
        <ProjectSettingsModule
          :value="state.modules.documents"
          module-name="Docs &amp; Files"
          :default-icon="PROJECT_MODULE_ICON.iconDocuments"
          @update="val => update('documents', val)"
        />

        <!-- Action Items -->
        <ProjectSettingsModule
          :value="state.modules.tasks"
          module-name="Tasks"
          :default-icon="PROJECT_MODULE_ICON.iconActionItems"
          @update="val => update('tasks', val)"
        />

        <!-- Timeline -->
        <ProjectSettingsModule
          :value="state.modules.timeline"
          module-name="Timeline"
          :default-icon="PROJECT_MODULE_ICON.iconTimeline"
          @update="val => update('timeline', val)"
        />

        <!-- Packaged Services -->
        <ProjectSettingsModule
          :value="state.modules.packagedServices"
          module-name="Packaged Services"
          :default-icon="PROJECT_MODULE_ICON.iconPackagedServices"
          @update="val => update('packagedServices', val)"
        />

        <!-- Pinned Folders -->
        <ProjectSettingsModule
          :value="state.modules.pinnedFolders"
          module-name="Pinned Folders"
          @update="val => update('pinnedFolders', val)"
        />

        <!-- Pages -->
        <ProjectSettingsModule
          :value="state.modules.pages"
          module-name="Pages"
          @update="val => update('pages', val)"
        />

        <!-- Quick Links -->
        <ProjectSettingsModule
          :value="state.modules.quickLinks"
          module-name="Quick Links"
          @update="val => update('quickLinks', val)"
        />

        <!-- Embedded Apps -->
        <ProjectSettingsModule
          :value="state.modules.embeddedApps"
          module-name="Embedded Apps"
          @update="val => update('embeddedApps', val)"
        />

        <!-- Integrations -->
        <ProjectSettingsModule
          :value="state.modules.integrations"
          module-name="Integrations"
          @update="val => update('integrations', val)"
        />

        <!-- Recently Updated -->
        <ProjectSettingsModule
          :value="state.modules.recentlyUpdated"
          module-name="Recently Updated"
          @update="val => update('recentlyUpdated', val)"
        >
          <template #description>
            Lists <span class="font-bold">Recently Updated</span>
            documents on the client-facing Shared Folder.
          </template>
        </ProjectSettingsModule>
      </ul>
    </div>
    <PageFooter>
      <Button
        :disabled="isBusy"
        size="lg"
        variant="primary"
        type="submit"
        @click="submit"
      >
        Save Changes
      </Button>
      <template v-if="hasChanges" #right>
        <PageFooterMessage />
      </template>
    </PageFooter>
  </Form>
</div>
