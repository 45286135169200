<template>
  <nav>
    <SidebarGroup>
      <SidebarMenu>
        <SidebarMenuLink
          :to="{ name: USER_SETTINGS_PROFILE }"
        >
          <SidebarItemBasic label="My Account" icon="icon_v2-so_user-circle" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: USER_SETTINGS_SECURITY }"
        >
          <SidebarItemBasic label="Security" icon="icon_v2-so_lock" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: USER_SETTINGS_NOTIFICATIONS }"
        >
          <SidebarItemBasic label="Notifications" icon="icon_v2-so_notification-ringing" />
        </SidebarMenuLink>
      </SidebarMenu>
    </SidebarGroup>
  </nav>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  USER_SETTINGS_NOTIFICATIONS,
  USER_SETTINGS_PROFILE,
  USER_SETTINGS_SECURITY,
} from '@/router/user/type'
import SidebarGroup from '@/components/Sidebar/SidebarGroup.vue'
import SidebarMenu from '@/components/Sidebar/SidebarMenu.vue'
import SidebarMenuLink from '@/components/Sidebar/SidebarMenuLink.vue'
import SidebarItemBasic from '@/components/Sidebar/SidebarItemBasic.vue'

export default defineComponent({
  name: 'UserSettingsNav',
  components: {
    SidebarGroup,
    SidebarMenu,
    SidebarMenuLink,
    SidebarItemBasic,
  },
  setup() {
    return {
      USER_SETTINGS_NOTIFICATIONS,
      USER_SETTINGS_PROFILE,
      USER_SETTINGS_SECURITY,
    }
  },
})
</script>
