
<div class="pb-24">
  <PageHeader title="My Organization" />
  <Form
    v-if="state"
    v-slot="{ isBusy, v, submit, hasChanges }"
    :state="state"
    :validation="validation"
    success-message="Organization settings saved"
    :on-submit="() => save()"
  >
    <div class="space-y-16">
      <FormField
        :v="v.name"
        label="Organization name"
        wrapper-class="max-w-510"
      >
        <b-input
          v-model="state.name"
          size="lg"
        />
      </FormField>

      <div class="max-w-510">
        <FormField
          :v="v.currency"
          label="Default Currency"
          description="Changing the currency will be reflected across all documents, old and new."
        >
          <FormInputCurrencies
            v-model="state.currency"
            class="form-control-lg"
          />
        </FormField>
      </div>
    </div>

    <div class="space-y-48">
      <section>
        <!-- Logo -->
        <h4 class="project-page-h3 mt-48">Logo</h4>
        <p class="text-12 text-gray-600">
          A .png with transparency or .svg will look best.
        </p>

        <div class="flex flex-col md:flex-row gap-40 mt-16">
          <div :class="$style['company-logo-area']">
            <h3 class="font-medium text-14">Primary</h3>
            <p class="mb-8 text-12 text-gray-500">Used on light backgrounds</p>
            <div :class="$style['company-logo']">
              <LogoUpload
                v-model="state.logoUrl"
                :height="80"
                :width="240"
                :display-height="100"
                :display-width="320"
                class-name="p-0"
                @uploading="uploadLogo"
              >
                <template #buttons="{ selectFiles, removeFile }">
                  <Button
                    v-if="!state.logoUrl"
                    variant="dark"
                    size="xs"
                    @click="selectFiles"
                  >
                    Upload Logo
                  </Button>
                  <template v-else>
                    <Button
                      variant="dark"
                      size="xs"
                      @click="selectFiles"
                    >
                      Replace
                    </Button>
                    <Button
                      variant="danger"
                      size="xs"
                      @click="removeFile"
                    >
                      Remove
                    </Button>
                  </template>
                </template>
              </LogoUpload>
            </div>
          </div>
          <div :class="$style['company-logo-area']">
            <h3 class="font-medium text-14">Reversed</h3>
            <p class="mb-8 text-12 text-gray-500">Used on dark backgrounds</p>
            <div :class="$style['company-logo']">
              <LogoUpload
                v-model="state.logoDarkUrl"
                :height="80"
                :width="240"
                :display-height="100"
                :display-width="320"
                variant="dark"
                class-name="p-0"
                @uploading="uploadLogo"
              >
                <template #buttons="{ selectFiles, removeFile }">
                  <Button
                    v-if="!state.logoDarkUrl"
                    variant="dark"
                    size="xs"
                    @click="selectFiles"
                  >
                    Upload Logo
                  </Button>
                  <template v-else>
                    <Button
                      variant="dark"
                      size="xs"
                      @click="selectFiles"
                    >
                      Replace
                    </Button>
                    <Button
                      variant="danger"
                      size="xs"
                      @click="removeFile"
                    >
                      Remove
                    </Button>
                  </template>
                </template>
              </LogoUpload>
            </div>
          </div>
        </div>
      </section>

      <!-- Colors -->
      <section>
        <h3 class="project-page-h3">Color Palette</h3>
        <p class="text-12 text-gray-600 mb-24">
          This color palette will be available in all your documents and projects.
        </p>
        <ColorPalette v-model="state.brandingColors" />
      </section>
      <!-- Fonts -->
      <section>
        <h3 class="project-page-h3">Fonts</h3>
        <p class="text-12 text-gray-600 mb-24">
          Choose the default fonts used on headings
          or paragraphs across your projects and documents
        </p>
        <!-- Fonts  -->
        <FormPickFont
          :has-title="false"
          :model="state"
          @change-font="openModal"
        />
      </section>
    </div>
    <PageFooter>
      <Button
        :disabled="isBusy"
        size="lg"
        variant="primary"
        type="submit"
        @click="submit"
      >
        Save Changes
      </Button>
      <template v-if="hasChanges" #right>
        <PageFooterMessage />
      </template>
    </PageFooter>
  </Form>
  <BrandingFontsModal
    v-if="isModalOpen"
    v-model="isModalOpen"
    :organization="organization"
    :apply-font-on="applyFontOn"
    :model="state"
    @select="selectFont"
  />
</div>
