
<div>
  <PageHeader
    title="Tasks"
    :search="!$isMobile"
    :search-keyword.sync="keyword"
  />
  <div
    v-if="!isPending"
    class="project-page project-page-wrapper"
  >
    <!-- Dashboard Header -->
    <section class="mb-24">
      <ActionItemsTable
        v-if="!$isMobile"
        :action-items="items"
        :keyword="keyword"
      />
      <ActionItemsCards
        v-else
        :action-items="items"
        :keyword="keyword"
      />
    </section>
  </div>
</div>
