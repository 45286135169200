<template>
  <div>
    <PageHeader
      :title="
        !client ? 'New Client Organization' : 'Edit Client Organization'
      "
      :show-back="Boolean(client)"
      @back="redirect(client?._id)"
    />
    <ClientEditor
      :organization="organization"
      :client-id="client?._id ?? 'new'"
      @saved="redirect"
      @removed="redirect"
    />
  </div>
</template>
<script>
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { defineComponent } from '@vue/composition-api'
import { ORGANIZATION_CLIENT } from '@/router/organization/type'
import ClientEditor from '@/components/ClientEditor.vue'
import PageHeader from '@/components/PageHeader.vue'

export default defineComponent({
  name: 'ViewOrganizationClientDetails',
  components: {
    ClientEditor,
    PageHeader,
  },
  props: {
    client: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const organization = useCurrentOrganization()
    const { routerPush } = useRouter()

    const redirect = clientId => {
      routerPush({
        name: ORGANIZATION_CLIENT,
        params: { clientId },
      })
    }

    return {
      organization,
      redirect,
    }
  },
})
</script>
