
<button
  class="inline-flex items-center justify-center w-32 h-32 text-white"
  @click="$emit('click')"
  :class="classes()"
>
  <span v-if="text">
    {{ text }}
  </span>
  <slot />
</button>
