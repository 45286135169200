<template>
  <ModalBasic
    :title="modalTitle"
    :should-close-on-backdrop="false"
    :hide-footer="true"
    content-class="modal-content-tertiary"
    :body-class="$style['modal-body-apps']"
    :header-class="$style['modal-header-apps']"
    footer-class="modal-footer-tertiary"
    :title-class="$style['modal-title-apps']"
    size="lg"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #body="{ close }">
      <EmbeddedAppForm
        :app="linkedAppDefaults"
        :variant="appPreset ? 'url':'embedCode'"
        :instructions="appPreset ? appPreset.instructions : null"
        @submit="data => !(void $tracking.track('New Embedded App Created')) && saveFormData(data)"
      >
        <template #buttons="{ isFormBusy, isFormInvalid }">
          <div class="mb-32 mt-auto">
            <Button
              :disabled="isFormInvalid || isFormBusy"
              type="submit"
              size="md"
              variant="primary"
            >
              Save App
            </Button>
          </div>
        </template>
      </EmbeddedAppForm>
    </template>
  </ModalBasic>
</template>
<script>
import { nanoid } from 'nanoid'
import { defineComponent, computed } from '@vue/composition-api'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import EmbeddedAppForm from '@/components/EmbeddedAppForm.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'ProjectSettingsEmbeddedAppModal',
  components: {
    ModalBasic,
    EmbeddedAppForm,
    Button,
  },
  props: {
    formError: {
      type: Object,
      default: null,
    },
    appPreset: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    /** `true` if this is an edit operation for an already embedded app, `false` if this is a
     * create operation */
    const isEditOperation = computed(() => Boolean(props.appPreset?._id))
    const modalTitle = computed(() => (isEditOperation.value ? 'Edit app' : 'Add new app'))
    const linkedAppDefaults = computed(() => (isEditOperation.value
      ? props.appPreset // Edit mode
      // Create mode. Assign presets
      : ({
        _id: nanoid(),
        icon: props.appPreset?.icon ?? 'icon_v2-so_apps',
        title: props.appPreset?.title ?? '',
        url: '',
        description: '',
        embedCode: '',
      })))

    const saveFormData = formData => (isEditOperation.value
      ? context.emit('update', formData)
      : context.emit('create', formData))


    return {
      linkedAppDefaults,
      saveFormData,
      modalTitle,
    }
  },
})
</script>
<style lang="postcss" module>
.modal-header-apps {
  @apply min-h-0 pt-32 pb-0 mb-0;
}
.modal-body-apps {
  @apply pt-32;
}
.modal-title-apps {
  @apply text-left;
}
</style>
