<template>
  <b-form-checkbox
    v-model="isDocumentPreview"
    v-b-tooltip.hover.bottom.v-info.dh0.ds200="`
      See how this document is displayed in the
      client portal in read-only mode.`
    "
    size="md"
    switch
    class="hidden pr-4 pl-36 md:flex"
    :class="isApproved && 'opacity-50'"
    :disabled="isApproved"
  >
    <span
      class="text-14 font-semibold leading-none"
      :class="isDocumentPreview ? 'text-green-600' : 'text-gray-600'"
    >
      Preview
    </span>
  </b-form-checkbox>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const {
  useMutations: useMutationsDocumentEditor,
  useState: useStateDocumentEditor,
} = createNamespacedHelpers('documentEditor')

export default defineComponent({
  name: 'DocPreviewToggle',
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isApproved = computed(() => Boolean(props.document.status === 'APPROVED'))

    // document preview
    const { previewDocumentId } = useStateDocumentEditor(['previewDocumentId'])
    const { setPreviewDocumentId } = useMutationsDocumentEditor([
      'setPreviewDocumentId',
    ])
    const isDocumentPreview = computed({
      get() {
        return previewDocumentId.value === props.document._id
      },
      set(value) {
        setPreviewDocumentId(value ? props.document._id : null)
      },
    })

    return {
      isDocumentPreview,
      isApproved,
    }
  },
})
</script>
