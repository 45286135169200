<template>
  <ModalBasic
    :title="title"
    :hide-footer="true"
    dialog-class="modal-dialog-tertiary"
    content-class="modal-content-tertiary"
    body-class="modal-body-tertiary"
    header-class="modal-header-tertiary"
    footer-class="modal-footer-tertiary"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #body="{ close }">
      <div class="fade-to-white fade-from-bottom">
        <div class="flex flex-col space-y-6 px-16 pb-88 overflow-y-auto max-h-vh-2/3 min-h-384">
          <ListMultiSelectDocuments
            :value="selection"
            :documents="documents"
            :hide-cost="hideCost"
            @input="updateSelection"
          />
        </div>
      </div>
      <Buttons>
        <Button
          type="button"
          variant="neutral"
          size="md"
          @click="close"
        >
          Cancel
        </Button>
        <b-button
          type="submit"
          variant="primary"
          size="md"
          @click="done"
        >
          Save
        </b-button>
      </Buttons>
    </template>
  </ModalBasic>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { pluck } from 'ramda'
import { arrayDiff } from '@/lib/array'
import Button from '@/components/Button.vue'
import Buttons from '@/components/Buttons.vue'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import ListMultiSelectDocuments from '@/components/ListMultiSelectDocuments.vue'

const pluckId = pluck('_id')

export default defineComponent({
  name: 'SelectDocumentsModal',
  components: {
    Button,
    Buttons,
    ModalBasic,
    ListMultiSelectDocuments,
  },
  props: {
    title: {
      type: String,
      default: 'Add documents from your library',
    },
    buttonLabel: {
      type: String,
      default: 'Documents',
    },
    selection: {
      type: Array,
      required: true,
    },
    documents: {
      type: Array,
      default: null,
    },
    hideCost: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const done = () => {
      context.emit('done')
    }

    const initialSelection = [...props.selection]

    const updateSelection = newSelection => {
      const { removed, added } = arrayDiff(initialSelection, newSelection)
      const removedSet = new Set(removed)
      const addedSet = new Set(added)

      const newOrderedSelection = [
        ...initialSelection.filter(item => !removedSet.has(item)),
        ...pluckId(props.documents.filter(doc => addedSet.has(doc._id))),
      ]

      context.emit('update:selection', newOrderedSelection)
    }

    return {
      updateSelection,
      done,
      initialSelection,
    }
  },
})
</script>
