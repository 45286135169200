import ViewOrganization from '@/views/ViewOrganization.vue'
import ViewOrganizationProjects from '@/views/ViewOrganizationProjects.vue'
import ViewOrganizationTasks from '@/views/ViewOrganizationTasks.vue'
import ViewNotifications from '@/views/ViewNotifications.vue'

import clients from './clients'
import templates from './templates'
import library from './library'
import settings from './settings'
import payment from './payment'

import userRoutes from '../user'
import projectRoutes from '../project'

import {
  ORGANIZATION,
  ORGANIZATION_HOME,
  ORGANIZATION_TASKS,
  ORGANIZATION_INBOX,
} from './type'

export default container => [
  {
    name: ORGANIZATION,
    path: '/o/:organizationId',
    component: ViewOrganization,
    props: true,
    meta: {
      requiresAuth: true,
    },
    redirect: {
      name: ORGANIZATION_HOME,
    },
    children: [
      {
        name: ORGANIZATION_HOME,
        path: 'home',
        component: ViewOrganizationProjects,
      },
      {
        name: ORGANIZATION_TASKS,
        path: 'tasks',
        component: ViewOrganizationTasks,
      },
      {
        name: ORGANIZATION_INBOX,
        path: 'inbox',
        // component: () => import('@/views/ViewNotifications.vue'),
        component: ViewNotifications,
      },
      ...clients,
      ...templates,
      ...library,
      ...settings,
      ...payment,
      ...userRoutes(container),
      ...projectRoutes(container),
    ],
  },
]
