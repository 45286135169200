<template>
  <MemberRole v-slot="{ admin, projectManager }">
    <div class="flex items-center justify-between w-full">
      <router-link
        v-slot="{ navigate }"
        :to="{ name: PROJECT_DASHBOARD }"
        custom
      >
        <ButtonTransparent class="project-button" @click="navigate">
          <ProjectSwitcherItem :project="project" @click="navigate" />
        </ButtonTransparent>
      </router-link>
      <ProjectMenu
        :project="project"
        class="-mr-8"
        :items="{
          settings: admin || projectManager,
          portalPreview: true,
          duplicate: admin || projectManager,
          archive: admin || projectManager,
          delete: admin || projectManager,
        }"
      />
    </div>
  </MemberRole>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  PROJECT_SETTINGS,
  PROJECT_DASHBOARD,
} from '@/router/project/type'
import ProjectSwitcherItem from '@/components/ProjectSwitcherItem.vue'
import ButtonTransparent from '@/components/ButtonTransparent.vue'
import ProjectMenu from '@/components/ProjectMenu.vue'
import MemberRole from '@/components/MemberRole.vue'

export default defineComponent({
  name: 'ProjectNavHeader',
  components: {
    ProjectSwitcherItem,
    ButtonTransparent,
    ProjectMenu,
    MemberRole,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      PROJECT_SETTINGS,
      PROJECT_DASHBOARD,
    }
  },
})
</script>
<style lang="postcss" scoped>
  .project-button {
    width: calc(100% - 16px);
  }
</style>
