<template>
  <div v-if="!isPending">
    <TasksPlaceholder
      v-if="!sections.length"
      @create-section="createSection"
    />
    <FeatureLimit
      v-else
      v-slot="{ checkFeatureLimit: checkActionItemsLimit }"
      flag="limitActionItems"
      label="action items"
      @allow="createTask"
    >
      <TaskBoard
        :selected-task-id="$route.params.taskId"
        :sections="sections"
        @update-section="updateSection"
        @remove-section="removeSection"
        @reorder-sections="reorderSections"
        @section-menu-action="handleSectionMenuAction"
        @create-task="checkActionItemsLimit"
        @update-task="updateTask"
        @remove-task="removeTask"
        @move-task="moveTask"
      >
        <template #task="{ section, task }">
          <router-link
            v-slot="{ navigate }"
            :to="{
              name: PROJECT_TASKS_LIST_TASK,
              params: {
                sectionId: section.taskSection._id,
                taskId: task._id
              }
            }"
            custom
          >
            <ProjectTaskListItem
              :task="task"
              @update="updateTask"
              @remove="removeTask(section, task)"
              @click="navigate"
            />
          </router-link>
        </template>
      </TaskBoard>
    </FeatureLimit>
    <router-view
      :project="project"
      @remove="task => removeTask(null, task)"
      @created="goToTasks"
      @updated="goToTasks"
    />
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { PROJECT_TASKS_LIST, PROJECT_TASKS_LIST_TASK } from '@/router/project/type'
import { useProjectTasks } from '@/v2/services/projects/compositions'
import TaskBoard from '@/components/Tasks/TaskBoard.vue'
import TasksPlaceholder from '@/components/Tasks/TasksPlaceholder.vue'
import ProjectTaskListItem from '@/components/ProjectTaskListItem.vue'
import FeatureLimit from '@/components/FeatureLimit.vue'

export default defineComponent({
  name: 'ViewProjectTasksList',
  components: {
    TaskBoard,
    TasksPlaceholder,
    ProjectTaskListItem,
    FeatureLimit,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    viewContext: {
      type: Object,
      required: true,
    },
    keyword: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { routerPush } = useRouter()
    const project = computed(() => props.project)

    const options = computed(() => ({
      viewContext: props.viewContext,
      keyword: props.keyword,
    }))

    const {
      selectedTaskId,
      sections,
      isPending,

      // sections
      createSection: _createSection,
      updateSection,
      removeSection,
      reorderSections,
      handleSectionMenuAction,

      // tasks
      removeTask,
      updateTask,
      moveTask,
    } = useProjectTasks(project, options)

    const createTask = async section => {
      routerPush({
        name: PROJECT_TASKS_LIST_TASK,
        params: {
          sectionId: section.taskSection._id,
          taskId: 'new',
        },
      })
    }

    const createSection = async () => {
      const section = await _createSection()
      document.getElementById(`section-${section._id}`).scrollIntoView()
    }

    const goToTasks = () => routerPush({ name: PROJECT_TASKS_LIST })

    return {
      selectedTaskId,
      sections,
      isPending,
      handleSectionMenuAction,

      // sections
      createSection,
      updateSection,
      removeSection,
      reorderSections,

      // tasks
      createTask,
      updateTask,
      removeTask,
      moveTask,

      goToTasks,

      PROJECT_TASKS_LIST_TASK,
    }
  },
})
</script>
