import Vue from 'vue'
import Vuex from 'vuex'
import { FeathersVuex } from '@/v2/services/feathersClientService'
import feathersVuexPlugins from '@/v2/services/store'

// modules
import documentEditor from './documentEditor'
import globalFlags from './globalFlags'

Vue.use(Vuex)
Vue.use(FeathersVuex)

export default new Vuex.Store({
  plugins: feathersVuexPlugins,
  modules: {
    documentEditor,
    globalFlags,
  },
})
