<template>
  <div>
    <SettingsEmailStatus
      class="mb-24"
      :organization="organization"
      :cancel-is-loading="cancelSetupLoading"
      :cancel-error="cancelSetupError?.message"
      @cancel="cancelSetup"
    />
    <SettingsEmailForm
      v-if="![STATUS.cancelSetupRequested, STATUS.cancelSetupPending].includes(status)"
      :organization="organization"
    />
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { EMAIL_STATUS as STATUS } from '@/v2/services/organizations/organizationsTypes'
import useAsyncTask from '@/v2/lib/composition/useAsyncTask'
import { useMsgBoxConfirm } from '@/v2/lib/composition/useMsgBox'
import SettingsEmailStatus from '@/components/SettingsEmailStatus.vue'
import SettingsEmailForm from '@/components/SettingsEmailForm.vue'

export default defineComponent({
  name: 'SettingsEmailSetup',
  components: {
    SettingsEmailStatus,
    SettingsEmailForm,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const status = computed(() => props.organization.emailStatus)

    const msgBoxConfirm = useMsgBoxConfirm()

    // cancel SETUP
    const {
      exec: requestCancelSetup,
      isBusy: cancelSetupLoading,
      error: cancelSetupError,
    } = useAsyncTask(() => props.organization.patch({
      data: { emailStatus: STATUS.cancelSetupRequested },
    }))

    const cancelSetup = async () => {
      const ok = await msgBoxConfirm({
        okLabel: 'Yes, Cancel Setup',
        cancelLabel: 'No, Continue Setup',
        okVariant: 'danger',
        size: 'lg',
        title: 'Cancel Email Setup in Progress?',
        message:
          'Do you want to cancel the automatic email setup in progress? '
          + 'If cancelled, the setup process must be started from the beginning. You can use the same email or choose a different one.',
      })

      if (ok) {
        requestCancelSetup()
      }
    }

    return {
      // domain status
      STATUS,
      status,

      cancelSetup,
      cancelSetupLoading,
      cancelSetupError,
    }
  },
})
</script>
