
<NotificationsInbox show-document>
  <template
    #header="{
      hasUnreadNotifications,
      isPendingMarkAllAsRead,
      markAllAsRead
    }"
  >
    <PageHeader title="Inbox">
      <template #actions>
        <Button
          v-if="hasUnreadNotifications"
          type="button"
          variant="neutral"
          size="md"
          :is-loading="isPendingMarkAllAsRead"
          @click="markAllAsRead"
        >
          <span class="mr-4 -ml-4 icon_v2-so_ok text-green-600" />
          Mark All As Read
        </Button>
      </template>
    </PageHeader>
  </template>
</NotificationsInbox>
