<template>
  <DocumentNodeEditorToolbarPopover
    :width="320"
    class-name="w-32 rounded hover:bg-gray-500 hover:bg-opacity-10"
    tooltip="Heading size"
  >
    <template #button-content>
      <span
        :class="[
          'w-24 text-24 text-white',
          `icon-toolbar-heading-${value[value.length - 1]}`,
        ]"
      ></span>
      <span :class="[$style['toolbar-dropdown']]"></span>
    </template>
    <!-- <template #title>
      Heading Size
    </template> -->
    <template #default>
      <ul class="p-0 m-0">
        <li
          v-for="size in sizes"
          :key="size.value"
          class="mb-8 list-none"
        >
          <button
            :class="[
              {
                'bg-green-500 bg-opacity-20 hover:opacity-100':
                  size.value === value,
              },
              $style[`heading-${size.value}`],
              'font-semibold text-white rounded text-left hover:no-underline hover:bg-green-500 hover:bg-opacity-20 px-12 w-full',
            ]"
            @click="$emit('input', size.value)"
          >
            {{ size.label }}
          </button>
        </li>
      </ul>
    </template>
  </DocumentNodeEditorToolbarPopover>
</template>
<script>
import IconBase from '@/components/Icons/IconBase.vue'
import IconToolbarHeading from '@/components/Icons/IconToolbarHeading.vue'
import DocumentNodeEditorToolbarPopover from './DocumentNodeEditorToolbarPopover.vue'

export default {
  name: 'DocumentNodeEditorToolbarFontSize',
  components: {
    DocumentNodeEditorToolbarPopover,
    IconBase,
    IconToolbarHeading,
  },
  props: {
    sizes: Array,
    value: String,
  },
}
</script>
<style lang="scss" module>
.heading {
  font-weight: $semibold;
  cursor: pointer;

  &:hover {
    background-color: rgba(101, 106, 121, 0.2);
  }
}
.heading-h1 {
  @apply text-32 md:text-42 lg:text-56 leading-snug font-bold;
}

.heading-h2 {
  @apply text-24 md:text-32 lg:text-42 leading-snug font-bold;
}

.heading-h3 {
  @apply text-20 md:text-24 lg:text-32 leading-snug font-bold;
}

.heading-h4 {
  @apply text-18 md:text-20 lg:text-24 leading-snug font-bold;
}

.list {
  :global(.list-group-item.active) {
    color: $green;
  }
}
.toolbar-dropdown {
  border-width: 0 0 6px 6px;
  border-color: transparent transparent rgba(255, 255, 255, 0.4) transparent;
  @apply w-0 h-0 border-solid absolute bottom-0 right-0;
}
</style>

<style lang="scss" scoped>
.toolbar-heading {
  font-size: 24px;
  fill: #aaa;
  &:hover {
    fill: #666;
  }
}
</style>
