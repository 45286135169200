<template>
  <div>
    <PageHeader
      title="Tasks"
      :search="!$isMobile"
      :search-keyword.sync="keyword"
    />
    <div
      v-if="!isPending"
      class="project-page project-page-wrapper"
    >
      <!-- Dashboard Header -->
      <section class="mb-24">
        <ActionItemsTable
          v-if="!$isMobile"
          :action-items="items"
          :keyword="keyword"
        />
        <ActionItemsCards
          v-else
          :action-items="items"
          :keyword="keyword"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useFindActionItems } from '@/v2/services/actionItems/compositions'
import ActionItemsTable from '@/components/ActionItemsTable.vue'
import ActionItemsCards from '@/components/ActionItemsCards.vue';
import PageHeader from '@/components/PageHeader.vue';

export default defineComponent({
  name: 'ViewOrganizationTasks',
  metaInfo: {
    title: 'Action Items',
  },
  components: {
    ActionItemsTable,
    PageHeader,
    ActionItemsCards,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const keyword = ref('')
    const { items, isPending } = useFindActionItems()

    return {
      keyword,
      items,
      isPending,
    }
  },
})
</script>
