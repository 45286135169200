
<button
  :class="['card-interactive transition-all cursor-pointer',
           'text-14 text-center text-gray-600 font-semibold',
           'flex flex-col items-center w-88 py-8']"
  @click.stop="addColumn"
>
  <span
    :class="['w-24 text-24 text-gray-700',
             `icon-columns-variation-${$props.ratio.join('')}`
    ]"
  />
  <span>{{ showRatio }}</span>
</button>
