
<ul class="flex">
  <template v-if="items.length">
    <li
      v-for="(item, index) in visibleItems"
      :key="item.member._id"
      class="flex flex-row items-center"
      :class="{'-ml-10': index > 0}"
    >
      <template v-if="item.user">
        <Avatar
          :profile="item.user.profile$"
          :size="32"
          font-size="14"
        />
      </template>
    </li>
    <li v-if="remainingCount > 0" class="-ml-10">
      <span
        class="
              w-32 h-32 inline-flex bg-white rounded-100
              items-center justify-center font-semibold text-15
            "
      >+{{ remainingCount }}</span>
    </li>
  </template>
</ul>
