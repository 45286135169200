<template>
  <div class="space-y-12 flex flex-col justify-stretch items-center">
    <!-- Logo -->
    <span class="w-48 text-48 icon-superokay-symbol text-white md:text-green-500"></span>
    <!-- Welcome message -->
    <div class="text-center mb-40">
      <h1 class="text-24 text-gray-900 font-semibold">
        Welcome back
      </h1>
    </div>
    <!-- NOTE: Suppress "There was an error submitting the form" generic message -->
    <FormMessage v-if="formError" supress-generic-message>
      {{ formError.message }}
    </FormMessage>
    <ButtonGoogleAuth message="Sign in with Google" />
    <div class="flex flex-row w-full items-center justify-stretch space-x-16">
      <span class="h-2 block flex-1 bg-gray-200 rounded-full" />
      <span class="text-14 text-gray-500 flex text-center">or use email</span>
      <span class="h-2 block flex-1 bg-gray-200 rounded-full" />
    </div>
    <b-form class="space-y-12 w-full" @submit.prevent="formSubmit">
      <FormField
        :v="$v.email"
        :messages="{
          email: `This doesn't look like a valid email address`,
          required: 'A valid email address is required',
        }"
      >
        <b-input
          autofocus
          autocomplete="username"
          placeholder="Email Address"
          class="form-control-lg"
          :value="formState.email"
          @input="onEmailInput"
        />
      </FormField>

      <FormField
        :v="$v.password"
        :messages="{
          required: 'We need your password to log you in.',
        }"
      >
        <FormInputPassword
          v-model="formState.password"
          placeholder="Password"
          autocomplete="current-password"
        />
      </FormField>
      <div :class="$style.links">
        <b-link @click="$emit('recover-password')">
          Recover Your Password
        </b-link>
      </div>
      <Button
        type="submit"
        size="md"
        variant="primary"
        :block="true"
        :is-loading="formIsBusy"
      >
        Sign in
      </Button>
    </b-form>
  </div>
</template>

<script>
import { defineComponent, reactive } from '@vue/composition-api'
import { required, email } from '@vuelidate/validators'
import { email as emailSanitizer } from '@/v2/lib/helpers/sanitizer'
import { useUser } from '@/v2/services/users/usersCompositions'
import useForm from '@/v2/lib/composition/useForm'
import FormField from '@/components/FormField.vue'
import Button from '@/components/Button.vue'
import FormMessage from '@/components/FormMessage.vue'
import ButtonGoogleAuth from '@/components/ButtonGoogleAuth.vue'
import FormInputPassword from './FormInputPassword.vue'

export default defineComponent({
  name: 'SignInForm',
  components: {
    FormField,
    Button,
    FormMessage,
    ButtonGoogleAuth,
    FormInputPassword,
  },
  setup() {
    const { authenticateLocal } = useUser()

    const formState = reactive({
      email: '',
      password: '',
    })

    const { formSubmit, formError, formIsBusy, $v } = useForm({
      onSubmit: () => authenticateLocal(formState),
      state: formState,
      validation: {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    })

    const onEmailInput = value => {
      formState.email = emailSanitizer(value)
    }

    return {
      formIsBusy,
      formState,
      formSubmit,
      formError,
      $v,
      onEmailInput,
    }
  },
})
</script>

<style lang="scss" module>
.or {
  position: relative;
  text-align: center;
  font-size: $fs18;
  color: $gray-500;
  font-weight: $medium;
  margin: 20px 0;
  &:before {
    z-index: 1;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 1px;
    background-color: $gray-300;
  }
  span {
    position: relative;
    display: inline-block;
    background-color: white;
    padding: 8px;
    z-index: 2;
  }
}
.group {
  margin-bottom: 14px;
}
.pass {
  margin-bottom: 0;
}
.links {
  color: $gray-500;
  font-size: $fs14;
  margin-bottom: 14px;
  a {
    color: $gray-500;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
