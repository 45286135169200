<template>
  <CheckMemberRole
    v-slot="{ hasRole: isProjectAdmin }"
    :roles="['admin', 'projectManager']"
  >
    <PageHeader
      search
      :search-keyword.sync="keyword"
      search-placeholder="Search by title..."
      :show-search-result.sync="showSearchResult"
    >
      <div class="flex items-center gap-x-28 text-14 text-gray-600">
        <div v-if="teamMembers.length > 0" class="flex items-center gap-x-8">
          <span>Your Team</span>
          <ProjectDashboardPageHeaderMembers :items="teamMembers" />
        </div>
        <div v-if="clientContacts.length > 0" class="flex items-center gap-x-8">
          <span>Client's Team</span>
          <ProjectDashboardPageHeaderMembers :items="clientContacts" />
        </div>
        <Button
          v-if="isProjectAdmin"
          variant="transparent"
          :to="{ name: PROJECT_SETTINGS_MANAGE_ACCESS }"
          class="text-gray-700 border border-gray-400"
        >
          Manage Access
        </Button>
      </div>
      <template #search-result>
        <ProjectDashboardPageHeaderSearch :keyword="keyword" />
      </template>
    </PageHeader>
  </CheckMemberRole>
</template>
<script>
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { ROLES, STATUS } from '@/v2/services/members/membersTypes'
import { PROJECT_SETTINGS_MANAGE_ACCESS } from '@/router/project/type'
import { useFindMembers } from '@/v2/services/members/compositions'
import PageHeader from '@/components/PageHeader.vue'
import ProjectDashboardPageHeaderMembers from '@/components/ProjectDashboardPageHeaderMembers.vue'
import Button from '@/components/Button.vue'
import ProjectDashboardPageHeaderSearch from '@/components/ProjectDashboardPageHeaderSearch.vue'
import CheckMemberRole from './CheckMemberRole.vue'

export default defineComponent({
  name: 'ProjectDashboardPageHeader',
  components: {
    Button,
    PageHeader,
    ProjectDashboardPageHeaderMembers,
    ProjectDashboardPageHeaderSearch,
    CheckMemberRole,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const keyword = ref('')
    const showSearchResult = ref(false)

    const params = computed(() => ({
      query: {
        organization: props.project.organization,
        status: STATUS.ACTIVE,
        $or: [
          {
            _id: {
              $in: props.project.members,
            },
          },
          {
            role: {
              $ne: ROLES.client,
            },
          },
        ],
      },
    }))

    const { items: members } = useFindMembers({ params, joinUser: true })
    const teamMembers = computed(() => members.value.filter(m => m.member.role !== ROLES.client))
    const clientContacts = computed(() => members.value.filter(m => m.member.role === ROLES.client))

    watch(keyword, newVal => {
      showSearchResult.value = Boolean(newVal?.length)
    })

    return {
      keyword,
      teamMembers,
      clientContacts,
      PROJECT_SETTINGS_MANAGE_ACCESS,
      showSearchResult,
    }
  },
})
</script>
