import ViewSignUpOrganizationDetails from '@/views/ViewSignUpOrganizationDetails.vue'
import ViewSignUpBranding from '@/views/ViewSignUpBranding.vue'
import ViewSignUpProjectTemplates from '@/views/ViewSignUpProjectTemplates.vue'
// import ViewSignUpDemoPortal from '@/views/ViewSignUpDemoPortal.vue'
import LayoutOnboarding from '@/views/LayoutOnboarding.vue'

import {
  ONBOARDING_ORGANIZATION_DETAILS,
  ONBOARDING_BRANDING,
  ONBOARDING_PROJECT_TEMPLATES,
  WELCOME,
} from './type'

export default () => [
  {
    name: WELCOME,
    path: '/welcome/:organizationId',
    component: LayoutOnboarding,
    meta: {
      requiresAuth: true,
    },
    redirect: {
      name: ONBOARDING_ORGANIZATION_DETAILS,
    },
    children: [
      {
        name: ONBOARDING_ORGANIZATION_DETAILS,
        path: 'organization',
        component: ViewSignUpOrganizationDetails,
      },
      {
        name: ONBOARDING_BRANDING,
        path: 'branding',
        component: ViewSignUpBranding,
        props: true,
      },
      {
        name: ONBOARDING_PROJECT_TEMPLATES,
        path: 'project-templates',
        component: ViewSignUpProjectTemplates,
        props: true,
      },
    ],
  },
]
