
<div class="flex flex-row items-center gap-6 flex-1 w-full">
  <Avatar
    :profile="item.user?.profile$"
    :name="fullName"
    :size="32"
    font-size="14"
  />
  <div class="truncate px-2 py-2">
    <div class="flex flex-wrap items-center mb-2 gap-4">
      <p class="font-semibold text-gray-900 text-14 truncate dark:text-darkGray-300">
        {{ fullName }}
      </p>
      <BadgeMemberRole :member="item.member" />
      <Badge
        v-if="isInvited"
        class="table-disabled-text"
        variant="tertiary"
      >
        Invited
      </Badge>
    </div>
    <div class="flex items-center gap-6">
      <span
        class="text-12 truncate"
        :class="{
          'text-gray-500': !isInvited,
          'text-gray-300': isInvited
        }"
      >
        {{ email }}
      </span>
    </div>
  </div>
</div>
