
<div v-fragment>
  <CardSettings :step="step">
    <CardSettingsHeader
      title="Your client"
      info="Select the client organization you are working with on this project."
    >
      <template #button>
        <FeatureLimit
          v-slot="{ checkFeatureLimit }"
          flag="limitClients"
          label="clients"
          @allow="createClient"
        >
          <Button
            type="button"
            variant="primary"
            size="sm"
            @click="checkFeatureLimit"
          >
            <span class="icon_v2-so_users mr-4"></span>
            Add client
          </Button>
        </FeatureLimit>
      </template>
    </CardSettingsHeader>
    <b-form-group>
      <FormField>
        <div class="flex">
          <FormInputClients
            :value="stateClient"
            :organization-id="organization._id"
            size="md"
            class="flex-1"
            @change="onClientChange"
          />
        </div>
      </FormField>
    </b-form-group>
    <CardSettingsHeader
      title="Client contacts"
      :info="stateClient
        ? 'Who from the client\'s team can access this project?'
        : 'To add client contacts and manage their access, you must first select a client.'
      "
      :warn="!stateClient"
    >
      <template #button>
        <FeatureLimit
          v-slot="{ checkFeatureLimit }"
          flag="limitClientContacts"
          label="contact"
          @allow="createContact"
        >
          <Button
            type="button"
            variant="primary"
            size="sm"
            :disabled="!stateClient"
            @click="checkFeatureLimit"
          >
            <span class="icon_v2-so_users mr-4"></span>
            Add client contact
          </Button>
        </FeatureLimit>
      </template>
    </CardSettingsHeader>
    <TeamManager
      v-model="state"
      :organization-id="organization._id"
      :query="query"
      :query-selectable="querySelectable"
      :disabled="!stateClient"
      disabled-message="No client selected"
      empty-message="No contacts"
      button-title="Manage client access"
      select-people-title="Add people from client's team"
      select-people-description="Who from the client's team can access this project?"
    />
  </CardSettings>

  <!-- MODALS -->
  <TeamMemberFormModal
    v-if="isContactModalOpen"
    v-model="isContactModalOpen"
    :organization-id="organization._id"
    :client-id="stateClient"
    create-as-client-contact
    @saved="onContactCreated"
  />

  <ClientModal
    v-if="isClientModalOpen"
    v-model="isClientModalOpen"
    :organization="organization"
    @save="onClientCreated"
  />
</div>
