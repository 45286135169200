<script>
import { defineComponent, watch, computed, onUnmounted } from '@vue/composition-api'
import { useRouter } from '@/v2/lib/composition/useRouter'
// import { ORGANIZATION_HOME } from '@/router/organization/type'
import { LICENSING_PROVIDER, STATUS } from '@/v2/services/organizations/organizationsTypes'
import {
  ORGANIZATION_HOME,
  ORGANIZATION_SETTINGS_SUBSCRIPTION,
  ORGANIZATION_SETTINGS_SUBSCRIPTION_MANAGE,
  ORGANIZATION_SUBSCRIPTION,
  ORGANIZATION_SUBSCRIPTION_CHECKOUT,
  ORGANIZATION_SUBSCRIPTION_PLANS,
  ORGANIZATION_SUBSCRIPTION_SUCCESS,
} from '@/router/organization/type'

const ALLOWED_ROUTES_WHEN_NOT_ACTIVE = [
  ORGANIZATION_SETTINGS_SUBSCRIPTION,
  ORGANIZATION_SETTINGS_SUBSCRIPTION_MANAGE,
  ORGANIZATION_SUBSCRIPTION_PLANS,
  ORGANIZATION_SUBSCRIPTION_CHECKOUT,
  ORGANIZATION_SUBSCRIPTION_SUCCESS,
]

export default defineComponent({
  name: 'OrganizationStatusGuard',
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { routerReplace, router } = useRouter()

    const status = computed(() => props.organization.status)
    const isActive = computed(() => status.value === STATUS.active)

    const redirectToPayment = () => {
      console.log('redirectToPayment')
      routerReplace({
        name: ORGANIZATION_SUBSCRIPTION,
        query: { action: 'subscription-required' },
        params: {
          provider: LICENSING_PROVIDER.chargebee,
        },
      })
    }

    // const redirectToOrganizationHome = () => {
    //   routerReplace({
    //     name: ORGANIZATION_HOME,
    //     params: { organizationId: props.organization._id },
    //   })
    // }

    // handle organization status change
    const onStatusChange = () => {
      switch (status.value) {
        case STATUS.subscriptionRequired:
          redirectToPayment()
          break
        // case STATUS.active:
        //   redirectToOrganizationHome()
        //   break
        default:
          break
      }
    }

    watch(status, onStatusChange, { immediate: true })

    const unsubscribeRouter = router.beforeEach((to, from, next) => {
      if (to.name === ORGANIZATION_HOME
        && to.params.organizationId !== from.params.organizationId) {
        return next()
      }

      if (!isActive.value && !ALLOWED_ROUTES_WHEN_NOT_ACTIVE.includes(to.name)) {
        return next(false)
      }

      return next()
    })

    onUnmounted(() => {
      unsubscribeRouter()
    })

    return () => context.slots.default({ active: isActive.value })
  },
})
</script>
