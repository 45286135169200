<template>
  <FeathersVuexFind
    v-slot="{ items: documents }"
    service="documents"
    :params="findParams"
    watch="params"
  >
    <div>
      <slot name="header" v-bind="{ isEmpty: !documents.length }" />
      <slot v-if="!documents.length" name="placeholder" />
      <template v-if="documents.length">
        <slot name="folders" />
        <slot name="list" v-bind="{ documents, open, remove }" />
        <template v-if="hasDefaultSlot">
          <div
            v-for="document in documents"
            :key="document._id"
            :class="{ 'auto-margin': autoMargin }"
          >
            <slot
              v-bind="{
                document,
                open: () => open(document),
                remove: () => remove(document),
              }"
            />
          </div>
        </template>
      </template>
    </div>
  </FeathersVuexFind>
</template>

<script>
import { isString, rejectNil } from 'ramda-extension'
import { computed, defineComponent, inject } from '@vue/composition-api'
import { FeathersVuexFind } from 'feathers-vuex'
import { switchCond } from '@/v2/lib/helpers/fp'
import { useDocuments } from '@/v2/services/documents/documentsCompositions'

export default defineComponent({
  name: 'DocumentList',
  components: {
    FeathersVuexFind,
  },
  props: {
    excludeCategories: {
      type: Array,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    organizationId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      default: null,
    },
    limit: {
      type: Number,
      default: null,
    },
    sort: {
      type: Object,
      default: null,
    },
    autoMargin: Boolean,
  },
  setup(props, context) {
    const { open, remove } = useDocuments()
    const isClientPortal = inject('isClientPortal', false)

    const findParams = computed(() => {
      const category = switchCond([
        [isString(props.category), props.category],
        [Array.isArray(props.excludeCategories), { $nin: props.excludeCategories }],
        [true, null],
      ])

      return {
        query: rejectNil({
          $limit: props.limit,
          $sort: props.sort ?? { updatedAt: -1 },
          category,
          project: props.projectId,
          shared: isClientPortal ? true : null,
          organization: props.organizationId,
        }),
      }
    })

    const hasDefaultSlot = computed(() => Boolean(context.slots.default))

    return {
      findParams,
      hasDefaultSlot,
      open,
      remove,
      isClientPortal,
    }
  },
})
</script>
<style lang="scss" scoped>
.auto-margin {
  margin-bottom: $gutterx2;
}
</style>
