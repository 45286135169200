
<div class="flex w-full">
  <div class="flex flex-col mx-auto lg:pb-48 max-w-[554px] md:py-16 px-16 md:px-32">
    <div class="mb-36 text-center">
      <h1 class="font-semibold text-gray-900 text-18 md:text-24">
        Let's continue with your details
      </h1>
    </div>
    <Form
      v-if="model"
      v-slot="{ state }"
      :state="model"
      :validation="{subdomain: { minLength: minLength(3) }}"
      :on-submit="()=>saveOrgBranding()"
    >
      <div class="flex flex-col">
        <!-- Logo -->
        <section class="mb-36">
          <h3 class="pb-8 font-semibold text-gray-800 text-18">
            Logo
          </h3>
          <p class="text-14 mb-16 text-gray-500">
            A .png with transparency or .svg will look best.
          </p>

          <div class="grid-cols-2 gap-64 tablet:grid">
            <div class="mb-24 tablet:mb-0">
              <p class="text-gray-700 text-14">Primary</p>
              <p class="mb-8 text-gray-400 text-12">Used on light backgrounds</p>
              <LogoUpload
                v-model="state.logoUrl"
                :height="90"
                :width="160"
                :display-height="100"
                :display-width="320"
                class-name="p-8"
                @uploading="uploadLogo"
              >
                <template #buttons="{ selectFiles, removeFile }">
                  <Button
                    v-if="!state.logoUrl"
                    variant="neutral"
                    size="xs"
                    @click="selectFiles"
                  >
                    Upload
                  </Button>
                  <template v-else>
                    <Button
                      variant="neutral"
                      size="xs"
                      @click="selectFiles"
                    >
                      Replace
                    </Button>
                    <Button
                      variant="danger"
                      size="xs"
                      @click="removeFile"
                    >
                      Remove
                    </Button>
                  </template>
                </template>
              </LogoUpload>
            </div>
            <div>
              <p class="text-gray-700 text-14">Reversed</p>
              <p class="mb-8 text-gray-400 text-12">Used on dark backgrounds</p>
              <LogoUpload
                v-model="state.logoDarkUrl"
                :height="90"
                :width="160"
                :display-height="100"
                :display-width="320"
                variant="dark"
                class-name="p-8"
                @uploading="uploadLogo"
              >
                <template #buttons="{ selectFiles, removeFile }">
                  <Button
                    v-if="!state.logoDarkUrl"
                    variant="neutral"
                    size="xs"
                    @click="selectFiles"
                  >
                    Upload
                  </Button>
                  <template v-else>
                    <Button
                      variant="neutral"
                      size="xs"
                      @click="selectFiles"
                    >
                      Replace
                    </Button>
                    <Button
                      variant="danger"
                      size="xs"
                      @click="removeFile"
                    >
                      Remove
                    </Button>
                  </template>
                </template>
              </LogoUpload>
            </div>
          </div>
        </section>

        <!-- Brand Colors -->
        <section class="mb-40">
          <h3 class="pb-8 font-semibold text-gray-800 text-18">
            Brand Colors
          </h3>
          <p class="text-14 mb-16 text-gray-500">
            This color palette will be available in all your documents and projects.
          </p>
          <div
            v-if="!isFetchColorsLoading"
            class="flex flex-col flex-wrap"
          >
            <ColorPalette
              v-model="state.brandingColors"
              :size="56"
            />
            <p class="mt-8 text-gray-500 text-11">
              Chose your colour palette
            </p>
          </div>
          <Spinner v-else />
        </section>

        <!-- Next Button -->
        <section class="mb-40 lg:mb-24">
          <Button
            type="submit"
            :is-loading="isLoading"
            size="lg"
            variant="primary"
            class="w-full mr-8 shadow max-w-[256px]"
          >
            Continue
          </Button>
        </section>
      </div>
    </Form>
  </div>
</div>
