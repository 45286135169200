<template>
  <b-dropdown
    right
    no-caret
    variant="slim"
    boundary="window"
  >
    <template #button-content>
      <span class="my-6 icon_v2-so_menu-circle-vertical text-20"></span>
    </template>
    <b-dropdown-item
      :disabled="isLocked"
      :class="{ 'opacity-50 pointer-events-none': isLocked }"
      @click.stop="$emit('edit')"
    >
      <span class="w-16 mr-8 icon_v2-so_pencil text-16"></span>
      Edit
    </b-dropdown-item>
    <b-dropdown-item
      v-if="!member.isOwner && (isClientContact || member.status !== STATUS.INVITED)"
      @click.stop="$emit('remove')"
    >
      <span class="w-16 mr-8 icon_v2-so_trash text-16"></span>
      Remove
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isClientContact"
      :disabled="disableResendInvitation || isLocked"
      :class="{ 'opacity-50 pointer-events-none': disableResendInvitation || isLocked }"
      @click.stop="$emit('resend-invite')"
    >
      <span class="w-16 mr-8 icon-paper-plane text-16"></span>
      Resend Invite Link
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isTeamMember && member.status === STATUS.INVITED"
      :disabled="disableResendInvitation || isLocked"
      :class="disableResendInvitation && 'opacity-50 pointer-events-none'"
      @click.stop="$emit('resend-invite')"
    >
      <span class="w-16 mr-8 icon_v2-so_copy-done text-16"></span>
      Resend Invitation
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isTeamMember && member.status === STATUS.INVITED"
      @click.stop="$emit('cancel-invite')"
    >
      <span class="w-16 mr-8 icon_v2-so_close text-16"></span>
      Cancel Invite
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import { ROLES, STATUS } from '@/v2/services/members/membersTypes'

export default defineComponent({
  name: 'OrganizationTeamMemberMenu',
  components: {
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    disableResendInvitation: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isTeamMember = computed(() => props.member.role !== ROLES.client)
    const isClientContact = computed(() => props.member.role === ROLES.client)
    const isLocked = computed(() => props.member.status === STATUS.LOCKED)

    return {
      isTeamMember,
      isClientContact,
      isLocked,
      STATUS,
    }
  },
})
</script>
