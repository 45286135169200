
<div v-fragment>
  <CardSettings :step="step">
    <CardSettingsHeader
      title="Your Team"
      info="Who from your team can access this project?"
    >
      <template #button>
        <Button
          type="button"
          variant="primary"
          size="sm"
          @click="create"
        >
          <span class="icon_v2-so_users mr-4"></span>
          Add team member
        </Button>
      </template>
    </CardSettingsHeader>
    <TeamManager
      v-model="state"
      :organization-id="organization._id"
      :query="query"
      :query-selectable="querySelectable"
      empty-message="No team members"
      button-title="Manage team access"
      select-people-title="Add people from your organization"
      select-people-description="Who from your team can access this project?"
    />
  </CardSettings>
  <TeamMemberFormModal
    v-if="isMemberModalOpen"
    v-model="isMemberModalOpen"
    :organization-id="organization._id"
    @saved="onCreated"
  />
</div>
