
<ModalBasic
  title="Login Link"
  :hide-footer="true"
  dialog-class="modal-dialog-share"
  content-class="modal-content-tertiary"
  body-class="modal-body-share"
  header-class="modal-header-tertiary"
  footer-class="modal-footer-tertiary"
  size="sm"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #body>
    <h3 class="project-page-title">Login Link generated</h3>
    <p class="text-13 text-gray-400 mt-4 mb-16">
      A new login link has been successfully generated.
    </p>
    <FormMessage v-if="error">{{ error }}</FormMessage>
    <div class="relative z-20 flex flex-col gap-8 w-full">
      <div>
        <p
          class="
          font-mono text-gray-500
          break-all border border-gray-200 px-6 py-4
          text-13 rounded-t-md bg-gray-100
        "
        >
          {{ link }}
        </p>
        <b-button
          v-b-tooltip.hover.top.v-info.dh0.ds200="`Copy link to clipboard`"
          variant="dark"
          size="sm"
          :block="false"
          class="px-16 w-full !rounded-t-none"
          @click.stop="copyLinkToClipboard"
        >
          <span v-if="isCopied">Copied!</span>
          <span v-else>Copy link to clipboard</span>
        </b-button>
      </div>
      <b-button
        v-if="showOpenInNewTab"
        variant="reset"
        size="sm"
        :href="link"
        target="_blank"
        class="font-normal text-gray-500 text-13 self-start p-0"
      >
        <span class="w-14 icon-external-link text-14 text-gray-400"></span>
        <span class="pl-6">Open link in a new tab</span>
      </b-button>
    </div>
  </template>
</ModalBasic>
