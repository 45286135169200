
<div>
  <div class="flex items-center mb-6">
    <h3 class="project-page-subtitle">
      Custom domain
    </h3>
  </div>
  <div class="mb-24 text-gray-600 text-14">
    <p>Share with clients from your own domain instead of superokay.com</p>
    <p>Connect your business domain to fully personalize your portal links.</p>
  </div>

  <SettingsDomainActive
    v-if="[
      DOMAIN_STATUS.active,
      DOMAIN_STATUS.locked,
      DOMAIN_STATUS.removeRequested
    ].includes(organization.domainStatus)"
    :organization="organization"
  />
  <SettingsDomainSetup v-else :organization="organization" />
  <!-- STEPS -->
</div>
