<template>
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="isEmpty"
    :root-class="[

      'node-group-basic',
    ]"
    :config-override="{
      stylingToolbar: {
        features: {
          sizes: true,
          textColor: false,
        },
      },
      styling: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      block: {
        enableBlockLabel: false,
      }
    }"
  >
    <template #default="{ isHover }">
      <!-- Group Label -->
      <div
        :class="[
          'h-48 flex flex-row items-center justify-end py-4 px-12',
          'right-0 left-0 sticky top-48 z-1 transition-all pointer-events-none',
          isHover ? 'opacity-100' : 'opacity-25',
          'print:hidden'
        ]"
      >
        <span
          :class="[
            'text-green-800 bg-green-200 font-bold uppercase',
            'text-11 inline-flex rounded-l-lg pl-8 pr-5 pb-4 pt-5 leading-none',
            'transform translate-x-12',
          ]"
        >
          Group
        </span>
      </div>
      <div
        v-if="!isEmpty"
        class="mt-32"
      >
        <SlickList
          v-model="nodes"
          lock-axis="y"
          use-drag-handle
          helper-class="so-group-node-dragging"
          lock-to-container-edges
          @sort-start="handleSortStart"
          @sort-end="handleSortEnd"
        >
          <SlickItem
            v-for="(nodeId, index) in nodes"
            :key="nodeId"
            :index="index"
          >
            <DocumentNodeEditable
              :index="index"
              :node-id="nodeId"
              :parent="node._id"
              :is-first="index === 0"
              :is-last="index === children.length - 1"
              class="so-slick-inner"
            />
          </SlickItem>
        </SlickList>
      </div>
      <div v-else class="mx-2 mt-32 mb-16">
        <DocumentNodeGroupBasicPlaceholder
          :node-id="node._id"
        />
      </div>
      <div
        :class="[
          'h-32',
          'absolute top-24 left-0 right-0 transition-all',
          isHover ? 'opacity-100' : 'opacity-25',
          'print:hidden'
        ]"
      >
        <div class="flex flex-row items-center justify-between mx-auto space-x-24 max-w-prose">
          <DocumentBlockButtonCreate
            :show="isHover"
            :parent-node-id="node._id"
            :index="children.length"
            :accepts="accepts"
            mode="child"
            class="grow"
            button-label="Add to group"
          />
          <b-button
            variant="neutral-primary"
            size="sm"
            :class="[
              'p-8 h-auto text-14 leading-none',
              'hover:text-green-700',
            ]"
            @click="unGroup"
          >
            Ungroup
          </b-button>
        </div>
      </div>
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import { computed } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { SlickList, SlickItem } from 'vue-slicksort'
import { CATEGORY as CONTENT_BLOCK_CATEGORY } from '@/v2/services/contentBlocks/contentBlocksTypes'
import useNestedComponents from '@/v2/lib/composition/useNestedComponents'
import {
  useStructureBranch,
  useStructureReorderChildren,
  useStructureUngroupNodes,
} from '@/v2/services/documentStructures/documentStructuresCompositions'
import DocumentBlockButtonCreate from '@/components/Document/DocumentBlockButtonCreate.vue'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentNodeEditable from '@/v2/features/document/documentNode/DocumentNodeEditable.vue'
import { changeCursorStyle } from '@/helpers/dom'
import DocumentNodeGroupBasicPlaceholder from './DocumentNodeGroupBasicPlaceholder.vue'

const ACCEPTS = [
  CONTENT_BLOCK_CATEGORY.ElementHeading,
  CONTENT_BLOCK_CATEGORY.ElementText,
  CONTENT_BLOCK_CATEGORY.ElementImage,
  CONTENT_BLOCK_CATEGORY.ElementBrief,
  CONTENT_BLOCK_CATEGORY.ElementEstimate,
  CONTENT_BLOCK_CATEGORY.ElementTeamBio,
  CONTENT_BLOCK_CATEGORY.ElementCaseStudy,
  CONTENT_BLOCK_CATEGORY.ElementPackagedService,
  CONTENT_BLOCK_CATEGORY.ElementAttachments,
  CONTENT_BLOCK_CATEGORY.ElementApproval,
  CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  CONTENT_BLOCK_CATEGORY.ElementCost,
  CONTENT_BLOCK_CATEGORY.ElementCostBreakdown,
  CONTENT_BLOCK_CATEGORY.ElementQA,
  CONTENT_BLOCK_CATEGORY.ElementReusableBlock,
  CONTENT_BLOCK_CATEGORY.ElementEmbed,
  CONTENT_BLOCK_CATEGORY.ElementTable,
  CONTENT_BLOCK_CATEGORY.ElementButton,
]

const { useMutations, useState, useActions } = createNamespacedHelpers('documentEditor')

export default {
  name: 'DocumentNodeGroupBasicEditable',
  components: {
    DocumentBlockButtonCreate,
    DocumentNodeRenderer,
    DocumentNodeGroupBasicPlaceholder,
    SlickList,
    SlickItem,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    useNestedComponents({ DocumentNodeEditable })
    const structureBranch = useStructureBranch()
    const structureReorderChildren = useStructureReorderChildren()
    const unGroupNode = useStructureUngroupNodes()
    const { focusDisabled } = useState(['focusDisabled'])
    const { setFocusDisabled, setFocus } = useMutations(['setFocusDisabled', 'setFocus'])
    const { clearNodeSelection } = useActions(['clearNodeSelection'])
    const { children, isEmpty } = structureBranch(props.node._id)

    const nodes = computed({
      set: newOrder => structureReorderChildren({ parent: props.node._id, children: newOrder }),
      get: () => children.value,
    })
    const handleSortStart = () => {
      setFocus(null)
      setFocusDisabled(true)
      changeCursorStyle('grabbing')
    }
    const handleSortEnd = () => {
      setFocusDisabled(false)
      changeCursorStyle('')
    }
    const unGroup = () => {
      unGroupNode({ nodeId: props.node._id })
      clearNodeSelection()
    }
    return {
      children,
      isEmpty,
      nodes,
      focusDisabled,
      setFocusDisabled,
      handleSortStart,
      handleSortEnd,
      unGroup,
      accepts: ACCEPTS,
    }
  },
}
</script>

<style lang="postcss">
.so-group-node-dragging .node-highlight,
.so-group-node-dragging .block-actions-right {
  display: none;
}

.so-slick-inner {
  @apply h-auto shadow-none;
}

.so-group-node-dragging .so-slick-inner{
  @apply z-0 transform -rotate-1 scale-105;
  @apply m-0 ring-2 bg-white bg-opacity-50 ring-green-500 rounded-lg overflow-hidden;
  box-shadow: 0 10px 40px rgba(0,0,0, 0.10), 0 0px 60px rgba(0,0,0, 0.04);
}

.so-group-node-dragging .so-slick-inner .block-section-wrapper {
  @apply opacity-75;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
</style>
