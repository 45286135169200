
<div v-fragment>
  <component
    :is="component"
    :document="document"
    @share="showShareModal = true"
  />
  <ContentLibraryModal :document="document" />
  <DocumentEditorShareModal
    v-model="showShareModal"
    :document="document"
    size="lg"
  />
  <DocumentSelectionToolbar />
</div>
