<template>
  <GuestTemplateDocument
    v-if="!isPending"
    ref="iframe"
    :document-id="error ? null : documentId"
  />
</template>

<script>
import { isNumber } from 'ramda-adjunct'
import { computed, defineComponent, onMounted, onUnmounted, ref, toRef } from '@vue/composition-api'
import { useToken } from '@/v2/services/tokens/tokensCompositions'
import GuestTemplateDocument from '@/components/GuestTemplateDocument.vue'

export default defineComponent({
  name: 'ViewGuestTemplateDocument',
  components: {
    GuestTemplateDocument,
  },
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const iframe = ref(null)

    const {
      isPending,
      error,
      token: tokenDetails,
    } = useToken(toRef(props, 'token'))

    const documentId = computed(() => tokenDetails.value?.document ?? null);

    let timeout = null

    const getHeight = () => {
      const height = iframe.value?.$el?.scrollHeight

      if (isNumber(height) && height > 0) {
        window.parent?.postMessage({ height }, import.meta.env.VITE_MARKETING_WEBSITE_URL)
        console.log(height);
        return
      }

      timeout = setTimeout(getHeight, 1000)
    }

    onMounted(getHeight)
    onUnmounted(() => {
      clearTimeout(timeout)
    })

    return {
      iframe,
      isPending,
      error,
      tokenDetails,
      documentId,
    }
  },
})
</script>
