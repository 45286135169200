<template>
  <div class="flex-1 flex -m-16">
    <ProjectDashboardPageHeader
      v-if="!$isMobile"
      :project="project"
    />
    <ProjectDashboard
      :organization="organization"
      :project="project"
      :routes="routes"
      @create-quick-link="isQuickLinkModalOpen = true"
    />
    <ProjectSettingsQuickLinkModal
      v-if="isQuickLinkModalOpen"
      v-model="isQuickLinkModalOpen"
      @create="createQuickLink"
    />
  </div>
</template>
<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import {
  PROJECT_FS_FOLDER,
  PROJECT_KNOWLEDGE_BASE,
  PROJECT_LINKED_APP,
  PROJECT_SETTINGS_APPS,
  PROJECT_SETTINGS_QUICK_LINKS,
  PROJECT_SETTINGS_PACKAGED_SERVICES,
  PROJECT_TASKS,
} from '@/router/project/type'
import useBrowserTitle from '@/v2/lib/composition/useBrowserTitle'
import ProjectDashboard from '@/components/ProjectDashboard.vue'
import ProjectDashboardPageHeader from '@/components/ProjectDashboardPageHeader.vue'
import ProjectSettingsQuickLinkModal from '@/components/ProjectSettingsQuickLinkModal.vue'

export default defineComponent({
  name: 'ViewProjectDashboard',
  components: {
    ProjectDashboard,
    ProjectDashboardPageHeader,
    ProjectSettingsQuickLinkModal,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    useBrowserTitle(
      computed(() => [props.project.name, 'SuperOkay'].join(' - '))
    )

    const routes = {
      docs: {
        name: PROJECT_FS_FOLDER,
        params: {
          folderId: props.project.rootFolder,
        },
      },
      knowledgeBase: { name: PROJECT_KNOWLEDGE_BASE },
      linkedApp: { name: PROJECT_LINKED_APP },
      settingsApps: { name: PROJECT_SETTINGS_APPS },
      settingsQuickLinks: { name: PROJECT_SETTINGS_QUICK_LINKS },
      packagedServices: { name: PROJECT_SETTINGS_PACKAGED_SERVICES },
      tasks: { name: PROJECT_TASKS },
    }

    // -- add quick link
    const isQuickLinkModalOpen = ref(false)

    const createQuickLink = quickLink => {
      const clone = props.project.clone()
      clone.portalQuickLinks = [...clone.portalQuickLinks, quickLink]
      clone.save({ data: { portalQuickLinks: clone.portalQuickLinks } })
      isQuickLinkModalOpen.value = false
    }

    return {
      routes,
      isQuickLinkModalOpen,
      createQuickLink,
    }
  },
})
</script>
