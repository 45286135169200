<template>
  <div>
    <div :class="$style.org_team" class="mb-12 border-b border-gray-200">
      <b-table
        hover
        primary-key="member._id"
        details-td-class="!pt-0 !pl-36"
        :items="items"
        :fields="[
          {
            key: 'name',
            sortable: true,
            label: 'Name',
            tdClass: $style['col-menu']
          },
          {
            key: 'projects',
            sortable: false,
            name: 'Projects',
          },
          {
            key: 'accessLevel',
            label: 'Access Level',
            sortable: true,
            tdClass: $style['col-menu']
          },
          {
            key: 'remove',
            label: '',
            sortable: false,
            tdClass: $style['col-menu']
          },
        ]"
        class="text-14"
        :class="$style['m-table']"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'remove' ? '80px' : 'auto' }"
          />
        </template>
        <template #cell(name)="data">
          <p class="inline-flex items-center">
            <Avatar
              :class="!data.item.user && $style['avatar-resend']"
              :profile="data.item.user?.profile$"
              :name="
                data.item.member.status === STATUS.INVITED
                  ? [data.item.member.firstName, data.item.member.lastName].join(' ')
                  : null
              "
              show-name
            />
            <span
              v-if="isCurrentMember(data.item.member._id)"
              class="opacity-50 font-medium"
            >&nbsp;(You)</span>
          </p>
          <p class="ml-28 text-12 font-medium">
            {{ data.item.user?.email ?? data.item.member?.email }}
          </p>
        </template>
        <template #cell(accessLevel)="data">
          <div :class="[$style.actions, 'flex items-center']">
            <BadgeMemberRole
              v-if="data.item.member.status === STATUS.ACTIVE"
              :member="data.item.member"
            />
            <Badge
              v-if="data.item.member.status === STATUS.INVITED"
              class="table-disabled-text"
              variant="tertiary"
            >
              Invited
            </Badge>
            <Badge
              v-else-if="data.item.member.status === STATUS.LOCKED"
              variant="warning"
            >
              Disabled
            </Badge>
          </div>
        </template>
        <template #cell(remove)="data">
          <OrganizationTeamMemberMenu
            v-if="!isCurrentMember(data.item.member._id) || !isOwner"
            :member="data.item.member"
            :disable-resend-invitation="
              isLoadingResendInvitation
                || resendRequestMap[data.item.member._id]
            "
            @edit="edit(data.item.member._id)"
            @remove="removeMember(data.item.member)"
            @resend-invite="resendInvitation(data.item.member)"
            @cancel-invite="cancelInvitation(data.item.member)"
          />
        </template>
        <template #cell(projects)="data">
          <template v-if="data.item.member.role === ROLES.member">
            <MemberAssignedProjects
              v-if="data.item.projects?.length"
              :projects="data.item.projects"
            />
            <div v-else class="text-gray-400 text-12 font-semibold">No Projects</div>
          </template>
          <div
            v-else
            class="text-green-600 text-12 font-semibold"
          >
            All Projects
          </div>
        </template>
      </b-table>
      <TeamMemberFormModal
        v-if="isEditorOpen"
        v-model="isEditorOpen"
        :member-id="memberId"
        :organization-id="organizationId"
        :create-as-client-contact="typeOfMembers === 'client-contacts'"
        @save="closeAndNotify"
      />
    </div>
    <Licensing v-slot="{ plan }">
      <div
        v-if="plan?.featureFlags?.limitMembers"
        class="w-full bg-violet-50/50 p-12 rounded-md border border-violet-200
        flex items-center justify-between my-16"
      >
        <p class="text-14 grow">
          {{ items?.length }} of
          <strong>{{ plan?.featureFlags?.limitMembers ?? 'unlimited' }}</strong>
          team members invited.
        </p>
        <router-link
          v-slot="{ navigate }"
          :to="{
            name: ORGANIZATION_SUBSCRIPTION,
            params: { provider: LICENSING_PROVIDER.chargebee }
          }"
          custom
        >
          <b-button
            v-if="plan?.featureFlags?.limitMembers === items?.length"
            size="sm"
            variant="tertiary"
            @click="navigate"
          >
            🚀 Upgrade to invite more team members
          </b-button>
        </router-link>
      </div>
    </Licensing>
  </div>
</template>

<script>
import { computed, ref, defineComponent, reactive, set } from '@vue/composition-api'
import { rejectNil } from 'ramda-extension'
import { ROLES, STATUS } from '@/v2/services/members/membersTypes'
import { useFindMembers } from '@/v2/services/members/compositions'
import useEntityEditor from '@/v2/lib/composition/useEntityEditor'
import useNotification from '@/v2/lib/composition/useNotification'
import { useMsgBoxConfirm, useMsgBoxOK } from '@/v2/lib/composition/useMsgBox'
import { useMemberLimit } from '@/v2/services/members/compositions'
import { useRouteParams } from '@/v2/lib/composition/useRouter'
import { useCurrentMember } from '@/v2/services/myMembers/compositions'
import { LICENSING_PROVIDER } from '@/v2/services/organizations/organizationsTypes'
import { ORGANIZATION_SUBSCRIPTION } from '@/router/organization/type'
import Badge from '@/components/Badge.vue'
import Avatar from '@/components/Blocks/Avatar/Avatar.vue'
import TeamMemberFormModal from '@/components/TeamMemberFormModal.vue'
import OrganizationTeamMemberMenu from '@/components/OrganizationTeamMemberMenu.vue'
import Licensing from '@/components/Licensing.vue'
import BadgeMemberRole from '@/components/BadgeMemberRole.vue'
import MemberAssignedProjects from '@/components/MemberAssignedProjects.vue'

export default defineComponent({
  name: 'OrganizationTeam',
  components: {
    Avatar,
    TeamMemberFormModal,
    Badge,
    OrganizationTeamMemberMenu,
    Licensing,
    BadgeMemberRole,
    MemberAssignedProjects,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
    /** Specifies which members of an organization to display: agency team members or client
     * contacts */
    typeOfMembers: {
      type: String,
      // NOTE: Can have one of two values: 'client-contacts' or 'team-members'
      validator: value => ['client-contacts', 'team-members'].includes(value),
      default: 'team-members',
    },
  },
  setup(props) {
    const confirm = useMsgBoxConfirm()
    const notice = useMsgBoxOK()
    const notification = useNotification()
    const { clientId } = useRouteParams(['clientId'])

    const resendRequestMap = reactive({})
    const isLoadingResendInvitation = ref(false)
    const isLoadingCancelInvitation = ref(false)
    const isMemberRemovalPending = ref(false)

    const isClientContact = computed(() => Boolean(props.typeOfMembers === 'client-contacts'))

    const roles = computed(() => (props.typeOfMembers === 'client-contacts'
      ? [ROLES.client]
      : [ROLES.admin, ROLES.member, ROLES.projectManager]));

    const params = computed(() => ({
      query: rejectNil({
        organization: props.organizationId,
        role: { $in: roles.value },
        client: clientId.value,
        $sort: { _id: 1 },
      }),
    }))

    const { items } = useFindMembers({
      params,
      joinUser: true,
      joinProjects: true,
    })

    const {
      entityId: memberId,
      isEditorOpen,
      closeEditor,
      create,
      edit,
    } = useEntityEditor()

    const { member: currentMember, isOwner } = useCurrentMember()
    /** Is the provided member id the same as the current user's member id? */
    const isCurrentMember = _memberId => currentMember.value?._id === _memberId

    const resendInvitation = async member => {
      if (isLoadingResendInvitation.value) {
        return
      }

      const ok = await confirm({
        title: member.email,
        message: 'Are you sure you want to resend this invitation?',
      })

      if (ok) {
        isLoadingResendInvitation.value = true
        try {
          await member.patch({ data: { resendRequested: true } })
          notification({
            title: member.email,
            message: isClientContact.value ? 'Portal access link has been resent' : 'Member invitation has been resent.',
          });
          set(resendRequestMap, member._id, true)
        } catch (err) {
          notification({
            variant: 'danger',
            message: 'There has been an error processing your request',
          })
          console.error(err);
        } finally {
          isLoadingResendInvitation.value = false
        }
      }
    }

    const cancelInvitation = async member => {
      if (isLoadingCancelInvitation.value) {
        return
      }

      const ok = await confirm({
        title: member.email,
        message: 'Are you sure you want to cancel this invitation?',
        okVariant: 'danger',
      })

      if (ok) {
        isLoadingCancelInvitation.value = true
        try {
          await member.remove()
          notification({
            title: member.email,
            message: 'Member invitation has been cancelled.',
          });
        } catch (err) {
          notification({
            variant: 'danger',
            message: 'There has been an error processing your request',
          })
          console.error(err);
        } finally {
          isLoadingCancelInvitation.value = false
        }
      }
    }

    const removeMember = async member => {
      if (isMemberRemovalPending.value) return

      const typeOfMember = props.typeOfMembers === 'team-members' ? 'team member' : 'client contact'
      const message = `Are you sure you want to remove this ${typeOfMember}? This is irreversible`
      const ok = await confirm({
        title: member.email,
        message,
      })

      if (ok) {
        isMemberRemovalPending.value = true
        try {
          await member.remove()
          notification({
            title: member.email,
            message: 'Removal has been successful.',
          });
        } catch (err) {
          notification({
            variant: 'danger',
            message: 'There has been an error processing your request',
          })
          console.error(err);
        } finally {
          isMemberRemovalPending.value = false
        }
      }
    }

    const { checkMemberLimit } = useMemberLimit()

    const closeAndNotify = isNew => {
      closeEditor()
      // Only display modal for team members, not client contacts
      if (!isClientContact.value && isNew) {
        notice({
          title: 'Invitation Sent',
          message: 'Member invitation has been sent.',
          size: 'md',
        })
      }
    }

    const _create = () => {
      const role = props.typeOfMembers === 'client-contacts' ? ROLES.client : null;
      if (!checkMemberLimit(role)) {
        create()
      }
    }

    return {
      // Static
      STATUS,
      memberId,
      isEditorOpen,
      closeEditor,
      create: _create,
      edit,
      cancelInvitation,
      resendInvitation,
      removeMember,
      isCurrentMember,
      isClientContact,
      items,
      resendRequestMap,
      isLoadingResendInvitation,
      isLoadingCancelInvitation,
      closeAndNotify,
      isOwner,
      ORGANIZATION_SUBSCRIPTION,
      LICENSING_PROVIDER,
      ROLES,
    }
  },
})
</script>

<style lang="scss" module>
.col-menu {
  width: 1%;
}

.org_team {
  width: 100%;
  .card-table {
    padding: 0;
    :global(.b-table) {
      border-radius: $border-radius-x2;
    }
  }
}
.actions > * {
  display: inline-block;
}
.member {
  background-color: red;
}
.email {
  margin-right: 8px;
  opacity: 0.6;
}
.avatar-resend {
  opacity: 0.6;
}

.cancel {
  color: $gray-500;
  &:hover {
    color: $gray-700;
  }
}
.sent {
  color: $green-100;
}
</style>
