<template>
  <div>
    <ModalBasic
      :no-close="false"
      :hide-footer="true"
      title="Google Fonts"
      :content-class="$style['modal-content-fonts']"
      :body-class="$style['modal-body-fonts']"
      header-class="modal-header-tertiary"
      footer-class="modal-footer-tertiary"
      :dialog-class="$style['modal-dialog-fonts']"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #body>
        <BrandingFonts
          :organization="organization"
          :apply-font-on="applyFontOn"
          :model="model"
          @select="(family, applyFontOn) => $emit('select', family, applyFontOn)"
        />
      </template>
    </ModalBasic>
  </div>
</template>

<script>
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import BrandingFonts from '@/components/BrandingFonts.vue'

export default {
  name: 'BrandingFontsModal',
  components: {
    ModalBasic,
    BrandingFonts,
  },
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
    applyFontOn: {
      type: String,
      default: '',
    },
    model: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
    }
  },

}
</script>

<style lang="scss" module>
.modal-dialog-fonts {
  max-width: 924px;
  .modal-content-fonts {
    background-color: theme('colors.gray.DEFAULT');
  }
  .modal-body-fonts {
    padding: 40px;
  }
}

</style>
