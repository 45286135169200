export function mergeElements(arr1, arr2, key) {
  return arr1.map(item1 => {
    const props = arr2.find(item2 => item2[key] === item1[key])
    return { ...item1, ...props }
  })
}

export function flat(arr) {
  return arr.reduce((acc, elements) => acc.concat(elements), [])
}

export function arrayDiff(oldArr = [], newArr = []) {
  const oldSet = new Set(oldArr)
  const newSet = new Set(newArr)

  const removed = oldArr.filter(val => !newSet.has(val))
  const added = newArr.filter(val => !oldSet.has(val))

  return { removed, added }
}
