
<div>
  <PageHeader title="Project Templates" />
  <ProjectTemplateList
    :templates="templates"
    @preview="onTemplatePreview"
    @create="onCreate"
  />
  <ProjectTemplatePreviewModal
    v-if="isPreviewModalOpen"
    v-model="isPreviewModalOpen"
    :template="selectedTemplate"
    :pending="cloneProjectPending"
    :error="cloneProjectError"
    @use="onUseTemplate"
    @hide="onTemplateModalClosed"
  />
</div>
