<template>
  <transition name="toolbar" appear>
    <div
      class="absolute flex justify-center w-full h-px pb-4 block-toolbar bottom-full print:hidden"
      :class="[commentsSidebarStatus ? 'z-1 xl:z-50' : 'z-50' ]"
      data-intercom-target="Block Toolbar"
      @click.stop
    >
      <div
        class="flex px-8 pt-2 pb-6 -mt-64 text-white bg-gray-800 rounded-md h-60 editor-toolbar"
      >
        <slot />
      </div>
    </div>
  </transition>
</template>
<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useState } = createNamespacedHelpers('documentEditor')
export default {
  name: 'DocumentNodeEditorToolbar',
  props: {
    column: Boolean,
  },
  setup() {
    const { commentsSidebarStatus } = useState(['contextMenu', 'commentsSidebarStatus'])
    return { commentsSidebarStatus }
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .toolbar-button-text {
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    padding-right: $gutter;
    position: relative;
    text-decoration: none;
    span {
      font-size: 14px;
    }
    &:hover {
      text-decoration: none;
    }
    &::after {
      display: none;
    }
  }
  .toolbar-dropdown-menu {
    .dropdown-toggle {
      &::after {
        position: absolute;
        right: -1px;
        bottom: 1px;
        transform: rotate(-45deg);
        border-top-color: $primary;
      }
    }
  }
}
</style>
<style>
/* Enter and leave animations */
.toolbar-enter-active {
  transition: all .2s ease-out;
}

.toolbar-leave-active {
  transition: all .1s ease-in;
}
.toolbar-enter {
  transform: translateY(10px);
  opacity: 0;
}
.toolbar-leave-to {
  opacity: 0;
}
</style>
