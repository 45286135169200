
<div>
  <ListLightDropdown
    :variant="variant"
    :size="size"
    :lazy="true"
    position="right"
    toggle-class="px-10"
    no-caret
  >
    <template #button-content>
      <span :class="iconClass" />
      <span>{{ label }}</span>
    </template>

    <template #default>
      <ListLightDropdownItem
        label="Blank"
        :document-category="category"
        @select="createDocument()"
      />
      <ListLightDropdownItem
        v-if="hasTemplates"
        label="Use Template"
        icon="templates"
        :badge="templateCount"
        @select="openModal"
      />
    </template>
  </ListLightDropdown>
  <DocumentFromTemplateModal
    v-if="hasTemplates && isModalOpen"
    v-model="isModalOpen"
    :templates-category="templatesCategory"
    @select="onTemplateSelect"
  />
</div>
