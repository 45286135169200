
<FeatureEnabled
  v-slot="{ checkFeatureEnabled }"
  flag="fullWhiteLabel"
  @allow="formSubmit"
>
  <div>
    <Step
      :step="1"
      header="Configure your email address &amp; sender name"
    >
      <b-form @submit.prevent="checkFeatureEnabled">
        <FormMessage v-if="formError">
          {{ formError.message }}
        </FormMessage>
        <div class="mb-6">
          <!-- SENDER EMAIL ADDRESS -->
          <FormField
            v-slot="{ state }"
            label="Sender email address"
            wrapper-class="mr-10 mb-8"
            :v="$v.email"
            :messages="{
              required: 'Email is required',
              email: 'Invalid email format',
              available: 'Email is not available',
            }"
          >
            <div>
              <b-input
                v-model="email"
                placeholder="name@yourdomain.com"
                :state="!email ? null : state"
                :disabled="formIsBusy || statusPending"
                size="lg"
                class="max-w-510 min-w-[330px]"
              />
            </div>
            <p
              class="
                text-gray-800 text-13 bg-yellow-50 rounded-md p-4 overflow-hidden
                mt-8 inline-flex items-center leading-none
              "
            >
              💡 Email address must be on a domain you own.
              Public email providers (like @gmail.com) are not supported.
            </p>
          </FormField>
          <!-- SENDER NAME -->
          <FormField
            wrapper-class="mr-10"
            label="Sender name"
            :v="$v.emailSenderName"
            :messages="{
              required: 'Sender name is required',
            }"
          >
            <b-input
              v-model="model.emailSenderName"
              placeholder="Sender Name"
              :disabled="formIsBusy || statusPending"
              size="lg"
              class="max-w-510 min-w-[330px]"
            />
          </FormField>
          <Button
            v-if="!statusPending"
            :is-loading="formIsBusy"
            size="md"
            variant="neutral"
            type="submit"
            class="mt-16"
          >
            Save
          </Button>
        </div>
      </b-form>
    </Step>
    <!-- STEP 2 -->
    <Step
      :step="2"
      :header="
        `Add a TXT record to ${cleanDomainName || 'your domain'}'s
      DNS settings with these values:`
      "
    >
      <!-- table -->
      <div class="flex justify-start leading-relaxed mb-18 text-14 gap-40 w-full">
        <div class="flex flex-col">
          <span>Hostname</span>
          <span v-if="!organization.emailDKIMHost" class="text-orange-600">
            ⚠️ Please set your email address first
          </span>
          <span v-else class="text-gray-500">
            {{ organization.emailDKIMHost }}
          </span>
        </div>
        <div class="flex flex-col">
          <span>Type</span>
          <span class="text-gray-500">TXT</span>
        </div>
        <div class="flex flex-col">
          <span>Value</span>
          <span v-if="!organization.emailDKIMTextValue" class="text-orange-600">
            ⚠️ Please set your email address first
          </span>
          <p v-else class="text-gray-500 break-all">
            {{ organization.emailDKIMTextValue }}
          </p>
        </div>
      </div>
      <a
        class="text-green-700 text-14 group"
        href="https://help.superokay.com/en/articles/6234488-how-to-set-up-email-white-labeling"
        target="_blank"
      >
        <span class="mr-4 text-12">💡</span>
        <span class="underline group-hover:no-underline">
          How to add a TXT record to your domain DNS
        </span>
      </a>
    </Step>

    <!-- STEP 3 -->
    <Step
      :step="3"
      header="Validate the configuration"
      class-name="border-none"
    >
      <p class="text-gray-500 text-14 mb-20">
        <strong>After you've updated your DNS settings</strong>,
        please click the "Validate DNS Changes" button below.
      </p>
      <Button
        :disabled="!(status === STATUS.awaitingDKIMConfirmation && DKIMRecordsAvailable)"
        :is-loading="confirmDKIMLoading"
        size="md"
        variant="primary-dark"
        type="button"
        @click="confirmDKIM"
      >
        <span v-if="status === STATUS.setupError">Revalidate DNS Changes</span>
        <span v-else-if="statusPending">Setup In Progress...</span>
        <span v-else>Validate DNS Changes</span>
      </Button>
      <p class="text-gray-500 text-12 max-w-[480px] mt-20">
        Sometimes DNS changes can take up to 24hrs to show up.<br /> We'll keep
        checking once you start the validation process.
      </p>
    </Step>
  </div>
</FeatureEnabled>
