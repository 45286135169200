
<div class="flex flex-col space-y-8">
  <div class="relative text-gray-600 focus-within:text-gray-700">
    <b-input
      v-model="filterFont"
      autofocus
      placeholder="Search fonts"
      size="md"
      class="pr-32"
    />
    <span class="icon-magnify text-18 w-18 absolute right-12 top-12 text-gray-600" />
  </div>
  <b-form-select
    :value="value || 'ABeeZee'"
    :options="options"
    :select-size="16"
    plain
    @change="(font)=>$emit('input', font)"
  />
  <b-link
    v-if="value"
    class="underline hover:no-underline text-gray-400 hover:text-gray-100"
    @click="$emit('input',null)"
  >
    Reset
  </b-link>
</div>
