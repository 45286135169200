<template>
  <section
    :class="[
      'flex flex-col lg:pb-48',
    ]"
  >
    <div class="flex items-center justify-center md:py-16 h-116 lg:py-34 lg:mb-0">
      <span class="w-48 text-white text-48 icon-superokay-symbol"></span>
    </div>
    <!-- Card -->
    <div
      :class="[
        'flex flex-col justify-center items-center',
        'w-full md:py-16 px-16 md:px-32 max-w-[554px]',
      ]"
    >
      <!-- Logo -->

      <!-- Welcome message -->
      <div class="mb-40 text-center">
        <h1 class="font-semibold text-gray-900 text-18 md:text-24">
          Create a <span class="text-green-600">free account</span> now
        </h1>
        <p class="leading-normal text-gray-600 text-14 lg:text-15">
          One project free forever. No credit card required.
        </p>
      </div>

      <!-- Form -->
      <section class="flex flex-col items-center justify-center space-y-8">
        <ButtonGoogleAuth
          message="Sign up with Google"
          :track="['Sign Up With Google Clicked']"
        />

        <div class="flex flex-row items-center w-full space-x-16 justify-stretch">
          <span class="flex-1 block h-2 bg-gray-200 rounded-full" />
          <span class="flex text-center text-gray-500 text-14">or use email</span>
          <span class="flex-1 block h-2 bg-gray-200 rounded-full" />
        </div>
        <SignUpFormProfile
          :user="user"
          :profile="profile"
          :is-loading="isLoading"
          @submit="onSubmit"
        />
      </section>
      <div
        class="inline-flex flex-row items-center justify-center space-x-16 text-14"
      >
        <span class="text-gray-600">Already have an account?</span>
        <b-link
          class="inline-flex items-center font-bold text-green-600 hover:text-green-800"
          @click.stop="goToSignIn"
        >
          Sign in
          <span class="w-16 ml-6 icon-arrow-right-util text-16"></span>
        </b-link>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { path, pick } from 'ramda'
import { GUEST_HOME } from '@/router/guest/type'
import ButtonGoogleAuth from '@/components/ButtonGoogleAuth.vue'
import { useAccountsRegister } from '@/v2/services/accounts/accountsCompositions'
import SignUpFormProfile from '@/components/SignUpFormProfile.vue'
import { useUser } from '@/v2/services/users/usersCompositions'
// import CardWhiteRounded from '@/components/CardWhiteRounded.vue'


const credentials = pick(['email', 'password'])

export default defineComponent({
  name: 'ViewSignUp',
  metaInfo: {
    titleTemplate: 'Sign Up - %s',
  },
  components: {
    ButtonGoogleAuth,
    SignUpFormProfile,
    // CardWhiteRounded,
  },
  staticData: {
    GUEST_HOME,
  },
  setup(props, context) {
    const user = reactive({ email: '', password: '' })
    const profile = reactive({ firstName: '', lastName: '' })
    const registerUser = useAccountsRegister()
    const { authenticateLocal } = useUser()
    const isLoading = ref(false)

    async function onSubmit(formData) {
      isLoading.value = true
      try {
        await registerUser({
          user: formData.user,
          profile: formData.profile,
          organization: {
            name: `${path(['profile', 'firstName'], formData)}'s Organization`,
          },
        });
        // await wait(2000);
        await authenticateLocal(credentials(formData.user));
      } catch (err) {
        console.error('Error on form submission:', err);
      }
    }

    const goToSignIn = () => {
      context.root.$router.push({
        name: GUEST_HOME,
      })
    }

    return {
      user,
      profile,
      isLoading,
      onSubmit,
      goToSignIn,
    }
  },
})
</script>

<style lang="scss" module>
.login {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 32px 0 100px;
}

.text {
  padding-right: 48px;
  color: $gray-900;
}

.form {
  background-color: white;
  border-radius: $border-radius-x2;
  padding: 55px 55px;
  @include box-shadow-item;
  border: 1px solid $gray-300;
}
.signin {
  color: $gray-900;
  border: 1px solid $gray-500;
  border-radius: $border-radius;
  display: inline-block;
  text-decoration: none;
  font-weight: $medium;
  font-size: $fs18;
  line-height: 50px;
  padding: 0 34px;
  margin-left: 24px;
  transition: all 0.3 ease;
  &:hover {
    color: $primary;
    text-decoration: none;
    border: 1px solid $primary;
    background-color: rgba(white, 1);
  }
}
.links {
  font-size: $fs18;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-900;
  position: absolute;
  bottom: -68px;
  right: 0;
  left: 0;
  height: 40px;
}
</style>
