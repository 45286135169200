
<DocumentContentLoader v-slot="{ isLoading }" :document="document">
  <DocumentPage
    v-if="!isLoading"
    :document="document"
    :hide-page-border="hidePageBorder"
  >
    <DocumentNodeGroupRootEditable v-if="isEditable" />
    <DocumentNodeGroupRoot v-else :is-read-only="true" />
  </DocumentPage>
</DocumentContentLoader>
