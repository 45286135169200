
<div class="space-y-24 max-w-page">
  <div class="lg:px-24 lg:py-16">
    <h2 class="font-semibold text-gray-800 text-20 dark:text-darkGray-300">
      Client Branding
    </h2>
    <p class="text-gray-400 text-14 dark:text-darkGray-400">Configure your client's brand</p>
    <b-form class="mt-24" @submit.prevent="create">
      <FormMessage v-if="formError">
        {{ formError.message }}
      </FormMessage>
      <!-- Main Project Settings -->
      <div class="flex flex-col gap-32">
        <!-- CLIENT NAME -->
        <div class="grid grid-cols-2 gap-16">
          <FormField
            label="Client Name"
            required
            :v="$v.name"
          >
            <b-input
              v-model="model.name"
              placeholder="Acme Corp."
              class="form-control-lg"
              autofocus
            />
          </FormField>

          <FormField
            label="Client brand color"
          >
            <ColorPaletteItem
              v-model="model.mainColor"
              variant="pick"
              popover-placement="buttom"
              class="list-none included list-item"
              :size="42"
            />
          </FormField>
        </div>

        <!-- CLIENT LOGO -->
        <div class="flex flex-col gap-16">
          <h4 class="pb-8 font-semibold border-b border-gray-100">Client Logo</h4>
          <div class="grid grid-cols-2 gap-16">
            <div class="flex flex-col items-start">
              <h6 class="font-medium text-14">Primary</h6>
              <p class="mb-8 text-gray-500 text-12">Used on light backgrounds</p>
              <LogoUpload
                v-model="model.logoLight"
                :height="80"
                :width="240"
                :display-height="100"
                :display-width="320"
                class-name="p-0"
                @uploading="uploadLogo"
              >
                <template #buttons="{ selectFiles, removeFile }">
                  <Button
                    v-if="!model.logoLight"
                    variant="dark"
                    size="xs"
                    @click="selectFiles"
                  >
                    Upload
                  </Button>
                  <template v-else>
                    <Button
                      variant="dark"
                      size="xs"
                      @click="selectFiles"
                    >
                      Replace
                    </Button>
                    <Button
                      variant="danger"
                      size="xs"
                      @click="removeFile"
                    >
                      Remove
                    </Button>
                  </template>
                </template>
              </LogoUpload>
            </div>
            <div class="flex flex-col items-start">
              <h6 class="font-medium text-14">Reversed</h6>
              <p class="mb-8 text-gray-500 text-12">Used on dark backgrounds</p>
              <LogoUpload
                v-model="model.logoDark"
                :height="80"
                :width="240"
                :display-height="100"
                :display-width="320"
                variant="dark"
                class-name="p-0"
                @uploading="uploadLogo"
              >
                <template #buttons="{ selectFiles, removeFile }">
                  <Button
                    v-if="!model.logoDark"
                    variant="dark"
                    size="xs"
                    @click="selectFiles"
                  >
                    Upload
                  </Button>
                  <template v-else>
                    <Button
                      variant="dark"
                      size="xs"
                      @click="selectFiles"
                    >
                      Replace
                    </Button>
                    <Button
                      variant="danger"
                      size="xs"
                      @click="removeFile"
                    >
                      Remove
                    </Button>
                  </template>
                </template>
              </LogoUpload>
            </div>
          </div>
        </div>

        <!-- v-model="model.mainColor"  -->
      </div>
      <div v-if="standalone" class="mt-24">
        <Button
          type="submit"
          variant="primary"
          size="md"
          class="justify-start pl-16 w-176"
        >
          {{ isNew ? 'Create Client' : 'Save Changes' }}
          <span
            class="ml-auto -mr-8 text-green-200 icon-arrow-right-util"
          ></span>
        </Button>
      </div>
    </b-form>
  </div>
</div>
