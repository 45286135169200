<template>
  <div class="flex space-x-8 whitespace-nowrap">
    <Badge :variant="ROLE_COLOR[member.role]">
      {{ ROLE_LABEL[member.role] }}
    </Badge>
    <Badge v-if="member.isOwner" variant="dark">Owner</Badge>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { ROLES } from '@/v2/services/members/membersTypes';
import Badge from '@/components/Badge.vue'

const ROLE_LABEL = /** @type {const} */ ({
  [ROLES.admin]: 'Admin',
  [ROLES.projectManager]: 'Project Manager',
  [ROLES.member]: 'Member',
  [ROLES.client]: 'Client',
})

const ROLE_COLOR = /** @type {const} */ ({
  [ROLES.admin]: 'success',
  [ROLES.projectManager]: 'secondary',
  [ROLES.member]: 'neutral',
  [ROLES.client]: 'info',
})

export default defineComponent({
  name: 'BadgeMemberRole',
  components: {
    Badge,
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      ROLE_LABEL,
      ROLE_COLOR,
    }
  },
})
</script>
