export default (a = {}, b = {}) => {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  const allKeys = new Set([...aKeys, ...bKeys]);

  return Array.from(allKeys).reduce((acc, key) => {
    const aVal = a[key];
    const bVal = b[key];

    if (aVal === bVal) {
      return { ...acc };
    }

    return {
      ...acc,
      [key]: { oldValue: a[key], newValue: b[key] },
    };
  }, {});
};
