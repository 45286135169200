
<!-- User needs to confirm the DKIM record -->
<InfoMessage
  v-if="emailStatus === STATUS.awaitingDKIMConfirmation"
  variant="info"
>
  <template #title>Awaiting DKIM Record Confirmation</template>
  Please set the DKIM record as instructed below, then confirm the CNAME settings.
</InfoMessage>

<!-- Email setup in progress -->
<InfoMessage
  v-else-if="statusPending"
  variant="info"
>
  <template #title>Your Email Is Being Set Up...</template>
  <template #default>
    <div>
      Your email is being set up. It could take up to 24 hours for the
      DKIM to propagate properly.
    </div>
    <div class="mt-16">
      <Button
        size="sm"
        variant="danger"
        :is-loading="cancelIsLoading"
        @click="$emit('cancel')"
      >
        Cancel Setup
      </Button>
    </div>
  </template>
</InfoMessage>

<InfoMessage
  v-else-if="statusCancelSetup"
  variant="warn"
>
  <template #title>Email Setup Cancellation in Progress</template>
  The email setup cancellation for
  <strong>{{ organization.email }}</strong> is currently in progress.
  Please wait while we process your request.
  You can restart the setup with the same or a different email once the cancellation is complete.
</InfoMessage>

<div v-else-if="emailStatus === STATUS.createDomainError">
  <!-- domainExists error -->
  <InfoMessage
    v-if="emailError === ERROR.emailDomainExists"
    variant="error"
  >
    <template #title>The Email Domain Already Exists</template>
    Please use a different email domain or contact support for assistance.
  </InfoMessage>

  <InfoMessage
    v-else
    variant="error"
  >
    <template #title>An Error Occurred</template>
    An error occurred and your domain couldn't be set up. Please contact us for assistance.
  </InfoMessage>
</div>

<!-- invalidDKIMSetup -->
<div v-else-if="emailStatus === STATUS.DKIMValidationError">
  <InfoMessage
    v-if="emailError === ERROR.invalidDKIMSetup"
    variant="error"
  >
    <template #title>Invalid DKIM setup.</template>
    <p>
      Please review the help article linked below, fix the DKIM record,
      and then retry the validation.
    </p>

    <p>
      <a
        class="text-green-700 group"
        href="https://help.superokay.com/en/articles/6234488-how-to-set-up-email-white-labeling"
        target="_blank"
      >
        <span class="underline group-hover:no-underline">
          How to add a TXT record to your domain DNS
        </span>
      </a>
    </p>
  </InfoMessage>
  <InfoMessage
    v-else
    variant="error"
  >
    <template #title>An Error Occurred</template>
    An error occurred and your DKIM couldn't be verified. Please contact us for assistance.
  </InfoMessage>
</div>
