
<div>
  <div
    v-for="featureFlagsGroup in featureFlagsGroups"
    :key="featureFlagsGroup.id"
    class="mb-16"
  >
    <PaymentPlanCardFeature
      v-for="featureFlag in featureFlagsGroup.featureFlags"
      :key="featureFlag.featureFlag"
      :group="featureFlagsGroup"
      :feature-flag="featureFlag"
    />
  </div>
</div>
