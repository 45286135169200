<template>
  <TabsPageHeader
    :tabs="tabs"
    :search="!$isMobile"
    :search-keyword.sync="searchKeyword"
    :search-placeholder="$t('tasks.searchPlaceholder')"
  >
    <template #actions>
      <ProjectTasksViewOptions
        v-if="!$isMobile"
        class="mr-8"
        :value="viewContext"
        @input="val => $emit('update:view-context', val)"
      />
      <b-button
        v-if="!hideNewSectionButton"
        variant="neutral"
        size="md"
        class="rounded-lg xl:rounded-sm"
        @click="$emit('create-section')"
      >
        <span class="xl:mr-4 xl:-ml-4 icon_v2-so_plus text-green-600" />
        <span class="hidden xl:inline">{{ $t('tasks.newSection') }}</span>
      </b-button>
    </template>
  </TabsPageHeader>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import {
  PROJECT_TASKS_KANBAN,
  PROJECT_TASKS_LIST,
  PROJECT_TASKS_DOCUMENTS,
} from '@/router/project/type'
import TabsPageHeader from '@/components/TabsPageHeader.vue'
import ProjectTasksViewOptions from '@/components/ProjectTasksViewOptions.vue'

const TABS = [
  {
    id: 'list',
    translationKey: 'tasks.list',
    icon: 'icon_v2-so_list-view',
    to: { name: PROJECT_TASKS_LIST },
    activeOnChildren: true,
  },
  {
    id: 'board',
    translationKey: 'tasks.board',
    icon: 'icon_v2-so_grid',
    to: { name: PROJECT_TASKS_KANBAN },
    activeOnChildren: true,
  },
  {
    id: 'action-items',
    translationKey: 'modules.docs',
    icon: 'icon_v2-so_docs-files',
    to: { name: PROJECT_TASKS_DOCUMENTS },
  },
]

export default defineComponent({
  name: 'ProjectTasksPageHeader',
  components: {
    TabsPageHeader,
    ProjectTasksViewOptions,
  },
  props: {
    viewContext: {
      type: Object,
      required: true,
    },
    keyword: {
      type: String,
      default: '',
    },
    hiddenTabs: {
      type: Array,
      default: () => [],
    },
    hideNewSectionButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const searchKeyword = computed({
      get() {
        return props.keyword
      },
      set(val) {
        context.emit('update:keyword', val)
      },
    })

    const tabs = computed(
      () => TABS.filter(tab => !props.hiddenTabs.includes(tab.id))
    );

    return {
      tabs,
      searchKeyword,
    }
  },
})
</script>
