
<div>
  <PageHeader
    :title="client.name"
    show-back
    @back="$router.go(-1)"
  >
    <template #actions>
      <b-button
        v-track="['New Client Contact Modal Opened', { client: client._id ?? null }]"
        variant="neutral"
        size="md"
        @click="$emit('create-client-contact')"
      >
        <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600"></span>
        <span>Add Contact</span>
      </b-button>
      <b-button
        variant="neutral"
        size="md"
        :to="{ name: ORGANIZATION_CLIENT_DETAILS }"
      >
        <span class="mr-4 -ml-4 icon_v2-so_edit-rectangle text-blue-600"></span>
        <span>Edit Client</span>
      </b-button>
      <b-button
        variant="neutral"
        size="md"
        @click="$emit('remove-client')"
      >
        <span class="mr-4 -ml-4 icon_v2-so_trash text-red-600"></span>
        <span>Delete Client</span>
      </b-button>
    </template>
  </PageHeader>
</div>
