import auth from './auth/authStore'
import billables from './billables/billablesStore'
import configurations from './configurations/configurationsStore'
import contentBlocks from './contentBlocks/contentBlocksStore'
import documents from './documents/documentsStore'
import documentNodes from './documentNodes/documentNodesStore'
import documentStructures from './documentStructures/documentStructuresStore'
import documentEvents from './documentEvents/documentEventsStore'
import members from './members/membersStore'
import myMembers from './myMembers/myMembersStore'
import organizations from './organizations/organizationsStore'
import domains from './domains/domainsStore'
import profiles from './profiles/profilesStore'
import projects from './projects/projectsStore'
import projectEvents from './projectEvents/projectEventsStore'
import users from './users/usersStore'
import comments from './comments/commentsStore'
import actionItems from './actionItems/actionItemsStore'
import clients from './clients/clientsStore'
import notifications from './notifications/notificationsStore'
import downloads from './downloads/downloadsStore'
import taskSections from './taskSections/taskSectionsStore'

export default [
  auth,
  billables,
  configurations,
  contentBlocks,
  documents,
  documentNodes,
  documentStructures,
  documentEvents,
  members,
  myMembers,
  organizations,
  domains,
  profiles,
  projects,
  projectEvents,
  users,
  comments,
  actionItems,
  clients,
  notifications,
  downloads,
  taskSections,
]
