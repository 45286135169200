
<Form
  v-if="state"
  v-slot="{ isBusy, v, submit, hasChanges }"
  :state="state"
  :validation="validation"
  success-message="Organization settings saved"
  :on-submit="() => save()"
>
  <h3 class="project-page-h3 mb-18">
    Default Client Portal Language
  </h3>
  <!-- Language -->
  <FormField
    :v="v.locale"
    label="Language"
    wrapper-class="flex"
    label-class="mr-24"
  >
    <b-form-select v-model="state.locale" class="max-w-240">
      <b-form-select-option
        v-for="(lang, i) in locales"
        :key="`lang-${i}`"
        :value="lang"
      >
        {{ localesNames[lang] }}
      </b-form-select-option>
    </b-form-select>
  </FormField>


  <!-- Portal Login -->
  <div class="grid grid-cols-1 gap-32 mt-32 pb-32">
    <div>
      <h3 class="mt-24 project-page-h3">
        Login Options
      </h3>
      <p class="mb-16 text-gray-500 text-12">
        Switching on both login options will give your
        Client the option to choose how they would like to login
      </p>
      <div class="option-container">
        <FormField
          label="Email / Password"
          wrapper-class="flex items-center flex-wrap"
          label-class="w-192 mb-0"
          aside
          error-class="w-full"
          :description-class="$style['option-description']"
          description="Standard email/password authentication for your portal."
          :v="v.portalLoginPasswordEnabled"
          :messages="{
            required: 'At least one login method must be selected.'
          }"
        >
          <b-form-checkbox
            v-model="state.portalLoginPasswordEnabled"
            switch
            size="lg"
          />
        </FormField>
      </div>
      <div class="option-container">
        <FormField
          label="Magic Link"
          wrapper-class="flex items-center flex-wrap"
          label-class="w-192 mb-0"
          aside
          :v="v.portalLoginMagicLinkEnabled"
          error-class="w-full"
          :description-class="$style['option-description']"
          :description="[
            'One-click magic link authentication for your portal.',
            !state.portalLoginMagicLinkNoExpire
              && 'Each magic link has a short lifespan and will expire if not used.',
            state.portalLoginMagicLinkNoExpire
              && 'Each magic link is permanent and can be used indefinitely.',
          ].filter(Boolean)"
          :messages="{
            required: 'At least one login method must be selected.'
          }"
        >
          <b-form-checkbox
            v-model="state.portalLoginMagicLinkEnabled"
            switch
            size="lg"
            @change="onMagicLinkEnabledChanged"
          />
        </FormField>
      </div>
      <div v-if="state.portalLoginMagicLinkEnabled" class="option-container">
        <FormField
          label="Magic Link Doesn't Expire"
          wrapper-class="flex items-center flex-wrap"
          label-class="w-192 mb-0"
          aside
          error-class="w-full"
          :description-class="$style['option-description']"
          :description="[
            'Permanent magic links for portal authentication',
            'Please be aware that this option may decrease the security level of your portal'
          ]"
        >
          <b-form-checkbox
            v-model="state.portalLoginMagicLinkNoExpire"
            switch
            size="lg"
            @change="onMagicLinkNoExpireChanged"
          />
        </FormField>
      </div>
    </div>
  </div>
  <PageFooter>
    <Button
      :disabled="isBusy"
      size="lg"
      variant="primary"
      type="submit"
      @click="submit"
    >
      Save Changes
    </Button>
    <template v-if="hasChanges" #right>
      <PageFooterMessage />
    </template>
  </PageFooter>
</Form>
