
<div>
  <MainNavItemButton
    label="Feedback"
    icon="icon_v2-so_chat"
    icon-active="icon_v2-so_chat"
    :active="showModal"
    @click="showModal = true"
  />
  <UserFeedbackModal
    v-if="showModal"
    v-model="showModal"
    @sent="showModal = false"
  />
</div>
