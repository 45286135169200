
<WithSidebar>
  <template #sidebar-header>
    <h2 class="font-semibold text-16">Settings</h2>
  </template>
  <template #sidebar>
    <UserSettingsNav />
  </template>
  <template #default>
    <router-view :user="user" :organization="organization" />
  </template>
</WithSidebar>
