
<ProjectPermissions
  v-slot="{ permissions }"
  :project="project"
>
  <DocActions
    v-slot="{
      update,
      remove,
      move,
      duplicate,
      share,
      saveAsReusableBlock,
      download,
      changeFonts,
    }"
    :document="document"
  >
    <DocRenderer :document="document">
      <template #breadcrumbs>
        <DocBreadcrumbs :document="document" :project="project" />
      </template>
      <template #actions>
        <DocMenu
          :document="document"
          :active-items="{
            share: true,
            remove: permissions.remove,
            download: true,
            move: permissions.create && permissions.remove,
            duplicate: permissions.create,
            saveAsReusableBlock: permissions.create,
            changeFonts: permissions.update,
          }"
          @remove="remove"
          @move="move"
          @duplicate="duplicate"
          @share="share"
          @download="download"
          @save-as-reusable-block="saveAsReusableBlock"
          @change-fonts="changeFonts"
        />
      </template>
      <template #header>
        <DocTitle
          :disabled="!permissions.update"
          :document="document"
          @update="title => update({ title })"
        />
        <DocSharedBadge :document="document" @click="share" />
      </template>

      <template #footer-left>
        <DocUpdatedBy :document="document" />
      </template>
      <template #footer-right>
        <DocPreviewToggle
          v-if="permissions.create"
          :document="document"
        />
        <DocStatusToggle
          :value="document.status"
          data-intercom-target="Document Status Select"
          @input="status => update({ status })"
        />
      </template>

      <template #content>
        <DocumentEditorContent
          v-if="document"
          :document="document"
          :read-only="!permissions.update"
        />
        <DocApprovalBadge :document="document" />
      </template>
      <template #sidebar-right>
        <DocSidebar :document="document" />
      </template>
    </DocRenderer>
  </DocActions>
</ProjectPermissions>
