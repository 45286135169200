
<div>
  <div
    class="flex flex-wrap"
  >
    <ColorPaletteItem
      v-for="(color, index) in value"
      :key="color.id"
      :no-fade="noFade"
      :popover-placement="popoverPlacement"
      class="mr-8 included list-item mb-8 list-none"
      :value="color.color"
      :is-new="index === newColorIndex"
      :size="size"
      @input="col => handleEditColor(col, index)"
      @remove-color="handleRemoveColor(index)"
    />

    <button
      v-b-tooltip.hover.bottom.v-info.dh0.ds200
      type="button"
      title="Add new color"
      :class="[
        sizeMap[size].buttonSize,
        'rounded-full relative inline-flex items-center',
        'justify-center ring-2 ring-gray-200 ring-inset bg-white text-gray-700',
        'hover:ring-gray-300 transition-all outline-none focus:outline-none flex-none'
      ]"
      @click="handleAddNewColor"
    >
      <span :class="['icon_v2-so_plus',sizeMap[size].iconSize]" />
    </button>
  </div>
</div>
