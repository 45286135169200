<template>
  <div class="pb-16">
    <PageHeader title="Subscription">
      <template #actions>
        <template v-if="licensing.provider === LICENSING_PROVIDER.chargebee">
          <Button
            v-if="licensing.isActive && plan.isManageable"
            size="md"
            variant="neutral"
            :to="{
              name: ORGANIZATION_SETTINGS_SUBSCRIPTION_MANAGE
            }"
          >
            Manage Subscription
          </Button>
          <Button
            v-if="plan.isUpgradable || licensing.isTrial"
            size="md"
            variant="neutral"
            :to="{
              name: ORGANIZATION_SUBSCRIPTION,
              params: { provider: LICENSING_PROVIDER.chargebee }
            }"
          >
            {{ licensing.isTrial ? 'Subscribe' : 'Upgrade' }}
          </Button>
        </template>
        <template v-if="licensing.provider === LICENSING_PROVIDER.appsumo">
          <Button
            size="md"
            variant="neutral"
            :href="`https://appsumo.com/account/redemption/${licensing.invoiceItemUUID}#change-plan`"
            target="_blank"
          >
            Manage Subscription
          </Button>
          <Button
            v-if="plan.isUpgradable"
            size="md"
            variant="neutral"
            :to="{
              name: ORGANIZATION_SUBSCRIPTION,
              params: { provider: LICENSING_PROVIDER.appsumo }
            }"
          >
            Change Plan
          </Button>
        </template>
      </template>
    </PageHeader>
    <div v-if="licensing.status === SUBSCRIPTION_STATUS.pending" class="mb-16">
      <span>Subscription status <Badge variant="warning">Pending</Badge> </span>
    </div>
    <div v-else class="mb-32">
      <h3 class="project-page-subtitle">Your plan details</h3>
      <div class="flex flex-col mb-24 lg:items-end lg:flex-row">
        <b-row>
          <b-col
            v-if="plan.name"
            md="auto"
            class="mr-3"
          >
            <div :class="$style['plan-label']">Current plan</div>
            <p :class="$style['plan-result']">
              {{ plan.name }}
            </p>
          </b-col>
          <b-col v-if="licensing.status" md="auto">
            <div :class="$style['plan-label']">Status</div>
            <p class="capitalize" :class="$style['plan-result']">
              {{ licensing.status | translate }}
            </p>
          </b-col>
          <b-col v-if="licensing.isTrial" md="auto">
            <div :class="$style['plan-label']">Trial expires</div>
            <p class="capitalize" :class="$style['plan-result']">
              {{ licensing.trialEnd | dateIntlDistance }}
            </p>
          </b-col>
          <b-col
            v-if="licensing.isActive && licensing.price"
            md="auto"
            class="mr-3"
          >
            <div :class="$style['plan-label']">
              Price
            </div>
            <div :class="$style['plan-result']">
              <CurrencyFormatter
                :value="licensing.price"
                :currency="licensing.priceCurrency"
              /> / {{ licensing.pricePeriodUnit }}
            </div>
          </b-col>
          <b-col
            v-if="licensing.isActive && licensing.nextBillingAt && licensing.price"
            md="auto"
          >
            <div :class="$style['plan-label']">Next billing date</div>
            <p :class="$style['plan-result']">
              {{ licensing.nextBillingAt | dateTime('date') }}
            </p>
          </b-col>
          <b-col
            v-else-if="licensing.isNonRenewing"
            md="auto"
          >
            <div :class="$style['plan-label']">Subscription term end</div>
            <p :class="$style['plan-result']">
              {{ licensing.currentTermEnd | dateTime('date') }}
            </p>
          </b-col>
        </b-row>
      </div>
      <div v-if="licensing.isTrial" class="text-gray-600">
        When your 14 Days Fee Trial expires,
        you will be signed out of your account and you will be able to regain access to your account
        and all of your existing projects by signing up to any of our paid plans.
      </div>

      <Button
        size="sm"
        variant="primary"
        :to="{
          name: ORGANIZATION_SUBSCRIPTION,
          params: { provider: licensing.provider }
        }"
      >
        View Payment Plans
      </Button>
      <h3 class="mt-16 mb-8 project-page-subtitle">Your current plan</h3>
      <PaymentPlanCardFeatures
        :feature-flags-groups="organization.featureFlagsGroups"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  ORGANIZATION_SUBSCRIPTION,
  ORGANIZATION_SETTINGS_SUBSCRIPTION_MANAGE,
} from '@/router/organization/type'
import { LICENSING_PROVIDER, SUBSCRIPTION_STATUS } from '@/v2/services/organizations/organizationsTypes'
import PageHeader from '@/components/PageHeader.vue'
import Button from '@/components/Button.vue'
import CurrencyFormatter from '@/components/CurrencyFormatter.vue'
import Badge from '@/components/Badge.vue'
import PaymentPlanCardFeatures from '@/components/PaymentPlanCardFeatures.vue'

export default defineComponent({
  name: 'ViewOrganizationSettingsSubscription',
  components: {
    PageHeader,
    Button,
    CurrencyFormatter,
    Badge,
    PaymentPlanCardFeatures,
  },
  props: {
    licensing: {
      type: Object,
      required: true,
    },
    plan: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      ORGANIZATION_SUBSCRIPTION,
      ORGANIZATION_SETTINGS_SUBSCRIPTION_MANAGE,
      SUBSCRIPTION_STATUS,
      supportEmail: import.meta.env.VITE_SUPPORT_EMAIL,
      LICENSING_PROVIDER,
    }
  },
})
</script>
<style lang="scss" module>
.locale-select {
  max-width: 245px;
}
.plan-label {
  @apply mb-4 inline-block text-12 text-gray-500 dark:text-darkGray-400;
}
.plan-item {
  margin-bottom: 18px;
}
.plan-result {
  @apply text-14 text-gray-700 font-semibold;
}

.plan-support {
  color: $gray-700;
  font-weight: $regular;
  font-size: $fs14;
}
</style>
