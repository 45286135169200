
<OrganizationGet
  :id="organizationId"
  v-slot="{ organization, isGetPending: isOrganizationGetPending }"
>
  <div class="flex flex-col w-full">
    <div
      v-if="!isOrganizationGetPending"
      class="flex flex-col mx-auto lg:pb-48 max-w-[554px] md:py-16 px-16 md:px-32"
    >
      <!-- Welcome message -->
      <div class="mb-36 text-center">
        <h1 class="font-semibold text-gray-900 text-18 md:text-24">
          Let's continue with your details
        </h1>
      </div>

      <FormMessage v-if="error">
        {{ error }}
      </FormMessage>
      <SignUpFormOrganization
        :profile="profile"
        :user="user"
        :organization="organization"
        :is-loading="isLoading"
        @submit="onSubmit"
      />
    </div>
  </div>
</OrganizationGet>
