import { has, isEmpty, prop } from 'ramda';
import { unref } from '@vue/composition-api';
import objectDiff from '@/v2/lib/helpers/object-diff'
import { isNumber } from 'ramda-adjunct';

const getNumericLimit = val => {
  if (isNumber(val)) {
    return val
  }

  if (val === null) {
    return Infinity
  }

  return 0
}

const downgradeMessages = [
  {
    flag: 'limitMembers',
    check: (newValue, oldValue) => getNumericLimit(newValue) < getNumericLimit(oldValue),
    message: 'Some of your invited team members will be disabled (if applicable)',
  },
  {
    flag: 'customDomains',
    check: (newValue, oldValue) => !newValue && oldValue,
    message: 'Your custom domain will be disabled (if set)',
  },
  {
    flag: 'fullWhiteLabel',
    check: (newValue, oldValue) => !newValue && oldValue,
    message: 'Full white label on client portal will be disabled',
  },
  {
    flag: 'portalShowPoweredBySuperOkay',
    check: (newValue, oldValue) => newValue && !oldValue,
    message: '"Powered by Superokay" badge will appear on client portal',
  },
]

const computeDowngradeMessages = (messages, diff) => messages
  .filter(({ flag, check }) => has(flag, diff)
    && check(diff[flag].newValue, diff[flag].oldValue))
  .map(prop('message'))


export default currentFeatureFlags => newFeatureFlags => {
  const featureFlagsDiff = objectDiff(
    unref(currentFeatureFlags),
    newFeatureFlags
  )

  const changed = !isEmpty(featureFlagsDiff)
  const messages = changed
    ? computeDowngradeMessages(downgradeMessages, featureFlagsDiff)
    : null

  return { changed, messages };
}
