
<ContentLibraryList
  v-slot="{ document }"
  :category="category"
  :parent-folder-id="parentFolderId"
  :title="CATEGORY_TITLES[category] | pluralize(2)"
  create-button="Create new"
  v-bind="$attrs"
  :grid="false"
  v-on="$listeners"
>
  <FolderItemRow
    :document="document"
    size="l"
    read-only
  />
</ContentLibraryList>
