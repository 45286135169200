
<div class="flex-1 flex -m-16">
  <ProjectDashboardPageHeader
    v-if="!$isMobile"
    :project="project"
  />
  <ProjectDashboard
    :organization="organization"
    :project="project"
    :routes="routes"
    @create-quick-link="isQuickLinkModalOpen = true"
  />
  <ProjectSettingsQuickLinkModal
    v-if="isQuickLinkModalOpen"
    v-model="isQuickLinkModalOpen"
    @create="createQuickLink"
  />
</div>
