<template>
  <div class="pb-16">
    <PageHeader title="Team Members">
      <template #actions>
        <b-button
          v-track="['New Team Member Modal Opened']"
          type="submit"
          variant="neutral"
          size="md"
          @click="organizationTeamRef.create()"
        >
          <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600"></span>
          Invite team member
        </b-button>
      </template>
    </PageHeader>
    <OrganizationTeam
      ref="organizationTeamRef"
      :organization-id="organization._id"
      type-of-members="team-members"
    />
    <OrganizationTeamPermissions />
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import PageHeader from '@/components/PageHeader.vue'
import OrganizationTeam from '@/components/OrganizationTeam.vue'
import OrganizationTeamPermissions from '@/components/OrganizationTeamPermissions.vue'

export default defineComponent({
  name: 'ViewOrganizationSettingsTeamMembers',
  components: {
    PageHeader,
    OrganizationTeam,
    OrganizationTeamPermissions,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const organizationTeamRef = ref(null)

    return {
      organizationTeamRef,
    }
  },
})
</script>
