<template>
  <b-form @submit.prevent="formSubmit">
    <b-form-group :class="$style.group">
      <FormField
        :v="$v.email"
        label="Email Address"
        required
        :messages="{
          email: 'A valid email address is required',
          required: 'A valid email address is required',
          available: 'The email address you have entered is already registered'
        }"
      >
        <b-input
          v-model.trim="formStateEmail"
          placeholder="you@super.agency"
          type="email"
          :disabled="isEmailLocked"
          autocomplete="username"
          class="form-control-lg"
          autofocus
        />
      </FormField>
    </b-form-group>
    <b-form-group :class="$style.group">
      <div :class="$style['group-col']">
        <section>
          <FormField
            :v="$v.firstName"
            label="First Name"
            required
            :messages="{
              required: 'Please fill in your first name',
            }"
          >
            <b-input
              v-model="formState.firstName"
              placeholder="Alex"
              autocomplete="given-name"
              class="form-control-lg"
            />
          </FormField>
        </section>
        <section>
          <FormField
            :v="$v.lastName"
            label="Last Name"
            required
            :messages="{
              required: 'Please fill in your last name',
            }"
          >
            <b-input
              v-model="formState.lastName"
              placeholder="Appleseed"
              autocomplete="family-name"
              class="form-control-lg"
            />
          </FormField>
        </section>
      </div>
    </b-form-group>
    <b-form-group :class="$style.group">
      <FormField
        :v="$v.password"
        label="Password (must be at least 8 characters)"
        required
        :messages="{
          minLength: 'The password should be at least 8 characters long.”',
          required: 'Please fill in the Password field',
        }"
      >
        <FormInputPassword
          v-model="formState.password"
          placeholder="Choose your password"
        />
      </FormField>
    </b-form-group>
    <Button
      type="submit"
      size="md"
      variant="primary"
      :is-loading="isLoading"
      :block="true"
    >
      <span v-if="isLoading">Your account is being created...</span>
      <span v-else>{{ buttonLabel }}</span>
    </Button>
    <div :class="$style.note">
      By continuing, you agree to our
      <b-link href="https://www.superokay.com/terms-of-use">
        Terms Of Use
      </b-link>
    </div>
  </b-form>
</template>

<script>
import { pathOr, pick, applySpec } from 'ramda'
import { defineComponent, reactive, computed, ref } from '@vue/composition-api'
import { required, email, minLength, helpers as vuelidateHelpers } from '@vuelidate/validators'
import { useTracking } from '@/v2/services/tracking/compositions'
import useForm from '@/v2/lib/composition/useForm'
import useValidators from '@/v2/services/validators/validatorsCompositions'
import debounce from '@/v2/lib/helpers/debounce'
import { email as emailSanitizer } from '@/v2/lib/helpers/sanitizer'
import FormField from '@/components/FormField.vue'
import Button from '@/components/Button.vue'
import FormInputPassword from './FormInputPassword.vue'

const pathOrEmpty = pathOr('');
const userProps = pick(['email', 'password']);
const profileProps = pick(['firstName', 'lastName']);

const formStateSpec = applySpec({
  email: pathOrEmpty(['user', 'email']),
  password: pathOrEmpty(['user', 'password']),
  firstName: pathOrEmpty(['profile', 'firstName']),
  lastName: pathOrEmpty(['profile', 'lastName']),
})

const { withAsync } = vuelidateHelpers

export default defineComponent({
  name: 'SignUpFormProfile',
  components: {
    FormField,
    Button,
    FormInputPassword,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    profile: {
      type: Object,
      default: () => ({}),
    },
    buttonLabel: {
      type: String,
      default: 'Continue',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    /** If this is `true`, the email field is not editable */
    isEmailLocked: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const formState = reactive(formStateSpec(props))

    const { trackGuest } = useTracking()
    const { emailAvailable } = useValidators()

    const formStateEmail = computed({
      get: () => formState.email,
      set: debounce(value => {
        formState.email = emailSanitizer(value)
      }),
    })

    const onSubmit = () => {
      trackGuest('Onboarding Account Completed', {
        email: formState.email,
      })

      context.emit('submit', {
        user: userProps(formState),
        profile: profileProps(formState),
      })
    }

    const hidePassword = ref(true);

    const { formSubmit, $v } = useForm({
      onSubmit,
      state: formState,
      validation: {
        email: {
          required,
          email,
          available: withAsync(emailAvailable),
          $lazy: true,
        },
        password: {
          required,
          minLength: minLength(8),
        },
        firstName: {
          required,
        },
        lastName: {
          required,
        },
      },
    })

    return {
      formState,
      formSubmit,
      formStateEmail,
      $v,
      hidePassword,
    }
  },
})
</script>

<style lang="scss" module>
.group {
  margin-bottom: 16px;
}
.group-col {
  display: flex;
  margin-left: -13px;
  section {
    flex: 1;
    margin-left: 13px;
  }
}
.note {
  font-size: $fs14;
  color: $gray-500;
  text-align: center;
  margin: 16px 0 24px;
}
</style>
