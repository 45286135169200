<template>
  <DocActions
    v-slot="{
      update,
      remove,
      duplicate,
      share,
      saveAsReusableBlock,
      download,
      changeFonts,
    }"
    :document="document"
  >
    <DocRenderer :document="document">
      <template #breadcrumbs>
        <DocLibraryBreadcrumbs :document="document" :category="category" />
      </template>
      <template #actions>
        <DocMenu
          :document="document"
          :active-items="{
            share: true,
            remove: true,
            download: true,
            duplicate: true,
            saveAsReusableBlock: true,
            changeFonts: true,
          }"
          @remove="remove"
          @duplicate="duplicate"
          @share="share"
          @download="download"
          @save-as-reusable-block="saveAsReusableBlock"
          @change-fonts="changeFonts"
        />
      </template>
      <template #header>
        <DocTitle
          :document="document"
          @update="title => update({ title })"
        />
        <DocSharedBadge :document="document" @click="share" />
      </template>

      <template #footer-left>
        <DocUpdatedBy :document="document" />
      </template>
      <template #footer-right>
        <DocPreviewToggle :document="document" />
        <DocStatusToggle
          :value="document.status"
          data-intercom-target="Document Status Select"
          @input="status => update({ status })"
        />
      </template>

      <template #content>
        <DocumentEditorContent
          v-if="document"
          :document="document"
        />
        <DocApprovalBadge :document="document" />
      </template>
    </DocRenderer>
  </DocActions>
</template>

<script>
import { defineComponent, provide } from '@vue/composition-api'
import { ORGANIZATION_LIBRARY_REUSABLE_BLOCKS } from '@/router/organization/type'
import { CATEGORY } from '@/v2/services/documents/documentsTypes'
import DocumentEditorContent from '@/components/Document/Editor/DocumentEditorContent.vue'
import DocApprovalBadge from '@/components/Doc/DocApprovalBadge.vue'
import DocLibraryBreadcrumbs from '@/components/Doc/DocLibraryBreadcrumbs.vue'
import DocMenu from '@/components/Doc/DocMenu.vue'
import DocRenderer from '@/components/Doc/DocRenderer.vue'
import DocTitle from '@/components/Doc/DocTitle.vue'
import DocUpdatedBy from '@/components/Doc/DocUpdatedBy.vue'
import DocPreviewToggle from '@/components/Doc/DocPreviewToggle.vue'
import DocStatusToggle from '@/components/Doc/DocStatusToggle.vue'
import DocActions from '@/components/Doc/DocActions.vue'
import DocSharedBadge from '@/components/Doc/DocSharedBadge.vue'

export default defineComponent({
  name: 'DocumentEditorReusableBlock',
  components: {
    DocumentEditorContent,
    DocApprovalBadge,
    DocLibraryBreadcrumbs,
    DocMenu,
    DocRenderer,
    DocTitle,
    DocUpdatedBy,
    DocPreviewToggle,
    DocStatusToggle,
    DocActions,
    DocSharedBadge,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  setup() {
    provide('hideNodeComments', true)
    provide('hideNodeActionItems', true)

    return {
      ORGANIZATION_LIBRARY_REUSABLE_BLOCKS,
      category: CATEGORY.DocumentReusableBlock,
    }
  },
})
</script>
