import Vue from 'vue'
import Router from 'vue-router'
import { sync } from 'vuex-router-sync'

import landingFactory from './landing'
import guestFactory from './guest'
import appFactory from './app'
import welcomeRoutes from './welcome'
import errorRoutes from './error'

Vue.use(Router)

export default function routerFactory(container, options = {}) {
  const { scrollTop = false, scrollElementSelector = null } = options;

  const { baseUrl } = container.get('config')
  const router = new Router({
    mode: 'history',
    base: baseUrl,
    routes: [
      ...errorRoutes(),
      ...guestFactory(container),
      ...landingFactory(container),
      ...appFactory(container),
      ...welcomeRoutes(),
    ],
    scrollBehavior(to, from, savedPosition) {
      return scrollTop ? { y: 0 } : savedPosition
    },
  })

  // if (scrollTop) {
  //   router.afterEach(() => {
  //     const el = document.querySelector(scrollElementSelector)
  //     if (el) {
  //       el.scrollTop = 0
  //       return
  //     }

  //     window.scrollTo(0, 0)
  //   })
  // }

  container.set('router', router)

  const store = container.get('store')
  sync(store, router)

  // Register navigation handler for 404 server errors
  // If some content cannot be found, redirect user to organization dashboard
  // EventBus.$on(EVENT_TYPES.contentNotFound, () => {
  //   router.currentRoute.name !== ORGANIZATION_HOME &&
  //     router.push({ name: ORGANIZATION_HOME })
  // })

  return router
}
