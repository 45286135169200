// Tasks
import ViewProjectTasks from '@/views/ViewProjectTasks.vue'
import ViewProjectTasksList from '@/views/ViewProjectTasksList.vue'
import ViewProjectTasksKanban from '@/views/ViewProjectTasksKanban.vue'
import ViewProjectTask from '@/views/ViewProjectTask.vue'
import ViewProjectTasksDocuments from '@/views/ViewProjectTasksDocuments.vue'

import {
  PROJECT_TASKS,
  PROJECT_TASKS_LIST,
  PROJECT_TASKS_LIST_TASK,
  PROJECT_TASKS_KANBAN,
  PROJECT_TASKS_KANBAN_TASK,
  PROJECT_TASKS_DOCUMENTS,
  PROJECT_TASKS_DOCUMENTS_TASK,
} from './type'

export default [
  {
    name: PROJECT_TASKS,
    path: 'tasks',
    component: ViewProjectTasks,
    props: true,
    redirect: { name: PROJECT_TASKS_LIST },
    children: [
      {
        name: PROJECT_TASKS_LIST,
        path: 'list',
        component: ViewProjectTasksList,
        children: [
          {
            name: PROJECT_TASKS_LIST_TASK,
            path: ':sectionId/:taskId',
            component: ViewProjectTask,
            props: ({ params }) => ({ ...params, mode: 'list' }),
          },
        ],
      },
      {
        name: PROJECT_TASKS_KANBAN,
        path: 'board',
        component: ViewProjectTasksKanban,
        children: [
          {
            name: PROJECT_TASKS_KANBAN_TASK,
            path: ':sectionId/:taskId',
            component: ViewProjectTask,
            props: ({ params }) => ({ ...params, mode: 'board' }),
          },
        ],
      },
      {
        name: PROJECT_TASKS_DOCUMENTS,
        path: 'documents',
        component: ViewProjectTasksDocuments,
        children: [
          {
            name: PROJECT_TASKS_DOCUMENTS_TASK,
            path: ':documentId/:taskId',
            component: ViewProjectTask,
            props: ({ params }) => ({ ...params, mode: 'documents' }),
          },
        ],
      },
    ],
  },
]
