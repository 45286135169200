
<DocumentNodeEditorModel
  v-slot="{
    node,
    updateNode,
    contentBlock,
    updateContentBlock,
    asyncSaveStart,
  }"
  :node-id="nodeId"
  :use-clone="isFocus"
>
  <DocumentNodeResolverEditor v-slot="{ label, component }" :node="node">
    <component
      :is="component"
      :node="node"
      :content-block="contentBlock"
      :is-focus="isFocus"
      @update="updateContentBlock"
      @update-node="updateNode"
      @async-save-start="asyncSaveStart"
    >
      <template #editor="{ config, styling, textVariant }">
        <DocumentNodeEditor
          :label="label"
          :node="node"
          :parent="parent"
          :config="config"
          :styling="styling"
          :text-variant="textVariant"
          :index="index"
          :is-first="isFirst"
          :is-last="isLast"
          :rounded-selection="roundedSelection"
          :grid="grid"
          @update="updateNode"
          @move="moveNode"
          @async-save-start="asyncSaveStart"
        />
      </template>
    </component>
  </DocumentNodeResolverEditor>
</DocumentNodeEditorModel>
