
<SpinnerOverlay
  v-if="trialExtendRequested"
  variant="blur"
  label="Extending your trial period..."
/>
<div v-else-if="trialExtendAllow">
  <span class="text-13 text-gray-500">Need more time?</span>
  <Button
    variant="primary"
    size="xs"
    class="ml-8"
    :is-loading="isBusyExtendTrial"
    @click="extendTrial"
  >
    Extend you trial by {{ TRIAL_EXTEND_DAYS }} days
  </Button>
</div>
