<template>
  <portal :to="to">
    <Provide :provide="provide">
      <slot />
    </Provide>
  </portal>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import Provide from '@/components/Provide.vue'

export default defineComponent({
  name: 'PortalRender',
  components: {
    Provide,
  },
  props: {
    to: {
      type: String,
      required: true,
    },
    provide: {
      type: Object,
      default: null,
    },
  },
})
</script>
