
<!-- User needs to confirm the CNAME record -->
<InfoMessage
  v-if="domainStatus === STATUS.awaitingCNAMEConfirmation"
  variant="info"
>
  <template #title>Awaiting CNAME Record Confirmation</template>
  Please set the CNAME record as instructed below, then confirm the CNAME settings.
</InfoMessage>

<!-- Domain setup in progress -->
<InfoMessage
  v-else-if="domainStatus === STATUS.setupPending"
  variant="info"
>
  <template #title>Your Domain Is Being Set Up...</template>
  <template #default>
    <div>
      Your domain is being set up. It could take up to 24 hours for the
      CNAME to propagate properly.
    </div>
    <div class="mt-16">
      <Button
        size="sm"
        variant="danger"
        :is-loading="cancelIsLoading"
        @click="$emit('cancel')"
      >
        Cancel Setup
      </Button>
    </div>
  </template>
</InfoMessage>

<InfoMessage
  v-else-if="statusCancelSetup"
  variant="warn"
>
  <template #title>Domain Setup Cancellation in Progress</template>
  The domain setup cancellation for
  <strong>{{ organization.domain }}</strong> is currently in progress.
  Please wait while we process your request.
  You can restart the setup with the same or a different domain once the cancellation is complete.
</InfoMessage>

<!-- Setup Error -->
<div v-else-if="domainStatus === STATUS.setupError">
  <!-- CNAMEInvalid / CNAMEMismatch error -->
  <InfoMessage
    v-if="domainError === ERROR.CNAMEInvalid || domainError === ERROR.CNAMEMismatch"
    variant="error"
  >
    <template #title>Invalid CNAME Settings</template>
    <p>
      Please review the help article linked below, fix the CNAME record,
      and then retry the validation.
    </p>

    <p>
      <a
        class="text-green-700 group"
        href="https://help.superokay.com/en/articles/5797674-how-to-connect-your-custom-domain"
        target="_blank"
      >
        <span class="underline group-hover:no-underline">
          How to configure your CNAME settings
        </span>
      </a>
    </p>
  </InfoMessage>

  <!-- domainExists error -->
  <InfoMessage
    v-else-if="domainError === ERROR.domainExists"
    variant="error"
  >
    <template #title>The Domain Already Exists</template>
    Please use a different domain or contact support for assistance.
  </InfoMessage>

  <!-- domainInvalid error -->
  <InfoMessage
    v-else-if="domainError === ERROR.domainInvalid"
    variant="error"
  >
    <template #title>The Domain Is Invalid</template>
    Please check the domain name and try again.
  </InfoMessage>

  <!-- unknown error -->
  <InfoMessage
    v-else
    variant="error"
  >
    <template #title>An Error Occurred</template>
    An error occurred and your domain couldn't be set up. Please contact us for assistance.
  </InfoMessage>
</div>
