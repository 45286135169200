
<div>
  <PageHeader title="Embedded Apps">
    <template #actions>
      <Button
        v-track="['New Embedded App Modal Opened']"
        type="button"
        variant="neutral"
        size="md"
        @click="connectBlank"
      >
        <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600" />
        Embed App
      </Button>
    </template>
  </PageHeader>
  <InfoBox
    title="Extend the portal with apps you already use"
    icon="icon_v2-so_apps"
  >
    Each app becomes a new custom sidebar menu app and
    is featured on the Portal Dashboard.  👉
    <a
      href="https://help.superokay.com/en/articles/6053247-how-do-you-embed-apps-in-a-superokay-client-portal"
      class="underline"
      target="_blank"
    >Here's a guide on how to embed apps in SuperOkay</a>
  </InfoBox>
  <!-- Quick Connect -->
  <div class="mb-32">
    <h3 class="settings-section-title">
      Quick embed
    </h3>
    <p class="settings-section-description">
      Click on one of the apps below for instructions.
    </p>
    <div class="flex flex-row flex-wrap gap-16 mb-24">
      <QuickEmbedApp
        v-for="app in quickEmbedApps"
        :key="app.name"
        :app="app"
        @click="preselect(app)"
      />
    </div>
  </div>

  <Form
    v-if="state"
    v-slot="{ isBusy, submit, hasChanges }"
    :state="state"
    success-message="Project changes saved"
    :on-submit="() => save()"
  >
    <div class="flex flex-col max-w-page">
      <!-- Pinned Folders -->
      <b-form-group>
        <SlickList
          v-if="state.portalLinkedApps.length"
          :value="state.portalLinkedApps"
          use-drag-handle
          lock-to-container-edges
          axis="xy"
          class="
            md:grid md:gap-24 space-y-24 md:space-y-0 gap-24
            md:grid-cols-4
            md:auto-cols-fr md:auto-rows-fr
            mx-16 sm:mx-16 max-w-page
          "
          helper-class="so-table-row-dragging"
          @input="reorder"
        >
          <SlickItem
            v-for="(app, index) in state.portalLinkedApps"
            :key="app._id"
            :index="index"
            class="
              flex flex-col h-full col-span-1 row-span-1 p-0
              mb-16 overflow-visible card-interactive
            "
          >
            <ProjectSettingsApp
              :app="app"
              @update="update"
              @remove="remove"
              @edit="edit"
            />
          </SlickItem>
        </SlickList>
      </b-form-group>
      <!-- End of Pinned Folders -->
    </div>
    <PageFooter>
      <Button
        :disabled="isBusy"
        size="lg"
        variant="primary"
        type="submit"
        @click="submit"
      >
        Save Changes
      </Button>
      <template v-if="hasChanges" #right>
        <PageFooterMessage />
      </template>
    </PageFooter>
  </Form>
  <ProjectSettingsEmbeddedAppModal
    v-if="isModalOpen"
    v-model="isModalOpen"
    :app-preset="appPreset"
    @create="create"
    @update="update"
  />
</div>
