
<div>
  <PageHeader title="Document Templates" />
  <div
    class="
    grid gap-24 grid-cols-1 sm:grid-cols-3 tablet:grid-cols-2
    xl:grid-cols-3 auto-cols-min auto-rows-min
  "
  >
    <DocumentTemplatePreview
      v-for="template in templates"
      :key="template.id"
      :template="template"
      :category="template.template_category.name"
    />
  </div>
</div>
