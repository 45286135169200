
<div v-fragment>
  <div class="flex items-center space-x-8">
    <Button
      type="button"
      variant="dark"
      size="sm"
      class="right-pos"
      :disabled="disabled || !hasSelectableMembers"
      @click="openModal"
    >
      <span class="mr-4 icon_v2-so_edit-rectangle"></span>
      {{ buttonTitle }}
    </Button>
    <div
      v-if="disabled && disabledMessage"
      class="text-gray-400 text-12 dark:text-darkGray-300"
    >
      {{ disabledMessage }}
    </div>
    <div v-else-if="!hasSelectableMembers && emptyMessage" class="text-gray-400 text-12">
      {{ emptyMessage }}
    </div>
  </div>
  <div class="mt-24">
    <TeamMemberList :items="assignedMembers" />
  </div>
  <ProjectSettingsManageAccessModal
    v-if="isModalOpen"
    v-model="isModalOpen"
    :title="selectPeopleTitle"
    :description="selectPeopleDescription"
    :items="selectableMembers"
    :model.sync="state"
  />
</div>
