
<div v-if="!directLink">
  <MainNavItemButton
    ref="buttonSwitch"
    :label="label"
    :icon="icon"
    :icon-active="iconActive"
    :active="active"
    @click.stop="showPopover = !showPopover"
  />
  <ProjectCreate v-slot="{ create }">
    <ProjectSwitcher
      v-if="showPopover"
      :organization="organization"
      :current-project="project"
      :show.sync="showPopover"
      show-client
      :navi-button-ref="buttonSwitch"
      @new-project="create"
      @select="goToProject"
      @all-projects="goToAllProjects"
    />
  </ProjectCreate>
</div>
<MainNavItemLink
  v-else
  :label="label"
  :icon="icon"
  :icon-active="iconActive"
  :to="{ name: ORGANIZATION_HOME }"
  :activate-on-parent-routes="[PROJECT]"
/>
