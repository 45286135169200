
<FeatureEnabled
  v-slot="{ checkFeatureEnabled: checkFullWhiteLabelEnabled }"
  flag="fullWhiteLabel"
  @allow="() => save()"
>
  <Form
    v-if="state"
    v-slot="{ isBusy, submit, hasChanges }"
    :state="state"
    success-message="Organization settings saved"
    :on-submit="() => checkFullWhiteLabelEnabled()"
    class="pb-24"
  >
    <!-- FAVICON -->
    <div class="grid grid-cols-1 gap-32 lg:grid-cols-2">
      <div class="flex flex-col items-start">
        <h3 class="project-page-h3">
          Favicon
        </h3>
        <p class="mb-8 text-gray-500 text-12">A square 64x64px .png</p>
        <LogoUpload
          v-model="state.portalMetaFavIcon"
          :height="80"
          :width="80"
          :display-height="64"
          :display-width="64"
          variant="light"
          class-name="p-0 !bg-transparent border-none"
          @uploading="uploadImage"
        >
          <template #buttons="{ selectFiles, removeFile }">
            <Button
              v-if="!state.portalMetaFavIcon"
              variant="dark"
              size="xs"
              @click="selectFiles"
            >
              Upload Favicon
            </Button>
            <template v-else>
              <Button
                variant="dark"
                size="xs"
                @click="selectFiles"
              >
                Replace
              </Button>
              <Button
                variant="danger"
                size="xs"
                @click="removeFile"
              >
                Remove
              </Button>
            </template>
          </template>
        </LogoUpload>
      </div>

      <div>
        <h3 class="text-gray-400 project-page-h3">
          Preview
        </h3>
        <div class="pointer-events-none">
          <!-- Top -->
          <div
            class="flex items-end h-40 pl-16 bg-gray-200 border border-gray-300 rounded-t-lg "
          >
            <ul class="flex flex-row items-center h-32 mr-12 space-x-4">
              <li class="w-10 h-10 bg-gray-300 rounded-full"></li>
              <li class="w-10 h-10 bg-gray-300 rounded-full"></li>
              <li class="w-10 h-10 bg-gray-300 rounded-full"></li>
            </ul>
            <!-- tab -->
            <div
              class="relative flex flex-row items-center h-32 px-12 space-x-6 transform
                  translate-y-px bg-gray-100 border-t border-l border-r border-gray-300
                  rounded-t-lg w-160 "
            >
              <img
                :src="state.portalMetaFavIcon
                  || `${publicPath}assets/img/favicons/favicon-96x96.png`"
                alt=""
                class="w-16 h-16"
              />
              <span class="text-gray-700 truncate text-12">
                {{ state.portalMetaTitle || organization.name }}
              </span>
            </div>
          </div>
          <!-- Bottom -->
          <div
            class="
              flex flex-row items-center px-12 pt-6 pb-6 space-x-16 text-gray-300 bg-gray-100
              border-b border-l border-r border-gray-300 rounded-b-lg
            "
          >
            <span class="icon-arrow-left-util text-18"></span>
            <span class="icon-arrow-right-util text-18"></span>
            <div
              class="
                flex items-center flex-1 h-32 pl-16 bg-white border border-gray-200
                rounded-full
              "
            >
              <span class="mr-4 text-gray-400 icon_v2-so_lock text-11"></span>
              <span class="text-gray-400 text-12">{{ portalUrl }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-px border-b border-gray-200 w-full my-24"></div>
    <!-- METADATA` -->
    <div class="grid grid-cols-1 gap-32 lg:grid-cols-2">
      <div class="flex flex-col space-y-16">
        <h3 class="project-page-h3">
          Metadata
        </h3>
        <FormField
          label="Meta Title"
        >
          <b-input
            v-model="state.portalMetaTitle"
            size="lg"
            placeholder="Add your title here..."
          />
          <span class="text-gray-500 text-13">Recommended length: 60 characters</span>
        </FormField>
        <FormField
          label="Meta Description"
        >
          <b-textarea
            v-model="state.portalMetaDescription"
            size="lg"
            placeholder="Add your description here..."
            :max-rows="8"
            :rows="8"
          />
          <span class="text-gray-500 text-13">Recommended length: 155 - 160 characters</span>
        </FormField>
        <FormField
          label="Cover Image"
        >
          <LogoUpload
            v-model="state.portalMetaCoverImage"
            :height="126"
            :width="240"
            :display-height="630"
            :display-width="1200"
            variant="light"
            @uploading="uploadImage"
          >
            <template #buttons="{ selectFiles, removeFile }">
              <Button
                v-if="!state.portalMetaCoverImage"
                variant="dark"
                size="sm"
                @click="selectFiles"
              >
                Upload Preview Image
              </Button>
              <template v-else>
                <Button
                  variant="dark"
                  size="sm"
                  @click="selectFiles"
                >
                  Replace
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  @click="removeFile"
                >
                  Remove
                </Button>
              </template>
            </template>
          </LogoUpload>
        </FormField>
      </div>
      <div class="flex flex-col space-y-16">
        <h3 class="text-gray-400 project-page-h3">
          Preview
        </h3>

        <!-- Preview square -->
        <div
          class="
            relative flex flex-row items-stretch overflow-hidden bg-white border
            border-gray-200 rounded-md pointer-events-none
          "
        >
          <div class="relative flex-shrink-0 bg-gray-100 border-r border-gray-200 w-128 h-128">
            <img
              v-if="state.portalMetaCoverImage"
              :src="state.portalMetaCoverImage"
              alt="Preview cover image"
              class="absolute inset-0 object-cover object-center w-full h-full"
            />
          </div>
          <div class="p-12">
            <h6 class="font-semibold text-blue-600 text-13">
              {{ state.portalMetaTitle || organization.name }}
            </h6>
            <p class="text-gray-500 text-11">
              {{ state.portalMetaDescription || organization.name }}
            </p>
            <span
              class="text-gray-400 underline text-11"
            >{{ portalUrl }}</span>
          </div>
        </div>
        <!-- Preview card -->
        <div
          class="
            overflow-hidden bg-white border border-gray-200 rounded-md pointer-events-none
            w-320
          "
        >
          <div class="w-full bg-gray-100 border-b border-gray-200 aspect-w-2 aspect-h-1">
            <img
              v-if="state.portalMetaCoverImage"
              :src="state.portalMetaCoverImage"
              alt="Preview cover image"
              class="object-cover w-full"
            />
          </div>
          <div class="p-12">
            <h6 class="font-semibold text-blue-600 text-13">
              {{ state.portalMetaTitle || organization.name }}
            </h6>
            <p class="text-gray-500 text-11">
              {{ state.portalMetaDescription || organization.name }}
            </p>
            <span
              class="text-gray-400 underline text-11"
            >{{ portalUrl }}</span>
          </div>
        </div>
      </div>
    </div>
    <PageFooter>
      <Button
        :disabled="isBusy"
        size="lg"
        variant="primary"
        type="submit"
        @click="submit"
      >
        Save Changes
      </Button>
      <template v-if="hasChanges" #right>
        <PageFooterMessage />
      </template>
    </PageFooter>
  </Form>
</FeatureEnabled>
