
<WithSidebar>
  <template #sidebar-header>
    <h2 class="font-semibold text-16">Library</h2>
  </template>
  <template #sidebar>
    <OrganizationLibraryNav />
  </template>
  <template #default>
    <router-view :organization="organization" />
  </template>
</WithSidebar>
