
<div class="space-y-16 bg-gray-100 p-16 border-b">
  <!-- buttons -->
  <div class="flex justify-between">
    <Button
      variant="neutral"
      size="sm"
      @click="goToAllProjects"
    >
      <span class="mr-4 -ml-4 icon_v2-so_list-view text-blue-600"></span>
      <span>{{ $t('common.projects') }}</span>
    </Button>
    <CheckMemberRole v-slot="{ hasRole }" :roles="['admin', 'projectManager']">
      <Button
        v-if="hasRole"
        variant="neutral"
        size="sm"
        @click="newProject"
      >
        <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600"></span>
        <span>New Project</span>
      </Button>
    </CheckMemberRole>
  </div>

  <!-- search box -->
  <div class="flex items-center">
    <div class="relative flex-1">
      <b-input
        :value="keyword"
        trim
        type="text"
        placeholder="Search..."
        class="rounded-sm pr-36 text-14"
        size="xs"
        autofocus
        @input="val => $emit('update:keyword', val)"
      />
      <span class="icon_v2-so_search text-gray-300 absolute right-10 top-6 text-20" />
    </div>
    <ButtonFilter
      v-if="tags.length"
      :count="selectedTagCount"
      :active="showTags"
      class="p-6"
      @click="showTags = !showTags"
    />
  </div>

  <!-- tags -->
  <ProjectSwitcherTags
    v-if="showTags"
    :tags="tags"
    :value="selectedTags"
    @input="val => $emit('update:selected-tags', val)"
  />
</div>
