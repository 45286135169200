<template>
  <div class="group-placeholder" @contextmenu.stop.prevent="onContextMenuOpen">
    <span class="block-placeholder">

    </span>
    <span class="block-placeholder">
    </span>
    <span class="block-placeholder">

    </span>
    <ContextMenu
      v-if="contextMenu.nodeId === placeholderNodeId"
      label="New Column Group"
      :left="contextMenu.left"
      :top="contextMenu.top"
      @close="onContextMenuClose"
    >
      <b-dropdown-item
        :disabled="isClipboardEmpty"
        @click="pasteNodes({targetBranch:nodeId })"
      >
        <span class="cm-icon icon-util-paste-before inline-block mr-8"></span>
        <span>Paste</span>
      </b-dropdown-item>
    </ContextMenu>
  </div>
</template>

<script>
import ContextMenu from '@/components/ContextMenu.vue'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { usePasteNodesFromClipboard } from '@/v2/services/documentNodes/documentNodesCompositions'

const { useGetters, useState, useMutations, useActions } = createNamespacedHelpers('documentEditor')

export default {
  name: 'DocumentNodeGroupGroupPlaceholder',
  components: { ContextMenu },
  props: {
    nodeId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const placeholderNodeId = 'NodePlaceholderGroupBasic'
    const { contextMenu } = useState(['contextMenu', 'clipboard'])
    const { isClipboardEmpty } = useGetters(['isClipboardEmpty'])

    const { setContextMenuData } = useMutations(['setContextMenuData'])
    const { clearContextMenuData } = useActions(['clearContextMenuData'])
    const pasteNodes = usePasteNodesFromClipboard()

    const onContextMenuOpen = event => setContextMenuData({
      nodeId: placeholderNodeId,
      left: event.clientX,
      top: event.clientY,
    })
    const onContextMenuClose = clearContextMenuData
    return {
      // State
      placeholderNodeId,
      contextMenu,
      isClipboardEmpty,
      // Methods
      onContextMenuOpen,
      onContextMenuClose,
      pasteNodes,
    }
  },
}
</script>

<style lang="postcss" scoped>
.group-placeholder {
  @apply flex flex-col text-gray-100 mx-auto space-y-12 px-12;
}

.group-placeholder .block-placeholder {
  @apply  rounded-lg bg-gray-400 opacity-5 overflow-hidden h-64 border-2 border-black;
}

</style>
