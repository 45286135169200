
<div>
  <div :class="$style['list']">
    <div v-if="fields" :class="$style['row-header']">
      <ListTableCellHeader
        v-for="(field, index) in fields"
        :key="index"
        :field="field"
      />
    </div>
    <div
      v-for="(item, index) in items"
      :key="item[keyField] || index"
      :class="$style['row']"
    >
      <slot name="default" v-bind="{ item, index }" />
    </div>
  </div>
  <div v-if="hasCreateListener" :class="$style['row-create']">
    <Button
      variant="primary"
      @click="$emit('create')"
    >
      <span class="w-24 icon-add-rounded-s text-24 mr-8 leading-none"></span>
      Add
    </Button>
  </div>
</div>
