// eslint-disable-next-line import/no-unresolved
import 'virtual:svg-icons-register';


import * as R from 'ramda'
import 'scroll-behavior-polyfill'
import './assets/scss/bootstrap.scss'
import './assets/css/tailwind.css'
import './assets/css/font-icons.css'
import './assets/css/font-icons-v2.css'
import './assets/css/font-logos.css'
import './assets/css/font-brands.css'
import './assets/css/font-standard.css'
import './assets/css/font-material.css'

// //3rd party components
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import retina from 'retinajs'
import VueRetina from 'vue-retina'
import VueMeta from 'vue-meta'
import BootstrapVue from 'bootstrap-vue'
import PortalVue from 'portal-vue'
import VueStaticData from 'vue-static-data'
import ComponentID from 'vue-component-id'
import VueScrollTo from 'vue-scrollto'
import VueFragment from 'vue-frag';
import VueGtag from 'vue-gtag';
import VueMq from 'vue-mq'
import VueMasonry from 'vue-masonry-css'
import VueObserveVisibility from 'vue-observe-visibility'
import VueHorizontal from 'vue-horizontal';
// import Vue2DatePicker from 'vue2-datepicker';

import useBrowserTitle from '@/v2/lib/composition/useBrowserTitle'
import i18n from './i18n'


// import { GUEST_PRINT_DOCUMENT } from '@/router/guest/type'

import './directives'
import './filters'
import './svg-icons'

import store from '@/v2/store'

import TrackingPlugin from '@/v2/services/tracking/plugin'
import InjectPlugin from './lib/plugins/inject'
import App from './App.vue'

import servicesFactory from './services'
import routerFactory from './router'

import config from './config'
import container from './container'

import intercomService from '@/v2/services/intercomService'

useBrowserTitle.debug = import.meta.env.DEV

Object.defineProperty(window, 'APP_VERSION', {
  enumerable: false,
  configurable: false,
  writable: false,
  value: import.meta.env.VITE_APP_VERSION ?? null,
})

const eventBus = new Vue()
container.set('config', config)
container.set('eventBus', eventBus) // use a separate Vue instance as an event bus
container.set('store', store)

servicesFactory(container)
// const store = storeFactory(container)
const router = routerFactory(container, {
  scrollTop: true,
  // scrollElementSelector: '#appScrollContainer',
});

intercomService.init(() => window.Intercom, {
  router,
  store,
  config: config.intercom,
  debug: config.debug,
})

Vue.config.productionTip = false
Vue.use(VueCompositionAPI)
Vue.use(VueRetina, { retina })
Vue.use(VueMeta)
Vue.use(BootstrapVue)
Vue.use(VueMasonry)
Vue.use(PortalVue)
Vue.use(VueStaticData)
Vue.use(ComponentID)
Vue.use(VueScrollTo)
Vue.use(VueHorizontal)
Vue.use(VueObserveVisibility)

Vue.use(TrackingPlugin, {
  router,
  store,
  config: config.tracking,
})


Vue.use(VueGtag, {
  config: {
    enabled: config.gtm.enable,
    id: config.gtm.id,
  },
}, router);

Vue.directive('fragment', VueFragment);

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: Infinity,
  },
  defaultBreakpoint: 'sm', // customize this for SSR
})

Object.defineProperty(Vue.prototype, '$isMobile', {
  get() {
    return ['sm', 'md'].includes(this.$mq)
  },
});

// Vue.use(VueGtm, {
//   id: config.googleTagManager.code, // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
//   enabled: config.googleTagManager.enable, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//   debug: config.logger.enable, // Whether or not display console logs debugs (optional)
//   loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//   vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//   ignoredViews: [GUEST_PRINT_DOCUMENT]
// })

Vue.use(InjectPlugin(), { name: '$container', dependency: container })
Vue.use(InjectPlugin(), { name: '$eventBus', dependency: eventBus })
Vue.use(InjectPlugin(), { name: '$mac', dependency: window.navigator.userAgent.includes('Mac') })

Vue.directive('fragment', VueFragment);

const makeToasterOptions = R.pipe(
  R.mergeDeepRight({
    solid: true,
    // toaster: 'b-toaster-top-full',
    toaster: 'b-toaster-top-right',
    autoHideDelay: 3000,
  })
  // R.dissoc('title')
)

eventBus.$emit('init')

const vm = new Vue({
  router,
  store,
  created() {
    this.$root.$on(
      'notification',
      ({ title, message, variant = 'success' }) => {
        this.toast(message, {
          title,
          variant,
        })
      }
    );
  },
  methods: {
    toast(message, options = {}) {
      // const h = this.$createElement
      // const vNodeContent = options.title
      //     ? [
      //         h(
      //             'span',
      //             { class: 'font-weight-bold' },
      //             `${options.title}: `
      //         ),
      //         message,
      //     ]
      //     : message

      // const vNodesMsg = h(
      //     'div',
      //     { class: 'text-center' },
      //     vNodeContent
      // )
      // this.$bvToast.toast(vNodesMsg, makeToasterOptions(options))
      this.$bvToast.toast(message, makeToasterOptions(options))
    },
  },
  render: h => h(App),
  i18n: i18n({
    // locales: [import.meta.env.VITE_I18N_LOCALE],
    locales: config.locales,
  }),
}).$mount('#app')

container.set('$bvModal', vm.$bvModal)
