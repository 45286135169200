
<CheckMemberRole
  v-slot="{ hasRole: isProjectAdmin }"
  :roles="['admin', 'projectManager']"
>
  <PageHeader
    search
    :search-keyword.sync="keyword"
    search-placeholder="Search by title..."
    :show-search-result.sync="showSearchResult"
  >
    <div class="flex items-center gap-x-28 text-14 text-gray-600">
      <div v-if="teamMembers.length > 0" class="flex items-center gap-x-8">
        <span>Your Team</span>
        <ProjectDashboardPageHeaderMembers :items="teamMembers" />
      </div>
      <div v-if="clientContacts.length > 0" class="flex items-center gap-x-8">
        <span>Client's Team</span>
        <ProjectDashboardPageHeaderMembers :items="clientContacts" />
      </div>
      <Button
        v-if="isProjectAdmin"
        variant="transparent"
        :to="{ name: PROJECT_SETTINGS_MANAGE_ACCESS }"
        class="text-gray-700 border border-gray-400"
      >
        Manage Access
      </Button>
    </div>
    <template #search-result>
      <ProjectDashboardPageHeaderSearch :keyword="keyword" />
    </template>
  </PageHeader>
</CheckMemberRole>
