<template>
  <div class="flex w-full">
    <div class="flex flex-col mx-auto lg:pb-48 max-w-[554px] md:py-16 px-16 md:px-32">
      <div class="mb-36 text-center">
        <h1 class="font-semibold text-gray-900 text-18 md:text-24">
          Let's continue with your details
        </h1>
      </div>
      <Form
        v-if="model"
        v-slot="{ state }"
        :state="model"
        :validation="{subdomain: { minLength: minLength(3) }}"
        :on-submit="()=>saveOrgBranding()"
      >
        <div class="flex flex-col">
          <!-- Logo -->
          <section class="mb-36">
            <h3 class="pb-8 font-semibold text-gray-800 text-18">
              Logo
            </h3>
            <p class="text-14 mb-16 text-gray-500">
              A .png with transparency or .svg will look best.
            </p>

            <div class="grid-cols-2 gap-64 tablet:grid">
              <div class="mb-24 tablet:mb-0">
                <p class="text-gray-700 text-14">Primary</p>
                <p class="mb-8 text-gray-400 text-12">Used on light backgrounds</p>
                <LogoUpload
                  v-model="state.logoUrl"
                  :height="90"
                  :width="160"
                  :display-height="100"
                  :display-width="320"
                  class-name="p-8"
                  @uploading="uploadLogo"
                >
                  <template #buttons="{ selectFiles, removeFile }">
                    <Button
                      v-if="!state.logoUrl"
                      variant="neutral"
                      size="xs"
                      @click="selectFiles"
                    >
                      Upload
                    </Button>
                    <template v-else>
                      <Button
                        variant="neutral"
                        size="xs"
                        @click="selectFiles"
                      >
                        Replace
                      </Button>
                      <Button
                        variant="danger"
                        size="xs"
                        @click="removeFile"
                      >
                        Remove
                      </Button>
                    </template>
                  </template>
                </LogoUpload>
              </div>
              <div>
                <p class="text-gray-700 text-14">Reversed</p>
                <p class="mb-8 text-gray-400 text-12">Used on dark backgrounds</p>
                <LogoUpload
                  v-model="state.logoDarkUrl"
                  :height="90"
                  :width="160"
                  :display-height="100"
                  :display-width="320"
                  variant="dark"
                  class-name="p-8"
                  @uploading="uploadLogo"
                >
                  <template #buttons="{ selectFiles, removeFile }">
                    <Button
                      v-if="!state.logoDarkUrl"
                      variant="neutral"
                      size="xs"
                      @click="selectFiles"
                    >
                      Upload
                    </Button>
                    <template v-else>
                      <Button
                        variant="neutral"
                        size="xs"
                        @click="selectFiles"
                      >
                        Replace
                      </Button>
                      <Button
                        variant="danger"
                        size="xs"
                        @click="removeFile"
                      >
                        Remove
                      </Button>
                    </template>
                  </template>
                </LogoUpload>
              </div>
            </div>
          </section>

          <!-- Brand Colors -->
          <section class="mb-40">
            <h3 class="pb-8 font-semibold text-gray-800 text-18">
              Brand Colors
            </h3>
            <p class="text-14 mb-16 text-gray-500">
              This color palette will be available in all your documents and projects.
            </p>
            <div
              v-if="!isFetchColorsLoading"
              class="flex flex-col flex-wrap"
            >
              <ColorPalette
                v-model="state.brandingColors"
                :size="56"
              />
              <p class="mt-8 text-gray-500 text-11">
                Chose your colour palette
              </p>
            </div>
            <Spinner v-else />
          </section>

          <!-- Next Button -->
          <section class="mb-40 lg:mb-24">
            <Button
              type="submit"
              :is-loading="isLoading"
              size="lg"
              variant="primary"
              class="w-full mr-8 shadow max-w-[256px]"
            >
              Continue
            </Button>
          </section>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import { equals } from 'ramda'
import { nanoid } from 'nanoid'
import { rejectNil } from 'ramda-extension'
import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import { minLength } from '@vuelidate/validators'
import { ONBOARDING_PROJECT_TEMPLATES } from '@/router/welcome/type'
import { ONBOARDING_STATUS } from '@/v2/services/organizations/organizationsTypes'
import { getUrlInfo } from '@/v2/services/urlInfo/urlInfoCompositions'
import { rgbToHex } from '@/helpers/branding-helper'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useTracking } from '@/v2/services/tracking/compositions'
import Spinner from '@/components/Spinner.vue'
import Button from '@/components/Button.vue'
import LogoUpload from '@/components/Blocks/Image/LogoUpload.vue'
import Form from '@/components/Form.vue'
import ColorPalette from '@/components/ColorPalette.vue'
// import ProjectBrandingPreview from '@/components/ProjectBrandingPreview.vue'

const isEnabled = equals('1')

export default defineComponent({
  name: 'ViewSignUpBranding',
  components: {
    LogoUpload,
    Button,
    Form,
    ColorPalette,
    // ProjectBrandingPreview,
    Spinner,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { router } = useRouter()
    const organization = useCurrentOrganization();
    const { track } = useTracking()

    const isColorPickEnabled = isEnabled(import.meta.env.VITE_COLOR_PICK)

    const model = organization.value.clone()

    const isFetchColorsLoading = ref(false)
    const fetchColorsError = ref(null)
    const isLoading = ref(false)

    const portalTheme = ref('light');
    const portalThemeChange = theme => {
      portalTheme.value = theme
    }

    const projectBranding = computed(() => rejectNil({
      portalAccentColor: model.brandingColors?.[0]?.color,
      portalHeaderBackgroundColor: model.brandingColors?.[1]?.color,
      portalTheme: portalTheme.value,
    }));

    const isUploading = ref(false)
    const uploadLogo = status => { isUploading.value = status }

    const orgFields = computed(
      () => (organization.value.subdomain
        ? ['logoUrl', 'logoDarkUrl', 'brandingColors']
        : ['logoUrl', 'logoDarkUrl', 'brandingColors', 'subdomain'])
    )

    const saveOrgBranding = async () => {
      if (isLoading.value) {
        return
      }

      isLoading.value = true

      await model.patch({
        data: rejectNil({
          logoUrl: model.logoUrl,
          logoDarkUrl: model.logoDarkUrl,
          brandingColors: model.brandingColors,
          subdomain: organization.value.subdomain ? null : model.subdomain,
          onboardingStatus: ONBOARDING_STATUS.projectTemplate,
        }),
      })

      track('Onboarding Branding Completed')

      router.push({
        name: ONBOARDING_PROJECT_TEMPLATES,
        params: {
          organizationId: props.organizationId,
        },
      })
    }

    const fetchLogoColors = async logoUrl => {
      isFetchColorsLoading.value = true
      try {
        const data = await getUrlInfo(logoUrl, ['colorPalette'])
        model.brandingColors = [
          {
            color: rgbToHex(
              data.colorPalette[0][0],
              data.colorPalette[0][1],
              data.colorPalette[0][2]
            ),
            id: nanoid(),
          },
          {
            color: rgbToHex(
              data.colorPalette[1][0],
              data.colorPalette[1][1],
              data.colorPalette[1][2]
            ),
            id: nanoid(),
          },
          {
            color: rgbToHex(
              data.colorPalette[2][0],
              data.colorPalette[2][1],
              data.colorPalette[2][2]
            ),
            id: nanoid(),
          },
          {
            color: rgbToHex(
              data.colorPalette[3][0],
              data.colorPalette[3][1],
              data.colorPalette[3][2]
            ),
            id: nanoid(),
          },
          {
            color: rgbToHex(
              data.colorPalette[4][0],
              data.colorPalette[4][1],
              data.colorPalette[4][2]
            ),
            id: nanoid(),
          },
        ]
      } catch (err) {
        fetchColorsError.value = err
      } finally {
        isFetchColorsLoading.value = false
      }
    }

    // Steal colors from logo
    // This is not ideal, we need to optimise the backend service

    watch(
      () => model.logoUrl,
      newLogo => isColorPickEnabled && fetchLogoColors(newLogo),
      { immediate: false }
    )

    return {
      portalTheme,
      organization,
      model,
      isUploading,
      uploadLogo,
      saveOrgBranding,
      minLength,
      orgFields,
      portalThemeChange,
      isFetchColorsLoading,
      isLoading,
      isColorPickEnabled,
      projectBranding,
    }
  },
})
</script>
<style lang="scss" module>
.company-logo-area {
  @apply mb-24 mt-8;
}
.company-logo {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  :global(.pu) {
    margin-bottom: 0;
  }
  :global(.logo-area) {
    margin: 0;
    background-color: $gray-000;
    border: 1px solid $gray-300;
    padding: 4px;
    text-align: center;
    min-width: 142px;
    margin-right: 24px;
  }
  :global(.logo-placeholder) {
    color: $gray-500;
    font-weight: $medium;
    line-height: 42px;
  }
}
.company-name {
  :global(.form-control) {
    max-width: 403px;
  }
}
.popover {
  &[x-placement^='top'] {
    :global(.arrow) {
      &::after {
        border-top-color: theme('colors.gray.DEFAULT');
      }
    }
  }

  &[x-placement^='bottom'] {
    :global(.arrow) {
      &::after {
        border-bottom-color: theme('colors.gray.DEFAULT');
      }
    }
  }

  :global(.popover-body) {
    color: theme('colors.gray.700');
    padding: 0;
  }
}

</style>
<style>
.bg-browser-address {
  background-image: url('/assets/img/browser-address.png');
  background-position: 0 center;
}
</style>
