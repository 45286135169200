import Vue from 'vue'
import { useNamespacedMutations } from 'vuex-composition-helpers'
import { ref, onUnmounted } from '@vue/composition-api'
import { notifications as notificationsService } from '@/v2/services'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @param {object} params
 * @param {Ref<object>} params.query
 * @param {number} [params.pageSize]
 * @param {string} [params.queryId]
 */
export default function useInboxActions() {
  const { Notification } = Vue.$FeathersVuex.api

  // -- mark individual notification as read
  const markAsRead = async notificationId => {
    const modelInstance = Notification.getFromStore(notificationId);
    if (!modelInstance) {
      throw new Error('notification not in store');
    }

    await modelInstance.patch({
      data: { read: true },
    })
  }

  // -- mark all as read
  const { updateItems: updateNotificationsLocal } = useNamespacedMutations(
    'notifications-webapp',
    ['updateItems']
  )

  const isPendingMarkAllAsRead = ref(false)
  const markAllAsRead = async query => {
    isPendingMarkAllAsRead.value = true
    try {
      const params = {
        query: { ...query, read: false },
        markAllAsRead: true,
      }

      const updatedNotifications = await notificationsService.patch(
        null,
        { read: true },
        params
      )

      updateNotificationsLocal(updatedNotifications)
    } finally {
      setTimeout(() => {
        isPendingMarkAllAsRead.value = false
      }, 1000);
    }
  }

  const onMarkAllAsRead = () => {
    if (!isPendingMarkAllAsRead.value) {
      const { data: items } = Notification.findInStore({ query: { read: false } });
      const updatedItems = items.map(item => ({ ...item.toPlainObject(), read: true }));
      updateNotificationsLocal(updatedItems);
    }
  }

  notificationsService.on('markAllAsRead', onMarkAllAsRead);

  onUnmounted(() => {
    notificationsService.off('markAllAsRead', onMarkAllAsRead);
  })

  return {
    markAsRead,
    markAllAsRead,
    isPendingMarkAllAsRead,
  }
}
