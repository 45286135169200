<template>
  <WithSidebar>
    <template #sidebar-header>
      <div class="flex items-center justify-between w-full">
        <h2 class="font-semibold text-16">Clients</h2>
        <router-link
          v-slot="{ navigate }"
          :to="{
            name: ORGANIZATION_CLIENT_DETAILS,
            params: { clientId: 'new'}
          }"
          class="block w-116"
          custom
        >
          <FeatureLimit
            v-slot="{ checkFeatureLimit }"
            flag="limitClients"
            label="clients"
            @allow="navigate"
          >
            <Button
              v-track="['New Client Organization Modal Opened']"
              variant="neutral"
              size="xs"
              block
              @click="checkFeatureLimit"
            >
              <span class="inline-block mr-6 icon_v2-so_plus text-green-600" />
              Add Client
            </Button>
          </FeatureLimit>
        </router-link>
      </div>
    </template>
    <template #sidebar>
      <OrganizationClientsNav
        :is-pending="!haveLoaded"
        :clients="clients"
      />
    </template>
    <template #default>
      <router-view :organization="organization" />
    </template>
  </WithSidebar>
</template>

<script>
import Vue from 'vue'
import { defineComponent, computed } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import { ORGANIZATION_CLIENT_DETAILS } from '@/router/organization/type'
import WithSidebar from '@/components/WithSidebar.vue'
import OrganizationClientsNav from '@/components/OrganizationClientsNav.vue'
import Button from '@/components/Button.vue'
import FeatureLimit from '@/components/FeatureLimit.vue'

export default defineComponent({
  name: 'ViewOrganizationClients',
  components: {
    WithSidebar,
    OrganizationClientsNav,
    Button,
    FeatureLimit,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { Client } = Vue.$FeathersVuex.api

    const params = computed(() => ({
      query: {
        organization: props.organization._id,
        $sort: {
          name: 1,
        },
      },
    }))

    const { items: clients, haveLoaded } = useFind({
      model: Client,
      params,
    })

    return {
      clients,
      haveLoaded,
      ORGANIZATION_CLIENT_DETAILS,
    }
  },
})
</script>

<style lang="scss" scoped></style>
