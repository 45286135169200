import ViewProjectContent from '@/views/ViewProjectContent.vue'
import ViewProjectDashboard from '@/views/ViewProjectDashboard.vue'
import ViewProjectEmbeddedApp from '@/views/ViewProjectEmbeddedApp.vue'
import ViewProjectNotifications from '@/views/ViewProjectNotifications.vue'
import ViewProjectUpdates from '@/views/ViewProjectUpdates.vue'
import ViewPage from '@/views/ViewPage.vue'

// FileSystem
import ViewFS from '@/views/ViewFS.vue'
import ViewFSFolder from '@/views/ViewFSFolder.vue'
import ViewFSDocument from '@/views/ViewFSDocument.vue'

import tasks from './content-tasks'

import {
  PROJECT_CONTENT,
  PROJECT_DASHBOARD,
  PROJECT_LINKED_APP,
  PROJECT_NOTIFICATIONS,
  PROJECT_UPDATES,
  PROJECT_VIEW_AS_CLIENT,
  PROJECT_PAGE,
  PROJECT_SERVICE,

  // FS
  PROJECT_FS_FOLDER,
  PROJECT_FS_DOCUMENT,

  // Pinned Folders
  PROJECT_PINNED_FS_FOLDER,
  PROJECT_PINNED_FS_DOCUMENT,

} from './type'

export default [{
  name: PROJECT_CONTENT,
  path: 'content',
  component: ViewProjectContent,
  props: true,
  redirect: {
    name: PROJECT_DASHBOARD,
  },
  children: [
    {
      name: PROJECT_DASHBOARD,
      path: 'dashboard',
      component: ViewProjectDashboard,
    },
    {
      name: PROJECT_LINKED_APP,
      path: 'app/:linkedAppId',
      component: ViewProjectEmbeddedApp,
      props: true,
    },
    {
      name: PROJECT_NOTIFICATIONS,
      path: 'notifications',
      component: ViewProjectNotifications,
      props: true,
    },
    {
      name: PROJECT_UPDATES,
      path: 'updates',
      component: ViewProjectUpdates,
      props: true,
    },

    // FileSystem
    {
      path: 'fs/folders/:folderId',
      component: ViewFS,
      props: true,
      children: [
        {
          name: PROJECT_FS_FOLDER,
          path: '',
          component: ViewFSFolder,
          props: true,
        },
        {
          name: PROJECT_FS_DOCUMENT,
          path: 'docs/:documentId',
          component: ViewFSDocument,
          props: true,
        },
      ],
    },

    // Pinned folders
    {
      path: 'fs/pinned/:folderId',
      component: ViewFS,
      props: route => ({ ...route.params, isPinnedFolder: true }),
      children: [
        {
          name: PROJECT_PINNED_FS_FOLDER,
          path: '',
          component: ViewFSFolder,
          props: true,
        },
        {
          name: PROJECT_PINNED_FS_DOCUMENT,
          path: 'docs/:documentId',
          component: ViewFSDocument,
          props: true,
        },
      ],
    },

    // pages
    {
      path: 'pages/:documentId',
      name: PROJECT_PAGE,
      component: ViewPage,
      props: true,
    },

    // packaged services
    {
      path: 'service/:documentId',
      name: PROJECT_SERVICE,
      component: ViewPage,
      props: true,
    },

    ...tasks,
  ],
}]
