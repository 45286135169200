import { always } from 'ramda'
import { noop } from 'ramda-adjunct'
import Vue from 'vue'
import { computed, watch, ref } from '@vue/composition-api'
import useFormFeathers from './useFormFeathers'

export default function useFormFeathersEditor({
  modelName,
  id,
  newProps = always({}),

  // useFormFeathers args
  fields,
  validation,
  onSave = noop,
  successMessage = null,
  autoCommit = false,
} = {}) {
  const model = ref(null)
  const Model = Vue.$FeathersVuex.api[modelName]
  const isNew = computed(() => id.value === 'new')

  watch(id, async val => {
    if (val === 'new') {
      model.value = new Model(newProps())
      return
    }

    const item = Model.getFromStore(val)
    if (item) {
      model.value = item.clone()
    } else {
      const loadedItem = await Model.get(val)
      model.value = loadedItem.clone()
    }
  }, { immediate: true })

  const formFeathers = useFormFeathers({
    model,
    fields,
    validation,
    onSave,
    successMessage,
    autoCommit,
  })

  return {
    isNew,
    model,
    ...formFeathers,
  }
}

