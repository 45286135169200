import { ROLES } from '@/v2/services/members/membersTypes'
import ViewOrganizationSubscription from '@/views/ViewOrganizationSubscription.vue'
import ViewOrganizationSubscriptionPlans from '@/views/ViewOrganizationSubscriptionPlans.vue'
import ViewOrganizationSubscriptionCheckout from '@/views/ViewOrganizationSubscriptionCheckout.vue'

import {
  ORGANIZATION_SUBSCRIPTION,
  ORGANIZATION_SUBSCRIPTION_PLANS,
  ORGANIZATION_SUBSCRIPTION_CHECKOUT,
  ORGANIZATION_SUBSCRIPTION_SUCCESS,
  ORGANIZATION_SETTINGS_SUBSCRIPTION,
} from './type'

// CLIENTS
export default [{
  name: ORGANIZATION_SUBSCRIPTION,
  path: 'subscription',
  component: ViewOrganizationSubscription,
  props: true,
  meta: {
    requiresRoles: [ROLES.admin],
  },
  redirect: {
    name: ORGANIZATION_SUBSCRIPTION_PLANS,
  },
  children: [
    {
      name: ORGANIZATION_SUBSCRIPTION_PLANS,
      path: 'plans/:provider',
      component: ViewOrganizationSubscriptionPlans,
      props: true,
    },
    {
      name: ORGANIZATION_SUBSCRIPTION_CHECKOUT,
      path: 'checkout/:provider/:plan/:periodUnit',
      component: ViewOrganizationSubscriptionCheckout,
      props: true,
    },
    {
      name: ORGANIZATION_SUBSCRIPTION_SUCCESS,
      path: 'success',
      // redirect without query params using middleware
      beforeEnter: (to, from, next) => {
        next({ ...to, name: ORGANIZATION_SETTINGS_SUBSCRIPTION, query: {} })
      },
    },
  ],
}]
