<template>
  <div
    class="flex flex-col items-center text-center relative hover:text-green-600"
    :class="{'text-green-600': active }"
  >
    <i
      :class="active ? iconActive : icon"
      class="text-28"
    />
    <span class="text-10 leading-3 font-semibold">{{ label }}</span>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MainNavItem',
  components: {
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    iconActive: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
  },
})
</script>
