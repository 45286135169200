import Vue from 'vue';
import { indexBy, isEmpty, mergeDeepRight, uniq } from 'ramda'
import { computed, unref } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import { isNonEmptyArray } from 'ramda-adjunct';

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @typedef {Object} MemberItem
 * @property {any} member
 * @property {any} user
 */


/**
 * @typedef {Object} Options
 * @property {Ref<Record<string, any>} params
 * @property {(item: MemberItem) => boolean} [filterFn]
 * @property {boolean} [joinUser]
 * @property {boolean} [joinProjects]
 */


/**
 * @param {Options} options
 * @returns {Ref<MemberItem[]>}
 */
function useFindActionItems(options = {}) {
  const organization = useCurrentOrganization()

  const {
    params = {},
    joinProject = true,
    limit = 200,
  } = options


  const { ActionItem, Project } = Vue.$FeathersVuex.api

  // -- all action items
  const paramsActionItems = computed(() => mergeDeepRight(
    {
      query: {
        organization: organization.value._id,
        $limit: limit,
        $sort: { createdAt: -1 },
      },
    },
    unref(params)
  ))

  const { items: actionItems, isPending: actionItemsPending } = useFind({
    model: ActionItem,
    params: paramsActionItems,
  })

  // -- find projects
  const projectIds = computed(
    () => uniq(actionItems.value.map(actionItem => actionItem.project))
  );

  const paramsProjects = computed(() => ((joinProject && isNonEmptyArray(projectIds.value)) ? {
    query: {
      _id: { $in: projectIds.value },
    },
  } : null))

  const { items: projects, isPending: projectsPending } = useFind({
    model: Project,
    params: paramsProjects,
    local: true,
  })

  const indexedProjects = computed(() => indexBy(p => p._id, projects.value));

  // -- join everything
  const items = computed(() => actionItems.value.map(actionItem => {
    const { client$ = null, ...project } = indexedProjects.value[actionItem.project] ?? {};

    return {
      actionItem,
      user: actionItem.assignedTo$ ?? null,
      project: isEmpty(project) ? null : project,
      client: client$,
    }
  }));

  const isPending = computed(() => [
    actionItemsPending.value,
    projectsPending.value,
  ].some(Boolean))

  return {
    items,
    isPending,
  };
}

export default useFindActionItems
