import { pipe, map, flatten, filter, uniq, when } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'
import { computed } from '@vue/composition-api'
import useDocumentNodes from './useDocumentNodes'

const hasNoTransparency = color => color.length === 7
const addTransparency = color => `${color}FF`

const uniqColors = pipe(
  map(n => [n?.styling.textColor, n?.styling.backgroundColor]),
  flatten,
  filter(isNotNilOrEmpty),
  map(when(hasNoTransparency, addTransparency)),
  uniq
)

export default function useDocumentColors(documentId) {
  const nodes = useDocumentNodes(documentId)
  const currentColors = computed(() => uniqColors(nodes.value))

  return { currentColors }
}
