<template>
  <div class="h-full relative">
    <PageHeader
      show-back
      title="Manage Subscription"
      @back="$router.push({ name: ORGANIZATION_SETTINGS_SUBSCRIPTION })"
    />
    <SpinnerOverlay v-if="isBusy" absolute />
    <Error
      v-else-if="error"
      variant="danger"
      :error="error"
    />
    <div v-if="portalUrl" class="h-full">
      <iframe
        frameborder="0"
        :src="portalUrl"
        class="h-full w-full"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useChargebee } from '@/v2/services/chargebee/compositions'
import useAsyncTask from '@/v2/lib/composition/useAsyncTask'
import { ORGANIZATION_SETTINGS_SUBSCRIPTION } from '@/router/organization/type'
import PageHeader from '@/components/PageHeader.vue'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'

export default defineComponent({
  name: 'ViewOrganizationSettingsSubscriptionManage',
  components: {
    PageHeader,
    SpinnerOverlay,
  },
  props: {
  },
  setup() {
    const chargebee = useChargebee()
    const portalUrl = ref(null)

    const { exec: load, isBusy, error } = useAsyncTask(async () => {
      const { result } = await chargebee.createPortalSession()
      portalUrl.value = result.accessUrl
    })

    load()

    return {
      portalUrl,
      isBusy,
      error,
      ORGANIZATION_SETTINGS_SUBSCRIPTION,
    }
  },
})
</script>
