
<ModalBasic
  :title="title"
  :hide-footer="true"
  dialog-class="modal-dialog-tertiary"
  content-class="modal-content-tertiary"
  body-class="modal-body-tertiary"
  header-class="modal-header-tertiary"
  footer-class="modal-footer-tertiary"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #body="{ close }">
    <div class="fade-to-white fade-from-bottom">
      <div class="flex flex-col space-y-6 px-16 pb-88 overflow-y-auto max-h-vh-2/3 min-h-384">
        <ListMultiSelectDocuments
          :value="selection"
          :documents="documents"
          :hide-cost="hideCost"
          @input="updateSelection"
        />
      </div>
    </div>
    <Buttons>
      <Button
        type="button"
        variant="neutral"
        size="md"
        @click="close"
      >
        Cancel
      </Button>
      <b-button
        type="submit"
        variant="primary"
        size="md"
        @click="done"
      >
        Save
      </b-button>
    </Buttons>
  </template>
</ModalBasic>
