<template>
  <div
    ref="target"
    class="project-switcher"
    @keydown.esc.prevent="hidePopover"
  >
    <ProjectSwitcherHeader
      :tags="tags"
      :keyword.sync="keyword"
      :selected-tags.sync="selectedTags"
      :selected-tag-count="selectedTagCount"
      @all-projects="goToAllProjects"
      @new-project="newProject"
    />
    <ul class="project-switcher-list p-8">
      <li
        v-for="project in projects"
        :key="project._id"
        class="
          hover:bg-gray-200 dark:text-white
          dark:hover:bg-gray-300 dark:hover:text-gray-700 p-4
          px-8 mb-4 flex items-center justify-between rounded-sm
        "
        :class="{
          'bg-gray-200 dark:bg-gray-300 relative cursor-default pr-28 dark:text-darkGray-700':
            project._id === currentProject?._id,
          'cursor-pointer': project._id !== currentProject?._id,
        }"
        @click="select(project)"
      >
        <ProjectSwitcherItem
          :project="project"
          :selected="project._id === currentProject?._id"
          :show-client="showClient"
          :dark-theme="darkTheme"
        />
        <span
          v-if="project._id === currentProject?._id"
          class="
            icon_v2-so_tick absolute right-4
            top-6 text-gray-400 pointer-events-none
          "
        />
      </li>
    </ul>
  </div>
</template>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { onClickOutside } from '@vueuse/core'
import { useSearchProjects } from '@/v2/services/projects/compositions'
import ProjectSwitcherItem from '@/components/ProjectSwitcherItem.vue'
import ProjectSwitcherHeader from '@/components/ProjectSwitcherHeader.vue'

export default defineComponent({
  name: 'ProjectSwitcher',
  components: {
    ProjectSwitcherItem,
    ProjectSwitcherHeader,
  },
  props: {
    darkTheme: {
      type: Boolean,
      default: false,
    },
    organization: {
      type: Object,
      required: true,
    },
    currentProject: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    showClient: {
      type: Boolean,
      default: false,
    },
    naviButtonRef: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const keyword = ref('')
    const selectedTags = ref({})

    const query = computed(() => ({ archived: { $ne: true } }));

    const {
      projects,
      selectedTagCount,
      tags,
    } = useSearchProjects({
      keyword,
      selectedTags,
      query,
    })

    const hidePopover = () => {
      context.emit('update:show', false)
    }

    const goToProject = (navigate, e) => {
      navigate(e)
      hidePopover()
    }

    const newProject = () => {
      context.emit('new-project')
      hidePopover()
    }

    const select = project => {
      context.emit('select', project)
      hidePopover()
    }

    const goToAllProjects = () => {
      context.emit('all-projects')
      hidePopover()
    }

    const target = ref(null)
    onClickOutside(target, hidePopover, { ignore: [props.naviButtonRef] })

    return {
      projects,
      hidePopover,
      goToProject,
      newProject,
      select,
      goToAllProjects,
      target,
      keyword,

      // tags
      selectedTags,
      selectedTagCount,
      tags,
    }
  },
})
</script>
<style lang="postcss" scoped>
.project-switcher {
  @apply bg-white/95 border fixed bottom-0 top-0 z-1;
  @apply backdrop-blur-sm w-320;
  @apply grid;
  left: 76px;
  grid-template-rows: auto 1fr;
}

.project-switcher-list {
  @apply overflow-y-auto overflow-x-hidden;
}
</style>
