<template>
  <div class="card-desktop-only" :class="[className]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CardWhiteRounded',
  props: {
    className: {
      type: String,
      default: null,
    },
  },
}
</script>
