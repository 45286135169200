import urlInfoService from './urlInfoService'


/**
 * Obtains information for a publicly available website found at `websiteURL`
 * @param {String} websiteURL URL of website for which info is wanted
 * @param {('favicon'|'colorPalette'|'faviconColorPalette')[]} wantedInfo A list of details which
 * is to be retrieved
 * @returns {Promise<{ favicon?: String, colorPalette?: ([Number, Number, Number])[] }>}
 */
export function getUrlInfo(websiteURL, wantedInfo = []) {
  return urlInfoService.get(websiteURL, { query: { $select: wantedInfo } })
}
