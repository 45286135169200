<template>
  <div>
    <PageHeader title="Document Templates" />
    <div
      class="
      grid gap-24 grid-cols-1 sm:grid-cols-3 tablet:grid-cols-2
      xl:grid-cols-3 auto-cols-min auto-rows-min
    "
    >
      <DocumentTemplatePreview
        v-for="template in templates"
        :key="template.id"
        :template="template"
        :category="template.template_category.name"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watchEffect } from '@vue/composition-api'
import strapi from '@/v2/services/strapiService'
import DocumentTemplatePreview from '@/components/Template/DocumentTemplatePreview.vue'
import PageHeader from '@/components/PageHeader.vue';

export default defineComponent({
  name: 'ViewTemplatesDocuments',
  components: {
    DocumentTemplatePreview,
    PageHeader,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    collections: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const templates = ref([]);

    const loadTemplates = async query => {
      const { data } = await strapi.templates.find(query)
      templates.value = data
    }

    watchEffect(() => {
      loadTemplates({
        'template_collections.slug': props.slug,
      })
    })

    return {
      templates,
    }
  },
})
</script>
