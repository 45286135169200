<template>
  <div
    class="text-center p-32 relative flex flex-col border border-gray-300 rounded-xl shadow-xl"
    :class="{ 'border-green-500': plan.isCurrent }"
  >
    <div
      v-if="plan.discount?.discountPercentage"
      class="pricing-plan-discount"
    >
      <span>{{ plan.discount.discountPercentage }}% off</span>
    </div>
    <div class="flex-grow">
      <h2 class="font-bold text-gray-900 text-18">{{ plan.name }}</h2>
      <div class="mb-32 text-gray-600 text-14">{{ plan.description }}</div>
      <div class="flex flex-row items-end justify-center mb-32">
        <div class="price-text">
          <span v-if="pricePerMonth.price === 0">Free</span>
          <template v-else>
            <CurrencyFormatter
              :value="pricePerMonth.price"
              :currency="pricePerMonth.currency"
              :class="pricePerMonth.discountedPrice && 'line-through text-gray-400'"
            />
            <CurrencyFormatter
              v-if="pricePerMonth.discountedPrice"
              :value="pricePerMonth.discountedPrice"
              :currency="pricePerMonth.currency"
              class="ml-4"
            />
          </template>
        </div>
        <div v-if="periodUnit" class="text-gray-600 price-time">/month</div>
      </div>
      <!-- flag groups -->
      <PaymentPlanCardFeatures
        :feature-flags-groups="plan.featureFlagsGroups"
      />
    </div>
    <Button
      v-if="!plan.isAvailable && !plan.isCurrent"
      variant="neutral"
      size="lg"
      disabled
      block
    >
      Included
    </Button>
    <Button
      v-else-if="plan.isCurrent"
      variant="neutral"
      size="lg"
      disabled
      block
    >
      Your Plan
    </Button>
    <Button
      v-else-if="plan.isAvailable"
      block
      size="lg"
      @click="$emit('select')"
    >
      <span v-if="plan.isDowngrade">Downgrade</span>
      <span v-else-if="plan.isUpgrade">Upgrade</span>
      <span v-else>Select</span>
    </Button>
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import { applySpec, find, head, map, pathOr, pick, pipe, prop, propEq, when } from 'ramda'
import { isNotNil, rejectNil } from 'ramda-extension'
import { ORGANIZATION_SUBSCRIPTION_CHECKOUT } from '@/router/organization/type'
import Button from '@/components/Button.vue'
import CurrencyFormatter from '@/components/CurrencyFormatter.vue'
import PaymentPlanCardFeatures from '@/components/PaymentPlanCardFeatures.vue'

// import Badge from '@/components/Badge.vue'

const limitationsSpec = pipe(
  applySpec({
    members: pathOr(null, ['featureFlags', 'limitMembers']),
    projects: pathOr(null, ['featureFlags', 'limitProjects']),
  }),
  map(when(isNotNil, Number))
)

const getDiscountedPrice = periodUnit => ({ discountedPrice }) => {
  if (!discountedPrice) {
    return null;
  }
  return periodUnit === 'year' ? discountedPrice / 12 : discountedPrice;
}

const findPricePerMonth = periodUnit => pipe(
  prop('prices'),
  find(propEq('periodUnit', periodUnit)),
  applySpec({
    price: ({ price }) => (periodUnit === 'year' ? price / 12 : price),
    currency: prop('currency'),
    discountPercentage: prop('discountPercentage'),
    discountedPrice: getDiscountedPrice(periodUnit),
  }),
  rejectNil
)

const findPrice = pipe(
  prop('prices'),
  head,
  pick(['price', 'currency'])
)

export default defineComponent({
  name: 'PaymentPlanCard',
  components: {
    Button,
    CurrencyFormatter,
    PaymentPlanCardFeatures,
    // Badge,
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    periodUnit: {
      type: String,
      // required: true,
      default: null,
    },
  },
  setup(props) {
    const pricePerMonth = computed(() => (props.periodUnit
      ? findPricePerMonth(props.periodUnit)(props.plan)
      : findPrice(props.plan)
    ))
    const limitations = computed(() => limitationsSpec(props.plan))

    return {
      pricePerMonth,
      limitations,
      ORGANIZATION_SUBSCRIPTION_CHECKOUT,
    }
  },
})
</script>

<style lang="scss" scoped>
.price-text {
  font-size: 28px;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;
}

.price-time {
  font-size: 16px;
  line-height: 21px;
}

.price-feat {
  display: flex;
  margin-bottom: 28px;
  flex-direction: column;
  align-items: center;
}


.pricing-plan-discount {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.pricing-plan-discount span {
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  /* Needed for Safari */
  width: 100px;
  display: block;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
  @apply bg-red-600 bg-gradient-to-r from-red-600 to-red-400;
}
</style>
