import ViewOrganizationLibrary from '@/views/library/ViewOrganizationLibrary.vue'
import ViewOrganizationLibraryPlaceholder from '@/views/library/ViewOrganizationLibraryPlaceholder.vue'
import ViewOrganizationLibraryReusableBlocks from '@/views/library/ViewOrganizationLibraryReusableBlocks.vue'
import ViewOrganizationLibraryTeamBios from '@/views/library/ViewOrganizationLibraryTeamBios.vue'
import ViewOrganizationLibraryCaseStudies from '@/views/library/ViewOrganizationLibraryCaseStudies.vue'
import ViewOrganizationLibraryPackagedServices from '@/views/library/ViewOrganizationLibraryPackagedServices.vue'
import ViewOrganizationLibraryPages from '@/views/library/ViewOrganizationLibraryPages.vue'
import ViewOrganizationLibraryBillableServices from '@/views/library/ViewOrganizationLibraryBillableServices.vue'
// import { ROLES } from '@/v2/services/members/membersTypes'

import {
  ORGANIZATION_LIBRARY,
  ORGANIZATION_LIBRARY_PLACEHOLDER,

  ORGANIZATION_LIBRARY_REUSABLE_BLOCKS,
  ORGANIZATION_LIBRARY_REUSABLE_BLOCK,

  ORGANIZATION_LIBRARY_TEAM_BIOS,
  ORGANIZATION_LIBRARY_TEAM_BIO,

  ORGANIZATION_LIBRARY_CASE_STUDIES,
  ORGANIZATION_LIBRARY_CASE_STUDY,

  ORGANIZATION_LIBRARY_PACKAGED_SERVICES,
  ORGANIZATION_LIBRARY_PACKAGED_SERVICE,

  ORGANIZATION_LIBRARY_PAGES,
  ORGANIZATION_LIBRARY_PAGE,

  ORGANIZATION_LIBRARY_BILLABLE_SERVICES,
} from './type'

const viewDocument = () => import('@/views/ViewDocument.vue')

// TEMPLATES
export default [{
  name: ORGANIZATION_LIBRARY,
  path: 'library',
  component: ViewOrganizationLibrary,
  redirect: { name: ORGANIZATION_LIBRARY_PLACEHOLDER },
  children: [
    {
      name: ORGANIZATION_LIBRARY_PLACEHOLDER,
      path: '',
      component: ViewOrganizationLibraryPlaceholder,
    },
    // -- reusable blocks
    {
      name: ORGANIZATION_LIBRARY_REUSABLE_BLOCKS,
      path: 'reusable-blocks',
      component: ViewOrganizationLibraryReusableBlocks,
    },
    {
      name: ORGANIZATION_LIBRARY_REUSABLE_BLOCK,
      path: 'reusable-blocks/:documentId',
      props: true,
      component: viewDocument,
    },
    // -- team bios
    {
      name: ORGANIZATION_LIBRARY_TEAM_BIOS,
      path: 'team-bios',
      component: ViewOrganizationLibraryTeamBios,
    },
    {
      name: ORGANIZATION_LIBRARY_TEAM_BIO,
      path: 'team-bios/:documentId',
      props: true,
      component: viewDocument,
    },
    // -- case studies
    {
      name: ORGANIZATION_LIBRARY_CASE_STUDIES,
      path: 'case-studies',
      component: ViewOrganizationLibraryCaseStudies,
    },
    {
      name: ORGANIZATION_LIBRARY_CASE_STUDY,
      path: 'case-studies/:documentId',
      props: true,
      component: viewDocument,
    },
    // -- packaged services
    {
      name: ORGANIZATION_LIBRARY_PACKAGED_SERVICES,
      path: 'packaged-services',
      component: ViewOrganizationLibraryPackagedServices,
    },
    {
      name: ORGANIZATION_LIBRARY_PACKAGED_SERVICE,
      path: 'packaged-services/:documentId',
      props: true,
      component: viewDocument,
    },
    // -- pages
    {
      name: ORGANIZATION_LIBRARY_PAGES,
      path: 'pages',
      component: ViewOrganizationLibraryPages,
    },
    {
      name: ORGANIZATION_LIBRARY_PAGE,
      path: 'pages/:documentId',
      props: true,
      component: viewDocument,
    },
    // -- billable services
    {
      name: ORGANIZATION_LIBRARY_BILLABLE_SERVICES,
      path: 'billable-services',
      component: ViewOrganizationLibraryBillableServices,
    },
  ],
}];
