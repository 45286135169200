<template>
  <WithSidebar>
    <template #sidebar-header>
      <h2 class="font-semibold text-16">Library</h2>
    </template>
    <template #sidebar>
      <OrganizationLibraryNav />
    </template>
    <template #default>
      <router-view :organization="organization" />
    </template>
  </WithSidebar>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import WithSidebar from '@/components/WithSidebar.vue'
import OrganizationLibraryNav from '@/components/OrganizationLibraryNav.vue'

export default defineComponent({
  name: 'ViewOrganizationLibrary',
  components: {
    WithSidebar,
    OrganizationLibraryNav,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
})
</script>
