<template>
  <DocumentNodeEditorModel
    v-slot="{
      node,
      updateNode,
      contentBlock,
      updateContentBlock,
      asyncSaveStart,
    }"
    :node-id="nodeId"
    :use-clone="isFocus"
  >
    <DocumentNodeResolverEditor v-slot="{ label, component }" :node="node">
      <component
        :is="component"
        :node="node"
        :content-block="contentBlock"
        :is-focus="isFocus"
        @update="updateContentBlock"
        @update-node="updateNode"
        @async-save-start="asyncSaveStart"
      >
        <template #editor="{ config, styling, textVariant }">
          <DocumentNodeEditor
            :label="label"
            :node="node"
            :parent="parent"
            :config="config"
            :styling="styling"
            :text-variant="textVariant"
            :index="index"
            :is-first="isFirst"
            :is-last="isLast"
            :rounded-selection="roundedSelection"
            :grid="grid"
            @update="updateNode"
            @move="moveNode"
            @async-save-start="asyncSaveStart"
          />
        </template>
      </component>
    </DocumentNodeResolverEditor>
  </DocumentNodeEditorModel>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useStructureMoveNode } from '@/v2/services/documentStructures/documentStructuresCompositions'
import DocumentNodeEditorModel from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorModel.vue'
import DocumentNodeEditor from '@/v2/features/document/documentNodeEditor/DocumentNodeEditor.vue'
import DocumentNodeResolverEditor from '@/v2/features/document/documentNode/DocumentNodeResolverEditor.vue'

const { useState } = createNamespacedHelpers('documentEditor')

export default defineComponent({
  name: 'DocumentNodeEditable',
  components: {
    DocumentNodeEditorModel,
    DocumentNodeEditor,
    DocumentNodeResolverEditor,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    parent: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isFirst: Boolean,
    isLast: Boolean,
    roundedSelection: Boolean,
    grid: Boolean,
  },
  setup(props, context) {
    const { focusNodeId } = useState(['focusNodeId'])
    const isFocus = computed(() => props.nodeId === focusNodeId.value)
    const structureMoveNode = useStructureMoveNode()

    const moveNode = offset => {
      structureMoveNode({
        id: props.nodeId,
        index: props.index + offset,
      })
    }

    return {
      isFocus,
      moveNode,
    }
  },
})
</script>
