<template>
  <nav>
    <SidebarGroup>
      <SidebarMenu>
        <SidebarMenuLink
          :to="{ name: ORGANIZATION_LIBRARY_REUSABLE_BLOCKS }"
          activate-on-child-route
        >
          <SidebarItemBasic label="Reusable Blocks" icon="icon_v2-so_reusable-block" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: ORGANIZATION_LIBRARY_TEAM_BIOS }"
          activate-on-child-route
        >
          <SidebarItemBasic label="Team Bios" icon="icon_v2-so_team-bios" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: ORGANIZATION_LIBRARY_CASE_STUDIES }"
          activate-on-child-route
        >
          <SidebarItemBasic label="Case Studies" icon="icon_v2-so_case-study" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: ORGANIZATION_LIBRARY_PACKAGED_SERVICES }"
          activate-on-child-route
        >
          <SidebarItemBasic label="Packaged Services" icon="icon_v2-so_open-box" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: ORGANIZATION_LIBRARY_PAGES }"
          activate-on-child-route
        >
          <SidebarItemBasic label="Pages" icon="icon_v2-so_docs-files" />
        </SidebarMenuLink>
        <CheckMemberRole
          v-slot="{ hasRole: isAdmin }"
          :roles="['admin']"
        >
          <SidebarMenuLink
            v-if="isAdmin"
            :to="{ name: ORGANIZATION_LIBRARY_BILLABLE_SERVICES }"
            activate-on-child-route
          >
            <SidebarItemBasic label="Billable Services" icon="icon_v2-so_bill" />
          </SidebarMenuLink>
        </CheckMemberRole>
      </SidebarMenu>
    </SidebarGroup>
  </nav>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  ORGANIZATION_LIBRARY_REUSABLE_BLOCKS,
  ORGANIZATION_LIBRARY_TEAM_BIOS,
  ORGANIZATION_LIBRARY_CASE_STUDIES,
  ORGANIZATION_LIBRARY_PACKAGED_SERVICES,
  ORGANIZATION_LIBRARY_PAGES,
  ORGANIZATION_LIBRARY_BILLABLE_SERVICES,
} from '@/router/organization/type'
import SidebarGroup from '@/components/Sidebar/SidebarGroup.vue'
import SidebarMenu from '@/components/Sidebar/SidebarMenu.vue'
import SidebarMenuLink from '@/components/Sidebar/SidebarMenuLink.vue'
import SidebarItemBasic from '@/components/Sidebar/SidebarItemBasic.vue'
import CheckMemberRole from '@/components/CheckMemberRole.vue'

export default defineComponent({
  name: 'OrganizationLibraryNav',
  components: {
    SidebarGroup,
    SidebarMenu,
    SidebarMenuLink,
    SidebarItemBasic,
    CheckMemberRole,
  },
  props: {
  },
  setup() {
    return {
      ORGANIZATION_LIBRARY_REUSABLE_BLOCKS,
      ORGANIZATION_LIBRARY_TEAM_BIOS,
      ORGANIZATION_LIBRARY_CASE_STUDIES,
      ORGANIZATION_LIBRARY_PACKAGED_SERVICES,
      ORGANIZATION_LIBRARY_PAGES,
      ORGANIZATION_LIBRARY_BILLABLE_SERVICES,
    }
  },
})
</script>
