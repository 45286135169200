<template>
  <div v-fragment>
    <ListTableCell class-name="unit">
      <b-form-select
        :value="item.unit"
        :options="[
          { text: 'Hourly', value: 'hour' },
          { text: 'Daily', value: 'day' },
          { text: 'Weekly', value: 'week' },
          { text: 'Monthly', value: 'month' },
          { text: 'Yearly', value: 'year' },
          { text: 'Per Item', value: 'item' },
          { text: 'Flat Fee', value: 'flat' },
        ]"
        size="md"
        @change="value => update('unit', value)"
      />
    </ListTableCell>
    <ListTableCell>
      <FormInputCurrencies
        :value="item.currency"
        size="md"
        @change="value => update('currency', value)"
      />
    </ListTableCell>
    <ListTableCell>
      <FormField :v="$v.rate">
        <b-form-input
          :value="item.rate"
          :class="$style.rate"
          type="number"
          size="md"
          :number="true"
          trim
          @blur="$v.rate.$touch()"
          @input="value => update('rate', Number(value))"
        />
      </FormField>
    </ListTableCell>
    <ListTableCell>
      <div :class="$style.actions">
        <Button
          variant="delete"
          class="w-24 h-24 p-0"
          @click="$emit('remove')"
        >
          <span class="w-20 icon_v2-so_trash text-20"></span>
        </Button>
      </div>
    </ListTableCell>
  </div>
</template>

<script>
import { __, gt } from 'ramda'
import { toRef } from '@vue/composition-api'
import { useVuelidate } from '@vuelidate/core'
import { required, integer, helpers } from '@vuelidate/validators'
import ListTableCell from '@/components/ListTableCell.vue'
import Button from '@/components/Button.vue'
import FormField from '@/components/FormField.vue'
import FormInputCurrencies from '@/components/FormInputCurrencies.vue'

export default {
  name: 'BillableFormRateListItem',
  components: {
    ListTableCell,
    Button,
    FormField,
    FormInputCurrencies,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const validationRules = {
      rate: {
        required,
        integer,
        gtZero: helpers.withMessage('Must be positive', gt(__, -1)),
      },
    }

    const $v = useVuelidate(validationRules, toRef(props, 'item'))

    const update = (key, value) => {
      context.emit('update', { key, value })
    }

    return {
      update,
      $v,
    }
  },
}
</script>

<style lang="scss" module>
.actions {
  text-align: right;
  :global(.btn-delete) {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
