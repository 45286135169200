
<div v-fragment>
  <ListTableCell class-name="unit">
    <b-form-select
      :value="item.unit"
      :options="[
        { text: 'Hourly', value: 'hour' },
        { text: 'Daily', value: 'day' },
        { text: 'Weekly', value: 'week' },
        { text: 'Monthly', value: 'month' },
        { text: 'Yearly', value: 'year' },
        { text: 'Per Item', value: 'item' },
        { text: 'Flat Fee', value: 'flat' },
      ]"
      size="md"
      @change="value => update('unit', value)"
    />
  </ListTableCell>
  <ListTableCell>
    <FormInputCurrencies
      :value="item.currency"
      size="md"
      @change="value => update('currency', value)"
    />
  </ListTableCell>
  <ListTableCell>
    <FormField :v="$v.rate">
      <b-form-input
        :value="item.rate"
        :class="$style.rate"
        type="number"
        size="md"
        :number="true"
        trim
        @blur="$v.rate.$touch()"
        @input="value => update('rate', Number(value))"
      />
    </FormField>
  </ListTableCell>
  <ListTableCell>
    <div :class="$style.actions">
      <Button
        variant="delete"
        class="w-24 h-24 p-0"
        @click="$emit('remove')"
      >
        <span class="w-20 icon_v2-so_trash text-20"></span>
      </Button>
    </div>
  </ListTableCell>
</div>
