<template>
  <div>
    <PageHeader title="Customize Modules" />
    <Form
      v-if="state"
      v-slot="{ isBusy, submit, hasChanges }"
      :state="state"
      success-message="Project changes saved"
      :on-submit="() => save()"
    >
      <div>
        <div>
          <p class="settings-section-description mb-0">
            Customize the titles, icons, and visiblity of portal modules.
          </p>
          <p class="settings-section-description mb-16">
            Toggle on and off any module to control whether it's visible
            to your organization and/or client organization.
          </p>
        </div>
        <ul class="flex flex-col max-w-page gap-16">
          <!-- Docs & files -->
          <ProjectSettingsModule
            :value="state.modules.documents"
            module-name="Docs &amp; Files"
            :default-icon="PROJECT_MODULE_ICON.iconDocuments"
            @update="val => update('documents', val)"
          />

          <!-- Action Items -->
          <ProjectSettingsModule
            :value="state.modules.tasks"
            module-name="Tasks"
            :default-icon="PROJECT_MODULE_ICON.iconActionItems"
            @update="val => update('tasks', val)"
          />

          <!-- Timeline -->
          <ProjectSettingsModule
            :value="state.modules.timeline"
            module-name="Timeline"
            :default-icon="PROJECT_MODULE_ICON.iconTimeline"
            @update="val => update('timeline', val)"
          />

          <!-- Packaged Services -->
          <ProjectSettingsModule
            :value="state.modules.packagedServices"
            module-name="Packaged Services"
            :default-icon="PROJECT_MODULE_ICON.iconPackagedServices"
            @update="val => update('packagedServices', val)"
          />

          <!-- Pinned Folders -->
          <ProjectSettingsModule
            :value="state.modules.pinnedFolders"
            module-name="Pinned Folders"
            @update="val => update('pinnedFolders', val)"
          />

          <!-- Pages -->
          <ProjectSettingsModule
            :value="state.modules.pages"
            module-name="Pages"
            @update="val => update('pages', val)"
          />

          <!-- Quick Links -->
          <ProjectSettingsModule
            :value="state.modules.quickLinks"
            module-name="Quick Links"
            @update="val => update('quickLinks', val)"
          />

          <!-- Embedded Apps -->
          <ProjectSettingsModule
            :value="state.modules.embeddedApps"
            module-name="Embedded Apps"
            @update="val => update('embeddedApps', val)"
          />

          <!-- Integrations -->
          <ProjectSettingsModule
            :value="state.modules.integrations"
            module-name="Integrations"
            @update="val => update('integrations', val)"
          />

          <!-- Recently Updated -->
          <ProjectSettingsModule
            :value="state.modules.recentlyUpdated"
            module-name="Recently Updated"
            @update="val => update('recentlyUpdated', val)"
          >
            <template #description>
              Lists <span class="font-bold">Recently Updated</span>
              documents on the client-facing Shared Folder.
            </template>
          </ProjectSettingsModule>
        </ul>
      </div>
      <PageFooter>
        <Button
          :disabled="isBusy"
          size="lg"
          variant="primary"
          type="submit"
          @click="submit"
        >
          Save Changes
        </Button>
        <template v-if="hasChanges" #right>
          <PageFooterMessage />
        </template>
      </PageFooter>
    </Form>
  </div>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import { PROJECT_MODULE_ICON } from '@/v2/services/projects/projectsTypes'
import PageFooter from '@/components/PageFooter.vue'
import Form from '@/components/Form.vue'
import Button from '@/components/Button.vue'
import ProjectSettingsModule from '@/components/ProjectSettingsModule.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'
import PageHeader from '@/components/PageHeader.vue'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'


const fields = [
  // documents
  'modules.documents.title',
  'modules.documents.icon',
  'modules.documents.enabled',
  'modules.documents.enabledPortal',

  // tasks
  'modules.tasks.title',
  'modules.tasks.icon',
  'modules.tasks.enabled',
  'modules.tasks.enabledPortal',

  // timeline
  'modules.timeline.title',
  'modules.timeline.icon',
  'modules.timeline.enabled',
  'modules.timeline.enabledPortal',

  // packagedServices
  'modules.packagedServices.title',
  'modules.packagedServices.icon',
  'modules.packagedServices.enabled',
  'modules.packagedServices.enabledPortal',

  // pinnedFolders
  'modules.pinnedFolders.title',
  'modules.pinnedFolders.enabled',
  'modules.pinnedFolders.enabledPortal',

  // pages
  'modules.pages.title',
  'modules.pages.enabled',
  'modules.pages.enabledPortal',

  // quickLinks
  'modules.quickLinks.enabled',
  'modules.quickLinks.enabledPortal',

  // embeddedApps
  'modules.embeddedApps.enabled',
  'modules.embeddedApps.enabledPortal',

  // integrations
  'modules.integrations.enabled',
  'modules.integrations.enabledPortal',

  // recentlyUpdated
  'modules.recentlyUpdated.enabled',
  'modules.recentlyUpdated.enabledPortal',
];

export default defineComponent({
  name: 'ViewProjectSettingsModule',
  components: {
    PageFooter,
    Form,
    Button,
    ProjectSettingsModule,
    PageFooterMessage,
    PageHeader,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      state,
      save,
    } = useFormEntityEditor({
      model: 'Project',
      id: computed(() => props.project._id),
      fields,
    })

    const update = (key, data) => {
      Object.assign(state.value.modules[key], data)
    }

    return {
      DOCUMENT_CATEGORY,
      PROJECT_MODULE_ICON,
      update,
      state,
      save,
    }
  },
})
</script>
