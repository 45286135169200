<template>
  <component
    :is="component"
    v-bind="$props"
    v-on="$listeners"
  />
</template>
<script>
import Vue from 'vue'
import { defineComponent, computed, onUnmounted } from '@vue/composition-api'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import FSFolder from '@/components/FSFolder.vue'
import FSFolderRoot from '@/components/FSFolderRoot.vue'
import FSFolderPublic from '@/components/FSFolderPublic.vue'

const componentMap = {
  [DOCUMENT_CATEGORY.Folder]: FSFolder,
  [DOCUMENT_CATEGORY.ProjectRootFolder]: FSFolderRoot,
  [DOCUMENT_CATEGORY.ProjectPublicFolder]: FSFolderPublic,
};

export default defineComponent({
  name: 'ViewFSFolder',
  components: {
    FSFolder,
    FSFolderRoot,
    FSFolderPublic,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    viewContext: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const component = computed(() => componentMap[props.document.category]);

    return {
      component,
    }
  },
})
</script>
