
<div class="text-12 flex items-center mb-8 text-white">
  <div v-if="label" class="text-white w-88">{{ label }}</div>
  <input
    v-bind="$attrs"
    type="range"
    :min="min"
    :max="max"
    :step="step"
    :value="value"
    :style="{ backgroundSize: backgroundSize }"
    @input="updateSlider"
  />
  <div class="w-60 text-right text-gray-400 select-none">{{ value }}</div>
</div>
