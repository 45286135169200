<template>
  <div>
    <ListTable
      v-slot="{ item, index }"
      :items="value"
      :fields="[
        { label: 'Unit', width: '150px' },
        { label: 'Currency', width: '240px' },
        { label: 'Cost', width: '110px' },
        { width: 'auto' },
      ]"
      key-field="id"
      @create="create"
    >
      <BillableFormRateListItem
        :item="item"
        @update="({ key, value }) => update({ index, key, value })"
        @remove="del(index)"
      />
    </ListTable>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import useFormList from '@/v2/lib/composition/useFormList'
import ListTable from '@/components/ListTable.vue'
import BillableFormRateListItem from '@/components/BillableFormRateListItem.vue'

export default {
  name: 'BillableFormRateList',
  components: {
    ListTable,
    BillableFormRateListItem,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { create, update, del } = useFormList(() => ({
      id: nanoid(),
      unit: 'hour',
      currency: 'USD',
      rate: 0,
    }))

    return {
      create,
      update,
      del,
    }
  },
}
</script>

<style lang="scss" module>
.actions {
  text-align: right;
  :global(.btn-delete) {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
