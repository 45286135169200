<template>
  <div>
    <p class="inline-flex items-center mb-4">
      <span class="font-semibold">{{ organization.domain }}</span>
      <Badge v-if="statusActive" class="ml-6">Active</Badge>
      <Badge
        v-else-if="statusLocked"
        variant="danger"
        class="ml-6"
      >
        Disabled
      </Badge>
      <Badge
        v-else-if="statusRemoveRequested"
        variant="danger"
        class="ml-6"
      >
        Resetting...
      </Badge>
    </p>
    <p class="text-gray-600 text-14">
      <span v-if="statusActive">
        This is your custom domain. If you'd like to update it, please reset it first.
      </span>
      <span v-if="statusLocked">
        Your current plan doesn't include custom domains. Please <b-link
          class="underline"
          :to="{
            name: ORGANIZATION_SUBSCRIPTION,
            params: { provider: LICENSING_PROVIDER.chargebee }
          }"
        >upgrade</b-link> to unlock your domain.
      </span>
    </p>
    <div v-if="statusActive || statusLocked" class="mt-16">
      <Button variant="danger" @click="resetDomain">Reset Domain</Button>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import { ORGANIZATION_SUBSCRIPTION } from '@/router/organization/type'
import { LICENSING_PROVIDER, DOMAIN_STATUS } from '@/v2/services/organizations/organizationsTypes'
import { useMsgBoxConfirm } from '@/v2/lib/composition/useMsgBox'
import Badge from '@/components/Badge.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'SettingsDomainActive',
  components: {
    Badge,
    Button,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // domain is up & running
    const statusActive = computed(
      () => props.organization.domainStatus === DOMAIN_STATUS.active
    )

    // domain was locked caused by a plan downgrade
    // the new plan doesn't have the `customDomains` feature flag enabled
    const statusLocked = computed(
      () => props.organization.domainStatus === DOMAIN_STATUS.locked
    )

    const statusRemoveRequested = computed(
      () => props.organization.domainStatus === DOMAIN_STATUS.removeRequested
    )

    // -- reset domain
    const confirm = useMsgBoxConfirm()
    const resetError = ref(null)

    const reset = async () => {
      resetError.value = null

      try {
        await props.organization.patch({
          data: {
            domainStatus: DOMAIN_STATUS.removeRequested,
          },
        })
      } catch (err) {
        resetError.value = err.message
      }
    }

    const resetDomain = async () => {
      const ok = await confirm({
        title: 'Reset Domain?',
        message: 'Please note that resetting your custom domain '
          + 'will result in your portal being available only at '
          + `${props.organization.subdomain}.superokay.com until you set up a new custom domain.`
          + 'Continue?',
        size: 'md',
      })

      if (ok) {
        reset();
      }
    }

    return {
      ORGANIZATION_SUBSCRIPTION,
      LICENSING_PROVIDER,
      statusActive,
      statusLocked,
      statusRemoveRequested,
      resetDomain,
    }
  },
})
</script>
