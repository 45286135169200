
<ModalBasic
  :title="isNew ? 'Create Billable Service' : 'Edit Billable Service'"
  :hide-footer="true"
  :should-close-on-backdrop="false"
  dialog-class="modal-dialog-tertiary"
  content-class="modal-content-tertiary"
  body-class="modal-body-tertiary"
  header-class="modal-header-tertiary"
  footer-class="modal-footer-tertiary"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #body="{ close }">
    <b-form @submit.prevent="formSubmit">
      <FormMessage v-if="formError">
        {{ formError.message }}
      </FormMessage>
      <div class="flex items-center mb-16">
        <div class="flex-1">
          <FormField
            label="Billable Service Name"
            :v="$v.name"
            required
          >
            <b-input
              v-model="model.name"
              autofocus
              placeholder="Enter the Service Name"
              class="form-control-md"
            />
          </FormField>
        </div>
        <div class="mt-16 ml-24">
          <b-form-checkbox
            v-model="model.isBillable"
            switch
          >
            Billable
          </b-form-checkbox>
        </div>
      </div>

      <div>
        <h3 class="text-24 font-semibold mb-12">
          Rates
        </h3>
        <BillableFormRateList v-model="model.rates" />
      </div>
      <Buttons>
        <Button
          type="button"
          size="md"
          variant="neutral"
          @click="close"
        >
          Cancel
        </Button>
        <Button
          v-track="['New Billable Service Created']"
          type="submit"
          variant="primary"
          size="md"
        >
          Save Changes
        </Button>
      </Buttons>
    </b-form>
  </template>
</ModalBasic>
