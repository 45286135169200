
<!-- <div v-if="!isPending" class="container max-w-screen-xl"> -->
<div v-if="!isPending" class="container max-w-screen-2xl">
  <template v-if="paymentPlans.length">
    <!-- Header -->
    <section class="flex flex-col justify-center text-center pt-40 pb-32">
      <template v-if="$route.query.action === 'subscription-required'">
        <span class="text-gray-600 text-16 ">Your subscription has ended.</span>
        <h1 class="text-red-600 text-24 font-semibold">
          Please subscribe to continue using SuperOkay
        </h1>
      </template>
      <template v-else>
        <span class="text-gray-600 text-16 ">Turn your true work into profit</span>
        <h1 class="text-gray-900 text-32 font-semibold">
          Upgrade your
          <span class="text-gray-600">{{ plan.name }}</span>
          account
        </h1>
      </template>

      <div v-if="hasPricesUnits" class="mx-auto mt-40 flex items-center space-x-24">
        <b-form-radio-group
          id="btn-radios-1"
          :checked="periodUnit"
          :options="periodSwitchOptions"
          name="radios-btn-default"
          button-variant="radio-dark"
          class="btn-group-sp flex"
          size="md"
          buttons
          plain
          @input="val => periodUnit = val"
        />
        <SubscriptionTrialExtend :organization="organization" />
      </div>
    </section>
    <div
      v-if="globalDiscountPercentage"
      class="flex items-center justify-center mb-32 flex-col"
    >
      <div
        class="
          font-semibold font-18 text-center
          bg-red-600 text-white rounded-md px-16 mb-6
        "
      >
        {{ globalDiscountPercentage }}% off Limited-Time Offer
      </div>
      <div class="text-14 text-gray-500">Applicable for the first 12 months.</div>
    </div>
    <div
      class="grid grid-cols-1 gap-32 mb-32"
      :class="`md:grid-cols-${paymentPlans.length}`"
    >
      <PaymentPlanCard
        v-for="availablePlan in paymentPlans"
        :key="availablePlan._id"
        :plan="availablePlan"
        :period-unit="hasPricesUnits ? periodUnit : null"
        @select="onPlanSelect(availablePlan)"
      />
    </div>
    <FormMessage v-if="error">{{ error }}</FormMessage>
  </template>
  <b-alert
    v-else
    show
    variant="info"
    class="m-16"
  >
    <h4 class="alert-heading">No available plans</h4>
    <router-link
      v-slot="{ navigate }"
      :to="{ name: ORGANIZATION, params: { organization: organization._id } }"
      custom
    >
      <Button variant="primary" @click="navigate">Go to dashboard</Button>
    </router-link>
  </b-alert>
</div>
