<template>
  <DocumentNodeEditorToolbarPopover
    class-name="w-32 rounded hover:bg-gray-500 hover:bg-opacity-10 editor-toolbar-button"
    tooltip="Control padding & margin"
  >
    <template #button-content>
      <span class="w-24 text-24 icon-toolbar-spacing"></span>
      <span :class="[$style['toolbar-dropdown']]"></span>
    </template>
    <!-- <template #title>Spacing</template> -->
    <template #default>
      <header
        :class="[
          'text-gray-200 text-opacity-90',
          'bg-gray-1000 bg-opacity-50',
          'px-5 py-3 text-11 uppercase rounded-sm',
          'inline-flex items-center font-semibold mb-6',
        ]"
      >
        Padding Inside
      </header>
      <div class="mb-16">
        <DocumentNodeEditorToolbarSlider
          :value="paddingTop"
          :min="0"
          :max="512"
          :step="8"
          label="Top"
          @input="value => onChange('paddingTop', value)"
        />
        <DocumentNodeEditorToolbarSlider
          :value="paddingBottom"
          :min="0"
          :max="512"
          :step="8"
          label="Bottom"
          @input="value => onChange('paddingBottom', value)"
        />
      </div>

      <header
        :class="[
          'text-gray-200 text-opacity-90',
          'bg-gray-1000 bg-opacity-50',
          'px-5 py-3 text-11 uppercase rounded-sm',
          'inline-flex items-center font-semibold mb-6',
        ]"
      >
        Margin Outside
      </header>
      <div>
        <DocumentNodeEditorToolbarSlider
          :value="marginTop"
          :min="0"
          :max="512"
          :step="8"
          label="Before"
          @input="value => onChange('marginTop', value)"
        />
        <DocumentNodeEditorToolbarSlider
          :value="marginBottom"
          :min="0"
          :max="512"
          :step="8"
          label="After"
          @input="value => onChange('marginBottom', value)"
        />
      </div>
    </template>
  </DocumentNodeEditorToolbarPopover>
</template>
<script>
import DocumentNodeEditorToolbarPopover from './DocumentNodeEditorToolbarPopover.vue'
import DocumentNodeEditorToolbarSlider from './DocumentNodeEditorToolbarSlider.vue'

export default {
  name: 'DocumentNodeEditorToolbarSpacing',
  components: {
    DocumentNodeEditorToolbarSlider,
    DocumentNodeEditorToolbarPopover,
  },
  props: {
    paddingTop: Number,
    paddingBottom: Number,
    marginTop: Number,
    marginBottom: Number,
  },
  methods: {
    onChange(key, value) {
      const numericValue = Number(value)

      if (!Number.isInteger(numericValue) || Math.sign(numericValue) === -1) {
        return
      }

      this.$emit('change', key, numericValue)
    },
  },
}
</script>

<style lang="scss" module>
.toolbar-dropdown{
  border-width: 0 0 6px 6px;
  border-color: transparent transparent rgba(255,255,255,0.4) transparent;
  @apply w-0 h-0 border-solid absolute bottom-0 right-0;
}
</style>
