
<div>
  <PageHeader title="Custom Subdomain &amp; Domain" />
  <OrganizationSettingsSubdomain
    :organization="organization"
    :licensing="licensing"
  />

  <OrganizationSettingsDomain
    :organization="organization"
    :licensing="licensing"
  />
</div>
