
<b-select
  :options="clientOptions"
  class="custom-select-client"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #first>
    <b-form-select-option :value="null">None</b-form-select-option>
  </template>
</b-select>
