
<div :class="['flex items-start pb-24 mb-24 ', className]">
  <div
    :class="['flex items-center justify-center w-40 h-40 text-white',
             'bg-gray-400 rounded-full mr-20 text-20 font-semibold',
             'flex-shrink-0']"
  >
    {{ step }}
  </div>
  <div class="flex flex-col mt-8">
    <h6 class="mb-16 font-medium text-gray-800">{{ header }}</h6>
    <div><slot /></div>
  </div>
</div>
