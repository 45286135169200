<template>
  <component
    :is="component"
    v-bind="$props"
    v-on="$listeners"
  />
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import FSDocument from '@/components/FSDocument.vue'
import FSFile from '@/components/FSFile.vue'

const componentMap = {
  [DOCUMENT_CATEGORY.Document]: FSDocument,
  [DOCUMENT_CATEGORY.File]: FSFile,
  [DOCUMENT_CATEGORY.FileLink]: FSFile,
};

export default defineComponent({
  name: 'ViewFSDocument',
  components: {
    FSDocument,
    FSFile,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const component = computed(() => componentMap[props.document.category]);

    return {
      component,
    }
  },
})
</script>
