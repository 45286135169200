
<ActionsToolbar
  show-drag
  card-drag
  show-delete
  show-edit
  class="p-32"
  @remove="$emit('remove', app)"
  @edit="$emit('edit', app)"
>
  <!-- <template #action-icon>
    <ButtonTransparent class="w-24 h-24" @click.stop="toggleEdit">
      <span
        class="text-gray-600 text-18"
        :class="isEdit ? 'icon_v2-so_close' : 'icon_v2-so_pencil'"
      />
    </ButtonTransparent>
  </template> -->

  <template #default>
    <div class="flex items-center">
      <div class="flex flex-col items-center justify-center px-16 pt-12 pb-4 space-y-4">
        <span :class="`${app.icon} mb-16 portal-accent-foreground text-32 md:text-24`" />
        <span class="font-semibold leading-none text-center text-18 portal-heading-font text-gray-800 dark:text-darkGray-300">
          {{ app.title }}
        </span>
        <span class="font-normal text-center text-12 opacity-40 dark:text-darkGray-400">{{ app.description }}</span>
      </div>
    </div>
  </template>
</ActionsToolbar>
