<template>
  <SpinnerOverlay
    v-if="trialExtendRequested"
    variant="blur"
    label="Extending your trial period..."
  />
  <div v-else-if="trialExtendAllow">
    <span class="text-13 text-gray-500">Need more time?</span>
    <Button
      variant="primary"
      size="xs"
      class="ml-8"
      :is-loading="isBusyExtendTrial"
      @click="extendTrial"
    >
      Extend you trial by {{ TRIAL_EXTEND_DAYS }} days
    </Button>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import useAsyncTask from '@/v2/lib/composition/useAsyncTask'
import { useConfiguration } from '@/v2/services/configurations/configurationsCompositions'
import Button from '@/components/Button.vue'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'

export default defineComponent({
  name: 'SubscriptionTrialExtend',
  components: {
    Button,
    SpinnerOverlay,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const trialExtendRequested = ref(false)
    const { TRIAL_EXTEND_DAYS } = useConfiguration()

    const trialExtendAllow = computed(() => props.organization.trialExtendAllow)
    const { exec: extendTrial, isBusy: isBusyExtendTrial } = useAsyncTask(
      () => props.organization.patch({ data: { trialExtendRequested: true } }).then(() => {
        trialExtendRequested.value = true
      })
    )

    return {
      extendTrial,
      trialExtendAllow,
      isBusyExtendTrial,
      trialExtendRequested,
      TRIAL_EXTEND_DAYS,
    }
  },
})
</script>
