import Cookie from 'js-cookie'
import { getUnixTime, parseISO } from 'date-fns'
import { either, isNil, reject } from 'ramda'

import { isNilOrEmpty, isNonEmptyString, isString } from 'ramda-adjunct'

/**
 * @typedef {import('vue-router').Route} Route
 */

/**
 * @typedef {(method: string, data: object) => void} Intercom
 */

/**
 * @typedef {Object} IntercomServiceConfig
 * @property {string} appId
 * @property {boolean} enable
 * @property {boolean} debug
 * @property {string} cookieDomain
 */

/**
 * @typedef {Object} IntercomIdentity
 * @property {string} id
 * @property {string} email
 * @property {string} name
 * @property {string} createdAt
 * @property {string} [role]
 */

/**
 * @typedef {Object} IntercomServiceOptions
 * @property {IntercomServiceConfig} config
 * @property {import('vuex').Store} store
 * @property {import('vue-router').default} router
 */

const BOOT_OPTIONS = {
  // alignment: 'right',
  horizontal_padding: 20,
  vertical_padding: 32,
}

const KNOWN_PAYMENT_PLANS = [
  'v2-trial',
  'v2-solo-plus',
  'v2-solo',
  'v2-freemium',
  'v2-business',
  'superokay_tier3',
  'superokay_tier2',
  'superokay_tier1',
  'solo',
  'free',
  'business',
]

const KNOWN_PAYMENT_PROVIDERS = [
  'chargebee',
  'appsumo',
]

const KNOWN_ORGANIZATION_STATUSES = [
  'active',
  'subscriptionRequired',
]

const MAX_RETRY = 5;

const rejectNilOrNaN = reject(either(isNil, Number.isNaN))
const timestamp = () => Math.floor(Date.now() / 1000)
const valueOrOther = (value, validOptions) => (validOptions.includes(value) ? value : 'other');
const toUnixTime = dateISOStr => {
  if (isNilOrEmpty(dateISOStr) || !isString(dateISOStr)) {
    return null
  }

  try {
    const date = parseISO(dateISOStr)
    return getUnixTime(date)
  } catch (err) {
    return null
  }
}

export default class IntercomService {
  /** @type {IntercomService} */
  static instance = null

  /**
   * @private
   * @param {() => Intercom | undefined} intercom
   * @param {IntercomServiceOptions} options
   */
  constructor(intercom, { store, router, config, debug = false }) {
    if (IntercomService.instance) {
      throw new Error('Use IntercomService.init() instead of new.')
    }

    this._intercom = intercom // fn getter, can be undefined if not loaded
    this.config = config
    this.store = store
    this.router = router
    this.debugEnabled = debug

    if (config.enable) {
      store.subscribe(this.storeListener.bind(this))
      router.beforeEach(this.routerListenerBefore.bind(this))
      router.afterEach(this.routerListenerAfter.bind(this))

      this.boot()
    }
  }

  /** @private */
  debug(...args) {
    if (!this.debugEnabled) {
      return
    }

    // eslint-disable-next-line no-console
    console.log(...args)
  }

  /**
   * Initializes the singleton instance of the IntercomService.
   * @param {IntercomServiceOptions} options
   */
  static init(intercom, options) {
    if (!IntercomService.instance) {
      IntercomService.instance = new IntercomService(intercom, options)
    }
    return IntercomService.instance
  }

  static getInstance() {
    if (!IntercomService.instance) {
      throw new Error('IntercomService has not been initialized. Please call init first.')
    }

    return IntercomService.instance
  }

  /**
   *
   * @param {'boot' | 'update'} method
   * @param {Record<string, number | string | boolean>} data
   */
  send(method, data) {
    let retry = 0
    return new Promise((resolve, _reject) => {
      const _send = () => {
        const intercom = this._intercom()
        // intercom not loaded, retry in 1 second
        if (!intercom) {
          retry += 1
          if (retry > MAX_RETRY) {
            _reject(new Error('intercom not loaded'))
            return
          }
          setTimeout(_send, 1000)
          return
        }

        intercom(method, data)
        resolve()
      }

      _send()
    })
  }

  clearIdentity() {
    const cookies = [
      'intercom-device-id',
      'intercom-id',
      'intercom-session',
    ].map(name => `${name}-${this.config.appId}`)

    cookies.forEach(name => {
      Cookie.remove(name, { domain: this.config.cookieDomain })
    })
  }

  /** @private */
  boot() {
    this.clearIdentity()
    this.send('boot', rejectNilOrNaN({
      app_id: this.config.appId,
      ...BOOT_OPTIONS,
    }))
  }

  // /** @private */
  // async shutdown() {
  //   if (document.cookie.includes('intercom-session')) {
  //     await this.send('shutdown')
  //   }
  // }

  /**
   * @private
   * @param {Record<string, number | string | boolean>} data
   */
  async update(data) {
    const _data = rejectNilOrNaN(data)

    if (!isNilOrEmpty(_data)) {
      await this.send('update', {
        ..._data,
        app_id: this.config.appId,
      })
    }
  }

  /**
   * @private
   * @param {boolean} value
   * @returns
   */
  toggleDefaultLauncher(value) {
    return this.update({
      hide_default_launcher: !value,
    })
  }

  /**
   * @private
   * @param {import('vuex/types/index').MutationPayload} mutation
   */
  storeListener(mutation) {
    switch (mutation.type) {
      case 'auth/setPayload':
        this.handleAuthPayload(mutation.payload)
        break
      case 'auth/logout':
        this.clearIdentity()
        break
      default:
        break
    }
  }

  /**
   * @private
   * @param {Route} to
   * @param {Route} from
   * @param {import('vue-router').NavigationGuardNext} next
   */
  async routerListenerBefore(to, from, next) {
    const disabled = to.matched.some(m => m.meta.intercomDisable)
    await this.toggleDefaultLauncher(!disabled)
    next()
  }

  /**
   * @private
   * @param {Route} to
   * @param {Route} from
   */
  routerListenerAfter(to, from) {
    this.update({
      last_request_at: timestamp(),
    })
  }

  /**
   * @private
   * @param {string} memberId
   * @param {string} organizationId
   * @returns {Promise<{ member: object, organization: object }>}
   */
  getMemberAndOrganization(memberId, organizationId) {
    return new Promise((resolve, _reject) => {
      let retry = 0

      const getFromStore = () => {
        const member = this.store.state.myMembers.keyedById[memberId]
        const organization = this.store.state.organizations.keyedById[organizationId]

        return {
          organization,
          member,
        }
      }

      const poll = () => {
        const { member, organization } = getFromStore()
        if (!member || !organization) {
          retry += 1
          if (retry > MAX_RETRY) {
            _reject(new Error('member and/or organization not found in store'))
            return
          }
          setTimeout(poll, 500)
          return
        }

        resolve({ member, organization })
      }

      poll()
    })
  }

  /**
   * @private
   * @param {*} auth
   */
  async handleAuthPayload(auth) {
    if (auth.authentication.strategy !== 'member'
      || auth.organization.status !== 'active') {
      return
    }

    const {
      user: { _id: userId, email, createdAt, profile$: { firstName, lastName } },
      member: { _id: memberId },
      organization: { _id: organizationId },
    } = auth

    const { member, organization } = await this.getMemberAndOrganization(memberId, organizationId)
    const role = member.isOwner ? 'owner' : member.role

    const { licensing = {} } = organization

    const paymentProvider = valueOrOther(licensing.provider, KNOWN_PAYMENT_PROVIDERS)
    const paymentPlan = valueOrOther(licensing.planId, KNOWN_PAYMENT_PLANS)
    const status = valueOrOther(organization.status, KNOWN_ORGANIZATION_STATUSES)
    const subscriptionStatus = licensing.status || 'other'

    const data = {
      email,
      role,
      user_id: userId,
      name: [firstName, lastName].filter(isNonEmptyString).join(' '),
      created_at: toUnixTime(createdAt),
      company: rejectNilOrNaN({
        id: organization._id,
        name: organization.name,
        remote_created_at: toUnixTime(organization.createdAt),
        payment_provider: paymentProvider,
        payment_plan: paymentPlan,
        status,
        subscription_status: subscriptionStatus,
      }),
    }

    this.debug('intercom.update', data)
    this.update(data)
  }
}
