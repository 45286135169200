
<DocActions
  v-slot="{
    update,
    remove,
    duplicate,
    share,
    saveAsReusableBlock,
    download,
    changeFonts,
  }"
  :document="document"
>
  <DocRenderer :document="document">
    <template #breadcrumbs>
      <DocLibraryBreadcrumbs :document="document" :category="category" />
    </template>
    <template #actions>
      <DocMenu
        :document="document"
        :active-items="{
          share: true,
          remove: true,
          download: true,
          duplicate: true,
          saveAsReusableBlock: true,
          changeFonts: true,
        }"
        @remove="remove"
        @duplicate="duplicate"
        @share="share"
        @download="download"
        @save-as-reusable-block="saveAsReusableBlock"
        @change-fonts="changeFonts"
      />
    </template>
    <template #header>
      <DocTitle
        :document="document"
        @update="title => update({ title })"
      />
      <DocSharedBadge :document="document" @click="share" />
    </template>

    <template #footer-left>
      <DocUpdatedBy :document="document" />
    </template>
    <template #footer-right>
      <DocPreviewToggle :document="document" />
      <DocStatusToggle
        :value="document.status"
        data-intercom-target="Document Status Select"
        @input="status => update({ status })"
      />
    </template>

    <template #content>
      <DocumentEditorContent
        v-if="document"
        :document="document"
      />
      <DocApprovalBadge :document="document" />
    </template>
  </DocRenderer>
</DocActions>
