<template>
  <ModalBasic
    title="Send Us Your Feedback"
    :hide-footer="true"
    dialog-class="modal-dialog-tertiary"
    content-class="modal-content-tertiary"
    body-class="modal-body-tertiary"
    header-class="modal-header-tertiary"
    footer-class="modal-footer-tertiary"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #body="{ close }">
      <p class="text-16 text-gray-500 mb-8 font-medium pr-32">
        We're all ears for your feedback, whether it's a pat on the back
        or constructive criticism 🙌
      </p>
      <p class="text-13 text-gray-400 mb-16 pr-32">
        Your thoughts matter to us, so we created a safe space for you to share
        your anonymous feedback. We are here, ready to listen and take action
        to make things even better for you 🚀
      </p>
      <Form
        v-slot="{ state, v }"
        :state="formState"
        :on-submit="onSave"
        :validation="validation"
        success-message="Your feedback has been sent. Thank you!"
      >
        <FormField
          :v="v.feedback"
          required
          :messages="{
            required: 'Feedback is empty',
          }"
        >
          <b-textarea
            v-model="state.feedback"
            placeholder="Write your feedback..."
            size="lg"
            :rows="12"
            autofocus
          />
        </FormField>
        <Buttons class="mt-16">
          <Button
            type="button"
            variant="neutral"
            size="md"
            :disabled="isBusy"
            @click="close"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            size="md"
            :is-loading="isBusy"
          >
            Send Your Feedback
          </Button>
        </Buttons>
      </Form>
    </template>
  </ModalBasic>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { required } from '@vuelidate/validators'
import useUserFeedback from '@/v2/services/feedback/compositions/useUserFeedback'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import Button from '@/components/Button.vue'
import Buttons from '@/components/Buttons.vue'
import Form from '@/components/Form.vue'
import FormField from '@/components/FormField.vue'

export default defineComponent({
  name: 'UserFeedbackModal',
  components: {
    ModalBasic,
    Buttons,
    Button,
    Form,
    FormField,
  },
  setup(props, context) {
    const formState = reactive({
      feedback: '',
    })

    const { send, isBusy, error } = useUserFeedback()

    const validation = {
      feedback: { required },
    }

    const onSave = async () => {
      if (isBusy.value) {
        return
      }

      await send(formState)
      context.emit('sent')
    }

    return {
      validation,
      formState,
      onSave,
      isBusy,
      error,
    }
  },
})
</script>
