
<form @submit.prevent.stop="onSubmit">
  <ul class="space-y-8">
    <template v-if="items.length">
      <li
        v-for="item in items"
        :key="item.member._id"
      >
        <div v-if="selectable" class="flex items-center">
          <label
            class="flex items-center cursor-pointer select-none w-full"
            :class="{ 'hover:bg-gray-100 px-16 py-2 rounded-md flex-none': selectable }"
          >
            <input
              type="checkbox"
              class="form-checkbox md mr-12"
              :checked="value.includes(item.member._id)"
              @change="e => onChange(item.member._id, e.target.checked)"
            />
            <TeamMemberListItem :item="item" />
          </label>
        </div>
        <TeamMemberListItem v-else :item="item" />
      </li>
    </template>
    <TeamManagerPlaceholder v-else />
  </ul>
  <template v-if="selectable">
    <div
      v-if="items.length"
      class="mt-30 flex justify-between items-center gap-14"
    >
      <Button
        v-if="items.length"
        type="submit"
        size="md"
        variant="primary"
      >
        Done
      </Button>
      <p
        v-if="items.length"
        class="leading-tight bg-gray-100 p-8 text-gray-500 rounded-md text-13"
      >
        💡 Remember to save the project changes after updating access; otherwise, the changes will not be applied.
      </p>
    </div>
    <Button
      v-else
      type="submit"
      size="md"
      class="mt-30"
      variant="neutral"
    >
      Close
    </Button>
  </template>
</form>
