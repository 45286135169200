
<div>
  <p class="inline-flex items-center mb-4">
    <span class="font-semibold">{{ organization.email }}</span>
    <Badge v-if="statusActive" class="ml-6">Active</Badge>
    <Badge
      v-else-if="statusLocked"
      variant="danger"
      class="ml-6"
    >
      Disabled
    </Badge>
    <Badge
      v-else-if="statusRemoveRequested"
      variant="danger"
      class="ml-6"
    >
      Resetting...
    </Badge>
  </p>
  <p class="text-gray-600 text-14">
    <span v-if="statusActive">
      This is your email we're using to send email notification on your behalf.
      If you'd like to update it, please reset it first.
    </span>
    <span v-if="statusLocked">
      Your current plan doesn't include white label email. Please <b-link
        class="underline"
        :to="{
          name: ORGANIZATION_SUBSCRIPTION,
          params: { provider: LICENSING_PROVIDER.chargebee }
        }"
      >upgrade</b-link> to unlock your white label email.
    </span>
  </p>
  <div v-if="statusActive || statusLocked" class="mt-16">
    <Button variant="danger" @click="resetEmail">Reset Email</Button>
  </div>
</div>
