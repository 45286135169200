<template>
  <div>
    <LibraryDocumentPageHeader
      title="Team Bios"
      label="Team Bio"
      :document-category="category"
    />
    <div class="project-page">
      <div class="project-page-wrapper">
        <DocumentList
          :category="category"
          :organization-id="organization._id"
          :auto-margin="false"
        >
          <template #list="{ documents, open, remove }">
            <div class="max-w-page space-y-16 mt-24">
              <DocumentMasonry>
                <DocumentContentBlockTeamBio
                  v-for="document in documents"
                  :key="document._id"
                  class="masonry-item"
                  :content-block="{ embeddedDocument: document._id }"
                  standalone
                  show-analytics
                  @open="open(document)"
                  @remove="remove(document)"
                />
              </DocumentMasonry>
            </div>
          </template>
          <template #placeholder>
            <PlaceholderCreate
              :document-category="category"
              button-label="Create your first Team Bio"
            >
              <template #title>Introduce your all-star team</template>
              <template #description>
                Create rich team bios for your key people then easily include them
                in Proposals.
              </template>
            </PlaceholderCreate>
          </template>
        </DocumentList>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import DocumentList from '@/components/DocumentList.vue'
import PlaceholderCreate from '@/components/PlaceholderCreate.vue'
import LibraryDocumentPageHeader from '@/components/LibraryDocumentPageHeader.vue'
import DocumentMasonry from '@/components/Document/DocumentMasonry.vue'
import DocumentContentBlockTeamBio from '@/v2/features/document/documentNodeImpl/teamBio/DocumentContentBlockTeamBio.vue'

export default defineComponent({
  name: 'ViewOrganizationLibraryTeamBios',
  metaInfo: {
    title: 'Team Bios',
  },
  components: {
    DocumentList,
    PlaceholderCreate,
    LibraryDocumentPageHeader,
    DocumentMasonry,
    DocumentContentBlockTeamBio,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      category: DOCUMENT_CATEGORY.DocumentTeamBio,
    }
  },
})
</script>
