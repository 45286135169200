<template>
  <div class="mb-16">
    <h3 class="settings-section-title">
      {{ title }}
      <slot name="button" />
    </h3>
    <p class="settings-section-description" :class="{ 'text-orange-500': warn }">
      {{ info }}
    </p>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CardSettingsHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      required: true,
    },
    warn: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
