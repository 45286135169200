<template>
  <FeathersVuexGet
    :id="clientId === 'new' ? null : clientId"
    v-slot="{ item: client, isGetPending }"
    service="clients"
  >
    <router-view
      v-if="!isGetPending"
      :organization="organization"
      :client="client"
    />
  </FeathersVuexGet>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ViewOrganizationClient',
  components: {
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
  },
})
</script>
