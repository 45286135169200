
<div class="flex items-center">
  <b-dropdown
    v-if="user"
    ref="menu"
    dropup
    right
    offset="0"
    :text="organization.name"
    variant="link"
    class="dropdown-admin flex flex-1 w-full"
    menu-class="overflow-y-auto max-h-[90vh] text-13 shadow-2xl dropdown-user"
    toggle-class="
      p-0 flex justify-start w-full flex-1 group
      focus:outline-none focus:shadow-none h-32
    "
    no-caret
  >
    <template #button-content>
      <Avatar
        v-if="profile"
        :size="32"
        :name="profile.fullName"
        :src="profile.profilePhotoUrl"
        v-bind="avatarProps"
      />
      <div
        v-if="!$isMobile"
        class="
            break-all flex-1 text-left ml-8
            font-normal text-gray-700
            group-hover:text-gray-1000 whitespace-normal
            word-wrap"
      >
        {{ profile.firstName }}
      </div>
      <!-- <span class="h-20 text-gray-400 icon-arrow-up text-20 dropdown-caret" /> -->
    </template>

    <b-dropdown-text
      class="pointer-events-none"
      text-class="
          px-8 font-semibold
          text-gray-700 dark:text-darkGray-300 text-14 pb-0
        "
    >
      {{ profile.firstName }}
    </b-dropdown-text>
    <b-dropdown-text
      class="pointer-events-none w-208"
      text-class="
          px-8 font-normal text-gray-500 dark:text-darkGray-400
          text-12 text-ellipsis overflow-hidden
        "
    >
      {{ user.email }}
    </b-dropdown-text>

    <!-- Account  -->
    <DropdownItem
      v-if="!disabledItems.inbox"
      icon="icon_v2-so_notification-ringing"
      label="Inbox"
      :to="{ name: ORGANIZATION_INBOX } "
    />

    <!-- Account  -->
    <DropdownItem
      v-if="!disabledItems.userProfile"
      icon="icon_v2-so_menu-user"
      label="Account Settings"
      intercom="Organization My Account Link"
      :to="{ name: USER_SETTINGS } "
    />

    <b-dropdown-divider />

    <!-- Updates (What's new in SuperOkay ) -->
    <b-dropdown-item
      v-if="!disabledItems.productUpdates"
      href="https://superokay.com/progress-updates/"
      target="_blank"
    >
      <span
        class="relative inline-flex items-center justify-center w-24 mr-4"
      > <span class="icon_v2-so_time-check"></span>
      </span>
      <!-- What's new in SuperOkay -->
      Product Updates
    </b-dropdown-item>
    <!-- Help -->
    <b-dropdown-item
      intercom="SuperOkay Help Center"
      href="https://help.superokay.com/en/"
      target="_blank"
    >
      <span
        class="relative inline-flex items-center justify-center w-24 mr-4"
      ><span class="icon_v2-so_book-help"></span></span>Help
    </b-dropdown-item>

    <DropdownItem
      v-if="!disabledItems.deleteAccount"
      icon="icon_v2-so_trash"
      label="Delete account"
      @dropdown-item-select="deleteAccount"
    />
    <!-- Log Out -->
    <b-dropdown-divider />

    <DropdownItem
      icon="icon_v2-so_logout"
      label="Log Out"
      @dropdown-item-select="logout"
    />
  </b-dropdown>
</div>
