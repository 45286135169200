
<div v-fragment>
  <div>
    <div
      v-if="contacts?.length > 0"
      :class="['relative flex flex-col', 'items-start flex-1']"
    >
      <div class="w-full">
        <ul class="flex flex-wrap items-center text-gray-700">
          <ClientContact
            v-for="contact in contacts"
            :key="contact._id"
            :contact="contact"
            class="hover:bg-gray-100 p-8"
            @send-invite="sendInvite"
            @request-magic-link-token="requestMagicLinkToken"
            @edit="$emit('edit-client-contact', contact.member._id)"
            @remove="remove"
          />
        </ul>
      </div>
    </div>
    <div v-else>
      <InfoBox
        icon="icon_v2-so_clients"
        title="Contacts List Empty"
      >
        No Contacts added to this Client Organization
      </InfoBox>
      <Button
        variant="primary"
        size="xl"
        @click="$emit('create-client-contact')"
      >
        <span class="mr-4 -ml-4 icon_v2-so_plus text-white"></span>
        Add First Contact
      </Button>
    </div>
  </div>
  <MagicLinkModal
    v-if="magicLinkUrl"
    v-model="magicLinkShowModal"
    :link="magicLinkUrl"
    @hidden="onMagicLinkModalClosed"
  />
</div>
