
<div :style="cssVars">
  <PageHeader title="Packaged Services">
    <template #actions>
      <Button
        v-track="['Packaged Services Modal Opened']"
        type="button"
        variant="neutral"
        size="md"
        @click="addServices"
      >
        <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600" />
        Add From Library
      </Button>
    </template>
  </PageHeader>
  <InfoBox
    title="Sell Packaged Services related to this project"
    icon="icon_v2-so_open-box"
  >
    Add your packaged services and they will appear on the portal dashboard.
  </InfoBox>
  <Form
    v-if="state"
    v-slot="{ isBusy, submit, hasChanges }"
    :state="state"
    success-message="Project changes saved"
    :on-submit="() => save()"
  >
    <div class="flex flex-col max-w-page">
      <!-- Packaged Services -->
      <b-form-group>
        <SlickList
          :value="packagedServices"
          use-drag-handle
          lock-to-container-edges
          axis="xy"
          class="flex flex-wrap gap-16 max-w-page"
          helper-class="so-table-row-dragging"
          @input="reorder"
        >
          <SlickItem
            v-for="(packagedService, index) in packagedServices"
            :key="packagedService._id"
            :index="index"
            class="flex flex-col p-0 overflow-visible card-interactive"
          >
            <ActionsToolbar
              show-delete
              show-drag
              card-drag
              show-edit
              @remove="() => remove(packagedService._id)"
              @edit="() => edit(packagedService._id)"
            >
              <div class="p-24 flex">
                <PackagedServiceCard :document="packagedService" />
              </div>
            </ActionsToolbar>
          </SlickItem>
        </SlickList>
      </b-form-group>
    </div>
    <PageFooter>
      <Button
        :disabled="isBusy"
        size="lg"
        variant="primary"
        type="submit"
        @click="submit"
      >
        Save Changes
      </Button>
      <template v-if="hasChanges" #right>
        <PageFooterMessage />
      </template>
    </PageFooter>
  </Form>
  <SelectDocumentsModal
    v-if="isModalOpen"
    v-model="isModalOpen"
    :selection.sync="selection"
    title="Add packaged services from your library"
    button-label="Packaged Service"
    :documents="documents"
    @done="updatePackagedServices"
  />
</div>
