<template>
  <div>
    <PageHeader :title="$t('settings.notifications')" />
    <div class="max-w-form">
      <p class="mb-12">{{ $t('settings.notifications-email-description') }}</p>
      <UserSettingsNotificationsForm
        :organization="organization"
        :user="user"
        :model="model"
      >
        <template #button="{ submit, isBusy, hasChanges }">
          <PageFooter>
            <Button
              :is-loading="isBusy"
              size="lg"
              variant="primary"
              type="submit"
              @click="submit"
            >
              {{ $t('common.save-changes') }}
            </Button>
            <template v-if="hasChanges" #right>
              <PageFooterMessage />
            </template>
          </PageFooter>
        </template>
      </UserSettingsNotificationsForm>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { SUBJECT, ACTION } from '@/v2/services/notifications/notificationsTypes'
import PageHeader from '@/components/PageHeader.vue'
import PageFooter from '@/components/PageFooter.vue'
import Button from '@/components/Button.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'
import UserSettingsNotificationsForm from '@/components/UserSettingsNotificationsForm.vue'

const model = /** @type {const} */ ([
  {
    category: SUBJECT.organizations,
    notifications: [
      ACTION[SUBJECT.organizations].libraryDocumentAdded,
    ],
  },
  {
    category: SUBJECT.projects,
    notifications: [
      ACTION[SUBJECT.projects].newSharesClient,
    ],
  },
  {
    category: SUBJECT.documents,
    notifications: [
      ACTION[SUBJECT.documents].approved,
      ACTION[SUBJECT.documents].firstViewGuest,
      ACTION[SUBJECT.documents].newQaAnswers,
    ],
  },
  {
    category: SUBJECT.actionItems,
    translationKey: 'common.action-items',
    notifications: [
      ACTION[SUBJECT.actionItems].created,
      ACTION[SUBJECT.actionItems].assigned,
      ACTION[SUBJECT.actionItems].resolved,
      ACTION[SUBJECT.actionItems].reopened,
    ],
  },
  {
    category: SUBJECT.comments,
    notifications: [
      ACTION[SUBJECT.comments].created,
      ACTION[SUBJECT.comments].resolved,
      ACTION[SUBJECT.comments].reopened,
    ],
  },
]);

export default defineComponent({
  name: 'ViewUserSettingsNotifications',
  components: {
    PageHeader,
    PageFooter,
    Button,
    PageFooterMessage,
    UserSettingsNotificationsForm,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      model,
    }
  },
})
</script>
