
<nav>
  <ProjectTheme :project="project">
    <div class="border-b border-dotted pb-4 mb-16">
      <SidebarBack @back="$router.push({ name: PROJECT_DASHBOARD })">
        {{ $t('settings.back-to-project') }}
      </SidebarBack>
    </div>
    <SidebarGroup label="Project Settings">
      <SidebarMenu>
        <SidebarMenuLink
          :to="{ name: PROJECT_SETTINGS_DETAILS }"
        >
          <SidebarItemBasic icon="icon_v2-so_filter-eq" label="Details" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: PROJECT_SETTINGS_BRANDING }"
        >
          <SidebarItemBasic icon="icon_v2-so_paint-bucket" label="Branding" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: PROJECT_SETTINGS_MODULES }"
        >
          <SidebarItemBasic icon="icon_v2-so_modules" label="Modules" />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: PROJECT_SETTINGS_MANAGE_ACCESS }"
        >
          <SidebarItemBasic icon="icon_v2-so_user-block" label="Manage Access" />
        </SidebarMenuLink>
      </SidebarMenu>
    </SidebarGroup>
    <SidebarGroup label="Content Settings">
      <SidebarMenu>
        <!-- pinned folders -->
        <SidebarMenuLink :to="{ name: PROJECT_SETTINGS_PINNED_FOLDERS }">
          <SidebarItemBasic icon="icon_v2-so_pin" label="Pinned Folders" />
        </SidebarMenuLink>

        <!-- quick links -->
        <SidebarMenuLink :to="{ name: PROJECT_SETTINGS_QUICK_LINKS }">
          <SidebarItemBasic icon="icon_v2-so_interactive" label="Quick Links" />
        </SidebarMenuLink>

        <!-- embedded apps -->
        <SidebarMenuLink :to="{ name: PROJECT_SETTINGS_APPS }">
          <SidebarItemBasic icon="icon_v2-so_apps" label="Embedded Apps" />
        </SidebarMenuLink>

        <!-- integrations -->
        <SidebarMenuLink :to="{ name: PROJECT_SETTINGS_INTEGRATIONS }">
          <SidebarItemBasic icon="icon_v2-so_diamond" label="Integrations" />
        </SidebarMenuLink>

        <!-- packaged services -->
        <SidebarMenuLink :to="{ name: PROJECT_SETTINGS_PACKAGED_SERVICES }">
          <SidebarItemBasic icon="icon_v2-so_open-box" label="Packaged Services" />
        </SidebarMenuLink>

        <!-- pages -->
        <SidebarMenuLink :to="{ name: PROJECT_SETTINGS_PAGES }">
          <SidebarItemBasic icon="icon_v2-so_docs-files" label="Pages" />
        </SidebarMenuLink>
      </SidebarMenu>
    </SidebarGroup>
  </ProjectTheme>
</nav>
