
<TabsPageHeader
  :tabs="tabs"
  :search="!$isMobile"
  :search-keyword.sync="searchKeyword"
  :search-placeholder="$t('tasks.searchPlaceholder')"
>
  <template #actions>
    <ProjectTasksViewOptions
      v-if="!$isMobile"
      class="mr-8"
      :value="viewContext"
      @input="val => $emit('update:view-context', val)"
    />
    <b-button
      v-if="!hideNewSectionButton"
      variant="neutral"
      size="md"
      class="rounded-lg xl:rounded-sm"
      @click="$emit('create-section')"
    >
      <span class="xl:mr-4 xl:-ml-4 icon_v2-so_plus text-green-600" />
      <span class="hidden xl:inline">{{ $t('tasks.newSection') }}</span>
    </b-button>
  </template>
</TabsPageHeader>
