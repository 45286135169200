
<section
  :class="[
    'flex flex-col lg:pb-48',
  ]"
>
  <div class="flex items-center justify-center md:py-16 h-116 lg:py-34 lg:mb-0">
    <span class="w-48 text-white text-48 icon-superokay-symbol"></span>
  </div>
  <!-- Card -->
  <div
    :class="[
      'flex flex-col justify-center items-center',
      'w-full md:py-16 px-16 md:px-32 max-w-[554px]',
    ]"
  >
    <!-- Logo -->

    <!-- Welcome message -->
    <div class="mb-40 text-center">
      <h1 class="font-semibold text-gray-900 text-18 md:text-24">
        Create a <span class="text-green-600">free account</span> now
      </h1>
      <p class="leading-normal text-gray-600 text-14 lg:text-15">
        One project free forever. No credit card required.
      </p>
    </div>

    <!-- Form -->
    <section class="flex flex-col items-center justify-center space-y-8">
      <ButtonGoogleAuth
        message="Sign up with Google"
        :track="['Sign Up With Google Clicked']"
      />

      <div class="flex flex-row items-center w-full space-x-16 justify-stretch">
        <span class="flex-1 block h-2 bg-gray-200 rounded-full" />
        <span class="flex text-center text-gray-500 text-14">or use email</span>
        <span class="flex-1 block h-2 bg-gray-200 rounded-full" />
      </div>
      <SignUpFormProfile
        :user="user"
        :profile="profile"
        :is-loading="isLoading"
        @submit="onSubmit"
      />
    </section>
    <div
      class="inline-flex flex-row items-center justify-center space-x-16 text-14"
    >
      <span class="text-gray-600">Already have an account?</span>
      <b-link
        class="inline-flex items-center font-bold text-green-600 hover:text-green-800"
        @click.stop="goToSignIn"
      >
        Sign in
        <span class="w-16 ml-6 icon-arrow-right-util text-16"></span>
      </b-link>
    </div>
  </div>
</section>
