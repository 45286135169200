<template>
  <div v-if="!directLink">
    <MainNavItemButton
      ref="buttonSwitch"
      :label="label"
      :icon="icon"
      :icon-active="iconActive"
      :active="active"
      @click.stop="showPopover = !showPopover"
    />
    <ProjectCreate v-slot="{ create }">
      <ProjectSwitcher
        v-if="showPopover"
        :organization="organization"
        :current-project="project"
        :show.sync="showPopover"
        show-client
        :navi-button-ref="buttonSwitch"
        @new-project="create"
        @select="goToProject"
        @all-projects="goToAllProjects"
      />
    </ProjectCreate>
  </div>
  <MainNavItemLink
    v-else
    :label="label"
    :icon="icon"
    :icon-active="iconActive"
    :to="{ name: ORGANIZATION_HOME }"
    :activate-on-parent-routes="[PROJECT]"
  />
</template>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { useCurrentProject } from '@/v2/services/projects/compositions'
import { useRoute, useRouter } from '@/v2/lib/composition/useRouter'
import { ORGANIZATION_HOME } from '@/router/organization/type'
import { PROJECT } from '@/router/project/type'
import MainNavItemButton from '@/components/MainNavItemButton.vue'
import ProjectSwitcher from '@/components/ProjectSwitcher.vue'
import ProjectCreate from '@/components/ProjectCreate.vue'
import MainNavItemLink from '@/components/MainNavItemLink.vue'

export default defineComponent({
  name: 'MainNavItemProjects',
  components: {
    MainNavItemButton,
    ProjectSwitcher,
    ProjectCreate,
    MainNavItemLink,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    directLink: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { routeIsChildOf, routeIs } = useRoute()
    const { routerPush } = useRouter()
    const project = useCurrentProject()
    const buttonSwitch = ref(null)
    const showPopover = ref(false)

    const active = computed(() => [
      showPopover.value,
      routeIs(ORGANIZATION_HOME),
      routeIsChildOf(PROJECT),
    ].some(Boolean))

    const goToProject = ({ _id: projectId }) => {
      if (projectId === project.value?._id) {
        // current project
        return
      }

      routerPush({
        name: PROJECT,
        params: {
          projectId,
          organizationId: props.organization._id,
        },
      })
    }

    const goToAllProjects = () => {
      routerPush({
        name: ORGANIZATION_HOME,
      })
    }

    return {
      buttonSwitch,
      project,
      showPopover,
      active,
      goToProject,
      goToAllProjects,
      ORGANIZATION_HOME,
      PROJECT,

      label: 'Projects',
      icon: 'icon_v2-so_project',
      iconActive: 'icon_v2-so_project-solid',
    }
  },
})
</script>
