<template>
  <OrganizationGet
    :id="organizationId"
    v-slot="{ organization, isGetPending: isOrganizationPending }"
    force
  >
    <Provide
      v-if="!isOrganizationPending"
      :provide="{ organization }"
    >
      <OrganizationStatusGuard
        v-slot="{ active }"
        :organization="organization"
      >
        <Licensing v-slot="{ licensing, plan }">
          <LayoutApp>
            <template #header>
              <MainHeader
                :organization="organization"
                :client="project?.client$"
              >
                <template v-if="!$isMobile" #nav>
                  <MainHeaderNav :organization="organization" :disabled="!active" />
                </template>
                <template #user>
                  <DropdownUser
                    :organization="organization"
                    :disabled-items="{
                      deleteAccount: active,
                      userProfile: !active,
                      inbox: !$isMobile,
                    }"
                  />
                </template>
              </MainHeader>
            </template>
            <template #nav>
              <MainNav
                :organization="organization"
                :disabled="!active"
                :horizontal="$isMobile"
              />
            </template>
            <template #main>
              <router-view
                v-if="organization"
                :organization="organization"
                :licensing="licensing"
                :plan="plan"
              />
            </template>
            <template #modals>
              <QuickStartModal
                v-if="showQuickStart"
                v-model="showQuickStart"
                @customize-project="goToProjectBranding"
              />
            </template>
          </LayoutApp>
        </Licensing>
      </OrganizationStatusGuard>
    </Provide>
    <SpinnerOverlay v-else />
  </OrganizationGet>
</template>

<script>
import { computed } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { PROJECT_SETTINGS_BRANDING } from '@/router/project/type'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useCurrentProject } from '@/v2/services/projects/compositions'
import LayoutApp from '@/views/LayoutApp.vue'
import Provide from '@/components/Provide.vue'
import OrganizationGet from '@/components/OrganizationGet.vue'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import OrganizationStatusGuard from '@/components/OrganizationStatusGuard.vue'
import Licensing from '@/components/Licensing.vue'
import QuickStartModal from '@/components/QuickStart/QuickStartModal.vue'
import MainHeader from '@/components/MainHeader.vue'
import MainNav from '@/components/MainNav.vue'
import DropdownUser from '@/components/DropdownUser.vue'
import MainHeaderNav from '@/components/MainHeaderNav.vue'

const { useState, useMutations } = createNamespacedHelpers('globalFlags')

export default {
  name: 'ViewOrganization',
  metaInfo: {
    title: 'SuperOkay',
    titleTemplate: '%s - SuperOkay',
  },
  components: {
    LayoutApp,
    Provide,
    OrganizationGet,
    SpinnerOverlay,
    OrganizationStatusGuard,
    Licensing,
    QuickStartModal,
    MainHeader,
    MainNav,
    DropdownUser,
    MainHeaderNav,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const project = useCurrentProject()

    const { sidebarIsExpanded, showQuickStart: _showQuickStart } = useState([
      'sidebarIsExpanded',
      'showQuickStart',
    ])

    const { setShowQuickStart } = useMutations(['setShowQuickStart'])

    const showQuickStart = computed({
      get() {
        return _showQuickStart.value
      },
      set(value) {
        setShowQuickStart(value)
      },
    })

    const { routerPush } = useRouter()

    const goToProjectBranding = () => {
      routerPush({
        name: PROJECT_SETTINGS_BRANDING,
      })

      showQuickStart.value = false
    }

    return {
      sidebarIsExpanded,
      showQuickStart,
      goToProjectBranding,
      project,
    }
  },
}
</script>
<style lang="scss" module>
.logo {
  max-height: 32px;
  width: auto;
}
.dashboard-recent {
  max-width: $content-max-width;
  margin-bottom: 62px;
}
</style>
