<template>
  <FeatureEnabled
    v-slot="{ checkFeatureEnabled: checkFullWhiteLabelEnabled }"
    flag="fullWhiteLabel"
    @allow="() => save()"
  >
    <Form
      v-if="state"
      v-slot="{ isBusy, submit, hasChanges }"
      :state="state"
      success-message="Organization settings saved"
      :on-submit="() => checkFullWhiteLabelEnabled()"
    >
      <div class="pb-24">
        <div class="items-center mb-16 md:mb-32 page-header">
          <div>
            <h3 class="project-page-subtitle">
              Client Portal Login Page
            </h3>
            <p class="settings-section-description">
              Configure how the your portal login page looks like for clients.
            </p>
          </div>
          <div class="flex-col items-center justify-center md:flex">
            <b-button
              :href="portalUrl"
              target="blank"
              variant="neutral"
            >
              <span>Open Portal Login Page</span>
              <span class="ml-8 icon-external-link text-16"></span>
            </b-button>
            <div class="mt-4 text-gray-400 text-12">Only clients can log in via this page</div>
          </div>
        </div>
        <div
          class="
            flex flex-col-reverse 2xl:grid
            2xl:grid-cols-[minmax(350px,_1fr)_1fr_1fr]
            2xl:gap-48 2xl:auto-cols-min
          "
        >
          <div>
            <div
              class="
                grid md:border md:border-gray-200
                rounded-md md:bg-gray md:p-24
              "
            >
              <h3 class="mb-16 border-gray-200 settings-section-title">Page</h3>

              <!-- APPEARANCE -->
              <div>
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  label="Appearance"
                  label-for="disabled-fieldset"
                  class="flex items-center justify-between btn-group-sp-wrap"
                >
                  <b-form-radio-group
                    id="appearance-radios"
                    v-model="state.portalLoginCustomizations.appearance"
                    :options="appearanceOptions"
                    :aria-describedby="ariaDescribedby"
                    name="radios-btn-default"
                    button-variant="radio"
                    class="btn-group-sp "
                    :class="$style['btn-group-appearance']"
                    buttons
                    plain
                  />
                </b-form-group>
              </div>

              <FormField
                label="Background Color"
                wrapper-class="flex items-center justify-between h-32 mb-16"
              >
                <ColorPaletteItem
                  v-model="state.portalLoginCustomizations.backgroundColor"
                  variant="pick"
                  popover-placement="buttom"
                  class="list-none included list-item"
                  :size="24"
                />
              </FormField>
              <FormField label="Background image" wrapper-class="flex justify-between">
                <FileUploader
                  v-slot="{ selectFiles }"
                  accept="image/*"
                  :multiple="false"
                  :block="true"
                  :inline-block="false"
                  @upload-start="onUploadStart"
                  @file-progress="onFileProgress"
                  @file-done="onFileDone"
                  @file-error="onFileError"
                >
                  <DocumentNodeEditorToolbarPopover
                    ref="popover"
                    triggers="click"
                    class-name="bg-transparent hover:bg-transparent rounded-md"
                    :close-on-click-outside="!noClose"
                  >
                    <template #button-content>
                      <!-- IF IMAGE -->
                      <div
                        v-if="state.portalLoginCustomizations.backgroundImage"
                        class="
                          relative w-[50px] h-[30px] background-center rounded-md
                          outline outline-darkGray-900/50
                        "
                        :style="{
                          backgroundImage: `url(${optimizedImageUrl(
                            state.portalLoginCustomizations.backgroundImage
                          )})`,
                        }"
                      >
                      </div>
                      <div
                        v-else
                        class="
                          w-[50px] h-[30px] bg-gray-100
                          flex items-center justify-center leading-none
                          border border-gray-300 rounded-md
                        "
                      >
                        <span class="text-gray-400 text-18 icon_v2-so_image"></span>
                      </div>
                    </template>
                    <template #title>
                      Background Image
                    </template>
                    <template #default>
                      <div class="flex flex-col items-stretch gap-8 mt-8">
                        <b-button
                          v-b-tooltip.hover.bottom.v-info.dh0.ds200
                          title="Upload from your device"
                          variant="primary"
                          size="sm"
                          class="px-12"
                          :disabled="fileUploadState.isUploading"
                          @click="selectFiles"
                        >
                          <span class="mr-4 icon-util-upload" />
                          <span>Upload</span>
                        </b-button>
                        <b-button
                          v-b-tooltip.hover.bottom.v-info
                          title="Use an image from Unsplash"
                          variant="black"
                          size="sm"
                          :disabled="fileUploadState.isUploading"
                          class="px-12"
                          @click="openUnsplash"
                        >
                          <span class="mr-4 icon_v2-so_unsplash" />
                          <span>Unsplash</span>
                        </b-button>
                        <b-button
                          v-if="!fileUploadState.isUploading && hasUrl"
                          variant="danger"
                          size="sm"
                          @click.stop="removeBackgroundImage"
                        >
                          <span class="ml-0 mr-0 icon_v2-so_trash"></span>
                          <span>Remove image</span>
                        </b-button>
                      </div>
                    </template>
                  </DocumentNodeEditorToolbarPopover>
                  <!-- UNSPLASH MODAL -->
                  <ImageSearchUnsplashModal
                    v-if="isUnsplashOpen"
                    v-model="isUnsplashOpen"
                    @select="onUnsplashImageSelect"
                  />
                </FileUploader>
              </FormField>
              <!-- Background Image Opacity -->
              <FormField
                v-if="state.portalLoginCustomizations.backgroundImageOpacity !== null"
                label="Image opacity"
                wrapper-class="flex items-center justify-between h-32 mt-8 mb-32"
              >
                <div
                  class="
                    text-14 flex flex-1 items-center text-white ml-24
                    max-w-240 min-w-140
                  "
                >
                  <RangeSlider
                    v-model="state.portalLoginCustomizations.backgroundImageOpacity"
                    :min="0"
                    :max="100"
                  />
                  <div class="text-right text-gray-600 select-none w-60">
                    {{ state.portalLoginCustomizations.backgroundImageOpacity }}%
                  </div>
                </div>
              </FormField>

              <h3 class="mb-16 border-gray-200 settings-section-title">Sign In Form</h3>

              <!-- POSITION -->
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label="Position"
                label-for="disabled-fieldset"
                class="flex items-center justify-between btn-group-sp-wrap"
              >
                <b-form-radio-group
                  id="page-layout-radios"
                  v-model="state.portalLoginCustomizations.pageLayout"
                  :options="pageLayoutOptions"
                  :aria-describedby="ariaDescribedby"
                  name="radios-btn-default"
                  button-variant="radio"
                  class="btn-group-sp"
                  buttons
                  plain
                />
              </b-form-group>

              <!-- SHOW FORM BG -->
              <div class="mb-16">
                <FormField
                  label="Show Form Background"
                  wrapper-class="flex items-center justify-between"
                >
                  <b-form-checkbox
                    v-model="state.portalLoginCustomizations.showFormBackground"
                    switch
                    size="lg"
                  />
                </FormField>
              </div>

              <FormField label="Header message" class="mb-12">
                <b-input
                  v-model="state.portalLoginCustomizations.heading"
                  placeholder="Add header message here..."
                />
              </FormField>

              <FormField label="Instructions" class="mb-16">
                <b-textarea
                  v-model="state.portalLoginCustomizations.welcomeMessage"
                  placeholder="Add instructions here..."
                  rows="2"
                  no-resize
                />
              </FormField>

              <FormField label="Button Color" wrapper-class="flex justify-between mb-16">
                <ColorPaletteItem
                  v-model="state.portalLoginCustomizations.loginButtonColor"
                  variant="pick"
                  popover-placement="buttom"
                  class="list-none included list-item"
                  :size="24"
                />
              </FormField>

              <FormField label="Button Text Color" wrapper-class="flex justify-between mb-16">
                <ColorPaletteItem
                  v-model="state.portalLoginCustomizations.loginButtonTextColor"
                  variant="pick"
                  popover-placement="buttom"
                  class="list-none included list-item"
                  :size="24"
                />
              </FormField>

              <FormField label="Button Label" wrapper-class="flex mb-16 justify-bettwen">
                <div class="flex-1 ml-auto max-w-192">
                  <b-input
                    v-model="state.portalLoginCustomizations.loginButtonLabel"
                    placeholder="Add button label here..."
                  />
                </div>
              </FormField>

              <h3 class="border-gray-200 settings-section-title">Custom Content</h3>

              <p class="mb-4 text-gray-500 text-14">Custom content displayed under form</p>

              <div class="px-12 py-8 mb-8 bg-white border border-gray-200 rounded-md min-h-128">
                <TextEditor
                  v-model="state.portalLoginCustomizations.customContent"
                  :editor-features="textEditorFeatures"
                  :lazy="true"
                  placeholder="Type something..."
                  :autofocus="false"
                />
              </div>
            </div>
          </div>
          <!-- WIP STYLING FOR RĂZVAN -->
          <div
            class="box-content relative 2xl:col-span-2"
            :style="previewCSSVars"
          >
            <h3
              class="
                mt-24 mb-16 text-gray-400 border-gray-200
                settings-section-title
              "
            >
              Preview
            </h3>

            <div
              ref="previewContainer"
              :class="$style['preview-box-container']"
              class="overflow-hidden shadow-2xl rounded-2xl"
            >
              <div
                class="w-screen h-screen transform origin-top-left pointer-events-none"
                :class="$style['preview-box']"
              >
                <LayoutClientPortalGuest
                  :organization="organization"
                  :page-customization="state.portalLoginCustomizations"
                  :domain="{}"
                >
                  <ViewClientPortalLogin
                    :organization="organization"
                    :page-customization="state.portalLoginCustomizations"
                    :domain="{}"
                    preview
                  />
                </LayoutClientPortalGuest>
              </div>
            </div>
            <h3 class="mt-24 mb-16 text-gray-400 border-gray-200 settings-section-title">Themes</h3>

            <vue-horizontal
              :displacement="0.7"
              class="mb-24 horizontal"
            >
              <template #btn-next>
                <div class="more-btn">
                  <div>
                    More
                    <span class="relative h-12 ml-4 icon-arrow-right text-12 top-px" />
                  </div>
                </div>
              </template>
              <div
                v-for="(theme, index) in presetThemes"
                :key="index"
                :style="{
                  backgroundColor: theme.backgroundColor
                }"
                :class="[
                  theme.pageLayout == 'left' && 'justify-start',
                  theme.pageLayout == 'floating' && 'justify-center',
                  theme.pageLayout == 'right' && 'justify-end',
                  $style['theme']
                ]"
                @click="loadPreset(theme)"
              >
                <div
                  class="
                    py-12 min-w-[65px] flex flex-col items-center
                    relative z-1 justify-center
                  "
                  :class="[
                    theme.showFormBackground == true && 'border shadow-xs',
                    theme.showFormBackground == true && theme.appearance == 'dark'
                      ? 'bg-darkGray-900 border-darkGray-800'
                      : '',
                    theme.showFormBackground == true && theme.appearance == 'light'
                      ? 'bg-white border-gray-200'
                      : '',
                    theme.pageLayout == 'left' && 'h-full border-none',
                    theme.pageLayout == 'right' && 'h-full border-none',
                    theme.pageLayout == 'floating' && 'rounded-[3px]'
                    ,
                  ]"
                >
                  <div class="w-[25px] mb-8">
                    <Logo
                      :dark="organization.logoDarkUrl"
                      :light="organization.logoUrl"
                      :theme="theme.appearance"
                    />
                  </div>
                  <div class="w-[35px]">
                    <div
                      class="h-2 mb-2"
                      :class="[
                        theme.appearance == 'light' && 'bg-gray-500',
                        theme.appearance == 'dark' && 'bg-darkGray-500'
                      ]"
                    ></div>
                    <div
                      class="h-2 w-[20px] mx-auto mb-6"
                      :class="[
                        theme.appearance == 'light' && 'bg-gray-400',
                        theme.appearance == 'dark' && 'bg-darkGray-400'
                      ]"
                    ></div>

                    <div
                      class="h-6 mb-2"
                      :class="[
                        theme.appearance == 'light'
                          && 'bg-white border border-gray-200',
                        theme.appearance == 'dark'
                          && 'bg-darkGray-900 border border-darkGray-700'
                      ]"
                    ></div>
                    <div
                      class="h-5 mb-2 w-full flex items-center justify-center"
                      :style="{
                        backgroundColor: theme.loginButtonColor
                      }"
                    >
                      <div
                        class="w-10 h-px"
                        :style="{
                          backgroundColor: theme.loginButtonTextColor
                        }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  class="absolute inset-0 bg-cover rounded-lg"
                  :style="{
                    backgroundImage: 'url(' + theme.backgroundImage + ')',
                    opacity: theme.backgroundImageOpacity + '%'
                  }"
                ></div>
              </div>
            </vue-horizontal>
          </div>
        </div>
        <PageFooter>
          <Button
            :disabled="isBusy"
            size="lg"
            variant="primary"
            type="submit"
            @click="submit"
          >
            Save Changes
          </Button>
          <template v-if="hasChanges" #right>
            <PageFooterMessage />
          </template>
        </PageFooter>
      </div>
    </Form>
  </FeatureEnabled>
</template>
<script>
import {
  computed, defineComponent, reactive, ref,
  onMounted, onUnmounted, watch, toRef,
} from '@vue/composition-api'
import { pick } from 'ramda'
import { EDITOR_FEATURES } from '@/types/editor'
import debounce from '@/v2/lib/helpers/debounce'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'
import FormMessage from '@/components/FormMessage.vue'
import TextEditor from '@/components/TextEditor/TextEditor.vue'
import FormField from '@/components/FormField.vue'
import ImageSearchUnsplashModal from '@/components/ImageSearchUnsplashModal.vue'
import { useFileUpload, optimizedImageUrl } from '@/v2/services/uploads/uploadsCompositions'
import ColorPaletteItem from '@/components/ColorPaletteItem.vue'
import FileUploader from '@/components/FileUploader/FileUploader.vue'
import Button from '@/components/Button.vue'
import RangeSlider from '@/components/RangeSlider.vue'
import useWindowSize from '@/v2/lib/composition/useWindowSize'
import DocumentNodeEditorToolbarPopover from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarPopover.vue'
import useDomain from '@/v2/services/domains/useDomain'
import Logo from '@/components/Logo.vue'
import FeatureEnabled from '@/components/FeatureEnabled.vue'
import ViewClientPortalLogin from '@/views/ViewClientPortalLogin.vue'
import LayoutClientPortalGuest from '@/views/LayoutClientPortalGuest.vue'
import PageFooter from '@/components/PageFooter.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'
import Form from '@/components/Form.vue'

const appearanceOptions = [
  { text: 'Light', value: 'light' },
  { text: 'Dark', value: 'dark' },
]
const pageLayoutOptions = [
  { text: 'Left', value: 'left' },
  { text: 'Floating', value: 'floating' },
  { text: 'Right', value: 'right' },
]
const pickSize = pick(['width', 'height'])

// Configure TextEditor available features
const textEditorFeatures = [
  EDITOR_FEATURES.highlight,
  EDITOR_FEATURES.link,
  EDITOR_FEATURES.bulletList,
  EDITOR_FEATURES.orderedList,
  EDITOR_FEATURES.blockquote,
  EDITOR_FEATURES.italic,
  EDITOR_FEATURES.strike,
  EDITOR_FEATURES.textAlign,
  EDITOR_FEATURES.bold,
]

const presetThemes = [
  {
    appearance: 'light',
    backgroundColor: '#EFEFE9FF',
    backgroundImage: null,
    backgroundImageOpacity: 100,
    pageLayout: 'floating',
    showFormBackground: false,
    loginButtonColor: '#009EC2FF',
    loginButtonTextColor: '#FFFFFF',
  },
  {
    appearance: 'light',
    backgroundColor: '#FFFFFF',
    backgroundImage: 'https://images.unsplash.com/photo-1487700160041-babef9c3cb55?ixid=MnwyMTAyNDN8MHwxfHNlYXJjaHwyM3x8bWluaW1hbGlzdHxlbnwwfHx8fDE2NTAyOTQ2NTE&ixlib=rb-1.2.1',
    backgroundImageOpacity: 100,
    pageLayout: 'right',
    showFormBackground: true,
    loginButtonColor: '#000000',
    loginButtonTextColor: '#FFFFFF',
  },
  {
    appearance: 'dark',
    backgroundColor: '#FFFFFF',
    backgroundImage: 'https://images.unsplash.com/photo-1498036882173-b41c28a8ba34?ixid=MnwyMTAyNDN8MHwxfHNlYXJjaHwxfHxjb29sfGVufDB8fHx8MTY1MDI5NDQwMg&ixlib=rb-1.2.1',
    backgroundImageOpacity: 100,
    pageLayout: 'left',
    showFormBackground: true,
    loginButtonColor: '#8700FFFF',
    loginButtonTextColor: '#FFFFFF',
  },

  {
    appearance: 'dark',
    backgroundColor: '#132E19',
    backgroundImage: 'https://images.unsplash.com/photo-1488330890490-c291ecf62571?ixid=MnwyMTAyNDN8MHwxfHNlYXJjaHw3fHxncmVlbnxlbnwwfHx8fDE2NTAyOTQwNzM&ixlib=rb-1.2.1',
    backgroundImageOpacity: 54,
    pageLayout: 'floating',
    showFormBackground: false,
    loginButtonColor: '#FFFFFF',
    loginButtonTextColor: '#132E19',
  },
  {
    appearance: 'dark',
    backgroundColor: '#000000',
    backgroundImage: 'https://images.unsplash.com/photo-1568733873715-f9d497a47ea0?ixid=MnwyMTAyNDN8MHwxfHNlYXJjaHwxNXx8YmxhY2slMjBhbmQlMjB3aGl0ZXxlbnwwfHx8fDE2NTAyODIxMjk&ixlib=rb-1.2.1',
    backgroundImageOpacity: 40,
    pageLayout: 'floating',
    showFormBackground: false,
    loginButtonColor: '#FFDA1A',
    loginButtonTextColor: '#000000',
  },
];

export default defineComponent({
  name: 'ViewOrganizationSettingsPortalLoginTheme',
  components: {
    FormMessage,
    FormField,
    ImageSearchUnsplashModal,
    ColorPaletteItem,
    FileUploader,
    Button,
    RangeSlider,
    TextEditor,
    ViewClientPortalLogin,
    LayoutClientPortalGuest,
    DocumentNodeEditorToolbarPopover,
    Form,
    Logo,
    FeatureEnabled,
    PageFooter,
    PageFooterMessage,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    licensing: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { withClientPortalUrl } = useDomain()
    const { width } = useWindowSize()

    const {
      state,
      save,
    } = useFormEntityEditor({
      model: 'Organization',
      id: computed(() => props.organization._id),
      fields: [
        'portalLoginCustomizations',
      ],
    })

    // PREVIEW BOX DYNAMIC SCALING
    // We're setting the width and height of the preview compoent to browser window width and height
    // Then we're seeing the space available to the preview box
    // Them we're calculating the required scale for this to fill up
    //    the entire space available.
    const previewContainer = ref(null)
    const previewContainerRect = reactive({ width: 0, height: 0 })
    const screenRect = reactive({ width: window.innerWidth, height: window.innerHeight })
    const previewCSSVars = computed(() => ({
      '--cp-login-preview-scale': previewContainerRect.width / screenRect.width,
      '--cp-login-preview-height': `${Math.round((previewContainerRect.width / screenRect.width) * screenRect.height)}px`,
    }))
    // End PREVIEW BOX DYNAMIC SCALING

    // Preview link for the user to view their login page live
    const portalUrl = withClientPortalUrl(toRef(props, 'organization'), 'login')

    const isUnsplashOpen = ref(false)
    const noClose = ref(false)
    const {
      state: fileUploadState,
      hasUrl,
      onUploadStart,
      onFileProgress,
      onFileError,
      onUploadEnd,
    } = useFileUpload(
      computed(() => state.value.portalLoginCustomizations.backgroundImage)
    )

    const _onUploadStart = (...args) => {
      context.emit('async-save-start') // TODO: Is this needed?
      noClose.value = true
      onUploadStart(...args)
    }

    const _onFileDone = (file, uploadedFile) => {
      state.value.portalLoginCustomizations.backgroundImage = uploadedFile.url
      noClose.value = false
      onUploadEnd(file, uploadedFile)
    }
    const onUnsplashImageSelect = ({ urls }) => {
      state.value.portalLoginCustomizations.backgroundImage = urls.raw
      isUnsplashOpen.value = false
      noClose.value = false
    }
    const openUnsplash = () => {
      isUnsplashOpen.value = true
      noClose.value = true
    }
    const removeBackgroundImage = () => {
      state.value.portalLoginCustomizations.backgroundImage = ''
      noClose.value = false
    }

    const updatePreviewBox = debounce(() => {
      Object.assign(
        previewContainerRect,
        pickSize(previewContainer.value.getBoundingClientRect())
      )
      screenRect.width = width.value
    }, 500)

    const loadPreset = presetTheme => Object.assign(
      state.value.portalLoginCustomizations,
      presetTheme
    )

    onMounted(() => {
      setTimeout(() => {
        Object.assign(
          previewContainerRect,
          pickSize(previewContainer.value.getBoundingClientRect())
        )
      }, 1000);
    })

    watch(width, () => updatePreviewBox())

    // const {
    //   formError,
    //   formSubmit,
    //   formIsInvalid,
    //   formIsBusy,
    // } = useForm({
    //   state: formState,
    //   vuelidateOptions: { $scope: 'portal-login-settings' },
    //   onSubmit: async ({ state }) => {
    //     // Patch organization. Only select data relevant for `portalLoginCustomizations`
    //     await props.organization.patch({
    //       data: {
    //         portalLoginCustomizations: state,
    //       },
    //     })
    //     // Show confirmation
    //     notification({ message: 'Settings saved' })
    //   },
    // })

    return {
      // Static
      appearanceOptions,
      pageLayoutOptions,
      textEditorFeatures,
      // State
      save,
      state,
      isUnsplashOpen,
      previewContainer,
      previewCSSVars,
      portalUrl,
      noClose,
      presetThemes,
      // File upload
      fileUploadState,
      hasUrl,
      // Methods
      openUnsplash,
      onUnsplashImageSelect,
      onUploadStart: _onUploadStart,
      onFileProgress,
      onFileError,
      onFileDone: _onFileDone,
      removeBackgroundImage,
      optimizedImageUrl,
      loadPreset,
    }
  },
})
</script>
<style lang="postcss" module>
.preview-box{
  transform: scale(var(--cp-login-preview-scale));
}
.preview-box-container {
  height: var(--cp-login-preview-height);
  @apply mb-24 2xl:mb-0;
}
.btn-group-appearance  {
  :global(.btn-radio.active:nth-child(2)) {
    @apply bg-darkGray-800 ring-darkGray-800;
  }
}
.theme {
  @apply relative aspect-[16/9] rounded-lg h-[90px]
  mr-12 flex items-center select-none shadow-sm border border-gray-100
  cursor-pointer hover:shadow-lg mb-24 transition-all overflow-hidden;
}
</style>

<style lang="postcss" scoped>
.horizontal >>> .v-hl-btn-prev svg {
  @apply rounded-full bg-white text-gray-400 hover:text-gray-600
}

.horizontal >>> .v-hl-btn-next {
  @apply top-0 bottom-24  translate-x-0 !important;
}


.more-btn {
  background: linear-gradient(to right, #ffffff00, white);
  @apply flex items-center pl-48 h-full
}

.more-btn > div {
  @apply bg-white text-gray-500 shadow-md text-15
  px-16 rounded-md h-30 inline-flex items-center
  justify-center font-medium leading-none hover:text-gray-900
  transition-all;
}
</style>

