<template>
  <div :style="cssVars" class="absolute top-0 left-0 w-[200vw] lg:w-screen overflow-hidden h-240">
    <span
      class="absolute bottom-0 left-0 w-full h-full"
      :style="`background: linear-gradient(0deg, #ffffff 0%, var(--gr-transparent) 90%), ` +
        `radial-gradient(at center top, var(${colorScheme[0]}) 15%, var(--gr-transparent) 60%) 0 -110px, `+
        `radial-gradient(at center top, var(${colorScheme[1]}) 10%, var(--gr-transparent) 70%) 0 0, ` +
        `radial-gradient(at left top, var(${colorScheme[2]}) 10%, var(--gr-transparent) 60%) 0 0, ` +
        `radial-gradient(at center top, var(${colorScheme[3]}) 10%, var(--gr-transparent) 60%) 420px -80px,`+
        `radial-gradient(at right top, var(${colorScheme[4]}) 40%, var(--gr-transparent) 70%) 620px 0;` +
        `background-repeat: no-repeat`
      "
    ></span>
  </div>
</template>

<script>

const colors = ['--gr-green', '--gr-yellow', '--gr-blue', '--gr-violet', '--gr-teal', '--gr-magenta']

// Shuffle an array
// https://stackoverflow.com/questions/64925666/how-can-i-sort-an-array-randomly-in-javascript
const shuffle = input => {
  const array = input
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const k = array[i]
    array[i] = array[j]
    array[j] = k
  }
  return array
}

export default {
  name: 'RadialGradients',

  setup() {
    const colorScheme = shuffle(colors)
    // const colorScheme = colors

    return {
      colorScheme,
    }
  },

  computed: {
    cssVars() {
      return {
        // Account
        '--gr-green': '#3fd255',
        '--gr-yellow': '#fee646',
        '--gr-blue': '#1999e8',
        '--gr-violet': '#8b5cf6',
        '--gr-teal': '#11f6e9',
        '--gr-magenta': '#F615C4',
        '--gr-transparent': 'rgba(255,255,255,0)',
      }
    },
  },
}
</script>
<style lang="scss" module>
  .g1 {
    //filter: saturate(120%) contrast(1.1);
    background:
    linear-gradient(0deg, #ffffff 10%, var(--gr-transparent) 90%),
    radial-gradient(at center top, var(--gr-green) 35%, var(--gr-transparent) 60%) 0 -110px,
    radial-gradient(at center top, var(--gr-first) 30%, var(--gr-transparent) 70%) 0 0,
    radial-gradient( at left top, var(--gr-second) 10%, var(--gr-transparent) 60%) 0 0,
    radial-gradient(at center top, var(--gr-third) 40%, var(--gr-transparent) 60%) 420px -80px,
    radial-gradient( at right top, var(--gr-fourth) 40%, var(--gr-transparent) 70%) 620px 0
    ;
    background-repeat: no-repeat;
  }
  .g2 {
    //filter: saturate(120%) contrast(1.1);
    background:
    linear-gradient(0deg, #ffffff 10%, var(--gr-transparent) 90%),
    radial-gradient(at center top, var(--gr-green) 35%, var(--gr-transparent) 60%) 0 -110px,
    radial-gradient(at center top, var(--gr-blue) 30%, var(--gr-transparent) 70%) 0 0,
    radial-gradient( at left top, var(--gr-yellow) 10%, var(--gr-transparent) 60%) 0 0,
    radial-gradient(at center top, var(--gr-blue) 40%, var(--gr-transparent) 60%) 420px -80px,
    radial-gradient( at right top, var(--gr-violet) 40%, var(--gr-transparent) 70%) 620px 0
    ;
    background-repeat: no-repeat;
  }
</style>
