
<div>
  <SettingsDomainStatus
    class="mb-24"
    :organization="organization"
    :cancel-is-loading="cancelSetupLoading"
    :cancel-error="cancelSetupError?.message"
    @cancel="cancelSetup"
  />
  <SettingsDomainForm
    v-if="![STATUS.cancelSetupRequested, STATUS.cancelSetupPending].includes(status)"
    :organization="organization"
  />
</div>
