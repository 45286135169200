
<div>
  <PageHeader title="Project Details" />
  <FormEntityEditor
    :id="project._id"
    v-slot="{ state, save }"
    :fields="fields"
    model="Project"
  >
    <Form
      v-if="state"
      v-slot="{ isBusy, v, submit, hasChanges }"
      :state="state"
      :validation="validation"
      success-message="Project changes saved"
      :on-submit="() => save()"
    >
      <!-- Main Project Settings -->
      <div class="max-w-form">
        <b-form-group>
          <FormField
            label="Project Name"
            :v="v.name"
            required
          >
            <b-input
              v-model="state.name"
              autofocus
              placeholder="Awesome project"
              class="form-control-lg"
            />
          </FormField>
        </b-form-group>
        <b-form-group>
          <FormField label="Project Tags" :v="v.tags">
            <b-form-tags
              v-model="state.tags"
              no-outer-focus
              tag-variant="light-gray"
              class="mb-2"
              tag-pills
              size="lg"
            >
              <template
                #default="{
                  tags,
                  tagVariant,
                  inputAttrs, inputHandlers, addTag, removeTag
                }"
              >
                <b-input-group aria-controls="my-custom-tags-list" class="mb-8">
                  <input
                    v-bind="inputAttrs"
                    placeholder="New tag - Press enter to add"
                    class="form-control-lg form-control"
                    v-on="inputHandlers"
                  />
                  <b-input-group-append>
                    <b-button variant="primary" @click="addTag()">Add</b-button>
                  </b-input-group-append>
                </b-input-group>
                <ul
                  class="list-unstyled d-inline-flex flex-wrap mb-0 gap-4"
                  aria-live="polite"
                  aria-atomic="false"
                  aria-relevant="additions removals"
                >
                  <li
                    v-for="tag in tags"
                    :key="tag"
                  >
                    <b-form-tag
                      :title="tag"
                      :variant="tagVariant"
                      class="pointer-events-auto"
                      @remove="removeTag(tag)"
                    >
                      {{ tag }}
                    </b-form-tag>
                  </li>
                </ul>
              </template>
            </b-form-tags>
          </FormField>
        </b-form-group>
        <b-form-group>
          <FormField label="Currency" :v="v.currency">
            <FormInputCurrencies
              v-model="state.currency"
              size="lg"
            />
          </FormField>
        </b-form-group>
        <b-form-group>
          <FormField :v="v.locale" label="Language">
            <b-form-select
              v-model="state.locale"
              size="lg"
            >
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option :value="null" disabled>
                <!-- Default Language -->
                </b-form-select-option>
              </template>
              <b-form-select-option
                v-for="(lang, i) in locales"
                :key="`lang-${i}`"
                :value="lang"
              >
                {{ localesNames[lang] }}
              </b-form-select-option>
            </b-form-select>
            <span class="flex mt-12 leading-tight text-gray-500 text-14">
              💡 When choosing another language, only the Portal that
              your client sees is translated.
              Parts of the app only visibile to you will remain in English for now.
            </span>
          </FormField>
        </b-form-group>
      </div>
      <PageFooter>
        <Button
          :disabled="isBusy"
          size="lg"
          variant="primary"
          type="submit"
          @click="submit"
        >
          Save Changes
        </Button>
        <template v-if="hasChanges" #right>
          <PageFooterMessage />
        </template>
      </PageFooter>
    </Form>
  </FormEntityEditor>
</div>
