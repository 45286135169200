
<div class="content-library">
  <!-- header with title -->
  <div class="content-library-header">
    <h2 v-if="title && !hideTitle" class="title">
      {{ title }}
    </h2>
  </div>
  <div class="flex flex-row items-center justify-between space-x-16">
    <!-- Search Bar -->
    <div class="content-library-search">
      <span class="absolute w-24 text-gray-600 icon-magnify text-24 left-10 top-10"></span>
      <!-- NOTE: Could not find a way to debounce keyword update. Local Vuex Store is querried
      after every key press -->
      <b-form-input
        v-model.lazy="keyword"
        :placeholder="`Search ${title}`"
        class="search"
        size="lg"
      />
    </div>
    <!-- End of Search Bar -->
    <!-- <Button
      v-if="createButton"
      type="button"
      size="md"
      variant="primary"
      @click="$emit('new-document')"
    >
      <span class="icon_v2-so_plus"></span>
      {{ createButton }}
    </Button> -->
  </div>
  <div :class="[$style['content-library-content'], isEmpty && 'is-empty']">
    <div
      v-if="isEmpty"
      class="flex flex-col items-center justify-center py-32"
    >
      <span :class="`icon-${icon}`" class="h-48 mb-24 text-gray-400 text-48"></span>
      <h3 class="font-medium text-gray-700/60 text-18">
        No {{ title }} available
      </h3>
      <!-- <p class="text-gray-600 text-16">
        Create {{ title }} in order to include them in your documents.
      </p> -->
    </div>
    <div v-else :class="$style['items-wrap']">
      <div
        :class="[grid ?
                   'grid grid-cols-2 md:grid-cols-none md:flex md:flex-wrap w-full gap-12' :
                   'flex flex-col justify-center items-stretch',
                 'mb-128']"
      >
        <div
          v-for="document in filteredItems"
          :key="document._id"
          :class="$style.item"
          @click="$emit('select', document)"
        >
          <slot v-bind="{ document }" />
        </div>
      </div>
    </div>
  </div>
</div>
