<template>
  <ul class="space-y-16">
    <li
      v-for="i in [0,1,2,3]"
      :key="i"
      class="flex items-center gap-8"
    >
      <span
        class="block w-32 h-32 bg-gray-100 rounded-100 dark:bg-darkGray-700"
      ></span>
      <div class="space-y-8">
        <span class="block h-8 bg-gray-100 dark:bg-darkGray-700 rounded-md w-80"></span>
        <span class="block h-8 bg-gray-100 dark:bg-darkGray-700 rounded-md w-140"></span>
      </div>
    </li>
  </ul>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'TeamManagerPlaceholder',
})
</script>
