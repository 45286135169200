
<WithSidebar>
  <template #sidebar-header>
    <div class="flex items-center justify-between w-full">
      <h2 class="font-semibold text-16">Clients</h2>
      <router-link
        v-slot="{ navigate }"
        :to="{
          name: ORGANIZATION_CLIENT_DETAILS,
          params: { clientId: 'new'}
        }"
        class="block w-116"
        custom
      >
        <FeatureLimit
          v-slot="{ checkFeatureLimit }"
          flag="limitClients"
          label="clients"
          @allow="navigate"
        >
          <Button
            v-track="['New Client Organization Modal Opened']"
            variant="neutral"
            size="xs"
            block
            @click="checkFeatureLimit"
          >
            <span class="inline-block mr-6 icon_v2-so_plus text-green-600" />
            Add Client
          </Button>
        </FeatureLimit>
      </router-link>
    </div>
  </template>
  <template #sidebar>
    <OrganizationClientsNav
      :is-pending="!haveLoaded"
      :clients="clients"
    />
  </template>
  <template #default>
    <router-view :organization="organization" />
  </template>
</WithSidebar>
