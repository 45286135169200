
<div :class="$style['icon-grid']">
  <div
    v-for="(row, rowIndex) in iconMatrix"
    :key="rowIndex"
    :class="$style['row']"
  >
    <div
      v-for="({ opacity }, colIndex) in row"
      :key="colIndex"
      :class="$style['icon']"
      :style="{ opacity }"
    >
      <slot else />
    </div>
  </div>
</div>
